$font-family-sans-serif: Manrope, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: .85rem;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;
$gold:    #fdd835;

$primary:       $gold;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "pink":       $pink,
  "cyan":       $cyan,
  "orange":     $orange,
  "blue":       $blue,
);

$spacer: 1rem;

$body-bg: $gray-200;
$body-color: $gray-800;
$modal-content-bg: $white;
$modal-content-color: $body-color;
$modal-content-border-width: 0;
$modal-header-padding-y: $spacer * 1.5;
$modal-header-padding-x: $spacer * 2.5;
$modal-inner-padding: $spacer * 2.5;


$enable-shadows: true;

$border-radius: 3rem;
$border-radius-sm: $border-radius * 0.75;
$border-radius-lg: $border-radius * 1.25;

$btn-border-width: 1px;
$btn-close-color: $black;
$btn-font-weight: 500;

$global-padding: 1.25rem;

$card-spacer-y: $spacer * 1.5;
$card-spacer-x: $spacer * 1.5;
$card-border-width: 0;
$card-box-shadow: 0 0 20px -10px rgba($black, .2);
