html {
  scroll-behavior: auto !important;
}

nav.navbar {
  .nav-link {
    text-transform: uppercase;
  }
}

img {
  max-width: 100%;
  height: auto;
}

#hero {
  position: relative;
  background: url(../img/main/bg.jpg) center center no-repeat;
  background-size: cover;

  #hero-title {
    position: relative;

    img {
      width: 800px;
      height: auto;
    }

    div {
      position: absolute;
      bottom: 10px;
      width: 650px;
      left: 150px;
      text-transform: uppercase;
      font-size: 14px;
    }

    @include media-breakpoint-only(md) {
      img {
        width: 600px;
      }

      div {
        bottom: 8px;
        width: 440px;
        left: 100px;
        font-size: 11px;
      }
    }

    @include media-breakpoint-down(sm) {
      img {
        width: 290px;
      }

      div {
        bottom: 4px;
        width: 440px;
        left: 48px;
        font-size: 6px;
      }
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to top, rgba($dark, .3), rgba($dark, 0));
  }

  .container {
    position: relative;
  }

  .play-button {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50px;
      right: -20px;
      width: 100px;
      height: 100px;
      border-radius: 120px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        top: -80px;
        right: -20px;
        width: 120px;
        height: 120px;
      }
    }
  }
}

.lang-ru {
  #player {display: block}
  #player-en {display: none}
  .show-en {display: none}
}

.lang-en {
  #player {display: none}
  #player-en {display: block}
  .show-ru {display: none}
}

#video {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;

  #video-bg,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #video-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url(../img/main/preview.jpg) center center no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: all .2s ease;

    #play-video {
      cursor: pointer;
      transition: all .3s ease;
      transform: scale(1);
      animation: ClickAnimation3 3s ease-in-out infinite;

      &:hover {
        opacity: .7;
      }
    }
  }
}

#calculator {
  @include media-breakpoint-down(sm) {
    table {
      thead,
      th:nth-of-type(1),
      th:nth-of-type(2),
      th:nth-of-type(3),
      th:nth-of-type(4) {
        display: none !important;
      }

      tbody {
        border-top: none !important;

        tr {
          border-bottom-width: 1px;
          display: flex;
          flex-direction: column;

          td {
            border-bottom: 0;
            display: inline-block;
            margin: 0 auto;
            width: 100%;
          }
        }
      }

      tfoot {
        td {
          display: block;
          text-align: center;
        }
      }
    }
  }
}

.bee-col {
  min-width: 250px;
  //&:nth-child(2) { .flying-bee {animation-delay: .13s}}
  //&:nth-child(3) { .flying-bee {animation-name: Bee2;}}
  //&:nth-child(4) { .flying-bee {animation-delay: .62s; animation-name: Bee2;}}
  //&:nth-child(5) { .flying-bee {animation-delay: .22s}}
  //&:nth-child(6) { .flying-bee {animation-delay: .25s; animation-name: Bee2;}}
  //&:nth-child(7) { .flying-bee {animation-delay: .28s}}
}

.flying-bee {
  position: absolute;
  width: 70px;
  height: 70px;
  top: -20px;
  left: -20px;
  z-index: 1;
  transform: translate(0, 0);
  transform-origin: 50% 50%;
  //animation: 5s linear 0s infinite Bee;

  &.bee-2 {
    //animation-name: Bee2;
  }

  &.more-gap {
    left: -35px;
    top: -25px;
  }

  &.right {
    left: auto;
    right: -20px;

    &.more-gap {
      left: auto;
      right: -35px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @include media-breakpoint-down(sm) {
    left: -10px;
    width: 90px;
    height: 90px;
    top: -30px;

    &.more-gap {
      top: -40px;
      left: -20px;
    }

    &.right {
      left: auto;
      right: -40px;

      &.more-gap {
        left: auto;
        right: -40px;
      }
    }

    &.h-bee-1 {
      right: -10px !important;
      top: -45px !important;
    }

    &.h-bee-2 {
      top: -80px !important;
      left: 0 !important;
    }

    &.h-bee-3 {
      top: 25px !important;
      right: -20px !important;
    }
  }
}

.card {
  position: relative;

  .flying-bee {
    @include media-breakpoint-down(sm) {
      left: 10px;
      top: 30px;
    }
  }

  .card-title {
    z-index: 2;
  }
}

@keyframes Bee {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, 2px);
  }
  50% {
    transform: translate(-5px, 15px);
  }
  75% {
    transform: translate(-8px, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes Bee2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-5px, 10px);
  }
  50% {
    transform: translate(5px, 15px);
  }
  75% {
    transform: translate(-13px, 2px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.card--with-honey {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

section {
  @include media-breakpoint-down(sm) {
    overflow-x: hidden;

    &#calculator {
      overflow-x: unset;
    }
  }
}

.click-animation {
  animation: 1.5s linear 0s infinite ClickAnimation2;
}

#how-to-list {
  .card {
    transition: background-color .2s ease;

    a {
      text-decoration: none !important;
    }
  }
  .click-icon {
    opacity: 1;
    display: block;
    position: absolute;
    top: 25px;
    right: 25px;
    color: #fff;
    font-size: 30px;
    animation: 1.5s linear 0s infinite ClickAnimation;
    transition: opacity .2s ease;
  }

  .card:hover {
    background-color: darken($primary, 15%) !important;
  }
}

@keyframes ClickAnimation {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.75);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ClickAnimation2 {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.95);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ClickAnimation3 {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.10);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.10);
  }
  100% {
    transform: scale(1);
  }
}

#support-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background: $dark;
  color: $primary;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  box-shadow: 0 0 10px rgba($dark, 0.75);
  text-decoration: none;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .navbar-nav {
    font-size: 82%;
  }
}
