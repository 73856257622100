@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Press+Start+2P&display=swap');
@font-face {
  font-family: RepublicaMinor;
  src: url("../../assets/fonts/republica-minor.otf") format("opentype");
}
@import "custom-variables";

@import "bootstrap";

@import "components/card";
@import "components/sidebar";
@import "components/honeycomb";

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/misc";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";

// LIBRARIES
@import "lib/fontawesome/fontawesome";
@import "lib/fontawesome/solid";
@import "lib/fontawesome/duotone";
@import "lib/fontawesome/brands";

@import 'variables';

@import 'app';
