@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Press+Start+2P&display=swap");
@font-face {
  font-family: RepublicaMinor;
  src: url("../../assets/fonts/republica-minor.otf") format("opentype");
}
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #fdd835;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-pink: #d63384;
  --bs-cyan: #0dcaf0;
  --bs-orange: #fd7e14;
  --bs-blue: #0d6efd;
  --bs-primary-rgb: 253, 216, 53;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-pink-rgb: 214, 51, 132;
  --bs-cyan-rgb: 13, 202, 240;
  --bs-orange-rgb: 253, 126, 20;
  --bs-blue-rgb: 13, 110, 253;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 52, 58, 64;
  --bs-body-bg-rgb: 233, 236, 239;
  --bs-font-sans-serif: Manrope, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.85rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #343a40;
  --bs-body-bg: #e9ecef;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.3375rem + 1.05vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.125rem;
  }
}

h2, .h2 {
  font-size: calc(1.295rem + 0.54vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.7rem;
  }
}

h3, .h3 {
  font-size: calc(1.27375rem + 0.285vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.4875rem;
  }
}

h4, .h4 {
  font-size: calc(1.2525rem + 0.03vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.275rem;
  }
}

h5, .h5 {
  font-size: 1.0625rem;
}

h6, .h6 {
  font-size: 0.85rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #fdd835;
  text-decoration: underline;
}
a:hover {
  color: #caad2a;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 2.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.0625rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.0625rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  border-radius: 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #343a40;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #343a40;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #343a40;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #343a40;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #fff7d7;
  --bs-table-striped-bg: #f2ebcc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dec2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece4c7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dec2;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.0625rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.74375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #e9ecef;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 3rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #343a40;
  background-color: #e9ecef;
  border-color: #feec9a;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #343a40;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #343a40;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #343a40;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  border-radius: 2.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  border-radius: 3.75rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 3rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 3rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #e9ecef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 3rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #feec9a;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #343a40;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.74375rem;
  border-radius: 2.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.0625rem;
  border-radius: 3.75rem;
}

.form-check {
  display: block;
  min-height: 1.275rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #e9ecef;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #feec9a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-check-input:checked {
  background-color: #fdd835;
  border-color: #fdd835;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #fdd835;
  border-color: #fdd835;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23feec9a'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #fdd835;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fef3c2;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #fdd835;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fef3c2;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 3rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  border-radius: 3.75rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  border-radius: 2.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.74375rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 3rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.74375rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 3rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.0625rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.74375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #e9ecef;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 3rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #343a40;
  background-color: #e9ecef;
  border-color: #feec9a;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #343a40;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #343a40;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #343a40;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  border-radius: 2.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  border-radius: 3.75rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 3rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 3rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #e9ecef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 3rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #feec9a;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #343a40;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.74375rem;
  border-radius: 2.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.0625rem;
  border-radius: 3.75rem;
}

.form-check {
  display: block;
  min-height: 1.275rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #e9ecef;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #feec9a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-check-input:checked {
  background-color: #fdd835;
  border-color: #fdd835;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #fdd835;
  border-color: #fdd835;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23feec9a'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #e9ecef, 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #fdd835;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fef3c2;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #fdd835;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fef3c2;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 3rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  border-radius: 3.75rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  border-radius: 2.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.74375rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 3rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.74375rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 3rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  position: relative;
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #343a40;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  border-radius: 3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #343a40;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #000;
  background-color: #fdd835;
  border-color: #fdd835;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(253, 216, 53, 0.6);
}
.btn-primary:hover {
  color: #000;
  background-color: #fdde53;
  border-color: #fddc49;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #fdde53;
  border-color: #fddc49;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(215, 184, 45, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #fde05d;
  border-color: #fddc49;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(215, 184, 45, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #000;
  background-color: #fdd835;
  border-color: #fdd835;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(108, 117, 125, 0.6);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(25, 135, 84, 0.6);
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(13, 202, 240, 0.6);
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(255, 193, 7, 0.6);
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(220, 53, 69, 0.6);
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(248, 249, 250, 0.6);
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(33, 37, 41, 0.6);
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-pink {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(214, 51, 132, 0.6);
}
.btn-pink:hover {
  color: #fff;
  background-color: #b62b70;
  border-color: #ab296a;
}
.btn-check:focus + .btn-pink, .btn-pink:focus {
  color: #fff;
  background-color: #b62b70;
  border-color: #ab296a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(220, 82, 150, 0.5);
}
.btn-check:checked + .btn-pink, .btn-check:active + .btn-pink, .btn-pink:active, .btn-pink.active, .show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #ab296a;
  border-color: #a12663;
}
.btn-check:checked + .btn-pink:focus, .btn-check:active + .btn-pink:focus, .btn-pink:active:focus, .btn-pink.active:focus, .show > .btn-pink.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 82, 150, 0.5);
}
.btn-pink:disabled, .btn-pink.disabled {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}

.btn-cyan {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(13, 202, 240, 0.6);
}
.btn-cyan:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-cyan, .btn-cyan:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-cyan, .btn-check:active + .btn-cyan, .btn-cyan:active, .btn-cyan.active, .show > .btn-cyan.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-cyan:focus, .btn-check:active + .btn-cyan:focus, .btn-cyan:active:focus, .btn-cyan.active:focus, .show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-cyan:disabled, .btn-cyan.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-orange {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(253, 126, 20, 0.6);
}
.btn-orange:hover {
  color: #000;
  background-color: #fd9137;
  border-color: #fd8b2c;
}
.btn-check:focus + .btn-orange, .btn-orange:focus {
  color: #000;
  background-color: #fd9137;
  border-color: #fd8b2c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(215, 107, 17, 0.5);
}
.btn-check:checked + .btn-orange, .btn-check:active + .btn-orange, .btn-orange:active, .btn-orange.active, .show > .btn-orange.dropdown-toggle {
  color: #000;
  background-color: #fd9843;
  border-color: #fd8b2c;
}
.btn-check:checked + .btn-orange:focus, .btn-check:active + .btn-orange:focus, .btn-orange:active:focus, .btn-orange.active:focus, .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(215, 107, 17, 0.5);
}
.btn-orange:disabled, .btn-orange.disabled {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.btn-blue {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 10px 0 rgba(13, 110, 253, 0.6);
}
.btn-blue:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-blue, .btn-blue:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-blue, .btn-check:active + .btn-blue, .btn-blue:active, .btn-blue.active, .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-blue:focus, .btn-check:active + .btn-blue:focus, .btn-blue:active:focus, .btn-blue.active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-blue:disabled, .btn-blue.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary {
  color: #fdd835;
  border-color: #fdd835;
}
.btn-outline-primary:hover {
  color: #000;
  background-color: #fdd835;
  border-color: #fdd835;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #fdd835;
  border-color: #fdd835;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(253, 216, 53, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #fdd835;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-outline-pink {
  color: #d63384;
  border-color: #d63384;
}
.btn-outline-pink:hover {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:focus + .btn-outline-pink, .btn-outline-pink:focus {
  box-shadow: 0 0 0 0.25rem rgba(214, 51, 132, 0.5);
}
.btn-check:checked + .btn-outline-pink, .btn-check:active + .btn-outline-pink, .btn-outline-pink:active, .btn-outline-pink.active, .btn-outline-pink.dropdown-toggle.show {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:checked + .btn-outline-pink:focus, .btn-check:active + .btn-outline-pink:focus, .btn-outline-pink:active:focus, .btn-outline-pink.active:focus, .btn-outline-pink.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(214, 51, 132, 0.5);
}
.btn-outline-pink:disabled, .btn-outline-pink.disabled {
  color: #d63384;
  background-color: transparent;
}

.btn-outline-cyan {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-cyan:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-cyan, .btn-outline-cyan:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-cyan, .btn-check:active + .btn-outline-cyan, .btn-outline-cyan:active, .btn-outline-cyan.active, .btn-outline-cyan.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-cyan:focus, .btn-check:active + .btn-outline-cyan:focus, .btn-outline-cyan:active:focus, .btn-outline-cyan.active:focus, .btn-outline-cyan.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-cyan:disabled, .btn-outline-cyan.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-orange {
  color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-orange:hover {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 126, 20, 0.5);
}
.btn-check:checked + .btn-outline-orange, .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:checked + .btn-outline-orange:focus, .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(253, 126, 20, 0.5);
}
.btn-outline-orange:disabled, .btn-outline-orange.disabled {
  color: #fd7e14;
  background-color: transparent;
}

.btn-outline-blue {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-blue:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-blue, .btn-outline-blue:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-blue, .btn-check:active + .btn-outline-blue, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-blue:focus, .btn-check:active + .btn-outline-blue:focus, .btn-outline-blue:active:focus, .btn-outline-blue.active:focus, .btn-outline-blue.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-blue:disabled, .btn-outline-blue.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #fdd835;
  text-decoration: underline;
}
.btn-link:hover {
  color: #caad2a;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  border-radius: 3.75rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  border-radius: 2.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.85rem;
  color: #343a40;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #fdd835;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.74375rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #fdd835;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #fdd835;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #caad2a;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6 #dee2e6 #e9ecef;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 3rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #fdd835;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.340625rem;
  padding-bottom: 0.340625rem;
  margin-right: 1rem;
  font-size: 1.0625rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.0625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 3rem;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.2);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 3rem 3rem 0 0;
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 3rem 3rem;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 3rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.85rem;
  color: #343a40;
  text-align: left;
  background-color: #e9ecef;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #e4c230;
  background-color: #fffbeb;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e4c230'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23343a40'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #feec9a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(3rem - 1px);
  border-top-right-radius: calc(3rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(3rem - 1px);
  border-bottom-left-radius: calc(3rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #fdd835;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #caad2a;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #caad2a;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #fdd835;
  border-color: #fdd835;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.0625rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 3.75rem;
  border-bottom-left-radius: 3.75rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 3.75rem;
  border-bottom-right-radius: 3.75rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2.25rem;
  border-bottom-left-radius: 2.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2.25rem;
  border-bottom-right-radius: 2.25rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #655615;
  background-color: #fff7d7;
  border-color: #fef3c2;
}
.alert-primary .alert-link {
  color: #514511;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

.alert-pink {
  color: #801f4f;
  background-color: #f7d6e6;
  border-color: #f3c2da;
}
.alert-pink .alert-link {
  color: #66193f;
}

.alert-cyan {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-cyan .alert-link {
  color: #04414d;
}

.alert-orange {
  color: #984c0c;
  background-color: #ffe5d0;
  border-color: #fed8b9;
}
.alert-orange .alert-link {
  color: #7a3d0a;
}

.alert-blue {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-blue .alert-link {
  color: #06357a;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.6375rem;
  background-color: #e9ecef;
  border-radius: 3rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #fdd835;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 3rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #343a40;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #fdd835;
  border-color: #fdd835;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 3rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 3rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 3rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #655615;
  background-color: #fff7d7;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #655615;
  background-color: #e6dec2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #655615;
  border-color: #655615;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.list-group-item-pink {
  color: #801f4f;
  background-color: #f7d6e6;
}
.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: #801f4f;
  background-color: #dec1cf;
}
.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #801f4f;
  border-color: #801f4f;
}

.list-group-item-cyan {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-orange {
  color: #984c0c;
  background-color: #ffe5d0;
}
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #984c0c;
  background-color: #e6cebb;
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #984c0c;
  border-color: #984c0c;
}

.list-group-item-blue {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 3rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 216, 53, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 3rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(3rem - 1px);
  border-top-right-radius: calc(3rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #343a40;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 3.75rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2.5rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 3.75rem;
  border-top-right-radius: 3.75rem;
}
.modal-header .btn-close {
  padding: 0.75rem 1.25rem;
  margin: -0.75rem -1.25rem -0.75rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.5rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 2.25rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 3.75rem;
  border-bottom-left-radius: 3.75rem;
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74375rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 3rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3.75rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(3.75rem - 1px);
  border-top-right-radius: calc(3.75rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #343a40;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: #343a40;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2.5rem;
}
.offcanvas-header .btn-close {
  padding: 1.25rem 1.25rem;
  margin-top: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 2.5rem 2.5rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #fdd835;
}
.link-primary:hover, .link-primary:focus {
  color: #fde05d;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.link-pink {
  color: #d63384;
}
.link-pink:hover, .link-pink:focus {
  color: #ab296a;
}

.link-cyan {
  color: #0dcaf0;
}
.link-cyan:hover, .link-cyan:focus {
  color: #3dd5f3;
}

.link-orange {
  color: #fd7e14;
}
.link-orange:hover, .link-orange:focus {
  color: #fd9843;
}

.link-blue {
  color: #0d6efd;
}
.link-blue:hover, .link-blue:focus {
  color: #0a58ca;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #fdd835 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-pink {
  border-color: #d63384 !important;
}

.border-cyan {
  border-color: #0dcaf0 !important;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.border-blue {
  border-color: #0d6efd !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3375rem + 1.05vw) !important;
}

.fs-2 {
  font-size: calc(1.295rem + 0.54vw) !important;
}

.fs-3 {
  font-size: calc(1.27375rem + 0.285vw) !important;
}

.fs-4 {
  font-size: calc(1.2525rem + 0.03vw) !important;
}

.fs-5 {
  font-size: 1.0625rem !important;
}

.fs-6 {
  font-size: 0.85rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 3rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 2.25rem !important;
}

.rounded-2 {
  border-radius: 3rem !important;
}

.rounded-3 {
  border-radius: 3.75rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 3rem !important;
  border-top-right-radius: 3rem !important;
}

.rounded-end {
  border-top-right-radius: 3rem !important;
  border-bottom-right-radius: 3rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3rem !important;
  border-bottom-left-radius: 3rem !important;
}

.rounded-start {
  border-bottom-left-radius: 3rem !important;
  border-top-left-radius: 3rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.125rem !important;
  }

  .fs-2 {
    font-size: 1.7rem !important;
  }

  .fs-3 {
    font-size: 1.4875rem !important;
  }

  .fs-4 {
    font-size: 1.275rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.card {
  position: relative;
}
.card .card-title {
  padding: 1.5rem 1.5rem 0;
}
.card .card-button {
  position: absolute;
  bottom: -0.5rem;
  right: 0.5rem;
}

#sidebar {
  flex: 1 auto;
  min-width: 270px;
}
#sidebar .card {
  height: 100%;
}
#sidebar .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#sidebar img {
  width: 100px;
  margin: 0 auto;
}
@media (max-width: 991.98px) {
  #sidebar img {
    width: 80px;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  #sidebar {
    top: 90px !important;
    height: calc(100% - 90px) !important;
  }
}
@media (max-width: 991.98px) {
  #sidebar {
    position: fixed;
    z-index: 100;
    top: 70px;
    left: -100%;
    width: 100%;
    height: calc(100% - 70px);
    transition: all 0.4s;
  }
  #sidebar .card {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  #sidebar.is-open {
    left: 0;
  }
}

#sidebar-nav .btn {
  text-transform: uppercase;
  display: block;
  width: 160px;
  text-align: center;
  margin: 0 auto 1.5rem;
}

.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 100px 0;
  transform: translateY(27.5px);
}
.honeycomb-cell {
  flex: 0 1 200px;
  max-width: 200px;
  height: 110px;
  margin: 52.380952381px 7.5px 15px;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 1;
}
.honeycomb-cell__title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #212529;
  font-weight: 700;
  font-size: 1.25em;
  transition: opacity 350ms;
}
.honeycomb-cell__title > small, .honeycomb-cell__title > .small {
  font-weight: 300;
  margin-top: 0.25em;
}
.honeycomb-cell__image {
  object-fit: cover;
  object-position: center;
}
.honeycomb-cell::before, .honeycomb-cell::after {
  content: "";
}
.honeycomb-cell::before, .honeycomb-cell::after, .honeycomb-cell__image {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}
.honeycomb-cell::before {
  background: #fdd835;
  transform: scale(1.055);
}
.honeycomb-cell::after {
  background: #fff;
  opacity: 0.5;
  transition: opacity 350ms;
}
.honeycomb__placeholder {
  display: none;
  opacity: 0;
  width: 200px;
  margin: 0 7.5px;
}

@media (max-width: 430px) {
  .honeycomb-cell {
    margin: 62.5px 15px;
  }
}
@media (min-width: 430px) and (max-width: 645px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 100px);
    margin-left: calc(50% - 100px);
  }

  .honeycomb__placeholder:nth-child(3n+5) {
    display: block;
  }
}
@media (min-width: 645px) and (max-width: 860px) {
  .honeycomb-cell:nth-child(5n+4) {
    margin-left: calc(50% - 215px);
  }

  .honeycomb-cell:nth-child(5n+5) {
    margin-right: calc(50% - 215px);
  }

  .honeycomb__placeholder:nth-child(5n), .honeycomb__placeholder:nth-child(5n+3) {
    display: block;
  }
}
@media (min-width: 860px) {
  .honeycomb-cell:nth-child(7n+5) {
    margin-left: calc(50% - 315px);
  }

  .honeycomb-cell:nth-child(7n+7), .honeycomb-cell:nth-child(7n+5):nth-last-child(2) {
    margin-right: calc(50% - 315px);
  }

  .honeycomb__placeholder:nth-child(7n+7), .honeycomb__placeholder:nth-child(7n+9), .honeycomb__placeholder:nth-child(7n+11) {
    display: block;
  }
}
/* ############### UTILITIES/HELPER CLASSES ############### */
/* ###### 9.1 Background  ###### */
.bg-transparent {
  background-color: transparent;
}

.bg-gray-100 {
  background-color: #f8f9fa;
}

.bg-gray-200 {
  background-color: #e9ecef;
}

.bg-gray-300 {
  background-color: #dee2e6;
}

.bg-gray-400 {
  background-color: #ced4da;
}

.bg-gray-500 {
  background-color: #adb5bd;
}

.bg-gray-600 {
  background-color: #6c757d;
}

.bg-gray-700 {
  background-color: #495057;
}

.bg-gray-800 {
  background-color: #343a40;
}

.bg-gray-900 {
  background-color: #212529;
}

.bg-white-1 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-2 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-3 {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-4 {
  background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-6 {
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-7 {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-8 {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-9 {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-1 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
  background-color: rgba(0, 0, 0, 0.9);
}

.bg-indigo {
  background-color: #6610f2;
}

.bg-purple {
  background-color: #6f42c1;
}

.bg-pink {
  background-color: #d63384;
}

.bg-orange {
  background-color: #fd7e14;
}

.bg-blue {
  background-color: #0d6efd;
}

.bg-cyan {
  background-color: #0dcaf0;
}

.bg-teal {
  background-color: #20c997;
}

.bg-purple-dark {
  background-color: #59339d;
}

.bg-image-cover {
  background-size: cover;
}

.bg-image-center {
  background-position: center center;
}

.bg-overlay {
  position: relative;
}
.bg-overlay::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

/* ###### 9.2 Border   ###### */
.bd-transparent {
  border-color: transparent;
}

.bd {
  border: 1px solid #dee2e6;
}

.bd-t {
  border-top: 1px solid #dee2e6;
}

.bd-r {
  border-right: 1px solid #dee2e6;
}

.bd-b {
  border-bottom: 1px solid #dee2e6;
}

.bd-l {
  border-left: 1px solid #dee2e6;
}

.bd-y {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.bd-x {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.bd-0 {
  border-width: 0;
}

.bd-1 {
  border-width: 1px;
}

.bd-2 {
  border-width: 2px;
}

.bd-3 {
  border-width: 3px;
}

.bd-4 {
  border-width: 4px;
}

.bd-5 {
  border-width: 5px;
}

.bd-t-0 {
  border-top: 0;
}

.bd-r-0 {
  border-right: 0;
}

.bd-b-0 {
  border-bottom: 0;
}

.bd-l-0 {
  border-left: 0;
}

.bd-t-0-f {
  border-top: 0 !important;
}

.bd-r-0-f {
  border-right: 0 !important;
}

.bd-b-0-f {
  border-bottom: 0 !important;
}

.bd-l-0-f {
  border-left: 0 !important;
}

.bd-y-0 {
  border-top-width: 0;
  border-bottom-width: 0;
}

.bd-y-1 {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.bd-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.bd-x-1 {
  border-left-width: 1px;
  border-right-width: 1px;
}

.bd-primary {
  border-color: #fdd835;
}

.bd-success {
  border-color: #198754;
}

.bd-warning {
  border-color: #ffc107;
}

.bd-danger {
  border-color: #dc3545;
}

.bd-info {
  border-color: #0dcaf0;
}

.bd-dark {
  border-color: #212529;
}

.bd-purple {
  border-color: #6f42c1;
}

.bd-blue {
  border-color: #0d6efd;
}

.bd-white {
  border-color: #fff;
}

.bd-gray-100 {
  border-color: #f8f9fa;
}

.bd-gray-200 {
  border-color: #e9ecef;
}

.bd-gray-300 {
  border-color: #dee2e6;
}

.bd-gray-400 {
  border-color: #ced4da;
}

.bd-gray-500 {
  border-color: #adb5bd;
}

.bd-gray-600 {
  border-color: #6c757d;
}

.bd-gray-700 {
  border-color: #495057;
}

.bd-gray-800 {
  border-color: #343a40;
}

.bd-gray-900 {
  border-color: #212529;
}

.bd-white-1 {
  border-color: rgba(255, 255, 255, 0.1);
}

.bd-white-2 {
  border-color: rgba(255, 255, 255, 0.2);
}

.bd-white-3 {
  border-color: rgba(255, 255, 255, 0.3);
}

.bd-white-4 {
  border-color: rgba(255, 255, 255, 0.4);
}

.bd-white-5 {
  border-color: rgba(255, 255, 255, 0.5);
}

.bd-white-6 {
  border-color: rgba(255, 255, 255, 0.6);
}

.bd-white-7 {
  border-color: rgba(255, 255, 255, 0.7);
}

.bd-white-8 {
  border-color: rgba(255, 255, 255, 0.8);
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-30 {
  border-radius: 30px;
}

.rounded-40 {
  border-radius: 40px;
}

.rounded-50 {
  border-radius: 50px;
}

.bd-dashed {
  border-style: dashed;
}

.bd-dotted {
  border-style: dotted;
}

.bd-solid {
  border-style: solid;
}

@media (min-width: 480px) {
  .bd-xs {
    border: 1px solid #dee2e6;
  }

  .bd-xs-t {
    border-top: 1px solid #dee2e6;
  }

  .bd-xs-r {
    border-right: 1px solid #dee2e6;
  }

  .bd-xs-b {
    border-bottom: 1px solid #dee2e6;
  }

  .bd-xs-l {
    border-left: 1px solid #dee2e6;
  }

  .bd-xs-y {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .bd-xs-x {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
}
@media (min-width: 576px) {
  .bd-sm {
    border: 1px solid #dee2e6;
  }

  .bd-sm-t {
    border-top: 1px solid #dee2e6;
  }

  .bd-sm-r {
    border-right: 1px solid #dee2e6;
  }

  .bd-sm-b {
    border-bottom: 1px solid #dee2e6;
  }

  .bd-sm-l {
    border-left: 1px solid #dee2e6;
  }

  .bd-sm-y {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .bd-sm-x {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
}
@media (min-width: 768px) {
  .bd-md {
    border: 1px solid #dee2e6;
  }

  .bd-md-t {
    border-top: 1px solid #dee2e6;
  }

  .bd-md-r {
    border-right: 1px solid #dee2e6;
  }

  .bd-md-b {
    border-bottom: 1px solid #dee2e6;
  }

  .bd-md-l {
    border-left: 1px solid #dee2e6;
  }

  .bd-md-y {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .bd-md-x {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
}
@media (min-width: 992px) {
  .bd-lg {
    border: 1px solid #dee2e6;
  }

  .bd-lg-t {
    border-top: 1px solid #dee2e6;
  }

  .bd-lg-r {
    border-right: 1px solid #dee2e6;
  }

  .bd-lg-b {
    border-bottom: 1px solid #dee2e6;
  }

  .bd-lg-l {
    border-left: 1px solid #dee2e6;
  }

  .bd-lg-y {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .bd-lg-x {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
}
@media (min-width: 1200px) {
  .bd-xl {
    border: 1px solid #dee2e6;
  }

  .bd-xl-t {
    border-top: 1px solid #dee2e6;
  }

  .bd-xl-r {
    border-right: 1px solid #dee2e6;
  }

  .bd-xl-b {
    border-bottom: 1px solid #dee2e6;
  }

  .bd-xl-l {
    border-left: 1px solid #dee2e6;
  }

  .bd-xl-y {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .bd-xl-x {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
}
/* ###### 9.3 Height   ###### */
.ht-5 {
  height: 5px;
}

.ht-5p {
  height: 5%;
}

.mx-ht-5p {
  max-height: 5%;
}

.mn-ht-5p {
  min-height: 5%;
}

.ht-5-f {
  height: 5px !important;
}

.ht-5p-f {
  height: 5% !important;
}

.ht-10 {
  height: 10px;
}

.ht-10p {
  height: 10%;
}

.mx-ht-10p {
  max-height: 10%;
}

.mn-ht-10p {
  min-height: 10%;
}

.ht-10-f {
  height: 10px !important;
}

.ht-10p-f {
  height: 10% !important;
}

.ht-15 {
  height: 15px;
}

.ht-15p {
  height: 15%;
}

.mx-ht-15p {
  max-height: 15%;
}

.mn-ht-15p {
  min-height: 15%;
}

.ht-15-f {
  height: 15px !important;
}

.ht-15p-f {
  height: 15% !important;
}

.ht-20 {
  height: 20px;
}

.ht-20p {
  height: 20%;
}

.mx-ht-20p {
  max-height: 20%;
}

.mn-ht-20p {
  min-height: 20%;
}

.ht-20-f {
  height: 20px !important;
}

.ht-20p-f {
  height: 20% !important;
}

.ht-25 {
  height: 25px;
}

.ht-25p {
  height: 25%;
}

.mx-ht-25p {
  max-height: 25%;
}

.mn-ht-25p {
  min-height: 25%;
}

.ht-25-f {
  height: 25px !important;
}

.ht-25p-f {
  height: 25% !important;
}

.ht-30 {
  height: 30px;
}

.ht-30p {
  height: 30%;
}

.mx-ht-30p {
  max-height: 30%;
}

.mn-ht-30p {
  min-height: 30%;
}

.ht-30-f {
  height: 30px !important;
}

.ht-30p-f {
  height: 30% !important;
}

.ht-35 {
  height: 35px;
}

.ht-35p {
  height: 35%;
}

.mx-ht-35p {
  max-height: 35%;
}

.mn-ht-35p {
  min-height: 35%;
}

.ht-35-f {
  height: 35px !important;
}

.ht-35p-f {
  height: 35% !important;
}

.ht-40 {
  height: 40px;
}

.ht-40p {
  height: 40%;
}

.mx-ht-40p {
  max-height: 40%;
}

.mn-ht-40p {
  min-height: 40%;
}

.ht-40-f {
  height: 40px !important;
}

.ht-40p-f {
  height: 40% !important;
}

.ht-45 {
  height: 45px;
}

.ht-45p {
  height: 45%;
}

.mx-ht-45p {
  max-height: 45%;
}

.mn-ht-45p {
  min-height: 45%;
}

.ht-45-f {
  height: 45px !important;
}

.ht-45p-f {
  height: 45% !important;
}

.ht-50 {
  height: 50px;
}

.ht-50p {
  height: 50%;
}

.mx-ht-50p {
  max-height: 50%;
}

.mn-ht-50p {
  min-height: 50%;
}

.ht-50-f {
  height: 50px !important;
}

.ht-50p-f {
  height: 50% !important;
}

.ht-55 {
  height: 55px;
}

.ht-55p {
  height: 55%;
}

.mx-ht-55p {
  max-height: 55%;
}

.mn-ht-55p {
  min-height: 55%;
}

.ht-55-f {
  height: 55px !important;
}

.ht-55p-f {
  height: 55% !important;
}

.ht-60 {
  height: 60px;
}

.ht-60p {
  height: 60%;
}

.mx-ht-60p {
  max-height: 60%;
}

.mn-ht-60p {
  min-height: 60%;
}

.ht-60-f {
  height: 60px !important;
}

.ht-60p-f {
  height: 60% !important;
}

.ht-65 {
  height: 65px;
}

.ht-65p {
  height: 65%;
}

.mx-ht-65p {
  max-height: 65%;
}

.mn-ht-65p {
  min-height: 65%;
}

.ht-65-f {
  height: 65px !important;
}

.ht-65p-f {
  height: 65% !important;
}

.ht-70 {
  height: 70px;
}

.ht-70p {
  height: 70%;
}

.mx-ht-70p {
  max-height: 70%;
}

.mn-ht-70p {
  min-height: 70%;
}

.ht-70-f {
  height: 70px !important;
}

.ht-70p-f {
  height: 70% !important;
}

.ht-75 {
  height: 75px;
}

.ht-75p {
  height: 75%;
}

.mx-ht-75p {
  max-height: 75%;
}

.mn-ht-75p {
  min-height: 75%;
}

.ht-75-f {
  height: 75px !important;
}

.ht-75p-f {
  height: 75% !important;
}

.ht-80 {
  height: 80px;
}

.ht-80p {
  height: 80%;
}

.mx-ht-80p {
  max-height: 80%;
}

.mn-ht-80p {
  min-height: 80%;
}

.ht-80-f {
  height: 80px !important;
}

.ht-80p-f {
  height: 80% !important;
}

.ht-85 {
  height: 85px;
}

.ht-85p {
  height: 85%;
}

.mx-ht-85p {
  max-height: 85%;
}

.mn-ht-85p {
  min-height: 85%;
}

.ht-85-f {
  height: 85px !important;
}

.ht-85p-f {
  height: 85% !important;
}

.ht-90 {
  height: 90px;
}

.ht-90p {
  height: 90%;
}

.mx-ht-90p {
  max-height: 90%;
}

.mn-ht-90p {
  min-height: 90%;
}

.ht-90-f {
  height: 90px !important;
}

.ht-90p-f {
  height: 90% !important;
}

.ht-95 {
  height: 95px;
}

.ht-95p {
  height: 95%;
}

.mx-ht-95p {
  max-height: 95%;
}

.mn-ht-95p {
  min-height: 95%;
}

.ht-95-f {
  height: 95px !important;
}

.ht-95p-f {
  height: 95% !important;
}

.ht-100 {
  height: 100px;
}

.ht-100p {
  height: 100%;
}

.mx-ht-100p {
  max-height: 100%;
}

.mn-ht-100p {
  min-height: 100%;
}

.ht-100-f {
  height: 100px !important;
}

.ht-100p-f {
  height: 100% !important;
}

.ht-105 {
  height: 105px;
}

.ht-105p {
  height: 105%;
}

.mx-ht-105p {
  max-height: 105%;
}

.mn-ht-105p {
  min-height: 105%;
}

.ht-105-f {
  height: 105px !important;
}

.ht-105p-f {
  height: 105% !important;
}

.ht-110 {
  height: 110px;
}

.ht-110p {
  height: 110%;
}

.mx-ht-110p {
  max-height: 110%;
}

.mn-ht-110p {
  min-height: 110%;
}

.ht-110-f {
  height: 110px !important;
}

.ht-110p-f {
  height: 110% !important;
}

.ht-115 {
  height: 115px;
}

.ht-115p {
  height: 115%;
}

.mx-ht-115p {
  max-height: 115%;
}

.mn-ht-115p {
  min-height: 115%;
}

.ht-115-f {
  height: 115px !important;
}

.ht-115p-f {
  height: 115% !important;
}

.ht-120 {
  height: 120px;
}

.ht-120p {
  height: 120%;
}

.mx-ht-120p {
  max-height: 120%;
}

.mn-ht-120p {
  min-height: 120%;
}

.ht-120-f {
  height: 120px !important;
}

.ht-120p-f {
  height: 120% !important;
}

.ht-125 {
  height: 125px;
}

.ht-125p {
  height: 125%;
}

.mx-ht-125p {
  max-height: 125%;
}

.mn-ht-125p {
  min-height: 125%;
}

.ht-125-f {
  height: 125px !important;
}

.ht-125p-f {
  height: 125% !important;
}

.ht-130 {
  height: 130px;
}

.ht-130p {
  height: 130%;
}

.mx-ht-130p {
  max-height: 130%;
}

.mn-ht-130p {
  min-height: 130%;
}

.ht-130-f {
  height: 130px !important;
}

.ht-130p-f {
  height: 130% !important;
}

.ht-135 {
  height: 135px;
}

.ht-135p {
  height: 135%;
}

.mx-ht-135p {
  max-height: 135%;
}

.mn-ht-135p {
  min-height: 135%;
}

.ht-135-f {
  height: 135px !important;
}

.ht-135p-f {
  height: 135% !important;
}

.ht-140 {
  height: 140px;
}

.ht-140p {
  height: 140%;
}

.mx-ht-140p {
  max-height: 140%;
}

.mn-ht-140p {
  min-height: 140%;
}

.ht-140-f {
  height: 140px !important;
}

.ht-140p-f {
  height: 140% !important;
}

.ht-145 {
  height: 145px;
}

.ht-145p {
  height: 145%;
}

.mx-ht-145p {
  max-height: 145%;
}

.mn-ht-145p {
  min-height: 145%;
}

.ht-145-f {
  height: 145px !important;
}

.ht-145p-f {
  height: 145% !important;
}

.ht-150 {
  height: 150px;
}

.ht-150p {
  height: 150%;
}

.mx-ht-150p {
  max-height: 150%;
}

.mn-ht-150p {
  min-height: 150%;
}

.ht-150-f {
  height: 150px !important;
}

.ht-150p-f {
  height: 150% !important;
}

.ht-155 {
  height: 155px;
}

.ht-155p {
  height: 155%;
}

.mx-ht-155p {
  max-height: 155%;
}

.mn-ht-155p {
  min-height: 155%;
}

.ht-155-f {
  height: 155px !important;
}

.ht-155p-f {
  height: 155% !important;
}

.ht-160 {
  height: 160px;
}

.ht-160p {
  height: 160%;
}

.mx-ht-160p {
  max-height: 160%;
}

.mn-ht-160p {
  min-height: 160%;
}

.ht-160-f {
  height: 160px !important;
}

.ht-160p-f {
  height: 160% !important;
}

.ht-165 {
  height: 165px;
}

.ht-165p {
  height: 165%;
}

.mx-ht-165p {
  max-height: 165%;
}

.mn-ht-165p {
  min-height: 165%;
}

.ht-165-f {
  height: 165px !important;
}

.ht-165p-f {
  height: 165% !important;
}

.ht-170 {
  height: 170px;
}

.ht-170p {
  height: 170%;
}

.mx-ht-170p {
  max-height: 170%;
}

.mn-ht-170p {
  min-height: 170%;
}

.ht-170-f {
  height: 170px !important;
}

.ht-170p-f {
  height: 170% !important;
}

.ht-175 {
  height: 175px;
}

.ht-175p {
  height: 175%;
}

.mx-ht-175p {
  max-height: 175%;
}

.mn-ht-175p {
  min-height: 175%;
}

.ht-175-f {
  height: 175px !important;
}

.ht-175p-f {
  height: 175% !important;
}

.ht-180 {
  height: 180px;
}

.ht-180p {
  height: 180%;
}

.mx-ht-180p {
  max-height: 180%;
}

.mn-ht-180p {
  min-height: 180%;
}

.ht-180-f {
  height: 180px !important;
}

.ht-180p-f {
  height: 180% !important;
}

.ht-185 {
  height: 185px;
}

.ht-185p {
  height: 185%;
}

.mx-ht-185p {
  max-height: 185%;
}

.mn-ht-185p {
  min-height: 185%;
}

.ht-185-f {
  height: 185px !important;
}

.ht-185p-f {
  height: 185% !important;
}

.ht-190 {
  height: 190px;
}

.ht-190p {
  height: 190%;
}

.mx-ht-190p {
  max-height: 190%;
}

.mn-ht-190p {
  min-height: 190%;
}

.ht-190-f {
  height: 190px !important;
}

.ht-190p-f {
  height: 190% !important;
}

.ht-195 {
  height: 195px;
}

.ht-195p {
  height: 195%;
}

.mx-ht-195p {
  max-height: 195%;
}

.mn-ht-195p {
  min-height: 195%;
}

.ht-195-f {
  height: 195px !important;
}

.ht-195p-f {
  height: 195% !important;
}

.ht-200 {
  height: 200px;
}

.ht-200p {
  height: 200%;
}

.mx-ht-200p {
  max-height: 200%;
}

.mn-ht-200p {
  min-height: 200%;
}

.ht-200-f {
  height: 200px !important;
}

.ht-200p-f {
  height: 200% !important;
}

.ht-205 {
  height: 205px;
}

.ht-205p {
  height: 205%;
}

.mx-ht-205p {
  max-height: 205%;
}

.mn-ht-205p {
  min-height: 205%;
}

.ht-205-f {
  height: 205px !important;
}

.ht-205p-f {
  height: 205% !important;
}

.ht-210 {
  height: 210px;
}

.ht-210p {
  height: 210%;
}

.mx-ht-210p {
  max-height: 210%;
}

.mn-ht-210p {
  min-height: 210%;
}

.ht-210-f {
  height: 210px !important;
}

.ht-210p-f {
  height: 210% !important;
}

.ht-215 {
  height: 215px;
}

.ht-215p {
  height: 215%;
}

.mx-ht-215p {
  max-height: 215%;
}

.mn-ht-215p {
  min-height: 215%;
}

.ht-215-f {
  height: 215px !important;
}

.ht-215p-f {
  height: 215% !important;
}

.ht-220 {
  height: 220px;
}

.ht-220p {
  height: 220%;
}

.mx-ht-220p {
  max-height: 220%;
}

.mn-ht-220p {
  min-height: 220%;
}

.ht-220-f {
  height: 220px !important;
}

.ht-220p-f {
  height: 220% !important;
}

.ht-225 {
  height: 225px;
}

.ht-225p {
  height: 225%;
}

.mx-ht-225p {
  max-height: 225%;
}

.mn-ht-225p {
  min-height: 225%;
}

.ht-225-f {
  height: 225px !important;
}

.ht-225p-f {
  height: 225% !important;
}

.ht-230 {
  height: 230px;
}

.ht-230p {
  height: 230%;
}

.mx-ht-230p {
  max-height: 230%;
}

.mn-ht-230p {
  min-height: 230%;
}

.ht-230-f {
  height: 230px !important;
}

.ht-230p-f {
  height: 230% !important;
}

.ht-235 {
  height: 235px;
}

.ht-235p {
  height: 235%;
}

.mx-ht-235p {
  max-height: 235%;
}

.mn-ht-235p {
  min-height: 235%;
}

.ht-235-f {
  height: 235px !important;
}

.ht-235p-f {
  height: 235% !important;
}

.ht-240 {
  height: 240px;
}

.ht-240p {
  height: 240%;
}

.mx-ht-240p {
  max-height: 240%;
}

.mn-ht-240p {
  min-height: 240%;
}

.ht-240-f {
  height: 240px !important;
}

.ht-240p-f {
  height: 240% !important;
}

.ht-245 {
  height: 245px;
}

.ht-245p {
  height: 245%;
}

.mx-ht-245p {
  max-height: 245%;
}

.mn-ht-245p {
  min-height: 245%;
}

.ht-245-f {
  height: 245px !important;
}

.ht-245p-f {
  height: 245% !important;
}

.ht-250 {
  height: 250px;
}

.ht-250p {
  height: 250%;
}

.mx-ht-250p {
  max-height: 250%;
}

.mn-ht-250p {
  min-height: 250%;
}

.ht-250-f {
  height: 250px !important;
}

.ht-250p-f {
  height: 250% !important;
}

.ht-255 {
  height: 255px;
}

.ht-255p {
  height: 255%;
}

.mx-ht-255p {
  max-height: 255%;
}

.mn-ht-255p {
  min-height: 255%;
}

.ht-255-f {
  height: 255px !important;
}

.ht-255p-f {
  height: 255% !important;
}

.ht-260 {
  height: 260px;
}

.ht-260p {
  height: 260%;
}

.mx-ht-260p {
  max-height: 260%;
}

.mn-ht-260p {
  min-height: 260%;
}

.ht-260-f {
  height: 260px !important;
}

.ht-260p-f {
  height: 260% !important;
}

.ht-265 {
  height: 265px;
}

.ht-265p {
  height: 265%;
}

.mx-ht-265p {
  max-height: 265%;
}

.mn-ht-265p {
  min-height: 265%;
}

.ht-265-f {
  height: 265px !important;
}

.ht-265p-f {
  height: 265% !important;
}

.ht-270 {
  height: 270px;
}

.ht-270p {
  height: 270%;
}

.mx-ht-270p {
  max-height: 270%;
}

.mn-ht-270p {
  min-height: 270%;
}

.ht-270-f {
  height: 270px !important;
}

.ht-270p-f {
  height: 270% !important;
}

.ht-275 {
  height: 275px;
}

.ht-275p {
  height: 275%;
}

.mx-ht-275p {
  max-height: 275%;
}

.mn-ht-275p {
  min-height: 275%;
}

.ht-275-f {
  height: 275px !important;
}

.ht-275p-f {
  height: 275% !important;
}

.ht-280 {
  height: 280px;
}

.ht-280p {
  height: 280%;
}

.mx-ht-280p {
  max-height: 280%;
}

.mn-ht-280p {
  min-height: 280%;
}

.ht-280-f {
  height: 280px !important;
}

.ht-280p-f {
  height: 280% !important;
}

.ht-285 {
  height: 285px;
}

.ht-285p {
  height: 285%;
}

.mx-ht-285p {
  max-height: 285%;
}

.mn-ht-285p {
  min-height: 285%;
}

.ht-285-f {
  height: 285px !important;
}

.ht-285p-f {
  height: 285% !important;
}

.ht-290 {
  height: 290px;
}

.ht-290p {
  height: 290%;
}

.mx-ht-290p {
  max-height: 290%;
}

.mn-ht-290p {
  min-height: 290%;
}

.ht-290-f {
  height: 290px !important;
}

.ht-290p-f {
  height: 290% !important;
}

.ht-295 {
  height: 295px;
}

.ht-295p {
  height: 295%;
}

.mx-ht-295p {
  max-height: 295%;
}

.mn-ht-295p {
  min-height: 295%;
}

.ht-295-f {
  height: 295px !important;
}

.ht-295p-f {
  height: 295% !important;
}

.ht-300 {
  height: 300px;
}

.ht-300p {
  height: 300%;
}

.mx-ht-300p {
  max-height: 300%;
}

.mn-ht-300p {
  min-height: 300%;
}

.ht-300-f {
  height: 300px !important;
}

.ht-300p-f {
  height: 300% !important;
}

.ht-300 {
  height: 300px;
}

.ht-350 {
  height: 350px;
}

.ht-400 {
  height: 400px;
}

.ht-450 {
  height: 450px;
}

.ht-500 {
  height: 500px;
}

.ht-550 {
  height: 550px;
}

.ht-600 {
  height: 600px;
}

.ht-650 {
  height: 650px;
}

.ht-700 {
  height: 700px;
}

.ht-750 {
  height: 750px;
}

.ht-800 {
  height: 800px;
}

.ht-100v {
  height: 100vh;
}

.ht-1 {
  height: 1px;
}

.ht-2 {
  height: 2px;
}

.ht-3 {
  height: 3px;
}

.ht-4 {
  height: 4px;
}

.ht-6 {
  height: 6px;
}

.ht-7 {
  height: 7px;
}

.ht-8 {
  height: 8px;
}

.ht-9 {
  height: 9px;
}

.ht-auto {
  height: auto;
}

.ht-16 {
  height: 16px;
}

.ht-32 {
  height: 32px;
}

.ht-36 {
  height: 36px;
}

.ht-48 {
  height: 48px;
}

.ht-64 {
  height: 64px;
}

.ht-72 {
  height: 72px;
}

.ht-115 {
  height: 115px;
}

.ht-120 {
  height: 120px;
}

.ht-160 {
  height: 160px;
}

@media (min-width: 480px) {
  .ht-xs-5 {
    height: 5px;
  }

  .ht-xs-5p {
    height: 5%;
  }

  .mx-ht-xs-5p {
    max-height: 5%;
  }

  .mn-ht-xs-5p {
    min-height: 5%;
  }

  .ht-xs-5-f {
    height: 5px !important;
  }

  .ht-xs-5p-f {
    height: 5% !important;
  }

  .ht-xs-10 {
    height: 10px;
  }

  .ht-xs-10p {
    height: 10%;
  }

  .mx-ht-xs-10p {
    max-height: 10%;
  }

  .mn-ht-xs-10p {
    min-height: 10%;
  }

  .ht-xs-10-f {
    height: 10px !important;
  }

  .ht-xs-10p-f {
    height: 10% !important;
  }

  .ht-xs-15 {
    height: 15px;
  }

  .ht-xs-15p {
    height: 15%;
  }

  .mx-ht-xs-15p {
    max-height: 15%;
  }

  .mn-ht-xs-15p {
    min-height: 15%;
  }

  .ht-xs-15-f {
    height: 15px !important;
  }

  .ht-xs-15p-f {
    height: 15% !important;
  }

  .ht-xs-20 {
    height: 20px;
  }

  .ht-xs-20p {
    height: 20%;
  }

  .mx-ht-xs-20p {
    max-height: 20%;
  }

  .mn-ht-xs-20p {
    min-height: 20%;
  }

  .ht-xs-20-f {
    height: 20px !important;
  }

  .ht-xs-20p-f {
    height: 20% !important;
  }

  .ht-xs-25 {
    height: 25px;
  }

  .ht-xs-25p {
    height: 25%;
  }

  .mx-ht-xs-25p {
    max-height: 25%;
  }

  .mn-ht-xs-25p {
    min-height: 25%;
  }

  .ht-xs-25-f {
    height: 25px !important;
  }

  .ht-xs-25p-f {
    height: 25% !important;
  }

  .ht-xs-30 {
    height: 30px;
  }

  .ht-xs-30p {
    height: 30%;
  }

  .mx-ht-xs-30p {
    max-height: 30%;
  }

  .mn-ht-xs-30p {
    min-height: 30%;
  }

  .ht-xs-30-f {
    height: 30px !important;
  }

  .ht-xs-30p-f {
    height: 30% !important;
  }

  .ht-xs-35 {
    height: 35px;
  }

  .ht-xs-35p {
    height: 35%;
  }

  .mx-ht-xs-35p {
    max-height: 35%;
  }

  .mn-ht-xs-35p {
    min-height: 35%;
  }

  .ht-xs-35-f {
    height: 35px !important;
  }

  .ht-xs-35p-f {
    height: 35% !important;
  }

  .ht-xs-40 {
    height: 40px;
  }

  .ht-xs-40p {
    height: 40%;
  }

  .mx-ht-xs-40p {
    max-height: 40%;
  }

  .mn-ht-xs-40p {
    min-height: 40%;
  }

  .ht-xs-40-f {
    height: 40px !important;
  }

  .ht-xs-40p-f {
    height: 40% !important;
  }

  .ht-xs-45 {
    height: 45px;
  }

  .ht-xs-45p {
    height: 45%;
  }

  .mx-ht-xs-45p {
    max-height: 45%;
  }

  .mn-ht-xs-45p {
    min-height: 45%;
  }

  .ht-xs-45-f {
    height: 45px !important;
  }

  .ht-xs-45p-f {
    height: 45% !important;
  }

  .ht-xs-50 {
    height: 50px;
  }

  .ht-xs-50p {
    height: 50%;
  }

  .mx-ht-xs-50p {
    max-height: 50%;
  }

  .mn-ht-xs-50p {
    min-height: 50%;
  }

  .ht-xs-50-f {
    height: 50px !important;
  }

  .ht-xs-50p-f {
    height: 50% !important;
  }

  .ht-xs-55 {
    height: 55px;
  }

  .ht-xs-55p {
    height: 55%;
  }

  .mx-ht-xs-55p {
    max-height: 55%;
  }

  .mn-ht-xs-55p {
    min-height: 55%;
  }

  .ht-xs-55-f {
    height: 55px !important;
  }

  .ht-xs-55p-f {
    height: 55% !important;
  }

  .ht-xs-60 {
    height: 60px;
  }

  .ht-xs-60p {
    height: 60%;
  }

  .mx-ht-xs-60p {
    max-height: 60%;
  }

  .mn-ht-xs-60p {
    min-height: 60%;
  }

  .ht-xs-60-f {
    height: 60px !important;
  }

  .ht-xs-60p-f {
    height: 60% !important;
  }

  .ht-xs-65 {
    height: 65px;
  }

  .ht-xs-65p {
    height: 65%;
  }

  .mx-ht-xs-65p {
    max-height: 65%;
  }

  .mn-ht-xs-65p {
    min-height: 65%;
  }

  .ht-xs-65-f {
    height: 65px !important;
  }

  .ht-xs-65p-f {
    height: 65% !important;
  }

  .ht-xs-70 {
    height: 70px;
  }

  .ht-xs-70p {
    height: 70%;
  }

  .mx-ht-xs-70p {
    max-height: 70%;
  }

  .mn-ht-xs-70p {
    min-height: 70%;
  }

  .ht-xs-70-f {
    height: 70px !important;
  }

  .ht-xs-70p-f {
    height: 70% !important;
  }

  .ht-xs-75 {
    height: 75px;
  }

  .ht-xs-75p {
    height: 75%;
  }

  .mx-ht-xs-75p {
    max-height: 75%;
  }

  .mn-ht-xs-75p {
    min-height: 75%;
  }

  .ht-xs-75-f {
    height: 75px !important;
  }

  .ht-xs-75p-f {
    height: 75% !important;
  }

  .ht-xs-80 {
    height: 80px;
  }

  .ht-xs-80p {
    height: 80%;
  }

  .mx-ht-xs-80p {
    max-height: 80%;
  }

  .mn-ht-xs-80p {
    min-height: 80%;
  }

  .ht-xs-80-f {
    height: 80px !important;
  }

  .ht-xs-80p-f {
    height: 80% !important;
  }

  .ht-xs-85 {
    height: 85px;
  }

  .ht-xs-85p {
    height: 85%;
  }

  .mx-ht-xs-85p {
    max-height: 85%;
  }

  .mn-ht-xs-85p {
    min-height: 85%;
  }

  .ht-xs-85-f {
    height: 85px !important;
  }

  .ht-xs-85p-f {
    height: 85% !important;
  }

  .ht-xs-90 {
    height: 90px;
  }

  .ht-xs-90p {
    height: 90%;
  }

  .mx-ht-xs-90p {
    max-height: 90%;
  }

  .mn-ht-xs-90p {
    min-height: 90%;
  }

  .ht-xs-90-f {
    height: 90px !important;
  }

  .ht-xs-90p-f {
    height: 90% !important;
  }

  .ht-xs-95 {
    height: 95px;
  }

  .ht-xs-95p {
    height: 95%;
  }

  .mx-ht-xs-95p {
    max-height: 95%;
  }

  .mn-ht-xs-95p {
    min-height: 95%;
  }

  .ht-xs-95-f {
    height: 95px !important;
  }

  .ht-xs-95p-f {
    height: 95% !important;
  }

  .ht-xs-100 {
    height: 100px;
  }

  .ht-xs-100p {
    height: 100%;
  }

  .mx-ht-xs-100p {
    max-height: 100%;
  }

  .mn-ht-xs-100p {
    min-height: 100%;
  }

  .ht-xs-100-f {
    height: 100px !important;
  }

  .ht-xs-100p-f {
    height: 100% !important;
  }

  .ht-xs-105 {
    height: 105px;
  }

  .ht-xs-105p {
    height: 105%;
  }

  .mx-ht-xs-105p {
    max-height: 105%;
  }

  .mn-ht-xs-105p {
    min-height: 105%;
  }

  .ht-xs-105-f {
    height: 105px !important;
  }

  .ht-xs-105p-f {
    height: 105% !important;
  }

  .ht-xs-110 {
    height: 110px;
  }

  .ht-xs-110p {
    height: 110%;
  }

  .mx-ht-xs-110p {
    max-height: 110%;
  }

  .mn-ht-xs-110p {
    min-height: 110%;
  }

  .ht-xs-110-f {
    height: 110px !important;
  }

  .ht-xs-110p-f {
    height: 110% !important;
  }

  .ht-xs-115 {
    height: 115px;
  }

  .ht-xs-115p {
    height: 115%;
  }

  .mx-ht-xs-115p {
    max-height: 115%;
  }

  .mn-ht-xs-115p {
    min-height: 115%;
  }

  .ht-xs-115-f {
    height: 115px !important;
  }

  .ht-xs-115p-f {
    height: 115% !important;
  }

  .ht-xs-120 {
    height: 120px;
  }

  .ht-xs-120p {
    height: 120%;
  }

  .mx-ht-xs-120p {
    max-height: 120%;
  }

  .mn-ht-xs-120p {
    min-height: 120%;
  }

  .ht-xs-120-f {
    height: 120px !important;
  }

  .ht-xs-120p-f {
    height: 120% !important;
  }

  .ht-xs-125 {
    height: 125px;
  }

  .ht-xs-125p {
    height: 125%;
  }

  .mx-ht-xs-125p {
    max-height: 125%;
  }

  .mn-ht-xs-125p {
    min-height: 125%;
  }

  .ht-xs-125-f {
    height: 125px !important;
  }

  .ht-xs-125p-f {
    height: 125% !important;
  }

  .ht-xs-130 {
    height: 130px;
  }

  .ht-xs-130p {
    height: 130%;
  }

  .mx-ht-xs-130p {
    max-height: 130%;
  }

  .mn-ht-xs-130p {
    min-height: 130%;
  }

  .ht-xs-130-f {
    height: 130px !important;
  }

  .ht-xs-130p-f {
    height: 130% !important;
  }

  .ht-xs-135 {
    height: 135px;
  }

  .ht-xs-135p {
    height: 135%;
  }

  .mx-ht-xs-135p {
    max-height: 135%;
  }

  .mn-ht-xs-135p {
    min-height: 135%;
  }

  .ht-xs-135-f {
    height: 135px !important;
  }

  .ht-xs-135p-f {
    height: 135% !important;
  }

  .ht-xs-140 {
    height: 140px;
  }

  .ht-xs-140p {
    height: 140%;
  }

  .mx-ht-xs-140p {
    max-height: 140%;
  }

  .mn-ht-xs-140p {
    min-height: 140%;
  }

  .ht-xs-140-f {
    height: 140px !important;
  }

  .ht-xs-140p-f {
    height: 140% !important;
  }

  .ht-xs-145 {
    height: 145px;
  }

  .ht-xs-145p {
    height: 145%;
  }

  .mx-ht-xs-145p {
    max-height: 145%;
  }

  .mn-ht-xs-145p {
    min-height: 145%;
  }

  .ht-xs-145-f {
    height: 145px !important;
  }

  .ht-xs-145p-f {
    height: 145% !important;
  }

  .ht-xs-150 {
    height: 150px;
  }

  .ht-xs-150p {
    height: 150%;
  }

  .mx-ht-xs-150p {
    max-height: 150%;
  }

  .mn-ht-xs-150p {
    min-height: 150%;
  }

  .ht-xs-150-f {
    height: 150px !important;
  }

  .ht-xs-150p-f {
    height: 150% !important;
  }

  .ht-xs-155 {
    height: 155px;
  }

  .ht-xs-155p {
    height: 155%;
  }

  .mx-ht-xs-155p {
    max-height: 155%;
  }

  .mn-ht-xs-155p {
    min-height: 155%;
  }

  .ht-xs-155-f {
    height: 155px !important;
  }

  .ht-xs-155p-f {
    height: 155% !important;
  }

  .ht-xs-160 {
    height: 160px;
  }

  .ht-xs-160p {
    height: 160%;
  }

  .mx-ht-xs-160p {
    max-height: 160%;
  }

  .mn-ht-xs-160p {
    min-height: 160%;
  }

  .ht-xs-160-f {
    height: 160px !important;
  }

  .ht-xs-160p-f {
    height: 160% !important;
  }

  .ht-xs-165 {
    height: 165px;
  }

  .ht-xs-165p {
    height: 165%;
  }

  .mx-ht-xs-165p {
    max-height: 165%;
  }

  .mn-ht-xs-165p {
    min-height: 165%;
  }

  .ht-xs-165-f {
    height: 165px !important;
  }

  .ht-xs-165p-f {
    height: 165% !important;
  }

  .ht-xs-170 {
    height: 170px;
  }

  .ht-xs-170p {
    height: 170%;
  }

  .mx-ht-xs-170p {
    max-height: 170%;
  }

  .mn-ht-xs-170p {
    min-height: 170%;
  }

  .ht-xs-170-f {
    height: 170px !important;
  }

  .ht-xs-170p-f {
    height: 170% !important;
  }

  .ht-xs-175 {
    height: 175px;
  }

  .ht-xs-175p {
    height: 175%;
  }

  .mx-ht-xs-175p {
    max-height: 175%;
  }

  .mn-ht-xs-175p {
    min-height: 175%;
  }

  .ht-xs-175-f {
    height: 175px !important;
  }

  .ht-xs-175p-f {
    height: 175% !important;
  }

  .ht-xs-180 {
    height: 180px;
  }

  .ht-xs-180p {
    height: 180%;
  }

  .mx-ht-xs-180p {
    max-height: 180%;
  }

  .mn-ht-xs-180p {
    min-height: 180%;
  }

  .ht-xs-180-f {
    height: 180px !important;
  }

  .ht-xs-180p-f {
    height: 180% !important;
  }

  .ht-xs-185 {
    height: 185px;
  }

  .ht-xs-185p {
    height: 185%;
  }

  .mx-ht-xs-185p {
    max-height: 185%;
  }

  .mn-ht-xs-185p {
    min-height: 185%;
  }

  .ht-xs-185-f {
    height: 185px !important;
  }

  .ht-xs-185p-f {
    height: 185% !important;
  }

  .ht-xs-190 {
    height: 190px;
  }

  .ht-xs-190p {
    height: 190%;
  }

  .mx-ht-xs-190p {
    max-height: 190%;
  }

  .mn-ht-xs-190p {
    min-height: 190%;
  }

  .ht-xs-190-f {
    height: 190px !important;
  }

  .ht-xs-190p-f {
    height: 190% !important;
  }

  .ht-xs-195 {
    height: 195px;
  }

  .ht-xs-195p {
    height: 195%;
  }

  .mx-ht-xs-195p {
    max-height: 195%;
  }

  .mn-ht-xs-195p {
    min-height: 195%;
  }

  .ht-xs-195-f {
    height: 195px !important;
  }

  .ht-xs-195p-f {
    height: 195% !important;
  }

  .ht-xs-200 {
    height: 200px;
  }

  .ht-xs-200p {
    height: 200%;
  }

  .mx-ht-xs-200p {
    max-height: 200%;
  }

  .mn-ht-xs-200p {
    min-height: 200%;
  }

  .ht-xs-200-f {
    height: 200px !important;
  }

  .ht-xs-200p-f {
    height: 200% !important;
  }

  .ht-xs-205 {
    height: 205px;
  }

  .ht-xs-205p {
    height: 205%;
  }

  .mx-ht-xs-205p {
    max-height: 205%;
  }

  .mn-ht-xs-205p {
    min-height: 205%;
  }

  .ht-xs-205-f {
    height: 205px !important;
  }

  .ht-xs-205p-f {
    height: 205% !important;
  }

  .ht-xs-210 {
    height: 210px;
  }

  .ht-xs-210p {
    height: 210%;
  }

  .mx-ht-xs-210p {
    max-height: 210%;
  }

  .mn-ht-xs-210p {
    min-height: 210%;
  }

  .ht-xs-210-f {
    height: 210px !important;
  }

  .ht-xs-210p-f {
    height: 210% !important;
  }

  .ht-xs-215 {
    height: 215px;
  }

  .ht-xs-215p {
    height: 215%;
  }

  .mx-ht-xs-215p {
    max-height: 215%;
  }

  .mn-ht-xs-215p {
    min-height: 215%;
  }

  .ht-xs-215-f {
    height: 215px !important;
  }

  .ht-xs-215p-f {
    height: 215% !important;
  }

  .ht-xs-220 {
    height: 220px;
  }

  .ht-xs-220p {
    height: 220%;
  }

  .mx-ht-xs-220p {
    max-height: 220%;
  }

  .mn-ht-xs-220p {
    min-height: 220%;
  }

  .ht-xs-220-f {
    height: 220px !important;
  }

  .ht-xs-220p-f {
    height: 220% !important;
  }

  .ht-xs-225 {
    height: 225px;
  }

  .ht-xs-225p {
    height: 225%;
  }

  .mx-ht-xs-225p {
    max-height: 225%;
  }

  .mn-ht-xs-225p {
    min-height: 225%;
  }

  .ht-xs-225-f {
    height: 225px !important;
  }

  .ht-xs-225p-f {
    height: 225% !important;
  }

  .ht-xs-230 {
    height: 230px;
  }

  .ht-xs-230p {
    height: 230%;
  }

  .mx-ht-xs-230p {
    max-height: 230%;
  }

  .mn-ht-xs-230p {
    min-height: 230%;
  }

  .ht-xs-230-f {
    height: 230px !important;
  }

  .ht-xs-230p-f {
    height: 230% !important;
  }

  .ht-xs-235 {
    height: 235px;
  }

  .ht-xs-235p {
    height: 235%;
  }

  .mx-ht-xs-235p {
    max-height: 235%;
  }

  .mn-ht-xs-235p {
    min-height: 235%;
  }

  .ht-xs-235-f {
    height: 235px !important;
  }

  .ht-xs-235p-f {
    height: 235% !important;
  }

  .ht-xs-240 {
    height: 240px;
  }

  .ht-xs-240p {
    height: 240%;
  }

  .mx-ht-xs-240p {
    max-height: 240%;
  }

  .mn-ht-xs-240p {
    min-height: 240%;
  }

  .ht-xs-240-f {
    height: 240px !important;
  }

  .ht-xs-240p-f {
    height: 240% !important;
  }

  .ht-xs-245 {
    height: 245px;
  }

  .ht-xs-245p {
    height: 245%;
  }

  .mx-ht-xs-245p {
    max-height: 245%;
  }

  .mn-ht-xs-245p {
    min-height: 245%;
  }

  .ht-xs-245-f {
    height: 245px !important;
  }

  .ht-xs-245p-f {
    height: 245% !important;
  }

  .ht-xs-250 {
    height: 250px;
  }

  .ht-xs-250p {
    height: 250%;
  }

  .mx-ht-xs-250p {
    max-height: 250%;
  }

  .mn-ht-xs-250p {
    min-height: 250%;
  }

  .ht-xs-250-f {
    height: 250px !important;
  }

  .ht-xs-250p-f {
    height: 250% !important;
  }

  .ht-xs-255 {
    height: 255px;
  }

  .ht-xs-255p {
    height: 255%;
  }

  .mx-ht-xs-255p {
    max-height: 255%;
  }

  .mn-ht-xs-255p {
    min-height: 255%;
  }

  .ht-xs-255-f {
    height: 255px !important;
  }

  .ht-xs-255p-f {
    height: 255% !important;
  }

  .ht-xs-260 {
    height: 260px;
  }

  .ht-xs-260p {
    height: 260%;
  }

  .mx-ht-xs-260p {
    max-height: 260%;
  }

  .mn-ht-xs-260p {
    min-height: 260%;
  }

  .ht-xs-260-f {
    height: 260px !important;
  }

  .ht-xs-260p-f {
    height: 260% !important;
  }

  .ht-xs-265 {
    height: 265px;
  }

  .ht-xs-265p {
    height: 265%;
  }

  .mx-ht-xs-265p {
    max-height: 265%;
  }

  .mn-ht-xs-265p {
    min-height: 265%;
  }

  .ht-xs-265-f {
    height: 265px !important;
  }

  .ht-xs-265p-f {
    height: 265% !important;
  }

  .ht-xs-270 {
    height: 270px;
  }

  .ht-xs-270p {
    height: 270%;
  }

  .mx-ht-xs-270p {
    max-height: 270%;
  }

  .mn-ht-xs-270p {
    min-height: 270%;
  }

  .ht-xs-270-f {
    height: 270px !important;
  }

  .ht-xs-270p-f {
    height: 270% !important;
  }

  .ht-xs-275 {
    height: 275px;
  }

  .ht-xs-275p {
    height: 275%;
  }

  .mx-ht-xs-275p {
    max-height: 275%;
  }

  .mn-ht-xs-275p {
    min-height: 275%;
  }

  .ht-xs-275-f {
    height: 275px !important;
  }

  .ht-xs-275p-f {
    height: 275% !important;
  }

  .ht-xs-280 {
    height: 280px;
  }

  .ht-xs-280p {
    height: 280%;
  }

  .mx-ht-xs-280p {
    max-height: 280%;
  }

  .mn-ht-xs-280p {
    min-height: 280%;
  }

  .ht-xs-280-f {
    height: 280px !important;
  }

  .ht-xs-280p-f {
    height: 280% !important;
  }

  .ht-xs-285 {
    height: 285px;
  }

  .ht-xs-285p {
    height: 285%;
  }

  .mx-ht-xs-285p {
    max-height: 285%;
  }

  .mn-ht-xs-285p {
    min-height: 285%;
  }

  .ht-xs-285-f {
    height: 285px !important;
  }

  .ht-xs-285p-f {
    height: 285% !important;
  }

  .ht-xs-290 {
    height: 290px;
  }

  .ht-xs-290p {
    height: 290%;
  }

  .mx-ht-xs-290p {
    max-height: 290%;
  }

  .mn-ht-xs-290p {
    min-height: 290%;
  }

  .ht-xs-290-f {
    height: 290px !important;
  }

  .ht-xs-290p-f {
    height: 290% !important;
  }

  .ht-xs-295 {
    height: 295px;
  }

  .ht-xs-295p {
    height: 295%;
  }

  .mx-ht-xs-295p {
    max-height: 295%;
  }

  .mn-ht-xs-295p {
    min-height: 295%;
  }

  .ht-xs-295-f {
    height: 295px !important;
  }

  .ht-xs-295p-f {
    height: 295% !important;
  }

  .ht-xs-300 {
    height: 300px;
  }

  .ht-xs-300p {
    height: 300%;
  }

  .mx-ht-xs-300p {
    max-height: 300%;
  }

  .mn-ht-xs-300p {
    min-height: 300%;
  }

  .ht-xs-300-f {
    height: 300px !important;
  }

  .ht-xs-300p-f {
    height: 300% !important;
  }

  .ht-xs-auto {
    height: auto;
  }

  .ht-xs-300 {
    height: 300px;
  }

  .ht-xs-300p {
    height: 300%;
  }

  .mx-ht-xs-300p {
    max-height: 300%;
  }

  .mn-ht-xs-300p {
    min-height: 300%;
  }

  .ht-xs-300-f {
    height: 300px !important;
  }

  .ht-xs-300p-f {
    height: 300% !important;
  }

  .ht-xs-350 {
    height: 350px;
  }

  .ht-xs-350p {
    height: 350%;
  }

  .mx-ht-xs-350p {
    max-height: 350%;
  }

  .mn-ht-xs-350p {
    min-height: 350%;
  }

  .ht-xs-350-f {
    height: 350px !important;
  }

  .ht-xs-350p-f {
    height: 350% !important;
  }

  .ht-xs-400 {
    height: 400px;
  }

  .ht-xs-400p {
    height: 400%;
  }

  .mx-ht-xs-400p {
    max-height: 400%;
  }

  .mn-ht-xs-400p {
    min-height: 400%;
  }

  .ht-xs-400-f {
    height: 400px !important;
  }

  .ht-xs-400p-f {
    height: 400% !important;
  }

  .ht-xs-450 {
    height: 450px;
  }

  .ht-xs-450p {
    height: 450%;
  }

  .mx-ht-xs-450p {
    max-height: 450%;
  }

  .mn-ht-xs-450p {
    min-height: 450%;
  }

  .ht-xs-450-f {
    height: 450px !important;
  }

  .ht-xs-450p-f {
    height: 450% !important;
  }

  .ht-xs-500 {
    height: 500px;
  }

  .ht-xs-500p {
    height: 500%;
  }

  .mx-ht-xs-500p {
    max-height: 500%;
  }

  .mn-ht-xs-500p {
    min-height: 500%;
  }

  .ht-xs-500-f {
    height: 500px !important;
  }

  .ht-xs-500p-f {
    height: 500% !important;
  }

  .ht-xs-550 {
    height: 550px;
  }

  .ht-xs-550p {
    height: 550%;
  }

  .mx-ht-xs-550p {
    max-height: 550%;
  }

  .mn-ht-xs-550p {
    min-height: 550%;
  }

  .ht-xs-550-f {
    height: 550px !important;
  }

  .ht-xs-550p-f {
    height: 550% !important;
  }

  .ht-xs-600 {
    height: 600px;
  }

  .ht-xs-600p {
    height: 600%;
  }

  .mx-ht-xs-600p {
    max-height: 600%;
  }

  .mn-ht-xs-600p {
    min-height: 600%;
  }

  .ht-xs-600-f {
    height: 600px !important;
  }

  .ht-xs-600p-f {
    height: 600% !important;
  }

  .ht-xs-650 {
    height: 650px;
  }

  .ht-xs-650p {
    height: 650%;
  }

  .mx-ht-xs-650p {
    max-height: 650%;
  }

  .mn-ht-xs-650p {
    min-height: 650%;
  }

  .ht-xs-650-f {
    height: 650px !important;
  }

  .ht-xs-650p-f {
    height: 650% !important;
  }

  .ht-xs-700 {
    height: 700px;
  }

  .ht-xs-700p {
    height: 700%;
  }

  .mx-ht-xs-700p {
    max-height: 700%;
  }

  .mn-ht-xs-700p {
    min-height: 700%;
  }

  .ht-xs-700-f {
    height: 700px !important;
  }

  .ht-xs-700p-f {
    height: 700% !important;
  }

  .ht-xs-750 {
    height: 750px;
  }

  .ht-xs-750p {
    height: 750%;
  }

  .mx-ht-xs-750p {
    max-height: 750%;
  }

  .mn-ht-xs-750p {
    min-height: 750%;
  }

  .ht-xs-750-f {
    height: 750px !important;
  }

  .ht-xs-750p-f {
    height: 750% !important;
  }

  .ht-xs-800 {
    height: 800px;
  }

  .ht-xs-800p {
    height: 800%;
  }

  .mx-ht-xs-800p {
    max-height: 800%;
  }

  .mn-ht-xs-800p {
    min-height: 800%;
  }

  .ht-xs-800-f {
    height: 800px !important;
  }

  .ht-xs-800p-f {
    height: 800% !important;
  }

  .ht-xs-850 {
    height: 850px;
  }

  .ht-xs-850p {
    height: 850%;
  }

  .mx-ht-xs-850p {
    max-height: 850%;
  }

  .mn-ht-xs-850p {
    min-height: 850%;
  }

  .ht-xs-850-f {
    height: 850px !important;
  }

  .ht-xs-850p-f {
    height: 850% !important;
  }

  .ht-xs-100v {
    height: 100vh;
  }
}
@media (min-width: 576px) {
  .ht-sm-5 {
    height: 5px;
  }

  .ht-sm-5p {
    height: 5%;
  }

  .mx-ht-sm-5p {
    max-height: 5%;
  }

  .mn-ht-sm-5p {
    min-height: 5%;
  }

  .ht-sm-5-f {
    height: 5px !important;
  }

  .ht-sm-5p-f {
    height: 5% !important;
  }

  .ht-sm-10 {
    height: 10px;
  }

  .ht-sm-10p {
    height: 10%;
  }

  .mx-ht-sm-10p {
    max-height: 10%;
  }

  .mn-ht-sm-10p {
    min-height: 10%;
  }

  .ht-sm-10-f {
    height: 10px !important;
  }

  .ht-sm-10p-f {
    height: 10% !important;
  }

  .ht-sm-15 {
    height: 15px;
  }

  .ht-sm-15p {
    height: 15%;
  }

  .mx-ht-sm-15p {
    max-height: 15%;
  }

  .mn-ht-sm-15p {
    min-height: 15%;
  }

  .ht-sm-15-f {
    height: 15px !important;
  }

  .ht-sm-15p-f {
    height: 15% !important;
  }

  .ht-sm-20 {
    height: 20px;
  }

  .ht-sm-20p {
    height: 20%;
  }

  .mx-ht-sm-20p {
    max-height: 20%;
  }

  .mn-ht-sm-20p {
    min-height: 20%;
  }

  .ht-sm-20-f {
    height: 20px !important;
  }

  .ht-sm-20p-f {
    height: 20% !important;
  }

  .ht-sm-25 {
    height: 25px;
  }

  .ht-sm-25p {
    height: 25%;
  }

  .mx-ht-sm-25p {
    max-height: 25%;
  }

  .mn-ht-sm-25p {
    min-height: 25%;
  }

  .ht-sm-25-f {
    height: 25px !important;
  }

  .ht-sm-25p-f {
    height: 25% !important;
  }

  .ht-sm-30 {
    height: 30px;
  }

  .ht-sm-30p {
    height: 30%;
  }

  .mx-ht-sm-30p {
    max-height: 30%;
  }

  .mn-ht-sm-30p {
    min-height: 30%;
  }

  .ht-sm-30-f {
    height: 30px !important;
  }

  .ht-sm-30p-f {
    height: 30% !important;
  }

  .ht-sm-35 {
    height: 35px;
  }

  .ht-sm-35p {
    height: 35%;
  }

  .mx-ht-sm-35p {
    max-height: 35%;
  }

  .mn-ht-sm-35p {
    min-height: 35%;
  }

  .ht-sm-35-f {
    height: 35px !important;
  }

  .ht-sm-35p-f {
    height: 35% !important;
  }

  .ht-sm-40 {
    height: 40px;
  }

  .ht-sm-40p {
    height: 40%;
  }

  .mx-ht-sm-40p {
    max-height: 40%;
  }

  .mn-ht-sm-40p {
    min-height: 40%;
  }

  .ht-sm-40-f {
    height: 40px !important;
  }

  .ht-sm-40p-f {
    height: 40% !important;
  }

  .ht-sm-45 {
    height: 45px;
  }

  .ht-sm-45p {
    height: 45%;
  }

  .mx-ht-sm-45p {
    max-height: 45%;
  }

  .mn-ht-sm-45p {
    min-height: 45%;
  }

  .ht-sm-45-f {
    height: 45px !important;
  }

  .ht-sm-45p-f {
    height: 45% !important;
  }

  .ht-sm-50 {
    height: 50px;
  }

  .ht-sm-50p {
    height: 50%;
  }

  .mx-ht-sm-50p {
    max-height: 50%;
  }

  .mn-ht-sm-50p {
    min-height: 50%;
  }

  .ht-sm-50-f {
    height: 50px !important;
  }

  .ht-sm-50p-f {
    height: 50% !important;
  }

  .ht-sm-55 {
    height: 55px;
  }

  .ht-sm-55p {
    height: 55%;
  }

  .mx-ht-sm-55p {
    max-height: 55%;
  }

  .mn-ht-sm-55p {
    min-height: 55%;
  }

  .ht-sm-55-f {
    height: 55px !important;
  }

  .ht-sm-55p-f {
    height: 55% !important;
  }

  .ht-sm-60 {
    height: 60px;
  }

  .ht-sm-60p {
    height: 60%;
  }

  .mx-ht-sm-60p {
    max-height: 60%;
  }

  .mn-ht-sm-60p {
    min-height: 60%;
  }

  .ht-sm-60-f {
    height: 60px !important;
  }

  .ht-sm-60p-f {
    height: 60% !important;
  }

  .ht-sm-65 {
    height: 65px;
  }

  .ht-sm-65p {
    height: 65%;
  }

  .mx-ht-sm-65p {
    max-height: 65%;
  }

  .mn-ht-sm-65p {
    min-height: 65%;
  }

  .ht-sm-65-f {
    height: 65px !important;
  }

  .ht-sm-65p-f {
    height: 65% !important;
  }

  .ht-sm-70 {
    height: 70px;
  }

  .ht-sm-70p {
    height: 70%;
  }

  .mx-ht-sm-70p {
    max-height: 70%;
  }

  .mn-ht-sm-70p {
    min-height: 70%;
  }

  .ht-sm-70-f {
    height: 70px !important;
  }

  .ht-sm-70p-f {
    height: 70% !important;
  }

  .ht-sm-75 {
    height: 75px;
  }

  .ht-sm-75p {
    height: 75%;
  }

  .mx-ht-sm-75p {
    max-height: 75%;
  }

  .mn-ht-sm-75p {
    min-height: 75%;
  }

  .ht-sm-75-f {
    height: 75px !important;
  }

  .ht-sm-75p-f {
    height: 75% !important;
  }

  .ht-sm-80 {
    height: 80px;
  }

  .ht-sm-80p {
    height: 80%;
  }

  .mx-ht-sm-80p {
    max-height: 80%;
  }

  .mn-ht-sm-80p {
    min-height: 80%;
  }

  .ht-sm-80-f {
    height: 80px !important;
  }

  .ht-sm-80p-f {
    height: 80% !important;
  }

  .ht-sm-85 {
    height: 85px;
  }

  .ht-sm-85p {
    height: 85%;
  }

  .mx-ht-sm-85p {
    max-height: 85%;
  }

  .mn-ht-sm-85p {
    min-height: 85%;
  }

  .ht-sm-85-f {
    height: 85px !important;
  }

  .ht-sm-85p-f {
    height: 85% !important;
  }

  .ht-sm-90 {
    height: 90px;
  }

  .ht-sm-90p {
    height: 90%;
  }

  .mx-ht-sm-90p {
    max-height: 90%;
  }

  .mn-ht-sm-90p {
    min-height: 90%;
  }

  .ht-sm-90-f {
    height: 90px !important;
  }

  .ht-sm-90p-f {
    height: 90% !important;
  }

  .ht-sm-95 {
    height: 95px;
  }

  .ht-sm-95p {
    height: 95%;
  }

  .mx-ht-sm-95p {
    max-height: 95%;
  }

  .mn-ht-sm-95p {
    min-height: 95%;
  }

  .ht-sm-95-f {
    height: 95px !important;
  }

  .ht-sm-95p-f {
    height: 95% !important;
  }

  .ht-sm-100 {
    height: 100px;
  }

  .ht-sm-100p {
    height: 100%;
  }

  .mx-ht-sm-100p {
    max-height: 100%;
  }

  .mn-ht-sm-100p {
    min-height: 100%;
  }

  .ht-sm-100-f {
    height: 100px !important;
  }

  .ht-sm-100p-f {
    height: 100% !important;
  }

  .ht-sm-105 {
    height: 105px;
  }

  .ht-sm-105p {
    height: 105%;
  }

  .mx-ht-sm-105p {
    max-height: 105%;
  }

  .mn-ht-sm-105p {
    min-height: 105%;
  }

  .ht-sm-105-f {
    height: 105px !important;
  }

  .ht-sm-105p-f {
    height: 105% !important;
  }

  .ht-sm-110 {
    height: 110px;
  }

  .ht-sm-110p {
    height: 110%;
  }

  .mx-ht-sm-110p {
    max-height: 110%;
  }

  .mn-ht-sm-110p {
    min-height: 110%;
  }

  .ht-sm-110-f {
    height: 110px !important;
  }

  .ht-sm-110p-f {
    height: 110% !important;
  }

  .ht-sm-115 {
    height: 115px;
  }

  .ht-sm-115p {
    height: 115%;
  }

  .mx-ht-sm-115p {
    max-height: 115%;
  }

  .mn-ht-sm-115p {
    min-height: 115%;
  }

  .ht-sm-115-f {
    height: 115px !important;
  }

  .ht-sm-115p-f {
    height: 115% !important;
  }

  .ht-sm-120 {
    height: 120px;
  }

  .ht-sm-120p {
    height: 120%;
  }

  .mx-ht-sm-120p {
    max-height: 120%;
  }

  .mn-ht-sm-120p {
    min-height: 120%;
  }

  .ht-sm-120-f {
    height: 120px !important;
  }

  .ht-sm-120p-f {
    height: 120% !important;
  }

  .ht-sm-125 {
    height: 125px;
  }

  .ht-sm-125p {
    height: 125%;
  }

  .mx-ht-sm-125p {
    max-height: 125%;
  }

  .mn-ht-sm-125p {
    min-height: 125%;
  }

  .ht-sm-125-f {
    height: 125px !important;
  }

  .ht-sm-125p-f {
    height: 125% !important;
  }

  .ht-sm-130 {
    height: 130px;
  }

  .ht-sm-130p {
    height: 130%;
  }

  .mx-ht-sm-130p {
    max-height: 130%;
  }

  .mn-ht-sm-130p {
    min-height: 130%;
  }

  .ht-sm-130-f {
    height: 130px !important;
  }

  .ht-sm-130p-f {
    height: 130% !important;
  }

  .ht-sm-135 {
    height: 135px;
  }

  .ht-sm-135p {
    height: 135%;
  }

  .mx-ht-sm-135p {
    max-height: 135%;
  }

  .mn-ht-sm-135p {
    min-height: 135%;
  }

  .ht-sm-135-f {
    height: 135px !important;
  }

  .ht-sm-135p-f {
    height: 135% !important;
  }

  .ht-sm-140 {
    height: 140px;
  }

  .ht-sm-140p {
    height: 140%;
  }

  .mx-ht-sm-140p {
    max-height: 140%;
  }

  .mn-ht-sm-140p {
    min-height: 140%;
  }

  .ht-sm-140-f {
    height: 140px !important;
  }

  .ht-sm-140p-f {
    height: 140% !important;
  }

  .ht-sm-145 {
    height: 145px;
  }

  .ht-sm-145p {
    height: 145%;
  }

  .mx-ht-sm-145p {
    max-height: 145%;
  }

  .mn-ht-sm-145p {
    min-height: 145%;
  }

  .ht-sm-145-f {
    height: 145px !important;
  }

  .ht-sm-145p-f {
    height: 145% !important;
  }

  .ht-sm-150 {
    height: 150px;
  }

  .ht-sm-150p {
    height: 150%;
  }

  .mx-ht-sm-150p {
    max-height: 150%;
  }

  .mn-ht-sm-150p {
    min-height: 150%;
  }

  .ht-sm-150-f {
    height: 150px !important;
  }

  .ht-sm-150p-f {
    height: 150% !important;
  }

  .ht-sm-155 {
    height: 155px;
  }

  .ht-sm-155p {
    height: 155%;
  }

  .mx-ht-sm-155p {
    max-height: 155%;
  }

  .mn-ht-sm-155p {
    min-height: 155%;
  }

  .ht-sm-155-f {
    height: 155px !important;
  }

  .ht-sm-155p-f {
    height: 155% !important;
  }

  .ht-sm-160 {
    height: 160px;
  }

  .ht-sm-160p {
    height: 160%;
  }

  .mx-ht-sm-160p {
    max-height: 160%;
  }

  .mn-ht-sm-160p {
    min-height: 160%;
  }

  .ht-sm-160-f {
    height: 160px !important;
  }

  .ht-sm-160p-f {
    height: 160% !important;
  }

  .ht-sm-165 {
    height: 165px;
  }

  .ht-sm-165p {
    height: 165%;
  }

  .mx-ht-sm-165p {
    max-height: 165%;
  }

  .mn-ht-sm-165p {
    min-height: 165%;
  }

  .ht-sm-165-f {
    height: 165px !important;
  }

  .ht-sm-165p-f {
    height: 165% !important;
  }

  .ht-sm-170 {
    height: 170px;
  }

  .ht-sm-170p {
    height: 170%;
  }

  .mx-ht-sm-170p {
    max-height: 170%;
  }

  .mn-ht-sm-170p {
    min-height: 170%;
  }

  .ht-sm-170-f {
    height: 170px !important;
  }

  .ht-sm-170p-f {
    height: 170% !important;
  }

  .ht-sm-175 {
    height: 175px;
  }

  .ht-sm-175p {
    height: 175%;
  }

  .mx-ht-sm-175p {
    max-height: 175%;
  }

  .mn-ht-sm-175p {
    min-height: 175%;
  }

  .ht-sm-175-f {
    height: 175px !important;
  }

  .ht-sm-175p-f {
    height: 175% !important;
  }

  .ht-sm-180 {
    height: 180px;
  }

  .ht-sm-180p {
    height: 180%;
  }

  .mx-ht-sm-180p {
    max-height: 180%;
  }

  .mn-ht-sm-180p {
    min-height: 180%;
  }

  .ht-sm-180-f {
    height: 180px !important;
  }

  .ht-sm-180p-f {
    height: 180% !important;
  }

  .ht-sm-185 {
    height: 185px;
  }

  .ht-sm-185p {
    height: 185%;
  }

  .mx-ht-sm-185p {
    max-height: 185%;
  }

  .mn-ht-sm-185p {
    min-height: 185%;
  }

  .ht-sm-185-f {
    height: 185px !important;
  }

  .ht-sm-185p-f {
    height: 185% !important;
  }

  .ht-sm-190 {
    height: 190px;
  }

  .ht-sm-190p {
    height: 190%;
  }

  .mx-ht-sm-190p {
    max-height: 190%;
  }

  .mn-ht-sm-190p {
    min-height: 190%;
  }

  .ht-sm-190-f {
    height: 190px !important;
  }

  .ht-sm-190p-f {
    height: 190% !important;
  }

  .ht-sm-195 {
    height: 195px;
  }

  .ht-sm-195p {
    height: 195%;
  }

  .mx-ht-sm-195p {
    max-height: 195%;
  }

  .mn-ht-sm-195p {
    min-height: 195%;
  }

  .ht-sm-195-f {
    height: 195px !important;
  }

  .ht-sm-195p-f {
    height: 195% !important;
  }

  .ht-sm-200 {
    height: 200px;
  }

  .ht-sm-200p {
    height: 200%;
  }

  .mx-ht-sm-200p {
    max-height: 200%;
  }

  .mn-ht-sm-200p {
    min-height: 200%;
  }

  .ht-sm-200-f {
    height: 200px !important;
  }

  .ht-sm-200p-f {
    height: 200% !important;
  }

  .ht-sm-205 {
    height: 205px;
  }

  .ht-sm-205p {
    height: 205%;
  }

  .mx-ht-sm-205p {
    max-height: 205%;
  }

  .mn-ht-sm-205p {
    min-height: 205%;
  }

  .ht-sm-205-f {
    height: 205px !important;
  }

  .ht-sm-205p-f {
    height: 205% !important;
  }

  .ht-sm-210 {
    height: 210px;
  }

  .ht-sm-210p {
    height: 210%;
  }

  .mx-ht-sm-210p {
    max-height: 210%;
  }

  .mn-ht-sm-210p {
    min-height: 210%;
  }

  .ht-sm-210-f {
    height: 210px !important;
  }

  .ht-sm-210p-f {
    height: 210% !important;
  }

  .ht-sm-215 {
    height: 215px;
  }

  .ht-sm-215p {
    height: 215%;
  }

  .mx-ht-sm-215p {
    max-height: 215%;
  }

  .mn-ht-sm-215p {
    min-height: 215%;
  }

  .ht-sm-215-f {
    height: 215px !important;
  }

  .ht-sm-215p-f {
    height: 215% !important;
  }

  .ht-sm-220 {
    height: 220px;
  }

  .ht-sm-220p {
    height: 220%;
  }

  .mx-ht-sm-220p {
    max-height: 220%;
  }

  .mn-ht-sm-220p {
    min-height: 220%;
  }

  .ht-sm-220-f {
    height: 220px !important;
  }

  .ht-sm-220p-f {
    height: 220% !important;
  }

  .ht-sm-225 {
    height: 225px;
  }

  .ht-sm-225p {
    height: 225%;
  }

  .mx-ht-sm-225p {
    max-height: 225%;
  }

  .mn-ht-sm-225p {
    min-height: 225%;
  }

  .ht-sm-225-f {
    height: 225px !important;
  }

  .ht-sm-225p-f {
    height: 225% !important;
  }

  .ht-sm-230 {
    height: 230px;
  }

  .ht-sm-230p {
    height: 230%;
  }

  .mx-ht-sm-230p {
    max-height: 230%;
  }

  .mn-ht-sm-230p {
    min-height: 230%;
  }

  .ht-sm-230-f {
    height: 230px !important;
  }

  .ht-sm-230p-f {
    height: 230% !important;
  }

  .ht-sm-235 {
    height: 235px;
  }

  .ht-sm-235p {
    height: 235%;
  }

  .mx-ht-sm-235p {
    max-height: 235%;
  }

  .mn-ht-sm-235p {
    min-height: 235%;
  }

  .ht-sm-235-f {
    height: 235px !important;
  }

  .ht-sm-235p-f {
    height: 235% !important;
  }

  .ht-sm-240 {
    height: 240px;
  }

  .ht-sm-240p {
    height: 240%;
  }

  .mx-ht-sm-240p {
    max-height: 240%;
  }

  .mn-ht-sm-240p {
    min-height: 240%;
  }

  .ht-sm-240-f {
    height: 240px !important;
  }

  .ht-sm-240p-f {
    height: 240% !important;
  }

  .ht-sm-245 {
    height: 245px;
  }

  .ht-sm-245p {
    height: 245%;
  }

  .mx-ht-sm-245p {
    max-height: 245%;
  }

  .mn-ht-sm-245p {
    min-height: 245%;
  }

  .ht-sm-245-f {
    height: 245px !important;
  }

  .ht-sm-245p-f {
    height: 245% !important;
  }

  .ht-sm-250 {
    height: 250px;
  }

  .ht-sm-250p {
    height: 250%;
  }

  .mx-ht-sm-250p {
    max-height: 250%;
  }

  .mn-ht-sm-250p {
    min-height: 250%;
  }

  .ht-sm-250-f {
    height: 250px !important;
  }

  .ht-sm-250p-f {
    height: 250% !important;
  }

  .ht-sm-255 {
    height: 255px;
  }

  .ht-sm-255p {
    height: 255%;
  }

  .mx-ht-sm-255p {
    max-height: 255%;
  }

  .mn-ht-sm-255p {
    min-height: 255%;
  }

  .ht-sm-255-f {
    height: 255px !important;
  }

  .ht-sm-255p-f {
    height: 255% !important;
  }

  .ht-sm-260 {
    height: 260px;
  }

  .ht-sm-260p {
    height: 260%;
  }

  .mx-ht-sm-260p {
    max-height: 260%;
  }

  .mn-ht-sm-260p {
    min-height: 260%;
  }

  .ht-sm-260-f {
    height: 260px !important;
  }

  .ht-sm-260p-f {
    height: 260% !important;
  }

  .ht-sm-265 {
    height: 265px;
  }

  .ht-sm-265p {
    height: 265%;
  }

  .mx-ht-sm-265p {
    max-height: 265%;
  }

  .mn-ht-sm-265p {
    min-height: 265%;
  }

  .ht-sm-265-f {
    height: 265px !important;
  }

  .ht-sm-265p-f {
    height: 265% !important;
  }

  .ht-sm-270 {
    height: 270px;
  }

  .ht-sm-270p {
    height: 270%;
  }

  .mx-ht-sm-270p {
    max-height: 270%;
  }

  .mn-ht-sm-270p {
    min-height: 270%;
  }

  .ht-sm-270-f {
    height: 270px !important;
  }

  .ht-sm-270p-f {
    height: 270% !important;
  }

  .ht-sm-275 {
    height: 275px;
  }

  .ht-sm-275p {
    height: 275%;
  }

  .mx-ht-sm-275p {
    max-height: 275%;
  }

  .mn-ht-sm-275p {
    min-height: 275%;
  }

  .ht-sm-275-f {
    height: 275px !important;
  }

  .ht-sm-275p-f {
    height: 275% !important;
  }

  .ht-sm-280 {
    height: 280px;
  }

  .ht-sm-280p {
    height: 280%;
  }

  .mx-ht-sm-280p {
    max-height: 280%;
  }

  .mn-ht-sm-280p {
    min-height: 280%;
  }

  .ht-sm-280-f {
    height: 280px !important;
  }

  .ht-sm-280p-f {
    height: 280% !important;
  }

  .ht-sm-285 {
    height: 285px;
  }

  .ht-sm-285p {
    height: 285%;
  }

  .mx-ht-sm-285p {
    max-height: 285%;
  }

  .mn-ht-sm-285p {
    min-height: 285%;
  }

  .ht-sm-285-f {
    height: 285px !important;
  }

  .ht-sm-285p-f {
    height: 285% !important;
  }

  .ht-sm-290 {
    height: 290px;
  }

  .ht-sm-290p {
    height: 290%;
  }

  .mx-ht-sm-290p {
    max-height: 290%;
  }

  .mn-ht-sm-290p {
    min-height: 290%;
  }

  .ht-sm-290-f {
    height: 290px !important;
  }

  .ht-sm-290p-f {
    height: 290% !important;
  }

  .ht-sm-295 {
    height: 295px;
  }

  .ht-sm-295p {
    height: 295%;
  }

  .mx-ht-sm-295p {
    max-height: 295%;
  }

  .mn-ht-sm-295p {
    min-height: 295%;
  }

  .ht-sm-295-f {
    height: 295px !important;
  }

  .ht-sm-295p-f {
    height: 295% !important;
  }

  .ht-sm-300 {
    height: 300px;
  }

  .ht-sm-300p {
    height: 300%;
  }

  .mx-ht-sm-300p {
    max-height: 300%;
  }

  .mn-ht-sm-300p {
    min-height: 300%;
  }

  .ht-sm-300-f {
    height: 300px !important;
  }

  .ht-sm-300p-f {
    height: 300% !important;
  }

  .ht-sm-auto {
    height: auto;
  }

  .ht-sm-300 {
    height: 300px;
  }

  .ht-sm-300p {
    height: 300%;
  }

  .mx-ht-sm-300p {
    max-height: 300%;
  }

  .mn-ht-sm-300p {
    min-height: 300%;
  }

  .ht-sm-300-f {
    height: 300px !important;
  }

  .ht-sm-300p-f {
    height: 300% !important;
  }

  .ht-sm-350 {
    height: 350px;
  }

  .ht-sm-350p {
    height: 350%;
  }

  .mx-ht-sm-350p {
    max-height: 350%;
  }

  .mn-ht-sm-350p {
    min-height: 350%;
  }

  .ht-sm-350-f {
    height: 350px !important;
  }

  .ht-sm-350p-f {
    height: 350% !important;
  }

  .ht-sm-400 {
    height: 400px;
  }

  .ht-sm-400p {
    height: 400%;
  }

  .mx-ht-sm-400p {
    max-height: 400%;
  }

  .mn-ht-sm-400p {
    min-height: 400%;
  }

  .ht-sm-400-f {
    height: 400px !important;
  }

  .ht-sm-400p-f {
    height: 400% !important;
  }

  .ht-sm-450 {
    height: 450px;
  }

  .ht-sm-450p {
    height: 450%;
  }

  .mx-ht-sm-450p {
    max-height: 450%;
  }

  .mn-ht-sm-450p {
    min-height: 450%;
  }

  .ht-sm-450-f {
    height: 450px !important;
  }

  .ht-sm-450p-f {
    height: 450% !important;
  }

  .ht-sm-500 {
    height: 500px;
  }

  .ht-sm-500p {
    height: 500%;
  }

  .mx-ht-sm-500p {
    max-height: 500%;
  }

  .mn-ht-sm-500p {
    min-height: 500%;
  }

  .ht-sm-500-f {
    height: 500px !important;
  }

  .ht-sm-500p-f {
    height: 500% !important;
  }

  .ht-sm-550 {
    height: 550px;
  }

  .ht-sm-550p {
    height: 550%;
  }

  .mx-ht-sm-550p {
    max-height: 550%;
  }

  .mn-ht-sm-550p {
    min-height: 550%;
  }

  .ht-sm-550-f {
    height: 550px !important;
  }

  .ht-sm-550p-f {
    height: 550% !important;
  }

  .ht-sm-600 {
    height: 600px;
  }

  .ht-sm-600p {
    height: 600%;
  }

  .mx-ht-sm-600p {
    max-height: 600%;
  }

  .mn-ht-sm-600p {
    min-height: 600%;
  }

  .ht-sm-600-f {
    height: 600px !important;
  }

  .ht-sm-600p-f {
    height: 600% !important;
  }

  .ht-sm-650 {
    height: 650px;
  }

  .ht-sm-650p {
    height: 650%;
  }

  .mx-ht-sm-650p {
    max-height: 650%;
  }

  .mn-ht-sm-650p {
    min-height: 650%;
  }

  .ht-sm-650-f {
    height: 650px !important;
  }

  .ht-sm-650p-f {
    height: 650% !important;
  }

  .ht-sm-700 {
    height: 700px;
  }

  .ht-sm-700p {
    height: 700%;
  }

  .mx-ht-sm-700p {
    max-height: 700%;
  }

  .mn-ht-sm-700p {
    min-height: 700%;
  }

  .ht-sm-700-f {
    height: 700px !important;
  }

  .ht-sm-700p-f {
    height: 700% !important;
  }

  .ht-sm-750 {
    height: 750px;
  }

  .ht-sm-750p {
    height: 750%;
  }

  .mx-ht-sm-750p {
    max-height: 750%;
  }

  .mn-ht-sm-750p {
    min-height: 750%;
  }

  .ht-sm-750-f {
    height: 750px !important;
  }

  .ht-sm-750p-f {
    height: 750% !important;
  }

  .ht-sm-800 {
    height: 800px;
  }

  .ht-sm-800p {
    height: 800%;
  }

  .mx-ht-sm-800p {
    max-height: 800%;
  }

  .mn-ht-sm-800p {
    min-height: 800%;
  }

  .ht-sm-800-f {
    height: 800px !important;
  }

  .ht-sm-800p-f {
    height: 800% !important;
  }

  .ht-sm-850 {
    height: 850px;
  }

  .ht-sm-850p {
    height: 850%;
  }

  .mx-ht-sm-850p {
    max-height: 850%;
  }

  .mn-ht-sm-850p {
    min-height: 850%;
  }

  .ht-sm-850-f {
    height: 850px !important;
  }

  .ht-sm-850p-f {
    height: 850% !important;
  }

  .ht-sm-100v {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .ht-md-5 {
    height: 5px;
  }

  .ht-md-5p {
    height: 5%;
  }

  .mx-ht-md-5p {
    max-height: 5%;
  }

  .mn-ht-md-5p {
    min-height: 5%;
  }

  .ht-md-5-f {
    height: 5px !important;
  }

  .ht-md-5p-f {
    height: 5% !important;
  }

  .ht-md-10 {
    height: 10px;
  }

  .ht-md-10p {
    height: 10%;
  }

  .mx-ht-md-10p {
    max-height: 10%;
  }

  .mn-ht-md-10p {
    min-height: 10%;
  }

  .ht-md-10-f {
    height: 10px !important;
  }

  .ht-md-10p-f {
    height: 10% !important;
  }

  .ht-md-15 {
    height: 15px;
  }

  .ht-md-15p {
    height: 15%;
  }

  .mx-ht-md-15p {
    max-height: 15%;
  }

  .mn-ht-md-15p {
    min-height: 15%;
  }

  .ht-md-15-f {
    height: 15px !important;
  }

  .ht-md-15p-f {
    height: 15% !important;
  }

  .ht-md-20 {
    height: 20px;
  }

  .ht-md-20p {
    height: 20%;
  }

  .mx-ht-md-20p {
    max-height: 20%;
  }

  .mn-ht-md-20p {
    min-height: 20%;
  }

  .ht-md-20-f {
    height: 20px !important;
  }

  .ht-md-20p-f {
    height: 20% !important;
  }

  .ht-md-25 {
    height: 25px;
  }

  .ht-md-25p {
    height: 25%;
  }

  .mx-ht-md-25p {
    max-height: 25%;
  }

  .mn-ht-md-25p {
    min-height: 25%;
  }

  .ht-md-25-f {
    height: 25px !important;
  }

  .ht-md-25p-f {
    height: 25% !important;
  }

  .ht-md-30 {
    height: 30px;
  }

  .ht-md-30p {
    height: 30%;
  }

  .mx-ht-md-30p {
    max-height: 30%;
  }

  .mn-ht-md-30p {
    min-height: 30%;
  }

  .ht-md-30-f {
    height: 30px !important;
  }

  .ht-md-30p-f {
    height: 30% !important;
  }

  .ht-md-35 {
    height: 35px;
  }

  .ht-md-35p {
    height: 35%;
  }

  .mx-ht-md-35p {
    max-height: 35%;
  }

  .mn-ht-md-35p {
    min-height: 35%;
  }

  .ht-md-35-f {
    height: 35px !important;
  }

  .ht-md-35p-f {
    height: 35% !important;
  }

  .ht-md-40 {
    height: 40px;
  }

  .ht-md-40p {
    height: 40%;
  }

  .mx-ht-md-40p {
    max-height: 40%;
  }

  .mn-ht-md-40p {
    min-height: 40%;
  }

  .ht-md-40-f {
    height: 40px !important;
  }

  .ht-md-40p-f {
    height: 40% !important;
  }

  .ht-md-45 {
    height: 45px;
  }

  .ht-md-45p {
    height: 45%;
  }

  .mx-ht-md-45p {
    max-height: 45%;
  }

  .mn-ht-md-45p {
    min-height: 45%;
  }

  .ht-md-45-f {
    height: 45px !important;
  }

  .ht-md-45p-f {
    height: 45% !important;
  }

  .ht-md-50 {
    height: 50px;
  }

  .ht-md-50p {
    height: 50%;
  }

  .mx-ht-md-50p {
    max-height: 50%;
  }

  .mn-ht-md-50p {
    min-height: 50%;
  }

  .ht-md-50-f {
    height: 50px !important;
  }

  .ht-md-50p-f {
    height: 50% !important;
  }

  .ht-md-55 {
    height: 55px;
  }

  .ht-md-55p {
    height: 55%;
  }

  .mx-ht-md-55p {
    max-height: 55%;
  }

  .mn-ht-md-55p {
    min-height: 55%;
  }

  .ht-md-55-f {
    height: 55px !important;
  }

  .ht-md-55p-f {
    height: 55% !important;
  }

  .ht-md-60 {
    height: 60px;
  }

  .ht-md-60p {
    height: 60%;
  }

  .mx-ht-md-60p {
    max-height: 60%;
  }

  .mn-ht-md-60p {
    min-height: 60%;
  }

  .ht-md-60-f {
    height: 60px !important;
  }

  .ht-md-60p-f {
    height: 60% !important;
  }

  .ht-md-65 {
    height: 65px;
  }

  .ht-md-65p {
    height: 65%;
  }

  .mx-ht-md-65p {
    max-height: 65%;
  }

  .mn-ht-md-65p {
    min-height: 65%;
  }

  .ht-md-65-f {
    height: 65px !important;
  }

  .ht-md-65p-f {
    height: 65% !important;
  }

  .ht-md-70 {
    height: 70px;
  }

  .ht-md-70p {
    height: 70%;
  }

  .mx-ht-md-70p {
    max-height: 70%;
  }

  .mn-ht-md-70p {
    min-height: 70%;
  }

  .ht-md-70-f {
    height: 70px !important;
  }

  .ht-md-70p-f {
    height: 70% !important;
  }

  .ht-md-75 {
    height: 75px;
  }

  .ht-md-75p {
    height: 75%;
  }

  .mx-ht-md-75p {
    max-height: 75%;
  }

  .mn-ht-md-75p {
    min-height: 75%;
  }

  .ht-md-75-f {
    height: 75px !important;
  }

  .ht-md-75p-f {
    height: 75% !important;
  }

  .ht-md-80 {
    height: 80px;
  }

  .ht-md-80p {
    height: 80%;
  }

  .mx-ht-md-80p {
    max-height: 80%;
  }

  .mn-ht-md-80p {
    min-height: 80%;
  }

  .ht-md-80-f {
    height: 80px !important;
  }

  .ht-md-80p-f {
    height: 80% !important;
  }

  .ht-md-85 {
    height: 85px;
  }

  .ht-md-85p {
    height: 85%;
  }

  .mx-ht-md-85p {
    max-height: 85%;
  }

  .mn-ht-md-85p {
    min-height: 85%;
  }

  .ht-md-85-f {
    height: 85px !important;
  }

  .ht-md-85p-f {
    height: 85% !important;
  }

  .ht-md-90 {
    height: 90px;
  }

  .ht-md-90p {
    height: 90%;
  }

  .mx-ht-md-90p {
    max-height: 90%;
  }

  .mn-ht-md-90p {
    min-height: 90%;
  }

  .ht-md-90-f {
    height: 90px !important;
  }

  .ht-md-90p-f {
    height: 90% !important;
  }

  .ht-md-95 {
    height: 95px;
  }

  .ht-md-95p {
    height: 95%;
  }

  .mx-ht-md-95p {
    max-height: 95%;
  }

  .mn-ht-md-95p {
    min-height: 95%;
  }

  .ht-md-95-f {
    height: 95px !important;
  }

  .ht-md-95p-f {
    height: 95% !important;
  }

  .ht-md-100 {
    height: 100px;
  }

  .ht-md-100p {
    height: 100%;
  }

  .mx-ht-md-100p {
    max-height: 100%;
  }

  .mn-ht-md-100p {
    min-height: 100%;
  }

  .ht-md-100-f {
    height: 100px !important;
  }

  .ht-md-100p-f {
    height: 100% !important;
  }

  .ht-md-105 {
    height: 105px;
  }

  .ht-md-105p {
    height: 105%;
  }

  .mx-ht-md-105p {
    max-height: 105%;
  }

  .mn-ht-md-105p {
    min-height: 105%;
  }

  .ht-md-105-f {
    height: 105px !important;
  }

  .ht-md-105p-f {
    height: 105% !important;
  }

  .ht-md-110 {
    height: 110px;
  }

  .ht-md-110p {
    height: 110%;
  }

  .mx-ht-md-110p {
    max-height: 110%;
  }

  .mn-ht-md-110p {
    min-height: 110%;
  }

  .ht-md-110-f {
    height: 110px !important;
  }

  .ht-md-110p-f {
    height: 110% !important;
  }

  .ht-md-115 {
    height: 115px;
  }

  .ht-md-115p {
    height: 115%;
  }

  .mx-ht-md-115p {
    max-height: 115%;
  }

  .mn-ht-md-115p {
    min-height: 115%;
  }

  .ht-md-115-f {
    height: 115px !important;
  }

  .ht-md-115p-f {
    height: 115% !important;
  }

  .ht-md-120 {
    height: 120px;
  }

  .ht-md-120p {
    height: 120%;
  }

  .mx-ht-md-120p {
    max-height: 120%;
  }

  .mn-ht-md-120p {
    min-height: 120%;
  }

  .ht-md-120-f {
    height: 120px !important;
  }

  .ht-md-120p-f {
    height: 120% !important;
  }

  .ht-md-125 {
    height: 125px;
  }

  .ht-md-125p {
    height: 125%;
  }

  .mx-ht-md-125p {
    max-height: 125%;
  }

  .mn-ht-md-125p {
    min-height: 125%;
  }

  .ht-md-125-f {
    height: 125px !important;
  }

  .ht-md-125p-f {
    height: 125% !important;
  }

  .ht-md-130 {
    height: 130px;
  }

  .ht-md-130p {
    height: 130%;
  }

  .mx-ht-md-130p {
    max-height: 130%;
  }

  .mn-ht-md-130p {
    min-height: 130%;
  }

  .ht-md-130-f {
    height: 130px !important;
  }

  .ht-md-130p-f {
    height: 130% !important;
  }

  .ht-md-135 {
    height: 135px;
  }

  .ht-md-135p {
    height: 135%;
  }

  .mx-ht-md-135p {
    max-height: 135%;
  }

  .mn-ht-md-135p {
    min-height: 135%;
  }

  .ht-md-135-f {
    height: 135px !important;
  }

  .ht-md-135p-f {
    height: 135% !important;
  }

  .ht-md-140 {
    height: 140px;
  }

  .ht-md-140p {
    height: 140%;
  }

  .mx-ht-md-140p {
    max-height: 140%;
  }

  .mn-ht-md-140p {
    min-height: 140%;
  }

  .ht-md-140-f {
    height: 140px !important;
  }

  .ht-md-140p-f {
    height: 140% !important;
  }

  .ht-md-145 {
    height: 145px;
  }

  .ht-md-145p {
    height: 145%;
  }

  .mx-ht-md-145p {
    max-height: 145%;
  }

  .mn-ht-md-145p {
    min-height: 145%;
  }

  .ht-md-145-f {
    height: 145px !important;
  }

  .ht-md-145p-f {
    height: 145% !important;
  }

  .ht-md-150 {
    height: 150px;
  }

  .ht-md-150p {
    height: 150%;
  }

  .mx-ht-md-150p {
    max-height: 150%;
  }

  .mn-ht-md-150p {
    min-height: 150%;
  }

  .ht-md-150-f {
    height: 150px !important;
  }

  .ht-md-150p-f {
    height: 150% !important;
  }

  .ht-md-155 {
    height: 155px;
  }

  .ht-md-155p {
    height: 155%;
  }

  .mx-ht-md-155p {
    max-height: 155%;
  }

  .mn-ht-md-155p {
    min-height: 155%;
  }

  .ht-md-155-f {
    height: 155px !important;
  }

  .ht-md-155p-f {
    height: 155% !important;
  }

  .ht-md-160 {
    height: 160px;
  }

  .ht-md-160p {
    height: 160%;
  }

  .mx-ht-md-160p {
    max-height: 160%;
  }

  .mn-ht-md-160p {
    min-height: 160%;
  }

  .ht-md-160-f {
    height: 160px !important;
  }

  .ht-md-160p-f {
    height: 160% !important;
  }

  .ht-md-165 {
    height: 165px;
  }

  .ht-md-165p {
    height: 165%;
  }

  .mx-ht-md-165p {
    max-height: 165%;
  }

  .mn-ht-md-165p {
    min-height: 165%;
  }

  .ht-md-165-f {
    height: 165px !important;
  }

  .ht-md-165p-f {
    height: 165% !important;
  }

  .ht-md-170 {
    height: 170px;
  }

  .ht-md-170p {
    height: 170%;
  }

  .mx-ht-md-170p {
    max-height: 170%;
  }

  .mn-ht-md-170p {
    min-height: 170%;
  }

  .ht-md-170-f {
    height: 170px !important;
  }

  .ht-md-170p-f {
    height: 170% !important;
  }

  .ht-md-175 {
    height: 175px;
  }

  .ht-md-175p {
    height: 175%;
  }

  .mx-ht-md-175p {
    max-height: 175%;
  }

  .mn-ht-md-175p {
    min-height: 175%;
  }

  .ht-md-175-f {
    height: 175px !important;
  }

  .ht-md-175p-f {
    height: 175% !important;
  }

  .ht-md-180 {
    height: 180px;
  }

  .ht-md-180p {
    height: 180%;
  }

  .mx-ht-md-180p {
    max-height: 180%;
  }

  .mn-ht-md-180p {
    min-height: 180%;
  }

  .ht-md-180-f {
    height: 180px !important;
  }

  .ht-md-180p-f {
    height: 180% !important;
  }

  .ht-md-185 {
    height: 185px;
  }

  .ht-md-185p {
    height: 185%;
  }

  .mx-ht-md-185p {
    max-height: 185%;
  }

  .mn-ht-md-185p {
    min-height: 185%;
  }

  .ht-md-185-f {
    height: 185px !important;
  }

  .ht-md-185p-f {
    height: 185% !important;
  }

  .ht-md-190 {
    height: 190px;
  }

  .ht-md-190p {
    height: 190%;
  }

  .mx-ht-md-190p {
    max-height: 190%;
  }

  .mn-ht-md-190p {
    min-height: 190%;
  }

  .ht-md-190-f {
    height: 190px !important;
  }

  .ht-md-190p-f {
    height: 190% !important;
  }

  .ht-md-195 {
    height: 195px;
  }

  .ht-md-195p {
    height: 195%;
  }

  .mx-ht-md-195p {
    max-height: 195%;
  }

  .mn-ht-md-195p {
    min-height: 195%;
  }

  .ht-md-195-f {
    height: 195px !important;
  }

  .ht-md-195p-f {
    height: 195% !important;
  }

  .ht-md-200 {
    height: 200px;
  }

  .ht-md-200p {
    height: 200%;
  }

  .mx-ht-md-200p {
    max-height: 200%;
  }

  .mn-ht-md-200p {
    min-height: 200%;
  }

  .ht-md-200-f {
    height: 200px !important;
  }

  .ht-md-200p-f {
    height: 200% !important;
  }

  .ht-md-205 {
    height: 205px;
  }

  .ht-md-205p {
    height: 205%;
  }

  .mx-ht-md-205p {
    max-height: 205%;
  }

  .mn-ht-md-205p {
    min-height: 205%;
  }

  .ht-md-205-f {
    height: 205px !important;
  }

  .ht-md-205p-f {
    height: 205% !important;
  }

  .ht-md-210 {
    height: 210px;
  }

  .ht-md-210p {
    height: 210%;
  }

  .mx-ht-md-210p {
    max-height: 210%;
  }

  .mn-ht-md-210p {
    min-height: 210%;
  }

  .ht-md-210-f {
    height: 210px !important;
  }

  .ht-md-210p-f {
    height: 210% !important;
  }

  .ht-md-215 {
    height: 215px;
  }

  .ht-md-215p {
    height: 215%;
  }

  .mx-ht-md-215p {
    max-height: 215%;
  }

  .mn-ht-md-215p {
    min-height: 215%;
  }

  .ht-md-215-f {
    height: 215px !important;
  }

  .ht-md-215p-f {
    height: 215% !important;
  }

  .ht-md-220 {
    height: 220px;
  }

  .ht-md-220p {
    height: 220%;
  }

  .mx-ht-md-220p {
    max-height: 220%;
  }

  .mn-ht-md-220p {
    min-height: 220%;
  }

  .ht-md-220-f {
    height: 220px !important;
  }

  .ht-md-220p-f {
    height: 220% !important;
  }

  .ht-md-225 {
    height: 225px;
  }

  .ht-md-225p {
    height: 225%;
  }

  .mx-ht-md-225p {
    max-height: 225%;
  }

  .mn-ht-md-225p {
    min-height: 225%;
  }

  .ht-md-225-f {
    height: 225px !important;
  }

  .ht-md-225p-f {
    height: 225% !important;
  }

  .ht-md-230 {
    height: 230px;
  }

  .ht-md-230p {
    height: 230%;
  }

  .mx-ht-md-230p {
    max-height: 230%;
  }

  .mn-ht-md-230p {
    min-height: 230%;
  }

  .ht-md-230-f {
    height: 230px !important;
  }

  .ht-md-230p-f {
    height: 230% !important;
  }

  .ht-md-235 {
    height: 235px;
  }

  .ht-md-235p {
    height: 235%;
  }

  .mx-ht-md-235p {
    max-height: 235%;
  }

  .mn-ht-md-235p {
    min-height: 235%;
  }

  .ht-md-235-f {
    height: 235px !important;
  }

  .ht-md-235p-f {
    height: 235% !important;
  }

  .ht-md-240 {
    height: 240px;
  }

  .ht-md-240p {
    height: 240%;
  }

  .mx-ht-md-240p {
    max-height: 240%;
  }

  .mn-ht-md-240p {
    min-height: 240%;
  }

  .ht-md-240-f {
    height: 240px !important;
  }

  .ht-md-240p-f {
    height: 240% !important;
  }

  .ht-md-245 {
    height: 245px;
  }

  .ht-md-245p {
    height: 245%;
  }

  .mx-ht-md-245p {
    max-height: 245%;
  }

  .mn-ht-md-245p {
    min-height: 245%;
  }

  .ht-md-245-f {
    height: 245px !important;
  }

  .ht-md-245p-f {
    height: 245% !important;
  }

  .ht-md-250 {
    height: 250px;
  }

  .ht-md-250p {
    height: 250%;
  }

  .mx-ht-md-250p {
    max-height: 250%;
  }

  .mn-ht-md-250p {
    min-height: 250%;
  }

  .ht-md-250-f {
    height: 250px !important;
  }

  .ht-md-250p-f {
    height: 250% !important;
  }

  .ht-md-255 {
    height: 255px;
  }

  .ht-md-255p {
    height: 255%;
  }

  .mx-ht-md-255p {
    max-height: 255%;
  }

  .mn-ht-md-255p {
    min-height: 255%;
  }

  .ht-md-255-f {
    height: 255px !important;
  }

  .ht-md-255p-f {
    height: 255% !important;
  }

  .ht-md-260 {
    height: 260px;
  }

  .ht-md-260p {
    height: 260%;
  }

  .mx-ht-md-260p {
    max-height: 260%;
  }

  .mn-ht-md-260p {
    min-height: 260%;
  }

  .ht-md-260-f {
    height: 260px !important;
  }

  .ht-md-260p-f {
    height: 260% !important;
  }

  .ht-md-265 {
    height: 265px;
  }

  .ht-md-265p {
    height: 265%;
  }

  .mx-ht-md-265p {
    max-height: 265%;
  }

  .mn-ht-md-265p {
    min-height: 265%;
  }

  .ht-md-265-f {
    height: 265px !important;
  }

  .ht-md-265p-f {
    height: 265% !important;
  }

  .ht-md-270 {
    height: 270px;
  }

  .ht-md-270p {
    height: 270%;
  }

  .mx-ht-md-270p {
    max-height: 270%;
  }

  .mn-ht-md-270p {
    min-height: 270%;
  }

  .ht-md-270-f {
    height: 270px !important;
  }

  .ht-md-270p-f {
    height: 270% !important;
  }

  .ht-md-275 {
    height: 275px;
  }

  .ht-md-275p {
    height: 275%;
  }

  .mx-ht-md-275p {
    max-height: 275%;
  }

  .mn-ht-md-275p {
    min-height: 275%;
  }

  .ht-md-275-f {
    height: 275px !important;
  }

  .ht-md-275p-f {
    height: 275% !important;
  }

  .ht-md-280 {
    height: 280px;
  }

  .ht-md-280p {
    height: 280%;
  }

  .mx-ht-md-280p {
    max-height: 280%;
  }

  .mn-ht-md-280p {
    min-height: 280%;
  }

  .ht-md-280-f {
    height: 280px !important;
  }

  .ht-md-280p-f {
    height: 280% !important;
  }

  .ht-md-285 {
    height: 285px;
  }

  .ht-md-285p {
    height: 285%;
  }

  .mx-ht-md-285p {
    max-height: 285%;
  }

  .mn-ht-md-285p {
    min-height: 285%;
  }

  .ht-md-285-f {
    height: 285px !important;
  }

  .ht-md-285p-f {
    height: 285% !important;
  }

  .ht-md-290 {
    height: 290px;
  }

  .ht-md-290p {
    height: 290%;
  }

  .mx-ht-md-290p {
    max-height: 290%;
  }

  .mn-ht-md-290p {
    min-height: 290%;
  }

  .ht-md-290-f {
    height: 290px !important;
  }

  .ht-md-290p-f {
    height: 290% !important;
  }

  .ht-md-295 {
    height: 295px;
  }

  .ht-md-295p {
    height: 295%;
  }

  .mx-ht-md-295p {
    max-height: 295%;
  }

  .mn-ht-md-295p {
    min-height: 295%;
  }

  .ht-md-295-f {
    height: 295px !important;
  }

  .ht-md-295p-f {
    height: 295% !important;
  }

  .ht-md-300 {
    height: 300px;
  }

  .ht-md-300p {
    height: 300%;
  }

  .mx-ht-md-300p {
    max-height: 300%;
  }

  .mn-ht-md-300p {
    min-height: 300%;
  }

  .ht-md-300-f {
    height: 300px !important;
  }

  .ht-md-300p-f {
    height: 300% !important;
  }

  .ht-md-auto {
    height: auto;
  }

  .ht-md-300 {
    height: 300px;
  }

  .ht-md-300p {
    height: 300%;
  }

  .mx-ht-md-300p {
    max-height: 300%;
  }

  .mn-ht-md-300p {
    min-height: 300%;
  }

  .ht-md-300-f {
    height: 300px !important;
  }

  .ht-md-300p-f {
    height: 300% !important;
  }

  .ht-md-350 {
    height: 350px;
  }

  .ht-md-350p {
    height: 350%;
  }

  .mx-ht-md-350p {
    max-height: 350%;
  }

  .mn-ht-md-350p {
    min-height: 350%;
  }

  .ht-md-350-f {
    height: 350px !important;
  }

  .ht-md-350p-f {
    height: 350% !important;
  }

  .ht-md-400 {
    height: 400px;
  }

  .ht-md-400p {
    height: 400%;
  }

  .mx-ht-md-400p {
    max-height: 400%;
  }

  .mn-ht-md-400p {
    min-height: 400%;
  }

  .ht-md-400-f {
    height: 400px !important;
  }

  .ht-md-400p-f {
    height: 400% !important;
  }

  .ht-md-450 {
    height: 450px;
  }

  .ht-md-450p {
    height: 450%;
  }

  .mx-ht-md-450p {
    max-height: 450%;
  }

  .mn-ht-md-450p {
    min-height: 450%;
  }

  .ht-md-450-f {
    height: 450px !important;
  }

  .ht-md-450p-f {
    height: 450% !important;
  }

  .ht-md-500 {
    height: 500px;
  }

  .ht-md-500p {
    height: 500%;
  }

  .mx-ht-md-500p {
    max-height: 500%;
  }

  .mn-ht-md-500p {
    min-height: 500%;
  }

  .ht-md-500-f {
    height: 500px !important;
  }

  .ht-md-500p-f {
    height: 500% !important;
  }

  .ht-md-550 {
    height: 550px;
  }

  .ht-md-550p {
    height: 550%;
  }

  .mx-ht-md-550p {
    max-height: 550%;
  }

  .mn-ht-md-550p {
    min-height: 550%;
  }

  .ht-md-550-f {
    height: 550px !important;
  }

  .ht-md-550p-f {
    height: 550% !important;
  }

  .ht-md-600 {
    height: 600px;
  }

  .ht-md-600p {
    height: 600%;
  }

  .mx-ht-md-600p {
    max-height: 600%;
  }

  .mn-ht-md-600p {
    min-height: 600%;
  }

  .ht-md-600-f {
    height: 600px !important;
  }

  .ht-md-600p-f {
    height: 600% !important;
  }

  .ht-md-650 {
    height: 650px;
  }

  .ht-md-650p {
    height: 650%;
  }

  .mx-ht-md-650p {
    max-height: 650%;
  }

  .mn-ht-md-650p {
    min-height: 650%;
  }

  .ht-md-650-f {
    height: 650px !important;
  }

  .ht-md-650p-f {
    height: 650% !important;
  }

  .ht-md-700 {
    height: 700px;
  }

  .ht-md-700p {
    height: 700%;
  }

  .mx-ht-md-700p {
    max-height: 700%;
  }

  .mn-ht-md-700p {
    min-height: 700%;
  }

  .ht-md-700-f {
    height: 700px !important;
  }

  .ht-md-700p-f {
    height: 700% !important;
  }

  .ht-md-750 {
    height: 750px;
  }

  .ht-md-750p {
    height: 750%;
  }

  .mx-ht-md-750p {
    max-height: 750%;
  }

  .mn-ht-md-750p {
    min-height: 750%;
  }

  .ht-md-750-f {
    height: 750px !important;
  }

  .ht-md-750p-f {
    height: 750% !important;
  }

  .ht-md-800 {
    height: 800px;
  }

  .ht-md-800p {
    height: 800%;
  }

  .mx-ht-md-800p {
    max-height: 800%;
  }

  .mn-ht-md-800p {
    min-height: 800%;
  }

  .ht-md-800-f {
    height: 800px !important;
  }

  .ht-md-800p-f {
    height: 800% !important;
  }

  .ht-md-850 {
    height: 850px;
  }

  .ht-md-850p {
    height: 850%;
  }

  .mx-ht-md-850p {
    max-height: 850%;
  }

  .mn-ht-md-850p {
    min-height: 850%;
  }

  .ht-md-850-f {
    height: 850px !important;
  }

  .ht-md-850p-f {
    height: 850% !important;
  }

  .ht-md-100v {
    height: 100vh;
  }
}
@media (min-width: 992px) {
  .ht-lg-5 {
    height: 5px;
  }

  .ht-lg-5p {
    height: 5%;
  }

  .mx-ht-lg-5p {
    max-height: 5%;
  }

  .mn-ht-lg-5p {
    min-height: 5%;
  }

  .ht-lg-5-f {
    height: 5px !important;
  }

  .ht-lg-5p-f {
    height: 5% !important;
  }

  .ht-lg-10 {
    height: 10px;
  }

  .ht-lg-10p {
    height: 10%;
  }

  .mx-ht-lg-10p {
    max-height: 10%;
  }

  .mn-ht-lg-10p {
    min-height: 10%;
  }

  .ht-lg-10-f {
    height: 10px !important;
  }

  .ht-lg-10p-f {
    height: 10% !important;
  }

  .ht-lg-15 {
    height: 15px;
  }

  .ht-lg-15p {
    height: 15%;
  }

  .mx-ht-lg-15p {
    max-height: 15%;
  }

  .mn-ht-lg-15p {
    min-height: 15%;
  }

  .ht-lg-15-f {
    height: 15px !important;
  }

  .ht-lg-15p-f {
    height: 15% !important;
  }

  .ht-lg-20 {
    height: 20px;
  }

  .ht-lg-20p {
    height: 20%;
  }

  .mx-ht-lg-20p {
    max-height: 20%;
  }

  .mn-ht-lg-20p {
    min-height: 20%;
  }

  .ht-lg-20-f {
    height: 20px !important;
  }

  .ht-lg-20p-f {
    height: 20% !important;
  }

  .ht-lg-25 {
    height: 25px;
  }

  .ht-lg-25p {
    height: 25%;
  }

  .mx-ht-lg-25p {
    max-height: 25%;
  }

  .mn-ht-lg-25p {
    min-height: 25%;
  }

  .ht-lg-25-f {
    height: 25px !important;
  }

  .ht-lg-25p-f {
    height: 25% !important;
  }

  .ht-lg-30 {
    height: 30px;
  }

  .ht-lg-30p {
    height: 30%;
  }

  .mx-ht-lg-30p {
    max-height: 30%;
  }

  .mn-ht-lg-30p {
    min-height: 30%;
  }

  .ht-lg-30-f {
    height: 30px !important;
  }

  .ht-lg-30p-f {
    height: 30% !important;
  }

  .ht-lg-35 {
    height: 35px;
  }

  .ht-lg-35p {
    height: 35%;
  }

  .mx-ht-lg-35p {
    max-height: 35%;
  }

  .mn-ht-lg-35p {
    min-height: 35%;
  }

  .ht-lg-35-f {
    height: 35px !important;
  }

  .ht-lg-35p-f {
    height: 35% !important;
  }

  .ht-lg-40 {
    height: 40px;
  }

  .ht-lg-40p {
    height: 40%;
  }

  .mx-ht-lg-40p {
    max-height: 40%;
  }

  .mn-ht-lg-40p {
    min-height: 40%;
  }

  .ht-lg-40-f {
    height: 40px !important;
  }

  .ht-lg-40p-f {
    height: 40% !important;
  }

  .ht-lg-45 {
    height: 45px;
  }

  .ht-lg-45p {
    height: 45%;
  }

  .mx-ht-lg-45p {
    max-height: 45%;
  }

  .mn-ht-lg-45p {
    min-height: 45%;
  }

  .ht-lg-45-f {
    height: 45px !important;
  }

  .ht-lg-45p-f {
    height: 45% !important;
  }

  .ht-lg-50 {
    height: 50px;
  }

  .ht-lg-50p {
    height: 50%;
  }

  .mx-ht-lg-50p {
    max-height: 50%;
  }

  .mn-ht-lg-50p {
    min-height: 50%;
  }

  .ht-lg-50-f {
    height: 50px !important;
  }

  .ht-lg-50p-f {
    height: 50% !important;
  }

  .ht-lg-55 {
    height: 55px;
  }

  .ht-lg-55p {
    height: 55%;
  }

  .mx-ht-lg-55p {
    max-height: 55%;
  }

  .mn-ht-lg-55p {
    min-height: 55%;
  }

  .ht-lg-55-f {
    height: 55px !important;
  }

  .ht-lg-55p-f {
    height: 55% !important;
  }

  .ht-lg-60 {
    height: 60px;
  }

  .ht-lg-60p {
    height: 60%;
  }

  .mx-ht-lg-60p {
    max-height: 60%;
  }

  .mn-ht-lg-60p {
    min-height: 60%;
  }

  .ht-lg-60-f {
    height: 60px !important;
  }

  .ht-lg-60p-f {
    height: 60% !important;
  }

  .ht-lg-65 {
    height: 65px;
  }

  .ht-lg-65p {
    height: 65%;
  }

  .mx-ht-lg-65p {
    max-height: 65%;
  }

  .mn-ht-lg-65p {
    min-height: 65%;
  }

  .ht-lg-65-f {
    height: 65px !important;
  }

  .ht-lg-65p-f {
    height: 65% !important;
  }

  .ht-lg-70 {
    height: 70px;
  }

  .ht-lg-70p {
    height: 70%;
  }

  .mx-ht-lg-70p {
    max-height: 70%;
  }

  .mn-ht-lg-70p {
    min-height: 70%;
  }

  .ht-lg-70-f {
    height: 70px !important;
  }

  .ht-lg-70p-f {
    height: 70% !important;
  }

  .ht-lg-75 {
    height: 75px;
  }

  .ht-lg-75p {
    height: 75%;
  }

  .mx-ht-lg-75p {
    max-height: 75%;
  }

  .mn-ht-lg-75p {
    min-height: 75%;
  }

  .ht-lg-75-f {
    height: 75px !important;
  }

  .ht-lg-75p-f {
    height: 75% !important;
  }

  .ht-lg-80 {
    height: 80px;
  }

  .ht-lg-80p {
    height: 80%;
  }

  .mx-ht-lg-80p {
    max-height: 80%;
  }

  .mn-ht-lg-80p {
    min-height: 80%;
  }

  .ht-lg-80-f {
    height: 80px !important;
  }

  .ht-lg-80p-f {
    height: 80% !important;
  }

  .ht-lg-85 {
    height: 85px;
  }

  .ht-lg-85p {
    height: 85%;
  }

  .mx-ht-lg-85p {
    max-height: 85%;
  }

  .mn-ht-lg-85p {
    min-height: 85%;
  }

  .ht-lg-85-f {
    height: 85px !important;
  }

  .ht-lg-85p-f {
    height: 85% !important;
  }

  .ht-lg-90 {
    height: 90px;
  }

  .ht-lg-90p {
    height: 90%;
  }

  .mx-ht-lg-90p {
    max-height: 90%;
  }

  .mn-ht-lg-90p {
    min-height: 90%;
  }

  .ht-lg-90-f {
    height: 90px !important;
  }

  .ht-lg-90p-f {
    height: 90% !important;
  }

  .ht-lg-95 {
    height: 95px;
  }

  .ht-lg-95p {
    height: 95%;
  }

  .mx-ht-lg-95p {
    max-height: 95%;
  }

  .mn-ht-lg-95p {
    min-height: 95%;
  }

  .ht-lg-95-f {
    height: 95px !important;
  }

  .ht-lg-95p-f {
    height: 95% !important;
  }

  .ht-lg-100 {
    height: 100px;
  }

  .ht-lg-100p {
    height: 100%;
  }

  .mx-ht-lg-100p {
    max-height: 100%;
  }

  .mn-ht-lg-100p {
    min-height: 100%;
  }

  .ht-lg-100-f {
    height: 100px !important;
  }

  .ht-lg-100p-f {
    height: 100% !important;
  }

  .ht-lg-105 {
    height: 105px;
  }

  .ht-lg-105p {
    height: 105%;
  }

  .mx-ht-lg-105p {
    max-height: 105%;
  }

  .mn-ht-lg-105p {
    min-height: 105%;
  }

  .ht-lg-105-f {
    height: 105px !important;
  }

  .ht-lg-105p-f {
    height: 105% !important;
  }

  .ht-lg-110 {
    height: 110px;
  }

  .ht-lg-110p {
    height: 110%;
  }

  .mx-ht-lg-110p {
    max-height: 110%;
  }

  .mn-ht-lg-110p {
    min-height: 110%;
  }

  .ht-lg-110-f {
    height: 110px !important;
  }

  .ht-lg-110p-f {
    height: 110% !important;
  }

  .ht-lg-115 {
    height: 115px;
  }

  .ht-lg-115p {
    height: 115%;
  }

  .mx-ht-lg-115p {
    max-height: 115%;
  }

  .mn-ht-lg-115p {
    min-height: 115%;
  }

  .ht-lg-115-f {
    height: 115px !important;
  }

  .ht-lg-115p-f {
    height: 115% !important;
  }

  .ht-lg-120 {
    height: 120px;
  }

  .ht-lg-120p {
    height: 120%;
  }

  .mx-ht-lg-120p {
    max-height: 120%;
  }

  .mn-ht-lg-120p {
    min-height: 120%;
  }

  .ht-lg-120-f {
    height: 120px !important;
  }

  .ht-lg-120p-f {
    height: 120% !important;
  }

  .ht-lg-125 {
    height: 125px;
  }

  .ht-lg-125p {
    height: 125%;
  }

  .mx-ht-lg-125p {
    max-height: 125%;
  }

  .mn-ht-lg-125p {
    min-height: 125%;
  }

  .ht-lg-125-f {
    height: 125px !important;
  }

  .ht-lg-125p-f {
    height: 125% !important;
  }

  .ht-lg-130 {
    height: 130px;
  }

  .ht-lg-130p {
    height: 130%;
  }

  .mx-ht-lg-130p {
    max-height: 130%;
  }

  .mn-ht-lg-130p {
    min-height: 130%;
  }

  .ht-lg-130-f {
    height: 130px !important;
  }

  .ht-lg-130p-f {
    height: 130% !important;
  }

  .ht-lg-135 {
    height: 135px;
  }

  .ht-lg-135p {
    height: 135%;
  }

  .mx-ht-lg-135p {
    max-height: 135%;
  }

  .mn-ht-lg-135p {
    min-height: 135%;
  }

  .ht-lg-135-f {
    height: 135px !important;
  }

  .ht-lg-135p-f {
    height: 135% !important;
  }

  .ht-lg-140 {
    height: 140px;
  }

  .ht-lg-140p {
    height: 140%;
  }

  .mx-ht-lg-140p {
    max-height: 140%;
  }

  .mn-ht-lg-140p {
    min-height: 140%;
  }

  .ht-lg-140-f {
    height: 140px !important;
  }

  .ht-lg-140p-f {
    height: 140% !important;
  }

  .ht-lg-145 {
    height: 145px;
  }

  .ht-lg-145p {
    height: 145%;
  }

  .mx-ht-lg-145p {
    max-height: 145%;
  }

  .mn-ht-lg-145p {
    min-height: 145%;
  }

  .ht-lg-145-f {
    height: 145px !important;
  }

  .ht-lg-145p-f {
    height: 145% !important;
  }

  .ht-lg-150 {
    height: 150px;
  }

  .ht-lg-150p {
    height: 150%;
  }

  .mx-ht-lg-150p {
    max-height: 150%;
  }

  .mn-ht-lg-150p {
    min-height: 150%;
  }

  .ht-lg-150-f {
    height: 150px !important;
  }

  .ht-lg-150p-f {
    height: 150% !important;
  }

  .ht-lg-155 {
    height: 155px;
  }

  .ht-lg-155p {
    height: 155%;
  }

  .mx-ht-lg-155p {
    max-height: 155%;
  }

  .mn-ht-lg-155p {
    min-height: 155%;
  }

  .ht-lg-155-f {
    height: 155px !important;
  }

  .ht-lg-155p-f {
    height: 155% !important;
  }

  .ht-lg-160 {
    height: 160px;
  }

  .ht-lg-160p {
    height: 160%;
  }

  .mx-ht-lg-160p {
    max-height: 160%;
  }

  .mn-ht-lg-160p {
    min-height: 160%;
  }

  .ht-lg-160-f {
    height: 160px !important;
  }

  .ht-lg-160p-f {
    height: 160% !important;
  }

  .ht-lg-165 {
    height: 165px;
  }

  .ht-lg-165p {
    height: 165%;
  }

  .mx-ht-lg-165p {
    max-height: 165%;
  }

  .mn-ht-lg-165p {
    min-height: 165%;
  }

  .ht-lg-165-f {
    height: 165px !important;
  }

  .ht-lg-165p-f {
    height: 165% !important;
  }

  .ht-lg-170 {
    height: 170px;
  }

  .ht-lg-170p {
    height: 170%;
  }

  .mx-ht-lg-170p {
    max-height: 170%;
  }

  .mn-ht-lg-170p {
    min-height: 170%;
  }

  .ht-lg-170-f {
    height: 170px !important;
  }

  .ht-lg-170p-f {
    height: 170% !important;
  }

  .ht-lg-175 {
    height: 175px;
  }

  .ht-lg-175p {
    height: 175%;
  }

  .mx-ht-lg-175p {
    max-height: 175%;
  }

  .mn-ht-lg-175p {
    min-height: 175%;
  }

  .ht-lg-175-f {
    height: 175px !important;
  }

  .ht-lg-175p-f {
    height: 175% !important;
  }

  .ht-lg-180 {
    height: 180px;
  }

  .ht-lg-180p {
    height: 180%;
  }

  .mx-ht-lg-180p {
    max-height: 180%;
  }

  .mn-ht-lg-180p {
    min-height: 180%;
  }

  .ht-lg-180-f {
    height: 180px !important;
  }

  .ht-lg-180p-f {
    height: 180% !important;
  }

  .ht-lg-185 {
    height: 185px;
  }

  .ht-lg-185p {
    height: 185%;
  }

  .mx-ht-lg-185p {
    max-height: 185%;
  }

  .mn-ht-lg-185p {
    min-height: 185%;
  }

  .ht-lg-185-f {
    height: 185px !important;
  }

  .ht-lg-185p-f {
    height: 185% !important;
  }

  .ht-lg-190 {
    height: 190px;
  }

  .ht-lg-190p {
    height: 190%;
  }

  .mx-ht-lg-190p {
    max-height: 190%;
  }

  .mn-ht-lg-190p {
    min-height: 190%;
  }

  .ht-lg-190-f {
    height: 190px !important;
  }

  .ht-lg-190p-f {
    height: 190% !important;
  }

  .ht-lg-195 {
    height: 195px;
  }

  .ht-lg-195p {
    height: 195%;
  }

  .mx-ht-lg-195p {
    max-height: 195%;
  }

  .mn-ht-lg-195p {
    min-height: 195%;
  }

  .ht-lg-195-f {
    height: 195px !important;
  }

  .ht-lg-195p-f {
    height: 195% !important;
  }

  .ht-lg-200 {
    height: 200px;
  }

  .ht-lg-200p {
    height: 200%;
  }

  .mx-ht-lg-200p {
    max-height: 200%;
  }

  .mn-ht-lg-200p {
    min-height: 200%;
  }

  .ht-lg-200-f {
    height: 200px !important;
  }

  .ht-lg-200p-f {
    height: 200% !important;
  }

  .ht-lg-205 {
    height: 205px;
  }

  .ht-lg-205p {
    height: 205%;
  }

  .mx-ht-lg-205p {
    max-height: 205%;
  }

  .mn-ht-lg-205p {
    min-height: 205%;
  }

  .ht-lg-205-f {
    height: 205px !important;
  }

  .ht-lg-205p-f {
    height: 205% !important;
  }

  .ht-lg-210 {
    height: 210px;
  }

  .ht-lg-210p {
    height: 210%;
  }

  .mx-ht-lg-210p {
    max-height: 210%;
  }

  .mn-ht-lg-210p {
    min-height: 210%;
  }

  .ht-lg-210-f {
    height: 210px !important;
  }

  .ht-lg-210p-f {
    height: 210% !important;
  }

  .ht-lg-215 {
    height: 215px;
  }

  .ht-lg-215p {
    height: 215%;
  }

  .mx-ht-lg-215p {
    max-height: 215%;
  }

  .mn-ht-lg-215p {
    min-height: 215%;
  }

  .ht-lg-215-f {
    height: 215px !important;
  }

  .ht-lg-215p-f {
    height: 215% !important;
  }

  .ht-lg-220 {
    height: 220px;
  }

  .ht-lg-220p {
    height: 220%;
  }

  .mx-ht-lg-220p {
    max-height: 220%;
  }

  .mn-ht-lg-220p {
    min-height: 220%;
  }

  .ht-lg-220-f {
    height: 220px !important;
  }

  .ht-lg-220p-f {
    height: 220% !important;
  }

  .ht-lg-225 {
    height: 225px;
  }

  .ht-lg-225p {
    height: 225%;
  }

  .mx-ht-lg-225p {
    max-height: 225%;
  }

  .mn-ht-lg-225p {
    min-height: 225%;
  }

  .ht-lg-225-f {
    height: 225px !important;
  }

  .ht-lg-225p-f {
    height: 225% !important;
  }

  .ht-lg-230 {
    height: 230px;
  }

  .ht-lg-230p {
    height: 230%;
  }

  .mx-ht-lg-230p {
    max-height: 230%;
  }

  .mn-ht-lg-230p {
    min-height: 230%;
  }

  .ht-lg-230-f {
    height: 230px !important;
  }

  .ht-lg-230p-f {
    height: 230% !important;
  }

  .ht-lg-235 {
    height: 235px;
  }

  .ht-lg-235p {
    height: 235%;
  }

  .mx-ht-lg-235p {
    max-height: 235%;
  }

  .mn-ht-lg-235p {
    min-height: 235%;
  }

  .ht-lg-235-f {
    height: 235px !important;
  }

  .ht-lg-235p-f {
    height: 235% !important;
  }

  .ht-lg-240 {
    height: 240px;
  }

  .ht-lg-240p {
    height: 240%;
  }

  .mx-ht-lg-240p {
    max-height: 240%;
  }

  .mn-ht-lg-240p {
    min-height: 240%;
  }

  .ht-lg-240-f {
    height: 240px !important;
  }

  .ht-lg-240p-f {
    height: 240% !important;
  }

  .ht-lg-245 {
    height: 245px;
  }

  .ht-lg-245p {
    height: 245%;
  }

  .mx-ht-lg-245p {
    max-height: 245%;
  }

  .mn-ht-lg-245p {
    min-height: 245%;
  }

  .ht-lg-245-f {
    height: 245px !important;
  }

  .ht-lg-245p-f {
    height: 245% !important;
  }

  .ht-lg-250 {
    height: 250px;
  }

  .ht-lg-250p {
    height: 250%;
  }

  .mx-ht-lg-250p {
    max-height: 250%;
  }

  .mn-ht-lg-250p {
    min-height: 250%;
  }

  .ht-lg-250-f {
    height: 250px !important;
  }

  .ht-lg-250p-f {
    height: 250% !important;
  }

  .ht-lg-255 {
    height: 255px;
  }

  .ht-lg-255p {
    height: 255%;
  }

  .mx-ht-lg-255p {
    max-height: 255%;
  }

  .mn-ht-lg-255p {
    min-height: 255%;
  }

  .ht-lg-255-f {
    height: 255px !important;
  }

  .ht-lg-255p-f {
    height: 255% !important;
  }

  .ht-lg-260 {
    height: 260px;
  }

  .ht-lg-260p {
    height: 260%;
  }

  .mx-ht-lg-260p {
    max-height: 260%;
  }

  .mn-ht-lg-260p {
    min-height: 260%;
  }

  .ht-lg-260-f {
    height: 260px !important;
  }

  .ht-lg-260p-f {
    height: 260% !important;
  }

  .ht-lg-265 {
    height: 265px;
  }

  .ht-lg-265p {
    height: 265%;
  }

  .mx-ht-lg-265p {
    max-height: 265%;
  }

  .mn-ht-lg-265p {
    min-height: 265%;
  }

  .ht-lg-265-f {
    height: 265px !important;
  }

  .ht-lg-265p-f {
    height: 265% !important;
  }

  .ht-lg-270 {
    height: 270px;
  }

  .ht-lg-270p {
    height: 270%;
  }

  .mx-ht-lg-270p {
    max-height: 270%;
  }

  .mn-ht-lg-270p {
    min-height: 270%;
  }

  .ht-lg-270-f {
    height: 270px !important;
  }

  .ht-lg-270p-f {
    height: 270% !important;
  }

  .ht-lg-275 {
    height: 275px;
  }

  .ht-lg-275p {
    height: 275%;
  }

  .mx-ht-lg-275p {
    max-height: 275%;
  }

  .mn-ht-lg-275p {
    min-height: 275%;
  }

  .ht-lg-275-f {
    height: 275px !important;
  }

  .ht-lg-275p-f {
    height: 275% !important;
  }

  .ht-lg-280 {
    height: 280px;
  }

  .ht-lg-280p {
    height: 280%;
  }

  .mx-ht-lg-280p {
    max-height: 280%;
  }

  .mn-ht-lg-280p {
    min-height: 280%;
  }

  .ht-lg-280-f {
    height: 280px !important;
  }

  .ht-lg-280p-f {
    height: 280% !important;
  }

  .ht-lg-285 {
    height: 285px;
  }

  .ht-lg-285p {
    height: 285%;
  }

  .mx-ht-lg-285p {
    max-height: 285%;
  }

  .mn-ht-lg-285p {
    min-height: 285%;
  }

  .ht-lg-285-f {
    height: 285px !important;
  }

  .ht-lg-285p-f {
    height: 285% !important;
  }

  .ht-lg-290 {
    height: 290px;
  }

  .ht-lg-290p {
    height: 290%;
  }

  .mx-ht-lg-290p {
    max-height: 290%;
  }

  .mn-ht-lg-290p {
    min-height: 290%;
  }

  .ht-lg-290-f {
    height: 290px !important;
  }

  .ht-lg-290p-f {
    height: 290% !important;
  }

  .ht-lg-295 {
    height: 295px;
  }

  .ht-lg-295p {
    height: 295%;
  }

  .mx-ht-lg-295p {
    max-height: 295%;
  }

  .mn-ht-lg-295p {
    min-height: 295%;
  }

  .ht-lg-295-f {
    height: 295px !important;
  }

  .ht-lg-295p-f {
    height: 295% !important;
  }

  .ht-lg-300 {
    height: 300px;
  }

  .ht-lg-300p {
    height: 300%;
  }

  .mx-ht-lg-300p {
    max-height: 300%;
  }

  .mn-ht-lg-300p {
    min-height: 300%;
  }

  .ht-lg-300-f {
    height: 300px !important;
  }

  .ht-lg-300p-f {
    height: 300% !important;
  }

  .ht-lg-auto {
    height: auto;
  }

  .ht-lg-300 {
    height: 300px;
  }

  .ht-lg-300p {
    height: 300%;
  }

  .mx-ht-lg-300p {
    max-height: 300%;
  }

  .mn-ht-lg-300p {
    min-height: 300%;
  }

  .ht-lg-300-f {
    height: 300px !important;
  }

  .ht-lg-300p-f {
    height: 300% !important;
  }

  .ht-lg-350 {
    height: 350px;
  }

  .ht-lg-350p {
    height: 350%;
  }

  .mx-ht-lg-350p {
    max-height: 350%;
  }

  .mn-ht-lg-350p {
    min-height: 350%;
  }

  .ht-lg-350-f {
    height: 350px !important;
  }

  .ht-lg-350p-f {
    height: 350% !important;
  }

  .ht-lg-400 {
    height: 400px;
  }

  .ht-lg-400p {
    height: 400%;
  }

  .mx-ht-lg-400p {
    max-height: 400%;
  }

  .mn-ht-lg-400p {
    min-height: 400%;
  }

  .ht-lg-400-f {
    height: 400px !important;
  }

  .ht-lg-400p-f {
    height: 400% !important;
  }

  .ht-lg-450 {
    height: 450px;
  }

  .ht-lg-450p {
    height: 450%;
  }

  .mx-ht-lg-450p {
    max-height: 450%;
  }

  .mn-ht-lg-450p {
    min-height: 450%;
  }

  .ht-lg-450-f {
    height: 450px !important;
  }

  .ht-lg-450p-f {
    height: 450% !important;
  }

  .ht-lg-500 {
    height: 500px;
  }

  .ht-lg-500p {
    height: 500%;
  }

  .mx-ht-lg-500p {
    max-height: 500%;
  }

  .mn-ht-lg-500p {
    min-height: 500%;
  }

  .ht-lg-500-f {
    height: 500px !important;
  }

  .ht-lg-500p-f {
    height: 500% !important;
  }

  .ht-lg-550 {
    height: 550px;
  }

  .ht-lg-550p {
    height: 550%;
  }

  .mx-ht-lg-550p {
    max-height: 550%;
  }

  .mn-ht-lg-550p {
    min-height: 550%;
  }

  .ht-lg-550-f {
    height: 550px !important;
  }

  .ht-lg-550p-f {
    height: 550% !important;
  }

  .ht-lg-600 {
    height: 600px;
  }

  .ht-lg-600p {
    height: 600%;
  }

  .mx-ht-lg-600p {
    max-height: 600%;
  }

  .mn-ht-lg-600p {
    min-height: 600%;
  }

  .ht-lg-600-f {
    height: 600px !important;
  }

  .ht-lg-600p-f {
    height: 600% !important;
  }

  .ht-lg-650 {
    height: 650px;
  }

  .ht-lg-650p {
    height: 650%;
  }

  .mx-ht-lg-650p {
    max-height: 650%;
  }

  .mn-ht-lg-650p {
    min-height: 650%;
  }

  .ht-lg-650-f {
    height: 650px !important;
  }

  .ht-lg-650p-f {
    height: 650% !important;
  }

  .ht-lg-700 {
    height: 700px;
  }

  .ht-lg-700p {
    height: 700%;
  }

  .mx-ht-lg-700p {
    max-height: 700%;
  }

  .mn-ht-lg-700p {
    min-height: 700%;
  }

  .ht-lg-700-f {
    height: 700px !important;
  }

  .ht-lg-700p-f {
    height: 700% !important;
  }

  .ht-lg-750 {
    height: 750px;
  }

  .ht-lg-750p {
    height: 750%;
  }

  .mx-ht-lg-750p {
    max-height: 750%;
  }

  .mn-ht-lg-750p {
    min-height: 750%;
  }

  .ht-lg-750-f {
    height: 750px !important;
  }

  .ht-lg-750p-f {
    height: 750% !important;
  }

  .ht-lg-800 {
    height: 800px;
  }

  .ht-lg-800p {
    height: 800%;
  }

  .mx-ht-lg-800p {
    max-height: 800%;
  }

  .mn-ht-lg-800p {
    min-height: 800%;
  }

  .ht-lg-800-f {
    height: 800px !important;
  }

  .ht-lg-800p-f {
    height: 800% !important;
  }

  .ht-lg-850 {
    height: 850px;
  }

  .ht-lg-850p {
    height: 850%;
  }

  .mx-ht-lg-850p {
    max-height: 850%;
  }

  .mn-ht-lg-850p {
    min-height: 850%;
  }

  .ht-lg-850-f {
    height: 850px !important;
  }

  .ht-lg-850p-f {
    height: 850% !important;
  }

  .ht-lg-100v {
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  .ht-xl-5 {
    height: 5px;
  }

  .ht-xl-5p {
    height: 5%;
  }

  .mx-ht-xl-5p {
    max-height: 5%;
  }

  .mn-ht-xl-5p {
    min-height: 5%;
  }

  .ht-xl-5-f {
    height: 5px !important;
  }

  .ht-xl-5p-f {
    height: 5% !important;
  }

  .ht-xl-10 {
    height: 10px;
  }

  .ht-xl-10p {
    height: 10%;
  }

  .mx-ht-xl-10p {
    max-height: 10%;
  }

  .mn-ht-xl-10p {
    min-height: 10%;
  }

  .ht-xl-10-f {
    height: 10px !important;
  }

  .ht-xl-10p-f {
    height: 10% !important;
  }

  .ht-xl-15 {
    height: 15px;
  }

  .ht-xl-15p {
    height: 15%;
  }

  .mx-ht-xl-15p {
    max-height: 15%;
  }

  .mn-ht-xl-15p {
    min-height: 15%;
  }

  .ht-xl-15-f {
    height: 15px !important;
  }

  .ht-xl-15p-f {
    height: 15% !important;
  }

  .ht-xl-20 {
    height: 20px;
  }

  .ht-xl-20p {
    height: 20%;
  }

  .mx-ht-xl-20p {
    max-height: 20%;
  }

  .mn-ht-xl-20p {
    min-height: 20%;
  }

  .ht-xl-20-f {
    height: 20px !important;
  }

  .ht-xl-20p-f {
    height: 20% !important;
  }

  .ht-xl-25 {
    height: 25px;
  }

  .ht-xl-25p {
    height: 25%;
  }

  .mx-ht-xl-25p {
    max-height: 25%;
  }

  .mn-ht-xl-25p {
    min-height: 25%;
  }

  .ht-xl-25-f {
    height: 25px !important;
  }

  .ht-xl-25p-f {
    height: 25% !important;
  }

  .ht-xl-30 {
    height: 30px;
  }

  .ht-xl-30p {
    height: 30%;
  }

  .mx-ht-xl-30p {
    max-height: 30%;
  }

  .mn-ht-xl-30p {
    min-height: 30%;
  }

  .ht-xl-30-f {
    height: 30px !important;
  }

  .ht-xl-30p-f {
    height: 30% !important;
  }

  .ht-xl-35 {
    height: 35px;
  }

  .ht-xl-35p {
    height: 35%;
  }

  .mx-ht-xl-35p {
    max-height: 35%;
  }

  .mn-ht-xl-35p {
    min-height: 35%;
  }

  .ht-xl-35-f {
    height: 35px !important;
  }

  .ht-xl-35p-f {
    height: 35% !important;
  }

  .ht-xl-40 {
    height: 40px;
  }

  .ht-xl-40p {
    height: 40%;
  }

  .mx-ht-xl-40p {
    max-height: 40%;
  }

  .mn-ht-xl-40p {
    min-height: 40%;
  }

  .ht-xl-40-f {
    height: 40px !important;
  }

  .ht-xl-40p-f {
    height: 40% !important;
  }

  .ht-xl-45 {
    height: 45px;
  }

  .ht-xl-45p {
    height: 45%;
  }

  .mx-ht-xl-45p {
    max-height: 45%;
  }

  .mn-ht-xl-45p {
    min-height: 45%;
  }

  .ht-xl-45-f {
    height: 45px !important;
  }

  .ht-xl-45p-f {
    height: 45% !important;
  }

  .ht-xl-50 {
    height: 50px;
  }

  .ht-xl-50p {
    height: 50%;
  }

  .mx-ht-xl-50p {
    max-height: 50%;
  }

  .mn-ht-xl-50p {
    min-height: 50%;
  }

  .ht-xl-50-f {
    height: 50px !important;
  }

  .ht-xl-50p-f {
    height: 50% !important;
  }

  .ht-xl-55 {
    height: 55px;
  }

  .ht-xl-55p {
    height: 55%;
  }

  .mx-ht-xl-55p {
    max-height: 55%;
  }

  .mn-ht-xl-55p {
    min-height: 55%;
  }

  .ht-xl-55-f {
    height: 55px !important;
  }

  .ht-xl-55p-f {
    height: 55% !important;
  }

  .ht-xl-60 {
    height: 60px;
  }

  .ht-xl-60p {
    height: 60%;
  }

  .mx-ht-xl-60p {
    max-height: 60%;
  }

  .mn-ht-xl-60p {
    min-height: 60%;
  }

  .ht-xl-60-f {
    height: 60px !important;
  }

  .ht-xl-60p-f {
    height: 60% !important;
  }

  .ht-xl-65 {
    height: 65px;
  }

  .ht-xl-65p {
    height: 65%;
  }

  .mx-ht-xl-65p {
    max-height: 65%;
  }

  .mn-ht-xl-65p {
    min-height: 65%;
  }

  .ht-xl-65-f {
    height: 65px !important;
  }

  .ht-xl-65p-f {
    height: 65% !important;
  }

  .ht-xl-70 {
    height: 70px;
  }

  .ht-xl-70p {
    height: 70%;
  }

  .mx-ht-xl-70p {
    max-height: 70%;
  }

  .mn-ht-xl-70p {
    min-height: 70%;
  }

  .ht-xl-70-f {
    height: 70px !important;
  }

  .ht-xl-70p-f {
    height: 70% !important;
  }

  .ht-xl-75 {
    height: 75px;
  }

  .ht-xl-75p {
    height: 75%;
  }

  .mx-ht-xl-75p {
    max-height: 75%;
  }

  .mn-ht-xl-75p {
    min-height: 75%;
  }

  .ht-xl-75-f {
    height: 75px !important;
  }

  .ht-xl-75p-f {
    height: 75% !important;
  }

  .ht-xl-80 {
    height: 80px;
  }

  .ht-xl-80p {
    height: 80%;
  }

  .mx-ht-xl-80p {
    max-height: 80%;
  }

  .mn-ht-xl-80p {
    min-height: 80%;
  }

  .ht-xl-80-f {
    height: 80px !important;
  }

  .ht-xl-80p-f {
    height: 80% !important;
  }

  .ht-xl-85 {
    height: 85px;
  }

  .ht-xl-85p {
    height: 85%;
  }

  .mx-ht-xl-85p {
    max-height: 85%;
  }

  .mn-ht-xl-85p {
    min-height: 85%;
  }

  .ht-xl-85-f {
    height: 85px !important;
  }

  .ht-xl-85p-f {
    height: 85% !important;
  }

  .ht-xl-90 {
    height: 90px;
  }

  .ht-xl-90p {
    height: 90%;
  }

  .mx-ht-xl-90p {
    max-height: 90%;
  }

  .mn-ht-xl-90p {
    min-height: 90%;
  }

  .ht-xl-90-f {
    height: 90px !important;
  }

  .ht-xl-90p-f {
    height: 90% !important;
  }

  .ht-xl-95 {
    height: 95px;
  }

  .ht-xl-95p {
    height: 95%;
  }

  .mx-ht-xl-95p {
    max-height: 95%;
  }

  .mn-ht-xl-95p {
    min-height: 95%;
  }

  .ht-xl-95-f {
    height: 95px !important;
  }

  .ht-xl-95p-f {
    height: 95% !important;
  }

  .ht-xl-100 {
    height: 100px;
  }

  .ht-xl-100p {
    height: 100%;
  }

  .mx-ht-xl-100p {
    max-height: 100%;
  }

  .mn-ht-xl-100p {
    min-height: 100%;
  }

  .ht-xl-100-f {
    height: 100px !important;
  }

  .ht-xl-100p-f {
    height: 100% !important;
  }

  .ht-xl-105 {
    height: 105px;
  }

  .ht-xl-105p {
    height: 105%;
  }

  .mx-ht-xl-105p {
    max-height: 105%;
  }

  .mn-ht-xl-105p {
    min-height: 105%;
  }

  .ht-xl-105-f {
    height: 105px !important;
  }

  .ht-xl-105p-f {
    height: 105% !important;
  }

  .ht-xl-110 {
    height: 110px;
  }

  .ht-xl-110p {
    height: 110%;
  }

  .mx-ht-xl-110p {
    max-height: 110%;
  }

  .mn-ht-xl-110p {
    min-height: 110%;
  }

  .ht-xl-110-f {
    height: 110px !important;
  }

  .ht-xl-110p-f {
    height: 110% !important;
  }

  .ht-xl-115 {
    height: 115px;
  }

  .ht-xl-115p {
    height: 115%;
  }

  .mx-ht-xl-115p {
    max-height: 115%;
  }

  .mn-ht-xl-115p {
    min-height: 115%;
  }

  .ht-xl-115-f {
    height: 115px !important;
  }

  .ht-xl-115p-f {
    height: 115% !important;
  }

  .ht-xl-120 {
    height: 120px;
  }

  .ht-xl-120p {
    height: 120%;
  }

  .mx-ht-xl-120p {
    max-height: 120%;
  }

  .mn-ht-xl-120p {
    min-height: 120%;
  }

  .ht-xl-120-f {
    height: 120px !important;
  }

  .ht-xl-120p-f {
    height: 120% !important;
  }

  .ht-xl-125 {
    height: 125px;
  }

  .ht-xl-125p {
    height: 125%;
  }

  .mx-ht-xl-125p {
    max-height: 125%;
  }

  .mn-ht-xl-125p {
    min-height: 125%;
  }

  .ht-xl-125-f {
    height: 125px !important;
  }

  .ht-xl-125p-f {
    height: 125% !important;
  }

  .ht-xl-130 {
    height: 130px;
  }

  .ht-xl-130p {
    height: 130%;
  }

  .mx-ht-xl-130p {
    max-height: 130%;
  }

  .mn-ht-xl-130p {
    min-height: 130%;
  }

  .ht-xl-130-f {
    height: 130px !important;
  }

  .ht-xl-130p-f {
    height: 130% !important;
  }

  .ht-xl-135 {
    height: 135px;
  }

  .ht-xl-135p {
    height: 135%;
  }

  .mx-ht-xl-135p {
    max-height: 135%;
  }

  .mn-ht-xl-135p {
    min-height: 135%;
  }

  .ht-xl-135-f {
    height: 135px !important;
  }

  .ht-xl-135p-f {
    height: 135% !important;
  }

  .ht-xl-140 {
    height: 140px;
  }

  .ht-xl-140p {
    height: 140%;
  }

  .mx-ht-xl-140p {
    max-height: 140%;
  }

  .mn-ht-xl-140p {
    min-height: 140%;
  }

  .ht-xl-140-f {
    height: 140px !important;
  }

  .ht-xl-140p-f {
    height: 140% !important;
  }

  .ht-xl-145 {
    height: 145px;
  }

  .ht-xl-145p {
    height: 145%;
  }

  .mx-ht-xl-145p {
    max-height: 145%;
  }

  .mn-ht-xl-145p {
    min-height: 145%;
  }

  .ht-xl-145-f {
    height: 145px !important;
  }

  .ht-xl-145p-f {
    height: 145% !important;
  }

  .ht-xl-150 {
    height: 150px;
  }

  .ht-xl-150p {
    height: 150%;
  }

  .mx-ht-xl-150p {
    max-height: 150%;
  }

  .mn-ht-xl-150p {
    min-height: 150%;
  }

  .ht-xl-150-f {
    height: 150px !important;
  }

  .ht-xl-150p-f {
    height: 150% !important;
  }

  .ht-xl-155 {
    height: 155px;
  }

  .ht-xl-155p {
    height: 155%;
  }

  .mx-ht-xl-155p {
    max-height: 155%;
  }

  .mn-ht-xl-155p {
    min-height: 155%;
  }

  .ht-xl-155-f {
    height: 155px !important;
  }

  .ht-xl-155p-f {
    height: 155% !important;
  }

  .ht-xl-160 {
    height: 160px;
  }

  .ht-xl-160p {
    height: 160%;
  }

  .mx-ht-xl-160p {
    max-height: 160%;
  }

  .mn-ht-xl-160p {
    min-height: 160%;
  }

  .ht-xl-160-f {
    height: 160px !important;
  }

  .ht-xl-160p-f {
    height: 160% !important;
  }

  .ht-xl-165 {
    height: 165px;
  }

  .ht-xl-165p {
    height: 165%;
  }

  .mx-ht-xl-165p {
    max-height: 165%;
  }

  .mn-ht-xl-165p {
    min-height: 165%;
  }

  .ht-xl-165-f {
    height: 165px !important;
  }

  .ht-xl-165p-f {
    height: 165% !important;
  }

  .ht-xl-170 {
    height: 170px;
  }

  .ht-xl-170p {
    height: 170%;
  }

  .mx-ht-xl-170p {
    max-height: 170%;
  }

  .mn-ht-xl-170p {
    min-height: 170%;
  }

  .ht-xl-170-f {
    height: 170px !important;
  }

  .ht-xl-170p-f {
    height: 170% !important;
  }

  .ht-xl-175 {
    height: 175px;
  }

  .ht-xl-175p {
    height: 175%;
  }

  .mx-ht-xl-175p {
    max-height: 175%;
  }

  .mn-ht-xl-175p {
    min-height: 175%;
  }

  .ht-xl-175-f {
    height: 175px !important;
  }

  .ht-xl-175p-f {
    height: 175% !important;
  }

  .ht-xl-180 {
    height: 180px;
  }

  .ht-xl-180p {
    height: 180%;
  }

  .mx-ht-xl-180p {
    max-height: 180%;
  }

  .mn-ht-xl-180p {
    min-height: 180%;
  }

  .ht-xl-180-f {
    height: 180px !important;
  }

  .ht-xl-180p-f {
    height: 180% !important;
  }

  .ht-xl-185 {
    height: 185px;
  }

  .ht-xl-185p {
    height: 185%;
  }

  .mx-ht-xl-185p {
    max-height: 185%;
  }

  .mn-ht-xl-185p {
    min-height: 185%;
  }

  .ht-xl-185-f {
    height: 185px !important;
  }

  .ht-xl-185p-f {
    height: 185% !important;
  }

  .ht-xl-190 {
    height: 190px;
  }

  .ht-xl-190p {
    height: 190%;
  }

  .mx-ht-xl-190p {
    max-height: 190%;
  }

  .mn-ht-xl-190p {
    min-height: 190%;
  }

  .ht-xl-190-f {
    height: 190px !important;
  }

  .ht-xl-190p-f {
    height: 190% !important;
  }

  .ht-xl-195 {
    height: 195px;
  }

  .ht-xl-195p {
    height: 195%;
  }

  .mx-ht-xl-195p {
    max-height: 195%;
  }

  .mn-ht-xl-195p {
    min-height: 195%;
  }

  .ht-xl-195-f {
    height: 195px !important;
  }

  .ht-xl-195p-f {
    height: 195% !important;
  }

  .ht-xl-200 {
    height: 200px;
  }

  .ht-xl-200p {
    height: 200%;
  }

  .mx-ht-xl-200p {
    max-height: 200%;
  }

  .mn-ht-xl-200p {
    min-height: 200%;
  }

  .ht-xl-200-f {
    height: 200px !important;
  }

  .ht-xl-200p-f {
    height: 200% !important;
  }

  .ht-xl-205 {
    height: 205px;
  }

  .ht-xl-205p {
    height: 205%;
  }

  .mx-ht-xl-205p {
    max-height: 205%;
  }

  .mn-ht-xl-205p {
    min-height: 205%;
  }

  .ht-xl-205-f {
    height: 205px !important;
  }

  .ht-xl-205p-f {
    height: 205% !important;
  }

  .ht-xl-210 {
    height: 210px;
  }

  .ht-xl-210p {
    height: 210%;
  }

  .mx-ht-xl-210p {
    max-height: 210%;
  }

  .mn-ht-xl-210p {
    min-height: 210%;
  }

  .ht-xl-210-f {
    height: 210px !important;
  }

  .ht-xl-210p-f {
    height: 210% !important;
  }

  .ht-xl-215 {
    height: 215px;
  }

  .ht-xl-215p {
    height: 215%;
  }

  .mx-ht-xl-215p {
    max-height: 215%;
  }

  .mn-ht-xl-215p {
    min-height: 215%;
  }

  .ht-xl-215-f {
    height: 215px !important;
  }

  .ht-xl-215p-f {
    height: 215% !important;
  }

  .ht-xl-220 {
    height: 220px;
  }

  .ht-xl-220p {
    height: 220%;
  }

  .mx-ht-xl-220p {
    max-height: 220%;
  }

  .mn-ht-xl-220p {
    min-height: 220%;
  }

  .ht-xl-220-f {
    height: 220px !important;
  }

  .ht-xl-220p-f {
    height: 220% !important;
  }

  .ht-xl-225 {
    height: 225px;
  }

  .ht-xl-225p {
    height: 225%;
  }

  .mx-ht-xl-225p {
    max-height: 225%;
  }

  .mn-ht-xl-225p {
    min-height: 225%;
  }

  .ht-xl-225-f {
    height: 225px !important;
  }

  .ht-xl-225p-f {
    height: 225% !important;
  }

  .ht-xl-230 {
    height: 230px;
  }

  .ht-xl-230p {
    height: 230%;
  }

  .mx-ht-xl-230p {
    max-height: 230%;
  }

  .mn-ht-xl-230p {
    min-height: 230%;
  }

  .ht-xl-230-f {
    height: 230px !important;
  }

  .ht-xl-230p-f {
    height: 230% !important;
  }

  .ht-xl-235 {
    height: 235px;
  }

  .ht-xl-235p {
    height: 235%;
  }

  .mx-ht-xl-235p {
    max-height: 235%;
  }

  .mn-ht-xl-235p {
    min-height: 235%;
  }

  .ht-xl-235-f {
    height: 235px !important;
  }

  .ht-xl-235p-f {
    height: 235% !important;
  }

  .ht-xl-240 {
    height: 240px;
  }

  .ht-xl-240p {
    height: 240%;
  }

  .mx-ht-xl-240p {
    max-height: 240%;
  }

  .mn-ht-xl-240p {
    min-height: 240%;
  }

  .ht-xl-240-f {
    height: 240px !important;
  }

  .ht-xl-240p-f {
    height: 240% !important;
  }

  .ht-xl-245 {
    height: 245px;
  }

  .ht-xl-245p {
    height: 245%;
  }

  .mx-ht-xl-245p {
    max-height: 245%;
  }

  .mn-ht-xl-245p {
    min-height: 245%;
  }

  .ht-xl-245-f {
    height: 245px !important;
  }

  .ht-xl-245p-f {
    height: 245% !important;
  }

  .ht-xl-250 {
    height: 250px;
  }

  .ht-xl-250p {
    height: 250%;
  }

  .mx-ht-xl-250p {
    max-height: 250%;
  }

  .mn-ht-xl-250p {
    min-height: 250%;
  }

  .ht-xl-250-f {
    height: 250px !important;
  }

  .ht-xl-250p-f {
    height: 250% !important;
  }

  .ht-xl-255 {
    height: 255px;
  }

  .ht-xl-255p {
    height: 255%;
  }

  .mx-ht-xl-255p {
    max-height: 255%;
  }

  .mn-ht-xl-255p {
    min-height: 255%;
  }

  .ht-xl-255-f {
    height: 255px !important;
  }

  .ht-xl-255p-f {
    height: 255% !important;
  }

  .ht-xl-260 {
    height: 260px;
  }

  .ht-xl-260p {
    height: 260%;
  }

  .mx-ht-xl-260p {
    max-height: 260%;
  }

  .mn-ht-xl-260p {
    min-height: 260%;
  }

  .ht-xl-260-f {
    height: 260px !important;
  }

  .ht-xl-260p-f {
    height: 260% !important;
  }

  .ht-xl-265 {
    height: 265px;
  }

  .ht-xl-265p {
    height: 265%;
  }

  .mx-ht-xl-265p {
    max-height: 265%;
  }

  .mn-ht-xl-265p {
    min-height: 265%;
  }

  .ht-xl-265-f {
    height: 265px !important;
  }

  .ht-xl-265p-f {
    height: 265% !important;
  }

  .ht-xl-270 {
    height: 270px;
  }

  .ht-xl-270p {
    height: 270%;
  }

  .mx-ht-xl-270p {
    max-height: 270%;
  }

  .mn-ht-xl-270p {
    min-height: 270%;
  }

  .ht-xl-270-f {
    height: 270px !important;
  }

  .ht-xl-270p-f {
    height: 270% !important;
  }

  .ht-xl-275 {
    height: 275px;
  }

  .ht-xl-275p {
    height: 275%;
  }

  .mx-ht-xl-275p {
    max-height: 275%;
  }

  .mn-ht-xl-275p {
    min-height: 275%;
  }

  .ht-xl-275-f {
    height: 275px !important;
  }

  .ht-xl-275p-f {
    height: 275% !important;
  }

  .ht-xl-280 {
    height: 280px;
  }

  .ht-xl-280p {
    height: 280%;
  }

  .mx-ht-xl-280p {
    max-height: 280%;
  }

  .mn-ht-xl-280p {
    min-height: 280%;
  }

  .ht-xl-280-f {
    height: 280px !important;
  }

  .ht-xl-280p-f {
    height: 280% !important;
  }

  .ht-xl-285 {
    height: 285px;
  }

  .ht-xl-285p {
    height: 285%;
  }

  .mx-ht-xl-285p {
    max-height: 285%;
  }

  .mn-ht-xl-285p {
    min-height: 285%;
  }

  .ht-xl-285-f {
    height: 285px !important;
  }

  .ht-xl-285p-f {
    height: 285% !important;
  }

  .ht-xl-290 {
    height: 290px;
  }

  .ht-xl-290p {
    height: 290%;
  }

  .mx-ht-xl-290p {
    max-height: 290%;
  }

  .mn-ht-xl-290p {
    min-height: 290%;
  }

  .ht-xl-290-f {
    height: 290px !important;
  }

  .ht-xl-290p-f {
    height: 290% !important;
  }

  .ht-xl-295 {
    height: 295px;
  }

  .ht-xl-295p {
    height: 295%;
  }

  .mx-ht-xl-295p {
    max-height: 295%;
  }

  .mn-ht-xl-295p {
    min-height: 295%;
  }

  .ht-xl-295-f {
    height: 295px !important;
  }

  .ht-xl-295p-f {
    height: 295% !important;
  }

  .ht-xl-300 {
    height: 300px;
  }

  .ht-xl-300p {
    height: 300%;
  }

  .mx-ht-xl-300p {
    max-height: 300%;
  }

  .mn-ht-xl-300p {
    min-height: 300%;
  }

  .ht-xl-300-f {
    height: 300px !important;
  }

  .ht-xl-300p-f {
    height: 300% !important;
  }

  .ht-xl-auto {
    height: auto;
  }

  .ht-xl-300 {
    height: 300px;
  }

  .ht-xl-300p {
    height: 300%;
  }

  .mx-ht-xl-300p {
    max-height: 300%;
  }

  .mn-ht-xl-300p {
    min-height: 300%;
  }

  .ht-xl-300-f {
    height: 300px !important;
  }

  .ht-xl-300p-f {
    height: 300% !important;
  }

  .ht-xl-350 {
    height: 350px;
  }

  .ht-xl-350p {
    height: 350%;
  }

  .mx-ht-xl-350p {
    max-height: 350%;
  }

  .mn-ht-xl-350p {
    min-height: 350%;
  }

  .ht-xl-350-f {
    height: 350px !important;
  }

  .ht-xl-350p-f {
    height: 350% !important;
  }

  .ht-xl-400 {
    height: 400px;
  }

  .ht-xl-400p {
    height: 400%;
  }

  .mx-ht-xl-400p {
    max-height: 400%;
  }

  .mn-ht-xl-400p {
    min-height: 400%;
  }

  .ht-xl-400-f {
    height: 400px !important;
  }

  .ht-xl-400p-f {
    height: 400% !important;
  }

  .ht-xl-450 {
    height: 450px;
  }

  .ht-xl-450p {
    height: 450%;
  }

  .mx-ht-xl-450p {
    max-height: 450%;
  }

  .mn-ht-xl-450p {
    min-height: 450%;
  }

  .ht-xl-450-f {
    height: 450px !important;
  }

  .ht-xl-450p-f {
    height: 450% !important;
  }

  .ht-xl-500 {
    height: 500px;
  }

  .ht-xl-500p {
    height: 500%;
  }

  .mx-ht-xl-500p {
    max-height: 500%;
  }

  .mn-ht-xl-500p {
    min-height: 500%;
  }

  .ht-xl-500-f {
    height: 500px !important;
  }

  .ht-xl-500p-f {
    height: 500% !important;
  }

  .ht-xl-550 {
    height: 550px;
  }

  .ht-xl-550p {
    height: 550%;
  }

  .mx-ht-xl-550p {
    max-height: 550%;
  }

  .mn-ht-xl-550p {
    min-height: 550%;
  }

  .ht-xl-550-f {
    height: 550px !important;
  }

  .ht-xl-550p-f {
    height: 550% !important;
  }

  .ht-xl-600 {
    height: 600px;
  }

  .ht-xl-600p {
    height: 600%;
  }

  .mx-ht-xl-600p {
    max-height: 600%;
  }

  .mn-ht-xl-600p {
    min-height: 600%;
  }

  .ht-xl-600-f {
    height: 600px !important;
  }

  .ht-xl-600p-f {
    height: 600% !important;
  }

  .ht-xl-650 {
    height: 650px;
  }

  .ht-xl-650p {
    height: 650%;
  }

  .mx-ht-xl-650p {
    max-height: 650%;
  }

  .mn-ht-xl-650p {
    min-height: 650%;
  }

  .ht-xl-650-f {
    height: 650px !important;
  }

  .ht-xl-650p-f {
    height: 650% !important;
  }

  .ht-xl-700 {
    height: 700px;
  }

  .ht-xl-700p {
    height: 700%;
  }

  .mx-ht-xl-700p {
    max-height: 700%;
  }

  .mn-ht-xl-700p {
    min-height: 700%;
  }

  .ht-xl-700-f {
    height: 700px !important;
  }

  .ht-xl-700p-f {
    height: 700% !important;
  }

  .ht-xl-750 {
    height: 750px;
  }

  .ht-xl-750p {
    height: 750%;
  }

  .mx-ht-xl-750p {
    max-height: 750%;
  }

  .mn-ht-xl-750p {
    min-height: 750%;
  }

  .ht-xl-750-f {
    height: 750px !important;
  }

  .ht-xl-750p-f {
    height: 750% !important;
  }

  .ht-xl-800 {
    height: 800px;
  }

  .ht-xl-800p {
    height: 800%;
  }

  .mx-ht-xl-800p {
    max-height: 800%;
  }

  .mn-ht-xl-800p {
    min-height: 800%;
  }

  .ht-xl-800-f {
    height: 800px !important;
  }

  .ht-xl-800p-f {
    height: 800% !important;
  }

  .ht-xl-850 {
    height: 850px;
  }

  .ht-xl-850p {
    height: 850%;
  }

  .mx-ht-xl-850p {
    max-height: 850%;
  }

  .mn-ht-xl-850p {
    min-height: 850%;
  }

  .ht-xl-850-f {
    height: 850px !important;
  }

  .ht-xl-850p-f {
    height: 850% !important;
  }

  .ht-xl-100v {
    height: 100vh;
  }
}
/* ###### 9.4 Margin   ###### */
.mg-0 {
  margin: 0px;
}

.mg-0-f {
  margin: 0px !important;
}

.mg-y-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mg-y-0-f {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mg-x-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mg-x-0-f {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mg-t-0 {
  margin-top: 0px;
}

.mg-r-0 {
  margin-right: 0px;
}

.mg-b-0 {
  margin-bottom: 0px;
}

.mg-l-0 {
  margin-left: 0px;
}

.mg-t-0-f {
  margin-top: 0px !important;
}

.mg-r-0-f {
  margin-right: 0px !important;
}

.mg-b-0-f {
  margin-bottom: 0px !important;
}

.mg-l-0-f {
  margin-left: 0px !important;
}

.mg-1 {
  margin: 1px;
}

.mg-1-f {
  margin: 1px !important;
}

.mg-y-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mg-y-1-f {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mg-x-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.mg-x-1-f {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mg-t-1 {
  margin-top: 1px;
}

.mg-r-1 {
  margin-right: 1px;
}

.mg-b-1 {
  margin-bottom: 1px;
}

.mg-l-1 {
  margin-left: 1px;
}

.mg-t-1-f {
  margin-top: 1px !important;
}

.mg-r-1-f {
  margin-right: 1px !important;
}

.mg-b-1-f {
  margin-bottom: 1px !important;
}

.mg-l-1-f {
  margin-left: 1px !important;
}

.mg-2 {
  margin: 2px;
}

.mg-2-f {
  margin: 2px !important;
}

.mg-y-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mg-y-2-f {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.mg-x-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.mg-x-2-f {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mg-t-2 {
  margin-top: 2px;
}

.mg-r-2 {
  margin-right: 2px;
}

.mg-b-2 {
  margin-bottom: 2px;
}

.mg-l-2 {
  margin-left: 2px;
}

.mg-t-2-f {
  margin-top: 2px !important;
}

.mg-r-2-f {
  margin-right: 2px !important;
}

.mg-b-2-f {
  margin-bottom: 2px !important;
}

.mg-l-2-f {
  margin-left: 2px !important;
}

.mg-3 {
  margin: 3px;
}

.mg-3-f {
  margin: 3px !important;
}

.mg-y-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mg-y-3-f {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.mg-x-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.mg-x-3-f {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mg-t-3 {
  margin-top: 3px;
}

.mg-r-3 {
  margin-right: 3px;
}

.mg-b-3 {
  margin-bottom: 3px;
}

.mg-l-3 {
  margin-left: 3px;
}

.mg-t-3-f {
  margin-top: 3px !important;
}

.mg-r-3-f {
  margin-right: 3px !important;
}

.mg-b-3-f {
  margin-bottom: 3px !important;
}

.mg-l-3-f {
  margin-left: 3px !important;
}

.mg-4 {
  margin: 4px;
}

.mg-4-f {
  margin: 4px !important;
}

.mg-y-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mg-y-4-f {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mg-x-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mg-x-4-f {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mg-t-4 {
  margin-top: 4px;
}

.mg-r-4 {
  margin-right: 4px;
}

.mg-b-4 {
  margin-bottom: 4px;
}

.mg-l-4 {
  margin-left: 4px;
}

.mg-t-4-f {
  margin-top: 4px !important;
}

.mg-r-4-f {
  margin-right: 4px !important;
}

.mg-b-4-f {
  margin-bottom: 4px !important;
}

.mg-l-4-f {
  margin-left: 4px !important;
}

.mg-5 {
  margin: 5px;
}

.mg-5-f {
  margin: 5px !important;
}

.mg-y-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mg-y-5-f {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mg-x-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mg-x-5-f {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mg-t-5 {
  margin-top: 5px;
}

.mg-r-5 {
  margin-right: 5px;
}

.mg-b-5 {
  margin-bottom: 5px;
}

.mg-l-5 {
  margin-left: 5px;
}

.mg-t-5-f {
  margin-top: 5px !important;
}

.mg-r-5-f {
  margin-right: 5px !important;
}

.mg-b-5-f {
  margin-bottom: 5px !important;
}

.mg-l-5-f {
  margin-left: 5px !important;
}

.mg-6 {
  margin: 6px;
}

.mg-6-f {
  margin: 6px !important;
}

.mg-y-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mg-y-6-f {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.mg-x-6 {
  margin-left: 6px;
  margin-right: 6px;
}

.mg-x-6-f {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mg-t-6 {
  margin-top: 6px;
}

.mg-r-6 {
  margin-right: 6px;
}

.mg-b-6 {
  margin-bottom: 6px;
}

.mg-l-6 {
  margin-left: 6px;
}

.mg-t-6-f {
  margin-top: 6px !important;
}

.mg-r-6-f {
  margin-right: 6px !important;
}

.mg-b-6-f {
  margin-bottom: 6px !important;
}

.mg-l-6-f {
  margin-left: 6px !important;
}

.mg-7 {
  margin: 7px;
}

.mg-7-f {
  margin: 7px !important;
}

.mg-y-7 {
  margin-top: 7px;
  margin-bottom: 7px;
}

.mg-y-7-f {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mg-x-7 {
  margin-left: 7px;
  margin-right: 7px;
}

.mg-x-7-f {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.mg-t-7 {
  margin-top: 7px;
}

.mg-r-7 {
  margin-right: 7px;
}

.mg-b-7 {
  margin-bottom: 7px;
}

.mg-l-7 {
  margin-left: 7px;
}

.mg-t-7-f {
  margin-top: 7px !important;
}

.mg-r-7-f {
  margin-right: 7px !important;
}

.mg-b-7-f {
  margin-bottom: 7px !important;
}

.mg-l-7-f {
  margin-left: 7px !important;
}

.mg-8 {
  margin: 8px;
}

.mg-8-f {
  margin: 8px !important;
}

.mg-y-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mg-y-8-f {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mg-x-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mg-x-8-f {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mg-t-8 {
  margin-top: 8px;
}

.mg-r-8 {
  margin-right: 8px;
}

.mg-b-8 {
  margin-bottom: 8px;
}

.mg-l-8 {
  margin-left: 8px;
}

.mg-t-8-f {
  margin-top: 8px !important;
}

.mg-r-8-f {
  margin-right: 8px !important;
}

.mg-b-8-f {
  margin-bottom: 8px !important;
}

.mg-l-8-f {
  margin-left: 8px !important;
}

.mg-9 {
  margin: 9px;
}

.mg-9-f {
  margin: 9px !important;
}

.mg-y-9 {
  margin-top: 9px;
  margin-bottom: 9px;
}

.mg-y-9-f {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.mg-x-9 {
  margin-left: 9px;
  margin-right: 9px;
}

.mg-x-9-f {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.mg-t-9 {
  margin-top: 9px;
}

.mg-r-9 {
  margin-right: 9px;
}

.mg-b-9 {
  margin-bottom: 9px;
}

.mg-l-9 {
  margin-left: 9px;
}

.mg-t-9-f {
  margin-top: 9px !important;
}

.mg-r-9-f {
  margin-right: 9px !important;
}

.mg-b-9-f {
  margin-bottom: 9px !important;
}

.mg-l-9-f {
  margin-left: 9px !important;
}

.mg-10 {
  margin: 10px;
}

.mg-10-f {
  margin: 10px !important;
}

.mg-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mg-y-10-f {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mg-x-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mg-x-10-f {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mg-t-10 {
  margin-top: 10px;
}

.mg-r-10 {
  margin-right: 10px;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-t-10-f {
  margin-top: 10px !important;
}

.mg-r-10-f {
  margin-right: 10px !important;
}

.mg-b-10-f {
  margin-bottom: 10px !important;
}

.mg-l-10-f {
  margin-left: 10px !important;
}

.mg-15 {
  margin: 15px;
}

.mg-15-f {
  margin: 15px !important;
}

.mg-y-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mg-y-15-f {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mg-x-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mg-x-15-f {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mg-t-15 {
  margin-top: 15px;
}

.mg-r-15 {
  margin-right: 15px;
}

.mg-b-15 {
  margin-bottom: 15px;
}

.mg-l-15 {
  margin-left: 15px;
}

.mg-t-15-f {
  margin-top: 15px !important;
}

.mg-r-15-f {
  margin-right: 15px !important;
}

.mg-b-15-f {
  margin-bottom: 15px !important;
}

.mg-l-15-f {
  margin-left: 15px !important;
}

.mg-20 {
  margin: 20px;
}

.mg-20-f {
  margin: 20px !important;
}

.mg-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mg-y-20-f {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mg-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mg-x-20-f {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mg-t-20 {
  margin-top: 20px;
}

.mg-r-20 {
  margin-right: 20px;
}

.mg-b-20 {
  margin-bottom: 20px;
}

.mg-l-20 {
  margin-left: 20px;
}

.mg-t-20-f {
  margin-top: 20px !important;
}

.mg-r-20-f {
  margin-right: 20px !important;
}

.mg-b-20-f {
  margin-bottom: 20px !important;
}

.mg-l-20-f {
  margin-left: 20px !important;
}

.mg-25 {
  margin: 25px;
}

.mg-25-f {
  margin: 25px !important;
}

.mg-y-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mg-y-25-f {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mg-x-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mg-x-25-f {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mg-t-25 {
  margin-top: 25px;
}

.mg-r-25 {
  margin-right: 25px;
}

.mg-b-25 {
  margin-bottom: 25px;
}

.mg-l-25 {
  margin-left: 25px;
}

.mg-t-25-f {
  margin-top: 25px !important;
}

.mg-r-25-f {
  margin-right: 25px !important;
}

.mg-b-25-f {
  margin-bottom: 25px !important;
}

.mg-l-25-f {
  margin-left: 25px !important;
}

.mg-30 {
  margin: 30px;
}

.mg-30-f {
  margin: 30px !important;
}

.mg-y-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mg-y-30-f {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mg-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mg-x-30-f {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mg-t-30 {
  margin-top: 30px;
}

.mg-r-30 {
  margin-right: 30px;
}

.mg-b-30 {
  margin-bottom: 30px;
}

.mg-l-30 {
  margin-left: 30px;
}

.mg-t-30-f {
  margin-top: 30px !important;
}

.mg-r-30-f {
  margin-right: 30px !important;
}

.mg-b-30-f {
  margin-bottom: 30px !important;
}

.mg-l-30-f {
  margin-left: 30px !important;
}

.mg-35 {
  margin: 35px;
}

.mg-35-f {
  margin: 35px !important;
}

.mg-y-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mg-y-35-f {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mg-x-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mg-x-35-f {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mg-t-35 {
  margin-top: 35px;
}

.mg-r-35 {
  margin-right: 35px;
}

.mg-b-35 {
  margin-bottom: 35px;
}

.mg-l-35 {
  margin-left: 35px;
}

.mg-t-35-f {
  margin-top: 35px !important;
}

.mg-r-35-f {
  margin-right: 35px !important;
}

.mg-b-35-f {
  margin-bottom: 35px !important;
}

.mg-l-35-f {
  margin-left: 35px !important;
}

.mg-40 {
  margin: 40px;
}

.mg-40-f {
  margin: 40px !important;
}

.mg-y-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mg-y-40-f {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mg-x-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mg-x-40-f {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mg-t-40 {
  margin-top: 40px;
}

.mg-r-40 {
  margin-right: 40px;
}

.mg-b-40 {
  margin-bottom: 40px;
}

.mg-l-40 {
  margin-left: 40px;
}

.mg-t-40-f {
  margin-top: 40px !important;
}

.mg-r-40-f {
  margin-right: 40px !important;
}

.mg-b-40-f {
  margin-bottom: 40px !important;
}

.mg-l-40-f {
  margin-left: 40px !important;
}

.mg-45 {
  margin: 45px;
}

.mg-45-f {
  margin: 45px !important;
}

.mg-y-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mg-y-45-f {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mg-x-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mg-x-45-f {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mg-t-45 {
  margin-top: 45px;
}

.mg-r-45 {
  margin-right: 45px;
}

.mg-b-45 {
  margin-bottom: 45px;
}

.mg-l-45 {
  margin-left: 45px;
}

.mg-t-45-f {
  margin-top: 45px !important;
}

.mg-r-45-f {
  margin-right: 45px !important;
}

.mg-b-45-f {
  margin-bottom: 45px !important;
}

.mg-l-45-f {
  margin-left: 45px !important;
}

.mg-50 {
  margin: 50px;
}

.mg-50-f {
  margin: 50px !important;
}

.mg-y-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mg-y-50-f {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mg-x-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mg-x-50-f {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mg-t-50 {
  margin-top: 50px;
}

.mg-r-50 {
  margin-right: 50px;
}

.mg-b-50 {
  margin-bottom: 50px;
}

.mg-l-50 {
  margin-left: 50px;
}

.mg-t-50-f {
  margin-top: 50px !important;
}

.mg-r-50-f {
  margin-right: 50px !important;
}

.mg-b-50-f {
  margin-bottom: 50px !important;
}

.mg-l-50-f {
  margin-left: 50px !important;
}

.mg-55 {
  margin: 55px;
}

.mg-55-f {
  margin: 55px !important;
}

.mg-y-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mg-y-55-f {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mg-x-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.mg-x-55-f {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.mg-t-55 {
  margin-top: 55px;
}

.mg-r-55 {
  margin-right: 55px;
}

.mg-b-55 {
  margin-bottom: 55px;
}

.mg-l-55 {
  margin-left: 55px;
}

.mg-t-55-f {
  margin-top: 55px !important;
}

.mg-r-55-f {
  margin-right: 55px !important;
}

.mg-b-55-f {
  margin-bottom: 55px !important;
}

.mg-l-55-f {
  margin-left: 55px !important;
}

.mg-60 {
  margin: 60px;
}

.mg-60-f {
  margin: 60px !important;
}

.mg-y-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mg-y-60-f {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mg-x-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mg-x-60-f {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mg-t-60 {
  margin-top: 60px;
}

.mg-r-60 {
  margin-right: 60px;
}

.mg-b-60 {
  margin-bottom: 60px;
}

.mg-l-60 {
  margin-left: 60px;
}

.mg-t-60-f {
  margin-top: 60px !important;
}

.mg-r-60-f {
  margin-right: 60px !important;
}

.mg-b-60-f {
  margin-bottom: 60px !important;
}

.mg-l-60-f {
  margin-left: 60px !important;
}

.mg-65 {
  margin: 65px;
}

.mg-65-f {
  margin: 65px !important;
}

.mg-y-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mg-y-65-f {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mg-x-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.mg-x-65-f {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.mg-t-65 {
  margin-top: 65px;
}

.mg-r-65 {
  margin-right: 65px;
}

.mg-b-65 {
  margin-bottom: 65px;
}

.mg-l-65 {
  margin-left: 65px;
}

.mg-t-65-f {
  margin-top: 65px !important;
}

.mg-r-65-f {
  margin-right: 65px !important;
}

.mg-b-65-f {
  margin-bottom: 65px !important;
}

.mg-l-65-f {
  margin-left: 65px !important;
}

.mg-70 {
  margin: 70px;
}

.mg-70-f {
  margin: 70px !important;
}

.mg-y-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mg-y-70-f {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mg-x-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mg-x-70-f {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mg-t-70 {
  margin-top: 70px;
}

.mg-r-70 {
  margin-right: 70px;
}

.mg-b-70 {
  margin-bottom: 70px;
}

.mg-l-70 {
  margin-left: 70px;
}

.mg-t-70-f {
  margin-top: 70px !important;
}

.mg-r-70-f {
  margin-right: 70px !important;
}

.mg-b-70-f {
  margin-bottom: 70px !important;
}

.mg-l-70-f {
  margin-left: 70px !important;
}

.mg-75 {
  margin: 75px;
}

.mg-75-f {
  margin: 75px !important;
}

.mg-y-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mg-y-75-f {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mg-x-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.mg-x-75-f {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.mg-t-75 {
  margin-top: 75px;
}

.mg-r-75 {
  margin-right: 75px;
}

.mg-b-75 {
  margin-bottom: 75px;
}

.mg-l-75 {
  margin-left: 75px;
}

.mg-t-75-f {
  margin-top: 75px !important;
}

.mg-r-75-f {
  margin-right: 75px !important;
}

.mg-b-75-f {
  margin-bottom: 75px !important;
}

.mg-l-75-f {
  margin-left: 75px !important;
}

.mg-80 {
  margin: 80px;
}

.mg-80-f {
  margin: 80px !important;
}

.mg-y-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mg-y-80-f {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mg-x-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mg-x-80-f {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mg-t-80 {
  margin-top: 80px;
}

.mg-r-80 {
  margin-right: 80px;
}

.mg-b-80 {
  margin-bottom: 80px;
}

.mg-l-80 {
  margin-left: 80px;
}

.mg-t-80-f {
  margin-top: 80px !important;
}

.mg-r-80-f {
  margin-right: 80px !important;
}

.mg-b-80-f {
  margin-bottom: 80px !important;
}

.mg-l-80-f {
  margin-left: 80px !important;
}

.mg-85 {
  margin: 85px;
}

.mg-85-f {
  margin: 85px !important;
}

.mg-y-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mg-y-85-f {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mg-x-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.mg-x-85-f {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.mg-t-85 {
  margin-top: 85px;
}

.mg-r-85 {
  margin-right: 85px;
}

.mg-b-85 {
  margin-bottom: 85px;
}

.mg-l-85 {
  margin-left: 85px;
}

.mg-t-85-f {
  margin-top: 85px !important;
}

.mg-r-85-f {
  margin-right: 85px !important;
}

.mg-b-85-f {
  margin-bottom: 85px !important;
}

.mg-l-85-f {
  margin-left: 85px !important;
}

.mg-90 {
  margin: 90px;
}

.mg-90-f {
  margin: 90px !important;
}

.mg-y-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mg-y-90-f {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mg-x-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mg-x-90-f {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mg-t-90 {
  margin-top: 90px;
}

.mg-r-90 {
  margin-right: 90px;
}

.mg-b-90 {
  margin-bottom: 90px;
}

.mg-l-90 {
  margin-left: 90px;
}

.mg-t-90-f {
  margin-top: 90px !important;
}

.mg-r-90-f {
  margin-right: 90px !important;
}

.mg-b-90-f {
  margin-bottom: 90px !important;
}

.mg-l-90-f {
  margin-left: 90px !important;
}

.mg-95 {
  margin: 95px;
}

.mg-95-f {
  margin: 95px !important;
}

.mg-y-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mg-y-95-f {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mg-x-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.mg-x-95-f {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.mg-t-95 {
  margin-top: 95px;
}

.mg-r-95 {
  margin-right: 95px;
}

.mg-b-95 {
  margin-bottom: 95px;
}

.mg-l-95 {
  margin-left: 95px;
}

.mg-t-95-f {
  margin-top: 95px !important;
}

.mg-r-95-f {
  margin-right: 95px !important;
}

.mg-b-95-f {
  margin-bottom: 95px !important;
}

.mg-l-95-f {
  margin-left: 95px !important;
}

.mg-100 {
  margin: 100px;
}

.mg-100-f {
  margin: 100px !important;
}

.mg-y-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mg-y-100-f {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mg-x-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mg-x-100-f {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.mg-t-100 {
  margin-top: 100px;
}

.mg-r-100 {
  margin-right: 100px;
}

.mg-b-100 {
  margin-bottom: 100px;
}

.mg-l-100 {
  margin-left: 100px;
}

.mg-t-100-f {
  margin-top: 100px !important;
}

.mg-r-100-f {
  margin-right: 100px !important;
}

.mg-b-100-f {
  margin-bottom: 100px !important;
}

.mg-l-100-f {
  margin-left: 100px !important;
}

.mg-105 {
  margin: 105px;
}

.mg-105-f {
  margin: 105px !important;
}

.mg-y-105 {
  margin-top: 105px;
  margin-bottom: 105px;
}

.mg-y-105-f {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.mg-x-105 {
  margin-left: 105px;
  margin-right: 105px;
}

.mg-x-105-f {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.mg-t-105 {
  margin-top: 105px;
}

.mg-r-105 {
  margin-right: 105px;
}

.mg-b-105 {
  margin-bottom: 105px;
}

.mg-l-105 {
  margin-left: 105px;
}

.mg-t-105-f {
  margin-top: 105px !important;
}

.mg-r-105-f {
  margin-right: 105px !important;
}

.mg-b-105-f {
  margin-bottom: 105px !important;
}

.mg-l-105-f {
  margin-left: 105px !important;
}

.mg-110 {
  margin: 110px;
}

.mg-110-f {
  margin: 110px !important;
}

.mg-y-110 {
  margin-top: 110px;
  margin-bottom: 110px;
}

.mg-y-110-f {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.mg-x-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.mg-x-110-f {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.mg-t-110 {
  margin-top: 110px;
}

.mg-r-110 {
  margin-right: 110px;
}

.mg-b-110 {
  margin-bottom: 110px;
}

.mg-l-110 {
  margin-left: 110px;
}

.mg-t-110-f {
  margin-top: 110px !important;
}

.mg-r-110-f {
  margin-right: 110px !important;
}

.mg-b-110-f {
  margin-bottom: 110px !important;
}

.mg-l-110-f {
  margin-left: 110px !important;
}

.mg-115 {
  margin: 115px;
}

.mg-115-f {
  margin: 115px !important;
}

.mg-y-115 {
  margin-top: 115px;
  margin-bottom: 115px;
}

.mg-y-115-f {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.mg-x-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.mg-x-115-f {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.mg-t-115 {
  margin-top: 115px;
}

.mg-r-115 {
  margin-right: 115px;
}

.mg-b-115 {
  margin-bottom: 115px;
}

.mg-l-115 {
  margin-left: 115px;
}

.mg-t-115-f {
  margin-top: 115px !important;
}

.mg-r-115-f {
  margin-right: 115px !important;
}

.mg-b-115-f {
  margin-bottom: 115px !important;
}

.mg-l-115-f {
  margin-left: 115px !important;
}

.mg-120 {
  margin: 120px;
}

.mg-120-f {
  margin: 120px !important;
}

.mg-y-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mg-y-120-f {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mg-x-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.mg-x-120-f {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.mg-t-120 {
  margin-top: 120px;
}

.mg-r-120 {
  margin-right: 120px;
}

.mg-b-120 {
  margin-bottom: 120px;
}

.mg-l-120 {
  margin-left: 120px;
}

.mg-t-120-f {
  margin-top: 120px !important;
}

.mg-r-120-f {
  margin-right: 120px !important;
}

.mg-b-120-f {
  margin-bottom: 120px !important;
}

.mg-l-120-f {
  margin-left: 120px !important;
}

.mg-t-auto {
  margin-top: auto;
}

.mg-r-auto {
  margin-right: auto;
}

.mg-b-auto {
  margin-bottom: auto;
}

.mg-l-auto {
  margin-left: auto;
}

.mg-x-auto {
  margin: auto;
}

@media (min-width: 480px) {
  .mg-xs-0 {
    margin: 0px;
  }

  .mg-xs-0-f {
    margin: 0px !important;
  }

  .mg-xs-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-xs-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-xs-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-xs-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-xs-t-0 {
    margin-top: 0px;
  }

  .mg-xs-r-0 {
    margin-right: 0px;
  }

  .mg-xs-b-0 {
    margin-bottom: 0px;
  }

  .mg-xs-l-0 {
    margin-left: 0px;
  }

  .mg-xs-t-0-f {
    margin-top: 0px !important;
  }

  .mg-xs-r-0-f {
    margin-right: 0px !important;
  }

  .mg-xs-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-xs-l-0-f {
    margin-left: 0px !important;
  }

  .mg-xs-1 {
    margin: 1px;
  }

  .mg-xs-1-f {
    margin: 1px !important;
  }

  .mg-xs-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-xs-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-xs-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-xs-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-xs-t-1 {
    margin-top: 1px;
  }

  .mg-xs-r-1 {
    margin-right: 1px;
  }

  .mg-xs-b-1 {
    margin-bottom: 1px;
  }

  .mg-xs-l-1 {
    margin-left: 1px;
  }

  .mg-xs-t-1-f {
    margin-top: 1px !important;
  }

  .mg-xs-r-1-f {
    margin-right: 1px !important;
  }

  .mg-xs-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-xs-l-1-f {
    margin-left: 1px !important;
  }

  .mg-xs-2 {
    margin: 2px;
  }

  .mg-xs-2-f {
    margin: 2px !important;
  }

  .mg-xs-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-xs-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-xs-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-xs-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-xs-t-2 {
    margin-top: 2px;
  }

  .mg-xs-r-2 {
    margin-right: 2px;
  }

  .mg-xs-b-2 {
    margin-bottom: 2px;
  }

  .mg-xs-l-2 {
    margin-left: 2px;
  }

  .mg-xs-t-2-f {
    margin-top: 2px !important;
  }

  .mg-xs-r-2-f {
    margin-right: 2px !important;
  }

  .mg-xs-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-xs-l-2-f {
    margin-left: 2px !important;
  }

  .mg-xs-3 {
    margin: 3px;
  }

  .mg-xs-3-f {
    margin: 3px !important;
  }

  .mg-xs-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-xs-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-xs-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-xs-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-xs-t-3 {
    margin-top: 3px;
  }

  .mg-xs-r-3 {
    margin-right: 3px;
  }

  .mg-xs-b-3 {
    margin-bottom: 3px;
  }

  .mg-xs-l-3 {
    margin-left: 3px;
  }

  .mg-xs-t-3-f {
    margin-top: 3px !important;
  }

  .mg-xs-r-3-f {
    margin-right: 3px !important;
  }

  .mg-xs-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-xs-l-3-f {
    margin-left: 3px !important;
  }

  .mg-xs-4 {
    margin: 4px;
  }

  .mg-xs-4-f {
    margin: 4px !important;
  }

  .mg-xs-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-xs-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-xs-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-xs-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-xs-t-4 {
    margin-top: 4px;
  }

  .mg-xs-r-4 {
    margin-right: 4px;
  }

  .mg-xs-b-4 {
    margin-bottom: 4px;
  }

  .mg-xs-l-4 {
    margin-left: 4px;
  }

  .mg-xs-t-4-f {
    margin-top: 4px !important;
  }

  .mg-xs-r-4-f {
    margin-right: 4px !important;
  }

  .mg-xs-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-xs-l-4-f {
    margin-left: 4px !important;
  }

  .mg-xs-5 {
    margin: 5px;
  }

  .mg-xs-5-f {
    margin: 5px !important;
  }

  .mg-xs-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-xs-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-xs-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-xs-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-xs-t-5 {
    margin-top: 5px;
  }

  .mg-xs-r-5 {
    margin-right: 5px;
  }

  .mg-xs-b-5 {
    margin-bottom: 5px;
  }

  .mg-xs-l-5 {
    margin-left: 5px;
  }

  .mg-xs-t-5-f {
    margin-top: 5px !important;
  }

  .mg-xs-r-5-f {
    margin-right: 5px !important;
  }

  .mg-xs-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-xs-l-5-f {
    margin-left: 5px !important;
  }

  .mg-xs-6 {
    margin: 6px;
  }

  .mg-xs-6-f {
    margin: 6px !important;
  }

  .mg-xs-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-xs-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-xs-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-xs-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-xs-t-6 {
    margin-top: 6px;
  }

  .mg-xs-r-6 {
    margin-right: 6px;
  }

  .mg-xs-b-6 {
    margin-bottom: 6px;
  }

  .mg-xs-l-6 {
    margin-left: 6px;
  }

  .mg-xs-t-6-f {
    margin-top: 6px !important;
  }

  .mg-xs-r-6-f {
    margin-right: 6px !important;
  }

  .mg-xs-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-xs-l-6-f {
    margin-left: 6px !important;
  }

  .mg-xs-7 {
    margin: 7px;
  }

  .mg-xs-7-f {
    margin: 7px !important;
  }

  .mg-xs-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-xs-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-xs-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-xs-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-xs-t-7 {
    margin-top: 7px;
  }

  .mg-xs-r-7 {
    margin-right: 7px;
  }

  .mg-xs-b-7 {
    margin-bottom: 7px;
  }

  .mg-xs-l-7 {
    margin-left: 7px;
  }

  .mg-xs-t-7-f {
    margin-top: 7px !important;
  }

  .mg-xs-r-7-f {
    margin-right: 7px !important;
  }

  .mg-xs-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-xs-l-7-f {
    margin-left: 7px !important;
  }

  .mg-xs-8 {
    margin: 8px;
  }

  .mg-xs-8-f {
    margin: 8px !important;
  }

  .mg-xs-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-xs-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-xs-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-xs-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-xs-t-8 {
    margin-top: 8px;
  }

  .mg-xs-r-8 {
    margin-right: 8px;
  }

  .mg-xs-b-8 {
    margin-bottom: 8px;
  }

  .mg-xs-l-8 {
    margin-left: 8px;
  }

  .mg-xs-t-8-f {
    margin-top: 8px !important;
  }

  .mg-xs-r-8-f {
    margin-right: 8px !important;
  }

  .mg-xs-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-xs-l-8-f {
    margin-left: 8px !important;
  }

  .mg-xs-9 {
    margin: 9px;
  }

  .mg-xs-9-f {
    margin: 9px !important;
  }

  .mg-xs-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-xs-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-xs-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-xs-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-xs-t-9 {
    margin-top: 9px;
  }

  .mg-xs-r-9 {
    margin-right: 9px;
  }

  .mg-xs-b-9 {
    margin-bottom: 9px;
  }

  .mg-xs-l-9 {
    margin-left: 9px;
  }

  .mg-xs-t-9-f {
    margin-top: 9px !important;
  }

  .mg-xs-r-9-f {
    margin-right: 9px !important;
  }

  .mg-xs-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-xs-l-9-f {
    margin-left: 9px !important;
  }

  .mg-xs-10 {
    margin: 10px;
  }

  .mg-xs-10-f {
    margin: 10px !important;
  }

  .mg-xs-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-xs-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-xs-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-xs-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-xs-t-10 {
    margin-top: 10px;
  }

  .mg-xs-r-10 {
    margin-right: 10px;
  }

  .mg-xs-b-10 {
    margin-bottom: 10px;
  }

  .mg-xs-l-10 {
    margin-left: 10px;
  }

  .mg-xs-t-10-f {
    margin-top: 10px !important;
  }

  .mg-xs-r-10-f {
    margin-right: 10px !important;
  }

  .mg-xs-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-xs-l-10-f {
    margin-left: 10px !important;
  }

  .mg-xs-15 {
    margin: 15px;
  }

  .mg-xs-15-f {
    margin: 15px !important;
  }

  .mg-xs-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-xs-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-xs-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-xs-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-xs-t-15 {
    margin-top: 15px;
  }

  .mg-xs-r-15 {
    margin-right: 15px;
  }

  .mg-xs-b-15 {
    margin-bottom: 15px;
  }

  .mg-xs-l-15 {
    margin-left: 15px;
  }

  .mg-xs-t-15-f {
    margin-top: 15px !important;
  }

  .mg-xs-r-15-f {
    margin-right: 15px !important;
  }

  .mg-xs-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-xs-l-15-f {
    margin-left: 15px !important;
  }

  .mg-xs-20 {
    margin: 20px;
  }

  .mg-xs-20-f {
    margin: 20px !important;
  }

  .mg-xs-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-xs-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-xs-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-xs-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-xs-t-20 {
    margin-top: 20px;
  }

  .mg-xs-r-20 {
    margin-right: 20px;
  }

  .mg-xs-b-20 {
    margin-bottom: 20px;
  }

  .mg-xs-l-20 {
    margin-left: 20px;
  }

  .mg-xs-t-20-f {
    margin-top: 20px !important;
  }

  .mg-xs-r-20-f {
    margin-right: 20px !important;
  }

  .mg-xs-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-xs-l-20-f {
    margin-left: 20px !important;
  }

  .mg-xs-25 {
    margin: 25px;
  }

  .mg-xs-25-f {
    margin: 25px !important;
  }

  .mg-xs-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-xs-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-xs-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-xs-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-xs-t-25 {
    margin-top: 25px;
  }

  .mg-xs-r-25 {
    margin-right: 25px;
  }

  .mg-xs-b-25 {
    margin-bottom: 25px;
  }

  .mg-xs-l-25 {
    margin-left: 25px;
  }

  .mg-xs-t-25-f {
    margin-top: 25px !important;
  }

  .mg-xs-r-25-f {
    margin-right: 25px !important;
  }

  .mg-xs-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-xs-l-25-f {
    margin-left: 25px !important;
  }

  .mg-xs-30 {
    margin: 30px;
  }

  .mg-xs-30-f {
    margin: 30px !important;
  }

  .mg-xs-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-xs-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-xs-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-xs-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-xs-t-30 {
    margin-top: 30px;
  }

  .mg-xs-r-30 {
    margin-right: 30px;
  }

  .mg-xs-b-30 {
    margin-bottom: 30px;
  }

  .mg-xs-l-30 {
    margin-left: 30px;
  }

  .mg-xs-t-30-f {
    margin-top: 30px !important;
  }

  .mg-xs-r-30-f {
    margin-right: 30px !important;
  }

  .mg-xs-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-xs-l-30-f {
    margin-left: 30px !important;
  }

  .mg-xs-35 {
    margin: 35px;
  }

  .mg-xs-35-f {
    margin: 35px !important;
  }

  .mg-xs-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-xs-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-xs-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-xs-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-xs-t-35 {
    margin-top: 35px;
  }

  .mg-xs-r-35 {
    margin-right: 35px;
  }

  .mg-xs-b-35 {
    margin-bottom: 35px;
  }

  .mg-xs-l-35 {
    margin-left: 35px;
  }

  .mg-xs-t-35-f {
    margin-top: 35px !important;
  }

  .mg-xs-r-35-f {
    margin-right: 35px !important;
  }

  .mg-xs-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-xs-l-35-f {
    margin-left: 35px !important;
  }

  .mg-xs-40 {
    margin: 40px;
  }

  .mg-xs-40-f {
    margin: 40px !important;
  }

  .mg-xs-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-xs-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-xs-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-xs-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-xs-t-40 {
    margin-top: 40px;
  }

  .mg-xs-r-40 {
    margin-right: 40px;
  }

  .mg-xs-b-40 {
    margin-bottom: 40px;
  }

  .mg-xs-l-40 {
    margin-left: 40px;
  }

  .mg-xs-t-40-f {
    margin-top: 40px !important;
  }

  .mg-xs-r-40-f {
    margin-right: 40px !important;
  }

  .mg-xs-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-xs-l-40-f {
    margin-left: 40px !important;
  }

  .mg-xs-45 {
    margin: 45px;
  }

  .mg-xs-45-f {
    margin: 45px !important;
  }

  .mg-xs-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-xs-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-xs-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-xs-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-xs-t-45 {
    margin-top: 45px;
  }

  .mg-xs-r-45 {
    margin-right: 45px;
  }

  .mg-xs-b-45 {
    margin-bottom: 45px;
  }

  .mg-xs-l-45 {
    margin-left: 45px;
  }

  .mg-xs-t-45-f {
    margin-top: 45px !important;
  }

  .mg-xs-r-45-f {
    margin-right: 45px !important;
  }

  .mg-xs-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-xs-l-45-f {
    margin-left: 45px !important;
  }

  .mg-xs-50 {
    margin: 50px;
  }

  .mg-xs-50-f {
    margin: 50px !important;
  }

  .mg-xs-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-xs-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-xs-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-xs-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-xs-t-50 {
    margin-top: 50px;
  }

  .mg-xs-r-50 {
    margin-right: 50px;
  }

  .mg-xs-b-50 {
    margin-bottom: 50px;
  }

  .mg-xs-l-50 {
    margin-left: 50px;
  }

  .mg-xs-t-50-f {
    margin-top: 50px !important;
  }

  .mg-xs-r-50-f {
    margin-right: 50px !important;
  }

  .mg-xs-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-xs-l-50-f {
    margin-left: 50px !important;
  }

  .mg-xs-55 {
    margin: 55px;
  }

  .mg-xs-55-f {
    margin: 55px !important;
  }

  .mg-xs-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-xs-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-xs-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-xs-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-xs-t-55 {
    margin-top: 55px;
  }

  .mg-xs-r-55 {
    margin-right: 55px;
  }

  .mg-xs-b-55 {
    margin-bottom: 55px;
  }

  .mg-xs-l-55 {
    margin-left: 55px;
  }

  .mg-xs-t-55-f {
    margin-top: 55px !important;
  }

  .mg-xs-r-55-f {
    margin-right: 55px !important;
  }

  .mg-xs-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-xs-l-55-f {
    margin-left: 55px !important;
  }

  .mg-xs-60 {
    margin: 60px;
  }

  .mg-xs-60-f {
    margin: 60px !important;
  }

  .mg-xs-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-xs-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-xs-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-xs-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-xs-t-60 {
    margin-top: 60px;
  }

  .mg-xs-r-60 {
    margin-right: 60px;
  }

  .mg-xs-b-60 {
    margin-bottom: 60px;
  }

  .mg-xs-l-60 {
    margin-left: 60px;
  }

  .mg-xs-t-60-f {
    margin-top: 60px !important;
  }

  .mg-xs-r-60-f {
    margin-right: 60px !important;
  }

  .mg-xs-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-xs-l-60-f {
    margin-left: 60px !important;
  }

  .mg-xs-65 {
    margin: 65px;
  }

  .mg-xs-65-f {
    margin: 65px !important;
  }

  .mg-xs-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-xs-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-xs-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-xs-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-xs-t-65 {
    margin-top: 65px;
  }

  .mg-xs-r-65 {
    margin-right: 65px;
  }

  .mg-xs-b-65 {
    margin-bottom: 65px;
  }

  .mg-xs-l-65 {
    margin-left: 65px;
  }

  .mg-xs-t-65-f {
    margin-top: 65px !important;
  }

  .mg-xs-r-65-f {
    margin-right: 65px !important;
  }

  .mg-xs-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-xs-l-65-f {
    margin-left: 65px !important;
  }

  .mg-xs-70 {
    margin: 70px;
  }

  .mg-xs-70-f {
    margin: 70px !important;
  }

  .mg-xs-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-xs-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-xs-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-xs-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-xs-t-70 {
    margin-top: 70px;
  }

  .mg-xs-r-70 {
    margin-right: 70px;
  }

  .mg-xs-b-70 {
    margin-bottom: 70px;
  }

  .mg-xs-l-70 {
    margin-left: 70px;
  }

  .mg-xs-t-70-f {
    margin-top: 70px !important;
  }

  .mg-xs-r-70-f {
    margin-right: 70px !important;
  }

  .mg-xs-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-xs-l-70-f {
    margin-left: 70px !important;
  }

  .mg-xs-75 {
    margin: 75px;
  }

  .mg-xs-75-f {
    margin: 75px !important;
  }

  .mg-xs-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-xs-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-xs-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-xs-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-xs-t-75 {
    margin-top: 75px;
  }

  .mg-xs-r-75 {
    margin-right: 75px;
  }

  .mg-xs-b-75 {
    margin-bottom: 75px;
  }

  .mg-xs-l-75 {
    margin-left: 75px;
  }

  .mg-xs-t-75-f {
    margin-top: 75px !important;
  }

  .mg-xs-r-75-f {
    margin-right: 75px !important;
  }

  .mg-xs-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-xs-l-75-f {
    margin-left: 75px !important;
  }

  .mg-xs-80 {
    margin: 80px;
  }

  .mg-xs-80-f {
    margin: 80px !important;
  }

  .mg-xs-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-xs-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-xs-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-xs-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-xs-t-80 {
    margin-top: 80px;
  }

  .mg-xs-r-80 {
    margin-right: 80px;
  }

  .mg-xs-b-80 {
    margin-bottom: 80px;
  }

  .mg-xs-l-80 {
    margin-left: 80px;
  }

  .mg-xs-t-80-f {
    margin-top: 80px !important;
  }

  .mg-xs-r-80-f {
    margin-right: 80px !important;
  }

  .mg-xs-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-xs-l-80-f {
    margin-left: 80px !important;
  }

  .mg-xs-85 {
    margin: 85px;
  }

  .mg-xs-85-f {
    margin: 85px !important;
  }

  .mg-xs-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-xs-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-xs-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-xs-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-xs-t-85 {
    margin-top: 85px;
  }

  .mg-xs-r-85 {
    margin-right: 85px;
  }

  .mg-xs-b-85 {
    margin-bottom: 85px;
  }

  .mg-xs-l-85 {
    margin-left: 85px;
  }

  .mg-xs-t-85-f {
    margin-top: 85px !important;
  }

  .mg-xs-r-85-f {
    margin-right: 85px !important;
  }

  .mg-xs-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-xs-l-85-f {
    margin-left: 85px !important;
  }

  .mg-xs-90 {
    margin: 90px;
  }

  .mg-xs-90-f {
    margin: 90px !important;
  }

  .mg-xs-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-xs-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-xs-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-xs-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-xs-t-90 {
    margin-top: 90px;
  }

  .mg-xs-r-90 {
    margin-right: 90px;
  }

  .mg-xs-b-90 {
    margin-bottom: 90px;
  }

  .mg-xs-l-90 {
    margin-left: 90px;
  }

  .mg-xs-t-90-f {
    margin-top: 90px !important;
  }

  .mg-xs-r-90-f {
    margin-right: 90px !important;
  }

  .mg-xs-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-xs-l-90-f {
    margin-left: 90px !important;
  }

  .mg-xs-95 {
    margin: 95px;
  }

  .mg-xs-95-f {
    margin: 95px !important;
  }

  .mg-xs-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-xs-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-xs-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-xs-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-xs-t-95 {
    margin-top: 95px;
  }

  .mg-xs-r-95 {
    margin-right: 95px;
  }

  .mg-xs-b-95 {
    margin-bottom: 95px;
  }

  .mg-xs-l-95 {
    margin-left: 95px;
  }

  .mg-xs-t-95-f {
    margin-top: 95px !important;
  }

  .mg-xs-r-95-f {
    margin-right: 95px !important;
  }

  .mg-xs-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-xs-l-95-f {
    margin-left: 95px !important;
  }

  .mg-xs-100 {
    margin: 100px;
  }

  .mg-xs-100-f {
    margin: 100px !important;
  }

  .mg-xs-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-xs-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-xs-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-xs-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-xs-t-100 {
    margin-top: 100px;
  }

  .mg-xs-r-100 {
    margin-right: 100px;
  }

  .mg-xs-b-100 {
    margin-bottom: 100px;
  }

  .mg-xs-l-100 {
    margin-left: 100px;
  }

  .mg-xs-t-100-f {
    margin-top: 100px !important;
  }

  .mg-xs-r-100-f {
    margin-right: 100px !important;
  }

  .mg-xs-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-xs-l-100-f {
    margin-left: 100px !important;
  }

  .mg-xs-t-auto {
    margin-top: auto;
  }

  .mg-xs-r-auto {
    margin-right: auto;
  }

  .mg-xs-b-auto {
    margin-bottom: auto;
  }

  .mg-xs-l-auto {
    margin-left: auto;
  }

  .mg-xs-auto {
    margin: auto;
  }

  .mg-xs-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 576px) {
  .mg-sm-0 {
    margin: 0px;
  }

  .mg-sm-0-f {
    margin: 0px !important;
  }

  .mg-sm-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-sm-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-sm-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-sm-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-sm-t-0 {
    margin-top: 0px;
  }

  .mg-sm-r-0 {
    margin-right: 0px;
  }

  .mg-sm-b-0 {
    margin-bottom: 0px;
  }

  .mg-sm-l-0 {
    margin-left: 0px;
  }

  .mg-sm-t-0-f {
    margin-top: 0px !important;
  }

  .mg-sm-r-0-f {
    margin-right: 0px !important;
  }

  .mg-sm-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-sm-l-0-f {
    margin-left: 0px !important;
  }

  .mg-sm-1 {
    margin: 1px;
  }

  .mg-sm-1-f {
    margin: 1px !important;
  }

  .mg-sm-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-sm-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-sm-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-sm-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-sm-t-1 {
    margin-top: 1px;
  }

  .mg-sm-r-1 {
    margin-right: 1px;
  }

  .mg-sm-b-1 {
    margin-bottom: 1px;
  }

  .mg-sm-l-1 {
    margin-left: 1px;
  }

  .mg-sm-t-1-f {
    margin-top: 1px !important;
  }

  .mg-sm-r-1-f {
    margin-right: 1px !important;
  }

  .mg-sm-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-sm-l-1-f {
    margin-left: 1px !important;
  }

  .mg-sm-2 {
    margin: 2px;
  }

  .mg-sm-2-f {
    margin: 2px !important;
  }

  .mg-sm-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-sm-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-sm-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-sm-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-sm-t-2 {
    margin-top: 2px;
  }

  .mg-sm-r-2 {
    margin-right: 2px;
  }

  .mg-sm-b-2 {
    margin-bottom: 2px;
  }

  .mg-sm-l-2 {
    margin-left: 2px;
  }

  .mg-sm-t-2-f {
    margin-top: 2px !important;
  }

  .mg-sm-r-2-f {
    margin-right: 2px !important;
  }

  .mg-sm-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-sm-l-2-f {
    margin-left: 2px !important;
  }

  .mg-sm-3 {
    margin: 3px;
  }

  .mg-sm-3-f {
    margin: 3px !important;
  }

  .mg-sm-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-sm-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-sm-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-sm-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-sm-t-3 {
    margin-top: 3px;
  }

  .mg-sm-r-3 {
    margin-right: 3px;
  }

  .mg-sm-b-3 {
    margin-bottom: 3px;
  }

  .mg-sm-l-3 {
    margin-left: 3px;
  }

  .mg-sm-t-3-f {
    margin-top: 3px !important;
  }

  .mg-sm-r-3-f {
    margin-right: 3px !important;
  }

  .mg-sm-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-sm-l-3-f {
    margin-left: 3px !important;
  }

  .mg-sm-4 {
    margin: 4px;
  }

  .mg-sm-4-f {
    margin: 4px !important;
  }

  .mg-sm-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-sm-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-sm-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-sm-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-sm-t-4 {
    margin-top: 4px;
  }

  .mg-sm-r-4 {
    margin-right: 4px;
  }

  .mg-sm-b-4 {
    margin-bottom: 4px;
  }

  .mg-sm-l-4 {
    margin-left: 4px;
  }

  .mg-sm-t-4-f {
    margin-top: 4px !important;
  }

  .mg-sm-r-4-f {
    margin-right: 4px !important;
  }

  .mg-sm-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-sm-l-4-f {
    margin-left: 4px !important;
  }

  .mg-sm-5 {
    margin: 5px;
  }

  .mg-sm-5-f {
    margin: 5px !important;
  }

  .mg-sm-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-sm-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-sm-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-sm-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-sm-t-5 {
    margin-top: 5px;
  }

  .mg-sm-r-5 {
    margin-right: 5px;
  }

  .mg-sm-b-5 {
    margin-bottom: 5px;
  }

  .mg-sm-l-5 {
    margin-left: 5px;
  }

  .mg-sm-t-5-f {
    margin-top: 5px !important;
  }

  .mg-sm-r-5-f {
    margin-right: 5px !important;
  }

  .mg-sm-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-sm-l-5-f {
    margin-left: 5px !important;
  }

  .mg-sm-6 {
    margin: 6px;
  }

  .mg-sm-6-f {
    margin: 6px !important;
  }

  .mg-sm-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-sm-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-sm-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-sm-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-sm-t-6 {
    margin-top: 6px;
  }

  .mg-sm-r-6 {
    margin-right: 6px;
  }

  .mg-sm-b-6 {
    margin-bottom: 6px;
  }

  .mg-sm-l-6 {
    margin-left: 6px;
  }

  .mg-sm-t-6-f {
    margin-top: 6px !important;
  }

  .mg-sm-r-6-f {
    margin-right: 6px !important;
  }

  .mg-sm-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-sm-l-6-f {
    margin-left: 6px !important;
  }

  .mg-sm-7 {
    margin: 7px;
  }

  .mg-sm-7-f {
    margin: 7px !important;
  }

  .mg-sm-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-sm-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-sm-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-sm-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-sm-t-7 {
    margin-top: 7px;
  }

  .mg-sm-r-7 {
    margin-right: 7px;
  }

  .mg-sm-b-7 {
    margin-bottom: 7px;
  }

  .mg-sm-l-7 {
    margin-left: 7px;
  }

  .mg-sm-t-7-f {
    margin-top: 7px !important;
  }

  .mg-sm-r-7-f {
    margin-right: 7px !important;
  }

  .mg-sm-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-sm-l-7-f {
    margin-left: 7px !important;
  }

  .mg-sm-8 {
    margin: 8px;
  }

  .mg-sm-8-f {
    margin: 8px !important;
  }

  .mg-sm-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-sm-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-sm-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-sm-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-sm-t-8 {
    margin-top: 8px;
  }

  .mg-sm-r-8 {
    margin-right: 8px;
  }

  .mg-sm-b-8 {
    margin-bottom: 8px;
  }

  .mg-sm-l-8 {
    margin-left: 8px;
  }

  .mg-sm-t-8-f {
    margin-top: 8px !important;
  }

  .mg-sm-r-8-f {
    margin-right: 8px !important;
  }

  .mg-sm-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-sm-l-8-f {
    margin-left: 8px !important;
  }

  .mg-sm-9 {
    margin: 9px;
  }

  .mg-sm-9-f {
    margin: 9px !important;
  }

  .mg-sm-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-sm-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-sm-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-sm-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-sm-t-9 {
    margin-top: 9px;
  }

  .mg-sm-r-9 {
    margin-right: 9px;
  }

  .mg-sm-b-9 {
    margin-bottom: 9px;
  }

  .mg-sm-l-9 {
    margin-left: 9px;
  }

  .mg-sm-t-9-f {
    margin-top: 9px !important;
  }

  .mg-sm-r-9-f {
    margin-right: 9px !important;
  }

  .mg-sm-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-sm-l-9-f {
    margin-left: 9px !important;
  }

  .mg-sm-10 {
    margin: 10px;
  }

  .mg-sm-10-f {
    margin: 10px !important;
  }

  .mg-sm-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-sm-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-sm-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-sm-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-sm-t-10 {
    margin-top: 10px;
  }

  .mg-sm-r-10 {
    margin-right: 10px;
  }

  .mg-sm-b-10 {
    margin-bottom: 10px;
  }

  .mg-sm-l-10 {
    margin-left: 10px;
  }

  .mg-sm-t-10-f {
    margin-top: 10px !important;
  }

  .mg-sm-r-10-f {
    margin-right: 10px !important;
  }

  .mg-sm-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-sm-l-10-f {
    margin-left: 10px !important;
  }

  .mg-sm-15 {
    margin: 15px;
  }

  .mg-sm-15-f {
    margin: 15px !important;
  }

  .mg-sm-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-sm-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-sm-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-sm-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-sm-t-15 {
    margin-top: 15px;
  }

  .mg-sm-r-15 {
    margin-right: 15px;
  }

  .mg-sm-b-15 {
    margin-bottom: 15px;
  }

  .mg-sm-l-15 {
    margin-left: 15px;
  }

  .mg-sm-t-15-f {
    margin-top: 15px !important;
  }

  .mg-sm-r-15-f {
    margin-right: 15px !important;
  }

  .mg-sm-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-sm-l-15-f {
    margin-left: 15px !important;
  }

  .mg-sm-20 {
    margin: 20px;
  }

  .mg-sm-20-f {
    margin: 20px !important;
  }

  .mg-sm-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-sm-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-sm-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-sm-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-sm-t-20 {
    margin-top: 20px;
  }

  .mg-sm-r-20 {
    margin-right: 20px;
  }

  .mg-sm-b-20 {
    margin-bottom: 20px;
  }

  .mg-sm-l-20 {
    margin-left: 20px;
  }

  .mg-sm-t-20-f {
    margin-top: 20px !important;
  }

  .mg-sm-r-20-f {
    margin-right: 20px !important;
  }

  .mg-sm-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-sm-l-20-f {
    margin-left: 20px !important;
  }

  .mg-sm-25 {
    margin: 25px;
  }

  .mg-sm-25-f {
    margin: 25px !important;
  }

  .mg-sm-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-sm-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-sm-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-sm-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-sm-t-25 {
    margin-top: 25px;
  }

  .mg-sm-r-25 {
    margin-right: 25px;
  }

  .mg-sm-b-25 {
    margin-bottom: 25px;
  }

  .mg-sm-l-25 {
    margin-left: 25px;
  }

  .mg-sm-t-25-f {
    margin-top: 25px !important;
  }

  .mg-sm-r-25-f {
    margin-right: 25px !important;
  }

  .mg-sm-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-sm-l-25-f {
    margin-left: 25px !important;
  }

  .mg-sm-30 {
    margin: 30px;
  }

  .mg-sm-30-f {
    margin: 30px !important;
  }

  .mg-sm-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-sm-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-sm-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-sm-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-sm-t-30 {
    margin-top: 30px;
  }

  .mg-sm-r-30 {
    margin-right: 30px;
  }

  .mg-sm-b-30 {
    margin-bottom: 30px;
  }

  .mg-sm-l-30 {
    margin-left: 30px;
  }

  .mg-sm-t-30-f {
    margin-top: 30px !important;
  }

  .mg-sm-r-30-f {
    margin-right: 30px !important;
  }

  .mg-sm-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-sm-l-30-f {
    margin-left: 30px !important;
  }

  .mg-sm-35 {
    margin: 35px;
  }

  .mg-sm-35-f {
    margin: 35px !important;
  }

  .mg-sm-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-sm-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-sm-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-sm-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-sm-t-35 {
    margin-top: 35px;
  }

  .mg-sm-r-35 {
    margin-right: 35px;
  }

  .mg-sm-b-35 {
    margin-bottom: 35px;
  }

  .mg-sm-l-35 {
    margin-left: 35px;
  }

  .mg-sm-t-35-f {
    margin-top: 35px !important;
  }

  .mg-sm-r-35-f {
    margin-right: 35px !important;
  }

  .mg-sm-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-sm-l-35-f {
    margin-left: 35px !important;
  }

  .mg-sm-40 {
    margin: 40px;
  }

  .mg-sm-40-f {
    margin: 40px !important;
  }

  .mg-sm-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-sm-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-sm-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-sm-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-sm-t-40 {
    margin-top: 40px;
  }

  .mg-sm-r-40 {
    margin-right: 40px;
  }

  .mg-sm-b-40 {
    margin-bottom: 40px;
  }

  .mg-sm-l-40 {
    margin-left: 40px;
  }

  .mg-sm-t-40-f {
    margin-top: 40px !important;
  }

  .mg-sm-r-40-f {
    margin-right: 40px !important;
  }

  .mg-sm-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-sm-l-40-f {
    margin-left: 40px !important;
  }

  .mg-sm-45 {
    margin: 45px;
  }

  .mg-sm-45-f {
    margin: 45px !important;
  }

  .mg-sm-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-sm-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-sm-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-sm-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-sm-t-45 {
    margin-top: 45px;
  }

  .mg-sm-r-45 {
    margin-right: 45px;
  }

  .mg-sm-b-45 {
    margin-bottom: 45px;
  }

  .mg-sm-l-45 {
    margin-left: 45px;
  }

  .mg-sm-t-45-f {
    margin-top: 45px !important;
  }

  .mg-sm-r-45-f {
    margin-right: 45px !important;
  }

  .mg-sm-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-sm-l-45-f {
    margin-left: 45px !important;
  }

  .mg-sm-50 {
    margin: 50px;
  }

  .mg-sm-50-f {
    margin: 50px !important;
  }

  .mg-sm-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-sm-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-sm-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-sm-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-sm-t-50 {
    margin-top: 50px;
  }

  .mg-sm-r-50 {
    margin-right: 50px;
  }

  .mg-sm-b-50 {
    margin-bottom: 50px;
  }

  .mg-sm-l-50 {
    margin-left: 50px;
  }

  .mg-sm-t-50-f {
    margin-top: 50px !important;
  }

  .mg-sm-r-50-f {
    margin-right: 50px !important;
  }

  .mg-sm-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-sm-l-50-f {
    margin-left: 50px !important;
  }

  .mg-sm-55 {
    margin: 55px;
  }

  .mg-sm-55-f {
    margin: 55px !important;
  }

  .mg-sm-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-sm-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-sm-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-sm-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-sm-t-55 {
    margin-top: 55px;
  }

  .mg-sm-r-55 {
    margin-right: 55px;
  }

  .mg-sm-b-55 {
    margin-bottom: 55px;
  }

  .mg-sm-l-55 {
    margin-left: 55px;
  }

  .mg-sm-t-55-f {
    margin-top: 55px !important;
  }

  .mg-sm-r-55-f {
    margin-right: 55px !important;
  }

  .mg-sm-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-sm-l-55-f {
    margin-left: 55px !important;
  }

  .mg-sm-60 {
    margin: 60px;
  }

  .mg-sm-60-f {
    margin: 60px !important;
  }

  .mg-sm-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-sm-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-sm-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-sm-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-sm-t-60 {
    margin-top: 60px;
  }

  .mg-sm-r-60 {
    margin-right: 60px;
  }

  .mg-sm-b-60 {
    margin-bottom: 60px;
  }

  .mg-sm-l-60 {
    margin-left: 60px;
  }

  .mg-sm-t-60-f {
    margin-top: 60px !important;
  }

  .mg-sm-r-60-f {
    margin-right: 60px !important;
  }

  .mg-sm-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-sm-l-60-f {
    margin-left: 60px !important;
  }

  .mg-sm-65 {
    margin: 65px;
  }

  .mg-sm-65-f {
    margin: 65px !important;
  }

  .mg-sm-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-sm-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-sm-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-sm-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-sm-t-65 {
    margin-top: 65px;
  }

  .mg-sm-r-65 {
    margin-right: 65px;
  }

  .mg-sm-b-65 {
    margin-bottom: 65px;
  }

  .mg-sm-l-65 {
    margin-left: 65px;
  }

  .mg-sm-t-65-f {
    margin-top: 65px !important;
  }

  .mg-sm-r-65-f {
    margin-right: 65px !important;
  }

  .mg-sm-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-sm-l-65-f {
    margin-left: 65px !important;
  }

  .mg-sm-70 {
    margin: 70px;
  }

  .mg-sm-70-f {
    margin: 70px !important;
  }

  .mg-sm-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-sm-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-sm-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-sm-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-sm-t-70 {
    margin-top: 70px;
  }

  .mg-sm-r-70 {
    margin-right: 70px;
  }

  .mg-sm-b-70 {
    margin-bottom: 70px;
  }

  .mg-sm-l-70 {
    margin-left: 70px;
  }

  .mg-sm-t-70-f {
    margin-top: 70px !important;
  }

  .mg-sm-r-70-f {
    margin-right: 70px !important;
  }

  .mg-sm-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-sm-l-70-f {
    margin-left: 70px !important;
  }

  .mg-sm-75 {
    margin: 75px;
  }

  .mg-sm-75-f {
    margin: 75px !important;
  }

  .mg-sm-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-sm-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-sm-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-sm-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-sm-t-75 {
    margin-top: 75px;
  }

  .mg-sm-r-75 {
    margin-right: 75px;
  }

  .mg-sm-b-75 {
    margin-bottom: 75px;
  }

  .mg-sm-l-75 {
    margin-left: 75px;
  }

  .mg-sm-t-75-f {
    margin-top: 75px !important;
  }

  .mg-sm-r-75-f {
    margin-right: 75px !important;
  }

  .mg-sm-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-sm-l-75-f {
    margin-left: 75px !important;
  }

  .mg-sm-80 {
    margin: 80px;
  }

  .mg-sm-80-f {
    margin: 80px !important;
  }

  .mg-sm-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-sm-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-sm-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-sm-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-sm-t-80 {
    margin-top: 80px;
  }

  .mg-sm-r-80 {
    margin-right: 80px;
  }

  .mg-sm-b-80 {
    margin-bottom: 80px;
  }

  .mg-sm-l-80 {
    margin-left: 80px;
  }

  .mg-sm-t-80-f {
    margin-top: 80px !important;
  }

  .mg-sm-r-80-f {
    margin-right: 80px !important;
  }

  .mg-sm-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-sm-l-80-f {
    margin-left: 80px !important;
  }

  .mg-sm-85 {
    margin: 85px;
  }

  .mg-sm-85-f {
    margin: 85px !important;
  }

  .mg-sm-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-sm-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-sm-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-sm-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-sm-t-85 {
    margin-top: 85px;
  }

  .mg-sm-r-85 {
    margin-right: 85px;
  }

  .mg-sm-b-85 {
    margin-bottom: 85px;
  }

  .mg-sm-l-85 {
    margin-left: 85px;
  }

  .mg-sm-t-85-f {
    margin-top: 85px !important;
  }

  .mg-sm-r-85-f {
    margin-right: 85px !important;
  }

  .mg-sm-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-sm-l-85-f {
    margin-left: 85px !important;
  }

  .mg-sm-90 {
    margin: 90px;
  }

  .mg-sm-90-f {
    margin: 90px !important;
  }

  .mg-sm-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-sm-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-sm-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-sm-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-sm-t-90 {
    margin-top: 90px;
  }

  .mg-sm-r-90 {
    margin-right: 90px;
  }

  .mg-sm-b-90 {
    margin-bottom: 90px;
  }

  .mg-sm-l-90 {
    margin-left: 90px;
  }

  .mg-sm-t-90-f {
    margin-top: 90px !important;
  }

  .mg-sm-r-90-f {
    margin-right: 90px !important;
  }

  .mg-sm-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-sm-l-90-f {
    margin-left: 90px !important;
  }

  .mg-sm-95 {
    margin: 95px;
  }

  .mg-sm-95-f {
    margin: 95px !important;
  }

  .mg-sm-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-sm-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-sm-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-sm-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-sm-t-95 {
    margin-top: 95px;
  }

  .mg-sm-r-95 {
    margin-right: 95px;
  }

  .mg-sm-b-95 {
    margin-bottom: 95px;
  }

  .mg-sm-l-95 {
    margin-left: 95px;
  }

  .mg-sm-t-95-f {
    margin-top: 95px !important;
  }

  .mg-sm-r-95-f {
    margin-right: 95px !important;
  }

  .mg-sm-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-sm-l-95-f {
    margin-left: 95px !important;
  }

  .mg-sm-100 {
    margin: 100px;
  }

  .mg-sm-100-f {
    margin: 100px !important;
  }

  .mg-sm-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-sm-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-sm-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-sm-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-sm-t-100 {
    margin-top: 100px;
  }

  .mg-sm-r-100 {
    margin-right: 100px;
  }

  .mg-sm-b-100 {
    margin-bottom: 100px;
  }

  .mg-sm-l-100 {
    margin-left: 100px;
  }

  .mg-sm-t-100-f {
    margin-top: 100px !important;
  }

  .mg-sm-r-100-f {
    margin-right: 100px !important;
  }

  .mg-sm-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-sm-l-100-f {
    margin-left: 100px !important;
  }

  .mg-sm-t-auto {
    margin-top: auto;
  }

  .mg-sm-r-auto {
    margin-right: auto;
  }

  .mg-sm-b-auto {
    margin-bottom: auto;
  }

  .mg-sm-l-auto {
    margin-left: auto;
  }

  .mg-sm-auto {
    margin: auto;
  }

  .mg-sm-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .mg-md-0 {
    margin: 0px;
  }

  .mg-md-0-f {
    margin: 0px !important;
  }

  .mg-md-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-md-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-md-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-md-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-md-t-0 {
    margin-top: 0px;
  }

  .mg-md-r-0 {
    margin-right: 0px;
  }

  .mg-md-b-0 {
    margin-bottom: 0px;
  }

  .mg-md-l-0 {
    margin-left: 0px;
  }

  .mg-md-t-0-f {
    margin-top: 0px !important;
  }

  .mg-md-r-0-f {
    margin-right: 0px !important;
  }

  .mg-md-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-md-l-0-f {
    margin-left: 0px !important;
  }

  .mg-md-1 {
    margin: 1px;
  }

  .mg-md-1-f {
    margin: 1px !important;
  }

  .mg-md-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-md-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-md-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-md-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-md-t-1 {
    margin-top: 1px;
  }

  .mg-md-r-1 {
    margin-right: 1px;
  }

  .mg-md-b-1 {
    margin-bottom: 1px;
  }

  .mg-md-l-1 {
    margin-left: 1px;
  }

  .mg-md-t-1-f {
    margin-top: 1px !important;
  }

  .mg-md-r-1-f {
    margin-right: 1px !important;
  }

  .mg-md-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-md-l-1-f {
    margin-left: 1px !important;
  }

  .mg-md-2 {
    margin: 2px;
  }

  .mg-md-2-f {
    margin: 2px !important;
  }

  .mg-md-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-md-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-md-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-md-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-md-t-2 {
    margin-top: 2px;
  }

  .mg-md-r-2 {
    margin-right: 2px;
  }

  .mg-md-b-2 {
    margin-bottom: 2px;
  }

  .mg-md-l-2 {
    margin-left: 2px;
  }

  .mg-md-t-2-f {
    margin-top: 2px !important;
  }

  .mg-md-r-2-f {
    margin-right: 2px !important;
  }

  .mg-md-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-md-l-2-f {
    margin-left: 2px !important;
  }

  .mg-md-3 {
    margin: 3px;
  }

  .mg-md-3-f {
    margin: 3px !important;
  }

  .mg-md-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-md-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-md-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-md-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-md-t-3 {
    margin-top: 3px;
  }

  .mg-md-r-3 {
    margin-right: 3px;
  }

  .mg-md-b-3 {
    margin-bottom: 3px;
  }

  .mg-md-l-3 {
    margin-left: 3px;
  }

  .mg-md-t-3-f {
    margin-top: 3px !important;
  }

  .mg-md-r-3-f {
    margin-right: 3px !important;
  }

  .mg-md-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-md-l-3-f {
    margin-left: 3px !important;
  }

  .mg-md-4 {
    margin: 4px;
  }

  .mg-md-4-f {
    margin: 4px !important;
  }

  .mg-md-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-md-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-md-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-md-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-md-t-4 {
    margin-top: 4px;
  }

  .mg-md-r-4 {
    margin-right: 4px;
  }

  .mg-md-b-4 {
    margin-bottom: 4px;
  }

  .mg-md-l-4 {
    margin-left: 4px;
  }

  .mg-md-t-4-f {
    margin-top: 4px !important;
  }

  .mg-md-r-4-f {
    margin-right: 4px !important;
  }

  .mg-md-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-md-l-4-f {
    margin-left: 4px !important;
  }

  .mg-md-5 {
    margin: 5px;
  }

  .mg-md-5-f {
    margin: 5px !important;
  }

  .mg-md-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-md-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-md-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-md-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-md-t-5 {
    margin-top: 5px;
  }

  .mg-md-r-5 {
    margin-right: 5px;
  }

  .mg-md-b-5 {
    margin-bottom: 5px;
  }

  .mg-md-l-5 {
    margin-left: 5px;
  }

  .mg-md-t-5-f {
    margin-top: 5px !important;
  }

  .mg-md-r-5-f {
    margin-right: 5px !important;
  }

  .mg-md-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-md-l-5-f {
    margin-left: 5px !important;
  }

  .mg-md-6 {
    margin: 6px;
  }

  .mg-md-6-f {
    margin: 6px !important;
  }

  .mg-md-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-md-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-md-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-md-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-md-t-6 {
    margin-top: 6px;
  }

  .mg-md-r-6 {
    margin-right: 6px;
  }

  .mg-md-b-6 {
    margin-bottom: 6px;
  }

  .mg-md-l-6 {
    margin-left: 6px;
  }

  .mg-md-t-6-f {
    margin-top: 6px !important;
  }

  .mg-md-r-6-f {
    margin-right: 6px !important;
  }

  .mg-md-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-md-l-6-f {
    margin-left: 6px !important;
  }

  .mg-md-7 {
    margin: 7px;
  }

  .mg-md-7-f {
    margin: 7px !important;
  }

  .mg-md-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-md-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-md-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-md-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-md-t-7 {
    margin-top: 7px;
  }

  .mg-md-r-7 {
    margin-right: 7px;
  }

  .mg-md-b-7 {
    margin-bottom: 7px;
  }

  .mg-md-l-7 {
    margin-left: 7px;
  }

  .mg-md-t-7-f {
    margin-top: 7px !important;
  }

  .mg-md-r-7-f {
    margin-right: 7px !important;
  }

  .mg-md-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-md-l-7-f {
    margin-left: 7px !important;
  }

  .mg-md-8 {
    margin: 8px;
  }

  .mg-md-8-f {
    margin: 8px !important;
  }

  .mg-md-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-md-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-md-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-md-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-md-t-8 {
    margin-top: 8px;
  }

  .mg-md-r-8 {
    margin-right: 8px;
  }

  .mg-md-b-8 {
    margin-bottom: 8px;
  }

  .mg-md-l-8 {
    margin-left: 8px;
  }

  .mg-md-t-8-f {
    margin-top: 8px !important;
  }

  .mg-md-r-8-f {
    margin-right: 8px !important;
  }

  .mg-md-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-md-l-8-f {
    margin-left: 8px !important;
  }

  .mg-md-9 {
    margin: 9px;
  }

  .mg-md-9-f {
    margin: 9px !important;
  }

  .mg-md-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-md-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-md-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-md-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-md-t-9 {
    margin-top: 9px;
  }

  .mg-md-r-9 {
    margin-right: 9px;
  }

  .mg-md-b-9 {
    margin-bottom: 9px;
  }

  .mg-md-l-9 {
    margin-left: 9px;
  }

  .mg-md-t-9-f {
    margin-top: 9px !important;
  }

  .mg-md-r-9-f {
    margin-right: 9px !important;
  }

  .mg-md-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-md-l-9-f {
    margin-left: 9px !important;
  }

  .mg-md-10 {
    margin: 10px;
  }

  .mg-md-10-f {
    margin: 10px !important;
  }

  .mg-md-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-md-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-md-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-md-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-md-t-10 {
    margin-top: 10px;
  }

  .mg-md-r-10 {
    margin-right: 10px;
  }

  .mg-md-b-10 {
    margin-bottom: 10px;
  }

  .mg-md-l-10 {
    margin-left: 10px;
  }

  .mg-md-t-10-f {
    margin-top: 10px !important;
  }

  .mg-md-r-10-f {
    margin-right: 10px !important;
  }

  .mg-md-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-md-l-10-f {
    margin-left: 10px !important;
  }

  .mg-md-15 {
    margin: 15px;
  }

  .mg-md-15-f {
    margin: 15px !important;
  }

  .mg-md-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-md-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-md-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-md-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-md-t-15 {
    margin-top: 15px;
  }

  .mg-md-r-15 {
    margin-right: 15px;
  }

  .mg-md-b-15 {
    margin-bottom: 15px;
  }

  .mg-md-l-15 {
    margin-left: 15px;
  }

  .mg-md-t-15-f {
    margin-top: 15px !important;
  }

  .mg-md-r-15-f {
    margin-right: 15px !important;
  }

  .mg-md-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-md-l-15-f {
    margin-left: 15px !important;
  }

  .mg-md-20 {
    margin: 20px;
  }

  .mg-md-20-f {
    margin: 20px !important;
  }

  .mg-md-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-md-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-md-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-md-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-md-t-20 {
    margin-top: 20px;
  }

  .mg-md-r-20 {
    margin-right: 20px;
  }

  .mg-md-b-20 {
    margin-bottom: 20px;
  }

  .mg-md-l-20 {
    margin-left: 20px;
  }

  .mg-md-t-20-f {
    margin-top: 20px !important;
  }

  .mg-md-r-20-f {
    margin-right: 20px !important;
  }

  .mg-md-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-md-l-20-f {
    margin-left: 20px !important;
  }

  .mg-md-25 {
    margin: 25px;
  }

  .mg-md-25-f {
    margin: 25px !important;
  }

  .mg-md-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-md-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-md-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-md-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-md-t-25 {
    margin-top: 25px;
  }

  .mg-md-r-25 {
    margin-right: 25px;
  }

  .mg-md-b-25 {
    margin-bottom: 25px;
  }

  .mg-md-l-25 {
    margin-left: 25px;
  }

  .mg-md-t-25-f {
    margin-top: 25px !important;
  }

  .mg-md-r-25-f {
    margin-right: 25px !important;
  }

  .mg-md-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-md-l-25-f {
    margin-left: 25px !important;
  }

  .mg-md-30 {
    margin: 30px;
  }

  .mg-md-30-f {
    margin: 30px !important;
  }

  .mg-md-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-md-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-md-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-md-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-md-t-30 {
    margin-top: 30px;
  }

  .mg-md-r-30 {
    margin-right: 30px;
  }

  .mg-md-b-30 {
    margin-bottom: 30px;
  }

  .mg-md-l-30 {
    margin-left: 30px;
  }

  .mg-md-t-30-f {
    margin-top: 30px !important;
  }

  .mg-md-r-30-f {
    margin-right: 30px !important;
  }

  .mg-md-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-md-l-30-f {
    margin-left: 30px !important;
  }

  .mg-md-35 {
    margin: 35px;
  }

  .mg-md-35-f {
    margin: 35px !important;
  }

  .mg-md-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-md-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-md-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-md-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-md-t-35 {
    margin-top: 35px;
  }

  .mg-md-r-35 {
    margin-right: 35px;
  }

  .mg-md-b-35 {
    margin-bottom: 35px;
  }

  .mg-md-l-35 {
    margin-left: 35px;
  }

  .mg-md-t-35-f {
    margin-top: 35px !important;
  }

  .mg-md-r-35-f {
    margin-right: 35px !important;
  }

  .mg-md-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-md-l-35-f {
    margin-left: 35px !important;
  }

  .mg-md-40 {
    margin: 40px;
  }

  .mg-md-40-f {
    margin: 40px !important;
  }

  .mg-md-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-md-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-md-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-md-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-md-t-40 {
    margin-top: 40px;
  }

  .mg-md-r-40 {
    margin-right: 40px;
  }

  .mg-md-b-40 {
    margin-bottom: 40px;
  }

  .mg-md-l-40 {
    margin-left: 40px;
  }

  .mg-md-t-40-f {
    margin-top: 40px !important;
  }

  .mg-md-r-40-f {
    margin-right: 40px !important;
  }

  .mg-md-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-md-l-40-f {
    margin-left: 40px !important;
  }

  .mg-md-45 {
    margin: 45px;
  }

  .mg-md-45-f {
    margin: 45px !important;
  }

  .mg-md-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-md-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-md-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-md-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-md-t-45 {
    margin-top: 45px;
  }

  .mg-md-r-45 {
    margin-right: 45px;
  }

  .mg-md-b-45 {
    margin-bottom: 45px;
  }

  .mg-md-l-45 {
    margin-left: 45px;
  }

  .mg-md-t-45-f {
    margin-top: 45px !important;
  }

  .mg-md-r-45-f {
    margin-right: 45px !important;
  }

  .mg-md-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-md-l-45-f {
    margin-left: 45px !important;
  }

  .mg-md-50 {
    margin: 50px;
  }

  .mg-md-50-f {
    margin: 50px !important;
  }

  .mg-md-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-md-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-md-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-md-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-md-t-50 {
    margin-top: 50px;
  }

  .mg-md-r-50 {
    margin-right: 50px;
  }

  .mg-md-b-50 {
    margin-bottom: 50px;
  }

  .mg-md-l-50 {
    margin-left: 50px;
  }

  .mg-md-t-50-f {
    margin-top: 50px !important;
  }

  .mg-md-r-50-f {
    margin-right: 50px !important;
  }

  .mg-md-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-md-l-50-f {
    margin-left: 50px !important;
  }

  .mg-md-55 {
    margin: 55px;
  }

  .mg-md-55-f {
    margin: 55px !important;
  }

  .mg-md-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-md-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-md-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-md-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-md-t-55 {
    margin-top: 55px;
  }

  .mg-md-r-55 {
    margin-right: 55px;
  }

  .mg-md-b-55 {
    margin-bottom: 55px;
  }

  .mg-md-l-55 {
    margin-left: 55px;
  }

  .mg-md-t-55-f {
    margin-top: 55px !important;
  }

  .mg-md-r-55-f {
    margin-right: 55px !important;
  }

  .mg-md-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-md-l-55-f {
    margin-left: 55px !important;
  }

  .mg-md-60 {
    margin: 60px;
  }

  .mg-md-60-f {
    margin: 60px !important;
  }

  .mg-md-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-md-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-md-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-md-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-md-t-60 {
    margin-top: 60px;
  }

  .mg-md-r-60 {
    margin-right: 60px;
  }

  .mg-md-b-60 {
    margin-bottom: 60px;
  }

  .mg-md-l-60 {
    margin-left: 60px;
  }

  .mg-md-t-60-f {
    margin-top: 60px !important;
  }

  .mg-md-r-60-f {
    margin-right: 60px !important;
  }

  .mg-md-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-md-l-60-f {
    margin-left: 60px !important;
  }

  .mg-md-65 {
    margin: 65px;
  }

  .mg-md-65-f {
    margin: 65px !important;
  }

  .mg-md-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-md-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-md-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-md-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-md-t-65 {
    margin-top: 65px;
  }

  .mg-md-r-65 {
    margin-right: 65px;
  }

  .mg-md-b-65 {
    margin-bottom: 65px;
  }

  .mg-md-l-65 {
    margin-left: 65px;
  }

  .mg-md-t-65-f {
    margin-top: 65px !important;
  }

  .mg-md-r-65-f {
    margin-right: 65px !important;
  }

  .mg-md-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-md-l-65-f {
    margin-left: 65px !important;
  }

  .mg-md-70 {
    margin: 70px;
  }

  .mg-md-70-f {
    margin: 70px !important;
  }

  .mg-md-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-md-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-md-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-md-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-md-t-70 {
    margin-top: 70px;
  }

  .mg-md-r-70 {
    margin-right: 70px;
  }

  .mg-md-b-70 {
    margin-bottom: 70px;
  }

  .mg-md-l-70 {
    margin-left: 70px;
  }

  .mg-md-t-70-f {
    margin-top: 70px !important;
  }

  .mg-md-r-70-f {
    margin-right: 70px !important;
  }

  .mg-md-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-md-l-70-f {
    margin-left: 70px !important;
  }

  .mg-md-75 {
    margin: 75px;
  }

  .mg-md-75-f {
    margin: 75px !important;
  }

  .mg-md-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-md-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-md-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-md-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-md-t-75 {
    margin-top: 75px;
  }

  .mg-md-r-75 {
    margin-right: 75px;
  }

  .mg-md-b-75 {
    margin-bottom: 75px;
  }

  .mg-md-l-75 {
    margin-left: 75px;
  }

  .mg-md-t-75-f {
    margin-top: 75px !important;
  }

  .mg-md-r-75-f {
    margin-right: 75px !important;
  }

  .mg-md-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-md-l-75-f {
    margin-left: 75px !important;
  }

  .mg-md-80 {
    margin: 80px;
  }

  .mg-md-80-f {
    margin: 80px !important;
  }

  .mg-md-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-md-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-md-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-md-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-md-t-80 {
    margin-top: 80px;
  }

  .mg-md-r-80 {
    margin-right: 80px;
  }

  .mg-md-b-80 {
    margin-bottom: 80px;
  }

  .mg-md-l-80 {
    margin-left: 80px;
  }

  .mg-md-t-80-f {
    margin-top: 80px !important;
  }

  .mg-md-r-80-f {
    margin-right: 80px !important;
  }

  .mg-md-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-md-l-80-f {
    margin-left: 80px !important;
  }

  .mg-md-85 {
    margin: 85px;
  }

  .mg-md-85-f {
    margin: 85px !important;
  }

  .mg-md-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-md-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-md-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-md-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-md-t-85 {
    margin-top: 85px;
  }

  .mg-md-r-85 {
    margin-right: 85px;
  }

  .mg-md-b-85 {
    margin-bottom: 85px;
  }

  .mg-md-l-85 {
    margin-left: 85px;
  }

  .mg-md-t-85-f {
    margin-top: 85px !important;
  }

  .mg-md-r-85-f {
    margin-right: 85px !important;
  }

  .mg-md-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-md-l-85-f {
    margin-left: 85px !important;
  }

  .mg-md-90 {
    margin: 90px;
  }

  .mg-md-90-f {
    margin: 90px !important;
  }

  .mg-md-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-md-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-md-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-md-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-md-t-90 {
    margin-top: 90px;
  }

  .mg-md-r-90 {
    margin-right: 90px;
  }

  .mg-md-b-90 {
    margin-bottom: 90px;
  }

  .mg-md-l-90 {
    margin-left: 90px;
  }

  .mg-md-t-90-f {
    margin-top: 90px !important;
  }

  .mg-md-r-90-f {
    margin-right: 90px !important;
  }

  .mg-md-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-md-l-90-f {
    margin-left: 90px !important;
  }

  .mg-md-95 {
    margin: 95px;
  }

  .mg-md-95-f {
    margin: 95px !important;
  }

  .mg-md-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-md-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-md-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-md-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-md-t-95 {
    margin-top: 95px;
  }

  .mg-md-r-95 {
    margin-right: 95px;
  }

  .mg-md-b-95 {
    margin-bottom: 95px;
  }

  .mg-md-l-95 {
    margin-left: 95px;
  }

  .mg-md-t-95-f {
    margin-top: 95px !important;
  }

  .mg-md-r-95-f {
    margin-right: 95px !important;
  }

  .mg-md-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-md-l-95-f {
    margin-left: 95px !important;
  }

  .mg-md-100 {
    margin: 100px;
  }

  .mg-md-100-f {
    margin: 100px !important;
  }

  .mg-md-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-md-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-md-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-md-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-md-t-100 {
    margin-top: 100px;
  }

  .mg-md-r-100 {
    margin-right: 100px;
  }

  .mg-md-b-100 {
    margin-bottom: 100px;
  }

  .mg-md-l-100 {
    margin-left: 100px;
  }

  .mg-md-t-100-f {
    margin-top: 100px !important;
  }

  .mg-md-r-100-f {
    margin-right: 100px !important;
  }

  .mg-md-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-md-l-100-f {
    margin-left: 100px !important;
  }

  .mg-md-t-auto {
    margin-top: auto;
  }

  .mg-md-r-auto {
    margin-right: auto;
  }

  .mg-md-b-auto {
    margin-bottom: auto;
  }

  .mg-md-l-auto {
    margin-left: auto;
  }

  .mg-md-auto {
    margin: auto;
  }

  .mg-md-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .mg-lg-0 {
    margin: 0px;
  }

  .mg-lg-0-f {
    margin: 0px !important;
  }

  .mg-lg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-lg-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-lg-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-lg-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-lg-t-0 {
    margin-top: 0px;
  }

  .mg-lg-r-0 {
    margin-right: 0px;
  }

  .mg-lg-b-0 {
    margin-bottom: 0px;
  }

  .mg-lg-l-0 {
    margin-left: 0px;
  }

  .mg-lg-t-0-f {
    margin-top: 0px !important;
  }

  .mg-lg-r-0-f {
    margin-right: 0px !important;
  }

  .mg-lg-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-lg-l-0-f {
    margin-left: 0px !important;
  }

  .mg-lg-1 {
    margin: 1px;
  }

  .mg-lg-1-f {
    margin: 1px !important;
  }

  .mg-lg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-lg-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-lg-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-lg-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-lg-t-1 {
    margin-top: 1px;
  }

  .mg-lg-r-1 {
    margin-right: 1px;
  }

  .mg-lg-b-1 {
    margin-bottom: 1px;
  }

  .mg-lg-l-1 {
    margin-left: 1px;
  }

  .mg-lg-t-1-f {
    margin-top: 1px !important;
  }

  .mg-lg-r-1-f {
    margin-right: 1px !important;
  }

  .mg-lg-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-lg-l-1-f {
    margin-left: 1px !important;
  }

  .mg-lg-2 {
    margin: 2px;
  }

  .mg-lg-2-f {
    margin: 2px !important;
  }

  .mg-lg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-lg-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-lg-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-lg-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-lg-t-2 {
    margin-top: 2px;
  }

  .mg-lg-r-2 {
    margin-right: 2px;
  }

  .mg-lg-b-2 {
    margin-bottom: 2px;
  }

  .mg-lg-l-2 {
    margin-left: 2px;
  }

  .mg-lg-t-2-f {
    margin-top: 2px !important;
  }

  .mg-lg-r-2-f {
    margin-right: 2px !important;
  }

  .mg-lg-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-lg-l-2-f {
    margin-left: 2px !important;
  }

  .mg-lg-3 {
    margin: 3px;
  }

  .mg-lg-3-f {
    margin: 3px !important;
  }

  .mg-lg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-lg-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-lg-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-lg-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-lg-t-3 {
    margin-top: 3px;
  }

  .mg-lg-r-3 {
    margin-right: 3px;
  }

  .mg-lg-b-3 {
    margin-bottom: 3px;
  }

  .mg-lg-l-3 {
    margin-left: 3px;
  }

  .mg-lg-t-3-f {
    margin-top: 3px !important;
  }

  .mg-lg-r-3-f {
    margin-right: 3px !important;
  }

  .mg-lg-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-lg-l-3-f {
    margin-left: 3px !important;
  }

  .mg-lg-4 {
    margin: 4px;
  }

  .mg-lg-4-f {
    margin: 4px !important;
  }

  .mg-lg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-lg-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-lg-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-lg-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-lg-t-4 {
    margin-top: 4px;
  }

  .mg-lg-r-4 {
    margin-right: 4px;
  }

  .mg-lg-b-4 {
    margin-bottom: 4px;
  }

  .mg-lg-l-4 {
    margin-left: 4px;
  }

  .mg-lg-t-4-f {
    margin-top: 4px !important;
  }

  .mg-lg-r-4-f {
    margin-right: 4px !important;
  }

  .mg-lg-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-lg-l-4-f {
    margin-left: 4px !important;
  }

  .mg-lg-5 {
    margin: 5px;
  }

  .mg-lg-5-f {
    margin: 5px !important;
  }

  .mg-lg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-lg-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-lg-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-lg-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-lg-t-5 {
    margin-top: 5px;
  }

  .mg-lg-r-5 {
    margin-right: 5px;
  }

  .mg-lg-b-5 {
    margin-bottom: 5px;
  }

  .mg-lg-l-5 {
    margin-left: 5px;
  }

  .mg-lg-t-5-f {
    margin-top: 5px !important;
  }

  .mg-lg-r-5-f {
    margin-right: 5px !important;
  }

  .mg-lg-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-lg-l-5-f {
    margin-left: 5px !important;
  }

  .mg-lg-6 {
    margin: 6px;
  }

  .mg-lg-6-f {
    margin: 6px !important;
  }

  .mg-lg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-lg-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-lg-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-lg-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-lg-t-6 {
    margin-top: 6px;
  }

  .mg-lg-r-6 {
    margin-right: 6px;
  }

  .mg-lg-b-6 {
    margin-bottom: 6px;
  }

  .mg-lg-l-6 {
    margin-left: 6px;
  }

  .mg-lg-t-6-f {
    margin-top: 6px !important;
  }

  .mg-lg-r-6-f {
    margin-right: 6px !important;
  }

  .mg-lg-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-lg-l-6-f {
    margin-left: 6px !important;
  }

  .mg-lg-7 {
    margin: 7px;
  }

  .mg-lg-7-f {
    margin: 7px !important;
  }

  .mg-lg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-lg-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-lg-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-lg-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-lg-t-7 {
    margin-top: 7px;
  }

  .mg-lg-r-7 {
    margin-right: 7px;
  }

  .mg-lg-b-7 {
    margin-bottom: 7px;
  }

  .mg-lg-l-7 {
    margin-left: 7px;
  }

  .mg-lg-t-7-f {
    margin-top: 7px !important;
  }

  .mg-lg-r-7-f {
    margin-right: 7px !important;
  }

  .mg-lg-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-lg-l-7-f {
    margin-left: 7px !important;
  }

  .mg-lg-8 {
    margin: 8px;
  }

  .mg-lg-8-f {
    margin: 8px !important;
  }

  .mg-lg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-lg-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-lg-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-lg-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-lg-t-8 {
    margin-top: 8px;
  }

  .mg-lg-r-8 {
    margin-right: 8px;
  }

  .mg-lg-b-8 {
    margin-bottom: 8px;
  }

  .mg-lg-l-8 {
    margin-left: 8px;
  }

  .mg-lg-t-8-f {
    margin-top: 8px !important;
  }

  .mg-lg-r-8-f {
    margin-right: 8px !important;
  }

  .mg-lg-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-lg-l-8-f {
    margin-left: 8px !important;
  }

  .mg-lg-9 {
    margin: 9px;
  }

  .mg-lg-9-f {
    margin: 9px !important;
  }

  .mg-lg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-lg-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-lg-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-lg-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-lg-t-9 {
    margin-top: 9px;
  }

  .mg-lg-r-9 {
    margin-right: 9px;
  }

  .mg-lg-b-9 {
    margin-bottom: 9px;
  }

  .mg-lg-l-9 {
    margin-left: 9px;
  }

  .mg-lg-t-9-f {
    margin-top: 9px !important;
  }

  .mg-lg-r-9-f {
    margin-right: 9px !important;
  }

  .mg-lg-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-lg-l-9-f {
    margin-left: 9px !important;
  }

  .mg-lg-10 {
    margin: 10px;
  }

  .mg-lg-10-f {
    margin: 10px !important;
  }

  .mg-lg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-lg-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-lg-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-lg-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-lg-t-10 {
    margin-top: 10px;
  }

  .mg-lg-r-10 {
    margin-right: 10px;
  }

  .mg-lg-b-10 {
    margin-bottom: 10px;
  }

  .mg-lg-l-10 {
    margin-left: 10px;
  }

  .mg-lg-t-10-f {
    margin-top: 10px !important;
  }

  .mg-lg-r-10-f {
    margin-right: 10px !important;
  }

  .mg-lg-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-lg-l-10-f {
    margin-left: 10px !important;
  }

  .mg-lg-15 {
    margin: 15px;
  }

  .mg-lg-15-f {
    margin: 15px !important;
  }

  .mg-lg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-lg-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-lg-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-lg-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-lg-t-15 {
    margin-top: 15px;
  }

  .mg-lg-r-15 {
    margin-right: 15px;
  }

  .mg-lg-b-15 {
    margin-bottom: 15px;
  }

  .mg-lg-l-15 {
    margin-left: 15px;
  }

  .mg-lg-t-15-f {
    margin-top: 15px !important;
  }

  .mg-lg-r-15-f {
    margin-right: 15px !important;
  }

  .mg-lg-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-lg-l-15-f {
    margin-left: 15px !important;
  }

  .mg-lg-20 {
    margin: 20px;
  }

  .mg-lg-20-f {
    margin: 20px !important;
  }

  .mg-lg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-lg-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-lg-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-lg-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-lg-t-20 {
    margin-top: 20px;
  }

  .mg-lg-r-20 {
    margin-right: 20px;
  }

  .mg-lg-b-20 {
    margin-bottom: 20px;
  }

  .mg-lg-l-20 {
    margin-left: 20px;
  }

  .mg-lg-t-20-f {
    margin-top: 20px !important;
  }

  .mg-lg-r-20-f {
    margin-right: 20px !important;
  }

  .mg-lg-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-lg-l-20-f {
    margin-left: 20px !important;
  }

  .mg-lg-25 {
    margin: 25px;
  }

  .mg-lg-25-f {
    margin: 25px !important;
  }

  .mg-lg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-lg-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-lg-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-lg-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-lg-t-25 {
    margin-top: 25px;
  }

  .mg-lg-r-25 {
    margin-right: 25px;
  }

  .mg-lg-b-25 {
    margin-bottom: 25px;
  }

  .mg-lg-l-25 {
    margin-left: 25px;
  }

  .mg-lg-t-25-f {
    margin-top: 25px !important;
  }

  .mg-lg-r-25-f {
    margin-right: 25px !important;
  }

  .mg-lg-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-lg-l-25-f {
    margin-left: 25px !important;
  }

  .mg-lg-30 {
    margin: 30px;
  }

  .mg-lg-30-f {
    margin: 30px !important;
  }

  .mg-lg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-lg-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-lg-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-lg-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-lg-t-30 {
    margin-top: 30px;
  }

  .mg-lg-r-30 {
    margin-right: 30px;
  }

  .mg-lg-b-30 {
    margin-bottom: 30px;
  }

  .mg-lg-l-30 {
    margin-left: 30px;
  }

  .mg-lg-t-30-f {
    margin-top: 30px !important;
  }

  .mg-lg-r-30-f {
    margin-right: 30px !important;
  }

  .mg-lg-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-lg-l-30-f {
    margin-left: 30px !important;
  }

  .mg-lg-35 {
    margin: 35px;
  }

  .mg-lg-35-f {
    margin: 35px !important;
  }

  .mg-lg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-lg-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-lg-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-lg-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-lg-t-35 {
    margin-top: 35px;
  }

  .mg-lg-r-35 {
    margin-right: 35px;
  }

  .mg-lg-b-35 {
    margin-bottom: 35px;
  }

  .mg-lg-l-35 {
    margin-left: 35px;
  }

  .mg-lg-t-35-f {
    margin-top: 35px !important;
  }

  .mg-lg-r-35-f {
    margin-right: 35px !important;
  }

  .mg-lg-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-lg-l-35-f {
    margin-left: 35px !important;
  }

  .mg-lg-40 {
    margin: 40px;
  }

  .mg-lg-40-f {
    margin: 40px !important;
  }

  .mg-lg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-lg-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-lg-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-lg-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-lg-t-40 {
    margin-top: 40px;
  }

  .mg-lg-r-40 {
    margin-right: 40px;
  }

  .mg-lg-b-40 {
    margin-bottom: 40px;
  }

  .mg-lg-l-40 {
    margin-left: 40px;
  }

  .mg-lg-t-40-f {
    margin-top: 40px !important;
  }

  .mg-lg-r-40-f {
    margin-right: 40px !important;
  }

  .mg-lg-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-lg-l-40-f {
    margin-left: 40px !important;
  }

  .mg-lg-45 {
    margin: 45px;
  }

  .mg-lg-45-f {
    margin: 45px !important;
  }

  .mg-lg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-lg-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-lg-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-lg-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-lg-t-45 {
    margin-top: 45px;
  }

  .mg-lg-r-45 {
    margin-right: 45px;
  }

  .mg-lg-b-45 {
    margin-bottom: 45px;
  }

  .mg-lg-l-45 {
    margin-left: 45px;
  }

  .mg-lg-t-45-f {
    margin-top: 45px !important;
  }

  .mg-lg-r-45-f {
    margin-right: 45px !important;
  }

  .mg-lg-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-lg-l-45-f {
    margin-left: 45px !important;
  }

  .mg-lg-50 {
    margin: 50px;
  }

  .mg-lg-50-f {
    margin: 50px !important;
  }

  .mg-lg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-lg-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-lg-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-lg-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-lg-t-50 {
    margin-top: 50px;
  }

  .mg-lg-r-50 {
    margin-right: 50px;
  }

  .mg-lg-b-50 {
    margin-bottom: 50px;
  }

  .mg-lg-l-50 {
    margin-left: 50px;
  }

  .mg-lg-t-50-f {
    margin-top: 50px !important;
  }

  .mg-lg-r-50-f {
    margin-right: 50px !important;
  }

  .mg-lg-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-lg-l-50-f {
    margin-left: 50px !important;
  }

  .mg-lg-55 {
    margin: 55px;
  }

  .mg-lg-55-f {
    margin: 55px !important;
  }

  .mg-lg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-lg-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-lg-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-lg-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-lg-t-55 {
    margin-top: 55px;
  }

  .mg-lg-r-55 {
    margin-right: 55px;
  }

  .mg-lg-b-55 {
    margin-bottom: 55px;
  }

  .mg-lg-l-55 {
    margin-left: 55px;
  }

  .mg-lg-t-55-f {
    margin-top: 55px !important;
  }

  .mg-lg-r-55-f {
    margin-right: 55px !important;
  }

  .mg-lg-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-lg-l-55-f {
    margin-left: 55px !important;
  }

  .mg-lg-60 {
    margin: 60px;
  }

  .mg-lg-60-f {
    margin: 60px !important;
  }

  .mg-lg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-lg-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-lg-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-lg-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-lg-t-60 {
    margin-top: 60px;
  }

  .mg-lg-r-60 {
    margin-right: 60px;
  }

  .mg-lg-b-60 {
    margin-bottom: 60px;
  }

  .mg-lg-l-60 {
    margin-left: 60px;
  }

  .mg-lg-t-60-f {
    margin-top: 60px !important;
  }

  .mg-lg-r-60-f {
    margin-right: 60px !important;
  }

  .mg-lg-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-lg-l-60-f {
    margin-left: 60px !important;
  }

  .mg-lg-65 {
    margin: 65px;
  }

  .mg-lg-65-f {
    margin: 65px !important;
  }

  .mg-lg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-lg-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-lg-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-lg-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-lg-t-65 {
    margin-top: 65px;
  }

  .mg-lg-r-65 {
    margin-right: 65px;
  }

  .mg-lg-b-65 {
    margin-bottom: 65px;
  }

  .mg-lg-l-65 {
    margin-left: 65px;
  }

  .mg-lg-t-65-f {
    margin-top: 65px !important;
  }

  .mg-lg-r-65-f {
    margin-right: 65px !important;
  }

  .mg-lg-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-lg-l-65-f {
    margin-left: 65px !important;
  }

  .mg-lg-70 {
    margin: 70px;
  }

  .mg-lg-70-f {
    margin: 70px !important;
  }

  .mg-lg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-lg-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-lg-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-lg-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-lg-t-70 {
    margin-top: 70px;
  }

  .mg-lg-r-70 {
    margin-right: 70px;
  }

  .mg-lg-b-70 {
    margin-bottom: 70px;
  }

  .mg-lg-l-70 {
    margin-left: 70px;
  }

  .mg-lg-t-70-f {
    margin-top: 70px !important;
  }

  .mg-lg-r-70-f {
    margin-right: 70px !important;
  }

  .mg-lg-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-lg-l-70-f {
    margin-left: 70px !important;
  }

  .mg-lg-75 {
    margin: 75px;
  }

  .mg-lg-75-f {
    margin: 75px !important;
  }

  .mg-lg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-lg-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-lg-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-lg-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-lg-t-75 {
    margin-top: 75px;
  }

  .mg-lg-r-75 {
    margin-right: 75px;
  }

  .mg-lg-b-75 {
    margin-bottom: 75px;
  }

  .mg-lg-l-75 {
    margin-left: 75px;
  }

  .mg-lg-t-75-f {
    margin-top: 75px !important;
  }

  .mg-lg-r-75-f {
    margin-right: 75px !important;
  }

  .mg-lg-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-lg-l-75-f {
    margin-left: 75px !important;
  }

  .mg-lg-80 {
    margin: 80px;
  }

  .mg-lg-80-f {
    margin: 80px !important;
  }

  .mg-lg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-lg-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-lg-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-lg-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-lg-t-80 {
    margin-top: 80px;
  }

  .mg-lg-r-80 {
    margin-right: 80px;
  }

  .mg-lg-b-80 {
    margin-bottom: 80px;
  }

  .mg-lg-l-80 {
    margin-left: 80px;
  }

  .mg-lg-t-80-f {
    margin-top: 80px !important;
  }

  .mg-lg-r-80-f {
    margin-right: 80px !important;
  }

  .mg-lg-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-lg-l-80-f {
    margin-left: 80px !important;
  }

  .mg-lg-85 {
    margin: 85px;
  }

  .mg-lg-85-f {
    margin: 85px !important;
  }

  .mg-lg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-lg-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-lg-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-lg-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-lg-t-85 {
    margin-top: 85px;
  }

  .mg-lg-r-85 {
    margin-right: 85px;
  }

  .mg-lg-b-85 {
    margin-bottom: 85px;
  }

  .mg-lg-l-85 {
    margin-left: 85px;
  }

  .mg-lg-t-85-f {
    margin-top: 85px !important;
  }

  .mg-lg-r-85-f {
    margin-right: 85px !important;
  }

  .mg-lg-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-lg-l-85-f {
    margin-left: 85px !important;
  }

  .mg-lg-90 {
    margin: 90px;
  }

  .mg-lg-90-f {
    margin: 90px !important;
  }

  .mg-lg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-lg-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-lg-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-lg-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-lg-t-90 {
    margin-top: 90px;
  }

  .mg-lg-r-90 {
    margin-right: 90px;
  }

  .mg-lg-b-90 {
    margin-bottom: 90px;
  }

  .mg-lg-l-90 {
    margin-left: 90px;
  }

  .mg-lg-t-90-f {
    margin-top: 90px !important;
  }

  .mg-lg-r-90-f {
    margin-right: 90px !important;
  }

  .mg-lg-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-lg-l-90-f {
    margin-left: 90px !important;
  }

  .mg-lg-95 {
    margin: 95px;
  }

  .mg-lg-95-f {
    margin: 95px !important;
  }

  .mg-lg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-lg-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-lg-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-lg-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-lg-t-95 {
    margin-top: 95px;
  }

  .mg-lg-r-95 {
    margin-right: 95px;
  }

  .mg-lg-b-95 {
    margin-bottom: 95px;
  }

  .mg-lg-l-95 {
    margin-left: 95px;
  }

  .mg-lg-t-95-f {
    margin-top: 95px !important;
  }

  .mg-lg-r-95-f {
    margin-right: 95px !important;
  }

  .mg-lg-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-lg-l-95-f {
    margin-left: 95px !important;
  }

  .mg-lg-100 {
    margin: 100px;
  }

  .mg-lg-100-f {
    margin: 100px !important;
  }

  .mg-lg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-lg-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-lg-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-lg-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-lg-t-100 {
    margin-top: 100px;
  }

  .mg-lg-r-100 {
    margin-right: 100px;
  }

  .mg-lg-b-100 {
    margin-bottom: 100px;
  }

  .mg-lg-l-100 {
    margin-left: 100px;
  }

  .mg-lg-t-100-f {
    margin-top: 100px !important;
  }

  .mg-lg-r-100-f {
    margin-right: 100px !important;
  }

  .mg-lg-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-lg-l-100-f {
    margin-left: 100px !important;
  }

  .mg-lg-t-auto {
    margin-top: auto;
  }

  .mg-lg-r-auto {
    margin-right: auto;
  }

  .mg-lg-b-auto {
    margin-bottom: auto;
  }

  .mg-lg-l-auto {
    margin-left: auto;
  }

  .mg-lg-auto {
    margin: auto;
  }

  .mg-lg-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .mg-xl-0 {
    margin: 0px;
  }

  .mg-xl-0-f {
    margin: 0px !important;
  }

  .mg-xl-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-xl-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-xl-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-xl-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-xl-t-0 {
    margin-top: 0px;
  }

  .mg-xl-r-0 {
    margin-right: 0px;
  }

  .mg-xl-b-0 {
    margin-bottom: 0px;
  }

  .mg-xl-l-0 {
    margin-left: 0px;
  }

  .mg-xl-t-0-f {
    margin-top: 0px !important;
  }

  .mg-xl-r-0-f {
    margin-right: 0px !important;
  }

  .mg-xl-b-0-f {
    margin-bottom: 0px !important;
  }

  .mg-xl-l-0-f {
    margin-left: 0px !important;
  }

  .mg-xl-1 {
    margin: 1px;
  }

  .mg-xl-1-f {
    margin: 1px !important;
  }

  .mg-xl-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-xl-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-xl-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-xl-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-xl-t-1 {
    margin-top: 1px;
  }

  .mg-xl-r-1 {
    margin-right: 1px;
  }

  .mg-xl-b-1 {
    margin-bottom: 1px;
  }

  .mg-xl-l-1 {
    margin-left: 1px;
  }

  .mg-xl-t-1-f {
    margin-top: 1px !important;
  }

  .mg-xl-r-1-f {
    margin-right: 1px !important;
  }

  .mg-xl-b-1-f {
    margin-bottom: 1px !important;
  }

  .mg-xl-l-1-f {
    margin-left: 1px !important;
  }

  .mg-xl-2 {
    margin: 2px;
  }

  .mg-xl-2-f {
    margin: 2px !important;
  }

  .mg-xl-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-xl-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-xl-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-xl-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-xl-t-2 {
    margin-top: 2px;
  }

  .mg-xl-r-2 {
    margin-right: 2px;
  }

  .mg-xl-b-2 {
    margin-bottom: 2px;
  }

  .mg-xl-l-2 {
    margin-left: 2px;
  }

  .mg-xl-t-2-f {
    margin-top: 2px !important;
  }

  .mg-xl-r-2-f {
    margin-right: 2px !important;
  }

  .mg-xl-b-2-f {
    margin-bottom: 2px !important;
  }

  .mg-xl-l-2-f {
    margin-left: 2px !important;
  }

  .mg-xl-3 {
    margin: 3px;
  }

  .mg-xl-3-f {
    margin: 3px !important;
  }

  .mg-xl-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-xl-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-xl-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-xl-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-xl-t-3 {
    margin-top: 3px;
  }

  .mg-xl-r-3 {
    margin-right: 3px;
  }

  .mg-xl-b-3 {
    margin-bottom: 3px;
  }

  .mg-xl-l-3 {
    margin-left: 3px;
  }

  .mg-xl-t-3-f {
    margin-top: 3px !important;
  }

  .mg-xl-r-3-f {
    margin-right: 3px !important;
  }

  .mg-xl-b-3-f {
    margin-bottom: 3px !important;
  }

  .mg-xl-l-3-f {
    margin-left: 3px !important;
  }

  .mg-xl-4 {
    margin: 4px;
  }

  .mg-xl-4-f {
    margin: 4px !important;
  }

  .mg-xl-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-xl-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-xl-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-xl-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-xl-t-4 {
    margin-top: 4px;
  }

  .mg-xl-r-4 {
    margin-right: 4px;
  }

  .mg-xl-b-4 {
    margin-bottom: 4px;
  }

  .mg-xl-l-4 {
    margin-left: 4px;
  }

  .mg-xl-t-4-f {
    margin-top: 4px !important;
  }

  .mg-xl-r-4-f {
    margin-right: 4px !important;
  }

  .mg-xl-b-4-f {
    margin-bottom: 4px !important;
  }

  .mg-xl-l-4-f {
    margin-left: 4px !important;
  }

  .mg-xl-5 {
    margin: 5px;
  }

  .mg-xl-5-f {
    margin: 5px !important;
  }

  .mg-xl-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-xl-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-xl-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-xl-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-xl-t-5 {
    margin-top: 5px;
  }

  .mg-xl-r-5 {
    margin-right: 5px;
  }

  .mg-xl-b-5 {
    margin-bottom: 5px;
  }

  .mg-xl-l-5 {
    margin-left: 5px;
  }

  .mg-xl-t-5-f {
    margin-top: 5px !important;
  }

  .mg-xl-r-5-f {
    margin-right: 5px !important;
  }

  .mg-xl-b-5-f {
    margin-bottom: 5px !important;
  }

  .mg-xl-l-5-f {
    margin-left: 5px !important;
  }

  .mg-xl-6 {
    margin: 6px;
  }

  .mg-xl-6-f {
    margin: 6px !important;
  }

  .mg-xl-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-xl-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-xl-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-xl-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-xl-t-6 {
    margin-top: 6px;
  }

  .mg-xl-r-6 {
    margin-right: 6px;
  }

  .mg-xl-b-6 {
    margin-bottom: 6px;
  }

  .mg-xl-l-6 {
    margin-left: 6px;
  }

  .mg-xl-t-6-f {
    margin-top: 6px !important;
  }

  .mg-xl-r-6-f {
    margin-right: 6px !important;
  }

  .mg-xl-b-6-f {
    margin-bottom: 6px !important;
  }

  .mg-xl-l-6-f {
    margin-left: 6px !important;
  }

  .mg-xl-7 {
    margin: 7px;
  }

  .mg-xl-7-f {
    margin: 7px !important;
  }

  .mg-xl-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-xl-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-xl-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-xl-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-xl-t-7 {
    margin-top: 7px;
  }

  .mg-xl-r-7 {
    margin-right: 7px;
  }

  .mg-xl-b-7 {
    margin-bottom: 7px;
  }

  .mg-xl-l-7 {
    margin-left: 7px;
  }

  .mg-xl-t-7-f {
    margin-top: 7px !important;
  }

  .mg-xl-r-7-f {
    margin-right: 7px !important;
  }

  .mg-xl-b-7-f {
    margin-bottom: 7px !important;
  }

  .mg-xl-l-7-f {
    margin-left: 7px !important;
  }

  .mg-xl-8 {
    margin: 8px;
  }

  .mg-xl-8-f {
    margin: 8px !important;
  }

  .mg-xl-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-xl-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-xl-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-xl-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-xl-t-8 {
    margin-top: 8px;
  }

  .mg-xl-r-8 {
    margin-right: 8px;
  }

  .mg-xl-b-8 {
    margin-bottom: 8px;
  }

  .mg-xl-l-8 {
    margin-left: 8px;
  }

  .mg-xl-t-8-f {
    margin-top: 8px !important;
  }

  .mg-xl-r-8-f {
    margin-right: 8px !important;
  }

  .mg-xl-b-8-f {
    margin-bottom: 8px !important;
  }

  .mg-xl-l-8-f {
    margin-left: 8px !important;
  }

  .mg-xl-9 {
    margin: 9px;
  }

  .mg-xl-9-f {
    margin: 9px !important;
  }

  .mg-xl-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-xl-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-xl-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-xl-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-xl-t-9 {
    margin-top: 9px;
  }

  .mg-xl-r-9 {
    margin-right: 9px;
  }

  .mg-xl-b-9 {
    margin-bottom: 9px;
  }

  .mg-xl-l-9 {
    margin-left: 9px;
  }

  .mg-xl-t-9-f {
    margin-top: 9px !important;
  }

  .mg-xl-r-9-f {
    margin-right: 9px !important;
  }

  .mg-xl-b-9-f {
    margin-bottom: 9px !important;
  }

  .mg-xl-l-9-f {
    margin-left: 9px !important;
  }

  .mg-xl-10 {
    margin: 10px;
  }

  .mg-xl-10-f {
    margin: 10px !important;
  }

  .mg-xl-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-xl-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-xl-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-xl-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-xl-t-10 {
    margin-top: 10px;
  }

  .mg-xl-r-10 {
    margin-right: 10px;
  }

  .mg-xl-b-10 {
    margin-bottom: 10px;
  }

  .mg-xl-l-10 {
    margin-left: 10px;
  }

  .mg-xl-t-10-f {
    margin-top: 10px !important;
  }

  .mg-xl-r-10-f {
    margin-right: 10px !important;
  }

  .mg-xl-b-10-f {
    margin-bottom: 10px !important;
  }

  .mg-xl-l-10-f {
    margin-left: 10px !important;
  }

  .mg-xl-15 {
    margin: 15px;
  }

  .mg-xl-15-f {
    margin: 15px !important;
  }

  .mg-xl-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-xl-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-xl-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-xl-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-xl-t-15 {
    margin-top: 15px;
  }

  .mg-xl-r-15 {
    margin-right: 15px;
  }

  .mg-xl-b-15 {
    margin-bottom: 15px;
  }

  .mg-xl-l-15 {
    margin-left: 15px;
  }

  .mg-xl-t-15-f {
    margin-top: 15px !important;
  }

  .mg-xl-r-15-f {
    margin-right: 15px !important;
  }

  .mg-xl-b-15-f {
    margin-bottom: 15px !important;
  }

  .mg-xl-l-15-f {
    margin-left: 15px !important;
  }

  .mg-xl-20 {
    margin: 20px;
  }

  .mg-xl-20-f {
    margin: 20px !important;
  }

  .mg-xl-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-xl-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-xl-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-xl-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-xl-t-20 {
    margin-top: 20px;
  }

  .mg-xl-r-20 {
    margin-right: 20px;
  }

  .mg-xl-b-20 {
    margin-bottom: 20px;
  }

  .mg-xl-l-20 {
    margin-left: 20px;
  }

  .mg-xl-t-20-f {
    margin-top: 20px !important;
  }

  .mg-xl-r-20-f {
    margin-right: 20px !important;
  }

  .mg-xl-b-20-f {
    margin-bottom: 20px !important;
  }

  .mg-xl-l-20-f {
    margin-left: 20px !important;
  }

  .mg-xl-25 {
    margin: 25px;
  }

  .mg-xl-25-f {
    margin: 25px !important;
  }

  .mg-xl-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-xl-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-xl-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-xl-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-xl-t-25 {
    margin-top: 25px;
  }

  .mg-xl-r-25 {
    margin-right: 25px;
  }

  .mg-xl-b-25 {
    margin-bottom: 25px;
  }

  .mg-xl-l-25 {
    margin-left: 25px;
  }

  .mg-xl-t-25-f {
    margin-top: 25px !important;
  }

  .mg-xl-r-25-f {
    margin-right: 25px !important;
  }

  .mg-xl-b-25-f {
    margin-bottom: 25px !important;
  }

  .mg-xl-l-25-f {
    margin-left: 25px !important;
  }

  .mg-xl-30 {
    margin: 30px;
  }

  .mg-xl-30-f {
    margin: 30px !important;
  }

  .mg-xl-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-xl-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-xl-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-xl-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-xl-t-30 {
    margin-top: 30px;
  }

  .mg-xl-r-30 {
    margin-right: 30px;
  }

  .mg-xl-b-30 {
    margin-bottom: 30px;
  }

  .mg-xl-l-30 {
    margin-left: 30px;
  }

  .mg-xl-t-30-f {
    margin-top: 30px !important;
  }

  .mg-xl-r-30-f {
    margin-right: 30px !important;
  }

  .mg-xl-b-30-f {
    margin-bottom: 30px !important;
  }

  .mg-xl-l-30-f {
    margin-left: 30px !important;
  }

  .mg-xl-35 {
    margin: 35px;
  }

  .mg-xl-35-f {
    margin: 35px !important;
  }

  .mg-xl-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-xl-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-xl-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-xl-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-xl-t-35 {
    margin-top: 35px;
  }

  .mg-xl-r-35 {
    margin-right: 35px;
  }

  .mg-xl-b-35 {
    margin-bottom: 35px;
  }

  .mg-xl-l-35 {
    margin-left: 35px;
  }

  .mg-xl-t-35-f {
    margin-top: 35px !important;
  }

  .mg-xl-r-35-f {
    margin-right: 35px !important;
  }

  .mg-xl-b-35-f {
    margin-bottom: 35px !important;
  }

  .mg-xl-l-35-f {
    margin-left: 35px !important;
  }

  .mg-xl-40 {
    margin: 40px;
  }

  .mg-xl-40-f {
    margin: 40px !important;
  }

  .mg-xl-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-xl-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-xl-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-xl-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-xl-t-40 {
    margin-top: 40px;
  }

  .mg-xl-r-40 {
    margin-right: 40px;
  }

  .mg-xl-b-40 {
    margin-bottom: 40px;
  }

  .mg-xl-l-40 {
    margin-left: 40px;
  }

  .mg-xl-t-40-f {
    margin-top: 40px !important;
  }

  .mg-xl-r-40-f {
    margin-right: 40px !important;
  }

  .mg-xl-b-40-f {
    margin-bottom: 40px !important;
  }

  .mg-xl-l-40-f {
    margin-left: 40px !important;
  }

  .mg-xl-45 {
    margin: 45px;
  }

  .mg-xl-45-f {
    margin: 45px !important;
  }

  .mg-xl-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-xl-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-xl-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-xl-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-xl-t-45 {
    margin-top: 45px;
  }

  .mg-xl-r-45 {
    margin-right: 45px;
  }

  .mg-xl-b-45 {
    margin-bottom: 45px;
  }

  .mg-xl-l-45 {
    margin-left: 45px;
  }

  .mg-xl-t-45-f {
    margin-top: 45px !important;
  }

  .mg-xl-r-45-f {
    margin-right: 45px !important;
  }

  .mg-xl-b-45-f {
    margin-bottom: 45px !important;
  }

  .mg-xl-l-45-f {
    margin-left: 45px !important;
  }

  .mg-xl-50 {
    margin: 50px;
  }

  .mg-xl-50-f {
    margin: 50px !important;
  }

  .mg-xl-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-xl-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-xl-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-xl-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-xl-t-50 {
    margin-top: 50px;
  }

  .mg-xl-r-50 {
    margin-right: 50px;
  }

  .mg-xl-b-50 {
    margin-bottom: 50px;
  }

  .mg-xl-l-50 {
    margin-left: 50px;
  }

  .mg-xl-t-50-f {
    margin-top: 50px !important;
  }

  .mg-xl-r-50-f {
    margin-right: 50px !important;
  }

  .mg-xl-b-50-f {
    margin-bottom: 50px !important;
  }

  .mg-xl-l-50-f {
    margin-left: 50px !important;
  }

  .mg-xl-55 {
    margin: 55px;
  }

  .mg-xl-55-f {
    margin: 55px !important;
  }

  .mg-xl-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-xl-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-xl-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-xl-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-xl-t-55 {
    margin-top: 55px;
  }

  .mg-xl-r-55 {
    margin-right: 55px;
  }

  .mg-xl-b-55 {
    margin-bottom: 55px;
  }

  .mg-xl-l-55 {
    margin-left: 55px;
  }

  .mg-xl-t-55-f {
    margin-top: 55px !important;
  }

  .mg-xl-r-55-f {
    margin-right: 55px !important;
  }

  .mg-xl-b-55-f {
    margin-bottom: 55px !important;
  }

  .mg-xl-l-55-f {
    margin-left: 55px !important;
  }

  .mg-xl-60 {
    margin: 60px;
  }

  .mg-xl-60-f {
    margin: 60px !important;
  }

  .mg-xl-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-xl-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-xl-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-xl-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-xl-t-60 {
    margin-top: 60px;
  }

  .mg-xl-r-60 {
    margin-right: 60px;
  }

  .mg-xl-b-60 {
    margin-bottom: 60px;
  }

  .mg-xl-l-60 {
    margin-left: 60px;
  }

  .mg-xl-t-60-f {
    margin-top: 60px !important;
  }

  .mg-xl-r-60-f {
    margin-right: 60px !important;
  }

  .mg-xl-b-60-f {
    margin-bottom: 60px !important;
  }

  .mg-xl-l-60-f {
    margin-left: 60px !important;
  }

  .mg-xl-65 {
    margin: 65px;
  }

  .mg-xl-65-f {
    margin: 65px !important;
  }

  .mg-xl-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-xl-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-xl-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-xl-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-xl-t-65 {
    margin-top: 65px;
  }

  .mg-xl-r-65 {
    margin-right: 65px;
  }

  .mg-xl-b-65 {
    margin-bottom: 65px;
  }

  .mg-xl-l-65 {
    margin-left: 65px;
  }

  .mg-xl-t-65-f {
    margin-top: 65px !important;
  }

  .mg-xl-r-65-f {
    margin-right: 65px !important;
  }

  .mg-xl-b-65-f {
    margin-bottom: 65px !important;
  }

  .mg-xl-l-65-f {
    margin-left: 65px !important;
  }

  .mg-xl-70 {
    margin: 70px;
  }

  .mg-xl-70-f {
    margin: 70px !important;
  }

  .mg-xl-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-xl-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-xl-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-xl-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-xl-t-70 {
    margin-top: 70px;
  }

  .mg-xl-r-70 {
    margin-right: 70px;
  }

  .mg-xl-b-70 {
    margin-bottom: 70px;
  }

  .mg-xl-l-70 {
    margin-left: 70px;
  }

  .mg-xl-t-70-f {
    margin-top: 70px !important;
  }

  .mg-xl-r-70-f {
    margin-right: 70px !important;
  }

  .mg-xl-b-70-f {
    margin-bottom: 70px !important;
  }

  .mg-xl-l-70-f {
    margin-left: 70px !important;
  }

  .mg-xl-75 {
    margin: 75px;
  }

  .mg-xl-75-f {
    margin: 75px !important;
  }

  .mg-xl-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-xl-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-xl-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-xl-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-xl-t-75 {
    margin-top: 75px;
  }

  .mg-xl-r-75 {
    margin-right: 75px;
  }

  .mg-xl-b-75 {
    margin-bottom: 75px;
  }

  .mg-xl-l-75 {
    margin-left: 75px;
  }

  .mg-xl-t-75-f {
    margin-top: 75px !important;
  }

  .mg-xl-r-75-f {
    margin-right: 75px !important;
  }

  .mg-xl-b-75-f {
    margin-bottom: 75px !important;
  }

  .mg-xl-l-75-f {
    margin-left: 75px !important;
  }

  .mg-xl-80 {
    margin: 80px;
  }

  .mg-xl-80-f {
    margin: 80px !important;
  }

  .mg-xl-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-xl-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-xl-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-xl-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-xl-t-80 {
    margin-top: 80px;
  }

  .mg-xl-r-80 {
    margin-right: 80px;
  }

  .mg-xl-b-80 {
    margin-bottom: 80px;
  }

  .mg-xl-l-80 {
    margin-left: 80px;
  }

  .mg-xl-t-80-f {
    margin-top: 80px !important;
  }

  .mg-xl-r-80-f {
    margin-right: 80px !important;
  }

  .mg-xl-b-80-f {
    margin-bottom: 80px !important;
  }

  .mg-xl-l-80-f {
    margin-left: 80px !important;
  }

  .mg-xl-85 {
    margin: 85px;
  }

  .mg-xl-85-f {
    margin: 85px !important;
  }

  .mg-xl-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-xl-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-xl-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-xl-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-xl-t-85 {
    margin-top: 85px;
  }

  .mg-xl-r-85 {
    margin-right: 85px;
  }

  .mg-xl-b-85 {
    margin-bottom: 85px;
  }

  .mg-xl-l-85 {
    margin-left: 85px;
  }

  .mg-xl-t-85-f {
    margin-top: 85px !important;
  }

  .mg-xl-r-85-f {
    margin-right: 85px !important;
  }

  .mg-xl-b-85-f {
    margin-bottom: 85px !important;
  }

  .mg-xl-l-85-f {
    margin-left: 85px !important;
  }

  .mg-xl-90 {
    margin: 90px;
  }

  .mg-xl-90-f {
    margin: 90px !important;
  }

  .mg-xl-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-xl-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-xl-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-xl-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-xl-t-90 {
    margin-top: 90px;
  }

  .mg-xl-r-90 {
    margin-right: 90px;
  }

  .mg-xl-b-90 {
    margin-bottom: 90px;
  }

  .mg-xl-l-90 {
    margin-left: 90px;
  }

  .mg-xl-t-90-f {
    margin-top: 90px !important;
  }

  .mg-xl-r-90-f {
    margin-right: 90px !important;
  }

  .mg-xl-b-90-f {
    margin-bottom: 90px !important;
  }

  .mg-xl-l-90-f {
    margin-left: 90px !important;
  }

  .mg-xl-95 {
    margin: 95px;
  }

  .mg-xl-95-f {
    margin: 95px !important;
  }

  .mg-xl-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-xl-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-xl-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-xl-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-xl-t-95 {
    margin-top: 95px;
  }

  .mg-xl-r-95 {
    margin-right: 95px;
  }

  .mg-xl-b-95 {
    margin-bottom: 95px;
  }

  .mg-xl-l-95 {
    margin-left: 95px;
  }

  .mg-xl-t-95-f {
    margin-top: 95px !important;
  }

  .mg-xl-r-95-f {
    margin-right: 95px !important;
  }

  .mg-xl-b-95-f {
    margin-bottom: 95px !important;
  }

  .mg-xl-l-95-f {
    margin-left: 95px !important;
  }

  .mg-xl-100 {
    margin: 100px;
  }

  .mg-xl-100-f {
    margin: 100px !important;
  }

  .mg-xl-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-xl-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-xl-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-xl-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-xl-t-100 {
    margin-top: 100px;
  }

  .mg-xl-r-100 {
    margin-right: 100px;
  }

  .mg-xl-b-100 {
    margin-bottom: 100px;
  }

  .mg-xl-l-100 {
    margin-left: 100px;
  }

  .mg-xl-t-100-f {
    margin-top: 100px !important;
  }

  .mg-xl-r-100-f {
    margin-right: 100px !important;
  }

  .mg-xl-b-100-f {
    margin-bottom: 100px !important;
  }

  .mg-xl-l-100-f {
    margin-left: 100px !important;
  }

  .mg-xl-t-auto {
    margin-top: auto;
  }

  .mg-xl-r-auto {
    margin-right: auto;
  }

  .mg-xl-b-auto {
    margin-bottom: auto;
  }

  .mg-xl-l-auto {
    margin-left: auto;
  }

  .mg-xl-auto {
    margin: auto;
  }

  .mg-xl-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
/* ###### 9.5 Misc   ###### */
.op-0 {
  opacity: 0;
}

.op-0-f {
  opacity: 0 !important;
}

.op-1 {
  opacity: 0.1;
}

.op-1-f {
  opacity: 0.1 !important;
}

.op-2 {
  opacity: 0.2;
}

.op-2-f {
  opacity: 0.2 !important;
}

.op-3 {
  opacity: 0.3;
}

.op-3-f {
  opacity: 0.3 !important;
}

.op-4 {
  opacity: 0.4;
}

.op-4-f {
  opacity: 0.4 !important;
}

.op-5 {
  opacity: 0.5;
}

.op-5-f {
  opacity: 0.5 !important;
}

.op-6 {
  opacity: 0.6;
}

.op-6-f {
  opacity: 0.6 !important;
}

.op-7 {
  opacity: 0.7;
}

.op-7-f {
  opacity: 0.7 !important;
}

.op-8 {
  opacity: 0.8;
}

.op-8-f {
  opacity: 0.8 !important;
}

.op-9 {
  opacity: 0.9;
}

.op-9-f {
  opacity: 0.9 !important;
}

.opacity-1 {
  opacity: 1;
}

.shadow-base {
  box-shadow: 0 0 2px 2px rgba(33, 37, 41, 0.03);
}

.shadow-1 {
  box-shadow: 0 0 7px 7px rgba(33, 37, 41, 0.1);
}

.shadow-2 {
  box-shadow: 0 0 30px rgba(33, 37, 41, 0.08);
}

.shadow-none {
  box-shadow: none;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0;
}

.img-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 480px) {
  .op-xs-0 {
    opacity: 0;
  }

  .op-xs-0-f {
    opacity: 0 !important;
  }

  .op-xs-1 {
    opacity: 0.1;
  }

  .op-xs-1-f {
    opacity: 0.1 !important;
  }

  .op-xs-2 {
    opacity: 0.2;
  }

  .op-xs-2-f {
    opacity: 0.2 !important;
  }

  .op-xs-3 {
    opacity: 0.3;
  }

  .op-xs-3-f {
    opacity: 0.3 !important;
  }

  .op-xs-4 {
    opacity: 0.4;
  }

  .op-xs-4-f {
    opacity: 0.4 !important;
  }

  .op-xs-5 {
    opacity: 0.5;
  }

  .op-xs-5-f {
    opacity: 0.5 !important;
  }

  .op-xs-6 {
    opacity: 0.6;
  }

  .op-xs-6-f {
    opacity: 0.6 !important;
  }

  .op-xs-7 {
    opacity: 0.7;
  }

  .op-xs-7-f {
    opacity: 0.7 !important;
  }

  .op-xs-8 {
    opacity: 0.8;
  }

  .op-xs-8-f {
    opacity: 0.8 !important;
  }

  .op-xs-9 {
    opacity: 0.9;
  }

  .op-xs-9-f {
    opacity: 0.9 !important;
  }
}
@media (min-width: 576px) {
  .op-sm-0 {
    opacity: 0;
  }

  .op-sm-0-f {
    opacity: 0 !important;
  }

  .op-sm-1 {
    opacity: 0.1;
  }

  .op-sm-1-f {
    opacity: 0.1 !important;
  }

  .op-sm-2 {
    opacity: 0.2;
  }

  .op-sm-2-f {
    opacity: 0.2 !important;
  }

  .op-sm-3 {
    opacity: 0.3;
  }

  .op-sm-3-f {
    opacity: 0.3 !important;
  }

  .op-sm-4 {
    opacity: 0.4;
  }

  .op-sm-4-f {
    opacity: 0.4 !important;
  }

  .op-sm-5 {
    opacity: 0.5;
  }

  .op-sm-5-f {
    opacity: 0.5 !important;
  }

  .op-sm-6 {
    opacity: 0.6;
  }

  .op-sm-6-f {
    opacity: 0.6 !important;
  }

  .op-sm-7 {
    opacity: 0.7;
  }

  .op-sm-7-f {
    opacity: 0.7 !important;
  }

  .op-sm-8 {
    opacity: 0.8;
  }

  .op-sm-8-f {
    opacity: 0.8 !important;
  }

  .op-sm-9 {
    opacity: 0.9;
  }

  .op-sm-9-f {
    opacity: 0.9 !important;
  }
}
@media (min-width: 768px) {
  .op-md-0 {
    opacity: 0;
  }

  .op-md-0-f {
    opacity: 0 !important;
  }

  .op-md-1 {
    opacity: 0.1;
  }

  .op-md-1-f {
    opacity: 0.1 !important;
  }

  .op-md-2 {
    opacity: 0.2;
  }

  .op-md-2-f {
    opacity: 0.2 !important;
  }

  .op-md-3 {
    opacity: 0.3;
  }

  .op-md-3-f {
    opacity: 0.3 !important;
  }

  .op-md-4 {
    opacity: 0.4;
  }

  .op-md-4-f {
    opacity: 0.4 !important;
  }

  .op-md-5 {
    opacity: 0.5;
  }

  .op-md-5-f {
    opacity: 0.5 !important;
  }

  .op-md-6 {
    opacity: 0.6;
  }

  .op-md-6-f {
    opacity: 0.6 !important;
  }

  .op-md-7 {
    opacity: 0.7;
  }

  .op-md-7-f {
    opacity: 0.7 !important;
  }

  .op-md-8 {
    opacity: 0.8;
  }

  .op-md-8-f {
    opacity: 0.8 !important;
  }

  .op-md-9 {
    opacity: 0.9;
  }

  .op-md-9-f {
    opacity: 0.9 !important;
  }
}
@media (min-width: 992px) {
  .op-lg-0 {
    opacity: 0;
  }

  .op-lg-0-f {
    opacity: 0 !important;
  }

  .op-lg-1 {
    opacity: 0.1;
  }

  .op-lg-1-f {
    opacity: 0.1 !important;
  }

  .op-lg-2 {
    opacity: 0.2;
  }

  .op-lg-2-f {
    opacity: 0.2 !important;
  }

  .op-lg-3 {
    opacity: 0.3;
  }

  .op-lg-3-f {
    opacity: 0.3 !important;
  }

  .op-lg-4 {
    opacity: 0.4;
  }

  .op-lg-4-f {
    opacity: 0.4 !important;
  }

  .op-lg-5 {
    opacity: 0.5;
  }

  .op-lg-5-f {
    opacity: 0.5 !important;
  }

  .op-lg-6 {
    opacity: 0.6;
  }

  .op-lg-6-f {
    opacity: 0.6 !important;
  }

  .op-lg-7 {
    opacity: 0.7;
  }

  .op-lg-7-f {
    opacity: 0.7 !important;
  }

  .op-lg-8 {
    opacity: 0.8;
  }

  .op-lg-8-f {
    opacity: 0.8 !important;
  }

  .op-lg-9 {
    opacity: 0.9;
  }

  .op-lg-9-f {
    opacity: 0.9 !important;
  }
}
@media (min-width: 1200px) {
  .op-xl-0 {
    opacity: 0;
  }

  .op-xl-0-f {
    opacity: 0 !important;
  }

  .op-xl-1 {
    opacity: 0.1;
  }

  .op-xl-1-f {
    opacity: 0.1 !important;
  }

  .op-xl-2 {
    opacity: 0.2;
  }

  .op-xl-2-f {
    opacity: 0.2 !important;
  }

  .op-xl-3 {
    opacity: 0.3;
  }

  .op-xl-3-f {
    opacity: 0.3 !important;
  }

  .op-xl-4 {
    opacity: 0.4;
  }

  .op-xl-4-f {
    opacity: 0.4 !important;
  }

  .op-xl-5 {
    opacity: 0.5;
  }

  .op-xl-5-f {
    opacity: 0.5 !important;
  }

  .op-xl-6 {
    opacity: 0.6;
  }

  .op-xl-6-f {
    opacity: 0.6 !important;
  }

  .op-xl-7 {
    opacity: 0.7;
  }

  .op-xl-7-f {
    opacity: 0.7 !important;
  }

  .op-xl-8 {
    opacity: 0.8;
  }

  .op-xl-8-f {
    opacity: 0.8 !important;
  }

  .op-xl-9 {
    opacity: 0.9;
  }

  .op-xl-9-f {
    opacity: 0.9 !important;
  }
}
/* ###### 9.6 Padding   ###### */
.pd-0 {
  padding: 0px;
}

.pd-0-f {
  padding: 0px !important;
}

.pd-y-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pd-y-0-f {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pd-x-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pd-x-0-f {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pd-t-0 {
  padding-top: 0px;
}

.pd-r-0 {
  padding-right: 0px;
}

.pd-b-0 {
  padding-bottom: 0px;
}

.pd-l-0 {
  padding-left: 0px;
}

.pd-t-0-f {
  padding-top: 0px !important;
}

.pd-r-0-f {
  padding-right: 0px !important;
}

.pd-b-0-f {
  padding-bottom: 0px !important;
}

.pd-l-0-f {
  padding-left: 0px !important;
}

.pd-1 {
  padding: 1px;
}

.pd-1-f {
  padding: 1px !important;
}

.pd-y-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pd-y-1-f {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pd-x-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.pd-x-1-f {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.pd-t-1 {
  padding-top: 1px;
}

.pd-r-1 {
  padding-right: 1px;
}

.pd-b-1 {
  padding-bottom: 1px;
}

.pd-l-1 {
  padding-left: 1px;
}

.pd-t-1-f {
  padding-top: 1px !important;
}

.pd-r-1-f {
  padding-right: 1px !important;
}

.pd-b-1-f {
  padding-bottom: 1px !important;
}

.pd-l-1-f {
  padding-left: 1px !important;
}

.pd-2 {
  padding: 2px;
}

.pd-2-f {
  padding: 2px !important;
}

.pd-y-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.pd-y-2-f {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.pd-x-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.pd-x-2-f {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.pd-t-2 {
  padding-top: 2px;
}

.pd-r-2 {
  padding-right: 2px;
}

.pd-b-2 {
  padding-bottom: 2px;
}

.pd-l-2 {
  padding-left: 2px;
}

.pd-t-2-f {
  padding-top: 2px !important;
}

.pd-r-2-f {
  padding-right: 2px !important;
}

.pd-b-2-f {
  padding-bottom: 2px !important;
}

.pd-l-2-f {
  padding-left: 2px !important;
}

.pd-3 {
  padding: 3px;
}

.pd-3-f {
  padding: 3px !important;
}

.pd-y-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pd-y-3-f {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.pd-x-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.pd-x-3-f {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.pd-t-3 {
  padding-top: 3px;
}

.pd-r-3 {
  padding-right: 3px;
}

.pd-b-3 {
  padding-bottom: 3px;
}

.pd-l-3 {
  padding-left: 3px;
}

.pd-t-3-f {
  padding-top: 3px !important;
}

.pd-r-3-f {
  padding-right: 3px !important;
}

.pd-b-3-f {
  padding-bottom: 3px !important;
}

.pd-l-3-f {
  padding-left: 3px !important;
}

.pd-4 {
  padding: 4px;
}

.pd-4-f {
  padding: 4px !important;
}

.pd-y-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pd-y-4-f {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.pd-x-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.pd-x-4-f {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.pd-t-4 {
  padding-top: 4px;
}

.pd-r-4 {
  padding-right: 4px;
}

.pd-b-4 {
  padding-bottom: 4px;
}

.pd-l-4 {
  padding-left: 4px;
}

.pd-t-4-f {
  padding-top: 4px !important;
}

.pd-r-4-f {
  padding-right: 4px !important;
}

.pd-b-4-f {
  padding-bottom: 4px !important;
}

.pd-l-4-f {
  padding-left: 4px !important;
}

.pd-5 {
  padding: 5px;
}

.pd-5-f {
  padding: 5px !important;
}

.pd-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pd-y-5-f {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pd-x-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pd-x-5-f {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pd-t-5 {
  padding-top: 5px;
}

.pd-r-5 {
  padding-right: 5px;
}

.pd-b-5 {
  padding-bottom: 5px;
}

.pd-l-5 {
  padding-left: 5px;
}

.pd-t-5-f {
  padding-top: 5px !important;
}

.pd-r-5-f {
  padding-right: 5px !important;
}

.pd-b-5-f {
  padding-bottom: 5px !important;
}

.pd-l-5-f {
  padding-left: 5px !important;
}

.pd-6 {
  padding: 6px;
}

.pd-6-f {
  padding: 6px !important;
}

.pd-y-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pd-y-6-f {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.pd-x-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.pd-x-6-f {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.pd-t-6 {
  padding-top: 6px;
}

.pd-r-6 {
  padding-right: 6px;
}

.pd-b-6 {
  padding-bottom: 6px;
}

.pd-l-6 {
  padding-left: 6px;
}

.pd-t-6-f {
  padding-top: 6px !important;
}

.pd-r-6-f {
  padding-right: 6px !important;
}

.pd-b-6-f {
  padding-bottom: 6px !important;
}

.pd-l-6-f {
  padding-left: 6px !important;
}

.pd-7 {
  padding: 7px;
}

.pd-7-f {
  padding: 7px !important;
}

.pd-y-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.pd-y-7-f {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.pd-x-7 {
  padding-left: 7px;
  padding-right: 7px;
}

.pd-x-7-f {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.pd-t-7 {
  padding-top: 7px;
}

.pd-r-7 {
  padding-right: 7px;
}

.pd-b-7 {
  padding-bottom: 7px;
}

.pd-l-7 {
  padding-left: 7px;
}

.pd-t-7-f {
  padding-top: 7px !important;
}

.pd-r-7-f {
  padding-right: 7px !important;
}

.pd-b-7-f {
  padding-bottom: 7px !important;
}

.pd-l-7-f {
  padding-left: 7px !important;
}

.pd-8 {
  padding: 8px;
}

.pd-8-f {
  padding: 8px !important;
}

.pd-y-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pd-y-8-f {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pd-x-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pd-x-8-f {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.pd-t-8 {
  padding-top: 8px;
}

.pd-r-8 {
  padding-right: 8px;
}

.pd-b-8 {
  padding-bottom: 8px;
}

.pd-l-8 {
  padding-left: 8px;
}

.pd-t-8-f {
  padding-top: 8px !important;
}

.pd-r-8-f {
  padding-right: 8px !important;
}

.pd-b-8-f {
  padding-bottom: 8px !important;
}

.pd-l-8-f {
  padding-left: 8px !important;
}

.pd-9 {
  padding: 9px;
}

.pd-9-f {
  padding: 9px !important;
}

.pd-y-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.pd-y-9-f {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.pd-x-9 {
  padding-left: 9px;
  padding-right: 9px;
}

.pd-x-9-f {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.pd-t-9 {
  padding-top: 9px;
}

.pd-r-9 {
  padding-right: 9px;
}

.pd-b-9 {
  padding-bottom: 9px;
}

.pd-l-9 {
  padding-left: 9px;
}

.pd-t-9-f {
  padding-top: 9px !important;
}

.pd-r-9-f {
  padding-right: 9px !important;
}

.pd-b-9-f {
  padding-bottom: 9px !important;
}

.pd-l-9-f {
  padding-left: 9px !important;
}

.pd-10 {
  padding: 10px;
}

.pd-10-f {
  padding: 10px !important;
}

.pd-y-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pd-y-10-f {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pd-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pd-x-10-f {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pd-t-10 {
  padding-top: 10px;
}

.pd-r-10 {
  padding-right: 10px;
}

.pd-b-10 {
  padding-bottom: 10px;
}

.pd-l-10 {
  padding-left: 10px;
}

.pd-t-10-f {
  padding-top: 10px !important;
}

.pd-r-10-f {
  padding-right: 10px !important;
}

.pd-b-10-f {
  padding-bottom: 10px !important;
}

.pd-l-10-f {
  padding-left: 10px !important;
}

.pd-15 {
  padding: 15px;
}

.pd-15-f {
  padding: 15px !important;
}

.pd-y-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pd-y-15-f {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pd-x-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pd-x-15-f {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pd-t-15 {
  padding-top: 15px;
}

.pd-r-15 {
  padding-right: 15px;
}

.pd-b-15 {
  padding-bottom: 15px;
}

.pd-l-15 {
  padding-left: 15px;
}

.pd-t-15-f {
  padding-top: 15px !important;
}

.pd-r-15-f {
  padding-right: 15px !important;
}

.pd-b-15-f {
  padding-bottom: 15px !important;
}

.pd-l-15-f {
  padding-left: 15px !important;
}

.pd-20 {
  padding: 20px;
}

.pd-20-f {
  padding: 20px !important;
}

.pd-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pd-y-20-f {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pd-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pd-x-20-f {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pd-t-20 {
  padding-top: 20px;
}

.pd-r-20 {
  padding-right: 20px;
}

.pd-b-20 {
  padding-bottom: 20px;
}

.pd-l-20 {
  padding-left: 20px;
}

.pd-t-20-f {
  padding-top: 20px !important;
}

.pd-r-20-f {
  padding-right: 20px !important;
}

.pd-b-20-f {
  padding-bottom: 20px !important;
}

.pd-l-20-f {
  padding-left: 20px !important;
}

.pd-25 {
  padding: 25px;
}

.pd-25-f {
  padding: 25px !important;
}

.pd-y-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pd-y-25-f {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pd-x-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pd-x-25-f {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.pd-t-25 {
  padding-top: 25px;
}

.pd-r-25 {
  padding-right: 25px;
}

.pd-b-25 {
  padding-bottom: 25px;
}

.pd-l-25 {
  padding-left: 25px;
}

.pd-t-25-f {
  padding-top: 25px !important;
}

.pd-r-25-f {
  padding-right: 25px !important;
}

.pd-b-25-f {
  padding-bottom: 25px !important;
}

.pd-l-25-f {
  padding-left: 25px !important;
}

.pd-30 {
  padding: 30px;
}

.pd-30-f {
  padding: 30px !important;
}

.pd-y-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pd-y-30-f {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pd-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pd-x-30-f {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pd-t-30 {
  padding-top: 30px;
}

.pd-r-30 {
  padding-right: 30px;
}

.pd-b-30 {
  padding-bottom: 30px;
}

.pd-l-30 {
  padding-left: 30px;
}

.pd-t-30-f {
  padding-top: 30px !important;
}

.pd-r-30-f {
  padding-right: 30px !important;
}

.pd-b-30-f {
  padding-bottom: 30px !important;
}

.pd-l-30-f {
  padding-left: 30px !important;
}

.pd-35 {
  padding: 35px;
}

.pd-35-f {
  padding: 35px !important;
}

.pd-y-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.pd-y-35-f {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pd-x-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.pd-x-35-f {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.pd-t-35 {
  padding-top: 35px;
}

.pd-r-35 {
  padding-right: 35px;
}

.pd-b-35 {
  padding-bottom: 35px;
}

.pd-l-35 {
  padding-left: 35px;
}

.pd-t-35-f {
  padding-top: 35px !important;
}

.pd-r-35-f {
  padding-right: 35px !important;
}

.pd-b-35-f {
  padding-bottom: 35px !important;
}

.pd-l-35-f {
  padding-left: 35px !important;
}

.pd-40 {
  padding: 40px;
}

.pd-40-f {
  padding: 40px !important;
}

.pd-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pd-y-40-f {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pd-x-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pd-x-40-f {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pd-t-40 {
  padding-top: 40px;
}

.pd-r-40 {
  padding-right: 40px;
}

.pd-b-40 {
  padding-bottom: 40px;
}

.pd-l-40 {
  padding-left: 40px;
}

.pd-t-40-f {
  padding-top: 40px !important;
}

.pd-r-40-f {
  padding-right: 40px !important;
}

.pd-b-40-f {
  padding-bottom: 40px !important;
}

.pd-l-40-f {
  padding-left: 40px !important;
}

.pd-45 {
  padding: 45px;
}

.pd-45-f {
  padding: 45px !important;
}

.pd-y-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.pd-y-45-f {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pd-x-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pd-x-45-f {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pd-t-45 {
  padding-top: 45px;
}

.pd-r-45 {
  padding-right: 45px;
}

.pd-b-45 {
  padding-bottom: 45px;
}

.pd-l-45 {
  padding-left: 45px;
}

.pd-t-45-f {
  padding-top: 45px !important;
}

.pd-r-45-f {
  padding-right: 45px !important;
}

.pd-b-45-f {
  padding-bottom: 45px !important;
}

.pd-l-45-f {
  padding-left: 45px !important;
}

.pd-50 {
  padding: 50px;
}

.pd-50-f {
  padding: 50px !important;
}

.pd-y-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pd-y-50-f {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pd-x-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pd-x-50-f {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pd-t-50 {
  padding-top: 50px;
}

.pd-r-50 {
  padding-right: 50px;
}

.pd-b-50 {
  padding-bottom: 50px;
}

.pd-l-50 {
  padding-left: 50px;
}

.pd-t-50-f {
  padding-top: 50px !important;
}

.pd-r-50-f {
  padding-right: 50px !important;
}

.pd-b-50-f {
  padding-bottom: 50px !important;
}

.pd-l-50-f {
  padding-left: 50px !important;
}

.pd-55 {
  padding: 55px;
}

.pd-55-f {
  padding: 55px !important;
}

.pd-y-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.pd-y-55-f {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pd-x-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.pd-x-55-f {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.pd-t-55 {
  padding-top: 55px;
}

.pd-r-55 {
  padding-right: 55px;
}

.pd-b-55 {
  padding-bottom: 55px;
}

.pd-l-55 {
  padding-left: 55px;
}

.pd-t-55-f {
  padding-top: 55px !important;
}

.pd-r-55-f {
  padding-right: 55px !important;
}

.pd-b-55-f {
  padding-bottom: 55px !important;
}

.pd-l-55-f {
  padding-left: 55px !important;
}

.pd-60 {
  padding: 60px;
}

.pd-60-f {
  padding: 60px !important;
}

.pd-y-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pd-y-60-f {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pd-x-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.pd-x-60-f {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pd-t-60 {
  padding-top: 60px;
}

.pd-r-60 {
  padding-right: 60px;
}

.pd-b-60 {
  padding-bottom: 60px;
}

.pd-l-60 {
  padding-left: 60px;
}

.pd-t-60-f {
  padding-top: 60px !important;
}

.pd-r-60-f {
  padding-right: 60px !important;
}

.pd-b-60-f {
  padding-bottom: 60px !important;
}

.pd-l-60-f {
  padding-left: 60px !important;
}

.pd-65 {
  padding: 65px;
}

.pd-65-f {
  padding: 65px !important;
}

.pd-y-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.pd-y-65-f {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pd-x-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.pd-x-65-f {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.pd-t-65 {
  padding-top: 65px;
}

.pd-r-65 {
  padding-right: 65px;
}

.pd-b-65 {
  padding-bottom: 65px;
}

.pd-l-65 {
  padding-left: 65px;
}

.pd-t-65-f {
  padding-top: 65px !important;
}

.pd-r-65-f {
  padding-right: 65px !important;
}

.pd-b-65-f {
  padding-bottom: 65px !important;
}

.pd-l-65-f {
  padding-left: 65px !important;
}

.pd-70 {
  padding: 70px;
}

.pd-70-f {
  padding: 70px !important;
}

.pd-y-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pd-y-70-f {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pd-x-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.pd-x-70-f {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.pd-t-70 {
  padding-top: 70px;
}

.pd-r-70 {
  padding-right: 70px;
}

.pd-b-70 {
  padding-bottom: 70px;
}

.pd-l-70 {
  padding-left: 70px;
}

.pd-t-70-f {
  padding-top: 70px !important;
}

.pd-r-70-f {
  padding-right: 70px !important;
}

.pd-b-70-f {
  padding-bottom: 70px !important;
}

.pd-l-70-f {
  padding-left: 70px !important;
}

.pd-75 {
  padding: 75px;
}

.pd-75-f {
  padding: 75px !important;
}

.pd-y-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.pd-y-75-f {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pd-x-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.pd-x-75-f {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.pd-t-75 {
  padding-top: 75px;
}

.pd-r-75 {
  padding-right: 75px;
}

.pd-b-75 {
  padding-bottom: 75px;
}

.pd-l-75 {
  padding-left: 75px;
}

.pd-t-75-f {
  padding-top: 75px !important;
}

.pd-r-75-f {
  padding-right: 75px !important;
}

.pd-b-75-f {
  padding-bottom: 75px !important;
}

.pd-l-75-f {
  padding-left: 75px !important;
}

.pd-80 {
  padding: 80px;
}

.pd-80-f {
  padding: 80px !important;
}

.pd-y-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pd-y-80-f {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pd-x-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.pd-x-80-f {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pd-t-80 {
  padding-top: 80px;
}

.pd-r-80 {
  padding-right: 80px;
}

.pd-b-80 {
  padding-bottom: 80px;
}

.pd-l-80 {
  padding-left: 80px;
}

.pd-t-80-f {
  padding-top: 80px !important;
}

.pd-r-80-f {
  padding-right: 80px !important;
}

.pd-b-80-f {
  padding-bottom: 80px !important;
}

.pd-l-80-f {
  padding-left: 80px !important;
}

.pd-85 {
  padding: 85px;
}

.pd-85-f {
  padding: 85px !important;
}

.pd-y-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.pd-y-85-f {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pd-x-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.pd-x-85-f {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.pd-t-85 {
  padding-top: 85px;
}

.pd-r-85 {
  padding-right: 85px;
}

.pd-b-85 {
  padding-bottom: 85px;
}

.pd-l-85 {
  padding-left: 85px;
}

.pd-t-85-f {
  padding-top: 85px !important;
}

.pd-r-85-f {
  padding-right: 85px !important;
}

.pd-b-85-f {
  padding-bottom: 85px !important;
}

.pd-l-85-f {
  padding-left: 85px !important;
}

.pd-90 {
  padding: 90px;
}

.pd-90-f {
  padding: 90px !important;
}

.pd-y-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.pd-y-90-f {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pd-x-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.pd-x-90-f {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pd-t-90 {
  padding-top: 90px;
}

.pd-r-90 {
  padding-right: 90px;
}

.pd-b-90 {
  padding-bottom: 90px;
}

.pd-l-90 {
  padding-left: 90px;
}

.pd-t-90-f {
  padding-top: 90px !important;
}

.pd-r-90-f {
  padding-right: 90px !important;
}

.pd-b-90-f {
  padding-bottom: 90px !important;
}

.pd-l-90-f {
  padding-left: 90px !important;
}

.pd-95 {
  padding: 95px;
}

.pd-95-f {
  padding: 95px !important;
}

.pd-y-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.pd-y-95-f {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pd-x-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.pd-x-95-f {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.pd-t-95 {
  padding-top: 95px;
}

.pd-r-95 {
  padding-right: 95px;
}

.pd-b-95 {
  padding-bottom: 95px;
}

.pd-l-95 {
  padding-left: 95px;
}

.pd-t-95-f {
  padding-top: 95px !important;
}

.pd-r-95-f {
  padding-right: 95px !important;
}

.pd-b-95-f {
  padding-bottom: 95px !important;
}

.pd-l-95-f {
  padding-left: 95px !important;
}

.pd-100 {
  padding: 100px;
}

.pd-100-f {
  padding: 100px !important;
}

.pd-y-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pd-y-100-f {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pd-x-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.pd-x-100-f {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.pd-t-100 {
  padding-top: 100px;
}

.pd-r-100 {
  padding-right: 100px;
}

.pd-b-100 {
  padding-bottom: 100px;
}

.pd-l-100 {
  padding-left: 100px;
}

.pd-t-100-f {
  padding-top: 100px !important;
}

.pd-r-100-f {
  padding-right: 100px !important;
}

.pd-b-100-f {
  padding-bottom: 100px !important;
}

.pd-l-100-f {
  padding-left: 100px !important;
}

.pd-110 {
  padding: 110px;
}

.pd-110-f {
  padding: 110px !important;
}

.pd-y-110 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.pd-y-110-f {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pd-x-110 {
  padding-left: 110px;
  padding-right: 110px;
}

.pd-x-110-f {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.pd-t-110 {
  padding-top: 110px;
}

.pd-r-110 {
  padding-right: 110px;
}

.pd-b-110 {
  padding-bottom: 110px;
}

.pd-l-110 {
  padding-left: 110px;
}

.pd-t-110-f {
  padding-top: 110px !important;
}

.pd-r-110-f {
  padding-right: 110px !important;
}

.pd-b-110-f {
  padding-bottom: 110px !important;
}

.pd-l-110-f {
  padding-left: 110px !important;
}

.pd-120 {
  padding: 120px;
}

.pd-120-f {
  padding: 120px !important;
}

.pd-y-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.pd-y-120-f {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pd-x-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.pd-x-120-f {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.pd-t-120 {
  padding-top: 120px;
}

.pd-r-120 {
  padding-right: 120px;
}

.pd-b-120 {
  padding-bottom: 120px;
}

.pd-l-120 {
  padding-left: 120px;
}

.pd-t-120-f {
  padding-top: 120px !important;
}

.pd-r-120-f {
  padding-right: 120px !important;
}

.pd-b-120-f {
  padding-bottom: 120px !important;
}

.pd-l-120-f {
  padding-left: 120px !important;
}

.pd-130 {
  padding: 130px;
}

.pd-130-f {
  padding: 130px !important;
}

.pd-y-130 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.pd-y-130-f {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.pd-x-130 {
  padding-left: 130px;
  padding-right: 130px;
}

.pd-x-130-f {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.pd-t-130 {
  padding-top: 130px;
}

.pd-r-130 {
  padding-right: 130px;
}

.pd-b-130 {
  padding-bottom: 130px;
}

.pd-l-130 {
  padding-left: 130px;
}

.pd-t-130-f {
  padding-top: 130px !important;
}

.pd-r-130-f {
  padding-right: 130px !important;
}

.pd-b-130-f {
  padding-bottom: 130px !important;
}

.pd-l-130-f {
  padding-left: 130px !important;
}

.pd-140 {
  padding: 140px;
}

.pd-140-f {
  padding: 140px !important;
}

.pd-y-140 {
  padding-top: 140px;
  padding-bottom: 140px;
}

.pd-y-140-f {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.pd-x-140 {
  padding-left: 140px;
  padding-right: 140px;
}

.pd-x-140-f {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.pd-t-140 {
  padding-top: 140px;
}

.pd-r-140 {
  padding-right: 140px;
}

.pd-b-140 {
  padding-bottom: 140px;
}

.pd-l-140 {
  padding-left: 140px;
}

.pd-t-140-f {
  padding-top: 140px !important;
}

.pd-r-140-f {
  padding-right: 140px !important;
}

.pd-b-140-f {
  padding-bottom: 140px !important;
}

.pd-l-140-f {
  padding-left: 140px !important;
}

.pd-150 {
  padding: 150px;
}

.pd-150-f {
  padding: 150px !important;
}

.pd-y-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pd-y-150-f {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pd-x-150 {
  padding-left: 150px;
  padding-right: 150px;
}

.pd-x-150-f {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.pd-t-150 {
  padding-top: 150px;
}

.pd-r-150 {
  padding-right: 150px;
}

.pd-b-150 {
  padding-bottom: 150px;
}

.pd-l-150 {
  padding-left: 150px;
}

.pd-t-150-f {
  padding-top: 150px !important;
}

.pd-r-150-f {
  padding-right: 150px !important;
}

.pd-b-150-f {
  padding-bottom: 150px !important;
}

.pd-l-150-f {
  padding-left: 150px !important;
}

.pd-160 {
  padding: 160px;
}

.pd-160-f {
  padding: 160px !important;
}

.pd-y-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.pd-y-160-f {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

.pd-x-160 {
  padding-left: 160px;
  padding-right: 160px;
}

.pd-x-160-f {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

.pd-t-160 {
  padding-top: 160px;
}

.pd-r-160 {
  padding-right: 160px;
}

.pd-b-160 {
  padding-bottom: 160px;
}

.pd-l-160 {
  padding-left: 160px;
}

.pd-t-160-f {
  padding-top: 160px !important;
}

.pd-r-160-f {
  padding-right: 160px !important;
}

.pd-b-160-f {
  padding-bottom: 160px !important;
}

.pd-l-160-f {
  padding-left: 160px !important;
}

.pd-170 {
  padding: 170px;
}

.pd-170-f {
  padding: 170px !important;
}

.pd-y-170 {
  padding-top: 170px;
  padding-bottom: 170px;
}

.pd-y-170-f {
  padding-top: 170px !important;
  padding-bottom: 170px !important;
}

.pd-x-170 {
  padding-left: 170px;
  padding-right: 170px;
}

.pd-x-170-f {
  padding-left: 170px !important;
  padding-right: 170px !important;
}

.pd-t-170 {
  padding-top: 170px;
}

.pd-r-170 {
  padding-right: 170px;
}

.pd-b-170 {
  padding-bottom: 170px;
}

.pd-l-170 {
  padding-left: 170px;
}

.pd-t-170-f {
  padding-top: 170px !important;
}

.pd-r-170-f {
  padding-right: 170px !important;
}

.pd-b-170-f {
  padding-bottom: 170px !important;
}

.pd-l-170-f {
  padding-left: 170px !important;
}

.pd-180 {
  padding: 180px;
}

.pd-180-f {
  padding: 180px !important;
}

.pd-y-180 {
  padding-top: 180px;
  padding-bottom: 180px;
}

.pd-y-180-f {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.pd-x-180 {
  padding-left: 180px;
  padding-right: 180px;
}

.pd-x-180-f {
  padding-left: 180px !important;
  padding-right: 180px !important;
}

.pd-t-180 {
  padding-top: 180px;
}

.pd-r-180 {
  padding-right: 180px;
}

.pd-b-180 {
  padding-bottom: 180px;
}

.pd-l-180 {
  padding-left: 180px;
}

.pd-t-180-f {
  padding-top: 180px !important;
}

.pd-r-180-f {
  padding-right: 180px !important;
}

.pd-b-180-f {
  padding-bottom: 180px !important;
}

.pd-l-180-f {
  padding-left: 180px !important;
}

.pd-190 {
  padding: 190px;
}

.pd-190-f {
  padding: 190px !important;
}

.pd-y-190 {
  padding-top: 190px;
  padding-bottom: 190px;
}

.pd-y-190-f {
  padding-top: 190px !important;
  padding-bottom: 190px !important;
}

.pd-x-190 {
  padding-left: 190px;
  padding-right: 190px;
}

.pd-x-190-f {
  padding-left: 190px !important;
  padding-right: 190px !important;
}

.pd-t-190 {
  padding-top: 190px;
}

.pd-r-190 {
  padding-right: 190px;
}

.pd-b-190 {
  padding-bottom: 190px;
}

.pd-l-190 {
  padding-left: 190px;
}

.pd-t-190-f {
  padding-top: 190px !important;
}

.pd-r-190-f {
  padding-right: 190px !important;
}

.pd-b-190-f {
  padding-bottom: 190px !important;
}

.pd-l-190-f {
  padding-left: 190px !important;
}

.pd-200 {
  padding: 200px;
}

.pd-200-f {
  padding: 200px !important;
}

.pd-y-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.pd-y-200-f {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.pd-x-200 {
  padding-left: 200px;
  padding-right: 200px;
}

.pd-x-200-f {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.pd-t-200 {
  padding-top: 200px;
}

.pd-r-200 {
  padding-right: 200px;
}

.pd-b-200 {
  padding-bottom: 200px;
}

.pd-l-200 {
  padding-left: 200px;
}

.pd-t-200-f {
  padding-top: 200px !important;
}

.pd-r-200-f {
  padding-right: 200px !important;
}

.pd-b-200-f {
  padding-bottom: 200px !important;
}

.pd-l-200-f {
  padding-left: 200px !important;
}

@media (min-width: 480px) {
  .pd-xs-0 {
    padding: 0px;
  }

  .pd-xs-0-f {
    padding: 0px !important;
  }

  .pd-xs-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-xs-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-xs-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-xs-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-xs-t-0 {
    padding-top: 0px;
  }

  .pd-xs-r-0 {
    padding-right: 0px;
  }

  .pd-xs-b-0 {
    padding-bottom: 0px;
  }

  .pd-xs-l-0 {
    padding-left: 0px;
  }

  .pd-xs-t-0-f {
    padding-top: 0px !important;
  }

  .pd-xs-r-0-f {
    padding-right: 0px !important;
  }

  .pd-xs-b-0-f {
    padding-bottom: 0px !important;
  }

  .pd-xs-l-0-f {
    padding-left: 0px !important;
  }

  .pd-xs-1 {
    padding: 1px;
  }

  .pd-xs-1-f {
    padding: 1px !important;
  }

  .pd-xs-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-xs-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-xs-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-xs-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-xs-t-1 {
    padding-top: 1px;
  }

  .pd-xs-r-1 {
    padding-right: 1px;
  }

  .pd-xs-b-1 {
    padding-bottom: 1px;
  }

  .pd-xs-l-1 {
    padding-left: 1px;
  }

  .pd-xs-t-1-f {
    padding-top: 1px !important;
  }

  .pd-xs-r-1-f {
    padding-right: 1px !important;
  }

  .pd-xs-b-1-f {
    padding-bottom: 1px !important;
  }

  .pd-xs-l-1-f {
    padding-left: 1px !important;
  }

  .pd-xs-2 {
    padding: 2px;
  }

  .pd-xs-2-f {
    padding: 2px !important;
  }

  .pd-xs-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-xs-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-xs-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-xs-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-xs-t-2 {
    padding-top: 2px;
  }

  .pd-xs-r-2 {
    padding-right: 2px;
  }

  .pd-xs-b-2 {
    padding-bottom: 2px;
  }

  .pd-xs-l-2 {
    padding-left: 2px;
  }

  .pd-xs-t-2-f {
    padding-top: 2px !important;
  }

  .pd-xs-r-2-f {
    padding-right: 2px !important;
  }

  .pd-xs-b-2-f {
    padding-bottom: 2px !important;
  }

  .pd-xs-l-2-f {
    padding-left: 2px !important;
  }

  .pd-xs-3 {
    padding: 3px;
  }

  .pd-xs-3-f {
    padding: 3px !important;
  }

  .pd-xs-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-xs-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-xs-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-xs-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-xs-t-3 {
    padding-top: 3px;
  }

  .pd-xs-r-3 {
    padding-right: 3px;
  }

  .pd-xs-b-3 {
    padding-bottom: 3px;
  }

  .pd-xs-l-3 {
    padding-left: 3px;
  }

  .pd-xs-t-3-f {
    padding-top: 3px !important;
  }

  .pd-xs-r-3-f {
    padding-right: 3px !important;
  }

  .pd-xs-b-3-f {
    padding-bottom: 3px !important;
  }

  .pd-xs-l-3-f {
    padding-left: 3px !important;
  }

  .pd-xs-4 {
    padding: 4px;
  }

  .pd-xs-4-f {
    padding: 4px !important;
  }

  .pd-xs-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-xs-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-xs-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-xs-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-xs-t-4 {
    padding-top: 4px;
  }

  .pd-xs-r-4 {
    padding-right: 4px;
  }

  .pd-xs-b-4 {
    padding-bottom: 4px;
  }

  .pd-xs-l-4 {
    padding-left: 4px;
  }

  .pd-xs-t-4-f {
    padding-top: 4px !important;
  }

  .pd-xs-r-4-f {
    padding-right: 4px !important;
  }

  .pd-xs-b-4-f {
    padding-bottom: 4px !important;
  }

  .pd-xs-l-4-f {
    padding-left: 4px !important;
  }

  .pd-xs-5 {
    padding: 5px;
  }

  .pd-xs-5-f {
    padding: 5px !important;
  }

  .pd-xs-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-xs-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-xs-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-xs-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-xs-t-5 {
    padding-top: 5px;
  }

  .pd-xs-r-5 {
    padding-right: 5px;
  }

  .pd-xs-b-5 {
    padding-bottom: 5px;
  }

  .pd-xs-l-5 {
    padding-left: 5px;
  }

  .pd-xs-t-5-f {
    padding-top: 5px !important;
  }

  .pd-xs-r-5-f {
    padding-right: 5px !important;
  }

  .pd-xs-b-5-f {
    padding-bottom: 5px !important;
  }

  .pd-xs-l-5-f {
    padding-left: 5px !important;
  }

  .pd-xs-6 {
    padding: 6px;
  }

  .pd-xs-6-f {
    padding: 6px !important;
  }

  .pd-xs-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-xs-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-xs-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-xs-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-xs-t-6 {
    padding-top: 6px;
  }

  .pd-xs-r-6 {
    padding-right: 6px;
  }

  .pd-xs-b-6 {
    padding-bottom: 6px;
  }

  .pd-xs-l-6 {
    padding-left: 6px;
  }

  .pd-xs-t-6-f {
    padding-top: 6px !important;
  }

  .pd-xs-r-6-f {
    padding-right: 6px !important;
  }

  .pd-xs-b-6-f {
    padding-bottom: 6px !important;
  }

  .pd-xs-l-6-f {
    padding-left: 6px !important;
  }

  .pd-xs-7 {
    padding: 7px;
  }

  .pd-xs-7-f {
    padding: 7px !important;
  }

  .pd-xs-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-xs-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-xs-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-xs-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-xs-t-7 {
    padding-top: 7px;
  }

  .pd-xs-r-7 {
    padding-right: 7px;
  }

  .pd-xs-b-7 {
    padding-bottom: 7px;
  }

  .pd-xs-l-7 {
    padding-left: 7px;
  }

  .pd-xs-t-7-f {
    padding-top: 7px !important;
  }

  .pd-xs-r-7-f {
    padding-right: 7px !important;
  }

  .pd-xs-b-7-f {
    padding-bottom: 7px !important;
  }

  .pd-xs-l-7-f {
    padding-left: 7px !important;
  }

  .pd-xs-8 {
    padding: 8px;
  }

  .pd-xs-8-f {
    padding: 8px !important;
  }

  .pd-xs-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-xs-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-xs-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-xs-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-xs-t-8 {
    padding-top: 8px;
  }

  .pd-xs-r-8 {
    padding-right: 8px;
  }

  .pd-xs-b-8 {
    padding-bottom: 8px;
  }

  .pd-xs-l-8 {
    padding-left: 8px;
  }

  .pd-xs-t-8-f {
    padding-top: 8px !important;
  }

  .pd-xs-r-8-f {
    padding-right: 8px !important;
  }

  .pd-xs-b-8-f {
    padding-bottom: 8px !important;
  }

  .pd-xs-l-8-f {
    padding-left: 8px !important;
  }

  .pd-xs-9 {
    padding: 9px;
  }

  .pd-xs-9-f {
    padding: 9px !important;
  }

  .pd-xs-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-xs-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-xs-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-xs-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-xs-t-9 {
    padding-top: 9px;
  }

  .pd-xs-r-9 {
    padding-right: 9px;
  }

  .pd-xs-b-9 {
    padding-bottom: 9px;
  }

  .pd-xs-l-9 {
    padding-left: 9px;
  }

  .pd-xs-t-9-f {
    padding-top: 9px !important;
  }

  .pd-xs-r-9-f {
    padding-right: 9px !important;
  }

  .pd-xs-b-9-f {
    padding-bottom: 9px !important;
  }

  .pd-xs-l-9-f {
    padding-left: 9px !important;
  }

  .pd-xs-10 {
    padding: 10px;
  }

  .pd-xs-10-f {
    padding: 10px !important;
  }

  .pd-xs-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-xs-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-xs-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-xs-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-xs-t-10 {
    padding-top: 10px;
  }

  .pd-xs-r-10 {
    padding-right: 10px;
  }

  .pd-xs-b-10 {
    padding-bottom: 10px;
  }

  .pd-xs-l-10 {
    padding-left: 10px;
  }

  .pd-xs-t-10-f {
    padding-top: 10px !important;
  }

  .pd-xs-r-10-f {
    padding-right: 10px !important;
  }

  .pd-xs-b-10-f {
    padding-bottom: 10px !important;
  }

  .pd-xs-l-10-f {
    padding-left: 10px !important;
  }

  .pd-xs-15 {
    padding: 15px;
  }

  .pd-xs-15-f {
    padding: 15px !important;
  }

  .pd-xs-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-xs-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-xs-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-xs-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-xs-t-15 {
    padding-top: 15px;
  }

  .pd-xs-r-15 {
    padding-right: 15px;
  }

  .pd-xs-b-15 {
    padding-bottom: 15px;
  }

  .pd-xs-l-15 {
    padding-left: 15px;
  }

  .pd-xs-t-15-f {
    padding-top: 15px !important;
  }

  .pd-xs-r-15-f {
    padding-right: 15px !important;
  }

  .pd-xs-b-15-f {
    padding-bottom: 15px !important;
  }

  .pd-xs-l-15-f {
    padding-left: 15px !important;
  }

  .pd-xs-20 {
    padding: 20px;
  }

  .pd-xs-20-f {
    padding: 20px !important;
  }

  .pd-xs-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-xs-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-xs-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-xs-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-xs-t-20 {
    padding-top: 20px;
  }

  .pd-xs-r-20 {
    padding-right: 20px;
  }

  .pd-xs-b-20 {
    padding-bottom: 20px;
  }

  .pd-xs-l-20 {
    padding-left: 20px;
  }

  .pd-xs-t-20-f {
    padding-top: 20px !important;
  }

  .pd-xs-r-20-f {
    padding-right: 20px !important;
  }

  .pd-xs-b-20-f {
    padding-bottom: 20px !important;
  }

  .pd-xs-l-20-f {
    padding-left: 20px !important;
  }

  .pd-xs-25 {
    padding: 25px;
  }

  .pd-xs-25-f {
    padding: 25px !important;
  }

  .pd-xs-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-xs-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-xs-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-xs-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-xs-t-25 {
    padding-top: 25px;
  }

  .pd-xs-r-25 {
    padding-right: 25px;
  }

  .pd-xs-b-25 {
    padding-bottom: 25px;
  }

  .pd-xs-l-25 {
    padding-left: 25px;
  }

  .pd-xs-t-25-f {
    padding-top: 25px !important;
  }

  .pd-xs-r-25-f {
    padding-right: 25px !important;
  }

  .pd-xs-b-25-f {
    padding-bottom: 25px !important;
  }

  .pd-xs-l-25-f {
    padding-left: 25px !important;
  }

  .pd-xs-30 {
    padding: 30px;
  }

  .pd-xs-30-f {
    padding: 30px !important;
  }

  .pd-xs-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-xs-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-xs-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-xs-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-xs-t-30 {
    padding-top: 30px;
  }

  .pd-xs-r-30 {
    padding-right: 30px;
  }

  .pd-xs-b-30 {
    padding-bottom: 30px;
  }

  .pd-xs-l-30 {
    padding-left: 30px;
  }

  .pd-xs-t-30-f {
    padding-top: 30px !important;
  }

  .pd-xs-r-30-f {
    padding-right: 30px !important;
  }

  .pd-xs-b-30-f {
    padding-bottom: 30px !important;
  }

  .pd-xs-l-30-f {
    padding-left: 30px !important;
  }

  .pd-xs-35 {
    padding: 35px;
  }

  .pd-xs-35-f {
    padding: 35px !important;
  }

  .pd-xs-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-xs-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-xs-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-xs-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-xs-t-35 {
    padding-top: 35px;
  }

  .pd-xs-r-35 {
    padding-right: 35px;
  }

  .pd-xs-b-35 {
    padding-bottom: 35px;
  }

  .pd-xs-l-35 {
    padding-left: 35px;
  }

  .pd-xs-t-35-f {
    padding-top: 35px !important;
  }

  .pd-xs-r-35-f {
    padding-right: 35px !important;
  }

  .pd-xs-b-35-f {
    padding-bottom: 35px !important;
  }

  .pd-xs-l-35-f {
    padding-left: 35px !important;
  }

  .pd-xs-40 {
    padding: 40px;
  }

  .pd-xs-40-f {
    padding: 40px !important;
  }

  .pd-xs-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-xs-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-xs-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-xs-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-xs-t-40 {
    padding-top: 40px;
  }

  .pd-xs-r-40 {
    padding-right: 40px;
  }

  .pd-xs-b-40 {
    padding-bottom: 40px;
  }

  .pd-xs-l-40 {
    padding-left: 40px;
  }

  .pd-xs-t-40-f {
    padding-top: 40px !important;
  }

  .pd-xs-r-40-f {
    padding-right: 40px !important;
  }

  .pd-xs-b-40-f {
    padding-bottom: 40px !important;
  }

  .pd-xs-l-40-f {
    padding-left: 40px !important;
  }

  .pd-xs-45 {
    padding: 45px;
  }

  .pd-xs-45-f {
    padding: 45px !important;
  }

  .pd-xs-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-xs-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-xs-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-xs-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-xs-t-45 {
    padding-top: 45px;
  }

  .pd-xs-r-45 {
    padding-right: 45px;
  }

  .pd-xs-b-45 {
    padding-bottom: 45px;
  }

  .pd-xs-l-45 {
    padding-left: 45px;
  }

  .pd-xs-t-45-f {
    padding-top: 45px !important;
  }

  .pd-xs-r-45-f {
    padding-right: 45px !important;
  }

  .pd-xs-b-45-f {
    padding-bottom: 45px !important;
  }

  .pd-xs-l-45-f {
    padding-left: 45px !important;
  }

  .pd-xs-50 {
    padding: 50px;
  }

  .pd-xs-50-f {
    padding: 50px !important;
  }

  .pd-xs-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-xs-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-xs-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-xs-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-xs-t-50 {
    padding-top: 50px;
  }

  .pd-xs-r-50 {
    padding-right: 50px;
  }

  .pd-xs-b-50 {
    padding-bottom: 50px;
  }

  .pd-xs-l-50 {
    padding-left: 50px;
  }

  .pd-xs-t-50-f {
    padding-top: 50px !important;
  }

  .pd-xs-r-50-f {
    padding-right: 50px !important;
  }

  .pd-xs-b-50-f {
    padding-bottom: 50px !important;
  }

  .pd-xs-l-50-f {
    padding-left: 50px !important;
  }

  .pd-xs-55 {
    padding: 55px;
  }

  .pd-xs-55-f {
    padding: 55px !important;
  }

  .pd-xs-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-xs-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-xs-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-xs-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-xs-t-55 {
    padding-top: 55px;
  }

  .pd-xs-r-55 {
    padding-right: 55px;
  }

  .pd-xs-b-55 {
    padding-bottom: 55px;
  }

  .pd-xs-l-55 {
    padding-left: 55px;
  }

  .pd-xs-t-55-f {
    padding-top: 55px !important;
  }

  .pd-xs-r-55-f {
    padding-right: 55px !important;
  }

  .pd-xs-b-55-f {
    padding-bottom: 55px !important;
  }

  .pd-xs-l-55-f {
    padding-left: 55px !important;
  }

  .pd-xs-60 {
    padding: 60px;
  }

  .pd-xs-60-f {
    padding: 60px !important;
  }

  .pd-xs-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-xs-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-xs-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-xs-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-xs-t-60 {
    padding-top: 60px;
  }

  .pd-xs-r-60 {
    padding-right: 60px;
  }

  .pd-xs-b-60 {
    padding-bottom: 60px;
  }

  .pd-xs-l-60 {
    padding-left: 60px;
  }

  .pd-xs-t-60-f {
    padding-top: 60px !important;
  }

  .pd-xs-r-60-f {
    padding-right: 60px !important;
  }

  .pd-xs-b-60-f {
    padding-bottom: 60px !important;
  }

  .pd-xs-l-60-f {
    padding-left: 60px !important;
  }

  .pd-xs-65 {
    padding: 65px;
  }

  .pd-xs-65-f {
    padding: 65px !important;
  }

  .pd-xs-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-xs-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-xs-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-xs-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-xs-t-65 {
    padding-top: 65px;
  }

  .pd-xs-r-65 {
    padding-right: 65px;
  }

  .pd-xs-b-65 {
    padding-bottom: 65px;
  }

  .pd-xs-l-65 {
    padding-left: 65px;
  }

  .pd-xs-t-65-f {
    padding-top: 65px !important;
  }

  .pd-xs-r-65-f {
    padding-right: 65px !important;
  }

  .pd-xs-b-65-f {
    padding-bottom: 65px !important;
  }

  .pd-xs-l-65-f {
    padding-left: 65px !important;
  }

  .pd-xs-70 {
    padding: 70px;
  }

  .pd-xs-70-f {
    padding: 70px !important;
  }

  .pd-xs-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-xs-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-xs-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-xs-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-xs-t-70 {
    padding-top: 70px;
  }

  .pd-xs-r-70 {
    padding-right: 70px;
  }

  .pd-xs-b-70 {
    padding-bottom: 70px;
  }

  .pd-xs-l-70 {
    padding-left: 70px;
  }

  .pd-xs-t-70-f {
    padding-top: 70px !important;
  }

  .pd-xs-r-70-f {
    padding-right: 70px !important;
  }

  .pd-xs-b-70-f {
    padding-bottom: 70px !important;
  }

  .pd-xs-l-70-f {
    padding-left: 70px !important;
  }

  .pd-xs-75 {
    padding: 75px;
  }

  .pd-xs-75-f {
    padding: 75px !important;
  }

  .pd-xs-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-xs-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-xs-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-xs-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-xs-t-75 {
    padding-top: 75px;
  }

  .pd-xs-r-75 {
    padding-right: 75px;
  }

  .pd-xs-b-75 {
    padding-bottom: 75px;
  }

  .pd-xs-l-75 {
    padding-left: 75px;
  }

  .pd-xs-t-75-f {
    padding-top: 75px !important;
  }

  .pd-xs-r-75-f {
    padding-right: 75px !important;
  }

  .pd-xs-b-75-f {
    padding-bottom: 75px !important;
  }

  .pd-xs-l-75-f {
    padding-left: 75px !important;
  }

  .pd-xs-80 {
    padding: 80px;
  }

  .pd-xs-80-f {
    padding: 80px !important;
  }

  .pd-xs-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-xs-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-xs-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-xs-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-xs-t-80 {
    padding-top: 80px;
  }

  .pd-xs-r-80 {
    padding-right: 80px;
  }

  .pd-xs-b-80 {
    padding-bottom: 80px;
  }

  .pd-xs-l-80 {
    padding-left: 80px;
  }

  .pd-xs-t-80-f {
    padding-top: 80px !important;
  }

  .pd-xs-r-80-f {
    padding-right: 80px !important;
  }

  .pd-xs-b-80-f {
    padding-bottom: 80px !important;
  }

  .pd-xs-l-80-f {
    padding-left: 80px !important;
  }

  .pd-xs-85 {
    padding: 85px;
  }

  .pd-xs-85-f {
    padding: 85px !important;
  }

  .pd-xs-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-xs-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-xs-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-xs-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-xs-t-85 {
    padding-top: 85px;
  }

  .pd-xs-r-85 {
    padding-right: 85px;
  }

  .pd-xs-b-85 {
    padding-bottom: 85px;
  }

  .pd-xs-l-85 {
    padding-left: 85px;
  }

  .pd-xs-t-85-f {
    padding-top: 85px !important;
  }

  .pd-xs-r-85-f {
    padding-right: 85px !important;
  }

  .pd-xs-b-85-f {
    padding-bottom: 85px !important;
  }

  .pd-xs-l-85-f {
    padding-left: 85px !important;
  }

  .pd-xs-90 {
    padding: 90px;
  }

  .pd-xs-90-f {
    padding: 90px !important;
  }

  .pd-xs-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-xs-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-xs-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-xs-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-xs-t-90 {
    padding-top: 90px;
  }

  .pd-xs-r-90 {
    padding-right: 90px;
  }

  .pd-xs-b-90 {
    padding-bottom: 90px;
  }

  .pd-xs-l-90 {
    padding-left: 90px;
  }

  .pd-xs-t-90-f {
    padding-top: 90px !important;
  }

  .pd-xs-r-90-f {
    padding-right: 90px !important;
  }

  .pd-xs-b-90-f {
    padding-bottom: 90px !important;
  }

  .pd-xs-l-90-f {
    padding-left: 90px !important;
  }

  .pd-xs-95 {
    padding: 95px;
  }

  .pd-xs-95-f {
    padding: 95px !important;
  }

  .pd-xs-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-xs-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-xs-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-xs-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-xs-t-95 {
    padding-top: 95px;
  }

  .pd-xs-r-95 {
    padding-right: 95px;
  }

  .pd-xs-b-95 {
    padding-bottom: 95px;
  }

  .pd-xs-l-95 {
    padding-left: 95px;
  }

  .pd-xs-t-95-f {
    padding-top: 95px !important;
  }

  .pd-xs-r-95-f {
    padding-right: 95px !important;
  }

  .pd-xs-b-95-f {
    padding-bottom: 95px !important;
  }

  .pd-xs-l-95-f {
    padding-left: 95px !important;
  }

  .pd-xs-100 {
    padding: 100px;
  }

  .pd-xs-100-f {
    padding: 100px !important;
  }

  .pd-xs-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-xs-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-xs-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-xs-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-xs-t-100 {
    padding-top: 100px;
  }

  .pd-xs-r-100 {
    padding-right: 100px;
  }

  .pd-xs-b-100 {
    padding-bottom: 100px;
  }

  .pd-xs-l-100 {
    padding-left: 100px;
  }

  .pd-xs-t-100-f {
    padding-top: 100px !important;
  }

  .pd-xs-r-100-f {
    padding-right: 100px !important;
  }

  .pd-xs-b-100-f {
    padding-bottom: 100px !important;
  }

  .pd-xs-l-100-f {
    padding-left: 100px !important;
  }

  .pd-xs-110 {
    padding: 110px;
  }

  .pd-xs-110-f {
    padding: 110px !important;
  }

  .pd-xs-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-xs-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-xs-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-xs-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-xs-t-110 {
    padding-top: 110px;
  }

  .pd-xs-r-110 {
    padding-right: 110px;
  }

  .pd-xs-b-110 {
    padding-bottom: 110px;
  }

  .pd-xs-l-110 {
    padding-left: 110px;
  }

  .pd-xs-t-110-f {
    padding-top: 110px !important;
  }

  .pd-xs-r-110-f {
    padding-right: 110px !important;
  }

  .pd-xs-b-110-f {
    padding-bottom: 110px !important;
  }

  .pd-xs-l-110-f {
    padding-left: 110px !important;
  }

  .pd-xs-120 {
    padding: 120px;
  }

  .pd-xs-120-f {
    padding: 120px !important;
  }

  .pd-xs-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-xs-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-xs-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-xs-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-xs-t-120 {
    padding-top: 120px;
  }

  .pd-xs-r-120 {
    padding-right: 120px;
  }

  .pd-xs-b-120 {
    padding-bottom: 120px;
  }

  .pd-xs-l-120 {
    padding-left: 120px;
  }

  .pd-xs-t-120-f {
    padding-top: 120px !important;
  }

  .pd-xs-r-120-f {
    padding-right: 120px !important;
  }

  .pd-xs-b-120-f {
    padding-bottom: 120px !important;
  }

  .pd-xs-l-120-f {
    padding-left: 120px !important;
  }

  .pd-xs-130 {
    padding: 130px;
  }

  .pd-xs-130-f {
    padding: 130px !important;
  }

  .pd-xs-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-xs-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-xs-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-xs-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-xs-t-130 {
    padding-top: 130px;
  }

  .pd-xs-r-130 {
    padding-right: 130px;
  }

  .pd-xs-b-130 {
    padding-bottom: 130px;
  }

  .pd-xs-l-130 {
    padding-left: 130px;
  }

  .pd-xs-t-130-f {
    padding-top: 130px !important;
  }

  .pd-xs-r-130-f {
    padding-right: 130px !important;
  }

  .pd-xs-b-130-f {
    padding-bottom: 130px !important;
  }

  .pd-xs-l-130-f {
    padding-left: 130px !important;
  }

  .pd-xs-140 {
    padding: 140px;
  }

  .pd-xs-140-f {
    padding: 140px !important;
  }

  .pd-xs-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-xs-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-xs-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-xs-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-xs-t-140 {
    padding-top: 140px;
  }

  .pd-xs-r-140 {
    padding-right: 140px;
  }

  .pd-xs-b-140 {
    padding-bottom: 140px;
  }

  .pd-xs-l-140 {
    padding-left: 140px;
  }

  .pd-xs-t-140-f {
    padding-top: 140px !important;
  }

  .pd-xs-r-140-f {
    padding-right: 140px !important;
  }

  .pd-xs-b-140-f {
    padding-bottom: 140px !important;
  }

  .pd-xs-l-140-f {
    padding-left: 140px !important;
  }

  .pd-xs-150 {
    padding: 150px;
  }

  .pd-xs-150-f {
    padding: 150px !important;
  }

  .pd-xs-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-xs-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-xs-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-xs-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-xs-t-150 {
    padding-top: 150px;
  }

  .pd-xs-r-150 {
    padding-right: 150px;
  }

  .pd-xs-b-150 {
    padding-bottom: 150px;
  }

  .pd-xs-l-150 {
    padding-left: 150px;
  }

  .pd-xs-t-150-f {
    padding-top: 150px !important;
  }

  .pd-xs-r-150-f {
    padding-right: 150px !important;
  }

  .pd-xs-b-150-f {
    padding-bottom: 150px !important;
  }

  .pd-xs-l-150-f {
    padding-left: 150px !important;
  }

  .pd-xs-160 {
    padding: 160px;
  }

  .pd-xs-160-f {
    padding: 160px !important;
  }

  .pd-xs-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-xs-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-xs-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-xs-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-xs-t-160 {
    padding-top: 160px;
  }

  .pd-xs-r-160 {
    padding-right: 160px;
  }

  .pd-xs-b-160 {
    padding-bottom: 160px;
  }

  .pd-xs-l-160 {
    padding-left: 160px;
  }

  .pd-xs-t-160-f {
    padding-top: 160px !important;
  }

  .pd-xs-r-160-f {
    padding-right: 160px !important;
  }

  .pd-xs-b-160-f {
    padding-bottom: 160px !important;
  }

  .pd-xs-l-160-f {
    padding-left: 160px !important;
  }

  .pd-xs-170 {
    padding: 170px;
  }

  .pd-xs-170-f {
    padding: 170px !important;
  }

  .pd-xs-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-xs-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-xs-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-xs-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-xs-t-170 {
    padding-top: 170px;
  }

  .pd-xs-r-170 {
    padding-right: 170px;
  }

  .pd-xs-b-170 {
    padding-bottom: 170px;
  }

  .pd-xs-l-170 {
    padding-left: 170px;
  }

  .pd-xs-t-170-f {
    padding-top: 170px !important;
  }

  .pd-xs-r-170-f {
    padding-right: 170px !important;
  }

  .pd-xs-b-170-f {
    padding-bottom: 170px !important;
  }

  .pd-xs-l-170-f {
    padding-left: 170px !important;
  }

  .pd-xs-180 {
    padding: 180px;
  }

  .pd-xs-180-f {
    padding: 180px !important;
  }

  .pd-xs-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-xs-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-xs-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-xs-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-xs-t-180 {
    padding-top: 180px;
  }

  .pd-xs-r-180 {
    padding-right: 180px;
  }

  .pd-xs-b-180 {
    padding-bottom: 180px;
  }

  .pd-xs-l-180 {
    padding-left: 180px;
  }

  .pd-xs-t-180-f {
    padding-top: 180px !important;
  }

  .pd-xs-r-180-f {
    padding-right: 180px !important;
  }

  .pd-xs-b-180-f {
    padding-bottom: 180px !important;
  }

  .pd-xs-l-180-f {
    padding-left: 180px !important;
  }

  .pd-xs-190 {
    padding: 190px;
  }

  .pd-xs-190-f {
    padding: 190px !important;
  }

  .pd-xs-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-xs-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-xs-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-xs-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-xs-t-190 {
    padding-top: 190px;
  }

  .pd-xs-r-190 {
    padding-right: 190px;
  }

  .pd-xs-b-190 {
    padding-bottom: 190px;
  }

  .pd-xs-l-190 {
    padding-left: 190px;
  }

  .pd-xs-t-190-f {
    padding-top: 190px !important;
  }

  .pd-xs-r-190-f {
    padding-right: 190px !important;
  }

  .pd-xs-b-190-f {
    padding-bottom: 190px !important;
  }

  .pd-xs-l-190-f {
    padding-left: 190px !important;
  }

  .pd-xs-200 {
    padding: 200px;
  }

  .pd-xs-200-f {
    padding: 200px !important;
  }

  .pd-xs-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-xs-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-xs-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-xs-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-xs-t-200 {
    padding-top: 200px;
  }

  .pd-xs-r-200 {
    padding-right: 200px;
  }

  .pd-xs-b-200 {
    padding-bottom: 200px;
  }

  .pd-xs-l-200 {
    padding-left: 200px;
  }

  .pd-xs-t-200-f {
    padding-top: 200px !important;
  }

  .pd-xs-r-200-f {
    padding-right: 200px !important;
  }

  .pd-xs-b-200-f {
    padding-bottom: 200px !important;
  }

  .pd-xs-l-200-f {
    padding-left: 200px !important;
  }
}
@media (min-width: 576px) {
  .pd-sm-0 {
    padding: 0px;
  }

  .pd-sm-0-f {
    padding: 0px !important;
  }

  .pd-sm-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-sm-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-sm-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-sm-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-sm-t-0 {
    padding-top: 0px;
  }

  .pd-sm-r-0 {
    padding-right: 0px;
  }

  .pd-sm-b-0 {
    padding-bottom: 0px;
  }

  .pd-sm-l-0 {
    padding-left: 0px;
  }

  .pd-sm-t-0-f {
    padding-top: 0px !important;
  }

  .pd-sm-r-0-f {
    padding-right: 0px !important;
  }

  .pd-sm-b-0-f {
    padding-bottom: 0px !important;
  }

  .pd-sm-l-0-f {
    padding-left: 0px !important;
  }

  .pd-sm-1 {
    padding: 1px;
  }

  .pd-sm-1-f {
    padding: 1px !important;
  }

  .pd-sm-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-sm-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-sm-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-sm-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-sm-t-1 {
    padding-top: 1px;
  }

  .pd-sm-r-1 {
    padding-right: 1px;
  }

  .pd-sm-b-1 {
    padding-bottom: 1px;
  }

  .pd-sm-l-1 {
    padding-left: 1px;
  }

  .pd-sm-t-1-f {
    padding-top: 1px !important;
  }

  .pd-sm-r-1-f {
    padding-right: 1px !important;
  }

  .pd-sm-b-1-f {
    padding-bottom: 1px !important;
  }

  .pd-sm-l-1-f {
    padding-left: 1px !important;
  }

  .pd-sm-2 {
    padding: 2px;
  }

  .pd-sm-2-f {
    padding: 2px !important;
  }

  .pd-sm-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-sm-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-sm-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-sm-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-sm-t-2 {
    padding-top: 2px;
  }

  .pd-sm-r-2 {
    padding-right: 2px;
  }

  .pd-sm-b-2 {
    padding-bottom: 2px;
  }

  .pd-sm-l-2 {
    padding-left: 2px;
  }

  .pd-sm-t-2-f {
    padding-top: 2px !important;
  }

  .pd-sm-r-2-f {
    padding-right: 2px !important;
  }

  .pd-sm-b-2-f {
    padding-bottom: 2px !important;
  }

  .pd-sm-l-2-f {
    padding-left: 2px !important;
  }

  .pd-sm-3 {
    padding: 3px;
  }

  .pd-sm-3-f {
    padding: 3px !important;
  }

  .pd-sm-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-sm-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-sm-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-sm-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-sm-t-3 {
    padding-top: 3px;
  }

  .pd-sm-r-3 {
    padding-right: 3px;
  }

  .pd-sm-b-3 {
    padding-bottom: 3px;
  }

  .pd-sm-l-3 {
    padding-left: 3px;
  }

  .pd-sm-t-3-f {
    padding-top: 3px !important;
  }

  .pd-sm-r-3-f {
    padding-right: 3px !important;
  }

  .pd-sm-b-3-f {
    padding-bottom: 3px !important;
  }

  .pd-sm-l-3-f {
    padding-left: 3px !important;
  }

  .pd-sm-4 {
    padding: 4px;
  }

  .pd-sm-4-f {
    padding: 4px !important;
  }

  .pd-sm-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-sm-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-sm-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-sm-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-sm-t-4 {
    padding-top: 4px;
  }

  .pd-sm-r-4 {
    padding-right: 4px;
  }

  .pd-sm-b-4 {
    padding-bottom: 4px;
  }

  .pd-sm-l-4 {
    padding-left: 4px;
  }

  .pd-sm-t-4-f {
    padding-top: 4px !important;
  }

  .pd-sm-r-4-f {
    padding-right: 4px !important;
  }

  .pd-sm-b-4-f {
    padding-bottom: 4px !important;
  }

  .pd-sm-l-4-f {
    padding-left: 4px !important;
  }

  .pd-sm-5 {
    padding: 5px;
  }

  .pd-sm-5-f {
    padding: 5px !important;
  }

  .pd-sm-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-sm-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-sm-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-sm-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-sm-t-5 {
    padding-top: 5px;
  }

  .pd-sm-r-5 {
    padding-right: 5px;
  }

  .pd-sm-b-5 {
    padding-bottom: 5px;
  }

  .pd-sm-l-5 {
    padding-left: 5px;
  }

  .pd-sm-t-5-f {
    padding-top: 5px !important;
  }

  .pd-sm-r-5-f {
    padding-right: 5px !important;
  }

  .pd-sm-b-5-f {
    padding-bottom: 5px !important;
  }

  .pd-sm-l-5-f {
    padding-left: 5px !important;
  }

  .pd-sm-6 {
    padding: 6px;
  }

  .pd-sm-6-f {
    padding: 6px !important;
  }

  .pd-sm-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-sm-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-sm-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-sm-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-sm-t-6 {
    padding-top: 6px;
  }

  .pd-sm-r-6 {
    padding-right: 6px;
  }

  .pd-sm-b-6 {
    padding-bottom: 6px;
  }

  .pd-sm-l-6 {
    padding-left: 6px;
  }

  .pd-sm-t-6-f {
    padding-top: 6px !important;
  }

  .pd-sm-r-6-f {
    padding-right: 6px !important;
  }

  .pd-sm-b-6-f {
    padding-bottom: 6px !important;
  }

  .pd-sm-l-6-f {
    padding-left: 6px !important;
  }

  .pd-sm-7 {
    padding: 7px;
  }

  .pd-sm-7-f {
    padding: 7px !important;
  }

  .pd-sm-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-sm-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-sm-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-sm-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-sm-t-7 {
    padding-top: 7px;
  }

  .pd-sm-r-7 {
    padding-right: 7px;
  }

  .pd-sm-b-7 {
    padding-bottom: 7px;
  }

  .pd-sm-l-7 {
    padding-left: 7px;
  }

  .pd-sm-t-7-f {
    padding-top: 7px !important;
  }

  .pd-sm-r-7-f {
    padding-right: 7px !important;
  }

  .pd-sm-b-7-f {
    padding-bottom: 7px !important;
  }

  .pd-sm-l-7-f {
    padding-left: 7px !important;
  }

  .pd-sm-8 {
    padding: 8px;
  }

  .pd-sm-8-f {
    padding: 8px !important;
  }

  .pd-sm-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-sm-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-sm-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-sm-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-sm-t-8 {
    padding-top: 8px;
  }

  .pd-sm-r-8 {
    padding-right: 8px;
  }

  .pd-sm-b-8 {
    padding-bottom: 8px;
  }

  .pd-sm-l-8 {
    padding-left: 8px;
  }

  .pd-sm-t-8-f {
    padding-top: 8px !important;
  }

  .pd-sm-r-8-f {
    padding-right: 8px !important;
  }

  .pd-sm-b-8-f {
    padding-bottom: 8px !important;
  }

  .pd-sm-l-8-f {
    padding-left: 8px !important;
  }

  .pd-sm-9 {
    padding: 9px;
  }

  .pd-sm-9-f {
    padding: 9px !important;
  }

  .pd-sm-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-sm-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-sm-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-sm-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-sm-t-9 {
    padding-top: 9px;
  }

  .pd-sm-r-9 {
    padding-right: 9px;
  }

  .pd-sm-b-9 {
    padding-bottom: 9px;
  }

  .pd-sm-l-9 {
    padding-left: 9px;
  }

  .pd-sm-t-9-f {
    padding-top: 9px !important;
  }

  .pd-sm-r-9-f {
    padding-right: 9px !important;
  }

  .pd-sm-b-9-f {
    padding-bottom: 9px !important;
  }

  .pd-sm-l-9-f {
    padding-left: 9px !important;
  }

  .pd-sm-10 {
    padding: 10px;
  }

  .pd-sm-10-f {
    padding: 10px !important;
  }

  .pd-sm-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-sm-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-sm-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-sm-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-sm-t-10 {
    padding-top: 10px;
  }

  .pd-sm-r-10 {
    padding-right: 10px;
  }

  .pd-sm-b-10 {
    padding-bottom: 10px;
  }

  .pd-sm-l-10 {
    padding-left: 10px;
  }

  .pd-sm-t-10-f {
    padding-top: 10px !important;
  }

  .pd-sm-r-10-f {
    padding-right: 10px !important;
  }

  .pd-sm-b-10-f {
    padding-bottom: 10px !important;
  }

  .pd-sm-l-10-f {
    padding-left: 10px !important;
  }

  .pd-sm-15 {
    padding: 15px;
  }

  .pd-sm-15-f {
    padding: 15px !important;
  }

  .pd-sm-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-sm-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-sm-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-sm-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-sm-t-15 {
    padding-top: 15px;
  }

  .pd-sm-r-15 {
    padding-right: 15px;
  }

  .pd-sm-b-15 {
    padding-bottom: 15px;
  }

  .pd-sm-l-15 {
    padding-left: 15px;
  }

  .pd-sm-t-15-f {
    padding-top: 15px !important;
  }

  .pd-sm-r-15-f {
    padding-right: 15px !important;
  }

  .pd-sm-b-15-f {
    padding-bottom: 15px !important;
  }

  .pd-sm-l-15-f {
    padding-left: 15px !important;
  }

  .pd-sm-20 {
    padding: 20px;
  }

  .pd-sm-20-f {
    padding: 20px !important;
  }

  .pd-sm-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-sm-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-sm-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-sm-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-sm-t-20 {
    padding-top: 20px;
  }

  .pd-sm-r-20 {
    padding-right: 20px;
  }

  .pd-sm-b-20 {
    padding-bottom: 20px;
  }

  .pd-sm-l-20 {
    padding-left: 20px;
  }

  .pd-sm-t-20-f {
    padding-top: 20px !important;
  }

  .pd-sm-r-20-f {
    padding-right: 20px !important;
  }

  .pd-sm-b-20-f {
    padding-bottom: 20px !important;
  }

  .pd-sm-l-20-f {
    padding-left: 20px !important;
  }

  .pd-sm-25 {
    padding: 25px;
  }

  .pd-sm-25-f {
    padding: 25px !important;
  }

  .pd-sm-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-sm-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-sm-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-sm-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-sm-t-25 {
    padding-top: 25px;
  }

  .pd-sm-r-25 {
    padding-right: 25px;
  }

  .pd-sm-b-25 {
    padding-bottom: 25px;
  }

  .pd-sm-l-25 {
    padding-left: 25px;
  }

  .pd-sm-t-25-f {
    padding-top: 25px !important;
  }

  .pd-sm-r-25-f {
    padding-right: 25px !important;
  }

  .pd-sm-b-25-f {
    padding-bottom: 25px !important;
  }

  .pd-sm-l-25-f {
    padding-left: 25px !important;
  }

  .pd-sm-30 {
    padding: 30px;
  }

  .pd-sm-30-f {
    padding: 30px !important;
  }

  .pd-sm-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-sm-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-sm-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-sm-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-sm-t-30 {
    padding-top: 30px;
  }

  .pd-sm-r-30 {
    padding-right: 30px;
  }

  .pd-sm-b-30 {
    padding-bottom: 30px;
  }

  .pd-sm-l-30 {
    padding-left: 30px;
  }

  .pd-sm-t-30-f {
    padding-top: 30px !important;
  }

  .pd-sm-r-30-f {
    padding-right: 30px !important;
  }

  .pd-sm-b-30-f {
    padding-bottom: 30px !important;
  }

  .pd-sm-l-30-f {
    padding-left: 30px !important;
  }

  .pd-sm-35 {
    padding: 35px;
  }

  .pd-sm-35-f {
    padding: 35px !important;
  }

  .pd-sm-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-sm-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-sm-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-sm-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-sm-t-35 {
    padding-top: 35px;
  }

  .pd-sm-r-35 {
    padding-right: 35px;
  }

  .pd-sm-b-35 {
    padding-bottom: 35px;
  }

  .pd-sm-l-35 {
    padding-left: 35px;
  }

  .pd-sm-t-35-f {
    padding-top: 35px !important;
  }

  .pd-sm-r-35-f {
    padding-right: 35px !important;
  }

  .pd-sm-b-35-f {
    padding-bottom: 35px !important;
  }

  .pd-sm-l-35-f {
    padding-left: 35px !important;
  }

  .pd-sm-40 {
    padding: 40px;
  }

  .pd-sm-40-f {
    padding: 40px !important;
  }

  .pd-sm-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-sm-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-sm-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-sm-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-sm-t-40 {
    padding-top: 40px;
  }

  .pd-sm-r-40 {
    padding-right: 40px;
  }

  .pd-sm-b-40 {
    padding-bottom: 40px;
  }

  .pd-sm-l-40 {
    padding-left: 40px;
  }

  .pd-sm-t-40-f {
    padding-top: 40px !important;
  }

  .pd-sm-r-40-f {
    padding-right: 40px !important;
  }

  .pd-sm-b-40-f {
    padding-bottom: 40px !important;
  }

  .pd-sm-l-40-f {
    padding-left: 40px !important;
  }

  .pd-sm-45 {
    padding: 45px;
  }

  .pd-sm-45-f {
    padding: 45px !important;
  }

  .pd-sm-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-sm-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-sm-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-sm-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-sm-t-45 {
    padding-top: 45px;
  }

  .pd-sm-r-45 {
    padding-right: 45px;
  }

  .pd-sm-b-45 {
    padding-bottom: 45px;
  }

  .pd-sm-l-45 {
    padding-left: 45px;
  }

  .pd-sm-t-45-f {
    padding-top: 45px !important;
  }

  .pd-sm-r-45-f {
    padding-right: 45px !important;
  }

  .pd-sm-b-45-f {
    padding-bottom: 45px !important;
  }

  .pd-sm-l-45-f {
    padding-left: 45px !important;
  }

  .pd-sm-50 {
    padding: 50px;
  }

  .pd-sm-50-f {
    padding: 50px !important;
  }

  .pd-sm-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-sm-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-sm-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-sm-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-sm-t-50 {
    padding-top: 50px;
  }

  .pd-sm-r-50 {
    padding-right: 50px;
  }

  .pd-sm-b-50 {
    padding-bottom: 50px;
  }

  .pd-sm-l-50 {
    padding-left: 50px;
  }

  .pd-sm-t-50-f {
    padding-top: 50px !important;
  }

  .pd-sm-r-50-f {
    padding-right: 50px !important;
  }

  .pd-sm-b-50-f {
    padding-bottom: 50px !important;
  }

  .pd-sm-l-50-f {
    padding-left: 50px !important;
  }

  .pd-sm-55 {
    padding: 55px;
  }

  .pd-sm-55-f {
    padding: 55px !important;
  }

  .pd-sm-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-sm-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-sm-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-sm-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-sm-t-55 {
    padding-top: 55px;
  }

  .pd-sm-r-55 {
    padding-right: 55px;
  }

  .pd-sm-b-55 {
    padding-bottom: 55px;
  }

  .pd-sm-l-55 {
    padding-left: 55px;
  }

  .pd-sm-t-55-f {
    padding-top: 55px !important;
  }

  .pd-sm-r-55-f {
    padding-right: 55px !important;
  }

  .pd-sm-b-55-f {
    padding-bottom: 55px !important;
  }

  .pd-sm-l-55-f {
    padding-left: 55px !important;
  }

  .pd-sm-60 {
    padding: 60px;
  }

  .pd-sm-60-f {
    padding: 60px !important;
  }

  .pd-sm-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-sm-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-sm-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-sm-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-sm-t-60 {
    padding-top: 60px;
  }

  .pd-sm-r-60 {
    padding-right: 60px;
  }

  .pd-sm-b-60 {
    padding-bottom: 60px;
  }

  .pd-sm-l-60 {
    padding-left: 60px;
  }

  .pd-sm-t-60-f {
    padding-top: 60px !important;
  }

  .pd-sm-r-60-f {
    padding-right: 60px !important;
  }

  .pd-sm-b-60-f {
    padding-bottom: 60px !important;
  }

  .pd-sm-l-60-f {
    padding-left: 60px !important;
  }

  .pd-sm-65 {
    padding: 65px;
  }

  .pd-sm-65-f {
    padding: 65px !important;
  }

  .pd-sm-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-sm-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-sm-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-sm-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-sm-t-65 {
    padding-top: 65px;
  }

  .pd-sm-r-65 {
    padding-right: 65px;
  }

  .pd-sm-b-65 {
    padding-bottom: 65px;
  }

  .pd-sm-l-65 {
    padding-left: 65px;
  }

  .pd-sm-t-65-f {
    padding-top: 65px !important;
  }

  .pd-sm-r-65-f {
    padding-right: 65px !important;
  }

  .pd-sm-b-65-f {
    padding-bottom: 65px !important;
  }

  .pd-sm-l-65-f {
    padding-left: 65px !important;
  }

  .pd-sm-70 {
    padding: 70px;
  }

  .pd-sm-70-f {
    padding: 70px !important;
  }

  .pd-sm-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-sm-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-sm-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-sm-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-sm-t-70 {
    padding-top: 70px;
  }

  .pd-sm-r-70 {
    padding-right: 70px;
  }

  .pd-sm-b-70 {
    padding-bottom: 70px;
  }

  .pd-sm-l-70 {
    padding-left: 70px;
  }

  .pd-sm-t-70-f {
    padding-top: 70px !important;
  }

  .pd-sm-r-70-f {
    padding-right: 70px !important;
  }

  .pd-sm-b-70-f {
    padding-bottom: 70px !important;
  }

  .pd-sm-l-70-f {
    padding-left: 70px !important;
  }

  .pd-sm-75 {
    padding: 75px;
  }

  .pd-sm-75-f {
    padding: 75px !important;
  }

  .pd-sm-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-sm-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-sm-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-sm-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-sm-t-75 {
    padding-top: 75px;
  }

  .pd-sm-r-75 {
    padding-right: 75px;
  }

  .pd-sm-b-75 {
    padding-bottom: 75px;
  }

  .pd-sm-l-75 {
    padding-left: 75px;
  }

  .pd-sm-t-75-f {
    padding-top: 75px !important;
  }

  .pd-sm-r-75-f {
    padding-right: 75px !important;
  }

  .pd-sm-b-75-f {
    padding-bottom: 75px !important;
  }

  .pd-sm-l-75-f {
    padding-left: 75px !important;
  }

  .pd-sm-80 {
    padding: 80px;
  }

  .pd-sm-80-f {
    padding: 80px !important;
  }

  .pd-sm-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-sm-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-sm-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-sm-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-sm-t-80 {
    padding-top: 80px;
  }

  .pd-sm-r-80 {
    padding-right: 80px;
  }

  .pd-sm-b-80 {
    padding-bottom: 80px;
  }

  .pd-sm-l-80 {
    padding-left: 80px;
  }

  .pd-sm-t-80-f {
    padding-top: 80px !important;
  }

  .pd-sm-r-80-f {
    padding-right: 80px !important;
  }

  .pd-sm-b-80-f {
    padding-bottom: 80px !important;
  }

  .pd-sm-l-80-f {
    padding-left: 80px !important;
  }

  .pd-sm-85 {
    padding: 85px;
  }

  .pd-sm-85-f {
    padding: 85px !important;
  }

  .pd-sm-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-sm-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-sm-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-sm-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-sm-t-85 {
    padding-top: 85px;
  }

  .pd-sm-r-85 {
    padding-right: 85px;
  }

  .pd-sm-b-85 {
    padding-bottom: 85px;
  }

  .pd-sm-l-85 {
    padding-left: 85px;
  }

  .pd-sm-t-85-f {
    padding-top: 85px !important;
  }

  .pd-sm-r-85-f {
    padding-right: 85px !important;
  }

  .pd-sm-b-85-f {
    padding-bottom: 85px !important;
  }

  .pd-sm-l-85-f {
    padding-left: 85px !important;
  }

  .pd-sm-90 {
    padding: 90px;
  }

  .pd-sm-90-f {
    padding: 90px !important;
  }

  .pd-sm-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-sm-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-sm-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-sm-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-sm-t-90 {
    padding-top: 90px;
  }

  .pd-sm-r-90 {
    padding-right: 90px;
  }

  .pd-sm-b-90 {
    padding-bottom: 90px;
  }

  .pd-sm-l-90 {
    padding-left: 90px;
  }

  .pd-sm-t-90-f {
    padding-top: 90px !important;
  }

  .pd-sm-r-90-f {
    padding-right: 90px !important;
  }

  .pd-sm-b-90-f {
    padding-bottom: 90px !important;
  }

  .pd-sm-l-90-f {
    padding-left: 90px !important;
  }

  .pd-sm-95 {
    padding: 95px;
  }

  .pd-sm-95-f {
    padding: 95px !important;
  }

  .pd-sm-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-sm-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-sm-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-sm-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-sm-t-95 {
    padding-top: 95px;
  }

  .pd-sm-r-95 {
    padding-right: 95px;
  }

  .pd-sm-b-95 {
    padding-bottom: 95px;
  }

  .pd-sm-l-95 {
    padding-left: 95px;
  }

  .pd-sm-t-95-f {
    padding-top: 95px !important;
  }

  .pd-sm-r-95-f {
    padding-right: 95px !important;
  }

  .pd-sm-b-95-f {
    padding-bottom: 95px !important;
  }

  .pd-sm-l-95-f {
    padding-left: 95px !important;
  }

  .pd-sm-100 {
    padding: 100px;
  }

  .pd-sm-100-f {
    padding: 100px !important;
  }

  .pd-sm-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-sm-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-sm-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-sm-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-sm-t-100 {
    padding-top: 100px;
  }

  .pd-sm-r-100 {
    padding-right: 100px;
  }

  .pd-sm-b-100 {
    padding-bottom: 100px;
  }

  .pd-sm-l-100 {
    padding-left: 100px;
  }

  .pd-sm-t-100-f {
    padding-top: 100px !important;
  }

  .pd-sm-r-100-f {
    padding-right: 100px !important;
  }

  .pd-sm-b-100-f {
    padding-bottom: 100px !important;
  }

  .pd-sm-l-100-f {
    padding-left: 100px !important;
  }

  .pd-sm-110 {
    padding: 110px;
  }

  .pd-sm-110-f {
    padding: 110px !important;
  }

  .pd-sm-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-sm-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-sm-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-sm-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-sm-t-110 {
    padding-top: 110px;
  }

  .pd-sm-r-110 {
    padding-right: 110px;
  }

  .pd-sm-b-110 {
    padding-bottom: 110px;
  }

  .pd-sm-l-110 {
    padding-left: 110px;
  }

  .pd-sm-t-110-f {
    padding-top: 110px !important;
  }

  .pd-sm-r-110-f {
    padding-right: 110px !important;
  }

  .pd-sm-b-110-f {
    padding-bottom: 110px !important;
  }

  .pd-sm-l-110-f {
    padding-left: 110px !important;
  }

  .pd-sm-120 {
    padding: 120px;
  }

  .pd-sm-120-f {
    padding: 120px !important;
  }

  .pd-sm-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-sm-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-sm-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-sm-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-sm-t-120 {
    padding-top: 120px;
  }

  .pd-sm-r-120 {
    padding-right: 120px;
  }

  .pd-sm-b-120 {
    padding-bottom: 120px;
  }

  .pd-sm-l-120 {
    padding-left: 120px;
  }

  .pd-sm-t-120-f {
    padding-top: 120px !important;
  }

  .pd-sm-r-120-f {
    padding-right: 120px !important;
  }

  .pd-sm-b-120-f {
    padding-bottom: 120px !important;
  }

  .pd-sm-l-120-f {
    padding-left: 120px !important;
  }

  .pd-sm-130 {
    padding: 130px;
  }

  .pd-sm-130-f {
    padding: 130px !important;
  }

  .pd-sm-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-sm-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-sm-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-sm-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-sm-t-130 {
    padding-top: 130px;
  }

  .pd-sm-r-130 {
    padding-right: 130px;
  }

  .pd-sm-b-130 {
    padding-bottom: 130px;
  }

  .pd-sm-l-130 {
    padding-left: 130px;
  }

  .pd-sm-t-130-f {
    padding-top: 130px !important;
  }

  .pd-sm-r-130-f {
    padding-right: 130px !important;
  }

  .pd-sm-b-130-f {
    padding-bottom: 130px !important;
  }

  .pd-sm-l-130-f {
    padding-left: 130px !important;
  }

  .pd-sm-140 {
    padding: 140px;
  }

  .pd-sm-140-f {
    padding: 140px !important;
  }

  .pd-sm-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-sm-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-sm-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-sm-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-sm-t-140 {
    padding-top: 140px;
  }

  .pd-sm-r-140 {
    padding-right: 140px;
  }

  .pd-sm-b-140 {
    padding-bottom: 140px;
  }

  .pd-sm-l-140 {
    padding-left: 140px;
  }

  .pd-sm-t-140-f {
    padding-top: 140px !important;
  }

  .pd-sm-r-140-f {
    padding-right: 140px !important;
  }

  .pd-sm-b-140-f {
    padding-bottom: 140px !important;
  }

  .pd-sm-l-140-f {
    padding-left: 140px !important;
  }

  .pd-sm-150 {
    padding: 150px;
  }

  .pd-sm-150-f {
    padding: 150px !important;
  }

  .pd-sm-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-sm-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-sm-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-sm-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-sm-t-150 {
    padding-top: 150px;
  }

  .pd-sm-r-150 {
    padding-right: 150px;
  }

  .pd-sm-b-150 {
    padding-bottom: 150px;
  }

  .pd-sm-l-150 {
    padding-left: 150px;
  }

  .pd-sm-t-150-f {
    padding-top: 150px !important;
  }

  .pd-sm-r-150-f {
    padding-right: 150px !important;
  }

  .pd-sm-b-150-f {
    padding-bottom: 150px !important;
  }

  .pd-sm-l-150-f {
    padding-left: 150px !important;
  }

  .pd-sm-160 {
    padding: 160px;
  }

  .pd-sm-160-f {
    padding: 160px !important;
  }

  .pd-sm-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-sm-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-sm-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-sm-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-sm-t-160 {
    padding-top: 160px;
  }

  .pd-sm-r-160 {
    padding-right: 160px;
  }

  .pd-sm-b-160 {
    padding-bottom: 160px;
  }

  .pd-sm-l-160 {
    padding-left: 160px;
  }

  .pd-sm-t-160-f {
    padding-top: 160px !important;
  }

  .pd-sm-r-160-f {
    padding-right: 160px !important;
  }

  .pd-sm-b-160-f {
    padding-bottom: 160px !important;
  }

  .pd-sm-l-160-f {
    padding-left: 160px !important;
  }

  .pd-sm-170 {
    padding: 170px;
  }

  .pd-sm-170-f {
    padding: 170px !important;
  }

  .pd-sm-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-sm-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-sm-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-sm-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-sm-t-170 {
    padding-top: 170px;
  }

  .pd-sm-r-170 {
    padding-right: 170px;
  }

  .pd-sm-b-170 {
    padding-bottom: 170px;
  }

  .pd-sm-l-170 {
    padding-left: 170px;
  }

  .pd-sm-t-170-f {
    padding-top: 170px !important;
  }

  .pd-sm-r-170-f {
    padding-right: 170px !important;
  }

  .pd-sm-b-170-f {
    padding-bottom: 170px !important;
  }

  .pd-sm-l-170-f {
    padding-left: 170px !important;
  }

  .pd-sm-180 {
    padding: 180px;
  }

  .pd-sm-180-f {
    padding: 180px !important;
  }

  .pd-sm-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-sm-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-sm-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-sm-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-sm-t-180 {
    padding-top: 180px;
  }

  .pd-sm-r-180 {
    padding-right: 180px;
  }

  .pd-sm-b-180 {
    padding-bottom: 180px;
  }

  .pd-sm-l-180 {
    padding-left: 180px;
  }

  .pd-sm-t-180-f {
    padding-top: 180px !important;
  }

  .pd-sm-r-180-f {
    padding-right: 180px !important;
  }

  .pd-sm-b-180-f {
    padding-bottom: 180px !important;
  }

  .pd-sm-l-180-f {
    padding-left: 180px !important;
  }

  .pd-sm-190 {
    padding: 190px;
  }

  .pd-sm-190-f {
    padding: 190px !important;
  }

  .pd-sm-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-sm-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-sm-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-sm-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-sm-t-190 {
    padding-top: 190px;
  }

  .pd-sm-r-190 {
    padding-right: 190px;
  }

  .pd-sm-b-190 {
    padding-bottom: 190px;
  }

  .pd-sm-l-190 {
    padding-left: 190px;
  }

  .pd-sm-t-190-f {
    padding-top: 190px !important;
  }

  .pd-sm-r-190-f {
    padding-right: 190px !important;
  }

  .pd-sm-b-190-f {
    padding-bottom: 190px !important;
  }

  .pd-sm-l-190-f {
    padding-left: 190px !important;
  }

  .pd-sm-200 {
    padding: 200px;
  }

  .pd-sm-200-f {
    padding: 200px !important;
  }

  .pd-sm-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-sm-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-sm-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-sm-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-sm-t-200 {
    padding-top: 200px;
  }

  .pd-sm-r-200 {
    padding-right: 200px;
  }

  .pd-sm-b-200 {
    padding-bottom: 200px;
  }

  .pd-sm-l-200 {
    padding-left: 200px;
  }

  .pd-sm-t-200-f {
    padding-top: 200px !important;
  }

  .pd-sm-r-200-f {
    padding-right: 200px !important;
  }

  .pd-sm-b-200-f {
    padding-bottom: 200px !important;
  }

  .pd-sm-l-200-f {
    padding-left: 200px !important;
  }
}
@media (min-width: 768px) {
  .pd-md-0 {
    padding: 0px;
  }

  .pd-md-0-f {
    padding: 0px !important;
  }

  .pd-md-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-md-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-md-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-md-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-md-t-0 {
    padding-top: 0px;
  }

  .pd-md-r-0 {
    padding-right: 0px;
  }

  .pd-md-b-0 {
    padding-bottom: 0px;
  }

  .pd-md-l-0 {
    padding-left: 0px;
  }

  .pd-md-t-0-f {
    padding-top: 0px !important;
  }

  .pd-md-r-0-f {
    padding-right: 0px !important;
  }

  .pd-md-b-0-f {
    padding-bottom: 0px !important;
  }

  .pd-md-l-0-f {
    padding-left: 0px !important;
  }

  .pd-md-1 {
    padding: 1px;
  }

  .pd-md-1-f {
    padding: 1px !important;
  }

  .pd-md-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-md-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-md-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-md-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-md-t-1 {
    padding-top: 1px;
  }

  .pd-md-r-1 {
    padding-right: 1px;
  }

  .pd-md-b-1 {
    padding-bottom: 1px;
  }

  .pd-md-l-1 {
    padding-left: 1px;
  }

  .pd-md-t-1-f {
    padding-top: 1px !important;
  }

  .pd-md-r-1-f {
    padding-right: 1px !important;
  }

  .pd-md-b-1-f {
    padding-bottom: 1px !important;
  }

  .pd-md-l-1-f {
    padding-left: 1px !important;
  }

  .pd-md-2 {
    padding: 2px;
  }

  .pd-md-2-f {
    padding: 2px !important;
  }

  .pd-md-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-md-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-md-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-md-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-md-t-2 {
    padding-top: 2px;
  }

  .pd-md-r-2 {
    padding-right: 2px;
  }

  .pd-md-b-2 {
    padding-bottom: 2px;
  }

  .pd-md-l-2 {
    padding-left: 2px;
  }

  .pd-md-t-2-f {
    padding-top: 2px !important;
  }

  .pd-md-r-2-f {
    padding-right: 2px !important;
  }

  .pd-md-b-2-f {
    padding-bottom: 2px !important;
  }

  .pd-md-l-2-f {
    padding-left: 2px !important;
  }

  .pd-md-3 {
    padding: 3px;
  }

  .pd-md-3-f {
    padding: 3px !important;
  }

  .pd-md-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-md-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-md-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-md-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-md-t-3 {
    padding-top: 3px;
  }

  .pd-md-r-3 {
    padding-right: 3px;
  }

  .pd-md-b-3 {
    padding-bottom: 3px;
  }

  .pd-md-l-3 {
    padding-left: 3px;
  }

  .pd-md-t-3-f {
    padding-top: 3px !important;
  }

  .pd-md-r-3-f {
    padding-right: 3px !important;
  }

  .pd-md-b-3-f {
    padding-bottom: 3px !important;
  }

  .pd-md-l-3-f {
    padding-left: 3px !important;
  }

  .pd-md-4 {
    padding: 4px;
  }

  .pd-md-4-f {
    padding: 4px !important;
  }

  .pd-md-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-md-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-md-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-md-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-md-t-4 {
    padding-top: 4px;
  }

  .pd-md-r-4 {
    padding-right: 4px;
  }

  .pd-md-b-4 {
    padding-bottom: 4px;
  }

  .pd-md-l-4 {
    padding-left: 4px;
  }

  .pd-md-t-4-f {
    padding-top: 4px !important;
  }

  .pd-md-r-4-f {
    padding-right: 4px !important;
  }

  .pd-md-b-4-f {
    padding-bottom: 4px !important;
  }

  .pd-md-l-4-f {
    padding-left: 4px !important;
  }

  .pd-md-5 {
    padding: 5px;
  }

  .pd-md-5-f {
    padding: 5px !important;
  }

  .pd-md-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-md-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-md-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-md-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-md-t-5 {
    padding-top: 5px;
  }

  .pd-md-r-5 {
    padding-right: 5px;
  }

  .pd-md-b-5 {
    padding-bottom: 5px;
  }

  .pd-md-l-5 {
    padding-left: 5px;
  }

  .pd-md-t-5-f {
    padding-top: 5px !important;
  }

  .pd-md-r-5-f {
    padding-right: 5px !important;
  }

  .pd-md-b-5-f {
    padding-bottom: 5px !important;
  }

  .pd-md-l-5-f {
    padding-left: 5px !important;
  }

  .pd-md-6 {
    padding: 6px;
  }

  .pd-md-6-f {
    padding: 6px !important;
  }

  .pd-md-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-md-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-md-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-md-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-md-t-6 {
    padding-top: 6px;
  }

  .pd-md-r-6 {
    padding-right: 6px;
  }

  .pd-md-b-6 {
    padding-bottom: 6px;
  }

  .pd-md-l-6 {
    padding-left: 6px;
  }

  .pd-md-t-6-f {
    padding-top: 6px !important;
  }

  .pd-md-r-6-f {
    padding-right: 6px !important;
  }

  .pd-md-b-6-f {
    padding-bottom: 6px !important;
  }

  .pd-md-l-6-f {
    padding-left: 6px !important;
  }

  .pd-md-7 {
    padding: 7px;
  }

  .pd-md-7-f {
    padding: 7px !important;
  }

  .pd-md-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-md-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-md-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-md-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-md-t-7 {
    padding-top: 7px;
  }

  .pd-md-r-7 {
    padding-right: 7px;
  }

  .pd-md-b-7 {
    padding-bottom: 7px;
  }

  .pd-md-l-7 {
    padding-left: 7px;
  }

  .pd-md-t-7-f {
    padding-top: 7px !important;
  }

  .pd-md-r-7-f {
    padding-right: 7px !important;
  }

  .pd-md-b-7-f {
    padding-bottom: 7px !important;
  }

  .pd-md-l-7-f {
    padding-left: 7px !important;
  }

  .pd-md-8 {
    padding: 8px;
  }

  .pd-md-8-f {
    padding: 8px !important;
  }

  .pd-md-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-md-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-md-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-md-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-md-t-8 {
    padding-top: 8px;
  }

  .pd-md-r-8 {
    padding-right: 8px;
  }

  .pd-md-b-8 {
    padding-bottom: 8px;
  }

  .pd-md-l-8 {
    padding-left: 8px;
  }

  .pd-md-t-8-f {
    padding-top: 8px !important;
  }

  .pd-md-r-8-f {
    padding-right: 8px !important;
  }

  .pd-md-b-8-f {
    padding-bottom: 8px !important;
  }

  .pd-md-l-8-f {
    padding-left: 8px !important;
  }

  .pd-md-9 {
    padding: 9px;
  }

  .pd-md-9-f {
    padding: 9px !important;
  }

  .pd-md-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-md-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-md-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-md-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-md-t-9 {
    padding-top: 9px;
  }

  .pd-md-r-9 {
    padding-right: 9px;
  }

  .pd-md-b-9 {
    padding-bottom: 9px;
  }

  .pd-md-l-9 {
    padding-left: 9px;
  }

  .pd-md-t-9-f {
    padding-top: 9px !important;
  }

  .pd-md-r-9-f {
    padding-right: 9px !important;
  }

  .pd-md-b-9-f {
    padding-bottom: 9px !important;
  }

  .pd-md-l-9-f {
    padding-left: 9px !important;
  }

  .pd-md-10 {
    padding: 10px;
  }

  .pd-md-10-f {
    padding: 10px !important;
  }

  .pd-md-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-md-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-md-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-md-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-md-t-10 {
    padding-top: 10px;
  }

  .pd-md-r-10 {
    padding-right: 10px;
  }

  .pd-md-b-10 {
    padding-bottom: 10px;
  }

  .pd-md-l-10 {
    padding-left: 10px;
  }

  .pd-md-t-10-f {
    padding-top: 10px !important;
  }

  .pd-md-r-10-f {
    padding-right: 10px !important;
  }

  .pd-md-b-10-f {
    padding-bottom: 10px !important;
  }

  .pd-md-l-10-f {
    padding-left: 10px !important;
  }

  .pd-md-15 {
    padding: 15px;
  }

  .pd-md-15-f {
    padding: 15px !important;
  }

  .pd-md-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-md-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-md-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-md-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-md-t-15 {
    padding-top: 15px;
  }

  .pd-md-r-15 {
    padding-right: 15px;
  }

  .pd-md-b-15 {
    padding-bottom: 15px;
  }

  .pd-md-l-15 {
    padding-left: 15px;
  }

  .pd-md-t-15-f {
    padding-top: 15px !important;
  }

  .pd-md-r-15-f {
    padding-right: 15px !important;
  }

  .pd-md-b-15-f {
    padding-bottom: 15px !important;
  }

  .pd-md-l-15-f {
    padding-left: 15px !important;
  }

  .pd-md-20 {
    padding: 20px;
  }

  .pd-md-20-f {
    padding: 20px !important;
  }

  .pd-md-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-md-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-md-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-md-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-md-t-20 {
    padding-top: 20px;
  }

  .pd-md-r-20 {
    padding-right: 20px;
  }

  .pd-md-b-20 {
    padding-bottom: 20px;
  }

  .pd-md-l-20 {
    padding-left: 20px;
  }

  .pd-md-t-20-f {
    padding-top: 20px !important;
  }

  .pd-md-r-20-f {
    padding-right: 20px !important;
  }

  .pd-md-b-20-f {
    padding-bottom: 20px !important;
  }

  .pd-md-l-20-f {
    padding-left: 20px !important;
  }

  .pd-md-25 {
    padding: 25px;
  }

  .pd-md-25-f {
    padding: 25px !important;
  }

  .pd-md-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-md-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-md-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-md-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-md-t-25 {
    padding-top: 25px;
  }

  .pd-md-r-25 {
    padding-right: 25px;
  }

  .pd-md-b-25 {
    padding-bottom: 25px;
  }

  .pd-md-l-25 {
    padding-left: 25px;
  }

  .pd-md-t-25-f {
    padding-top: 25px !important;
  }

  .pd-md-r-25-f {
    padding-right: 25px !important;
  }

  .pd-md-b-25-f {
    padding-bottom: 25px !important;
  }

  .pd-md-l-25-f {
    padding-left: 25px !important;
  }

  .pd-md-30 {
    padding: 30px;
  }

  .pd-md-30-f {
    padding: 30px !important;
  }

  .pd-md-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-md-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-md-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-md-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-md-t-30 {
    padding-top: 30px;
  }

  .pd-md-r-30 {
    padding-right: 30px;
  }

  .pd-md-b-30 {
    padding-bottom: 30px;
  }

  .pd-md-l-30 {
    padding-left: 30px;
  }

  .pd-md-t-30-f {
    padding-top: 30px !important;
  }

  .pd-md-r-30-f {
    padding-right: 30px !important;
  }

  .pd-md-b-30-f {
    padding-bottom: 30px !important;
  }

  .pd-md-l-30-f {
    padding-left: 30px !important;
  }

  .pd-md-35 {
    padding: 35px;
  }

  .pd-md-35-f {
    padding: 35px !important;
  }

  .pd-md-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-md-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-md-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-md-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-md-t-35 {
    padding-top: 35px;
  }

  .pd-md-r-35 {
    padding-right: 35px;
  }

  .pd-md-b-35 {
    padding-bottom: 35px;
  }

  .pd-md-l-35 {
    padding-left: 35px;
  }

  .pd-md-t-35-f {
    padding-top: 35px !important;
  }

  .pd-md-r-35-f {
    padding-right: 35px !important;
  }

  .pd-md-b-35-f {
    padding-bottom: 35px !important;
  }

  .pd-md-l-35-f {
    padding-left: 35px !important;
  }

  .pd-md-40 {
    padding: 40px;
  }

  .pd-md-40-f {
    padding: 40px !important;
  }

  .pd-md-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-md-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-md-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-md-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-md-t-40 {
    padding-top: 40px;
  }

  .pd-md-r-40 {
    padding-right: 40px;
  }

  .pd-md-b-40 {
    padding-bottom: 40px;
  }

  .pd-md-l-40 {
    padding-left: 40px;
  }

  .pd-md-t-40-f {
    padding-top: 40px !important;
  }

  .pd-md-r-40-f {
    padding-right: 40px !important;
  }

  .pd-md-b-40-f {
    padding-bottom: 40px !important;
  }

  .pd-md-l-40-f {
    padding-left: 40px !important;
  }

  .pd-md-45 {
    padding: 45px;
  }

  .pd-md-45-f {
    padding: 45px !important;
  }

  .pd-md-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-md-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-md-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-md-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-md-t-45 {
    padding-top: 45px;
  }

  .pd-md-r-45 {
    padding-right: 45px;
  }

  .pd-md-b-45 {
    padding-bottom: 45px;
  }

  .pd-md-l-45 {
    padding-left: 45px;
  }

  .pd-md-t-45-f {
    padding-top: 45px !important;
  }

  .pd-md-r-45-f {
    padding-right: 45px !important;
  }

  .pd-md-b-45-f {
    padding-bottom: 45px !important;
  }

  .pd-md-l-45-f {
    padding-left: 45px !important;
  }

  .pd-md-50 {
    padding: 50px;
  }

  .pd-md-50-f {
    padding: 50px !important;
  }

  .pd-md-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-md-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-md-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-md-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-md-t-50 {
    padding-top: 50px;
  }

  .pd-md-r-50 {
    padding-right: 50px;
  }

  .pd-md-b-50 {
    padding-bottom: 50px;
  }

  .pd-md-l-50 {
    padding-left: 50px;
  }

  .pd-md-t-50-f {
    padding-top: 50px !important;
  }

  .pd-md-r-50-f {
    padding-right: 50px !important;
  }

  .pd-md-b-50-f {
    padding-bottom: 50px !important;
  }

  .pd-md-l-50-f {
    padding-left: 50px !important;
  }

  .pd-md-55 {
    padding: 55px;
  }

  .pd-md-55-f {
    padding: 55px !important;
  }

  .pd-md-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-md-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-md-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-md-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-md-t-55 {
    padding-top: 55px;
  }

  .pd-md-r-55 {
    padding-right: 55px;
  }

  .pd-md-b-55 {
    padding-bottom: 55px;
  }

  .pd-md-l-55 {
    padding-left: 55px;
  }

  .pd-md-t-55-f {
    padding-top: 55px !important;
  }

  .pd-md-r-55-f {
    padding-right: 55px !important;
  }

  .pd-md-b-55-f {
    padding-bottom: 55px !important;
  }

  .pd-md-l-55-f {
    padding-left: 55px !important;
  }

  .pd-md-60 {
    padding: 60px;
  }

  .pd-md-60-f {
    padding: 60px !important;
  }

  .pd-md-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-md-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-md-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-md-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-md-t-60 {
    padding-top: 60px;
  }

  .pd-md-r-60 {
    padding-right: 60px;
  }

  .pd-md-b-60 {
    padding-bottom: 60px;
  }

  .pd-md-l-60 {
    padding-left: 60px;
  }

  .pd-md-t-60-f {
    padding-top: 60px !important;
  }

  .pd-md-r-60-f {
    padding-right: 60px !important;
  }

  .pd-md-b-60-f {
    padding-bottom: 60px !important;
  }

  .pd-md-l-60-f {
    padding-left: 60px !important;
  }

  .pd-md-65 {
    padding: 65px;
  }

  .pd-md-65-f {
    padding: 65px !important;
  }

  .pd-md-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-md-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-md-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-md-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-md-t-65 {
    padding-top: 65px;
  }

  .pd-md-r-65 {
    padding-right: 65px;
  }

  .pd-md-b-65 {
    padding-bottom: 65px;
  }

  .pd-md-l-65 {
    padding-left: 65px;
  }

  .pd-md-t-65-f {
    padding-top: 65px !important;
  }

  .pd-md-r-65-f {
    padding-right: 65px !important;
  }

  .pd-md-b-65-f {
    padding-bottom: 65px !important;
  }

  .pd-md-l-65-f {
    padding-left: 65px !important;
  }

  .pd-md-70 {
    padding: 70px;
  }

  .pd-md-70-f {
    padding: 70px !important;
  }

  .pd-md-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-md-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-md-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-md-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-md-t-70 {
    padding-top: 70px;
  }

  .pd-md-r-70 {
    padding-right: 70px;
  }

  .pd-md-b-70 {
    padding-bottom: 70px;
  }

  .pd-md-l-70 {
    padding-left: 70px;
  }

  .pd-md-t-70-f {
    padding-top: 70px !important;
  }

  .pd-md-r-70-f {
    padding-right: 70px !important;
  }

  .pd-md-b-70-f {
    padding-bottom: 70px !important;
  }

  .pd-md-l-70-f {
    padding-left: 70px !important;
  }

  .pd-md-75 {
    padding: 75px;
  }

  .pd-md-75-f {
    padding: 75px !important;
  }

  .pd-md-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-md-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-md-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-md-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-md-t-75 {
    padding-top: 75px;
  }

  .pd-md-r-75 {
    padding-right: 75px;
  }

  .pd-md-b-75 {
    padding-bottom: 75px;
  }

  .pd-md-l-75 {
    padding-left: 75px;
  }

  .pd-md-t-75-f {
    padding-top: 75px !important;
  }

  .pd-md-r-75-f {
    padding-right: 75px !important;
  }

  .pd-md-b-75-f {
    padding-bottom: 75px !important;
  }

  .pd-md-l-75-f {
    padding-left: 75px !important;
  }

  .pd-md-80 {
    padding: 80px;
  }

  .pd-md-80-f {
    padding: 80px !important;
  }

  .pd-md-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-md-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-md-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-md-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-md-t-80 {
    padding-top: 80px;
  }

  .pd-md-r-80 {
    padding-right: 80px;
  }

  .pd-md-b-80 {
    padding-bottom: 80px;
  }

  .pd-md-l-80 {
    padding-left: 80px;
  }

  .pd-md-t-80-f {
    padding-top: 80px !important;
  }

  .pd-md-r-80-f {
    padding-right: 80px !important;
  }

  .pd-md-b-80-f {
    padding-bottom: 80px !important;
  }

  .pd-md-l-80-f {
    padding-left: 80px !important;
  }

  .pd-md-85 {
    padding: 85px;
  }

  .pd-md-85-f {
    padding: 85px !important;
  }

  .pd-md-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-md-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-md-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-md-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-md-t-85 {
    padding-top: 85px;
  }

  .pd-md-r-85 {
    padding-right: 85px;
  }

  .pd-md-b-85 {
    padding-bottom: 85px;
  }

  .pd-md-l-85 {
    padding-left: 85px;
  }

  .pd-md-t-85-f {
    padding-top: 85px !important;
  }

  .pd-md-r-85-f {
    padding-right: 85px !important;
  }

  .pd-md-b-85-f {
    padding-bottom: 85px !important;
  }

  .pd-md-l-85-f {
    padding-left: 85px !important;
  }

  .pd-md-90 {
    padding: 90px;
  }

  .pd-md-90-f {
    padding: 90px !important;
  }

  .pd-md-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-md-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-md-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-md-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-md-t-90 {
    padding-top: 90px;
  }

  .pd-md-r-90 {
    padding-right: 90px;
  }

  .pd-md-b-90 {
    padding-bottom: 90px;
  }

  .pd-md-l-90 {
    padding-left: 90px;
  }

  .pd-md-t-90-f {
    padding-top: 90px !important;
  }

  .pd-md-r-90-f {
    padding-right: 90px !important;
  }

  .pd-md-b-90-f {
    padding-bottom: 90px !important;
  }

  .pd-md-l-90-f {
    padding-left: 90px !important;
  }

  .pd-md-95 {
    padding: 95px;
  }

  .pd-md-95-f {
    padding: 95px !important;
  }

  .pd-md-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-md-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-md-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-md-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-md-t-95 {
    padding-top: 95px;
  }

  .pd-md-r-95 {
    padding-right: 95px;
  }

  .pd-md-b-95 {
    padding-bottom: 95px;
  }

  .pd-md-l-95 {
    padding-left: 95px;
  }

  .pd-md-t-95-f {
    padding-top: 95px !important;
  }

  .pd-md-r-95-f {
    padding-right: 95px !important;
  }

  .pd-md-b-95-f {
    padding-bottom: 95px !important;
  }

  .pd-md-l-95-f {
    padding-left: 95px !important;
  }

  .pd-md-100 {
    padding: 100px;
  }

  .pd-md-100-f {
    padding: 100px !important;
  }

  .pd-md-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-md-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-md-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-md-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-md-t-100 {
    padding-top: 100px;
  }

  .pd-md-r-100 {
    padding-right: 100px;
  }

  .pd-md-b-100 {
    padding-bottom: 100px;
  }

  .pd-md-l-100 {
    padding-left: 100px;
  }

  .pd-md-t-100-f {
    padding-top: 100px !important;
  }

  .pd-md-r-100-f {
    padding-right: 100px !important;
  }

  .pd-md-b-100-f {
    padding-bottom: 100px !important;
  }

  .pd-md-l-100-f {
    padding-left: 100px !important;
  }

  .pd-md-110 {
    padding: 110px;
  }

  .pd-md-110-f {
    padding: 110px !important;
  }

  .pd-md-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-md-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-md-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-md-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-md-t-110 {
    padding-top: 110px;
  }

  .pd-md-r-110 {
    padding-right: 110px;
  }

  .pd-md-b-110 {
    padding-bottom: 110px;
  }

  .pd-md-l-110 {
    padding-left: 110px;
  }

  .pd-md-t-110-f {
    padding-top: 110px !important;
  }

  .pd-md-r-110-f {
    padding-right: 110px !important;
  }

  .pd-md-b-110-f {
    padding-bottom: 110px !important;
  }

  .pd-md-l-110-f {
    padding-left: 110px !important;
  }

  .pd-md-120 {
    padding: 120px;
  }

  .pd-md-120-f {
    padding: 120px !important;
  }

  .pd-md-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-md-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-md-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-md-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-md-t-120 {
    padding-top: 120px;
  }

  .pd-md-r-120 {
    padding-right: 120px;
  }

  .pd-md-b-120 {
    padding-bottom: 120px;
  }

  .pd-md-l-120 {
    padding-left: 120px;
  }

  .pd-md-t-120-f {
    padding-top: 120px !important;
  }

  .pd-md-r-120-f {
    padding-right: 120px !important;
  }

  .pd-md-b-120-f {
    padding-bottom: 120px !important;
  }

  .pd-md-l-120-f {
    padding-left: 120px !important;
  }

  .pd-md-130 {
    padding: 130px;
  }

  .pd-md-130-f {
    padding: 130px !important;
  }

  .pd-md-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-md-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-md-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-md-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-md-t-130 {
    padding-top: 130px;
  }

  .pd-md-r-130 {
    padding-right: 130px;
  }

  .pd-md-b-130 {
    padding-bottom: 130px;
  }

  .pd-md-l-130 {
    padding-left: 130px;
  }

  .pd-md-t-130-f {
    padding-top: 130px !important;
  }

  .pd-md-r-130-f {
    padding-right: 130px !important;
  }

  .pd-md-b-130-f {
    padding-bottom: 130px !important;
  }

  .pd-md-l-130-f {
    padding-left: 130px !important;
  }

  .pd-md-140 {
    padding: 140px;
  }

  .pd-md-140-f {
    padding: 140px !important;
  }

  .pd-md-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-md-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-md-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-md-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-md-t-140 {
    padding-top: 140px;
  }

  .pd-md-r-140 {
    padding-right: 140px;
  }

  .pd-md-b-140 {
    padding-bottom: 140px;
  }

  .pd-md-l-140 {
    padding-left: 140px;
  }

  .pd-md-t-140-f {
    padding-top: 140px !important;
  }

  .pd-md-r-140-f {
    padding-right: 140px !important;
  }

  .pd-md-b-140-f {
    padding-bottom: 140px !important;
  }

  .pd-md-l-140-f {
    padding-left: 140px !important;
  }

  .pd-md-150 {
    padding: 150px;
  }

  .pd-md-150-f {
    padding: 150px !important;
  }

  .pd-md-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-md-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-md-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-md-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-md-t-150 {
    padding-top: 150px;
  }

  .pd-md-r-150 {
    padding-right: 150px;
  }

  .pd-md-b-150 {
    padding-bottom: 150px;
  }

  .pd-md-l-150 {
    padding-left: 150px;
  }

  .pd-md-t-150-f {
    padding-top: 150px !important;
  }

  .pd-md-r-150-f {
    padding-right: 150px !important;
  }

  .pd-md-b-150-f {
    padding-bottom: 150px !important;
  }

  .pd-md-l-150-f {
    padding-left: 150px !important;
  }

  .pd-md-160 {
    padding: 160px;
  }

  .pd-md-160-f {
    padding: 160px !important;
  }

  .pd-md-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-md-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-md-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-md-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-md-t-160 {
    padding-top: 160px;
  }

  .pd-md-r-160 {
    padding-right: 160px;
  }

  .pd-md-b-160 {
    padding-bottom: 160px;
  }

  .pd-md-l-160 {
    padding-left: 160px;
  }

  .pd-md-t-160-f {
    padding-top: 160px !important;
  }

  .pd-md-r-160-f {
    padding-right: 160px !important;
  }

  .pd-md-b-160-f {
    padding-bottom: 160px !important;
  }

  .pd-md-l-160-f {
    padding-left: 160px !important;
  }

  .pd-md-170 {
    padding: 170px;
  }

  .pd-md-170-f {
    padding: 170px !important;
  }

  .pd-md-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-md-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-md-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-md-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-md-t-170 {
    padding-top: 170px;
  }

  .pd-md-r-170 {
    padding-right: 170px;
  }

  .pd-md-b-170 {
    padding-bottom: 170px;
  }

  .pd-md-l-170 {
    padding-left: 170px;
  }

  .pd-md-t-170-f {
    padding-top: 170px !important;
  }

  .pd-md-r-170-f {
    padding-right: 170px !important;
  }

  .pd-md-b-170-f {
    padding-bottom: 170px !important;
  }

  .pd-md-l-170-f {
    padding-left: 170px !important;
  }

  .pd-md-180 {
    padding: 180px;
  }

  .pd-md-180-f {
    padding: 180px !important;
  }

  .pd-md-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-md-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-md-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-md-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-md-t-180 {
    padding-top: 180px;
  }

  .pd-md-r-180 {
    padding-right: 180px;
  }

  .pd-md-b-180 {
    padding-bottom: 180px;
  }

  .pd-md-l-180 {
    padding-left: 180px;
  }

  .pd-md-t-180-f {
    padding-top: 180px !important;
  }

  .pd-md-r-180-f {
    padding-right: 180px !important;
  }

  .pd-md-b-180-f {
    padding-bottom: 180px !important;
  }

  .pd-md-l-180-f {
    padding-left: 180px !important;
  }

  .pd-md-190 {
    padding: 190px;
  }

  .pd-md-190-f {
    padding: 190px !important;
  }

  .pd-md-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-md-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-md-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-md-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-md-t-190 {
    padding-top: 190px;
  }

  .pd-md-r-190 {
    padding-right: 190px;
  }

  .pd-md-b-190 {
    padding-bottom: 190px;
  }

  .pd-md-l-190 {
    padding-left: 190px;
  }

  .pd-md-t-190-f {
    padding-top: 190px !important;
  }

  .pd-md-r-190-f {
    padding-right: 190px !important;
  }

  .pd-md-b-190-f {
    padding-bottom: 190px !important;
  }

  .pd-md-l-190-f {
    padding-left: 190px !important;
  }

  .pd-md-200 {
    padding: 200px;
  }

  .pd-md-200-f {
    padding: 200px !important;
  }

  .pd-md-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-md-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-md-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-md-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-md-t-200 {
    padding-top: 200px;
  }

  .pd-md-r-200 {
    padding-right: 200px;
  }

  .pd-md-b-200 {
    padding-bottom: 200px;
  }

  .pd-md-l-200 {
    padding-left: 200px;
  }

  .pd-md-t-200-f {
    padding-top: 200px !important;
  }

  .pd-md-r-200-f {
    padding-right: 200px !important;
  }

  .pd-md-b-200-f {
    padding-bottom: 200px !important;
  }

  .pd-md-l-200-f {
    padding-left: 200px !important;
  }
}
@media (min-width: 992px) {
  .pd-lg-0 {
    padding: 0px;
  }

  .pd-lg-0-f {
    padding: 0px !important;
  }

  .pd-lg-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-lg-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-lg-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-lg-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-lg-t-0 {
    padding-top: 0px;
  }

  .pd-lg-r-0 {
    padding-right: 0px;
  }

  .pd-lg-b-0 {
    padding-bottom: 0px;
  }

  .pd-lg-l-0 {
    padding-left: 0px;
  }

  .pd-lg-t-0-f {
    padding-top: 0px !important;
  }

  .pd-lg-r-0-f {
    padding-right: 0px !important;
  }

  .pd-lg-b-0-f {
    padding-bottom: 0px !important;
  }

  .pd-lg-l-0-f {
    padding-left: 0px !important;
  }

  .pd-lg-1 {
    padding: 1px;
  }

  .pd-lg-1-f {
    padding: 1px !important;
  }

  .pd-lg-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-lg-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-lg-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-lg-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-lg-t-1 {
    padding-top: 1px;
  }

  .pd-lg-r-1 {
    padding-right: 1px;
  }

  .pd-lg-b-1 {
    padding-bottom: 1px;
  }

  .pd-lg-l-1 {
    padding-left: 1px;
  }

  .pd-lg-t-1-f {
    padding-top: 1px !important;
  }

  .pd-lg-r-1-f {
    padding-right: 1px !important;
  }

  .pd-lg-b-1-f {
    padding-bottom: 1px !important;
  }

  .pd-lg-l-1-f {
    padding-left: 1px !important;
  }

  .pd-lg-2 {
    padding: 2px;
  }

  .pd-lg-2-f {
    padding: 2px !important;
  }

  .pd-lg-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-lg-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-lg-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-lg-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-lg-t-2 {
    padding-top: 2px;
  }

  .pd-lg-r-2 {
    padding-right: 2px;
  }

  .pd-lg-b-2 {
    padding-bottom: 2px;
  }

  .pd-lg-l-2 {
    padding-left: 2px;
  }

  .pd-lg-t-2-f {
    padding-top: 2px !important;
  }

  .pd-lg-r-2-f {
    padding-right: 2px !important;
  }

  .pd-lg-b-2-f {
    padding-bottom: 2px !important;
  }

  .pd-lg-l-2-f {
    padding-left: 2px !important;
  }

  .pd-lg-3 {
    padding: 3px;
  }

  .pd-lg-3-f {
    padding: 3px !important;
  }

  .pd-lg-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-lg-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-lg-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-lg-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-lg-t-3 {
    padding-top: 3px;
  }

  .pd-lg-r-3 {
    padding-right: 3px;
  }

  .pd-lg-b-3 {
    padding-bottom: 3px;
  }

  .pd-lg-l-3 {
    padding-left: 3px;
  }

  .pd-lg-t-3-f {
    padding-top: 3px !important;
  }

  .pd-lg-r-3-f {
    padding-right: 3px !important;
  }

  .pd-lg-b-3-f {
    padding-bottom: 3px !important;
  }

  .pd-lg-l-3-f {
    padding-left: 3px !important;
  }

  .pd-lg-4 {
    padding: 4px;
  }

  .pd-lg-4-f {
    padding: 4px !important;
  }

  .pd-lg-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-lg-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-lg-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-lg-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-lg-t-4 {
    padding-top: 4px;
  }

  .pd-lg-r-4 {
    padding-right: 4px;
  }

  .pd-lg-b-4 {
    padding-bottom: 4px;
  }

  .pd-lg-l-4 {
    padding-left: 4px;
  }

  .pd-lg-t-4-f {
    padding-top: 4px !important;
  }

  .pd-lg-r-4-f {
    padding-right: 4px !important;
  }

  .pd-lg-b-4-f {
    padding-bottom: 4px !important;
  }

  .pd-lg-l-4-f {
    padding-left: 4px !important;
  }

  .pd-lg-5 {
    padding: 5px;
  }

  .pd-lg-5-f {
    padding: 5px !important;
  }

  .pd-lg-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-lg-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-lg-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-lg-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-lg-t-5 {
    padding-top: 5px;
  }

  .pd-lg-r-5 {
    padding-right: 5px;
  }

  .pd-lg-b-5 {
    padding-bottom: 5px;
  }

  .pd-lg-l-5 {
    padding-left: 5px;
  }

  .pd-lg-t-5-f {
    padding-top: 5px !important;
  }

  .pd-lg-r-5-f {
    padding-right: 5px !important;
  }

  .pd-lg-b-5-f {
    padding-bottom: 5px !important;
  }

  .pd-lg-l-5-f {
    padding-left: 5px !important;
  }

  .pd-lg-6 {
    padding: 6px;
  }

  .pd-lg-6-f {
    padding: 6px !important;
  }

  .pd-lg-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-lg-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-lg-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-lg-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-lg-t-6 {
    padding-top: 6px;
  }

  .pd-lg-r-6 {
    padding-right: 6px;
  }

  .pd-lg-b-6 {
    padding-bottom: 6px;
  }

  .pd-lg-l-6 {
    padding-left: 6px;
  }

  .pd-lg-t-6-f {
    padding-top: 6px !important;
  }

  .pd-lg-r-6-f {
    padding-right: 6px !important;
  }

  .pd-lg-b-6-f {
    padding-bottom: 6px !important;
  }

  .pd-lg-l-6-f {
    padding-left: 6px !important;
  }

  .pd-lg-7 {
    padding: 7px;
  }

  .pd-lg-7-f {
    padding: 7px !important;
  }

  .pd-lg-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-lg-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-lg-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-lg-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-lg-t-7 {
    padding-top: 7px;
  }

  .pd-lg-r-7 {
    padding-right: 7px;
  }

  .pd-lg-b-7 {
    padding-bottom: 7px;
  }

  .pd-lg-l-7 {
    padding-left: 7px;
  }

  .pd-lg-t-7-f {
    padding-top: 7px !important;
  }

  .pd-lg-r-7-f {
    padding-right: 7px !important;
  }

  .pd-lg-b-7-f {
    padding-bottom: 7px !important;
  }

  .pd-lg-l-7-f {
    padding-left: 7px !important;
  }

  .pd-lg-8 {
    padding: 8px;
  }

  .pd-lg-8-f {
    padding: 8px !important;
  }

  .pd-lg-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-lg-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-lg-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-lg-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-lg-t-8 {
    padding-top: 8px;
  }

  .pd-lg-r-8 {
    padding-right: 8px;
  }

  .pd-lg-b-8 {
    padding-bottom: 8px;
  }

  .pd-lg-l-8 {
    padding-left: 8px;
  }

  .pd-lg-t-8-f {
    padding-top: 8px !important;
  }

  .pd-lg-r-8-f {
    padding-right: 8px !important;
  }

  .pd-lg-b-8-f {
    padding-bottom: 8px !important;
  }

  .pd-lg-l-8-f {
    padding-left: 8px !important;
  }

  .pd-lg-9 {
    padding: 9px;
  }

  .pd-lg-9-f {
    padding: 9px !important;
  }

  .pd-lg-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-lg-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-lg-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-lg-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-lg-t-9 {
    padding-top: 9px;
  }

  .pd-lg-r-9 {
    padding-right: 9px;
  }

  .pd-lg-b-9 {
    padding-bottom: 9px;
  }

  .pd-lg-l-9 {
    padding-left: 9px;
  }

  .pd-lg-t-9-f {
    padding-top: 9px !important;
  }

  .pd-lg-r-9-f {
    padding-right: 9px !important;
  }

  .pd-lg-b-9-f {
    padding-bottom: 9px !important;
  }

  .pd-lg-l-9-f {
    padding-left: 9px !important;
  }

  .pd-lg-10 {
    padding: 10px;
  }

  .pd-lg-10-f {
    padding: 10px !important;
  }

  .pd-lg-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-lg-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-lg-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-lg-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-lg-t-10 {
    padding-top: 10px;
  }

  .pd-lg-r-10 {
    padding-right: 10px;
  }

  .pd-lg-b-10 {
    padding-bottom: 10px;
  }

  .pd-lg-l-10 {
    padding-left: 10px;
  }

  .pd-lg-t-10-f {
    padding-top: 10px !important;
  }

  .pd-lg-r-10-f {
    padding-right: 10px !important;
  }

  .pd-lg-b-10-f {
    padding-bottom: 10px !important;
  }

  .pd-lg-l-10-f {
    padding-left: 10px !important;
  }

  .pd-lg-15 {
    padding: 15px;
  }

  .pd-lg-15-f {
    padding: 15px !important;
  }

  .pd-lg-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-lg-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-lg-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-lg-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-lg-t-15 {
    padding-top: 15px;
  }

  .pd-lg-r-15 {
    padding-right: 15px;
  }

  .pd-lg-b-15 {
    padding-bottom: 15px;
  }

  .pd-lg-l-15 {
    padding-left: 15px;
  }

  .pd-lg-t-15-f {
    padding-top: 15px !important;
  }

  .pd-lg-r-15-f {
    padding-right: 15px !important;
  }

  .pd-lg-b-15-f {
    padding-bottom: 15px !important;
  }

  .pd-lg-l-15-f {
    padding-left: 15px !important;
  }

  .pd-lg-20 {
    padding: 20px;
  }

  .pd-lg-20-f {
    padding: 20px !important;
  }

  .pd-lg-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-lg-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-lg-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-lg-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-lg-t-20 {
    padding-top: 20px;
  }

  .pd-lg-r-20 {
    padding-right: 20px;
  }

  .pd-lg-b-20 {
    padding-bottom: 20px;
  }

  .pd-lg-l-20 {
    padding-left: 20px;
  }

  .pd-lg-t-20-f {
    padding-top: 20px !important;
  }

  .pd-lg-r-20-f {
    padding-right: 20px !important;
  }

  .pd-lg-b-20-f {
    padding-bottom: 20px !important;
  }

  .pd-lg-l-20-f {
    padding-left: 20px !important;
  }

  .pd-lg-25 {
    padding: 25px;
  }

  .pd-lg-25-f {
    padding: 25px !important;
  }

  .pd-lg-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-lg-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-lg-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-lg-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-lg-t-25 {
    padding-top: 25px;
  }

  .pd-lg-r-25 {
    padding-right: 25px;
  }

  .pd-lg-b-25 {
    padding-bottom: 25px;
  }

  .pd-lg-l-25 {
    padding-left: 25px;
  }

  .pd-lg-t-25-f {
    padding-top: 25px !important;
  }

  .pd-lg-r-25-f {
    padding-right: 25px !important;
  }

  .pd-lg-b-25-f {
    padding-bottom: 25px !important;
  }

  .pd-lg-l-25-f {
    padding-left: 25px !important;
  }

  .pd-lg-30 {
    padding: 30px;
  }

  .pd-lg-30-f {
    padding: 30px !important;
  }

  .pd-lg-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-lg-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-lg-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-lg-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-lg-t-30 {
    padding-top: 30px;
  }

  .pd-lg-r-30 {
    padding-right: 30px;
  }

  .pd-lg-b-30 {
    padding-bottom: 30px;
  }

  .pd-lg-l-30 {
    padding-left: 30px;
  }

  .pd-lg-t-30-f {
    padding-top: 30px !important;
  }

  .pd-lg-r-30-f {
    padding-right: 30px !important;
  }

  .pd-lg-b-30-f {
    padding-bottom: 30px !important;
  }

  .pd-lg-l-30-f {
    padding-left: 30px !important;
  }

  .pd-lg-35 {
    padding: 35px;
  }

  .pd-lg-35-f {
    padding: 35px !important;
  }

  .pd-lg-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-lg-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-lg-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-lg-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-lg-t-35 {
    padding-top: 35px;
  }

  .pd-lg-r-35 {
    padding-right: 35px;
  }

  .pd-lg-b-35 {
    padding-bottom: 35px;
  }

  .pd-lg-l-35 {
    padding-left: 35px;
  }

  .pd-lg-t-35-f {
    padding-top: 35px !important;
  }

  .pd-lg-r-35-f {
    padding-right: 35px !important;
  }

  .pd-lg-b-35-f {
    padding-bottom: 35px !important;
  }

  .pd-lg-l-35-f {
    padding-left: 35px !important;
  }

  .pd-lg-40 {
    padding: 40px;
  }

  .pd-lg-40-f {
    padding: 40px !important;
  }

  .pd-lg-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-lg-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-lg-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-lg-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-lg-t-40 {
    padding-top: 40px;
  }

  .pd-lg-r-40 {
    padding-right: 40px;
  }

  .pd-lg-b-40 {
    padding-bottom: 40px;
  }

  .pd-lg-l-40 {
    padding-left: 40px;
  }

  .pd-lg-t-40-f {
    padding-top: 40px !important;
  }

  .pd-lg-r-40-f {
    padding-right: 40px !important;
  }

  .pd-lg-b-40-f {
    padding-bottom: 40px !important;
  }

  .pd-lg-l-40-f {
    padding-left: 40px !important;
  }

  .pd-lg-45 {
    padding: 45px;
  }

  .pd-lg-45-f {
    padding: 45px !important;
  }

  .pd-lg-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-lg-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-lg-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-lg-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-lg-t-45 {
    padding-top: 45px;
  }

  .pd-lg-r-45 {
    padding-right: 45px;
  }

  .pd-lg-b-45 {
    padding-bottom: 45px;
  }

  .pd-lg-l-45 {
    padding-left: 45px;
  }

  .pd-lg-t-45-f {
    padding-top: 45px !important;
  }

  .pd-lg-r-45-f {
    padding-right: 45px !important;
  }

  .pd-lg-b-45-f {
    padding-bottom: 45px !important;
  }

  .pd-lg-l-45-f {
    padding-left: 45px !important;
  }

  .pd-lg-50 {
    padding: 50px;
  }

  .pd-lg-50-f {
    padding: 50px !important;
  }

  .pd-lg-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-lg-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-lg-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-lg-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-lg-t-50 {
    padding-top: 50px;
  }

  .pd-lg-r-50 {
    padding-right: 50px;
  }

  .pd-lg-b-50 {
    padding-bottom: 50px;
  }

  .pd-lg-l-50 {
    padding-left: 50px;
  }

  .pd-lg-t-50-f {
    padding-top: 50px !important;
  }

  .pd-lg-r-50-f {
    padding-right: 50px !important;
  }

  .pd-lg-b-50-f {
    padding-bottom: 50px !important;
  }

  .pd-lg-l-50-f {
    padding-left: 50px !important;
  }

  .pd-lg-55 {
    padding: 55px;
  }

  .pd-lg-55-f {
    padding: 55px !important;
  }

  .pd-lg-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-lg-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-lg-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-lg-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-lg-t-55 {
    padding-top: 55px;
  }

  .pd-lg-r-55 {
    padding-right: 55px;
  }

  .pd-lg-b-55 {
    padding-bottom: 55px;
  }

  .pd-lg-l-55 {
    padding-left: 55px;
  }

  .pd-lg-t-55-f {
    padding-top: 55px !important;
  }

  .pd-lg-r-55-f {
    padding-right: 55px !important;
  }

  .pd-lg-b-55-f {
    padding-bottom: 55px !important;
  }

  .pd-lg-l-55-f {
    padding-left: 55px !important;
  }

  .pd-lg-60 {
    padding: 60px;
  }

  .pd-lg-60-f {
    padding: 60px !important;
  }

  .pd-lg-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-lg-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-lg-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-lg-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-lg-t-60 {
    padding-top: 60px;
  }

  .pd-lg-r-60 {
    padding-right: 60px;
  }

  .pd-lg-b-60 {
    padding-bottom: 60px;
  }

  .pd-lg-l-60 {
    padding-left: 60px;
  }

  .pd-lg-t-60-f {
    padding-top: 60px !important;
  }

  .pd-lg-r-60-f {
    padding-right: 60px !important;
  }

  .pd-lg-b-60-f {
    padding-bottom: 60px !important;
  }

  .pd-lg-l-60-f {
    padding-left: 60px !important;
  }

  .pd-lg-65 {
    padding: 65px;
  }

  .pd-lg-65-f {
    padding: 65px !important;
  }

  .pd-lg-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-lg-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-lg-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-lg-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-lg-t-65 {
    padding-top: 65px;
  }

  .pd-lg-r-65 {
    padding-right: 65px;
  }

  .pd-lg-b-65 {
    padding-bottom: 65px;
  }

  .pd-lg-l-65 {
    padding-left: 65px;
  }

  .pd-lg-t-65-f {
    padding-top: 65px !important;
  }

  .pd-lg-r-65-f {
    padding-right: 65px !important;
  }

  .pd-lg-b-65-f {
    padding-bottom: 65px !important;
  }

  .pd-lg-l-65-f {
    padding-left: 65px !important;
  }

  .pd-lg-70 {
    padding: 70px;
  }

  .pd-lg-70-f {
    padding: 70px !important;
  }

  .pd-lg-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-lg-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-lg-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-lg-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-lg-t-70 {
    padding-top: 70px;
  }

  .pd-lg-r-70 {
    padding-right: 70px;
  }

  .pd-lg-b-70 {
    padding-bottom: 70px;
  }

  .pd-lg-l-70 {
    padding-left: 70px;
  }

  .pd-lg-t-70-f {
    padding-top: 70px !important;
  }

  .pd-lg-r-70-f {
    padding-right: 70px !important;
  }

  .pd-lg-b-70-f {
    padding-bottom: 70px !important;
  }

  .pd-lg-l-70-f {
    padding-left: 70px !important;
  }

  .pd-lg-75 {
    padding: 75px;
  }

  .pd-lg-75-f {
    padding: 75px !important;
  }

  .pd-lg-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-lg-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-lg-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-lg-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-lg-t-75 {
    padding-top: 75px;
  }

  .pd-lg-r-75 {
    padding-right: 75px;
  }

  .pd-lg-b-75 {
    padding-bottom: 75px;
  }

  .pd-lg-l-75 {
    padding-left: 75px;
  }

  .pd-lg-t-75-f {
    padding-top: 75px !important;
  }

  .pd-lg-r-75-f {
    padding-right: 75px !important;
  }

  .pd-lg-b-75-f {
    padding-bottom: 75px !important;
  }

  .pd-lg-l-75-f {
    padding-left: 75px !important;
  }

  .pd-lg-80 {
    padding: 80px;
  }

  .pd-lg-80-f {
    padding: 80px !important;
  }

  .pd-lg-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-lg-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-lg-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-lg-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-lg-t-80 {
    padding-top: 80px;
  }

  .pd-lg-r-80 {
    padding-right: 80px;
  }

  .pd-lg-b-80 {
    padding-bottom: 80px;
  }

  .pd-lg-l-80 {
    padding-left: 80px;
  }

  .pd-lg-t-80-f {
    padding-top: 80px !important;
  }

  .pd-lg-r-80-f {
    padding-right: 80px !important;
  }

  .pd-lg-b-80-f {
    padding-bottom: 80px !important;
  }

  .pd-lg-l-80-f {
    padding-left: 80px !important;
  }

  .pd-lg-85 {
    padding: 85px;
  }

  .pd-lg-85-f {
    padding: 85px !important;
  }

  .pd-lg-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-lg-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-lg-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-lg-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-lg-t-85 {
    padding-top: 85px;
  }

  .pd-lg-r-85 {
    padding-right: 85px;
  }

  .pd-lg-b-85 {
    padding-bottom: 85px;
  }

  .pd-lg-l-85 {
    padding-left: 85px;
  }

  .pd-lg-t-85-f {
    padding-top: 85px !important;
  }

  .pd-lg-r-85-f {
    padding-right: 85px !important;
  }

  .pd-lg-b-85-f {
    padding-bottom: 85px !important;
  }

  .pd-lg-l-85-f {
    padding-left: 85px !important;
  }

  .pd-lg-90 {
    padding: 90px;
  }

  .pd-lg-90-f {
    padding: 90px !important;
  }

  .pd-lg-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-lg-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-lg-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-lg-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-lg-t-90 {
    padding-top: 90px;
  }

  .pd-lg-r-90 {
    padding-right: 90px;
  }

  .pd-lg-b-90 {
    padding-bottom: 90px;
  }

  .pd-lg-l-90 {
    padding-left: 90px;
  }

  .pd-lg-t-90-f {
    padding-top: 90px !important;
  }

  .pd-lg-r-90-f {
    padding-right: 90px !important;
  }

  .pd-lg-b-90-f {
    padding-bottom: 90px !important;
  }

  .pd-lg-l-90-f {
    padding-left: 90px !important;
  }

  .pd-lg-95 {
    padding: 95px;
  }

  .pd-lg-95-f {
    padding: 95px !important;
  }

  .pd-lg-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-lg-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-lg-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-lg-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-lg-t-95 {
    padding-top: 95px;
  }

  .pd-lg-r-95 {
    padding-right: 95px;
  }

  .pd-lg-b-95 {
    padding-bottom: 95px;
  }

  .pd-lg-l-95 {
    padding-left: 95px;
  }

  .pd-lg-t-95-f {
    padding-top: 95px !important;
  }

  .pd-lg-r-95-f {
    padding-right: 95px !important;
  }

  .pd-lg-b-95-f {
    padding-bottom: 95px !important;
  }

  .pd-lg-l-95-f {
    padding-left: 95px !important;
  }

  .pd-lg-100 {
    padding: 100px;
  }

  .pd-lg-100-f {
    padding: 100px !important;
  }

  .pd-lg-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-lg-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-lg-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-lg-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-lg-t-100 {
    padding-top: 100px;
  }

  .pd-lg-r-100 {
    padding-right: 100px;
  }

  .pd-lg-b-100 {
    padding-bottom: 100px;
  }

  .pd-lg-l-100 {
    padding-left: 100px;
  }

  .pd-lg-t-100-f {
    padding-top: 100px !important;
  }

  .pd-lg-r-100-f {
    padding-right: 100px !important;
  }

  .pd-lg-b-100-f {
    padding-bottom: 100px !important;
  }

  .pd-lg-l-100-f {
    padding-left: 100px !important;
  }

  .pd-lg-110 {
    padding: 110px;
  }

  .pd-lg-110-f {
    padding: 110px !important;
  }

  .pd-lg-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-lg-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-lg-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-lg-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-lg-t-110 {
    padding-top: 110px;
  }

  .pd-lg-r-110 {
    padding-right: 110px;
  }

  .pd-lg-b-110 {
    padding-bottom: 110px;
  }

  .pd-lg-l-110 {
    padding-left: 110px;
  }

  .pd-lg-t-110-f {
    padding-top: 110px !important;
  }

  .pd-lg-r-110-f {
    padding-right: 110px !important;
  }

  .pd-lg-b-110-f {
    padding-bottom: 110px !important;
  }

  .pd-lg-l-110-f {
    padding-left: 110px !important;
  }

  .pd-lg-120 {
    padding: 120px;
  }

  .pd-lg-120-f {
    padding: 120px !important;
  }

  .pd-lg-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-lg-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-lg-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-lg-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-lg-t-120 {
    padding-top: 120px;
  }

  .pd-lg-r-120 {
    padding-right: 120px;
  }

  .pd-lg-b-120 {
    padding-bottom: 120px;
  }

  .pd-lg-l-120 {
    padding-left: 120px;
  }

  .pd-lg-t-120-f {
    padding-top: 120px !important;
  }

  .pd-lg-r-120-f {
    padding-right: 120px !important;
  }

  .pd-lg-b-120-f {
    padding-bottom: 120px !important;
  }

  .pd-lg-l-120-f {
    padding-left: 120px !important;
  }

  .pd-lg-130 {
    padding: 130px;
  }

  .pd-lg-130-f {
    padding: 130px !important;
  }

  .pd-lg-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-lg-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-lg-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-lg-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-lg-t-130 {
    padding-top: 130px;
  }

  .pd-lg-r-130 {
    padding-right: 130px;
  }

  .pd-lg-b-130 {
    padding-bottom: 130px;
  }

  .pd-lg-l-130 {
    padding-left: 130px;
  }

  .pd-lg-t-130-f {
    padding-top: 130px !important;
  }

  .pd-lg-r-130-f {
    padding-right: 130px !important;
  }

  .pd-lg-b-130-f {
    padding-bottom: 130px !important;
  }

  .pd-lg-l-130-f {
    padding-left: 130px !important;
  }

  .pd-lg-140 {
    padding: 140px;
  }

  .pd-lg-140-f {
    padding: 140px !important;
  }

  .pd-lg-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-lg-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-lg-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-lg-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-lg-t-140 {
    padding-top: 140px;
  }

  .pd-lg-r-140 {
    padding-right: 140px;
  }

  .pd-lg-b-140 {
    padding-bottom: 140px;
  }

  .pd-lg-l-140 {
    padding-left: 140px;
  }

  .pd-lg-t-140-f {
    padding-top: 140px !important;
  }

  .pd-lg-r-140-f {
    padding-right: 140px !important;
  }

  .pd-lg-b-140-f {
    padding-bottom: 140px !important;
  }

  .pd-lg-l-140-f {
    padding-left: 140px !important;
  }

  .pd-lg-150 {
    padding: 150px;
  }

  .pd-lg-150-f {
    padding: 150px !important;
  }

  .pd-lg-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-lg-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-lg-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-lg-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-lg-t-150 {
    padding-top: 150px;
  }

  .pd-lg-r-150 {
    padding-right: 150px;
  }

  .pd-lg-b-150 {
    padding-bottom: 150px;
  }

  .pd-lg-l-150 {
    padding-left: 150px;
  }

  .pd-lg-t-150-f {
    padding-top: 150px !important;
  }

  .pd-lg-r-150-f {
    padding-right: 150px !important;
  }

  .pd-lg-b-150-f {
    padding-bottom: 150px !important;
  }

  .pd-lg-l-150-f {
    padding-left: 150px !important;
  }

  .pd-lg-160 {
    padding: 160px;
  }

  .pd-lg-160-f {
    padding: 160px !important;
  }

  .pd-lg-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-lg-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-lg-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-lg-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-lg-t-160 {
    padding-top: 160px;
  }

  .pd-lg-r-160 {
    padding-right: 160px;
  }

  .pd-lg-b-160 {
    padding-bottom: 160px;
  }

  .pd-lg-l-160 {
    padding-left: 160px;
  }

  .pd-lg-t-160-f {
    padding-top: 160px !important;
  }

  .pd-lg-r-160-f {
    padding-right: 160px !important;
  }

  .pd-lg-b-160-f {
    padding-bottom: 160px !important;
  }

  .pd-lg-l-160-f {
    padding-left: 160px !important;
  }

  .pd-lg-170 {
    padding: 170px;
  }

  .pd-lg-170-f {
    padding: 170px !important;
  }

  .pd-lg-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-lg-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-lg-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-lg-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-lg-t-170 {
    padding-top: 170px;
  }

  .pd-lg-r-170 {
    padding-right: 170px;
  }

  .pd-lg-b-170 {
    padding-bottom: 170px;
  }

  .pd-lg-l-170 {
    padding-left: 170px;
  }

  .pd-lg-t-170-f {
    padding-top: 170px !important;
  }

  .pd-lg-r-170-f {
    padding-right: 170px !important;
  }

  .pd-lg-b-170-f {
    padding-bottom: 170px !important;
  }

  .pd-lg-l-170-f {
    padding-left: 170px !important;
  }

  .pd-lg-180 {
    padding: 180px;
  }

  .pd-lg-180-f {
    padding: 180px !important;
  }

  .pd-lg-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-lg-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-lg-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-lg-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-lg-t-180 {
    padding-top: 180px;
  }

  .pd-lg-r-180 {
    padding-right: 180px;
  }

  .pd-lg-b-180 {
    padding-bottom: 180px;
  }

  .pd-lg-l-180 {
    padding-left: 180px;
  }

  .pd-lg-t-180-f {
    padding-top: 180px !important;
  }

  .pd-lg-r-180-f {
    padding-right: 180px !important;
  }

  .pd-lg-b-180-f {
    padding-bottom: 180px !important;
  }

  .pd-lg-l-180-f {
    padding-left: 180px !important;
  }

  .pd-lg-190 {
    padding: 190px;
  }

  .pd-lg-190-f {
    padding: 190px !important;
  }

  .pd-lg-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-lg-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-lg-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-lg-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-lg-t-190 {
    padding-top: 190px;
  }

  .pd-lg-r-190 {
    padding-right: 190px;
  }

  .pd-lg-b-190 {
    padding-bottom: 190px;
  }

  .pd-lg-l-190 {
    padding-left: 190px;
  }

  .pd-lg-t-190-f {
    padding-top: 190px !important;
  }

  .pd-lg-r-190-f {
    padding-right: 190px !important;
  }

  .pd-lg-b-190-f {
    padding-bottom: 190px !important;
  }

  .pd-lg-l-190-f {
    padding-left: 190px !important;
  }

  .pd-lg-200 {
    padding: 200px;
  }

  .pd-lg-200-f {
    padding: 200px !important;
  }

  .pd-lg-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-lg-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-lg-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-lg-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-lg-t-200 {
    padding-top: 200px;
  }

  .pd-lg-r-200 {
    padding-right: 200px;
  }

  .pd-lg-b-200 {
    padding-bottom: 200px;
  }

  .pd-lg-l-200 {
    padding-left: 200px;
  }

  .pd-lg-t-200-f {
    padding-top: 200px !important;
  }

  .pd-lg-r-200-f {
    padding-right: 200px !important;
  }

  .pd-lg-b-200-f {
    padding-bottom: 200px !important;
  }

  .pd-lg-l-200-f {
    padding-left: 200px !important;
  }
}
@media (min-width: 1200px) {
  .pd-xl-0 {
    padding: 0px;
  }

  .pd-xl-0-f {
    padding: 0px !important;
  }

  .pd-xl-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-xl-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-xl-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-xl-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-xl-t-0 {
    padding-top: 0px;
  }

  .pd-xl-r-0 {
    padding-right: 0px;
  }

  .pd-xl-b-0 {
    padding-bottom: 0px;
  }

  .pd-xl-l-0 {
    padding-left: 0px;
  }

  .pd-xl-t-0-f {
    padding-top: 0px !important;
  }

  .pd-xl-r-0-f {
    padding-right: 0px !important;
  }

  .pd-xl-b-0-f {
    padding-bottom: 0px !important;
  }

  .pd-xl-l-0-f {
    padding-left: 0px !important;
  }

  .pd-xl-1 {
    padding: 1px;
  }

  .pd-xl-1-f {
    padding: 1px !important;
  }

  .pd-xl-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-xl-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-xl-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-xl-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-xl-t-1 {
    padding-top: 1px;
  }

  .pd-xl-r-1 {
    padding-right: 1px;
  }

  .pd-xl-b-1 {
    padding-bottom: 1px;
  }

  .pd-xl-l-1 {
    padding-left: 1px;
  }

  .pd-xl-t-1-f {
    padding-top: 1px !important;
  }

  .pd-xl-r-1-f {
    padding-right: 1px !important;
  }

  .pd-xl-b-1-f {
    padding-bottom: 1px !important;
  }

  .pd-xl-l-1-f {
    padding-left: 1px !important;
  }

  .pd-xl-2 {
    padding: 2px;
  }

  .pd-xl-2-f {
    padding: 2px !important;
  }

  .pd-xl-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-xl-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-xl-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-xl-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-xl-t-2 {
    padding-top: 2px;
  }

  .pd-xl-r-2 {
    padding-right: 2px;
  }

  .pd-xl-b-2 {
    padding-bottom: 2px;
  }

  .pd-xl-l-2 {
    padding-left: 2px;
  }

  .pd-xl-t-2-f {
    padding-top: 2px !important;
  }

  .pd-xl-r-2-f {
    padding-right: 2px !important;
  }

  .pd-xl-b-2-f {
    padding-bottom: 2px !important;
  }

  .pd-xl-l-2-f {
    padding-left: 2px !important;
  }

  .pd-xl-3 {
    padding: 3px;
  }

  .pd-xl-3-f {
    padding: 3px !important;
  }

  .pd-xl-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-xl-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-xl-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-xl-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-xl-t-3 {
    padding-top: 3px;
  }

  .pd-xl-r-3 {
    padding-right: 3px;
  }

  .pd-xl-b-3 {
    padding-bottom: 3px;
  }

  .pd-xl-l-3 {
    padding-left: 3px;
  }

  .pd-xl-t-3-f {
    padding-top: 3px !important;
  }

  .pd-xl-r-3-f {
    padding-right: 3px !important;
  }

  .pd-xl-b-3-f {
    padding-bottom: 3px !important;
  }

  .pd-xl-l-3-f {
    padding-left: 3px !important;
  }

  .pd-xl-4 {
    padding: 4px;
  }

  .pd-xl-4-f {
    padding: 4px !important;
  }

  .pd-xl-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-xl-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-xl-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-xl-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-xl-t-4 {
    padding-top: 4px;
  }

  .pd-xl-r-4 {
    padding-right: 4px;
  }

  .pd-xl-b-4 {
    padding-bottom: 4px;
  }

  .pd-xl-l-4 {
    padding-left: 4px;
  }

  .pd-xl-t-4-f {
    padding-top: 4px !important;
  }

  .pd-xl-r-4-f {
    padding-right: 4px !important;
  }

  .pd-xl-b-4-f {
    padding-bottom: 4px !important;
  }

  .pd-xl-l-4-f {
    padding-left: 4px !important;
  }

  .pd-xl-5 {
    padding: 5px;
  }

  .pd-xl-5-f {
    padding: 5px !important;
  }

  .pd-xl-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-xl-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-xl-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-xl-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-xl-t-5 {
    padding-top: 5px;
  }

  .pd-xl-r-5 {
    padding-right: 5px;
  }

  .pd-xl-b-5 {
    padding-bottom: 5px;
  }

  .pd-xl-l-5 {
    padding-left: 5px;
  }

  .pd-xl-t-5-f {
    padding-top: 5px !important;
  }

  .pd-xl-r-5-f {
    padding-right: 5px !important;
  }

  .pd-xl-b-5-f {
    padding-bottom: 5px !important;
  }

  .pd-xl-l-5-f {
    padding-left: 5px !important;
  }

  .pd-xl-6 {
    padding: 6px;
  }

  .pd-xl-6-f {
    padding: 6px !important;
  }

  .pd-xl-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-xl-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-xl-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-xl-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-xl-t-6 {
    padding-top: 6px;
  }

  .pd-xl-r-6 {
    padding-right: 6px;
  }

  .pd-xl-b-6 {
    padding-bottom: 6px;
  }

  .pd-xl-l-6 {
    padding-left: 6px;
  }

  .pd-xl-t-6-f {
    padding-top: 6px !important;
  }

  .pd-xl-r-6-f {
    padding-right: 6px !important;
  }

  .pd-xl-b-6-f {
    padding-bottom: 6px !important;
  }

  .pd-xl-l-6-f {
    padding-left: 6px !important;
  }

  .pd-xl-7 {
    padding: 7px;
  }

  .pd-xl-7-f {
    padding: 7px !important;
  }

  .pd-xl-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-xl-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-xl-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-xl-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-xl-t-7 {
    padding-top: 7px;
  }

  .pd-xl-r-7 {
    padding-right: 7px;
  }

  .pd-xl-b-7 {
    padding-bottom: 7px;
  }

  .pd-xl-l-7 {
    padding-left: 7px;
  }

  .pd-xl-t-7-f {
    padding-top: 7px !important;
  }

  .pd-xl-r-7-f {
    padding-right: 7px !important;
  }

  .pd-xl-b-7-f {
    padding-bottom: 7px !important;
  }

  .pd-xl-l-7-f {
    padding-left: 7px !important;
  }

  .pd-xl-8 {
    padding: 8px;
  }

  .pd-xl-8-f {
    padding: 8px !important;
  }

  .pd-xl-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-xl-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-xl-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-xl-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-xl-t-8 {
    padding-top: 8px;
  }

  .pd-xl-r-8 {
    padding-right: 8px;
  }

  .pd-xl-b-8 {
    padding-bottom: 8px;
  }

  .pd-xl-l-8 {
    padding-left: 8px;
  }

  .pd-xl-t-8-f {
    padding-top: 8px !important;
  }

  .pd-xl-r-8-f {
    padding-right: 8px !important;
  }

  .pd-xl-b-8-f {
    padding-bottom: 8px !important;
  }

  .pd-xl-l-8-f {
    padding-left: 8px !important;
  }

  .pd-xl-9 {
    padding: 9px;
  }

  .pd-xl-9-f {
    padding: 9px !important;
  }

  .pd-xl-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-xl-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-xl-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-xl-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-xl-t-9 {
    padding-top: 9px;
  }

  .pd-xl-r-9 {
    padding-right: 9px;
  }

  .pd-xl-b-9 {
    padding-bottom: 9px;
  }

  .pd-xl-l-9 {
    padding-left: 9px;
  }

  .pd-xl-t-9-f {
    padding-top: 9px !important;
  }

  .pd-xl-r-9-f {
    padding-right: 9px !important;
  }

  .pd-xl-b-9-f {
    padding-bottom: 9px !important;
  }

  .pd-xl-l-9-f {
    padding-left: 9px !important;
  }

  .pd-xl-10 {
    padding: 10px;
  }

  .pd-xl-10-f {
    padding: 10px !important;
  }

  .pd-xl-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-xl-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-xl-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-xl-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-xl-t-10 {
    padding-top: 10px;
  }

  .pd-xl-r-10 {
    padding-right: 10px;
  }

  .pd-xl-b-10 {
    padding-bottom: 10px;
  }

  .pd-xl-l-10 {
    padding-left: 10px;
  }

  .pd-xl-t-10-f {
    padding-top: 10px !important;
  }

  .pd-xl-r-10-f {
    padding-right: 10px !important;
  }

  .pd-xl-b-10-f {
    padding-bottom: 10px !important;
  }

  .pd-xl-l-10-f {
    padding-left: 10px !important;
  }

  .pd-xl-15 {
    padding: 15px;
  }

  .pd-xl-15-f {
    padding: 15px !important;
  }

  .pd-xl-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-xl-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-xl-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-xl-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-xl-t-15 {
    padding-top: 15px;
  }

  .pd-xl-r-15 {
    padding-right: 15px;
  }

  .pd-xl-b-15 {
    padding-bottom: 15px;
  }

  .pd-xl-l-15 {
    padding-left: 15px;
  }

  .pd-xl-t-15-f {
    padding-top: 15px !important;
  }

  .pd-xl-r-15-f {
    padding-right: 15px !important;
  }

  .pd-xl-b-15-f {
    padding-bottom: 15px !important;
  }

  .pd-xl-l-15-f {
    padding-left: 15px !important;
  }

  .pd-xl-20 {
    padding: 20px;
  }

  .pd-xl-20-f {
    padding: 20px !important;
  }

  .pd-xl-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-xl-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-xl-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-xl-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-xl-t-20 {
    padding-top: 20px;
  }

  .pd-xl-r-20 {
    padding-right: 20px;
  }

  .pd-xl-b-20 {
    padding-bottom: 20px;
  }

  .pd-xl-l-20 {
    padding-left: 20px;
  }

  .pd-xl-t-20-f {
    padding-top: 20px !important;
  }

  .pd-xl-r-20-f {
    padding-right: 20px !important;
  }

  .pd-xl-b-20-f {
    padding-bottom: 20px !important;
  }

  .pd-xl-l-20-f {
    padding-left: 20px !important;
  }

  .pd-xl-25 {
    padding: 25px;
  }

  .pd-xl-25-f {
    padding: 25px !important;
  }

  .pd-xl-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-xl-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-xl-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-xl-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-xl-t-25 {
    padding-top: 25px;
  }

  .pd-xl-r-25 {
    padding-right: 25px;
  }

  .pd-xl-b-25 {
    padding-bottom: 25px;
  }

  .pd-xl-l-25 {
    padding-left: 25px;
  }

  .pd-xl-t-25-f {
    padding-top: 25px !important;
  }

  .pd-xl-r-25-f {
    padding-right: 25px !important;
  }

  .pd-xl-b-25-f {
    padding-bottom: 25px !important;
  }

  .pd-xl-l-25-f {
    padding-left: 25px !important;
  }

  .pd-xl-30 {
    padding: 30px;
  }

  .pd-xl-30-f {
    padding: 30px !important;
  }

  .pd-xl-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-xl-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-xl-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-xl-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-xl-t-30 {
    padding-top: 30px;
  }

  .pd-xl-r-30 {
    padding-right: 30px;
  }

  .pd-xl-b-30 {
    padding-bottom: 30px;
  }

  .pd-xl-l-30 {
    padding-left: 30px;
  }

  .pd-xl-t-30-f {
    padding-top: 30px !important;
  }

  .pd-xl-r-30-f {
    padding-right: 30px !important;
  }

  .pd-xl-b-30-f {
    padding-bottom: 30px !important;
  }

  .pd-xl-l-30-f {
    padding-left: 30px !important;
  }

  .pd-xl-35 {
    padding: 35px;
  }

  .pd-xl-35-f {
    padding: 35px !important;
  }

  .pd-xl-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-xl-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-xl-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-xl-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-xl-t-35 {
    padding-top: 35px;
  }

  .pd-xl-r-35 {
    padding-right: 35px;
  }

  .pd-xl-b-35 {
    padding-bottom: 35px;
  }

  .pd-xl-l-35 {
    padding-left: 35px;
  }

  .pd-xl-t-35-f {
    padding-top: 35px !important;
  }

  .pd-xl-r-35-f {
    padding-right: 35px !important;
  }

  .pd-xl-b-35-f {
    padding-bottom: 35px !important;
  }

  .pd-xl-l-35-f {
    padding-left: 35px !important;
  }

  .pd-xl-40 {
    padding: 40px;
  }

  .pd-xl-40-f {
    padding: 40px !important;
  }

  .pd-xl-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-xl-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-xl-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-xl-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-xl-t-40 {
    padding-top: 40px;
  }

  .pd-xl-r-40 {
    padding-right: 40px;
  }

  .pd-xl-b-40 {
    padding-bottom: 40px;
  }

  .pd-xl-l-40 {
    padding-left: 40px;
  }

  .pd-xl-t-40-f {
    padding-top: 40px !important;
  }

  .pd-xl-r-40-f {
    padding-right: 40px !important;
  }

  .pd-xl-b-40-f {
    padding-bottom: 40px !important;
  }

  .pd-xl-l-40-f {
    padding-left: 40px !important;
  }

  .pd-xl-45 {
    padding: 45px;
  }

  .pd-xl-45-f {
    padding: 45px !important;
  }

  .pd-xl-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-xl-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-xl-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-xl-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-xl-t-45 {
    padding-top: 45px;
  }

  .pd-xl-r-45 {
    padding-right: 45px;
  }

  .pd-xl-b-45 {
    padding-bottom: 45px;
  }

  .pd-xl-l-45 {
    padding-left: 45px;
  }

  .pd-xl-t-45-f {
    padding-top: 45px !important;
  }

  .pd-xl-r-45-f {
    padding-right: 45px !important;
  }

  .pd-xl-b-45-f {
    padding-bottom: 45px !important;
  }

  .pd-xl-l-45-f {
    padding-left: 45px !important;
  }

  .pd-xl-50 {
    padding: 50px;
  }

  .pd-xl-50-f {
    padding: 50px !important;
  }

  .pd-xl-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-xl-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-xl-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-xl-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-xl-t-50 {
    padding-top: 50px;
  }

  .pd-xl-r-50 {
    padding-right: 50px;
  }

  .pd-xl-b-50 {
    padding-bottom: 50px;
  }

  .pd-xl-l-50 {
    padding-left: 50px;
  }

  .pd-xl-t-50-f {
    padding-top: 50px !important;
  }

  .pd-xl-r-50-f {
    padding-right: 50px !important;
  }

  .pd-xl-b-50-f {
    padding-bottom: 50px !important;
  }

  .pd-xl-l-50-f {
    padding-left: 50px !important;
  }

  .pd-xl-55 {
    padding: 55px;
  }

  .pd-xl-55-f {
    padding: 55px !important;
  }

  .pd-xl-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-xl-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-xl-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-xl-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-xl-t-55 {
    padding-top: 55px;
  }

  .pd-xl-r-55 {
    padding-right: 55px;
  }

  .pd-xl-b-55 {
    padding-bottom: 55px;
  }

  .pd-xl-l-55 {
    padding-left: 55px;
  }

  .pd-xl-t-55-f {
    padding-top: 55px !important;
  }

  .pd-xl-r-55-f {
    padding-right: 55px !important;
  }

  .pd-xl-b-55-f {
    padding-bottom: 55px !important;
  }

  .pd-xl-l-55-f {
    padding-left: 55px !important;
  }

  .pd-xl-60 {
    padding: 60px;
  }

  .pd-xl-60-f {
    padding: 60px !important;
  }

  .pd-xl-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-xl-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-xl-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-xl-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-xl-t-60 {
    padding-top: 60px;
  }

  .pd-xl-r-60 {
    padding-right: 60px;
  }

  .pd-xl-b-60 {
    padding-bottom: 60px;
  }

  .pd-xl-l-60 {
    padding-left: 60px;
  }

  .pd-xl-t-60-f {
    padding-top: 60px !important;
  }

  .pd-xl-r-60-f {
    padding-right: 60px !important;
  }

  .pd-xl-b-60-f {
    padding-bottom: 60px !important;
  }

  .pd-xl-l-60-f {
    padding-left: 60px !important;
  }

  .pd-xl-65 {
    padding: 65px;
  }

  .pd-xl-65-f {
    padding: 65px !important;
  }

  .pd-xl-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-xl-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-xl-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-xl-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-xl-t-65 {
    padding-top: 65px;
  }

  .pd-xl-r-65 {
    padding-right: 65px;
  }

  .pd-xl-b-65 {
    padding-bottom: 65px;
  }

  .pd-xl-l-65 {
    padding-left: 65px;
  }

  .pd-xl-t-65-f {
    padding-top: 65px !important;
  }

  .pd-xl-r-65-f {
    padding-right: 65px !important;
  }

  .pd-xl-b-65-f {
    padding-bottom: 65px !important;
  }

  .pd-xl-l-65-f {
    padding-left: 65px !important;
  }

  .pd-xl-70 {
    padding: 70px;
  }

  .pd-xl-70-f {
    padding: 70px !important;
  }

  .pd-xl-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-xl-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-xl-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-xl-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-xl-t-70 {
    padding-top: 70px;
  }

  .pd-xl-r-70 {
    padding-right: 70px;
  }

  .pd-xl-b-70 {
    padding-bottom: 70px;
  }

  .pd-xl-l-70 {
    padding-left: 70px;
  }

  .pd-xl-t-70-f {
    padding-top: 70px !important;
  }

  .pd-xl-r-70-f {
    padding-right: 70px !important;
  }

  .pd-xl-b-70-f {
    padding-bottom: 70px !important;
  }

  .pd-xl-l-70-f {
    padding-left: 70px !important;
  }

  .pd-xl-75 {
    padding: 75px;
  }

  .pd-xl-75-f {
    padding: 75px !important;
  }

  .pd-xl-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-xl-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-xl-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-xl-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-xl-t-75 {
    padding-top: 75px;
  }

  .pd-xl-r-75 {
    padding-right: 75px;
  }

  .pd-xl-b-75 {
    padding-bottom: 75px;
  }

  .pd-xl-l-75 {
    padding-left: 75px;
  }

  .pd-xl-t-75-f {
    padding-top: 75px !important;
  }

  .pd-xl-r-75-f {
    padding-right: 75px !important;
  }

  .pd-xl-b-75-f {
    padding-bottom: 75px !important;
  }

  .pd-xl-l-75-f {
    padding-left: 75px !important;
  }

  .pd-xl-80 {
    padding: 80px;
  }

  .pd-xl-80-f {
    padding: 80px !important;
  }

  .pd-xl-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-xl-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-xl-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-xl-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-xl-t-80 {
    padding-top: 80px;
  }

  .pd-xl-r-80 {
    padding-right: 80px;
  }

  .pd-xl-b-80 {
    padding-bottom: 80px;
  }

  .pd-xl-l-80 {
    padding-left: 80px;
  }

  .pd-xl-t-80-f {
    padding-top: 80px !important;
  }

  .pd-xl-r-80-f {
    padding-right: 80px !important;
  }

  .pd-xl-b-80-f {
    padding-bottom: 80px !important;
  }

  .pd-xl-l-80-f {
    padding-left: 80px !important;
  }

  .pd-xl-85 {
    padding: 85px;
  }

  .pd-xl-85-f {
    padding: 85px !important;
  }

  .pd-xl-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-xl-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-xl-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-xl-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-xl-t-85 {
    padding-top: 85px;
  }

  .pd-xl-r-85 {
    padding-right: 85px;
  }

  .pd-xl-b-85 {
    padding-bottom: 85px;
  }

  .pd-xl-l-85 {
    padding-left: 85px;
  }

  .pd-xl-t-85-f {
    padding-top: 85px !important;
  }

  .pd-xl-r-85-f {
    padding-right: 85px !important;
  }

  .pd-xl-b-85-f {
    padding-bottom: 85px !important;
  }

  .pd-xl-l-85-f {
    padding-left: 85px !important;
  }

  .pd-xl-90 {
    padding: 90px;
  }

  .pd-xl-90-f {
    padding: 90px !important;
  }

  .pd-xl-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-xl-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-xl-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-xl-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-xl-t-90 {
    padding-top: 90px;
  }

  .pd-xl-r-90 {
    padding-right: 90px;
  }

  .pd-xl-b-90 {
    padding-bottom: 90px;
  }

  .pd-xl-l-90 {
    padding-left: 90px;
  }

  .pd-xl-t-90-f {
    padding-top: 90px !important;
  }

  .pd-xl-r-90-f {
    padding-right: 90px !important;
  }

  .pd-xl-b-90-f {
    padding-bottom: 90px !important;
  }

  .pd-xl-l-90-f {
    padding-left: 90px !important;
  }

  .pd-xl-95 {
    padding: 95px;
  }

  .pd-xl-95-f {
    padding: 95px !important;
  }

  .pd-xl-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-xl-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-xl-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-xl-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-xl-t-95 {
    padding-top: 95px;
  }

  .pd-xl-r-95 {
    padding-right: 95px;
  }

  .pd-xl-b-95 {
    padding-bottom: 95px;
  }

  .pd-xl-l-95 {
    padding-left: 95px;
  }

  .pd-xl-t-95-f {
    padding-top: 95px !important;
  }

  .pd-xl-r-95-f {
    padding-right: 95px !important;
  }

  .pd-xl-b-95-f {
    padding-bottom: 95px !important;
  }

  .pd-xl-l-95-f {
    padding-left: 95px !important;
  }

  .pd-xl-100 {
    padding: 100px;
  }

  .pd-xl-100-f {
    padding: 100px !important;
  }

  .pd-xl-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-xl-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-xl-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-xl-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-xl-t-100 {
    padding-top: 100px;
  }

  .pd-xl-r-100 {
    padding-right: 100px;
  }

  .pd-xl-b-100 {
    padding-bottom: 100px;
  }

  .pd-xl-l-100 {
    padding-left: 100px;
  }

  .pd-xl-t-100-f {
    padding-top: 100px !important;
  }

  .pd-xl-r-100-f {
    padding-right: 100px !important;
  }

  .pd-xl-b-100-f {
    padding-bottom: 100px !important;
  }

  .pd-xl-l-100-f {
    padding-left: 100px !important;
  }

  .pd-xl-110 {
    padding: 110px;
  }

  .pd-xl-110-f {
    padding: 110px !important;
  }

  .pd-xl-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-xl-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-xl-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-xl-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-xl-t-110 {
    padding-top: 110px;
  }

  .pd-xl-r-110 {
    padding-right: 110px;
  }

  .pd-xl-b-110 {
    padding-bottom: 110px;
  }

  .pd-xl-l-110 {
    padding-left: 110px;
  }

  .pd-xl-t-110-f {
    padding-top: 110px !important;
  }

  .pd-xl-r-110-f {
    padding-right: 110px !important;
  }

  .pd-xl-b-110-f {
    padding-bottom: 110px !important;
  }

  .pd-xl-l-110-f {
    padding-left: 110px !important;
  }

  .pd-xl-120 {
    padding: 120px;
  }

  .pd-xl-120-f {
    padding: 120px !important;
  }

  .pd-xl-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-xl-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-xl-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-xl-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-xl-t-120 {
    padding-top: 120px;
  }

  .pd-xl-r-120 {
    padding-right: 120px;
  }

  .pd-xl-b-120 {
    padding-bottom: 120px;
  }

  .pd-xl-l-120 {
    padding-left: 120px;
  }

  .pd-xl-t-120-f {
    padding-top: 120px !important;
  }

  .pd-xl-r-120-f {
    padding-right: 120px !important;
  }

  .pd-xl-b-120-f {
    padding-bottom: 120px !important;
  }

  .pd-xl-l-120-f {
    padding-left: 120px !important;
  }

  .pd-xl-130 {
    padding: 130px;
  }

  .pd-xl-130-f {
    padding: 130px !important;
  }

  .pd-xl-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-xl-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-xl-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-xl-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-xl-t-130 {
    padding-top: 130px;
  }

  .pd-xl-r-130 {
    padding-right: 130px;
  }

  .pd-xl-b-130 {
    padding-bottom: 130px;
  }

  .pd-xl-l-130 {
    padding-left: 130px;
  }

  .pd-xl-t-130-f {
    padding-top: 130px !important;
  }

  .pd-xl-r-130-f {
    padding-right: 130px !important;
  }

  .pd-xl-b-130-f {
    padding-bottom: 130px !important;
  }

  .pd-xl-l-130-f {
    padding-left: 130px !important;
  }

  .pd-xl-140 {
    padding: 140px;
  }

  .pd-xl-140-f {
    padding: 140px !important;
  }

  .pd-xl-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-xl-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-xl-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-xl-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-xl-t-140 {
    padding-top: 140px;
  }

  .pd-xl-r-140 {
    padding-right: 140px;
  }

  .pd-xl-b-140 {
    padding-bottom: 140px;
  }

  .pd-xl-l-140 {
    padding-left: 140px;
  }

  .pd-xl-t-140-f {
    padding-top: 140px !important;
  }

  .pd-xl-r-140-f {
    padding-right: 140px !important;
  }

  .pd-xl-b-140-f {
    padding-bottom: 140px !important;
  }

  .pd-xl-l-140-f {
    padding-left: 140px !important;
  }

  .pd-xl-150 {
    padding: 150px;
  }

  .pd-xl-150-f {
    padding: 150px !important;
  }

  .pd-xl-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-xl-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-xl-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-xl-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-xl-t-150 {
    padding-top: 150px;
  }

  .pd-xl-r-150 {
    padding-right: 150px;
  }

  .pd-xl-b-150 {
    padding-bottom: 150px;
  }

  .pd-xl-l-150 {
    padding-left: 150px;
  }

  .pd-xl-t-150-f {
    padding-top: 150px !important;
  }

  .pd-xl-r-150-f {
    padding-right: 150px !important;
  }

  .pd-xl-b-150-f {
    padding-bottom: 150px !important;
  }

  .pd-xl-l-150-f {
    padding-left: 150px !important;
  }

  .pd-xl-160 {
    padding: 160px;
  }

  .pd-xl-160-f {
    padding: 160px !important;
  }

  .pd-xl-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-xl-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-xl-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-xl-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-xl-t-160 {
    padding-top: 160px;
  }

  .pd-xl-r-160 {
    padding-right: 160px;
  }

  .pd-xl-b-160 {
    padding-bottom: 160px;
  }

  .pd-xl-l-160 {
    padding-left: 160px;
  }

  .pd-xl-t-160-f {
    padding-top: 160px !important;
  }

  .pd-xl-r-160-f {
    padding-right: 160px !important;
  }

  .pd-xl-b-160-f {
    padding-bottom: 160px !important;
  }

  .pd-xl-l-160-f {
    padding-left: 160px !important;
  }

  .pd-xl-170 {
    padding: 170px;
  }

  .pd-xl-170-f {
    padding: 170px !important;
  }

  .pd-xl-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-xl-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-xl-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-xl-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-xl-t-170 {
    padding-top: 170px;
  }

  .pd-xl-r-170 {
    padding-right: 170px;
  }

  .pd-xl-b-170 {
    padding-bottom: 170px;
  }

  .pd-xl-l-170 {
    padding-left: 170px;
  }

  .pd-xl-t-170-f {
    padding-top: 170px !important;
  }

  .pd-xl-r-170-f {
    padding-right: 170px !important;
  }

  .pd-xl-b-170-f {
    padding-bottom: 170px !important;
  }

  .pd-xl-l-170-f {
    padding-left: 170px !important;
  }

  .pd-xl-180 {
    padding: 180px;
  }

  .pd-xl-180-f {
    padding: 180px !important;
  }

  .pd-xl-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-xl-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-xl-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-xl-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-xl-t-180 {
    padding-top: 180px;
  }

  .pd-xl-r-180 {
    padding-right: 180px;
  }

  .pd-xl-b-180 {
    padding-bottom: 180px;
  }

  .pd-xl-l-180 {
    padding-left: 180px;
  }

  .pd-xl-t-180-f {
    padding-top: 180px !important;
  }

  .pd-xl-r-180-f {
    padding-right: 180px !important;
  }

  .pd-xl-b-180-f {
    padding-bottom: 180px !important;
  }

  .pd-xl-l-180-f {
    padding-left: 180px !important;
  }

  .pd-xl-190 {
    padding: 190px;
  }

  .pd-xl-190-f {
    padding: 190px !important;
  }

  .pd-xl-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-xl-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-xl-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-xl-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-xl-t-190 {
    padding-top: 190px;
  }

  .pd-xl-r-190 {
    padding-right: 190px;
  }

  .pd-xl-b-190 {
    padding-bottom: 190px;
  }

  .pd-xl-l-190 {
    padding-left: 190px;
  }

  .pd-xl-t-190-f {
    padding-top: 190px !important;
  }

  .pd-xl-r-190-f {
    padding-right: 190px !important;
  }

  .pd-xl-b-190-f {
    padding-bottom: 190px !important;
  }

  .pd-xl-l-190-f {
    padding-left: 190px !important;
  }

  .pd-xl-200 {
    padding: 200px;
  }

  .pd-xl-200-f {
    padding: 200px !important;
  }

  .pd-xl-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-xl-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-xl-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-xl-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-xl-t-200 {
    padding-top: 200px;
  }

  .pd-xl-r-200 {
    padding-right: 200px;
  }

  .pd-xl-b-200 {
    padding-bottom: 200px;
  }

  .pd-xl-l-200 {
    padding-left: 200px;
  }

  .pd-xl-t-200-f {
    padding-top: 200px !important;
  }

  .pd-xl-r-200-f {
    padding-right: 200px !important;
  }

  .pd-xl-b-200-f {
    padding-bottom: 200px !important;
  }

  .pd-xl-l-200-f {
    padding-left: 200px !important;
  }
}
.pd-12 {
  padding: 12px;
}

.pd-12-f {
  padding: 12px !important;
}

.pd-y-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pd-y-12-f {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pd-x-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.pd-x-12-f {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.pd-t-12 {
  padding-top: 12px;
}

.pd-r-12 {
  padding-right: 12px;
}

.pd-b-12 {
  padding-bottom: 12px;
}

.pd-l-12 {
  padding-left: 12px;
}

.pd-t-12-f {
  padding-top: 12px !important;
}

.pd-r-12-f {
  padding-right: 12px !important;
}

.pd-b-12-f {
  padding-bottom: 12px !important;
}

.pd-l-12-f {
  padding-left: 12px !important;
}

.pd-13 {
  padding: 13px;
}

.pd-13-f {
  padding: 13px !important;
}

.pd-y-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}

.pd-y-13-f {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pd-x-13 {
  padding-left: 13px;
  padding-right: 13px;
}

.pd-x-13-f {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.pd-t-13 {
  padding-top: 13px;
}

.pd-r-13 {
  padding-right: 13px;
}

.pd-b-13 {
  padding-bottom: 13px;
}

.pd-l-13 {
  padding-left: 13px;
}

.pd-t-13-f {
  padding-top: 13px !important;
}

.pd-r-13-f {
  padding-right: 13px !important;
}

.pd-b-13-f {
  padding-bottom: 13px !important;
}

.pd-l-13-f {
  padding-left: 13px !important;
}

/* ###### 9.7 Position   ###### */
.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

.pos-static {
  position: static;
}

.pos-absolute-f {
  position: absolute !important;
}

.pos-relative-f {
  position: relative !important;
}

.pos-fixed-f {
  position: fixed !important;
}

.pos-static-f {
  position: static !important;
}

.t-0 {
  top: 0px;
}

.r-0 {
  right: 0px;
}

.b-0 {
  bottom: 0px;
}

.l-0 {
  left: 0px;
}

.t--0 {
  top: -0px;
}

.r--0 {
  right: -0px;
}

.b--0 {
  bottom: -0px;
}

.l--0 {
  left: -0px;
}

.a-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.x-0 {
  left: 0px;
  right: 0px;
}

.y-0 {
  left: 0px;
  right: 0px;
}

.t-5 {
  top: 5px;
}

.r-5 {
  right: 5px;
}

.b-5 {
  bottom: 5px;
}

.l-5 {
  left: 5px;
}

.t--5 {
  top: -5px;
}

.r--5 {
  right: -5px;
}

.b--5 {
  bottom: -5px;
}

.l--5 {
  left: -5px;
}

.a-5 {
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.x-5 {
  left: 5px;
  right: 5px;
}

.y-5 {
  left: 5px;
  right: 5px;
}

.t-10 {
  top: 10px;
}

.r-10 {
  right: 10px;
}

.b-10 {
  bottom: 10px;
}

.l-10 {
  left: 10px;
}

.t--10 {
  top: -10px;
}

.r--10 {
  right: -10px;
}

.b--10 {
  bottom: -10px;
}

.l--10 {
  left: -10px;
}

.a-10 {
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
}

.x-10 {
  left: 10px;
  right: 10px;
}

.y-10 {
  left: 10px;
  right: 10px;
}

.t-15 {
  top: 15px;
}

.r-15 {
  right: 15px;
}

.b-15 {
  bottom: 15px;
}

.l-15 {
  left: 15px;
}

.t--15 {
  top: -15px;
}

.r--15 {
  right: -15px;
}

.b--15 {
  bottom: -15px;
}

.l--15 {
  left: -15px;
}

.a-15 {
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
}

.x-15 {
  left: 15px;
  right: 15px;
}

.y-15 {
  left: 15px;
  right: 15px;
}

.t-20 {
  top: 20px;
}

.r-20 {
  right: 20px;
}

.b-20 {
  bottom: 20px;
}

.l-20 {
  left: 20px;
}

.t--20 {
  top: -20px;
}

.r--20 {
  right: -20px;
}

.b--20 {
  bottom: -20px;
}

.l--20 {
  left: -20px;
}

.a-20 {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.x-20 {
  left: 20px;
  right: 20px;
}

.y-20 {
  left: 20px;
  right: 20px;
}

.t-25 {
  top: 25px;
}

.r-25 {
  right: 25px;
}

.b-25 {
  bottom: 25px;
}

.l-25 {
  left: 25px;
}

.t--25 {
  top: -25px;
}

.r--25 {
  right: -25px;
}

.b--25 {
  bottom: -25px;
}

.l--25 {
  left: -25px;
}

.a-25 {
  top: 25px;
  right: 25px;
  bottom: 25px;
  left: 25px;
}

.x-25 {
  left: 25px;
  right: 25px;
}

.y-25 {
  left: 25px;
  right: 25px;
}

.t-30 {
  top: 30px;
}

.r-30 {
  right: 30px;
}

.b-30 {
  bottom: 30px;
}

.l-30 {
  left: 30px;
}

.t--30 {
  top: -30px;
}

.r--30 {
  right: -30px;
}

.b--30 {
  bottom: -30px;
}

.l--30 {
  left: -30px;
}

.a-30 {
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
}

.x-30 {
  left: 30px;
  right: 30px;
}

.y-30 {
  left: 30px;
  right: 30px;
}

.t-35 {
  top: 35px;
}

.r-35 {
  right: 35px;
}

.b-35 {
  bottom: 35px;
}

.l-35 {
  left: 35px;
}

.t--35 {
  top: -35px;
}

.r--35 {
  right: -35px;
}

.b--35 {
  bottom: -35px;
}

.l--35 {
  left: -35px;
}

.a-35 {
  top: 35px;
  right: 35px;
  bottom: 35px;
  left: 35px;
}

.x-35 {
  left: 35px;
  right: 35px;
}

.y-35 {
  left: 35px;
  right: 35px;
}

.t-40 {
  top: 40px;
}

.r-40 {
  right: 40px;
}

.b-40 {
  bottom: 40px;
}

.l-40 {
  left: 40px;
}

.t--40 {
  top: -40px;
}

.r--40 {
  right: -40px;
}

.b--40 {
  bottom: -40px;
}

.l--40 {
  left: -40px;
}

.a-40 {
  top: 40px;
  right: 40px;
  bottom: 40px;
  left: 40px;
}

.x-40 {
  left: 40px;
  right: 40px;
}

.y-40 {
  left: 40px;
  right: 40px;
}

.t-45 {
  top: 45px;
}

.r-45 {
  right: 45px;
}

.b-45 {
  bottom: 45px;
}

.l-45 {
  left: 45px;
}

.t--45 {
  top: -45px;
}

.r--45 {
  right: -45px;
}

.b--45 {
  bottom: -45px;
}

.l--45 {
  left: -45px;
}

.a-45 {
  top: 45px;
  right: 45px;
  bottom: 45px;
  left: 45px;
}

.x-45 {
  left: 45px;
  right: 45px;
}

.y-45 {
  left: 45px;
  right: 45px;
}

.t-50 {
  top: 50px;
}

.r-50 {
  right: 50px;
}

.b-50 {
  bottom: 50px;
}

.l-50 {
  left: 50px;
}

.t--50 {
  top: -50px;
}

.r--50 {
  right: -50px;
}

.b--50 {
  bottom: -50px;
}

.l--50 {
  left: -50px;
}

.a-50 {
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
}

.x-50 {
  left: 50px;
  right: 50px;
}

.y-50 {
  left: 50px;
  right: 50px;
}

.t-55 {
  top: 55px;
}

.r-55 {
  right: 55px;
}

.b-55 {
  bottom: 55px;
}

.l-55 {
  left: 55px;
}

.t--55 {
  top: -55px;
}

.r--55 {
  right: -55px;
}

.b--55 {
  bottom: -55px;
}

.l--55 {
  left: -55px;
}

.a-55 {
  top: 55px;
  right: 55px;
  bottom: 55px;
  left: 55px;
}

.x-55 {
  left: 55px;
  right: 55px;
}

.y-55 {
  left: 55px;
  right: 55px;
}

.t-60 {
  top: 60px;
}

.r-60 {
  right: 60px;
}

.b-60 {
  bottom: 60px;
}

.l-60 {
  left: 60px;
}

.t--60 {
  top: -60px;
}

.r--60 {
  right: -60px;
}

.b--60 {
  bottom: -60px;
}

.l--60 {
  left: -60px;
}

.a-60 {
  top: 60px;
  right: 60px;
  bottom: 60px;
  left: 60px;
}

.x-60 {
  left: 60px;
  right: 60px;
}

.y-60 {
  left: 60px;
  right: 60px;
}

.t-65 {
  top: 65px;
}

.r-65 {
  right: 65px;
}

.b-65 {
  bottom: 65px;
}

.l-65 {
  left: 65px;
}

.t--65 {
  top: -65px;
}

.r--65 {
  right: -65px;
}

.b--65 {
  bottom: -65px;
}

.l--65 {
  left: -65px;
}

.a-65 {
  top: 65px;
  right: 65px;
  bottom: 65px;
  left: 65px;
}

.x-65 {
  left: 65px;
  right: 65px;
}

.y-65 {
  left: 65px;
  right: 65px;
}

.t-70 {
  top: 70px;
}

.r-70 {
  right: 70px;
}

.b-70 {
  bottom: 70px;
}

.l-70 {
  left: 70px;
}

.t--70 {
  top: -70px;
}

.r--70 {
  right: -70px;
}

.b--70 {
  bottom: -70px;
}

.l--70 {
  left: -70px;
}

.a-70 {
  top: 70px;
  right: 70px;
  bottom: 70px;
  left: 70px;
}

.x-70 {
  left: 70px;
  right: 70px;
}

.y-70 {
  left: 70px;
  right: 70px;
}

.t-75 {
  top: 75px;
}

.r-75 {
  right: 75px;
}

.b-75 {
  bottom: 75px;
}

.l-75 {
  left: 75px;
}

.t--75 {
  top: -75px;
}

.r--75 {
  right: -75px;
}

.b--75 {
  bottom: -75px;
}

.l--75 {
  left: -75px;
}

.a-75 {
  top: 75px;
  right: 75px;
  bottom: 75px;
  left: 75px;
}

.x-75 {
  left: 75px;
  right: 75px;
}

.y-75 {
  left: 75px;
  right: 75px;
}

.t-80 {
  top: 80px;
}

.r-80 {
  right: 80px;
}

.b-80 {
  bottom: 80px;
}

.l-80 {
  left: 80px;
}

.t--80 {
  top: -80px;
}

.r--80 {
  right: -80px;
}

.b--80 {
  bottom: -80px;
}

.l--80 {
  left: -80px;
}

.a-80 {
  top: 80px;
  right: 80px;
  bottom: 80px;
  left: 80px;
}

.x-80 {
  left: 80px;
  right: 80px;
}

.y-80 {
  left: 80px;
  right: 80px;
}

.t-85 {
  top: 85px;
}

.r-85 {
  right: 85px;
}

.b-85 {
  bottom: 85px;
}

.l-85 {
  left: 85px;
}

.t--85 {
  top: -85px;
}

.r--85 {
  right: -85px;
}

.b--85 {
  bottom: -85px;
}

.l--85 {
  left: -85px;
}

.a-85 {
  top: 85px;
  right: 85px;
  bottom: 85px;
  left: 85px;
}

.x-85 {
  left: 85px;
  right: 85px;
}

.y-85 {
  left: 85px;
  right: 85px;
}

.t-90 {
  top: 90px;
}

.r-90 {
  right: 90px;
}

.b-90 {
  bottom: 90px;
}

.l-90 {
  left: 90px;
}

.t--90 {
  top: -90px;
}

.r--90 {
  right: -90px;
}

.b--90 {
  bottom: -90px;
}

.l--90 {
  left: -90px;
}

.a-90 {
  top: 90px;
  right: 90px;
  bottom: 90px;
  left: 90px;
}

.x-90 {
  left: 90px;
  right: 90px;
}

.y-90 {
  left: 90px;
  right: 90px;
}

.t-95 {
  top: 95px;
}

.r-95 {
  right: 95px;
}

.b-95 {
  bottom: 95px;
}

.l-95 {
  left: 95px;
}

.t--95 {
  top: -95px;
}

.r--95 {
  right: -95px;
}

.b--95 {
  bottom: -95px;
}

.l--95 {
  left: -95px;
}

.a-95 {
  top: 95px;
  right: 95px;
  bottom: 95px;
  left: 95px;
}

.x-95 {
  left: 95px;
  right: 95px;
}

.y-95 {
  left: 95px;
  right: 95px;
}

.t-100 {
  top: 100px;
}

.r-100 {
  right: 100px;
}

.b-100 {
  bottom: 100px;
}

.l-100 {
  left: 100px;
}

.t--100 {
  top: -100px;
}

.r--100 {
  right: -100px;
}

.b--100 {
  bottom: -100px;
}

.l--100 {
  left: -100px;
}

.a-100 {
  top: 100px;
  right: 100px;
  bottom: 100px;
  left: 100px;
}

.x-100 {
  left: 100px;
  right: 100px;
}

.y-100 {
  left: 100px;
  right: 100px;
}

.t-2 {
  top: 2px;
}

.r--5 {
  right: -5px;
}

.z-index-0 {
  z-index: 0;
}

.z-index-10 {
  z-index: 10;
}

.z-index-50 {
  z-index: 50;
}

.z-index-100 {
  z-index: 100;
}

.z-index-150 {
  z-index: 150;
}

.z-index-200 {
  z-index: 200;
}

@media (min-width: 480px) {
  .pos-xs-absolute {
    position: absolute;
  }

  .pos-xs-relative {
    position: relative;
  }

  .pos-xs-fixed {
    position: fixed;
  }

  .pos-xs-static {
    position: static;
  }

  .t-xs-auto {
    top: auto;
  }

  .r-xs-auto {
    right: auto;
  }

  .b-xs-auto {
    bottom: auto;
  }

  .l-xs-auto {
    left: auto;
  }
}
@media (min-width: 576px) {
  .pos-sm-absolute {
    position: absolute;
  }

  .pos-sm-relative {
    position: relative;
  }

  .pos-sm-fixed {
    position: fixed;
  }

  .pos-sm-static {
    position: static;
  }

  .t-sm-auto {
    top: auto;
  }

  .r-sm-auto {
    right: auto;
  }

  .b-sm-auto {
    bottom: auto;
  }

  .l-sm-auto {
    left: auto;
  }
}
@media (min-width: 768px) {
  .pos-md-absolute {
    position: absolute;
  }

  .pos-md-relative {
    position: relative;
  }

  .pos-md-fixed {
    position: fixed;
  }

  .pos-md-static {
    position: static;
  }

  .t-md-auto {
    top: auto;
  }

  .r-md-auto {
    right: auto;
  }

  .b-md-auto {
    bottom: auto;
  }

  .l-md-auto {
    left: auto;
  }
}
@media (min-width: 992px) {
  .pos-lg-absolute {
    position: absolute;
  }

  .pos-lg-relative {
    position: relative;
  }

  .pos-lg-fixed {
    position: fixed;
  }

  .pos-lg-static {
    position: static;
  }

  .t-lg-auto {
    top: auto;
  }

  .r-lg-auto {
    right: auto;
  }

  .b-lg-auto {
    bottom: auto;
  }

  .l-lg-auto {
    left: auto;
  }
}
@media (min-width: 1200px) {
  .pos-xl-absolute {
    position: absolute;
  }

  .pos-xl-relative {
    position: relative;
  }

  .pos-xl-fixed {
    position: fixed;
  }

  .pos-xl-static {
    position: static;
  }

  .t-xl-auto {
    top: auto;
  }

  .r-xl-auto {
    right: auto;
  }

  .b-xl-auto {
    bottom: auto;
  }

  .l-xl-auto {
    left: auto;
  }
}
/* ###### 9.8 Typography   ###### */
.tx-black {
  font-weight: 900;
}

.tx-bold {
  font-weight: 700;
}

.tx-semibold {
  font-weight: 600;
}

.tx-medium {
  font-weight: 500;
}

.tx-normal {
  font-weight: 400;
}

.tx-light {
  font-weight: 300;
}

.tx-thin {
  font-weight: 200;
}

.tx-xthin {
  font-weight: 100;
}

.tx-sserif {
  font-family: Manrope, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-roboto {
  font-family: "Roboto", sans-serif;
}

.tx-white {
  color: #fff;
}

.tx-black {
  color: #000;
}

.tx-primary {
  color: #fdd835;
}

.tx-success {
  color: #198754;
}

.tx-warning {
  color: #ffc107;
}

.tx-danger {
  color: #dc3545;
}

.tx-info {
  color: #0dcaf0;
}

.tx-inverse {
  color: #212529;
}

.tx-teal {
  color: #20c997;
}

.tx-dark {
  color: #212529;
}

.tx-indigo {
  color: #6610f2;
}

.tx-purple {
  color: #6f42c1;
}

.tx-orange {
  color: #fd7e14;
}

.tx-pink {
  color: #d63384;
}

.tx-gray-100 {
  color: #f8f9fa;
}

.tx-gray-200 {
  color: #e9ecef;
}

.tx-gray-300 {
  color: #dee2e6;
}

.tx-gray-400 {
  color: #ced4da;
}

.tx-gray-500 {
  color: #adb5bd;
}

.tx-gray-600 {
  color: #6c757d;
}

.tx-gray-700 {
  color: #495057;
}

.tx-gray-800 {
  color: #343a40;
}

.tx-gray-900 {
  color: #212529;
}

.tx-white-2 {
  color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
  color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
  color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
  color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
  color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
  color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
  color: rgba(255, 255, 255, 0.8);
}

.tx-spacing-1 {
  letter-spacing: 0.5px;
}

.tx-spacing-2 {
  letter-spacing: 1px;
}

.tx-spacing-3 {
  letter-spacing: 1.5px;
}

.tx-spacing-4 {
  letter-spacing: 2px;
}

.tx-spacing-5 {
  letter-spacing: 2.5px;
}

.tx-spacing-6 {
  letter-spacing: 3px;
}

.tx-spacing-7 {
  letter-spacign: 3.5px;
}

.tx-spacing-8 {
  letter-spacing: 4px;
}

.tx-spacing--1 {
  letter-spacing: -0.5px;
}

.tx-spacing--2 {
  letter-spacing: -1px;
}

.tx-spacing--3 {
  letter-spacing: -1.5px;
}

.tx-spacing--4 {
  letter-spacing: -2px;
}

.tx-spacing--5 {
  letter-spacing: -2.5px;
}

.tx-spacing--6 {
  letter-spacing: -3px;
}

.tx-spacing--7 {
  letter-spacing: -3.5px;
}

.tx-spacing--8 {
  letter-spacing: -4px;
}

.tx-uppercase {
  text-transform: uppercase;
}

.tx-lowercase {
  text-transform: lowercase;
}

.tx-transform-none {
  text-transform: none;
}

.tx-center {
  text-align: center;
}

.tx-right {
  text-align: right;
}

.tx-left {
  text-align: left;
}

.tx-center-f {
  text-align: center !important;
}

.tx-right-f {
  text-align: right !important;
}

.tx-left-f {
  text-align: left !important;
}

.tx-italic {
  font-style: italic;
}

.tx-style-normal {
  font-style: normal;
}

.lh-base {
  line-height: 1.5;
}

.lh-normal {
  line-height: normal;
}

.lh-0 {
  line-height: 0;
}

.lh--1 {
  line-height: 0.1;
}

.lh--2 {
  line-height: 0.2;
}

.lh--3 {
  line-height: 0.3;
}

.lh--4 {
  line-height: 0.4;
}

.lh--5 {
  line-height: 0.5;
}

.lh--6 {
  line-height: 0.6;
}

.lh--7 {
  line-height: 0.7;
}

.lh--8 {
  line-height: 0.8;
}

.lh--9 {
  line-height: 0.9;
}

.lh-1 {
  line-height: 1.1;
}

.lh-2 {
  line-height: 1.2;
}

.lh-3 {
  line-height: 1.3;
}

.lh-4 {
  line-height: 1.4;
}

.lh-5 {
  line-height: 1.5;
}

.lh-6 {
  line-height: 1.6;
}

.lh-7 {
  line-height: 1.7;
}

.lh-8 {
  line-height: 1.8;
}

.lh-9 {
  line-height: 1.9;
}

.lh-10 {
  line-height: 2;
}

.lh-11 {
  line-height: 2.1;
}

.lh-12 {
  line-height: 2.2;
}

.lh-13 {
  line-height: 2.3;
}

.lh-14 {
  line-height: 2.4;
}

.lh-15 {
  line-height: 2.5;
}

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: baseline;
}

.valign-top-f {
  vertical-align: top !important;
}

.valign-middle-f {
  vertical-align: middle !important;
}

.valign-bottom-f {
  vertical-align: baseline !important;
}

.tx-base {
  font-size: 0.85rem;
}

.tx-8 {
  font-size: 8px;
}

.tx-8-f {
  font-size: 8px !important;
}

.tx-9 {
  font-size: 9px;
}

.tx-9-f {
  font-size: 9px !important;
}

.tx-10 {
  font-size: 10px;
}

.tx-10-f {
  font-size: 10px !important;
}

.tx-11 {
  font-size: 11px;
}

.tx-11-f {
  font-size: 11px !important;
}

.tx-12 {
  font-size: 12px;
}

.tx-12-f {
  font-size: 12px !important;
}

.tx-13 {
  font-size: 13px;
}

.tx-13-f {
  font-size: 13px !important;
}

.tx-14 {
  font-size: 14px;
}

.tx-14-f {
  font-size: 14px !important;
}

.tx-15 {
  font-size: 15px;
}

.tx-15-f {
  font-size: 15px !important;
}

.tx-16 {
  font-size: 16px;
}

.tx-16-f {
  font-size: 16px !important;
}

.tx-18 {
  font-size: 18px;
}

.tx-18-f {
  font-size: 18px !important;
}

.tx-20 {
  font-size: 20px;
}

.tx-20-f {
  font-size: 20px !important;
}

.tx-22 {
  font-size: 22px;
}

.tx-22-f {
  font-size: 22px !important;
}

.tx-24 {
  font-size: 24px;
}

.tx-24-f {
  font-size: 24px !important;
}

.tx-26 {
  font-size: 26px;
}

.tx-26-f {
  font-size: 26px !important;
}

.tx-28 {
  font-size: 28px;
}

.tx-28-f {
  font-size: 28px !important;
}

.tx-30 {
  font-size: 30px;
}

.tx-30-f {
  font-size: 30px !important;
}

.tx-32 {
  font-size: 32px;
}

.tx-32-f {
  font-size: 32px !important;
}

.tx-34 {
  font-size: 34px;
}

.tx-34-f {
  font-size: 34px !important;
}

.tx-36 {
  font-size: 36px;
}

.tx-36-f {
  font-size: 36px !important;
}

.tx-38 {
  font-size: 38px;
}

.tx-38-f {
  font-size: 38px !important;
}

.tx-40 {
  font-size: 40px;
}

.tx-40-f {
  font-size: 40px !important;
}

.tx-42 {
  font-size: 42px;
}

.tx-42-f {
  font-size: 42px !important;
}

.tx-44 {
  font-size: 44px;
}

.tx-44-f {
  font-size: 44px !important;
}

.tx-46 {
  font-size: 46px;
}

.tx-46-f {
  font-size: 46px !important;
}

.tx-48 {
  font-size: 48px;
}

.tx-48-f {
  font-size: 48px !important;
}

.tx-50 {
  font-size: 50px;
}

.tx-50-f {
  font-size: 50px !important;
}

.tx-52 {
  font-size: 52px;
}

.tx-52-f {
  font-size: 52px !important;
}

.tx-54 {
  font-size: 54px;
}

.tx-54-f {
  font-size: 54px !important;
}

.tx-56 {
  font-size: 56px;
}

.tx-56-f {
  font-size: 56px !important;
}

.tx-58 {
  font-size: 58px;
}

.tx-58-f {
  font-size: 58px !important;
}

.tx-60 {
  font-size: 60px;
}

.tx-60-f {
  font-size: 60px !important;
}

.tx-62 {
  font-size: 62px;
}

.tx-62-f {
  font-size: 62px !important;
}

.tx-64 {
  font-size: 64px;
}

.tx-64-f {
  font-size: 64px !important;
}

.tx-66 {
  font-size: 66px;
}

.tx-66-f {
  font-size: 66px !important;
}

.tx-68 {
  font-size: 68px;
}

.tx-68-f {
  font-size: 68px !important;
}

.tx-70 {
  font-size: 70px;
}

.tx-70-f {
  font-size: 70px !important;
}

.tx-72 {
  font-size: 72px;
}

.tx-72-f {
  font-size: 72px !important;
}

.tx-74 {
  font-size: 74px;
}

.tx-74-f {
  font-size: 74px !important;
}

.tx-76 {
  font-size: 76px;
}

.tx-76-f {
  font-size: 76px !important;
}

.tx-78 {
  font-size: 78px;
}

.tx-78-f {
  font-size: 78px !important;
}

.tx-80 {
  font-size: 80px;
}

.tx-80-f {
  font-size: 80px !important;
}

.tx-82 {
  font-size: 82px;
}

.tx-82-f {
  font-size: 82px !important;
}

.tx-84 {
  font-size: 84px;
}

.tx-84-f {
  font-size: 84px !important;
}

.tx-86 {
  font-size: 86px;
}

.tx-86-f {
  font-size: 86px !important;
}

.tx-88 {
  font-size: 88px;
}

.tx-88-f {
  font-size: 88px !important;
}

.tx-90 {
  font-size: 90px;
}

.tx-90-f {
  font-size: 90px !important;
}

.tx-92 {
  font-size: 92px;
}

.tx-92-f {
  font-size: 92px !important;
}

.tx-94 {
  font-size: 94px;
}

.tx-94-f {
  font-size: 94px !important;
}

.tx-96 {
  font-size: 96px;
}

.tx-96-f {
  font-size: 96px !important;
}

.tx-98 {
  font-size: 98px;
}

.tx-98-f {
  font-size: 98px !important;
}

.tx-100 {
  font-size: 100px;
}

.tx-100-f {
  font-size: 100px !important;
}

.tx-102 {
  font-size: 102px;
}

.tx-102-f {
  font-size: 102px !important;
}

.tx-104 {
  font-size: 104px;
}

.tx-104-f {
  font-size: 104px !important;
}

.tx-106 {
  font-size: 106px;
}

.tx-106-f {
  font-size: 106px !important;
}

.tx-108 {
  font-size: 108px;
}

.tx-108-f {
  font-size: 108px !important;
}

.tx-110 {
  font-size: 110px;
}

.tx-110-f {
  font-size: 110px !important;
}

.tx-112 {
  font-size: 112px;
}

.tx-112-f {
  font-size: 112px !important;
}

.tx-114 {
  font-size: 114px;
}

.tx-114-f {
  font-size: 114px !important;
}

.tx-116 {
  font-size: 116px;
}

.tx-116-f {
  font-size: 116px !important;
}

.tx-118 {
  font-size: 118px;
}

.tx-118-f {
  font-size: 118px !important;
}

.tx-120 {
  font-size: 120px;
}

.tx-120-f {
  font-size: 120px !important;
}

.tx-122 {
  font-size: 122px;
}

.tx-122-f {
  font-size: 122px !important;
}

.tx-124 {
  font-size: 124px;
}

.tx-124-f {
  font-size: 124px !important;
}

.tx-126 {
  font-size: 126px;
}

.tx-126-f {
  font-size: 126px !important;
}

.tx-128 {
  font-size: 128px;
}

.tx-128-f {
  font-size: 128px !important;
}

.tx-130 {
  font-size: 130px;
}

.tx-130-f {
  font-size: 130px !important;
}

.tx-132 {
  font-size: 132px;
}

.tx-132-f {
  font-size: 132px !important;
}

.tx-134 {
  font-size: 134px;
}

.tx-134-f {
  font-size: 134px !important;
}

.tx-136 {
  font-size: 136px;
}

.tx-136-f {
  font-size: 136px !important;
}

.tx-138 {
  font-size: 138px;
}

.tx-138-f {
  font-size: 138px !important;
}

.tx-140 {
  font-size: 140px;
}

.tx-140-f {
  font-size: 140px !important;
}

@media (min-width: 480px) {
  .tx-xs-8 {
    font-size: 8px;
  }

  .tx-xs-8-f {
    font-size: 8px !important;
  }

  .tx-xs-9 {
    font-size: 9px;
  }

  .tx-xs-9-f {
    font-size: 9px !important;
  }

  .tx-xs-10 {
    font-size: 10px;
  }

  .tx-xs-10-f {
    font-size: 10px !important;
  }

  .tx-xs-11 {
    font-size: 11px;
  }

  .tx-xs-11-f {
    font-size: 11px !important;
  }

  .tx-xs-12 {
    font-size: 12px;
  }

  .tx-xs-12-f {
    font-size: 12px !important;
  }

  .tx-xs-13 {
    font-size: 13px;
  }

  .tx-xs-13-f {
    font-size: 13px !important;
  }

  .tx-xs-14 {
    font-size: 14px;
  }

  .tx-xs-14-f {
    font-size: 14px !important;
  }

  .tx-xs-15 {
    font-size: 15px;
  }

  .tx-xs-15-f {
    font-size: 15px !important;
  }

  .tx-xs-16 {
    font-size: 16px;
  }

  .tx-xs-16-f {
    font-size: 16px !important;
  }

  .tx-xs-18 {
    font-size: 18px;
  }

  .tx-xs-18-f {
    font-size: 18px !important;
  }

  .tx-xs-20 {
    font-size: 20px;
  }

  .tx-xs-20-f {
    font-size: 20px !important;
  }

  .tx-xs-22 {
    font-size: 22px;
  }

  .tx-xs-22-f {
    font-size: 22px !important;
  }

  .tx-xs-24 {
    font-size: 24px;
  }

  .tx-xs-24-f {
    font-size: 24px !important;
  }

  .tx-xs-26 {
    font-size: 26px;
  }

  .tx-xs-26-f {
    font-size: 26px !important;
  }

  .tx-xs-28 {
    font-size: 28px;
  }

  .tx-xs-28-f {
    font-size: 28px !important;
  }

  .tx-xs-30 {
    font-size: 30px;
  }

  .tx-xs-30-f {
    font-size: 30px !important;
  }

  .tx-xs-32 {
    font-size: 32px;
  }

  .tx-xs-32-f {
    font-size: 32px !important;
  }

  .tx-xs-34 {
    font-size: 34px;
  }

  .tx-xs-34-f {
    font-size: 34px !important;
  }

  .tx-xs-36 {
    font-size: 36px;
  }

  .tx-xs-36-f {
    font-size: 36px !important;
  }

  .tx-xs-38 {
    font-size: 38px;
  }

  .tx-xs-38-f {
    font-size: 38px !important;
  }

  .tx-xs-40 {
    font-size: 40px;
  }

  .tx-xs-40-f {
    font-size: 40px !important;
  }

  .tx-xs-42 {
    font-size: 42px;
  }

  .tx-xs-42-f {
    font-size: 42px !important;
  }

  .tx-xs-44 {
    font-size: 44px;
  }

  .tx-xs-44-f {
    font-size: 44px !important;
  }

  .tx-xs-46 {
    font-size: 46px;
  }

  .tx-xs-46-f {
    font-size: 46px !important;
  }

  .tx-xs-48 {
    font-size: 48px;
  }

  .tx-xs-48-f {
    font-size: 48px !important;
  }

  .tx-xs-50 {
    font-size: 50px;
  }

  .tx-xs-50-f {
    font-size: 50px !important;
  }

  .tx-xs-52 {
    font-size: 52px;
  }

  .tx-xs-52-f {
    font-size: 52px !important;
  }

  .tx-xs-54 {
    font-size: 54px;
  }

  .tx-xs-54-f {
    font-size: 54px !important;
  }

  .tx-xs-56 {
    font-size: 56px;
  }

  .tx-xs-56-f {
    font-size: 56px !important;
  }

  .tx-xs-58 {
    font-size: 58px;
  }

  .tx-xs-58-f {
    font-size: 58px !important;
  }

  .tx-xs-60 {
    font-size: 60px;
  }

  .tx-xs-60-f {
    font-size: 60px !important;
  }

  .tx-xs-62 {
    font-size: 62px;
  }

  .tx-xs-62-f {
    font-size: 62px !important;
  }

  .tx-xs-64 {
    font-size: 64px;
  }

  .tx-xs-64-f {
    font-size: 64px !important;
  }

  .tx-xs-66 {
    font-size: 66px;
  }

  .tx-xs-66-f {
    font-size: 66px !important;
  }

  .tx-xs-68 {
    font-size: 68px;
  }

  .tx-xs-68-f {
    font-size: 68px !important;
  }

  .tx-xs-70 {
    font-size: 70px;
  }

  .tx-xs-70-f {
    font-size: 70px !important;
  }

  .tx-xs-72 {
    font-size: 72px;
  }

  .tx-xs-72-f {
    font-size: 72px !important;
  }

  .tx-xs-74 {
    font-size: 74px;
  }

  .tx-xs-74-f {
    font-size: 74px !important;
  }

  .tx-xs-76 {
    font-size: 76px;
  }

  .tx-xs-76-f {
    font-size: 76px !important;
  }

  .tx-xs-78 {
    font-size: 78px;
  }

  .tx-xs-78-f {
    font-size: 78px !important;
  }

  .tx-xs-80 {
    font-size: 80px;
  }

  .tx-xs-80-f {
    font-size: 80px !important;
  }

  .tx-xs-82 {
    font-size: 82px;
  }

  .tx-xs-82-f {
    font-size: 82px !important;
  }

  .tx-xs-84 {
    font-size: 84px;
  }

  .tx-xs-84-f {
    font-size: 84px !important;
  }

  .tx-xs-86 {
    font-size: 86px;
  }

  .tx-xs-86-f {
    font-size: 86px !important;
  }

  .tx-xs-88 {
    font-size: 88px;
  }

  .tx-xs-88-f {
    font-size: 88px !important;
  }

  .tx-xs-90 {
    font-size: 90px;
  }

  .tx-xs-90-f {
    font-size: 90px !important;
  }

  .tx-xs-92 {
    font-size: 92px;
  }

  .tx-xs-92-f {
    font-size: 92px !important;
  }

  .tx-xs-94 {
    font-size: 94px;
  }

  .tx-xs-94-f {
    font-size: 94px !important;
  }

  .tx-xs-96 {
    font-size: 96px;
  }

  .tx-xs-96-f {
    font-size: 96px !important;
  }

  .tx-xs-98 {
    font-size: 98px;
  }

  .tx-xs-98-f {
    font-size: 98px !important;
  }

  .tx-xs-100 {
    font-size: 100px;
  }

  .tx-xs-100-f {
    font-size: 100px !important;
  }

  .tx-xs-102 {
    font-size: 102px;
  }

  .tx-xs-102-f {
    font-size: 102px !important;
  }

  .tx-xs-104 {
    font-size: 104px;
  }

  .tx-xs-104-f {
    font-size: 104px !important;
  }

  .tx-xs-106 {
    font-size: 106px;
  }

  .tx-xs-106-f {
    font-size: 106px !important;
  }

  .tx-xs-108 {
    font-size: 108px;
  }

  .tx-xs-108-f {
    font-size: 108px !important;
  }

  .tx-xs-110 {
    font-size: 110px;
  }

  .tx-xs-110-f {
    font-size: 110px !important;
  }

  .tx-xs-112 {
    font-size: 112px;
  }

  .tx-xs-112-f {
    font-size: 112px !important;
  }

  .tx-xs-114 {
    font-size: 114px;
  }

  .tx-xs-114-f {
    font-size: 114px !important;
  }

  .tx-xs-116 {
    font-size: 116px;
  }

  .tx-xs-116-f {
    font-size: 116px !important;
  }

  .tx-xs-118 {
    font-size: 118px;
  }

  .tx-xs-118-f {
    font-size: 118px !important;
  }

  .tx-xs-120 {
    font-size: 120px;
  }

  .tx-xs-120-f {
    font-size: 120px !important;
  }

  .tx-xs-122 {
    font-size: 122px;
  }

  .tx-xs-122-f {
    font-size: 122px !important;
  }

  .tx-xs-124 {
    font-size: 124px;
  }

  .tx-xs-124-f {
    font-size: 124px !important;
  }

  .tx-xs-126 {
    font-size: 126px;
  }

  .tx-xs-126-f {
    font-size: 126px !important;
  }

  .tx-xs-128 {
    font-size: 128px;
  }

  .tx-xs-128-f {
    font-size: 128px !important;
  }

  .tx-xs-130 {
    font-size: 130px;
  }

  .tx-xs-130-f {
    font-size: 130px !important;
  }

  .tx-xs-132 {
    font-size: 132px;
  }

  .tx-xs-132-f {
    font-size: 132px !important;
  }

  .tx-xs-134 {
    font-size: 134px;
  }

  .tx-xs-134-f {
    font-size: 134px !important;
  }

  .tx-xs-136 {
    font-size: 136px;
  }

  .tx-xs-136-f {
    font-size: 136px !important;
  }

  .tx-xs-138 {
    font-size: 138px;
  }

  .tx-xs-138-f {
    font-size: 138px !important;
  }

  .tx-xs-140 {
    font-size: 140px;
  }

  .tx-xs-140-f {
    font-size: 140px !important;
  }

  .tx-xs-base {
    font-size: 0.85rem;
  }

  .tx-xs-bold {
    font-weight: 700;
  }

  .tx-xs-semibold {
    font-weight: 600;
  }

  .tx-xs-medium {
    font-weight: 500;
  }

  .tx-xs-normal {
    font-weight: 400;
  }

  .tx-xs-light {
    font-weight: 300;
  }

  .tx-xs-thin {
    font-weight: 200;
  }

  .tx-xs-xthin {
    font-weight: 100;
  }

  .lh-xs-0 {
    line-height: 0;
  }

  .lh-xs-1 {
    line-height: 1.1;
  }

  .lh-xs-2 {
    line-height: 1.2;
  }

  .lh-xs-3 {
    line-height: 1.3;
  }

  .lh-xs-4 {
    line-height: 1.4;
  }

  .lh-xs-5 {
    line-height: 1.5;
  }

  .lh-xs-6 {
    line-height: 1.6;
  }

  .lh-xs-7 {
    line-height: 1.7;
  }

  .lh-xs-8 {
    line-height: 1.8;
  }

  .lh-xs-9 {
    line-height: 1.9;
  }

  .lh-xs-10 {
    line-height: 2;
  }

  .lh-xs-11 {
    line-height: 2.1;
  }

  .lh-xs-12 {
    line-height: 2.2;
  }

  .lh-xs-13 {
    line-height: 2.3;
  }

  .lh-xs-14 {
    line-height: 2.4;
  }

  .lh-xs-15 {
    line-height: 2.5;
  }

  .tx-xs-center {
    text-align: center;
  }

  .tx-xs-right {
    text-align: right;
  }

  .tx-xs-left {
    text-align: left;
  }

  .tx-xs-center-f {
    text-align: center !important;
  }

  .tx-xs-right-f {
    text-align: right !important;
  }

  .tx-xs-left-f {
    text-align: left !important;
  }
}
@media (min-width: 576px) {
  .tx-sm-8 {
    font-size: 8px;
  }

  .tx-sm-8-f {
    font-size: 8px !important;
  }

  .tx-sm-9 {
    font-size: 9px;
  }

  .tx-sm-9-f {
    font-size: 9px !important;
  }

  .tx-sm-10 {
    font-size: 10px;
  }

  .tx-sm-10-f {
    font-size: 10px !important;
  }

  .tx-sm-11 {
    font-size: 11px;
  }

  .tx-sm-11-f {
    font-size: 11px !important;
  }

  .tx-sm-12 {
    font-size: 12px;
  }

  .tx-sm-12-f {
    font-size: 12px !important;
  }

  .tx-sm-13 {
    font-size: 13px;
  }

  .tx-sm-13-f {
    font-size: 13px !important;
  }

  .tx-sm-14 {
    font-size: 14px;
  }

  .tx-sm-14-f {
    font-size: 14px !important;
  }

  .tx-sm-15 {
    font-size: 15px;
  }

  .tx-sm-15-f {
    font-size: 15px !important;
  }

  .tx-sm-16 {
    font-size: 16px;
  }

  .tx-sm-16-f {
    font-size: 16px !important;
  }

  .tx-sm-18 {
    font-size: 18px;
  }

  .tx-sm-18-f {
    font-size: 18px !important;
  }

  .tx-sm-20 {
    font-size: 20px;
  }

  .tx-sm-20-f {
    font-size: 20px !important;
  }

  .tx-sm-22 {
    font-size: 22px;
  }

  .tx-sm-22-f {
    font-size: 22px !important;
  }

  .tx-sm-24 {
    font-size: 24px;
  }

  .tx-sm-24-f {
    font-size: 24px !important;
  }

  .tx-sm-26 {
    font-size: 26px;
  }

  .tx-sm-26-f {
    font-size: 26px !important;
  }

  .tx-sm-28 {
    font-size: 28px;
  }

  .tx-sm-28-f {
    font-size: 28px !important;
  }

  .tx-sm-30 {
    font-size: 30px;
  }

  .tx-sm-30-f {
    font-size: 30px !important;
  }

  .tx-sm-32 {
    font-size: 32px;
  }

  .tx-sm-32-f {
    font-size: 32px !important;
  }

  .tx-sm-34 {
    font-size: 34px;
  }

  .tx-sm-34-f {
    font-size: 34px !important;
  }

  .tx-sm-36 {
    font-size: 36px;
  }

  .tx-sm-36-f {
    font-size: 36px !important;
  }

  .tx-sm-38 {
    font-size: 38px;
  }

  .tx-sm-38-f {
    font-size: 38px !important;
  }

  .tx-sm-40 {
    font-size: 40px;
  }

  .tx-sm-40-f {
    font-size: 40px !important;
  }

  .tx-sm-42 {
    font-size: 42px;
  }

  .tx-sm-42-f {
    font-size: 42px !important;
  }

  .tx-sm-44 {
    font-size: 44px;
  }

  .tx-sm-44-f {
    font-size: 44px !important;
  }

  .tx-sm-46 {
    font-size: 46px;
  }

  .tx-sm-46-f {
    font-size: 46px !important;
  }

  .tx-sm-48 {
    font-size: 48px;
  }

  .tx-sm-48-f {
    font-size: 48px !important;
  }

  .tx-sm-50 {
    font-size: 50px;
  }

  .tx-sm-50-f {
    font-size: 50px !important;
  }

  .tx-sm-52 {
    font-size: 52px;
  }

  .tx-sm-52-f {
    font-size: 52px !important;
  }

  .tx-sm-54 {
    font-size: 54px;
  }

  .tx-sm-54-f {
    font-size: 54px !important;
  }

  .tx-sm-56 {
    font-size: 56px;
  }

  .tx-sm-56-f {
    font-size: 56px !important;
  }

  .tx-sm-58 {
    font-size: 58px;
  }

  .tx-sm-58-f {
    font-size: 58px !important;
  }

  .tx-sm-60 {
    font-size: 60px;
  }

  .tx-sm-60-f {
    font-size: 60px !important;
  }

  .tx-sm-62 {
    font-size: 62px;
  }

  .tx-sm-62-f {
    font-size: 62px !important;
  }

  .tx-sm-64 {
    font-size: 64px;
  }

  .tx-sm-64-f {
    font-size: 64px !important;
  }

  .tx-sm-66 {
    font-size: 66px;
  }

  .tx-sm-66-f {
    font-size: 66px !important;
  }

  .tx-sm-68 {
    font-size: 68px;
  }

  .tx-sm-68-f {
    font-size: 68px !important;
  }

  .tx-sm-70 {
    font-size: 70px;
  }

  .tx-sm-70-f {
    font-size: 70px !important;
  }

  .tx-sm-72 {
    font-size: 72px;
  }

  .tx-sm-72-f {
    font-size: 72px !important;
  }

  .tx-sm-74 {
    font-size: 74px;
  }

  .tx-sm-74-f {
    font-size: 74px !important;
  }

  .tx-sm-76 {
    font-size: 76px;
  }

  .tx-sm-76-f {
    font-size: 76px !important;
  }

  .tx-sm-78 {
    font-size: 78px;
  }

  .tx-sm-78-f {
    font-size: 78px !important;
  }

  .tx-sm-80 {
    font-size: 80px;
  }

  .tx-sm-80-f {
    font-size: 80px !important;
  }

  .tx-sm-82 {
    font-size: 82px;
  }

  .tx-sm-82-f {
    font-size: 82px !important;
  }

  .tx-sm-84 {
    font-size: 84px;
  }

  .tx-sm-84-f {
    font-size: 84px !important;
  }

  .tx-sm-86 {
    font-size: 86px;
  }

  .tx-sm-86-f {
    font-size: 86px !important;
  }

  .tx-sm-88 {
    font-size: 88px;
  }

  .tx-sm-88-f {
    font-size: 88px !important;
  }

  .tx-sm-90 {
    font-size: 90px;
  }

  .tx-sm-90-f {
    font-size: 90px !important;
  }

  .tx-sm-92 {
    font-size: 92px;
  }

  .tx-sm-92-f {
    font-size: 92px !important;
  }

  .tx-sm-94 {
    font-size: 94px;
  }

  .tx-sm-94-f {
    font-size: 94px !important;
  }

  .tx-sm-96 {
    font-size: 96px;
  }

  .tx-sm-96-f {
    font-size: 96px !important;
  }

  .tx-sm-98 {
    font-size: 98px;
  }

  .tx-sm-98-f {
    font-size: 98px !important;
  }

  .tx-sm-100 {
    font-size: 100px;
  }

  .tx-sm-100-f {
    font-size: 100px !important;
  }

  .tx-sm-102 {
    font-size: 102px;
  }

  .tx-sm-102-f {
    font-size: 102px !important;
  }

  .tx-sm-104 {
    font-size: 104px;
  }

  .tx-sm-104-f {
    font-size: 104px !important;
  }

  .tx-sm-106 {
    font-size: 106px;
  }

  .tx-sm-106-f {
    font-size: 106px !important;
  }

  .tx-sm-108 {
    font-size: 108px;
  }

  .tx-sm-108-f {
    font-size: 108px !important;
  }

  .tx-sm-110 {
    font-size: 110px;
  }

  .tx-sm-110-f {
    font-size: 110px !important;
  }

  .tx-sm-112 {
    font-size: 112px;
  }

  .tx-sm-112-f {
    font-size: 112px !important;
  }

  .tx-sm-114 {
    font-size: 114px;
  }

  .tx-sm-114-f {
    font-size: 114px !important;
  }

  .tx-sm-116 {
    font-size: 116px;
  }

  .tx-sm-116-f {
    font-size: 116px !important;
  }

  .tx-sm-118 {
    font-size: 118px;
  }

  .tx-sm-118-f {
    font-size: 118px !important;
  }

  .tx-sm-120 {
    font-size: 120px;
  }

  .tx-sm-120-f {
    font-size: 120px !important;
  }

  .tx-sm-122 {
    font-size: 122px;
  }

  .tx-sm-122-f {
    font-size: 122px !important;
  }

  .tx-sm-124 {
    font-size: 124px;
  }

  .tx-sm-124-f {
    font-size: 124px !important;
  }

  .tx-sm-126 {
    font-size: 126px;
  }

  .tx-sm-126-f {
    font-size: 126px !important;
  }

  .tx-sm-128 {
    font-size: 128px;
  }

  .tx-sm-128-f {
    font-size: 128px !important;
  }

  .tx-sm-130 {
    font-size: 130px;
  }

  .tx-sm-130-f {
    font-size: 130px !important;
  }

  .tx-sm-132 {
    font-size: 132px;
  }

  .tx-sm-132-f {
    font-size: 132px !important;
  }

  .tx-sm-134 {
    font-size: 134px;
  }

  .tx-sm-134-f {
    font-size: 134px !important;
  }

  .tx-sm-136 {
    font-size: 136px;
  }

  .tx-sm-136-f {
    font-size: 136px !important;
  }

  .tx-sm-138 {
    font-size: 138px;
  }

  .tx-sm-138-f {
    font-size: 138px !important;
  }

  .tx-sm-140 {
    font-size: 140px;
  }

  .tx-sm-140-f {
    font-size: 140px !important;
  }

  .tx-sm-base {
    font-size: 0.85rem;
  }

  .tx-sm-bold {
    font-weight: 700;
  }

  .tx-sm-semibold {
    font-weight: 600;
  }

  .tx-sm-medium {
    font-weight: 500;
  }

  .tx-sm-normal {
    font-weight: 400;
  }

  .tx-sm-light {
    font-weight: 300;
  }

  .tx-sm-thin {
    font-weight: 200;
  }

  .tx-sm-xthin {
    font-weight: 100;
  }

  .lh-sm-0 {
    line-height: 0;
  }

  .lh-sm-1 {
    line-height: 1.1;
  }

  .lh-sm-2 {
    line-height: 1.2;
  }

  .lh-sm-3 {
    line-height: 1.3;
  }

  .lh-sm-4 {
    line-height: 1.4;
  }

  .lh-sm-5 {
    line-height: 1.5;
  }

  .lh-sm-6 {
    line-height: 1.6;
  }

  .lh-sm-7 {
    line-height: 1.7;
  }

  .lh-sm-8 {
    line-height: 1.8;
  }

  .lh-sm-9 {
    line-height: 1.9;
  }

  .lh-sm-10 {
    line-height: 2;
  }

  .lh-sm-11 {
    line-height: 2.1;
  }

  .lh-sm-12 {
    line-height: 2.2;
  }

  .lh-sm-13 {
    line-height: 2.3;
  }

  .lh-sm-14 {
    line-height: 2.4;
  }

  .lh-sm-15 {
    line-height: 2.5;
  }

  .tx-sm-center {
    text-align: center;
  }

  .tx-sm-right {
    text-align: right;
  }

  .tx-sm-left {
    text-align: left;
  }

  .tx-sm-center-f {
    text-align: center !important;
  }

  .tx-sm-right-f {
    text-align: right !important;
  }

  .tx-sm-left-f {
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  .tx-md-8 {
    font-size: 8px;
  }

  .tx-md-8-f {
    font-size: 8px !important;
  }

  .tx-md-9 {
    font-size: 9px;
  }

  .tx-md-9-f {
    font-size: 9px !important;
  }

  .tx-md-10 {
    font-size: 10px;
  }

  .tx-md-10-f {
    font-size: 10px !important;
  }

  .tx-md-11 {
    font-size: 11px;
  }

  .tx-md-11-f {
    font-size: 11px !important;
  }

  .tx-md-12 {
    font-size: 12px;
  }

  .tx-md-12-f {
    font-size: 12px !important;
  }

  .tx-md-13 {
    font-size: 13px;
  }

  .tx-md-13-f {
    font-size: 13px !important;
  }

  .tx-md-14 {
    font-size: 14px;
  }

  .tx-md-14-f {
    font-size: 14px !important;
  }

  .tx-md-15 {
    font-size: 15px;
  }

  .tx-md-15-f {
    font-size: 15px !important;
  }

  .tx-md-16 {
    font-size: 16px;
  }

  .tx-md-16-f {
    font-size: 16px !important;
  }

  .tx-md-18 {
    font-size: 18px;
  }

  .tx-md-18-f {
    font-size: 18px !important;
  }

  .tx-md-20 {
    font-size: 20px;
  }

  .tx-md-20-f {
    font-size: 20px !important;
  }

  .tx-md-22 {
    font-size: 22px;
  }

  .tx-md-22-f {
    font-size: 22px !important;
  }

  .tx-md-24 {
    font-size: 24px;
  }

  .tx-md-24-f {
    font-size: 24px !important;
  }

  .tx-md-26 {
    font-size: 26px;
  }

  .tx-md-26-f {
    font-size: 26px !important;
  }

  .tx-md-28 {
    font-size: 28px;
  }

  .tx-md-28-f {
    font-size: 28px !important;
  }

  .tx-md-30 {
    font-size: 30px;
  }

  .tx-md-30-f {
    font-size: 30px !important;
  }

  .tx-md-32 {
    font-size: 32px;
  }

  .tx-md-32-f {
    font-size: 32px !important;
  }

  .tx-md-34 {
    font-size: 34px;
  }

  .tx-md-34-f {
    font-size: 34px !important;
  }

  .tx-md-36 {
    font-size: 36px;
  }

  .tx-md-36-f {
    font-size: 36px !important;
  }

  .tx-md-38 {
    font-size: 38px;
  }

  .tx-md-38-f {
    font-size: 38px !important;
  }

  .tx-md-40 {
    font-size: 40px;
  }

  .tx-md-40-f {
    font-size: 40px !important;
  }

  .tx-md-42 {
    font-size: 42px;
  }

  .tx-md-42-f {
    font-size: 42px !important;
  }

  .tx-md-44 {
    font-size: 44px;
  }

  .tx-md-44-f {
    font-size: 44px !important;
  }

  .tx-md-46 {
    font-size: 46px;
  }

  .tx-md-46-f {
    font-size: 46px !important;
  }

  .tx-md-48 {
    font-size: 48px;
  }

  .tx-md-48-f {
    font-size: 48px !important;
  }

  .tx-md-50 {
    font-size: 50px;
  }

  .tx-md-50-f {
    font-size: 50px !important;
  }

  .tx-md-52 {
    font-size: 52px;
  }

  .tx-md-52-f {
    font-size: 52px !important;
  }

  .tx-md-54 {
    font-size: 54px;
  }

  .tx-md-54-f {
    font-size: 54px !important;
  }

  .tx-md-56 {
    font-size: 56px;
  }

  .tx-md-56-f {
    font-size: 56px !important;
  }

  .tx-md-58 {
    font-size: 58px;
  }

  .tx-md-58-f {
    font-size: 58px !important;
  }

  .tx-md-60 {
    font-size: 60px;
  }

  .tx-md-60-f {
    font-size: 60px !important;
  }

  .tx-md-62 {
    font-size: 62px;
  }

  .tx-md-62-f {
    font-size: 62px !important;
  }

  .tx-md-64 {
    font-size: 64px;
  }

  .tx-md-64-f {
    font-size: 64px !important;
  }

  .tx-md-66 {
    font-size: 66px;
  }

  .tx-md-66-f {
    font-size: 66px !important;
  }

  .tx-md-68 {
    font-size: 68px;
  }

  .tx-md-68-f {
    font-size: 68px !important;
  }

  .tx-md-70 {
    font-size: 70px;
  }

  .tx-md-70-f {
    font-size: 70px !important;
  }

  .tx-md-72 {
    font-size: 72px;
  }

  .tx-md-72-f {
    font-size: 72px !important;
  }

  .tx-md-74 {
    font-size: 74px;
  }

  .tx-md-74-f {
    font-size: 74px !important;
  }

  .tx-md-76 {
    font-size: 76px;
  }

  .tx-md-76-f {
    font-size: 76px !important;
  }

  .tx-md-78 {
    font-size: 78px;
  }

  .tx-md-78-f {
    font-size: 78px !important;
  }

  .tx-md-80 {
    font-size: 80px;
  }

  .tx-md-80-f {
    font-size: 80px !important;
  }

  .tx-md-82 {
    font-size: 82px;
  }

  .tx-md-82-f {
    font-size: 82px !important;
  }

  .tx-md-84 {
    font-size: 84px;
  }

  .tx-md-84-f {
    font-size: 84px !important;
  }

  .tx-md-86 {
    font-size: 86px;
  }

  .tx-md-86-f {
    font-size: 86px !important;
  }

  .tx-md-88 {
    font-size: 88px;
  }

  .tx-md-88-f {
    font-size: 88px !important;
  }

  .tx-md-90 {
    font-size: 90px;
  }

  .tx-md-90-f {
    font-size: 90px !important;
  }

  .tx-md-92 {
    font-size: 92px;
  }

  .tx-md-92-f {
    font-size: 92px !important;
  }

  .tx-md-94 {
    font-size: 94px;
  }

  .tx-md-94-f {
    font-size: 94px !important;
  }

  .tx-md-96 {
    font-size: 96px;
  }

  .tx-md-96-f {
    font-size: 96px !important;
  }

  .tx-md-98 {
    font-size: 98px;
  }

  .tx-md-98-f {
    font-size: 98px !important;
  }

  .tx-md-100 {
    font-size: 100px;
  }

  .tx-md-100-f {
    font-size: 100px !important;
  }

  .tx-md-102 {
    font-size: 102px;
  }

  .tx-md-102-f {
    font-size: 102px !important;
  }

  .tx-md-104 {
    font-size: 104px;
  }

  .tx-md-104-f {
    font-size: 104px !important;
  }

  .tx-md-106 {
    font-size: 106px;
  }

  .tx-md-106-f {
    font-size: 106px !important;
  }

  .tx-md-108 {
    font-size: 108px;
  }

  .tx-md-108-f {
    font-size: 108px !important;
  }

  .tx-md-110 {
    font-size: 110px;
  }

  .tx-md-110-f {
    font-size: 110px !important;
  }

  .tx-md-112 {
    font-size: 112px;
  }

  .tx-md-112-f {
    font-size: 112px !important;
  }

  .tx-md-114 {
    font-size: 114px;
  }

  .tx-md-114-f {
    font-size: 114px !important;
  }

  .tx-md-116 {
    font-size: 116px;
  }

  .tx-md-116-f {
    font-size: 116px !important;
  }

  .tx-md-118 {
    font-size: 118px;
  }

  .tx-md-118-f {
    font-size: 118px !important;
  }

  .tx-md-120 {
    font-size: 120px;
  }

  .tx-md-120-f {
    font-size: 120px !important;
  }

  .tx-md-122 {
    font-size: 122px;
  }

  .tx-md-122-f {
    font-size: 122px !important;
  }

  .tx-md-124 {
    font-size: 124px;
  }

  .tx-md-124-f {
    font-size: 124px !important;
  }

  .tx-md-126 {
    font-size: 126px;
  }

  .tx-md-126-f {
    font-size: 126px !important;
  }

  .tx-md-128 {
    font-size: 128px;
  }

  .tx-md-128-f {
    font-size: 128px !important;
  }

  .tx-md-130 {
    font-size: 130px;
  }

  .tx-md-130-f {
    font-size: 130px !important;
  }

  .tx-md-132 {
    font-size: 132px;
  }

  .tx-md-132-f {
    font-size: 132px !important;
  }

  .tx-md-134 {
    font-size: 134px;
  }

  .tx-md-134-f {
    font-size: 134px !important;
  }

  .tx-md-136 {
    font-size: 136px;
  }

  .tx-md-136-f {
    font-size: 136px !important;
  }

  .tx-md-138 {
    font-size: 138px;
  }

  .tx-md-138-f {
    font-size: 138px !important;
  }

  .tx-md-140 {
    font-size: 140px;
  }

  .tx-md-140-f {
    font-size: 140px !important;
  }

  .tx-md-base {
    font-size: 0.85rem;
  }

  .tx-md-bold {
    font-weight: 700;
  }

  .tx-md-semibold {
    font-weight: 600;
  }

  .tx-md-medium {
    font-weight: 500;
  }

  .tx-md-normal {
    font-weight: 400;
  }

  .tx-md-light {
    font-weight: 300;
  }

  .tx-md-thin {
    font-weight: 200;
  }

  .tx-md-xthin {
    font-weight: 100;
  }

  .lh-md-0 {
    line-height: 0;
  }

  .lh-md-1 {
    line-height: 1.1;
  }

  .lh-md-2 {
    line-height: 1.2;
  }

  .lh-md-3 {
    line-height: 1.3;
  }

  .lh-md-4 {
    line-height: 1.4;
  }

  .lh-md-5 {
    line-height: 1.5;
  }

  .lh-md-6 {
    line-height: 1.6;
  }

  .lh-md-7 {
    line-height: 1.7;
  }

  .lh-md-8 {
    line-height: 1.8;
  }

  .lh-md-9 {
    line-height: 1.9;
  }

  .lh-md-10 {
    line-height: 2;
  }

  .lh-md-11 {
    line-height: 2.1;
  }

  .lh-md-12 {
    line-height: 2.2;
  }

  .lh-md-13 {
    line-height: 2.3;
  }

  .lh-md-14 {
    line-height: 2.4;
  }

  .lh-md-15 {
    line-height: 2.5;
  }

  .tx-md-center {
    text-align: center;
  }

  .tx-md-right {
    text-align: right;
  }

  .tx-md-left {
    text-align: left;
  }

  .tx-md-center-f {
    text-align: center !important;
  }

  .tx-md-right-f {
    text-align: right !important;
  }

  .tx-md-left-f {
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  .tx-lg-8 {
    font-size: 8px;
  }

  .tx-lg-8-f {
    font-size: 8px !important;
  }

  .tx-lg-9 {
    font-size: 9px;
  }

  .tx-lg-9-f {
    font-size: 9px !important;
  }

  .tx-lg-10 {
    font-size: 10px;
  }

  .tx-lg-10-f {
    font-size: 10px !important;
  }

  .tx-lg-11 {
    font-size: 11px;
  }

  .tx-lg-11-f {
    font-size: 11px !important;
  }

  .tx-lg-12 {
    font-size: 12px;
  }

  .tx-lg-12-f {
    font-size: 12px !important;
  }

  .tx-lg-13 {
    font-size: 13px;
  }

  .tx-lg-13-f {
    font-size: 13px !important;
  }

  .tx-lg-14 {
    font-size: 14px;
  }

  .tx-lg-14-f {
    font-size: 14px !important;
  }

  .tx-lg-15 {
    font-size: 15px;
  }

  .tx-lg-15-f {
    font-size: 15px !important;
  }

  .tx-lg-16 {
    font-size: 16px;
  }

  .tx-lg-16-f {
    font-size: 16px !important;
  }

  .tx-lg-18 {
    font-size: 18px;
  }

  .tx-lg-18-f {
    font-size: 18px !important;
  }

  .tx-lg-20 {
    font-size: 20px;
  }

  .tx-lg-20-f {
    font-size: 20px !important;
  }

  .tx-lg-22 {
    font-size: 22px;
  }

  .tx-lg-22-f {
    font-size: 22px !important;
  }

  .tx-lg-24 {
    font-size: 24px;
  }

  .tx-lg-24-f {
    font-size: 24px !important;
  }

  .tx-lg-26 {
    font-size: 26px;
  }

  .tx-lg-26-f {
    font-size: 26px !important;
  }

  .tx-lg-28 {
    font-size: 28px;
  }

  .tx-lg-28-f {
    font-size: 28px !important;
  }

  .tx-lg-30 {
    font-size: 30px;
  }

  .tx-lg-30-f {
    font-size: 30px !important;
  }

  .tx-lg-32 {
    font-size: 32px;
  }

  .tx-lg-32-f {
    font-size: 32px !important;
  }

  .tx-lg-34 {
    font-size: 34px;
  }

  .tx-lg-34-f {
    font-size: 34px !important;
  }

  .tx-lg-36 {
    font-size: 36px;
  }

  .tx-lg-36-f {
    font-size: 36px !important;
  }

  .tx-lg-38 {
    font-size: 38px;
  }

  .tx-lg-38-f {
    font-size: 38px !important;
  }

  .tx-lg-40 {
    font-size: 40px;
  }

  .tx-lg-40-f {
    font-size: 40px !important;
  }

  .tx-lg-42 {
    font-size: 42px;
  }

  .tx-lg-42-f {
    font-size: 42px !important;
  }

  .tx-lg-44 {
    font-size: 44px;
  }

  .tx-lg-44-f {
    font-size: 44px !important;
  }

  .tx-lg-46 {
    font-size: 46px;
  }

  .tx-lg-46-f {
    font-size: 46px !important;
  }

  .tx-lg-48 {
    font-size: 48px;
  }

  .tx-lg-48-f {
    font-size: 48px !important;
  }

  .tx-lg-50 {
    font-size: 50px;
  }

  .tx-lg-50-f {
    font-size: 50px !important;
  }

  .tx-lg-52 {
    font-size: 52px;
  }

  .tx-lg-52-f {
    font-size: 52px !important;
  }

  .tx-lg-54 {
    font-size: 54px;
  }

  .tx-lg-54-f {
    font-size: 54px !important;
  }

  .tx-lg-56 {
    font-size: 56px;
  }

  .tx-lg-56-f {
    font-size: 56px !important;
  }

  .tx-lg-58 {
    font-size: 58px;
  }

  .tx-lg-58-f {
    font-size: 58px !important;
  }

  .tx-lg-60 {
    font-size: 60px;
  }

  .tx-lg-60-f {
    font-size: 60px !important;
  }

  .tx-lg-62 {
    font-size: 62px;
  }

  .tx-lg-62-f {
    font-size: 62px !important;
  }

  .tx-lg-64 {
    font-size: 64px;
  }

  .tx-lg-64-f {
    font-size: 64px !important;
  }

  .tx-lg-66 {
    font-size: 66px;
  }

  .tx-lg-66-f {
    font-size: 66px !important;
  }

  .tx-lg-68 {
    font-size: 68px;
  }

  .tx-lg-68-f {
    font-size: 68px !important;
  }

  .tx-lg-70 {
    font-size: 70px;
  }

  .tx-lg-70-f {
    font-size: 70px !important;
  }

  .tx-lg-72 {
    font-size: 72px;
  }

  .tx-lg-72-f {
    font-size: 72px !important;
  }

  .tx-lg-74 {
    font-size: 74px;
  }

  .tx-lg-74-f {
    font-size: 74px !important;
  }

  .tx-lg-76 {
    font-size: 76px;
  }

  .tx-lg-76-f {
    font-size: 76px !important;
  }

  .tx-lg-78 {
    font-size: 78px;
  }

  .tx-lg-78-f {
    font-size: 78px !important;
  }

  .tx-lg-80 {
    font-size: 80px;
  }

  .tx-lg-80-f {
    font-size: 80px !important;
  }

  .tx-lg-82 {
    font-size: 82px;
  }

  .tx-lg-82-f {
    font-size: 82px !important;
  }

  .tx-lg-84 {
    font-size: 84px;
  }

  .tx-lg-84-f {
    font-size: 84px !important;
  }

  .tx-lg-86 {
    font-size: 86px;
  }

  .tx-lg-86-f {
    font-size: 86px !important;
  }

  .tx-lg-88 {
    font-size: 88px;
  }

  .tx-lg-88-f {
    font-size: 88px !important;
  }

  .tx-lg-90 {
    font-size: 90px;
  }

  .tx-lg-90-f {
    font-size: 90px !important;
  }

  .tx-lg-92 {
    font-size: 92px;
  }

  .tx-lg-92-f {
    font-size: 92px !important;
  }

  .tx-lg-94 {
    font-size: 94px;
  }

  .tx-lg-94-f {
    font-size: 94px !important;
  }

  .tx-lg-96 {
    font-size: 96px;
  }

  .tx-lg-96-f {
    font-size: 96px !important;
  }

  .tx-lg-98 {
    font-size: 98px;
  }

  .tx-lg-98-f {
    font-size: 98px !important;
  }

  .tx-lg-100 {
    font-size: 100px;
  }

  .tx-lg-100-f {
    font-size: 100px !important;
  }

  .tx-lg-102 {
    font-size: 102px;
  }

  .tx-lg-102-f {
    font-size: 102px !important;
  }

  .tx-lg-104 {
    font-size: 104px;
  }

  .tx-lg-104-f {
    font-size: 104px !important;
  }

  .tx-lg-106 {
    font-size: 106px;
  }

  .tx-lg-106-f {
    font-size: 106px !important;
  }

  .tx-lg-108 {
    font-size: 108px;
  }

  .tx-lg-108-f {
    font-size: 108px !important;
  }

  .tx-lg-110 {
    font-size: 110px;
  }

  .tx-lg-110-f {
    font-size: 110px !important;
  }

  .tx-lg-112 {
    font-size: 112px;
  }

  .tx-lg-112-f {
    font-size: 112px !important;
  }

  .tx-lg-114 {
    font-size: 114px;
  }

  .tx-lg-114-f {
    font-size: 114px !important;
  }

  .tx-lg-116 {
    font-size: 116px;
  }

  .tx-lg-116-f {
    font-size: 116px !important;
  }

  .tx-lg-118 {
    font-size: 118px;
  }

  .tx-lg-118-f {
    font-size: 118px !important;
  }

  .tx-lg-120 {
    font-size: 120px;
  }

  .tx-lg-120-f {
    font-size: 120px !important;
  }

  .tx-lg-122 {
    font-size: 122px;
  }

  .tx-lg-122-f {
    font-size: 122px !important;
  }

  .tx-lg-124 {
    font-size: 124px;
  }

  .tx-lg-124-f {
    font-size: 124px !important;
  }

  .tx-lg-126 {
    font-size: 126px;
  }

  .tx-lg-126-f {
    font-size: 126px !important;
  }

  .tx-lg-128 {
    font-size: 128px;
  }

  .tx-lg-128-f {
    font-size: 128px !important;
  }

  .tx-lg-130 {
    font-size: 130px;
  }

  .tx-lg-130-f {
    font-size: 130px !important;
  }

  .tx-lg-132 {
    font-size: 132px;
  }

  .tx-lg-132-f {
    font-size: 132px !important;
  }

  .tx-lg-134 {
    font-size: 134px;
  }

  .tx-lg-134-f {
    font-size: 134px !important;
  }

  .tx-lg-136 {
    font-size: 136px;
  }

  .tx-lg-136-f {
    font-size: 136px !important;
  }

  .tx-lg-138 {
    font-size: 138px;
  }

  .tx-lg-138-f {
    font-size: 138px !important;
  }

  .tx-lg-140 {
    font-size: 140px;
  }

  .tx-lg-140-f {
    font-size: 140px !important;
  }

  .tx-lg-base {
    font-size: 0.85rem;
  }

  .tx-lg-bold {
    font-weight: 700;
  }

  .tx-lg-semibold {
    font-weight: 600;
  }

  .tx-lg-medium {
    font-weight: 500;
  }

  .tx-lg-normal {
    font-weight: 400;
  }

  .tx-lg-light {
    font-weight: 300;
  }

  .tx-lg-thin {
    font-weight: 200;
  }

  .tx-lg-xthin {
    font-weight: 100;
  }

  .lh-lg-0 {
    line-height: 0;
  }

  .lh-lg-1 {
    line-height: 1.1;
  }

  .lh-lg-2 {
    line-height: 1.2;
  }

  .lh-lg-3 {
    line-height: 1.3;
  }

  .lh-lg-4 {
    line-height: 1.4;
  }

  .lh-lg-5 {
    line-height: 1.5;
  }

  .lh-lg-6 {
    line-height: 1.6;
  }

  .lh-lg-7 {
    line-height: 1.7;
  }

  .lh-lg-8 {
    line-height: 1.8;
  }

  .lh-lg-9 {
    line-height: 1.9;
  }

  .lh-lg-10 {
    line-height: 2;
  }

  .lh-lg-11 {
    line-height: 2.1;
  }

  .lh-lg-12 {
    line-height: 2.2;
  }

  .lh-lg-13 {
    line-height: 2.3;
  }

  .lh-lg-14 {
    line-height: 2.4;
  }

  .lh-lg-15 {
    line-height: 2.5;
  }

  .tx-lg-center {
    text-align: center;
  }

  .tx-lg-right {
    text-align: right;
  }

  .tx-lg-left {
    text-align: left;
  }

  .tx-lg-center-f {
    text-align: center !important;
  }

  .tx-lg-right-f {
    text-align: right !important;
  }

  .tx-lg-left-f {
    text-align: left !important;
  }
}
@media (min-width: 1200px) {
  .tx-xl-8 {
    font-size: 8px;
  }

  .tx-xl-8-f {
    font-size: 8px !important;
  }

  .tx-xl-9 {
    font-size: 9px;
  }

  .tx-xl-9-f {
    font-size: 9px !important;
  }

  .tx-xl-10 {
    font-size: 10px;
  }

  .tx-xl-10-f {
    font-size: 10px !important;
  }

  .tx-xl-11 {
    font-size: 11px;
  }

  .tx-xl-11-f {
    font-size: 11px !important;
  }

  .tx-xl-12 {
    font-size: 12px;
  }

  .tx-xl-12-f {
    font-size: 12px !important;
  }

  .tx-xl-13 {
    font-size: 13px;
  }

  .tx-xl-13-f {
    font-size: 13px !important;
  }

  .tx-xl-14 {
    font-size: 14px;
  }

  .tx-xl-14-f {
    font-size: 14px !important;
  }

  .tx-xl-15 {
    font-size: 15px;
  }

  .tx-xl-15-f {
    font-size: 15px !important;
  }

  .tx-xl-16 {
    font-size: 16px;
  }

  .tx-xl-16-f {
    font-size: 16px !important;
  }

  .tx-xl-18 {
    font-size: 18px;
  }

  .tx-xl-18-f {
    font-size: 18px !important;
  }

  .tx-xl-20 {
    font-size: 20px;
  }

  .tx-xl-20-f {
    font-size: 20px !important;
  }

  .tx-xl-22 {
    font-size: 22px;
  }

  .tx-xl-22-f {
    font-size: 22px !important;
  }

  .tx-xl-24 {
    font-size: 24px;
  }

  .tx-xl-24-f {
    font-size: 24px !important;
  }

  .tx-xl-26 {
    font-size: 26px;
  }

  .tx-xl-26-f {
    font-size: 26px !important;
  }

  .tx-xl-28 {
    font-size: 28px;
  }

  .tx-xl-28-f {
    font-size: 28px !important;
  }

  .tx-xl-30 {
    font-size: 30px;
  }

  .tx-xl-30-f {
    font-size: 30px !important;
  }

  .tx-xl-32 {
    font-size: 32px;
  }

  .tx-xl-32-f {
    font-size: 32px !important;
  }

  .tx-xl-34 {
    font-size: 34px;
  }

  .tx-xl-34-f {
    font-size: 34px !important;
  }

  .tx-xl-36 {
    font-size: 36px;
  }

  .tx-xl-36-f {
    font-size: 36px !important;
  }

  .tx-xl-38 {
    font-size: 38px;
  }

  .tx-xl-38-f {
    font-size: 38px !important;
  }

  .tx-xl-40 {
    font-size: 40px;
  }

  .tx-xl-40-f {
    font-size: 40px !important;
  }

  .tx-xl-42 {
    font-size: 42px;
  }

  .tx-xl-42-f {
    font-size: 42px !important;
  }

  .tx-xl-44 {
    font-size: 44px;
  }

  .tx-xl-44-f {
    font-size: 44px !important;
  }

  .tx-xl-46 {
    font-size: 46px;
  }

  .tx-xl-46-f {
    font-size: 46px !important;
  }

  .tx-xl-48 {
    font-size: 48px;
  }

  .tx-xl-48-f {
    font-size: 48px !important;
  }

  .tx-xl-50 {
    font-size: 50px;
  }

  .tx-xl-50-f {
    font-size: 50px !important;
  }

  .tx-xl-52 {
    font-size: 52px;
  }

  .tx-xl-52-f {
    font-size: 52px !important;
  }

  .tx-xl-54 {
    font-size: 54px;
  }

  .tx-xl-54-f {
    font-size: 54px !important;
  }

  .tx-xl-56 {
    font-size: 56px;
  }

  .tx-xl-56-f {
    font-size: 56px !important;
  }

  .tx-xl-58 {
    font-size: 58px;
  }

  .tx-xl-58-f {
    font-size: 58px !important;
  }

  .tx-xl-60 {
    font-size: 60px;
  }

  .tx-xl-60-f {
    font-size: 60px !important;
  }

  .tx-xl-62 {
    font-size: 62px;
  }

  .tx-xl-62-f {
    font-size: 62px !important;
  }

  .tx-xl-64 {
    font-size: 64px;
  }

  .tx-xl-64-f {
    font-size: 64px !important;
  }

  .tx-xl-66 {
    font-size: 66px;
  }

  .tx-xl-66-f {
    font-size: 66px !important;
  }

  .tx-xl-68 {
    font-size: 68px;
  }

  .tx-xl-68-f {
    font-size: 68px !important;
  }

  .tx-xl-70 {
    font-size: 70px;
  }

  .tx-xl-70-f {
    font-size: 70px !important;
  }

  .tx-xl-72 {
    font-size: 72px;
  }

  .tx-xl-72-f {
    font-size: 72px !important;
  }

  .tx-xl-74 {
    font-size: 74px;
  }

  .tx-xl-74-f {
    font-size: 74px !important;
  }

  .tx-xl-76 {
    font-size: 76px;
  }

  .tx-xl-76-f {
    font-size: 76px !important;
  }

  .tx-xl-78 {
    font-size: 78px;
  }

  .tx-xl-78-f {
    font-size: 78px !important;
  }

  .tx-xl-80 {
    font-size: 80px;
  }

  .tx-xl-80-f {
    font-size: 80px !important;
  }

  .tx-xl-82 {
    font-size: 82px;
  }

  .tx-xl-82-f {
    font-size: 82px !important;
  }

  .tx-xl-84 {
    font-size: 84px;
  }

  .tx-xl-84-f {
    font-size: 84px !important;
  }

  .tx-xl-86 {
    font-size: 86px;
  }

  .tx-xl-86-f {
    font-size: 86px !important;
  }

  .tx-xl-88 {
    font-size: 88px;
  }

  .tx-xl-88-f {
    font-size: 88px !important;
  }

  .tx-xl-90 {
    font-size: 90px;
  }

  .tx-xl-90-f {
    font-size: 90px !important;
  }

  .tx-xl-92 {
    font-size: 92px;
  }

  .tx-xl-92-f {
    font-size: 92px !important;
  }

  .tx-xl-94 {
    font-size: 94px;
  }

  .tx-xl-94-f {
    font-size: 94px !important;
  }

  .tx-xl-96 {
    font-size: 96px;
  }

  .tx-xl-96-f {
    font-size: 96px !important;
  }

  .tx-xl-98 {
    font-size: 98px;
  }

  .tx-xl-98-f {
    font-size: 98px !important;
  }

  .tx-xl-100 {
    font-size: 100px;
  }

  .tx-xl-100-f {
    font-size: 100px !important;
  }

  .tx-xl-102 {
    font-size: 102px;
  }

  .tx-xl-102-f {
    font-size: 102px !important;
  }

  .tx-xl-104 {
    font-size: 104px;
  }

  .tx-xl-104-f {
    font-size: 104px !important;
  }

  .tx-xl-106 {
    font-size: 106px;
  }

  .tx-xl-106-f {
    font-size: 106px !important;
  }

  .tx-xl-108 {
    font-size: 108px;
  }

  .tx-xl-108-f {
    font-size: 108px !important;
  }

  .tx-xl-110 {
    font-size: 110px;
  }

  .tx-xl-110-f {
    font-size: 110px !important;
  }

  .tx-xl-112 {
    font-size: 112px;
  }

  .tx-xl-112-f {
    font-size: 112px !important;
  }

  .tx-xl-114 {
    font-size: 114px;
  }

  .tx-xl-114-f {
    font-size: 114px !important;
  }

  .tx-xl-116 {
    font-size: 116px;
  }

  .tx-xl-116-f {
    font-size: 116px !important;
  }

  .tx-xl-118 {
    font-size: 118px;
  }

  .tx-xl-118-f {
    font-size: 118px !important;
  }

  .tx-xl-120 {
    font-size: 120px;
  }

  .tx-xl-120-f {
    font-size: 120px !important;
  }

  .tx-xl-122 {
    font-size: 122px;
  }

  .tx-xl-122-f {
    font-size: 122px !important;
  }

  .tx-xl-124 {
    font-size: 124px;
  }

  .tx-xl-124-f {
    font-size: 124px !important;
  }

  .tx-xl-126 {
    font-size: 126px;
  }

  .tx-xl-126-f {
    font-size: 126px !important;
  }

  .tx-xl-128 {
    font-size: 128px;
  }

  .tx-xl-128-f {
    font-size: 128px !important;
  }

  .tx-xl-130 {
    font-size: 130px;
  }

  .tx-xl-130-f {
    font-size: 130px !important;
  }

  .tx-xl-132 {
    font-size: 132px;
  }

  .tx-xl-132-f {
    font-size: 132px !important;
  }

  .tx-xl-134 {
    font-size: 134px;
  }

  .tx-xl-134-f {
    font-size: 134px !important;
  }

  .tx-xl-136 {
    font-size: 136px;
  }

  .tx-xl-136-f {
    font-size: 136px !important;
  }

  .tx-xl-138 {
    font-size: 138px;
  }

  .tx-xl-138-f {
    font-size: 138px !important;
  }

  .tx-xl-140 {
    font-size: 140px;
  }

  .tx-xl-140-f {
    font-size: 140px !important;
  }

  .tx-xl-base {
    font-size: 0.85rem;
  }

  .tx-xl-bold {
    font-weight: 700;
  }

  .tx-xl-semibold {
    font-weight: 600;
  }

  .tx-xl-medium {
    font-weight: 500;
  }

  .tx-xl-normal {
    font-weight: 400;
  }

  .tx-xl-light {
    font-weight: 300;
  }

  .tx-xl-thin {
    font-weight: 200;
  }

  .tx-xl-xthin {
    font-weight: 100;
  }

  .lh-xl-0 {
    line-height: 0;
  }

  .lh-xl-1 {
    line-height: 1.1;
  }

  .lh-xl-2 {
    line-height: 1.2;
  }

  .lh-xl-3 {
    line-height: 1.3;
  }

  .lh-xl-4 {
    line-height: 1.4;
  }

  .lh-xl-5 {
    line-height: 1.5;
  }

  .lh-xl-6 {
    line-height: 1.6;
  }

  .lh-xl-7 {
    line-height: 1.7;
  }

  .lh-xl-8 {
    line-height: 1.8;
  }

  .lh-xl-9 {
    line-height: 1.9;
  }

  .lh-xl-10 {
    line-height: 2;
  }

  .lh-xl-11 {
    line-height: 2.1;
  }

  .lh-xl-12 {
    line-height: 2.2;
  }

  .lh-xl-13 {
    line-height: 2.3;
  }

  .lh-xl-14 {
    line-height: 2.4;
  }

  .lh-xl-15 {
    line-height: 2.5;
  }

  .tx-xl-center {
    text-align: center;
  }

  .tx-xl-right {
    text-align: right;
  }

  .tx-xl-left {
    text-align: left;
  }

  .tx-xl-center-f {
    text-align: center !important;
  }

  .tx-xl-right-f {
    text-align: right !important;
  }

  .tx-xl-left-f {
    text-align: left !important;
  }
}
.word-break {
  word-break: break-word;
}

.tx-republica {
  font-family: RepublicaMinor, serif;
  letter-spacing: 5px;
}

/* ###### 9.9 Width   ###### */
.wd-1 {
  width: 1px;
}

.wd-2 {
  width: 2px;
}

.wd-3 {
  width: 3px;
}

.wd-4 {
  width: 4px;
}

.wd-6 {
  width: 6px;
}

.wd-7 {
  width: 7px;
}

.wd-8 {
  width: 8px;
}

.wd-9 {
  width: 9px;
}

.wd-280 {
  width: 280px;
}

.wd-5 {
  width: 5px;
}

.wd-5p {
  width: 5%;
}

.mx-wd-5p {
  max-width: 5%;
}

.mn-wd-5p {
  min-width: 5%;
}

.mx-wd-5 {
  max-width: 5px;
}

.mn-wd-5 {
  min-width: 5px;
}

.wd-5-f {
  width: 5px !important;
}

.wd-5p-f {
  width: 5% !important;
}

.mx-wd-5p-f {
  max-width: 5% !important;
}

.mn-wd-5p-f {
  min-width: 5% !important;
}

.mx-wd-5-f {
  max-width: 5px !important;
}

.mn-wd-5-f {
  min-width: 5px !important;
}

.wd-10 {
  width: 10px;
}

.wd-10p {
  width: 10%;
}

.mx-wd-10p {
  max-width: 10%;
}

.mn-wd-10p {
  min-width: 10%;
}

.mx-wd-10 {
  max-width: 10px;
}

.mn-wd-10 {
  min-width: 10px;
}

.wd-10-f {
  width: 10px !important;
}

.wd-10p-f {
  width: 10% !important;
}

.mx-wd-10p-f {
  max-width: 10% !important;
}

.mn-wd-10p-f {
  min-width: 10% !important;
}

.mx-wd-10-f {
  max-width: 10px !important;
}

.mn-wd-10-f {
  min-width: 10px !important;
}

.wd-15 {
  width: 15px;
}

.wd-15p {
  width: 15%;
}

.mx-wd-15p {
  max-width: 15%;
}

.mn-wd-15p {
  min-width: 15%;
}

.mx-wd-15 {
  max-width: 15px;
}

.mn-wd-15 {
  min-width: 15px;
}

.wd-15-f {
  width: 15px !important;
}

.wd-15p-f {
  width: 15% !important;
}

.mx-wd-15p-f {
  max-width: 15% !important;
}

.mn-wd-15p-f {
  min-width: 15% !important;
}

.mx-wd-15-f {
  max-width: 15px !important;
}

.mn-wd-15-f {
  min-width: 15px !important;
}

.wd-20 {
  width: 20px;
}

.wd-20p {
  width: 20%;
}

.mx-wd-20p {
  max-width: 20%;
}

.mn-wd-20p {
  min-width: 20%;
}

.mx-wd-20 {
  max-width: 20px;
}

.mn-wd-20 {
  min-width: 20px;
}

.wd-20-f {
  width: 20px !important;
}

.wd-20p-f {
  width: 20% !important;
}

.mx-wd-20p-f {
  max-width: 20% !important;
}

.mn-wd-20p-f {
  min-width: 20% !important;
}

.mx-wd-20-f {
  max-width: 20px !important;
}

.mn-wd-20-f {
  min-width: 20px !important;
}

.wd-25 {
  width: 25px;
}

.wd-25p {
  width: 25%;
}

.mx-wd-25p {
  max-width: 25%;
}

.mn-wd-25p {
  min-width: 25%;
}

.mx-wd-25 {
  max-width: 25px;
}

.mn-wd-25 {
  min-width: 25px;
}

.wd-25-f {
  width: 25px !important;
}

.wd-25p-f {
  width: 25% !important;
}

.mx-wd-25p-f {
  max-width: 25% !important;
}

.mn-wd-25p-f {
  min-width: 25% !important;
}

.mx-wd-25-f {
  max-width: 25px !important;
}

.mn-wd-25-f {
  min-width: 25px !important;
}

.wd-30 {
  width: 30px;
}

.wd-30p {
  width: 30%;
}

.mx-wd-30p {
  max-width: 30%;
}

.mn-wd-30p {
  min-width: 30%;
}

.mx-wd-30 {
  max-width: 30px;
}

.mn-wd-30 {
  min-width: 30px;
}

.wd-30-f {
  width: 30px !important;
}

.wd-30p-f {
  width: 30% !important;
}

.mx-wd-30p-f {
  max-width: 30% !important;
}

.mn-wd-30p-f {
  min-width: 30% !important;
}

.mx-wd-30-f {
  max-width: 30px !important;
}

.mn-wd-30-f {
  min-width: 30px !important;
}

.wd-35 {
  width: 35px;
}

.wd-35p {
  width: 35%;
}

.mx-wd-35p {
  max-width: 35%;
}

.mn-wd-35p {
  min-width: 35%;
}

.mx-wd-35 {
  max-width: 35px;
}

.mn-wd-35 {
  min-width: 35px;
}

.wd-35-f {
  width: 35px !important;
}

.wd-35p-f {
  width: 35% !important;
}

.mx-wd-35p-f {
  max-width: 35% !important;
}

.mn-wd-35p-f {
  min-width: 35% !important;
}

.mx-wd-35-f {
  max-width: 35px !important;
}

.mn-wd-35-f {
  min-width: 35px !important;
}

.wd-40 {
  width: 40px;
}

.wd-40p {
  width: 40%;
}

.mx-wd-40p {
  max-width: 40%;
}

.mn-wd-40p {
  min-width: 40%;
}

.mx-wd-40 {
  max-width: 40px;
}

.mn-wd-40 {
  min-width: 40px;
}

.wd-40-f {
  width: 40px !important;
}

.wd-40p-f {
  width: 40% !important;
}

.mx-wd-40p-f {
  max-width: 40% !important;
}

.mn-wd-40p-f {
  min-width: 40% !important;
}

.mx-wd-40-f {
  max-width: 40px !important;
}

.mn-wd-40-f {
  min-width: 40px !important;
}

.wd-45 {
  width: 45px;
}

.wd-45p {
  width: 45%;
}

.mx-wd-45p {
  max-width: 45%;
}

.mn-wd-45p {
  min-width: 45%;
}

.mx-wd-45 {
  max-width: 45px;
}

.mn-wd-45 {
  min-width: 45px;
}

.wd-45-f {
  width: 45px !important;
}

.wd-45p-f {
  width: 45% !important;
}

.mx-wd-45p-f {
  max-width: 45% !important;
}

.mn-wd-45p-f {
  min-width: 45% !important;
}

.mx-wd-45-f {
  max-width: 45px !important;
}

.mn-wd-45-f {
  min-width: 45px !important;
}

.wd-50 {
  width: 50px;
}

.wd-50p {
  width: 50%;
}

.mx-wd-50p {
  max-width: 50%;
}

.mn-wd-50p {
  min-width: 50%;
}

.mx-wd-50 {
  max-width: 50px;
}

.mn-wd-50 {
  min-width: 50px;
}

.wd-50-f {
  width: 50px !important;
}

.wd-50p-f {
  width: 50% !important;
}

.mx-wd-50p-f {
  max-width: 50% !important;
}

.mn-wd-50p-f {
  min-width: 50% !important;
}

.mx-wd-50-f {
  max-width: 50px !important;
}

.mn-wd-50-f {
  min-width: 50px !important;
}

.wd-55 {
  width: 55px;
}

.wd-55p {
  width: 55%;
}

.mx-wd-55p {
  max-width: 55%;
}

.mn-wd-55p {
  min-width: 55%;
}

.mx-wd-55 {
  max-width: 55px;
}

.mn-wd-55 {
  min-width: 55px;
}

.wd-55-f {
  width: 55px !important;
}

.wd-55p-f {
  width: 55% !important;
}

.mx-wd-55p-f {
  max-width: 55% !important;
}

.mn-wd-55p-f {
  min-width: 55% !important;
}

.mx-wd-55-f {
  max-width: 55px !important;
}

.mn-wd-55-f {
  min-width: 55px !important;
}

.wd-60 {
  width: 60px;
}

.wd-60p {
  width: 60%;
}

.mx-wd-60p {
  max-width: 60%;
}

.mn-wd-60p {
  min-width: 60%;
}

.mx-wd-60 {
  max-width: 60px;
}

.mn-wd-60 {
  min-width: 60px;
}

.wd-60-f {
  width: 60px !important;
}

.wd-60p-f {
  width: 60% !important;
}

.mx-wd-60p-f {
  max-width: 60% !important;
}

.mn-wd-60p-f {
  min-width: 60% !important;
}

.mx-wd-60-f {
  max-width: 60px !important;
}

.mn-wd-60-f {
  min-width: 60px !important;
}

.wd-65 {
  width: 65px;
}

.wd-65p {
  width: 65%;
}

.mx-wd-65p {
  max-width: 65%;
}

.mn-wd-65p {
  min-width: 65%;
}

.mx-wd-65 {
  max-width: 65px;
}

.mn-wd-65 {
  min-width: 65px;
}

.wd-65-f {
  width: 65px !important;
}

.wd-65p-f {
  width: 65% !important;
}

.mx-wd-65p-f {
  max-width: 65% !important;
}

.mn-wd-65p-f {
  min-width: 65% !important;
}

.mx-wd-65-f {
  max-width: 65px !important;
}

.mn-wd-65-f {
  min-width: 65px !important;
}

.wd-70 {
  width: 70px;
}

.wd-70p {
  width: 70%;
}

.mx-wd-70p {
  max-width: 70%;
}

.mn-wd-70p {
  min-width: 70%;
}

.mx-wd-70 {
  max-width: 70px;
}

.mn-wd-70 {
  min-width: 70px;
}

.wd-70-f {
  width: 70px !important;
}

.wd-70p-f {
  width: 70% !important;
}

.mx-wd-70p-f {
  max-width: 70% !important;
}

.mn-wd-70p-f {
  min-width: 70% !important;
}

.mx-wd-70-f {
  max-width: 70px !important;
}

.mn-wd-70-f {
  min-width: 70px !important;
}

.wd-75 {
  width: 75px;
}

.wd-75p {
  width: 75%;
}

.mx-wd-75p {
  max-width: 75%;
}

.mn-wd-75p {
  min-width: 75%;
}

.mx-wd-75 {
  max-width: 75px;
}

.mn-wd-75 {
  min-width: 75px;
}

.wd-75-f {
  width: 75px !important;
}

.wd-75p-f {
  width: 75% !important;
}

.mx-wd-75p-f {
  max-width: 75% !important;
}

.mn-wd-75p-f {
  min-width: 75% !important;
}

.mx-wd-75-f {
  max-width: 75px !important;
}

.mn-wd-75-f {
  min-width: 75px !important;
}

.wd-80 {
  width: 80px;
}

.wd-80p {
  width: 80%;
}

.mx-wd-80p {
  max-width: 80%;
}

.mn-wd-80p {
  min-width: 80%;
}

.mx-wd-80 {
  max-width: 80px;
}

.mn-wd-80 {
  min-width: 80px;
}

.wd-80-f {
  width: 80px !important;
}

.wd-80p-f {
  width: 80% !important;
}

.mx-wd-80p-f {
  max-width: 80% !important;
}

.mn-wd-80p-f {
  min-width: 80% !important;
}

.mx-wd-80-f {
  max-width: 80px !important;
}

.mn-wd-80-f {
  min-width: 80px !important;
}

.wd-85 {
  width: 85px;
}

.wd-85p {
  width: 85%;
}

.mx-wd-85p {
  max-width: 85%;
}

.mn-wd-85p {
  min-width: 85%;
}

.mx-wd-85 {
  max-width: 85px;
}

.mn-wd-85 {
  min-width: 85px;
}

.wd-85-f {
  width: 85px !important;
}

.wd-85p-f {
  width: 85% !important;
}

.mx-wd-85p-f {
  max-width: 85% !important;
}

.mn-wd-85p-f {
  min-width: 85% !important;
}

.mx-wd-85-f {
  max-width: 85px !important;
}

.mn-wd-85-f {
  min-width: 85px !important;
}

.wd-90 {
  width: 90px;
}

.wd-90p {
  width: 90%;
}

.mx-wd-90p {
  max-width: 90%;
}

.mn-wd-90p {
  min-width: 90%;
}

.mx-wd-90 {
  max-width: 90px;
}

.mn-wd-90 {
  min-width: 90px;
}

.wd-90-f {
  width: 90px !important;
}

.wd-90p-f {
  width: 90% !important;
}

.mx-wd-90p-f {
  max-width: 90% !important;
}

.mn-wd-90p-f {
  min-width: 90% !important;
}

.mx-wd-90-f {
  max-width: 90px !important;
}

.mn-wd-90-f {
  min-width: 90px !important;
}

.wd-95 {
  width: 95px;
}

.wd-95p {
  width: 95%;
}

.mx-wd-95p {
  max-width: 95%;
}

.mn-wd-95p {
  min-width: 95%;
}

.mx-wd-95 {
  max-width: 95px;
}

.mn-wd-95 {
  min-width: 95px;
}

.wd-95-f {
  width: 95px !important;
}

.wd-95p-f {
  width: 95% !important;
}

.mx-wd-95p-f {
  max-width: 95% !important;
}

.mn-wd-95p-f {
  min-width: 95% !important;
}

.mx-wd-95-f {
  max-width: 95px !important;
}

.mn-wd-95-f {
  min-width: 95px !important;
}

.wd-100 {
  width: 100px;
}

.wd-100p {
  width: 100%;
}

.mx-wd-100p {
  max-width: 100%;
}

.mn-wd-100p {
  min-width: 100%;
}

.mx-wd-100 {
  max-width: 100px;
}

.mn-wd-100 {
  min-width: 100px;
}

.wd-100-f {
  width: 100px !important;
}

.wd-100p-f {
  width: 100% !important;
}

.mx-wd-100p-f {
  max-width: 100% !important;
}

.mn-wd-100p-f {
  min-width: 100% !important;
}

.mx-wd-100-f {
  max-width: 100px !important;
}

.mn-wd-100-f {
  min-width: 100px !important;
}

.wd-105 {
  width: 105px;
}

.wd-105p {
  width: 105%;
}

.mx-wd-105p {
  max-width: 105%;
}

.mn-wd-105p {
  min-width: 105%;
}

.mx-wd-105 {
  max-width: 105px;
}

.mn-wd-105 {
  min-width: 105px;
}

.wd-105-f {
  width: 105px !important;
}

.wd-105p-f {
  width: 105% !important;
}

.mx-wd-105p-f {
  max-width: 105% !important;
}

.mn-wd-105p-f {
  min-width: 105% !important;
}

.mx-wd-105-f {
  max-width: 105px !important;
}

.mn-wd-105-f {
  min-width: 105px !important;
}

.wd-110 {
  width: 110px;
}

.wd-110p {
  width: 110%;
}

.mx-wd-110p {
  max-width: 110%;
}

.mn-wd-110p {
  min-width: 110%;
}

.mx-wd-110 {
  max-width: 110px;
}

.mn-wd-110 {
  min-width: 110px;
}

.wd-110-f {
  width: 110px !important;
}

.wd-110p-f {
  width: 110% !important;
}

.mx-wd-110p-f {
  max-width: 110% !important;
}

.mn-wd-110p-f {
  min-width: 110% !important;
}

.mx-wd-110-f {
  max-width: 110px !important;
}

.mn-wd-110-f {
  min-width: 110px !important;
}

.wd-115 {
  width: 115px;
}

.wd-115p {
  width: 115%;
}

.mx-wd-115p {
  max-width: 115%;
}

.mn-wd-115p {
  min-width: 115%;
}

.mx-wd-115 {
  max-width: 115px;
}

.mn-wd-115 {
  min-width: 115px;
}

.wd-115-f {
  width: 115px !important;
}

.wd-115p-f {
  width: 115% !important;
}

.mx-wd-115p-f {
  max-width: 115% !important;
}

.mn-wd-115p-f {
  min-width: 115% !important;
}

.mx-wd-115-f {
  max-width: 115px !important;
}

.mn-wd-115-f {
  min-width: 115px !important;
}

.wd-120 {
  width: 120px;
}

.wd-120p {
  width: 120%;
}

.mx-wd-120p {
  max-width: 120%;
}

.mn-wd-120p {
  min-width: 120%;
}

.mx-wd-120 {
  max-width: 120px;
}

.mn-wd-120 {
  min-width: 120px;
}

.wd-120-f {
  width: 120px !important;
}

.wd-120p-f {
  width: 120% !important;
}

.mx-wd-120p-f {
  max-width: 120% !important;
}

.mn-wd-120p-f {
  min-width: 120% !important;
}

.mx-wd-120-f {
  max-width: 120px !important;
}

.mn-wd-120-f {
  min-width: 120px !important;
}

.wd-125 {
  width: 125px;
}

.wd-125p {
  width: 125%;
}

.mx-wd-125p {
  max-width: 125%;
}

.mn-wd-125p {
  min-width: 125%;
}

.mx-wd-125 {
  max-width: 125px;
}

.mn-wd-125 {
  min-width: 125px;
}

.wd-125-f {
  width: 125px !important;
}

.wd-125p-f {
  width: 125% !important;
}

.mx-wd-125p-f {
  max-width: 125% !important;
}

.mn-wd-125p-f {
  min-width: 125% !important;
}

.mx-wd-125-f {
  max-width: 125px !important;
}

.mn-wd-125-f {
  min-width: 125px !important;
}

.wd-130 {
  width: 130px;
}

.wd-130p {
  width: 130%;
}

.mx-wd-130p {
  max-width: 130%;
}

.mn-wd-130p {
  min-width: 130%;
}

.mx-wd-130 {
  max-width: 130px;
}

.mn-wd-130 {
  min-width: 130px;
}

.wd-130-f {
  width: 130px !important;
}

.wd-130p-f {
  width: 130% !important;
}

.mx-wd-130p-f {
  max-width: 130% !important;
}

.mn-wd-130p-f {
  min-width: 130% !important;
}

.mx-wd-130-f {
  max-width: 130px !important;
}

.mn-wd-130-f {
  min-width: 130px !important;
}

.wd-135 {
  width: 135px;
}

.wd-135p {
  width: 135%;
}

.mx-wd-135p {
  max-width: 135%;
}

.mn-wd-135p {
  min-width: 135%;
}

.mx-wd-135 {
  max-width: 135px;
}

.mn-wd-135 {
  min-width: 135px;
}

.wd-135-f {
  width: 135px !important;
}

.wd-135p-f {
  width: 135% !important;
}

.mx-wd-135p-f {
  max-width: 135% !important;
}

.mn-wd-135p-f {
  min-width: 135% !important;
}

.mx-wd-135-f {
  max-width: 135px !important;
}

.mn-wd-135-f {
  min-width: 135px !important;
}

.wd-140 {
  width: 140px;
}

.wd-140p {
  width: 140%;
}

.mx-wd-140p {
  max-width: 140%;
}

.mn-wd-140p {
  min-width: 140%;
}

.mx-wd-140 {
  max-width: 140px;
}

.mn-wd-140 {
  min-width: 140px;
}

.wd-140-f {
  width: 140px !important;
}

.wd-140p-f {
  width: 140% !important;
}

.mx-wd-140p-f {
  max-width: 140% !important;
}

.mn-wd-140p-f {
  min-width: 140% !important;
}

.mx-wd-140-f {
  max-width: 140px !important;
}

.mn-wd-140-f {
  min-width: 140px !important;
}

.wd-145 {
  width: 145px;
}

.wd-145p {
  width: 145%;
}

.mx-wd-145p {
  max-width: 145%;
}

.mn-wd-145p {
  min-width: 145%;
}

.mx-wd-145 {
  max-width: 145px;
}

.mn-wd-145 {
  min-width: 145px;
}

.wd-145-f {
  width: 145px !important;
}

.wd-145p-f {
  width: 145% !important;
}

.mx-wd-145p-f {
  max-width: 145% !important;
}

.mn-wd-145p-f {
  min-width: 145% !important;
}

.mx-wd-145-f {
  max-width: 145px !important;
}

.mn-wd-145-f {
  min-width: 145px !important;
}

.wd-150 {
  width: 150px;
}

.wd-150p {
  width: 150%;
}

.mx-wd-150p {
  max-width: 150%;
}

.mn-wd-150p {
  min-width: 150%;
}

.mx-wd-150 {
  max-width: 150px;
}

.mn-wd-150 {
  min-width: 150px;
}

.wd-150-f {
  width: 150px !important;
}

.wd-150p-f {
  width: 150% !important;
}

.mx-wd-150p-f {
  max-width: 150% !important;
}

.mn-wd-150p-f {
  min-width: 150% !important;
}

.mx-wd-150-f {
  max-width: 150px !important;
}

.mn-wd-150-f {
  min-width: 150px !important;
}

.wd-155 {
  width: 155px;
}

.wd-155p {
  width: 155%;
}

.mx-wd-155p {
  max-width: 155%;
}

.mn-wd-155p {
  min-width: 155%;
}

.mx-wd-155 {
  max-width: 155px;
}

.mn-wd-155 {
  min-width: 155px;
}

.wd-155-f {
  width: 155px !important;
}

.wd-155p-f {
  width: 155% !important;
}

.mx-wd-155p-f {
  max-width: 155% !important;
}

.mn-wd-155p-f {
  min-width: 155% !important;
}

.mx-wd-155-f {
  max-width: 155px !important;
}

.mn-wd-155-f {
  min-width: 155px !important;
}

.wd-160 {
  width: 160px;
}

.wd-160p {
  width: 160%;
}

.mx-wd-160p {
  max-width: 160%;
}

.mn-wd-160p {
  min-width: 160%;
}

.mx-wd-160 {
  max-width: 160px;
}

.mn-wd-160 {
  min-width: 160px;
}

.wd-160-f {
  width: 160px !important;
}

.wd-160p-f {
  width: 160% !important;
}

.mx-wd-160p-f {
  max-width: 160% !important;
}

.mn-wd-160p-f {
  min-width: 160% !important;
}

.mx-wd-160-f {
  max-width: 160px !important;
}

.mn-wd-160-f {
  min-width: 160px !important;
}

.wd-165 {
  width: 165px;
}

.wd-165p {
  width: 165%;
}

.mx-wd-165p {
  max-width: 165%;
}

.mn-wd-165p {
  min-width: 165%;
}

.mx-wd-165 {
  max-width: 165px;
}

.mn-wd-165 {
  min-width: 165px;
}

.wd-165-f {
  width: 165px !important;
}

.wd-165p-f {
  width: 165% !important;
}

.mx-wd-165p-f {
  max-width: 165% !important;
}

.mn-wd-165p-f {
  min-width: 165% !important;
}

.mx-wd-165-f {
  max-width: 165px !important;
}

.mn-wd-165-f {
  min-width: 165px !important;
}

.wd-170 {
  width: 170px;
}

.wd-170p {
  width: 170%;
}

.mx-wd-170p {
  max-width: 170%;
}

.mn-wd-170p {
  min-width: 170%;
}

.mx-wd-170 {
  max-width: 170px;
}

.mn-wd-170 {
  min-width: 170px;
}

.wd-170-f {
  width: 170px !important;
}

.wd-170p-f {
  width: 170% !important;
}

.mx-wd-170p-f {
  max-width: 170% !important;
}

.mn-wd-170p-f {
  min-width: 170% !important;
}

.mx-wd-170-f {
  max-width: 170px !important;
}

.mn-wd-170-f {
  min-width: 170px !important;
}

.wd-175 {
  width: 175px;
}

.wd-175p {
  width: 175%;
}

.mx-wd-175p {
  max-width: 175%;
}

.mn-wd-175p {
  min-width: 175%;
}

.mx-wd-175 {
  max-width: 175px;
}

.mn-wd-175 {
  min-width: 175px;
}

.wd-175-f {
  width: 175px !important;
}

.wd-175p-f {
  width: 175% !important;
}

.mx-wd-175p-f {
  max-width: 175% !important;
}

.mn-wd-175p-f {
  min-width: 175% !important;
}

.mx-wd-175-f {
  max-width: 175px !important;
}

.mn-wd-175-f {
  min-width: 175px !important;
}

.wd-180 {
  width: 180px;
}

.wd-180p {
  width: 180%;
}

.mx-wd-180p {
  max-width: 180%;
}

.mn-wd-180p {
  min-width: 180%;
}

.mx-wd-180 {
  max-width: 180px;
}

.mn-wd-180 {
  min-width: 180px;
}

.wd-180-f {
  width: 180px !important;
}

.wd-180p-f {
  width: 180% !important;
}

.mx-wd-180p-f {
  max-width: 180% !important;
}

.mn-wd-180p-f {
  min-width: 180% !important;
}

.mx-wd-180-f {
  max-width: 180px !important;
}

.mn-wd-180-f {
  min-width: 180px !important;
}

.wd-185 {
  width: 185px;
}

.wd-185p {
  width: 185%;
}

.mx-wd-185p {
  max-width: 185%;
}

.mn-wd-185p {
  min-width: 185%;
}

.mx-wd-185 {
  max-width: 185px;
}

.mn-wd-185 {
  min-width: 185px;
}

.wd-185-f {
  width: 185px !important;
}

.wd-185p-f {
  width: 185% !important;
}

.mx-wd-185p-f {
  max-width: 185% !important;
}

.mn-wd-185p-f {
  min-width: 185% !important;
}

.mx-wd-185-f {
  max-width: 185px !important;
}

.mn-wd-185-f {
  min-width: 185px !important;
}

.wd-190 {
  width: 190px;
}

.wd-190p {
  width: 190%;
}

.mx-wd-190p {
  max-width: 190%;
}

.mn-wd-190p {
  min-width: 190%;
}

.mx-wd-190 {
  max-width: 190px;
}

.mn-wd-190 {
  min-width: 190px;
}

.wd-190-f {
  width: 190px !important;
}

.wd-190p-f {
  width: 190% !important;
}

.mx-wd-190p-f {
  max-width: 190% !important;
}

.mn-wd-190p-f {
  min-width: 190% !important;
}

.mx-wd-190-f {
  max-width: 190px !important;
}

.mn-wd-190-f {
  min-width: 190px !important;
}

.wd-195 {
  width: 195px;
}

.wd-195p {
  width: 195%;
}

.mx-wd-195p {
  max-width: 195%;
}

.mn-wd-195p {
  min-width: 195%;
}

.mx-wd-195 {
  max-width: 195px;
}

.mn-wd-195 {
  min-width: 195px;
}

.wd-195-f {
  width: 195px !important;
}

.wd-195p-f {
  width: 195% !important;
}

.mx-wd-195p-f {
  max-width: 195% !important;
}

.mn-wd-195p-f {
  min-width: 195% !important;
}

.mx-wd-195-f {
  max-width: 195px !important;
}

.mn-wd-195-f {
  min-width: 195px !important;
}

.wd-200 {
  width: 200px;
}

.wd-200p {
  width: 200%;
}

.mx-wd-200p {
  max-width: 200%;
}

.mn-wd-200p {
  min-width: 200%;
}

.mx-wd-200 {
  max-width: 200px;
}

.mn-wd-200 {
  min-width: 200px;
}

.wd-200-f {
  width: 200px !important;
}

.wd-200p-f {
  width: 200% !important;
}

.mx-wd-200p-f {
  max-width: 200% !important;
}

.mn-wd-200p-f {
  min-width: 200% !important;
}

.mx-wd-200-f {
  max-width: 200px !important;
}

.mn-wd-200-f {
  min-width: 200px !important;
}

.wd-205 {
  width: 205px;
}

.wd-205p {
  width: 205%;
}

.mx-wd-205p {
  max-width: 205%;
}

.mn-wd-205p {
  min-width: 205%;
}

.mx-wd-205 {
  max-width: 205px;
}

.mn-wd-205 {
  min-width: 205px;
}

.wd-205-f {
  width: 205px !important;
}

.wd-205p-f {
  width: 205% !important;
}

.mx-wd-205p-f {
  max-width: 205% !important;
}

.mn-wd-205p-f {
  min-width: 205% !important;
}

.mx-wd-205-f {
  max-width: 205px !important;
}

.mn-wd-205-f {
  min-width: 205px !important;
}

.wd-210 {
  width: 210px;
}

.wd-210p {
  width: 210%;
}

.mx-wd-210p {
  max-width: 210%;
}

.mn-wd-210p {
  min-width: 210%;
}

.mx-wd-210 {
  max-width: 210px;
}

.mn-wd-210 {
  min-width: 210px;
}

.wd-210-f {
  width: 210px !important;
}

.wd-210p-f {
  width: 210% !important;
}

.mx-wd-210p-f {
  max-width: 210% !important;
}

.mn-wd-210p-f {
  min-width: 210% !important;
}

.mx-wd-210-f {
  max-width: 210px !important;
}

.mn-wd-210-f {
  min-width: 210px !important;
}

.wd-215 {
  width: 215px;
}

.wd-215p {
  width: 215%;
}

.mx-wd-215p {
  max-width: 215%;
}

.mn-wd-215p {
  min-width: 215%;
}

.mx-wd-215 {
  max-width: 215px;
}

.mn-wd-215 {
  min-width: 215px;
}

.wd-215-f {
  width: 215px !important;
}

.wd-215p-f {
  width: 215% !important;
}

.mx-wd-215p-f {
  max-width: 215% !important;
}

.mn-wd-215p-f {
  min-width: 215% !important;
}

.mx-wd-215-f {
  max-width: 215px !important;
}

.mn-wd-215-f {
  min-width: 215px !important;
}

.wd-220 {
  width: 220px;
}

.wd-220p {
  width: 220%;
}

.mx-wd-220p {
  max-width: 220%;
}

.mn-wd-220p {
  min-width: 220%;
}

.mx-wd-220 {
  max-width: 220px;
}

.mn-wd-220 {
  min-width: 220px;
}

.wd-220-f {
  width: 220px !important;
}

.wd-220p-f {
  width: 220% !important;
}

.mx-wd-220p-f {
  max-width: 220% !important;
}

.mn-wd-220p-f {
  min-width: 220% !important;
}

.mx-wd-220-f {
  max-width: 220px !important;
}

.mn-wd-220-f {
  min-width: 220px !important;
}

.wd-225 {
  width: 225px;
}

.wd-225p {
  width: 225%;
}

.mx-wd-225p {
  max-width: 225%;
}

.mn-wd-225p {
  min-width: 225%;
}

.mx-wd-225 {
  max-width: 225px;
}

.mn-wd-225 {
  min-width: 225px;
}

.wd-225-f {
  width: 225px !important;
}

.wd-225p-f {
  width: 225% !important;
}

.mx-wd-225p-f {
  max-width: 225% !important;
}

.mn-wd-225p-f {
  min-width: 225% !important;
}

.mx-wd-225-f {
  max-width: 225px !important;
}

.mn-wd-225-f {
  min-width: 225px !important;
}

.wd-230 {
  width: 230px;
}

.wd-230p {
  width: 230%;
}

.mx-wd-230p {
  max-width: 230%;
}

.mn-wd-230p {
  min-width: 230%;
}

.mx-wd-230 {
  max-width: 230px;
}

.mn-wd-230 {
  min-width: 230px;
}

.wd-230-f {
  width: 230px !important;
}

.wd-230p-f {
  width: 230% !important;
}

.mx-wd-230p-f {
  max-width: 230% !important;
}

.mn-wd-230p-f {
  min-width: 230% !important;
}

.mx-wd-230-f {
  max-width: 230px !important;
}

.mn-wd-230-f {
  min-width: 230px !important;
}

.wd-235 {
  width: 235px;
}

.wd-235p {
  width: 235%;
}

.mx-wd-235p {
  max-width: 235%;
}

.mn-wd-235p {
  min-width: 235%;
}

.mx-wd-235 {
  max-width: 235px;
}

.mn-wd-235 {
  min-width: 235px;
}

.wd-235-f {
  width: 235px !important;
}

.wd-235p-f {
  width: 235% !important;
}

.mx-wd-235p-f {
  max-width: 235% !important;
}

.mn-wd-235p-f {
  min-width: 235% !important;
}

.mx-wd-235-f {
  max-width: 235px !important;
}

.mn-wd-235-f {
  min-width: 235px !important;
}

.wd-240 {
  width: 240px;
}

.wd-240p {
  width: 240%;
}

.mx-wd-240p {
  max-width: 240%;
}

.mn-wd-240p {
  min-width: 240%;
}

.mx-wd-240 {
  max-width: 240px;
}

.mn-wd-240 {
  min-width: 240px;
}

.wd-240-f {
  width: 240px !important;
}

.wd-240p-f {
  width: 240% !important;
}

.mx-wd-240p-f {
  max-width: 240% !important;
}

.mn-wd-240p-f {
  min-width: 240% !important;
}

.mx-wd-240-f {
  max-width: 240px !important;
}

.mn-wd-240-f {
  min-width: 240px !important;
}

.wd-245 {
  width: 245px;
}

.wd-245p {
  width: 245%;
}

.mx-wd-245p {
  max-width: 245%;
}

.mn-wd-245p {
  min-width: 245%;
}

.mx-wd-245 {
  max-width: 245px;
}

.mn-wd-245 {
  min-width: 245px;
}

.wd-245-f {
  width: 245px !important;
}

.wd-245p-f {
  width: 245% !important;
}

.mx-wd-245p-f {
  max-width: 245% !important;
}

.mn-wd-245p-f {
  min-width: 245% !important;
}

.mx-wd-245-f {
  max-width: 245px !important;
}

.mn-wd-245-f {
  min-width: 245px !important;
}

.wd-250 {
  width: 250px;
}

.wd-250p {
  width: 250%;
}

.mx-wd-250p {
  max-width: 250%;
}

.mn-wd-250p {
  min-width: 250%;
}

.mx-wd-250 {
  max-width: 250px;
}

.mn-wd-250 {
  min-width: 250px;
}

.wd-250-f {
  width: 250px !important;
}

.wd-250p-f {
  width: 250% !important;
}

.mx-wd-250p-f {
  max-width: 250% !important;
}

.mn-wd-250p-f {
  min-width: 250% !important;
}

.mx-wd-250-f {
  max-width: 250px !important;
}

.mn-wd-250-f {
  min-width: 250px !important;
}

.wd-255 {
  width: 255px;
}

.wd-255p {
  width: 255%;
}

.mx-wd-255p {
  max-width: 255%;
}

.mn-wd-255p {
  min-width: 255%;
}

.mx-wd-255 {
  max-width: 255px;
}

.mn-wd-255 {
  min-width: 255px;
}

.wd-255-f {
  width: 255px !important;
}

.wd-255p-f {
  width: 255% !important;
}

.mx-wd-255p-f {
  max-width: 255% !important;
}

.mn-wd-255p-f {
  min-width: 255% !important;
}

.mx-wd-255-f {
  max-width: 255px !important;
}

.mn-wd-255-f {
  min-width: 255px !important;
}

.wd-260 {
  width: 260px;
}

.wd-260p {
  width: 260%;
}

.mx-wd-260p {
  max-width: 260%;
}

.mn-wd-260p {
  min-width: 260%;
}

.mx-wd-260 {
  max-width: 260px;
}

.mn-wd-260 {
  min-width: 260px;
}

.wd-260-f {
  width: 260px !important;
}

.wd-260p-f {
  width: 260% !important;
}

.mx-wd-260p-f {
  max-width: 260% !important;
}

.mn-wd-260p-f {
  min-width: 260% !important;
}

.mx-wd-260-f {
  max-width: 260px !important;
}

.mn-wd-260-f {
  min-width: 260px !important;
}

.wd-265 {
  width: 265px;
}

.wd-265p {
  width: 265%;
}

.mx-wd-265p {
  max-width: 265%;
}

.mn-wd-265p {
  min-width: 265%;
}

.mx-wd-265 {
  max-width: 265px;
}

.mn-wd-265 {
  min-width: 265px;
}

.wd-265-f {
  width: 265px !important;
}

.wd-265p-f {
  width: 265% !important;
}

.mx-wd-265p-f {
  max-width: 265% !important;
}

.mn-wd-265p-f {
  min-width: 265% !important;
}

.mx-wd-265-f {
  max-width: 265px !important;
}

.mn-wd-265-f {
  min-width: 265px !important;
}

.wd-270 {
  width: 270px;
}

.wd-270p {
  width: 270%;
}

.mx-wd-270p {
  max-width: 270%;
}

.mn-wd-270p {
  min-width: 270%;
}

.mx-wd-270 {
  max-width: 270px;
}

.mn-wd-270 {
  min-width: 270px;
}

.wd-270-f {
  width: 270px !important;
}

.wd-270p-f {
  width: 270% !important;
}

.mx-wd-270p-f {
  max-width: 270% !important;
}

.mn-wd-270p-f {
  min-width: 270% !important;
}

.mx-wd-270-f {
  max-width: 270px !important;
}

.mn-wd-270-f {
  min-width: 270px !important;
}

.wd-275 {
  width: 275px;
}

.wd-275p {
  width: 275%;
}

.mx-wd-275p {
  max-width: 275%;
}

.mn-wd-275p {
  min-width: 275%;
}

.mx-wd-275 {
  max-width: 275px;
}

.mn-wd-275 {
  min-width: 275px;
}

.wd-275-f {
  width: 275px !important;
}

.wd-275p-f {
  width: 275% !important;
}

.mx-wd-275p-f {
  max-width: 275% !important;
}

.mn-wd-275p-f {
  min-width: 275% !important;
}

.mx-wd-275-f {
  max-width: 275px !important;
}

.mn-wd-275-f {
  min-width: 275px !important;
}

.wd-280 {
  width: 280px;
}

.wd-280p {
  width: 280%;
}

.mx-wd-280p {
  max-width: 280%;
}

.mn-wd-280p {
  min-width: 280%;
}

.mx-wd-280 {
  max-width: 280px;
}

.mn-wd-280 {
  min-width: 280px;
}

.wd-280-f {
  width: 280px !important;
}

.wd-280p-f {
  width: 280% !important;
}

.mx-wd-280p-f {
  max-width: 280% !important;
}

.mn-wd-280p-f {
  min-width: 280% !important;
}

.mx-wd-280-f {
  max-width: 280px !important;
}

.mn-wd-280-f {
  min-width: 280px !important;
}

.wd-285 {
  width: 285px;
}

.wd-285p {
  width: 285%;
}

.mx-wd-285p {
  max-width: 285%;
}

.mn-wd-285p {
  min-width: 285%;
}

.mx-wd-285 {
  max-width: 285px;
}

.mn-wd-285 {
  min-width: 285px;
}

.wd-285-f {
  width: 285px !important;
}

.wd-285p-f {
  width: 285% !important;
}

.mx-wd-285p-f {
  max-width: 285% !important;
}

.mn-wd-285p-f {
  min-width: 285% !important;
}

.mx-wd-285-f {
  max-width: 285px !important;
}

.mn-wd-285-f {
  min-width: 285px !important;
}

.wd-290 {
  width: 290px;
}

.wd-290p {
  width: 290%;
}

.mx-wd-290p {
  max-width: 290%;
}

.mn-wd-290p {
  min-width: 290%;
}

.mx-wd-290 {
  max-width: 290px;
}

.mn-wd-290 {
  min-width: 290px;
}

.wd-290-f {
  width: 290px !important;
}

.wd-290p-f {
  width: 290% !important;
}

.mx-wd-290p-f {
  max-width: 290% !important;
}

.mn-wd-290p-f {
  min-width: 290% !important;
}

.mx-wd-290-f {
  max-width: 290px !important;
}

.mn-wd-290-f {
  min-width: 290px !important;
}

.wd-295 {
  width: 295px;
}

.wd-295p {
  width: 295%;
}

.mx-wd-295p {
  max-width: 295%;
}

.mn-wd-295p {
  min-width: 295%;
}

.mx-wd-295 {
  max-width: 295px;
}

.mn-wd-295 {
  min-width: 295px;
}

.wd-295-f {
  width: 295px !important;
}

.wd-295p-f {
  width: 295% !important;
}

.mx-wd-295p-f {
  max-width: 295% !important;
}

.mn-wd-295p-f {
  min-width: 295% !important;
}

.mx-wd-295-f {
  max-width: 295px !important;
}

.mn-wd-295-f {
  min-width: 295px !important;
}

.wd-300 {
  width: 300px;
}

.wd-300p {
  width: 300%;
}

.mx-wd-300p {
  max-width: 300%;
}

.mn-wd-300p {
  min-width: 300%;
}

.mx-wd-300 {
  max-width: 300px;
}

.mn-wd-300 {
  min-width: 300px;
}

.wd-300-f {
  width: 300px !important;
}

.wd-300p-f {
  width: 300% !important;
}

.mx-wd-300p-f {
  max-width: 300% !important;
}

.mn-wd-300p-f {
  min-width: 300% !important;
}

.mx-wd-300-f {
  max-width: 300px !important;
}

.mn-wd-300-f {
  min-width: 300px !important;
}

.wd-305 {
  width: 305px;
}

.wd-305p {
  width: 305%;
}

.mx-wd-305p {
  max-width: 305%;
}

.mn-wd-305p {
  min-width: 305%;
}

.mx-wd-305 {
  max-width: 305px;
}

.mn-wd-305 {
  min-width: 305px;
}

.wd-305-f {
  width: 305px !important;
}

.wd-305p-f {
  width: 305% !important;
}

.mx-wd-305p-f {
  max-width: 305% !important;
}

.mn-wd-305p-f {
  min-width: 305% !important;
}

.mx-wd-305-f {
  max-width: 305px !important;
}

.mn-wd-305-f {
  min-width: 305px !important;
}

.wd-310 {
  width: 310px;
}

.wd-310p {
  width: 310%;
}

.mx-wd-310p {
  max-width: 310%;
}

.mn-wd-310p {
  min-width: 310%;
}

.mx-wd-310 {
  max-width: 310px;
}

.mn-wd-310 {
  min-width: 310px;
}

.wd-310-f {
  width: 310px !important;
}

.wd-310p-f {
  width: 310% !important;
}

.mx-wd-310p-f {
  max-width: 310% !important;
}

.mn-wd-310p-f {
  min-width: 310% !important;
}

.mx-wd-310-f {
  max-width: 310px !important;
}

.mn-wd-310-f {
  min-width: 310px !important;
}

.wd-315 {
  width: 315px;
}

.wd-315p {
  width: 315%;
}

.mx-wd-315p {
  max-width: 315%;
}

.mn-wd-315p {
  min-width: 315%;
}

.mx-wd-315 {
  max-width: 315px;
}

.mn-wd-315 {
  min-width: 315px;
}

.wd-315-f {
  width: 315px !important;
}

.wd-315p-f {
  width: 315% !important;
}

.mx-wd-315p-f {
  max-width: 315% !important;
}

.mn-wd-315p-f {
  min-width: 315% !important;
}

.mx-wd-315-f {
  max-width: 315px !important;
}

.mn-wd-315-f {
  min-width: 315px !important;
}

.wd-320 {
  width: 320px;
}

.wd-320p {
  width: 320%;
}

.mx-wd-320p {
  max-width: 320%;
}

.mn-wd-320p {
  min-width: 320%;
}

.mx-wd-320 {
  max-width: 320px;
}

.mn-wd-320 {
  min-width: 320px;
}

.wd-320-f {
  width: 320px !important;
}

.wd-320p-f {
  width: 320% !important;
}

.mx-wd-320p-f {
  max-width: 320% !important;
}

.mn-wd-320p-f {
  min-width: 320% !important;
}

.mx-wd-320-f {
  max-width: 320px !important;
}

.mn-wd-320-f {
  min-width: 320px !important;
}

.wd-325 {
  width: 325px;
}

.wd-325p {
  width: 325%;
}

.mx-wd-325p {
  max-width: 325%;
}

.mn-wd-325p {
  min-width: 325%;
}

.mx-wd-325 {
  max-width: 325px;
}

.mn-wd-325 {
  min-width: 325px;
}

.wd-325-f {
  width: 325px !important;
}

.wd-325p-f {
  width: 325% !important;
}

.mx-wd-325p-f {
  max-width: 325% !important;
}

.mn-wd-325p-f {
  min-width: 325% !important;
}

.mx-wd-325-f {
  max-width: 325px !important;
}

.mn-wd-325-f {
  min-width: 325px !important;
}

.wd-330 {
  width: 330px;
}

.wd-330p {
  width: 330%;
}

.mx-wd-330p {
  max-width: 330%;
}

.mn-wd-330p {
  min-width: 330%;
}

.mx-wd-330 {
  max-width: 330px;
}

.mn-wd-330 {
  min-width: 330px;
}

.wd-330-f {
  width: 330px !important;
}

.wd-330p-f {
  width: 330% !important;
}

.mx-wd-330p-f {
  max-width: 330% !important;
}

.mn-wd-330p-f {
  min-width: 330% !important;
}

.mx-wd-330-f {
  max-width: 330px !important;
}

.mn-wd-330-f {
  min-width: 330px !important;
}

.wd-335 {
  width: 335px;
}

.wd-335p {
  width: 335%;
}

.mx-wd-335p {
  max-width: 335%;
}

.mn-wd-335p {
  min-width: 335%;
}

.mx-wd-335 {
  max-width: 335px;
}

.mn-wd-335 {
  min-width: 335px;
}

.wd-335-f {
  width: 335px !important;
}

.wd-335p-f {
  width: 335% !important;
}

.mx-wd-335p-f {
  max-width: 335% !important;
}

.mn-wd-335p-f {
  min-width: 335% !important;
}

.mx-wd-335-f {
  max-width: 335px !important;
}

.mn-wd-335-f {
  min-width: 335px !important;
}

.wd-340 {
  width: 340px;
}

.wd-340p {
  width: 340%;
}

.mx-wd-340p {
  max-width: 340%;
}

.mn-wd-340p {
  min-width: 340%;
}

.mx-wd-340 {
  max-width: 340px;
}

.mn-wd-340 {
  min-width: 340px;
}

.wd-340-f {
  width: 340px !important;
}

.wd-340p-f {
  width: 340% !important;
}

.mx-wd-340p-f {
  max-width: 340% !important;
}

.mn-wd-340p-f {
  min-width: 340% !important;
}

.mx-wd-340-f {
  max-width: 340px !important;
}

.mn-wd-340-f {
  min-width: 340px !important;
}

.wd-345 {
  width: 345px;
}

.wd-345p {
  width: 345%;
}

.mx-wd-345p {
  max-width: 345%;
}

.mn-wd-345p {
  min-width: 345%;
}

.mx-wd-345 {
  max-width: 345px;
}

.mn-wd-345 {
  min-width: 345px;
}

.wd-345-f {
  width: 345px !important;
}

.wd-345p-f {
  width: 345% !important;
}

.mx-wd-345p-f {
  max-width: 345% !important;
}

.mn-wd-345p-f {
  min-width: 345% !important;
}

.mx-wd-345-f {
  max-width: 345px !important;
}

.mn-wd-345-f {
  min-width: 345px !important;
}

.wd-350 {
  width: 350px;
}

.wd-350p {
  width: 350%;
}

.mx-wd-350p {
  max-width: 350%;
}

.mn-wd-350p {
  min-width: 350%;
}

.mx-wd-350 {
  max-width: 350px;
}

.mn-wd-350 {
  min-width: 350px;
}

.wd-350-f {
  width: 350px !important;
}

.wd-350p-f {
  width: 350% !important;
}

.mx-wd-350p-f {
  max-width: 350% !important;
}

.mn-wd-350p-f {
  min-width: 350% !important;
}

.mx-wd-350-f {
  max-width: 350px !important;
}

.mn-wd-350-f {
  min-width: 350px !important;
}

.wd-355 {
  width: 355px;
}

.wd-355p {
  width: 355%;
}

.mx-wd-355p {
  max-width: 355%;
}

.mn-wd-355p {
  min-width: 355%;
}

.mx-wd-355 {
  max-width: 355px;
}

.mn-wd-355 {
  min-width: 355px;
}

.wd-355-f {
  width: 355px !important;
}

.wd-355p-f {
  width: 355% !important;
}

.mx-wd-355p-f {
  max-width: 355% !important;
}

.mn-wd-355p-f {
  min-width: 355% !important;
}

.mx-wd-355-f {
  max-width: 355px !important;
}

.mn-wd-355-f {
  min-width: 355px !important;
}

.wd-360 {
  width: 360px;
}

.wd-360p {
  width: 360%;
}

.mx-wd-360p {
  max-width: 360%;
}

.mn-wd-360p {
  min-width: 360%;
}

.mx-wd-360 {
  max-width: 360px;
}

.mn-wd-360 {
  min-width: 360px;
}

.wd-360-f {
  width: 360px !important;
}

.wd-360p-f {
  width: 360% !important;
}

.mx-wd-360p-f {
  max-width: 360% !important;
}

.mn-wd-360p-f {
  min-width: 360% !important;
}

.mx-wd-360-f {
  max-width: 360px !important;
}

.mn-wd-360-f {
  min-width: 360px !important;
}

.wd-365 {
  width: 365px;
}

.wd-365p {
  width: 365%;
}

.mx-wd-365p {
  max-width: 365%;
}

.mn-wd-365p {
  min-width: 365%;
}

.mx-wd-365 {
  max-width: 365px;
}

.mn-wd-365 {
  min-width: 365px;
}

.wd-365-f {
  width: 365px !important;
}

.wd-365p-f {
  width: 365% !important;
}

.mx-wd-365p-f {
  max-width: 365% !important;
}

.mn-wd-365p-f {
  min-width: 365% !important;
}

.mx-wd-365-f {
  max-width: 365px !important;
}

.mn-wd-365-f {
  min-width: 365px !important;
}

.wd-370 {
  width: 370px;
}

.wd-370p {
  width: 370%;
}

.mx-wd-370p {
  max-width: 370%;
}

.mn-wd-370p {
  min-width: 370%;
}

.mx-wd-370 {
  max-width: 370px;
}

.mn-wd-370 {
  min-width: 370px;
}

.wd-370-f {
  width: 370px !important;
}

.wd-370p-f {
  width: 370% !important;
}

.mx-wd-370p-f {
  max-width: 370% !important;
}

.mn-wd-370p-f {
  min-width: 370% !important;
}

.mx-wd-370-f {
  max-width: 370px !important;
}

.mn-wd-370-f {
  min-width: 370px !important;
}

.wd-375 {
  width: 375px;
}

.wd-375p {
  width: 375%;
}

.mx-wd-375p {
  max-width: 375%;
}

.mn-wd-375p {
  min-width: 375%;
}

.mx-wd-375 {
  max-width: 375px;
}

.mn-wd-375 {
  min-width: 375px;
}

.wd-375-f {
  width: 375px !important;
}

.wd-375p-f {
  width: 375% !important;
}

.mx-wd-375p-f {
  max-width: 375% !important;
}

.mn-wd-375p-f {
  min-width: 375% !important;
}

.mx-wd-375-f {
  max-width: 375px !important;
}

.mn-wd-375-f {
  min-width: 375px !important;
}

.wd-380 {
  width: 380px;
}

.wd-380p {
  width: 380%;
}

.mx-wd-380p {
  max-width: 380%;
}

.mn-wd-380p {
  min-width: 380%;
}

.mx-wd-380 {
  max-width: 380px;
}

.mn-wd-380 {
  min-width: 380px;
}

.wd-380-f {
  width: 380px !important;
}

.wd-380p-f {
  width: 380% !important;
}

.mx-wd-380p-f {
  max-width: 380% !important;
}

.mn-wd-380p-f {
  min-width: 380% !important;
}

.mx-wd-380-f {
  max-width: 380px !important;
}

.mn-wd-380-f {
  min-width: 380px !important;
}

.wd-385 {
  width: 385px;
}

.wd-385p {
  width: 385%;
}

.mx-wd-385p {
  max-width: 385%;
}

.mn-wd-385p {
  min-width: 385%;
}

.mx-wd-385 {
  max-width: 385px;
}

.mn-wd-385 {
  min-width: 385px;
}

.wd-385-f {
  width: 385px !important;
}

.wd-385p-f {
  width: 385% !important;
}

.mx-wd-385p-f {
  max-width: 385% !important;
}

.mn-wd-385p-f {
  min-width: 385% !important;
}

.mx-wd-385-f {
  max-width: 385px !important;
}

.mn-wd-385-f {
  min-width: 385px !important;
}

.wd-390 {
  width: 390px;
}

.wd-390p {
  width: 390%;
}

.mx-wd-390p {
  max-width: 390%;
}

.mn-wd-390p {
  min-width: 390%;
}

.mx-wd-390 {
  max-width: 390px;
}

.mn-wd-390 {
  min-width: 390px;
}

.wd-390-f {
  width: 390px !important;
}

.wd-390p-f {
  width: 390% !important;
}

.mx-wd-390p-f {
  max-width: 390% !important;
}

.mn-wd-390p-f {
  min-width: 390% !important;
}

.mx-wd-390-f {
  max-width: 390px !important;
}

.mn-wd-390-f {
  min-width: 390px !important;
}

.wd-395 {
  width: 395px;
}

.wd-395p {
  width: 395%;
}

.mx-wd-395p {
  max-width: 395%;
}

.mn-wd-395p {
  min-width: 395%;
}

.mx-wd-395 {
  max-width: 395px;
}

.mn-wd-395 {
  min-width: 395px;
}

.wd-395-f {
  width: 395px !important;
}

.wd-395p-f {
  width: 395% !important;
}

.mx-wd-395p-f {
  max-width: 395% !important;
}

.mn-wd-395p-f {
  min-width: 395% !important;
}

.mx-wd-395-f {
  max-width: 395px !important;
}

.mn-wd-395-f {
  min-width: 395px !important;
}

.wd-400 {
  width: 400px;
}

.wd-400p {
  width: 400%;
}

.mx-wd-400p {
  max-width: 400%;
}

.mn-wd-400p {
  min-width: 400%;
}

.mx-wd-400 {
  max-width: 400px;
}

.mn-wd-400 {
  min-width: 400px;
}

.wd-400-f {
  width: 400px !important;
}

.wd-400p-f {
  width: 400% !important;
}

.mx-wd-400p-f {
  max-width: 400% !important;
}

.mn-wd-400p-f {
  min-width: 400% !important;
}

.mx-wd-400-f {
  max-width: 400px !important;
}

.mn-wd-400-f {
  min-width: 400px !important;
}

.wd-405 {
  width: 405px;
}

.wd-405p {
  width: 405%;
}

.mx-wd-405p {
  max-width: 405%;
}

.mn-wd-405p {
  min-width: 405%;
}

.mx-wd-405 {
  max-width: 405px;
}

.mn-wd-405 {
  min-width: 405px;
}

.wd-405-f {
  width: 405px !important;
}

.wd-405p-f {
  width: 405% !important;
}

.mx-wd-405p-f {
  max-width: 405% !important;
}

.mn-wd-405p-f {
  min-width: 405% !important;
}

.mx-wd-405-f {
  max-width: 405px !important;
}

.mn-wd-405-f {
  min-width: 405px !important;
}

.wd-410 {
  width: 410px;
}

.wd-410p {
  width: 410%;
}

.mx-wd-410p {
  max-width: 410%;
}

.mn-wd-410p {
  min-width: 410%;
}

.mx-wd-410 {
  max-width: 410px;
}

.mn-wd-410 {
  min-width: 410px;
}

.wd-410-f {
  width: 410px !important;
}

.wd-410p-f {
  width: 410% !important;
}

.mx-wd-410p-f {
  max-width: 410% !important;
}

.mn-wd-410p-f {
  min-width: 410% !important;
}

.mx-wd-410-f {
  max-width: 410px !important;
}

.mn-wd-410-f {
  min-width: 410px !important;
}

.wd-415 {
  width: 415px;
}

.wd-415p {
  width: 415%;
}

.mx-wd-415p {
  max-width: 415%;
}

.mn-wd-415p {
  min-width: 415%;
}

.mx-wd-415 {
  max-width: 415px;
}

.mn-wd-415 {
  min-width: 415px;
}

.wd-415-f {
  width: 415px !important;
}

.wd-415p-f {
  width: 415% !important;
}

.mx-wd-415p-f {
  max-width: 415% !important;
}

.mn-wd-415p-f {
  min-width: 415% !important;
}

.mx-wd-415-f {
  max-width: 415px !important;
}

.mn-wd-415-f {
  min-width: 415px !important;
}

.wd-420 {
  width: 420px;
}

.wd-420p {
  width: 420%;
}

.mx-wd-420p {
  max-width: 420%;
}

.mn-wd-420p {
  min-width: 420%;
}

.mx-wd-420 {
  max-width: 420px;
}

.mn-wd-420 {
  min-width: 420px;
}

.wd-420-f {
  width: 420px !important;
}

.wd-420p-f {
  width: 420% !important;
}

.mx-wd-420p-f {
  max-width: 420% !important;
}

.mn-wd-420p-f {
  min-width: 420% !important;
}

.mx-wd-420-f {
  max-width: 420px !important;
}

.mn-wd-420-f {
  min-width: 420px !important;
}

.wd-425 {
  width: 425px;
}

.wd-425p {
  width: 425%;
}

.mx-wd-425p {
  max-width: 425%;
}

.mn-wd-425p {
  min-width: 425%;
}

.mx-wd-425 {
  max-width: 425px;
}

.mn-wd-425 {
  min-width: 425px;
}

.wd-425-f {
  width: 425px !important;
}

.wd-425p-f {
  width: 425% !important;
}

.mx-wd-425p-f {
  max-width: 425% !important;
}

.mn-wd-425p-f {
  min-width: 425% !important;
}

.mx-wd-425-f {
  max-width: 425px !important;
}

.mn-wd-425-f {
  min-width: 425px !important;
}

.wd-430 {
  width: 430px;
}

.wd-430p {
  width: 430%;
}

.mx-wd-430p {
  max-width: 430%;
}

.mn-wd-430p {
  min-width: 430%;
}

.mx-wd-430 {
  max-width: 430px;
}

.mn-wd-430 {
  min-width: 430px;
}

.wd-430-f {
  width: 430px !important;
}

.wd-430p-f {
  width: 430% !important;
}

.mx-wd-430p-f {
  max-width: 430% !important;
}

.mn-wd-430p-f {
  min-width: 430% !important;
}

.mx-wd-430-f {
  max-width: 430px !important;
}

.mn-wd-430-f {
  min-width: 430px !important;
}

.wd-435 {
  width: 435px;
}

.wd-435p {
  width: 435%;
}

.mx-wd-435p {
  max-width: 435%;
}

.mn-wd-435p {
  min-width: 435%;
}

.mx-wd-435 {
  max-width: 435px;
}

.mn-wd-435 {
  min-width: 435px;
}

.wd-435-f {
  width: 435px !important;
}

.wd-435p-f {
  width: 435% !important;
}

.mx-wd-435p-f {
  max-width: 435% !important;
}

.mn-wd-435p-f {
  min-width: 435% !important;
}

.mx-wd-435-f {
  max-width: 435px !important;
}

.mn-wd-435-f {
  min-width: 435px !important;
}

.wd-440 {
  width: 440px;
}

.wd-440p {
  width: 440%;
}

.mx-wd-440p {
  max-width: 440%;
}

.mn-wd-440p {
  min-width: 440%;
}

.mx-wd-440 {
  max-width: 440px;
}

.mn-wd-440 {
  min-width: 440px;
}

.wd-440-f {
  width: 440px !important;
}

.wd-440p-f {
  width: 440% !important;
}

.mx-wd-440p-f {
  max-width: 440% !important;
}

.mn-wd-440p-f {
  min-width: 440% !important;
}

.mx-wd-440-f {
  max-width: 440px !important;
}

.mn-wd-440-f {
  min-width: 440px !important;
}

.wd-445 {
  width: 445px;
}

.wd-445p {
  width: 445%;
}

.mx-wd-445p {
  max-width: 445%;
}

.mn-wd-445p {
  min-width: 445%;
}

.mx-wd-445 {
  max-width: 445px;
}

.mn-wd-445 {
  min-width: 445px;
}

.wd-445-f {
  width: 445px !important;
}

.wd-445p-f {
  width: 445% !important;
}

.mx-wd-445p-f {
  max-width: 445% !important;
}

.mn-wd-445p-f {
  min-width: 445% !important;
}

.mx-wd-445-f {
  max-width: 445px !important;
}

.mn-wd-445-f {
  min-width: 445px !important;
}

.wd-450 {
  width: 450px;
}

.wd-450p {
  width: 450%;
}

.mx-wd-450p {
  max-width: 450%;
}

.mn-wd-450p {
  min-width: 450%;
}

.mx-wd-450 {
  max-width: 450px;
}

.mn-wd-450 {
  min-width: 450px;
}

.wd-450-f {
  width: 450px !important;
}

.wd-450p-f {
  width: 450% !important;
}

.mx-wd-450p-f {
  max-width: 450% !important;
}

.mn-wd-450p-f {
  min-width: 450% !important;
}

.mx-wd-450-f {
  max-width: 450px !important;
}

.mn-wd-450-f {
  min-width: 450px !important;
}

.wd-455 {
  width: 455px;
}

.wd-455p {
  width: 455%;
}

.mx-wd-455p {
  max-width: 455%;
}

.mn-wd-455p {
  min-width: 455%;
}

.mx-wd-455 {
  max-width: 455px;
}

.mn-wd-455 {
  min-width: 455px;
}

.wd-455-f {
  width: 455px !important;
}

.wd-455p-f {
  width: 455% !important;
}

.mx-wd-455p-f {
  max-width: 455% !important;
}

.mn-wd-455p-f {
  min-width: 455% !important;
}

.mx-wd-455-f {
  max-width: 455px !important;
}

.mn-wd-455-f {
  min-width: 455px !important;
}

.wd-460 {
  width: 460px;
}

.wd-460p {
  width: 460%;
}

.mx-wd-460p {
  max-width: 460%;
}

.mn-wd-460p {
  min-width: 460%;
}

.mx-wd-460 {
  max-width: 460px;
}

.mn-wd-460 {
  min-width: 460px;
}

.wd-460-f {
  width: 460px !important;
}

.wd-460p-f {
  width: 460% !important;
}

.mx-wd-460p-f {
  max-width: 460% !important;
}

.mn-wd-460p-f {
  min-width: 460% !important;
}

.mx-wd-460-f {
  max-width: 460px !important;
}

.mn-wd-460-f {
  min-width: 460px !important;
}

.wd-465 {
  width: 465px;
}

.wd-465p {
  width: 465%;
}

.mx-wd-465p {
  max-width: 465%;
}

.mn-wd-465p {
  min-width: 465%;
}

.mx-wd-465 {
  max-width: 465px;
}

.mn-wd-465 {
  min-width: 465px;
}

.wd-465-f {
  width: 465px !important;
}

.wd-465p-f {
  width: 465% !important;
}

.mx-wd-465p-f {
  max-width: 465% !important;
}

.mn-wd-465p-f {
  min-width: 465% !important;
}

.mx-wd-465-f {
  max-width: 465px !important;
}

.mn-wd-465-f {
  min-width: 465px !important;
}

.wd-470 {
  width: 470px;
}

.wd-470p {
  width: 470%;
}

.mx-wd-470p {
  max-width: 470%;
}

.mn-wd-470p {
  min-width: 470%;
}

.mx-wd-470 {
  max-width: 470px;
}

.mn-wd-470 {
  min-width: 470px;
}

.wd-470-f {
  width: 470px !important;
}

.wd-470p-f {
  width: 470% !important;
}

.mx-wd-470p-f {
  max-width: 470% !important;
}

.mn-wd-470p-f {
  min-width: 470% !important;
}

.mx-wd-470-f {
  max-width: 470px !important;
}

.mn-wd-470-f {
  min-width: 470px !important;
}

.wd-475 {
  width: 475px;
}

.wd-475p {
  width: 475%;
}

.mx-wd-475p {
  max-width: 475%;
}

.mn-wd-475p {
  min-width: 475%;
}

.mx-wd-475 {
  max-width: 475px;
}

.mn-wd-475 {
  min-width: 475px;
}

.wd-475-f {
  width: 475px !important;
}

.wd-475p-f {
  width: 475% !important;
}

.mx-wd-475p-f {
  max-width: 475% !important;
}

.mn-wd-475p-f {
  min-width: 475% !important;
}

.mx-wd-475-f {
  max-width: 475px !important;
}

.mn-wd-475-f {
  min-width: 475px !important;
}

.wd-480 {
  width: 480px;
}

.wd-480p {
  width: 480%;
}

.mx-wd-480p {
  max-width: 480%;
}

.mn-wd-480p {
  min-width: 480%;
}

.mx-wd-480 {
  max-width: 480px;
}

.mn-wd-480 {
  min-width: 480px;
}

.wd-480-f {
  width: 480px !important;
}

.wd-480p-f {
  width: 480% !important;
}

.mx-wd-480p-f {
  max-width: 480% !important;
}

.mn-wd-480p-f {
  min-width: 480% !important;
}

.mx-wd-480-f {
  max-width: 480px !important;
}

.mn-wd-480-f {
  min-width: 480px !important;
}

.wd-485 {
  width: 485px;
}

.wd-485p {
  width: 485%;
}

.mx-wd-485p {
  max-width: 485%;
}

.mn-wd-485p {
  min-width: 485%;
}

.mx-wd-485 {
  max-width: 485px;
}

.mn-wd-485 {
  min-width: 485px;
}

.wd-485-f {
  width: 485px !important;
}

.wd-485p-f {
  width: 485% !important;
}

.mx-wd-485p-f {
  max-width: 485% !important;
}

.mn-wd-485p-f {
  min-width: 485% !important;
}

.mx-wd-485-f {
  max-width: 485px !important;
}

.mn-wd-485-f {
  min-width: 485px !important;
}

.wd-490 {
  width: 490px;
}

.wd-490p {
  width: 490%;
}

.mx-wd-490p {
  max-width: 490%;
}

.mn-wd-490p {
  min-width: 490%;
}

.mx-wd-490 {
  max-width: 490px;
}

.mn-wd-490 {
  min-width: 490px;
}

.wd-490-f {
  width: 490px !important;
}

.wd-490p-f {
  width: 490% !important;
}

.mx-wd-490p-f {
  max-width: 490% !important;
}

.mn-wd-490p-f {
  min-width: 490% !important;
}

.mx-wd-490-f {
  max-width: 490px !important;
}

.mn-wd-490-f {
  min-width: 490px !important;
}

.wd-495 {
  width: 495px;
}

.wd-495p {
  width: 495%;
}

.mx-wd-495p {
  max-width: 495%;
}

.mn-wd-495p {
  min-width: 495%;
}

.mx-wd-495 {
  max-width: 495px;
}

.mn-wd-495 {
  min-width: 495px;
}

.wd-495-f {
  width: 495px !important;
}

.wd-495p-f {
  width: 495% !important;
}

.mx-wd-495p-f {
  max-width: 495% !important;
}

.mn-wd-495p-f {
  min-width: 495% !important;
}

.mx-wd-495-f {
  max-width: 495px !important;
}

.mn-wd-495-f {
  min-width: 495px !important;
}

.wd-500 {
  width: 500px;
}

.wd-500p {
  width: 500%;
}

.mx-wd-500p {
  max-width: 500%;
}

.mn-wd-500p {
  min-width: 500%;
}

.mx-wd-500 {
  max-width: 500px;
}

.mn-wd-500 {
  min-width: 500px;
}

.wd-500-f {
  width: 500px !important;
}

.wd-500p-f {
  width: 500% !important;
}

.mx-wd-500p-f {
  max-width: 500% !important;
}

.mn-wd-500p-f {
  min-width: 500% !important;
}

.mx-wd-500-f {
  max-width: 500px !important;
}

.mn-wd-500-f {
  min-width: 500px !important;
}

.wd-505 {
  width: 505px;
}

.wd-505p {
  width: 505%;
}

.mx-wd-505p {
  max-width: 505%;
}

.mn-wd-505p {
  min-width: 505%;
}

.mx-wd-505 {
  max-width: 505px;
}

.mn-wd-505 {
  min-width: 505px;
}

.wd-505-f {
  width: 505px !important;
}

.wd-505p-f {
  width: 505% !important;
}

.mx-wd-505p-f {
  max-width: 505% !important;
}

.mn-wd-505p-f {
  min-width: 505% !important;
}

.mx-wd-505-f {
  max-width: 505px !important;
}

.mn-wd-505-f {
  min-width: 505px !important;
}

.wd-510 {
  width: 510px;
}

.wd-510p {
  width: 510%;
}

.mx-wd-510p {
  max-width: 510%;
}

.mn-wd-510p {
  min-width: 510%;
}

.mx-wd-510 {
  max-width: 510px;
}

.mn-wd-510 {
  min-width: 510px;
}

.wd-510-f {
  width: 510px !important;
}

.wd-510p-f {
  width: 510% !important;
}

.mx-wd-510p-f {
  max-width: 510% !important;
}

.mn-wd-510p-f {
  min-width: 510% !important;
}

.mx-wd-510-f {
  max-width: 510px !important;
}

.mn-wd-510-f {
  min-width: 510px !important;
}

.wd-515 {
  width: 515px;
}

.wd-515p {
  width: 515%;
}

.mx-wd-515p {
  max-width: 515%;
}

.mn-wd-515p {
  min-width: 515%;
}

.mx-wd-515 {
  max-width: 515px;
}

.mn-wd-515 {
  min-width: 515px;
}

.wd-515-f {
  width: 515px !important;
}

.wd-515p-f {
  width: 515% !important;
}

.mx-wd-515p-f {
  max-width: 515% !important;
}

.mn-wd-515p-f {
  min-width: 515% !important;
}

.mx-wd-515-f {
  max-width: 515px !important;
}

.mn-wd-515-f {
  min-width: 515px !important;
}

.wd-520 {
  width: 520px;
}

.wd-520p {
  width: 520%;
}

.mx-wd-520p {
  max-width: 520%;
}

.mn-wd-520p {
  min-width: 520%;
}

.mx-wd-520 {
  max-width: 520px;
}

.mn-wd-520 {
  min-width: 520px;
}

.wd-520-f {
  width: 520px !important;
}

.wd-520p-f {
  width: 520% !important;
}

.mx-wd-520p-f {
  max-width: 520% !important;
}

.mn-wd-520p-f {
  min-width: 520% !important;
}

.mx-wd-520-f {
  max-width: 520px !important;
}

.mn-wd-520-f {
  min-width: 520px !important;
}

.wd-525 {
  width: 525px;
}

.wd-525p {
  width: 525%;
}

.mx-wd-525p {
  max-width: 525%;
}

.mn-wd-525p {
  min-width: 525%;
}

.mx-wd-525 {
  max-width: 525px;
}

.mn-wd-525 {
  min-width: 525px;
}

.wd-525-f {
  width: 525px !important;
}

.wd-525p-f {
  width: 525% !important;
}

.mx-wd-525p-f {
  max-width: 525% !important;
}

.mn-wd-525p-f {
  min-width: 525% !important;
}

.mx-wd-525-f {
  max-width: 525px !important;
}

.mn-wd-525-f {
  min-width: 525px !important;
}

.wd-530 {
  width: 530px;
}

.wd-530p {
  width: 530%;
}

.mx-wd-530p {
  max-width: 530%;
}

.mn-wd-530p {
  min-width: 530%;
}

.mx-wd-530 {
  max-width: 530px;
}

.mn-wd-530 {
  min-width: 530px;
}

.wd-530-f {
  width: 530px !important;
}

.wd-530p-f {
  width: 530% !important;
}

.mx-wd-530p-f {
  max-width: 530% !important;
}

.mn-wd-530p-f {
  min-width: 530% !important;
}

.mx-wd-530-f {
  max-width: 530px !important;
}

.mn-wd-530-f {
  min-width: 530px !important;
}

.wd-535 {
  width: 535px;
}

.wd-535p {
  width: 535%;
}

.mx-wd-535p {
  max-width: 535%;
}

.mn-wd-535p {
  min-width: 535%;
}

.mx-wd-535 {
  max-width: 535px;
}

.mn-wd-535 {
  min-width: 535px;
}

.wd-535-f {
  width: 535px !important;
}

.wd-535p-f {
  width: 535% !important;
}

.mx-wd-535p-f {
  max-width: 535% !important;
}

.mn-wd-535p-f {
  min-width: 535% !important;
}

.mx-wd-535-f {
  max-width: 535px !important;
}

.mn-wd-535-f {
  min-width: 535px !important;
}

.wd-540 {
  width: 540px;
}

.wd-540p {
  width: 540%;
}

.mx-wd-540p {
  max-width: 540%;
}

.mn-wd-540p {
  min-width: 540%;
}

.mx-wd-540 {
  max-width: 540px;
}

.mn-wd-540 {
  min-width: 540px;
}

.wd-540-f {
  width: 540px !important;
}

.wd-540p-f {
  width: 540% !important;
}

.mx-wd-540p-f {
  max-width: 540% !important;
}

.mn-wd-540p-f {
  min-width: 540% !important;
}

.mx-wd-540-f {
  max-width: 540px !important;
}

.mn-wd-540-f {
  min-width: 540px !important;
}

.wd-545 {
  width: 545px;
}

.wd-545p {
  width: 545%;
}

.mx-wd-545p {
  max-width: 545%;
}

.mn-wd-545p {
  min-width: 545%;
}

.mx-wd-545 {
  max-width: 545px;
}

.mn-wd-545 {
  min-width: 545px;
}

.wd-545-f {
  width: 545px !important;
}

.wd-545p-f {
  width: 545% !important;
}

.mx-wd-545p-f {
  max-width: 545% !important;
}

.mn-wd-545p-f {
  min-width: 545% !important;
}

.mx-wd-545-f {
  max-width: 545px !important;
}

.mn-wd-545-f {
  min-width: 545px !important;
}

.wd-550 {
  width: 550px;
}

.wd-550p {
  width: 550%;
}

.mx-wd-550p {
  max-width: 550%;
}

.mn-wd-550p {
  min-width: 550%;
}

.mx-wd-550 {
  max-width: 550px;
}

.mn-wd-550 {
  min-width: 550px;
}

.wd-550-f {
  width: 550px !important;
}

.wd-550p-f {
  width: 550% !important;
}

.mx-wd-550p-f {
  max-width: 550% !important;
}

.mn-wd-550p-f {
  min-width: 550% !important;
}

.mx-wd-550-f {
  max-width: 550px !important;
}

.mn-wd-550-f {
  min-width: 550px !important;
}

.wd-555 {
  width: 555px;
}

.wd-555p {
  width: 555%;
}

.mx-wd-555p {
  max-width: 555%;
}

.mn-wd-555p {
  min-width: 555%;
}

.mx-wd-555 {
  max-width: 555px;
}

.mn-wd-555 {
  min-width: 555px;
}

.wd-555-f {
  width: 555px !important;
}

.wd-555p-f {
  width: 555% !important;
}

.mx-wd-555p-f {
  max-width: 555% !important;
}

.mn-wd-555p-f {
  min-width: 555% !important;
}

.mx-wd-555-f {
  max-width: 555px !important;
}

.mn-wd-555-f {
  min-width: 555px !important;
}

.wd-560 {
  width: 560px;
}

.wd-560p {
  width: 560%;
}

.mx-wd-560p {
  max-width: 560%;
}

.mn-wd-560p {
  min-width: 560%;
}

.mx-wd-560 {
  max-width: 560px;
}

.mn-wd-560 {
  min-width: 560px;
}

.wd-560-f {
  width: 560px !important;
}

.wd-560p-f {
  width: 560% !important;
}

.mx-wd-560p-f {
  max-width: 560% !important;
}

.mn-wd-560p-f {
  min-width: 560% !important;
}

.mx-wd-560-f {
  max-width: 560px !important;
}

.mn-wd-560-f {
  min-width: 560px !important;
}

.wd-565 {
  width: 565px;
}

.wd-565p {
  width: 565%;
}

.mx-wd-565p {
  max-width: 565%;
}

.mn-wd-565p {
  min-width: 565%;
}

.mx-wd-565 {
  max-width: 565px;
}

.mn-wd-565 {
  min-width: 565px;
}

.wd-565-f {
  width: 565px !important;
}

.wd-565p-f {
  width: 565% !important;
}

.mx-wd-565p-f {
  max-width: 565% !important;
}

.mn-wd-565p-f {
  min-width: 565% !important;
}

.mx-wd-565-f {
  max-width: 565px !important;
}

.mn-wd-565-f {
  min-width: 565px !important;
}

.wd-570 {
  width: 570px;
}

.wd-570p {
  width: 570%;
}

.mx-wd-570p {
  max-width: 570%;
}

.mn-wd-570p {
  min-width: 570%;
}

.mx-wd-570 {
  max-width: 570px;
}

.mn-wd-570 {
  min-width: 570px;
}

.wd-570-f {
  width: 570px !important;
}

.wd-570p-f {
  width: 570% !important;
}

.mx-wd-570p-f {
  max-width: 570% !important;
}

.mn-wd-570p-f {
  min-width: 570% !important;
}

.mx-wd-570-f {
  max-width: 570px !important;
}

.mn-wd-570-f {
  min-width: 570px !important;
}

.wd-575 {
  width: 575px;
}

.wd-575p {
  width: 575%;
}

.mx-wd-575p {
  max-width: 575%;
}

.mn-wd-575p {
  min-width: 575%;
}

.mx-wd-575 {
  max-width: 575px;
}

.mn-wd-575 {
  min-width: 575px;
}

.wd-575-f {
  width: 575px !important;
}

.wd-575p-f {
  width: 575% !important;
}

.mx-wd-575p-f {
  max-width: 575% !important;
}

.mn-wd-575p-f {
  min-width: 575% !important;
}

.mx-wd-575-f {
  max-width: 575px !important;
}

.mn-wd-575-f {
  min-width: 575px !important;
}

.wd-580 {
  width: 580px;
}

.wd-580p {
  width: 580%;
}

.mx-wd-580p {
  max-width: 580%;
}

.mn-wd-580p {
  min-width: 580%;
}

.mx-wd-580 {
  max-width: 580px;
}

.mn-wd-580 {
  min-width: 580px;
}

.wd-580-f {
  width: 580px !important;
}

.wd-580p-f {
  width: 580% !important;
}

.mx-wd-580p-f {
  max-width: 580% !important;
}

.mn-wd-580p-f {
  min-width: 580% !important;
}

.mx-wd-580-f {
  max-width: 580px !important;
}

.mn-wd-580-f {
  min-width: 580px !important;
}

.wd-585 {
  width: 585px;
}

.wd-585p {
  width: 585%;
}

.mx-wd-585p {
  max-width: 585%;
}

.mn-wd-585p {
  min-width: 585%;
}

.mx-wd-585 {
  max-width: 585px;
}

.mn-wd-585 {
  min-width: 585px;
}

.wd-585-f {
  width: 585px !important;
}

.wd-585p-f {
  width: 585% !important;
}

.mx-wd-585p-f {
  max-width: 585% !important;
}

.mn-wd-585p-f {
  min-width: 585% !important;
}

.mx-wd-585-f {
  max-width: 585px !important;
}

.mn-wd-585-f {
  min-width: 585px !important;
}

.wd-590 {
  width: 590px;
}

.wd-590p {
  width: 590%;
}

.mx-wd-590p {
  max-width: 590%;
}

.mn-wd-590p {
  min-width: 590%;
}

.mx-wd-590 {
  max-width: 590px;
}

.mn-wd-590 {
  min-width: 590px;
}

.wd-590-f {
  width: 590px !important;
}

.wd-590p-f {
  width: 590% !important;
}

.mx-wd-590p-f {
  max-width: 590% !important;
}

.mn-wd-590p-f {
  min-width: 590% !important;
}

.mx-wd-590-f {
  max-width: 590px !important;
}

.mn-wd-590-f {
  min-width: 590px !important;
}

.wd-595 {
  width: 595px;
}

.wd-595p {
  width: 595%;
}

.mx-wd-595p {
  max-width: 595%;
}

.mn-wd-595p {
  min-width: 595%;
}

.mx-wd-595 {
  max-width: 595px;
}

.mn-wd-595 {
  min-width: 595px;
}

.wd-595-f {
  width: 595px !important;
}

.wd-595p-f {
  width: 595% !important;
}

.mx-wd-595p-f {
  max-width: 595% !important;
}

.mn-wd-595p-f {
  min-width: 595% !important;
}

.mx-wd-595-f {
  max-width: 595px !important;
}

.mn-wd-595-f {
  min-width: 595px !important;
}

.wd-600 {
  width: 600px;
}

.wd-600p {
  width: 600%;
}

.mx-wd-600p {
  max-width: 600%;
}

.mn-wd-600p {
  min-width: 600%;
}

.mx-wd-600 {
  max-width: 600px;
}

.mn-wd-600 {
  min-width: 600px;
}

.wd-600-f {
  width: 600px !important;
}

.wd-600p-f {
  width: 600% !important;
}

.mx-wd-600p-f {
  max-width: 600% !important;
}

.mn-wd-600p-f {
  min-width: 600% !important;
}

.mx-wd-600-f {
  max-width: 600px !important;
}

.mn-wd-600-f {
  min-width: 600px !important;
}

.wd-605 {
  width: 605px;
}

.wd-605p {
  width: 605%;
}

.mx-wd-605p {
  max-width: 605%;
}

.mn-wd-605p {
  min-width: 605%;
}

.mx-wd-605 {
  max-width: 605px;
}

.mn-wd-605 {
  min-width: 605px;
}

.wd-605-f {
  width: 605px !important;
}

.wd-605p-f {
  width: 605% !important;
}

.mx-wd-605p-f {
  max-width: 605% !important;
}

.mn-wd-605p-f {
  min-width: 605% !important;
}

.mx-wd-605-f {
  max-width: 605px !important;
}

.mn-wd-605-f {
  min-width: 605px !important;
}

.wd-610 {
  width: 610px;
}

.wd-610p {
  width: 610%;
}

.mx-wd-610p {
  max-width: 610%;
}

.mn-wd-610p {
  min-width: 610%;
}

.mx-wd-610 {
  max-width: 610px;
}

.mn-wd-610 {
  min-width: 610px;
}

.wd-610-f {
  width: 610px !important;
}

.wd-610p-f {
  width: 610% !important;
}

.mx-wd-610p-f {
  max-width: 610% !important;
}

.mn-wd-610p-f {
  min-width: 610% !important;
}

.mx-wd-610-f {
  max-width: 610px !important;
}

.mn-wd-610-f {
  min-width: 610px !important;
}

.wd-615 {
  width: 615px;
}

.wd-615p {
  width: 615%;
}

.mx-wd-615p {
  max-width: 615%;
}

.mn-wd-615p {
  min-width: 615%;
}

.mx-wd-615 {
  max-width: 615px;
}

.mn-wd-615 {
  min-width: 615px;
}

.wd-615-f {
  width: 615px !important;
}

.wd-615p-f {
  width: 615% !important;
}

.mx-wd-615p-f {
  max-width: 615% !important;
}

.mn-wd-615p-f {
  min-width: 615% !important;
}

.mx-wd-615-f {
  max-width: 615px !important;
}

.mn-wd-615-f {
  min-width: 615px !important;
}

.wd-620 {
  width: 620px;
}

.wd-620p {
  width: 620%;
}

.mx-wd-620p {
  max-width: 620%;
}

.mn-wd-620p {
  min-width: 620%;
}

.mx-wd-620 {
  max-width: 620px;
}

.mn-wd-620 {
  min-width: 620px;
}

.wd-620-f {
  width: 620px !important;
}

.wd-620p-f {
  width: 620% !important;
}

.mx-wd-620p-f {
  max-width: 620% !important;
}

.mn-wd-620p-f {
  min-width: 620% !important;
}

.mx-wd-620-f {
  max-width: 620px !important;
}

.mn-wd-620-f {
  min-width: 620px !important;
}

.wd-625 {
  width: 625px;
}

.wd-625p {
  width: 625%;
}

.mx-wd-625p {
  max-width: 625%;
}

.mn-wd-625p {
  min-width: 625%;
}

.mx-wd-625 {
  max-width: 625px;
}

.mn-wd-625 {
  min-width: 625px;
}

.wd-625-f {
  width: 625px !important;
}

.wd-625p-f {
  width: 625% !important;
}

.mx-wd-625p-f {
  max-width: 625% !important;
}

.mn-wd-625p-f {
  min-width: 625% !important;
}

.mx-wd-625-f {
  max-width: 625px !important;
}

.mn-wd-625-f {
  min-width: 625px !important;
}

.wd-630 {
  width: 630px;
}

.wd-630p {
  width: 630%;
}

.mx-wd-630p {
  max-width: 630%;
}

.mn-wd-630p {
  min-width: 630%;
}

.mx-wd-630 {
  max-width: 630px;
}

.mn-wd-630 {
  min-width: 630px;
}

.wd-630-f {
  width: 630px !important;
}

.wd-630p-f {
  width: 630% !important;
}

.mx-wd-630p-f {
  max-width: 630% !important;
}

.mn-wd-630p-f {
  min-width: 630% !important;
}

.mx-wd-630-f {
  max-width: 630px !important;
}

.mn-wd-630-f {
  min-width: 630px !important;
}

.wd-635 {
  width: 635px;
}

.wd-635p {
  width: 635%;
}

.mx-wd-635p {
  max-width: 635%;
}

.mn-wd-635p {
  min-width: 635%;
}

.mx-wd-635 {
  max-width: 635px;
}

.mn-wd-635 {
  min-width: 635px;
}

.wd-635-f {
  width: 635px !important;
}

.wd-635p-f {
  width: 635% !important;
}

.mx-wd-635p-f {
  max-width: 635% !important;
}

.mn-wd-635p-f {
  min-width: 635% !important;
}

.mx-wd-635-f {
  max-width: 635px !important;
}

.mn-wd-635-f {
  min-width: 635px !important;
}

.wd-640 {
  width: 640px;
}

.wd-640p {
  width: 640%;
}

.mx-wd-640p {
  max-width: 640%;
}

.mn-wd-640p {
  min-width: 640%;
}

.mx-wd-640 {
  max-width: 640px;
}

.mn-wd-640 {
  min-width: 640px;
}

.wd-640-f {
  width: 640px !important;
}

.wd-640p-f {
  width: 640% !important;
}

.mx-wd-640p-f {
  max-width: 640% !important;
}

.mn-wd-640p-f {
  min-width: 640% !important;
}

.mx-wd-640-f {
  max-width: 640px !important;
}

.mn-wd-640-f {
  min-width: 640px !important;
}

.wd-645 {
  width: 645px;
}

.wd-645p {
  width: 645%;
}

.mx-wd-645p {
  max-width: 645%;
}

.mn-wd-645p {
  min-width: 645%;
}

.mx-wd-645 {
  max-width: 645px;
}

.mn-wd-645 {
  min-width: 645px;
}

.wd-645-f {
  width: 645px !important;
}

.wd-645p-f {
  width: 645% !important;
}

.mx-wd-645p-f {
  max-width: 645% !important;
}

.mn-wd-645p-f {
  min-width: 645% !important;
}

.mx-wd-645-f {
  max-width: 645px !important;
}

.mn-wd-645-f {
  min-width: 645px !important;
}

.wd-650 {
  width: 650px;
}

.wd-650p {
  width: 650%;
}

.mx-wd-650p {
  max-width: 650%;
}

.mn-wd-650p {
  min-width: 650%;
}

.mx-wd-650 {
  max-width: 650px;
}

.mn-wd-650 {
  min-width: 650px;
}

.wd-650-f {
  width: 650px !important;
}

.wd-650p-f {
  width: 650% !important;
}

.mx-wd-650p-f {
  max-width: 650% !important;
}

.mn-wd-650p-f {
  min-width: 650% !important;
}

.mx-wd-650-f {
  max-width: 650px !important;
}

.mn-wd-650-f {
  min-width: 650px !important;
}

.wd-655 {
  width: 655px;
}

.wd-655p {
  width: 655%;
}

.mx-wd-655p {
  max-width: 655%;
}

.mn-wd-655p {
  min-width: 655%;
}

.mx-wd-655 {
  max-width: 655px;
}

.mn-wd-655 {
  min-width: 655px;
}

.wd-655-f {
  width: 655px !important;
}

.wd-655p-f {
  width: 655% !important;
}

.mx-wd-655p-f {
  max-width: 655% !important;
}

.mn-wd-655p-f {
  min-width: 655% !important;
}

.mx-wd-655-f {
  max-width: 655px !important;
}

.mn-wd-655-f {
  min-width: 655px !important;
}

.wd-660 {
  width: 660px;
}

.wd-660p {
  width: 660%;
}

.mx-wd-660p {
  max-width: 660%;
}

.mn-wd-660p {
  min-width: 660%;
}

.mx-wd-660 {
  max-width: 660px;
}

.mn-wd-660 {
  min-width: 660px;
}

.wd-660-f {
  width: 660px !important;
}

.wd-660p-f {
  width: 660% !important;
}

.mx-wd-660p-f {
  max-width: 660% !important;
}

.mn-wd-660p-f {
  min-width: 660% !important;
}

.mx-wd-660-f {
  max-width: 660px !important;
}

.mn-wd-660-f {
  min-width: 660px !important;
}

.wd-665 {
  width: 665px;
}

.wd-665p {
  width: 665%;
}

.mx-wd-665p {
  max-width: 665%;
}

.mn-wd-665p {
  min-width: 665%;
}

.mx-wd-665 {
  max-width: 665px;
}

.mn-wd-665 {
  min-width: 665px;
}

.wd-665-f {
  width: 665px !important;
}

.wd-665p-f {
  width: 665% !important;
}

.mx-wd-665p-f {
  max-width: 665% !important;
}

.mn-wd-665p-f {
  min-width: 665% !important;
}

.mx-wd-665-f {
  max-width: 665px !important;
}

.mn-wd-665-f {
  min-width: 665px !important;
}

.wd-670 {
  width: 670px;
}

.wd-670p {
  width: 670%;
}

.mx-wd-670p {
  max-width: 670%;
}

.mn-wd-670p {
  min-width: 670%;
}

.mx-wd-670 {
  max-width: 670px;
}

.mn-wd-670 {
  min-width: 670px;
}

.wd-670-f {
  width: 670px !important;
}

.wd-670p-f {
  width: 670% !important;
}

.mx-wd-670p-f {
  max-width: 670% !important;
}

.mn-wd-670p-f {
  min-width: 670% !important;
}

.mx-wd-670-f {
  max-width: 670px !important;
}

.mn-wd-670-f {
  min-width: 670px !important;
}

.wd-675 {
  width: 675px;
}

.wd-675p {
  width: 675%;
}

.mx-wd-675p {
  max-width: 675%;
}

.mn-wd-675p {
  min-width: 675%;
}

.mx-wd-675 {
  max-width: 675px;
}

.mn-wd-675 {
  min-width: 675px;
}

.wd-675-f {
  width: 675px !important;
}

.wd-675p-f {
  width: 675% !important;
}

.mx-wd-675p-f {
  max-width: 675% !important;
}

.mn-wd-675p-f {
  min-width: 675% !important;
}

.mx-wd-675-f {
  max-width: 675px !important;
}

.mn-wd-675-f {
  min-width: 675px !important;
}

.wd-680 {
  width: 680px;
}

.wd-680p {
  width: 680%;
}

.mx-wd-680p {
  max-width: 680%;
}

.mn-wd-680p {
  min-width: 680%;
}

.mx-wd-680 {
  max-width: 680px;
}

.mn-wd-680 {
  min-width: 680px;
}

.wd-680-f {
  width: 680px !important;
}

.wd-680p-f {
  width: 680% !important;
}

.mx-wd-680p-f {
  max-width: 680% !important;
}

.mn-wd-680p-f {
  min-width: 680% !important;
}

.mx-wd-680-f {
  max-width: 680px !important;
}

.mn-wd-680-f {
  min-width: 680px !important;
}

.wd-685 {
  width: 685px;
}

.wd-685p {
  width: 685%;
}

.mx-wd-685p {
  max-width: 685%;
}

.mn-wd-685p {
  min-width: 685%;
}

.mx-wd-685 {
  max-width: 685px;
}

.mn-wd-685 {
  min-width: 685px;
}

.wd-685-f {
  width: 685px !important;
}

.wd-685p-f {
  width: 685% !important;
}

.mx-wd-685p-f {
  max-width: 685% !important;
}

.mn-wd-685p-f {
  min-width: 685% !important;
}

.mx-wd-685-f {
  max-width: 685px !important;
}

.mn-wd-685-f {
  min-width: 685px !important;
}

.wd-690 {
  width: 690px;
}

.wd-690p {
  width: 690%;
}

.mx-wd-690p {
  max-width: 690%;
}

.mn-wd-690p {
  min-width: 690%;
}

.mx-wd-690 {
  max-width: 690px;
}

.mn-wd-690 {
  min-width: 690px;
}

.wd-690-f {
  width: 690px !important;
}

.wd-690p-f {
  width: 690% !important;
}

.mx-wd-690p-f {
  max-width: 690% !important;
}

.mn-wd-690p-f {
  min-width: 690% !important;
}

.mx-wd-690-f {
  max-width: 690px !important;
}

.mn-wd-690-f {
  min-width: 690px !important;
}

.wd-695 {
  width: 695px;
}

.wd-695p {
  width: 695%;
}

.mx-wd-695p {
  max-width: 695%;
}

.mn-wd-695p {
  min-width: 695%;
}

.mx-wd-695 {
  max-width: 695px;
}

.mn-wd-695 {
  min-width: 695px;
}

.wd-695-f {
  width: 695px !important;
}

.wd-695p-f {
  width: 695% !important;
}

.mx-wd-695p-f {
  max-width: 695% !important;
}

.mn-wd-695p-f {
  min-width: 695% !important;
}

.mx-wd-695-f {
  max-width: 695px !important;
}

.mn-wd-695-f {
  min-width: 695px !important;
}

.wd-700 {
  width: 700px;
}

.wd-700p {
  width: 700%;
}

.mx-wd-700p {
  max-width: 700%;
}

.mn-wd-700p {
  min-width: 700%;
}

.mx-wd-700 {
  max-width: 700px;
}

.mn-wd-700 {
  min-width: 700px;
}

.wd-700-f {
  width: 700px !important;
}

.wd-700p-f {
  width: 700% !important;
}

.mx-wd-700p-f {
  max-width: 700% !important;
}

.mn-wd-700p-f {
  min-width: 700% !important;
}

.mx-wd-700-f {
  max-width: 700px !important;
}

.mn-wd-700-f {
  min-width: 700px !important;
}

.wd-705 {
  width: 705px;
}

.wd-705p {
  width: 705%;
}

.mx-wd-705p {
  max-width: 705%;
}

.mn-wd-705p {
  min-width: 705%;
}

.mx-wd-705 {
  max-width: 705px;
}

.mn-wd-705 {
  min-width: 705px;
}

.wd-705-f {
  width: 705px !important;
}

.wd-705p-f {
  width: 705% !important;
}

.mx-wd-705p-f {
  max-width: 705% !important;
}

.mn-wd-705p-f {
  min-width: 705% !important;
}

.mx-wd-705-f {
  max-width: 705px !important;
}

.mn-wd-705-f {
  min-width: 705px !important;
}

.wd-710 {
  width: 710px;
}

.wd-710p {
  width: 710%;
}

.mx-wd-710p {
  max-width: 710%;
}

.mn-wd-710p {
  min-width: 710%;
}

.mx-wd-710 {
  max-width: 710px;
}

.mn-wd-710 {
  min-width: 710px;
}

.wd-710-f {
  width: 710px !important;
}

.wd-710p-f {
  width: 710% !important;
}

.mx-wd-710p-f {
  max-width: 710% !important;
}

.mn-wd-710p-f {
  min-width: 710% !important;
}

.mx-wd-710-f {
  max-width: 710px !important;
}

.mn-wd-710-f {
  min-width: 710px !important;
}

.wd-715 {
  width: 715px;
}

.wd-715p {
  width: 715%;
}

.mx-wd-715p {
  max-width: 715%;
}

.mn-wd-715p {
  min-width: 715%;
}

.mx-wd-715 {
  max-width: 715px;
}

.mn-wd-715 {
  min-width: 715px;
}

.wd-715-f {
  width: 715px !important;
}

.wd-715p-f {
  width: 715% !important;
}

.mx-wd-715p-f {
  max-width: 715% !important;
}

.mn-wd-715p-f {
  min-width: 715% !important;
}

.mx-wd-715-f {
  max-width: 715px !important;
}

.mn-wd-715-f {
  min-width: 715px !important;
}

.wd-720 {
  width: 720px;
}

.wd-720p {
  width: 720%;
}

.mx-wd-720p {
  max-width: 720%;
}

.mn-wd-720p {
  min-width: 720%;
}

.mx-wd-720 {
  max-width: 720px;
}

.mn-wd-720 {
  min-width: 720px;
}

.wd-720-f {
  width: 720px !important;
}

.wd-720p-f {
  width: 720% !important;
}

.mx-wd-720p-f {
  max-width: 720% !important;
}

.mn-wd-720p-f {
  min-width: 720% !important;
}

.mx-wd-720-f {
  max-width: 720px !important;
}

.mn-wd-720-f {
  min-width: 720px !important;
}

.wd-725 {
  width: 725px;
}

.wd-725p {
  width: 725%;
}

.mx-wd-725p {
  max-width: 725%;
}

.mn-wd-725p {
  min-width: 725%;
}

.mx-wd-725 {
  max-width: 725px;
}

.mn-wd-725 {
  min-width: 725px;
}

.wd-725-f {
  width: 725px !important;
}

.wd-725p-f {
  width: 725% !important;
}

.mx-wd-725p-f {
  max-width: 725% !important;
}

.mn-wd-725p-f {
  min-width: 725% !important;
}

.mx-wd-725-f {
  max-width: 725px !important;
}

.mn-wd-725-f {
  min-width: 725px !important;
}

.wd-730 {
  width: 730px;
}

.wd-730p {
  width: 730%;
}

.mx-wd-730p {
  max-width: 730%;
}

.mn-wd-730p {
  min-width: 730%;
}

.mx-wd-730 {
  max-width: 730px;
}

.mn-wd-730 {
  min-width: 730px;
}

.wd-730-f {
  width: 730px !important;
}

.wd-730p-f {
  width: 730% !important;
}

.mx-wd-730p-f {
  max-width: 730% !important;
}

.mn-wd-730p-f {
  min-width: 730% !important;
}

.mx-wd-730-f {
  max-width: 730px !important;
}

.mn-wd-730-f {
  min-width: 730px !important;
}

.wd-735 {
  width: 735px;
}

.wd-735p {
  width: 735%;
}

.mx-wd-735p {
  max-width: 735%;
}

.mn-wd-735p {
  min-width: 735%;
}

.mx-wd-735 {
  max-width: 735px;
}

.mn-wd-735 {
  min-width: 735px;
}

.wd-735-f {
  width: 735px !important;
}

.wd-735p-f {
  width: 735% !important;
}

.mx-wd-735p-f {
  max-width: 735% !important;
}

.mn-wd-735p-f {
  min-width: 735% !important;
}

.mx-wd-735-f {
  max-width: 735px !important;
}

.mn-wd-735-f {
  min-width: 735px !important;
}

.wd-740 {
  width: 740px;
}

.wd-740p {
  width: 740%;
}

.mx-wd-740p {
  max-width: 740%;
}

.mn-wd-740p {
  min-width: 740%;
}

.mx-wd-740 {
  max-width: 740px;
}

.mn-wd-740 {
  min-width: 740px;
}

.wd-740-f {
  width: 740px !important;
}

.wd-740p-f {
  width: 740% !important;
}

.mx-wd-740p-f {
  max-width: 740% !important;
}

.mn-wd-740p-f {
  min-width: 740% !important;
}

.mx-wd-740-f {
  max-width: 740px !important;
}

.mn-wd-740-f {
  min-width: 740px !important;
}

.wd-745 {
  width: 745px;
}

.wd-745p {
  width: 745%;
}

.mx-wd-745p {
  max-width: 745%;
}

.mn-wd-745p {
  min-width: 745%;
}

.mx-wd-745 {
  max-width: 745px;
}

.mn-wd-745 {
  min-width: 745px;
}

.wd-745-f {
  width: 745px !important;
}

.wd-745p-f {
  width: 745% !important;
}

.mx-wd-745p-f {
  max-width: 745% !important;
}

.mn-wd-745p-f {
  min-width: 745% !important;
}

.mx-wd-745-f {
  max-width: 745px !important;
}

.mn-wd-745-f {
  min-width: 745px !important;
}

.wd-750 {
  width: 750px;
}

.wd-750p {
  width: 750%;
}

.mx-wd-750p {
  max-width: 750%;
}

.mn-wd-750p {
  min-width: 750%;
}

.mx-wd-750 {
  max-width: 750px;
}

.mn-wd-750 {
  min-width: 750px;
}

.wd-750-f {
  width: 750px !important;
}

.wd-750p-f {
  width: 750% !important;
}

.mx-wd-750p-f {
  max-width: 750% !important;
}

.mn-wd-750p-f {
  min-width: 750% !important;
}

.mx-wd-750-f {
  max-width: 750px !important;
}

.mn-wd-750-f {
  min-width: 750px !important;
}

.wd-755 {
  width: 755px;
}

.wd-755p {
  width: 755%;
}

.mx-wd-755p {
  max-width: 755%;
}

.mn-wd-755p {
  min-width: 755%;
}

.mx-wd-755 {
  max-width: 755px;
}

.mn-wd-755 {
  min-width: 755px;
}

.wd-755-f {
  width: 755px !important;
}

.wd-755p-f {
  width: 755% !important;
}

.mx-wd-755p-f {
  max-width: 755% !important;
}

.mn-wd-755p-f {
  min-width: 755% !important;
}

.mx-wd-755-f {
  max-width: 755px !important;
}

.mn-wd-755-f {
  min-width: 755px !important;
}

.wd-760 {
  width: 760px;
}

.wd-760p {
  width: 760%;
}

.mx-wd-760p {
  max-width: 760%;
}

.mn-wd-760p {
  min-width: 760%;
}

.mx-wd-760 {
  max-width: 760px;
}

.mn-wd-760 {
  min-width: 760px;
}

.wd-760-f {
  width: 760px !important;
}

.wd-760p-f {
  width: 760% !important;
}

.mx-wd-760p-f {
  max-width: 760% !important;
}

.mn-wd-760p-f {
  min-width: 760% !important;
}

.mx-wd-760-f {
  max-width: 760px !important;
}

.mn-wd-760-f {
  min-width: 760px !important;
}

.wd-765 {
  width: 765px;
}

.wd-765p {
  width: 765%;
}

.mx-wd-765p {
  max-width: 765%;
}

.mn-wd-765p {
  min-width: 765%;
}

.mx-wd-765 {
  max-width: 765px;
}

.mn-wd-765 {
  min-width: 765px;
}

.wd-765-f {
  width: 765px !important;
}

.wd-765p-f {
  width: 765% !important;
}

.mx-wd-765p-f {
  max-width: 765% !important;
}

.mn-wd-765p-f {
  min-width: 765% !important;
}

.mx-wd-765-f {
  max-width: 765px !important;
}

.mn-wd-765-f {
  min-width: 765px !important;
}

.wd-770 {
  width: 770px;
}

.wd-770p {
  width: 770%;
}

.mx-wd-770p {
  max-width: 770%;
}

.mn-wd-770p {
  min-width: 770%;
}

.mx-wd-770 {
  max-width: 770px;
}

.mn-wd-770 {
  min-width: 770px;
}

.wd-770-f {
  width: 770px !important;
}

.wd-770p-f {
  width: 770% !important;
}

.mx-wd-770p-f {
  max-width: 770% !important;
}

.mn-wd-770p-f {
  min-width: 770% !important;
}

.mx-wd-770-f {
  max-width: 770px !important;
}

.mn-wd-770-f {
  min-width: 770px !important;
}

.wd-775 {
  width: 775px;
}

.wd-775p {
  width: 775%;
}

.mx-wd-775p {
  max-width: 775%;
}

.mn-wd-775p {
  min-width: 775%;
}

.mx-wd-775 {
  max-width: 775px;
}

.mn-wd-775 {
  min-width: 775px;
}

.wd-775-f {
  width: 775px !important;
}

.wd-775p-f {
  width: 775% !important;
}

.mx-wd-775p-f {
  max-width: 775% !important;
}

.mn-wd-775p-f {
  min-width: 775% !important;
}

.mx-wd-775-f {
  max-width: 775px !important;
}

.mn-wd-775-f {
  min-width: 775px !important;
}

.wd-780 {
  width: 780px;
}

.wd-780p {
  width: 780%;
}

.mx-wd-780p {
  max-width: 780%;
}

.mn-wd-780p {
  min-width: 780%;
}

.mx-wd-780 {
  max-width: 780px;
}

.mn-wd-780 {
  min-width: 780px;
}

.wd-780-f {
  width: 780px !important;
}

.wd-780p-f {
  width: 780% !important;
}

.mx-wd-780p-f {
  max-width: 780% !important;
}

.mn-wd-780p-f {
  min-width: 780% !important;
}

.mx-wd-780-f {
  max-width: 780px !important;
}

.mn-wd-780-f {
  min-width: 780px !important;
}

.wd-785 {
  width: 785px;
}

.wd-785p {
  width: 785%;
}

.mx-wd-785p {
  max-width: 785%;
}

.mn-wd-785p {
  min-width: 785%;
}

.mx-wd-785 {
  max-width: 785px;
}

.mn-wd-785 {
  min-width: 785px;
}

.wd-785-f {
  width: 785px !important;
}

.wd-785p-f {
  width: 785% !important;
}

.mx-wd-785p-f {
  max-width: 785% !important;
}

.mn-wd-785p-f {
  min-width: 785% !important;
}

.mx-wd-785-f {
  max-width: 785px !important;
}

.mn-wd-785-f {
  min-width: 785px !important;
}

.wd-790 {
  width: 790px;
}

.wd-790p {
  width: 790%;
}

.mx-wd-790p {
  max-width: 790%;
}

.mn-wd-790p {
  min-width: 790%;
}

.mx-wd-790 {
  max-width: 790px;
}

.mn-wd-790 {
  min-width: 790px;
}

.wd-790-f {
  width: 790px !important;
}

.wd-790p-f {
  width: 790% !important;
}

.mx-wd-790p-f {
  max-width: 790% !important;
}

.mn-wd-790p-f {
  min-width: 790% !important;
}

.mx-wd-790-f {
  max-width: 790px !important;
}

.mn-wd-790-f {
  min-width: 790px !important;
}

.wd-795 {
  width: 795px;
}

.wd-795p {
  width: 795%;
}

.mx-wd-795p {
  max-width: 795%;
}

.mn-wd-795p {
  min-width: 795%;
}

.mx-wd-795 {
  max-width: 795px;
}

.mn-wd-795 {
  min-width: 795px;
}

.wd-795-f {
  width: 795px !important;
}

.wd-795p-f {
  width: 795% !important;
}

.mx-wd-795p-f {
  max-width: 795% !important;
}

.mn-wd-795p-f {
  min-width: 795% !important;
}

.mx-wd-795-f {
  max-width: 795px !important;
}

.mn-wd-795-f {
  min-width: 795px !important;
}

.wd-800 {
  width: 800px;
}

.wd-800p {
  width: 800%;
}

.mx-wd-800p {
  max-width: 800%;
}

.mn-wd-800p {
  min-width: 800%;
}

.mx-wd-800 {
  max-width: 800px;
}

.mn-wd-800 {
  min-width: 800px;
}

.wd-800-f {
  width: 800px !important;
}

.wd-800p-f {
  width: 800% !important;
}

.mx-wd-800p-f {
  max-width: 800% !important;
}

.mn-wd-800p-f {
  min-width: 800% !important;
}

.mx-wd-800-f {
  max-width: 800px !important;
}

.mn-wd-800-f {
  min-width: 800px !important;
}

.wd-805 {
  width: 805px;
}

.wd-805p {
  width: 805%;
}

.mx-wd-805p {
  max-width: 805%;
}

.mn-wd-805p {
  min-width: 805%;
}

.mx-wd-805 {
  max-width: 805px;
}

.mn-wd-805 {
  min-width: 805px;
}

.wd-805-f {
  width: 805px !important;
}

.wd-805p-f {
  width: 805% !important;
}

.mx-wd-805p-f {
  max-width: 805% !important;
}

.mn-wd-805p-f {
  min-width: 805% !important;
}

.mx-wd-805-f {
  max-width: 805px !important;
}

.mn-wd-805-f {
  min-width: 805px !important;
}

.wd-810 {
  width: 810px;
}

.wd-810p {
  width: 810%;
}

.mx-wd-810p {
  max-width: 810%;
}

.mn-wd-810p {
  min-width: 810%;
}

.mx-wd-810 {
  max-width: 810px;
}

.mn-wd-810 {
  min-width: 810px;
}

.wd-810-f {
  width: 810px !important;
}

.wd-810p-f {
  width: 810% !important;
}

.mx-wd-810p-f {
  max-width: 810% !important;
}

.mn-wd-810p-f {
  min-width: 810% !important;
}

.mx-wd-810-f {
  max-width: 810px !important;
}

.mn-wd-810-f {
  min-width: 810px !important;
}

.wd-815 {
  width: 815px;
}

.wd-815p {
  width: 815%;
}

.mx-wd-815p {
  max-width: 815%;
}

.mn-wd-815p {
  min-width: 815%;
}

.mx-wd-815 {
  max-width: 815px;
}

.mn-wd-815 {
  min-width: 815px;
}

.wd-815-f {
  width: 815px !important;
}

.wd-815p-f {
  width: 815% !important;
}

.mx-wd-815p-f {
  max-width: 815% !important;
}

.mn-wd-815p-f {
  min-width: 815% !important;
}

.mx-wd-815-f {
  max-width: 815px !important;
}

.mn-wd-815-f {
  min-width: 815px !important;
}

.wd-820 {
  width: 820px;
}

.wd-820p {
  width: 820%;
}

.mx-wd-820p {
  max-width: 820%;
}

.mn-wd-820p {
  min-width: 820%;
}

.mx-wd-820 {
  max-width: 820px;
}

.mn-wd-820 {
  min-width: 820px;
}

.wd-820-f {
  width: 820px !important;
}

.wd-820p-f {
  width: 820% !important;
}

.mx-wd-820p-f {
  max-width: 820% !important;
}

.mn-wd-820p-f {
  min-width: 820% !important;
}

.mx-wd-820-f {
  max-width: 820px !important;
}

.mn-wd-820-f {
  min-width: 820px !important;
}

.wd-825 {
  width: 825px;
}

.wd-825p {
  width: 825%;
}

.mx-wd-825p {
  max-width: 825%;
}

.mn-wd-825p {
  min-width: 825%;
}

.mx-wd-825 {
  max-width: 825px;
}

.mn-wd-825 {
  min-width: 825px;
}

.wd-825-f {
  width: 825px !important;
}

.wd-825p-f {
  width: 825% !important;
}

.mx-wd-825p-f {
  max-width: 825% !important;
}

.mn-wd-825p-f {
  min-width: 825% !important;
}

.mx-wd-825-f {
  max-width: 825px !important;
}

.mn-wd-825-f {
  min-width: 825px !important;
}

.wd-830 {
  width: 830px;
}

.wd-830p {
  width: 830%;
}

.mx-wd-830p {
  max-width: 830%;
}

.mn-wd-830p {
  min-width: 830%;
}

.mx-wd-830 {
  max-width: 830px;
}

.mn-wd-830 {
  min-width: 830px;
}

.wd-830-f {
  width: 830px !important;
}

.wd-830p-f {
  width: 830% !important;
}

.mx-wd-830p-f {
  max-width: 830% !important;
}

.mn-wd-830p-f {
  min-width: 830% !important;
}

.mx-wd-830-f {
  max-width: 830px !important;
}

.mn-wd-830-f {
  min-width: 830px !important;
}

.wd-835 {
  width: 835px;
}

.wd-835p {
  width: 835%;
}

.mx-wd-835p {
  max-width: 835%;
}

.mn-wd-835p {
  min-width: 835%;
}

.mx-wd-835 {
  max-width: 835px;
}

.mn-wd-835 {
  min-width: 835px;
}

.wd-835-f {
  width: 835px !important;
}

.wd-835p-f {
  width: 835% !important;
}

.mx-wd-835p-f {
  max-width: 835% !important;
}

.mn-wd-835p-f {
  min-width: 835% !important;
}

.mx-wd-835-f {
  max-width: 835px !important;
}

.mn-wd-835-f {
  min-width: 835px !important;
}

.wd-840 {
  width: 840px;
}

.wd-840p {
  width: 840%;
}

.mx-wd-840p {
  max-width: 840%;
}

.mn-wd-840p {
  min-width: 840%;
}

.mx-wd-840 {
  max-width: 840px;
}

.mn-wd-840 {
  min-width: 840px;
}

.wd-840-f {
  width: 840px !important;
}

.wd-840p-f {
  width: 840% !important;
}

.mx-wd-840p-f {
  max-width: 840% !important;
}

.mn-wd-840p-f {
  min-width: 840% !important;
}

.mx-wd-840-f {
  max-width: 840px !important;
}

.mn-wd-840-f {
  min-width: 840px !important;
}

.wd-845 {
  width: 845px;
}

.wd-845p {
  width: 845%;
}

.mx-wd-845p {
  max-width: 845%;
}

.mn-wd-845p {
  min-width: 845%;
}

.mx-wd-845 {
  max-width: 845px;
}

.mn-wd-845 {
  min-width: 845px;
}

.wd-845-f {
  width: 845px !important;
}

.wd-845p-f {
  width: 845% !important;
}

.mx-wd-845p-f {
  max-width: 845% !important;
}

.mn-wd-845p-f {
  min-width: 845% !important;
}

.mx-wd-845-f {
  max-width: 845px !important;
}

.mn-wd-845-f {
  min-width: 845px !important;
}

.wd-850 {
  width: 850px;
}

.wd-850p {
  width: 850%;
}

.mx-wd-850p {
  max-width: 850%;
}

.mn-wd-850p {
  min-width: 850%;
}

.mx-wd-850 {
  max-width: 850px;
}

.mn-wd-850 {
  min-width: 850px;
}

.wd-850-f {
  width: 850px !important;
}

.wd-850p-f {
  width: 850% !important;
}

.mx-wd-850p-f {
  max-width: 850% !important;
}

.mn-wd-850p-f {
  min-width: 850% !important;
}

.mx-wd-850-f {
  max-width: 850px !important;
}

.mn-wd-850-f {
  min-width: 850px !important;
}

.wd-855 {
  width: 855px;
}

.wd-855p {
  width: 855%;
}

.mx-wd-855p {
  max-width: 855%;
}

.mn-wd-855p {
  min-width: 855%;
}

.mx-wd-855 {
  max-width: 855px;
}

.mn-wd-855 {
  min-width: 855px;
}

.wd-855-f {
  width: 855px !important;
}

.wd-855p-f {
  width: 855% !important;
}

.mx-wd-855p-f {
  max-width: 855% !important;
}

.mn-wd-855p-f {
  min-width: 855% !important;
}

.mx-wd-855-f {
  max-width: 855px !important;
}

.mn-wd-855-f {
  min-width: 855px !important;
}

.wd-860 {
  width: 860px;
}

.wd-860p {
  width: 860%;
}

.mx-wd-860p {
  max-width: 860%;
}

.mn-wd-860p {
  min-width: 860%;
}

.mx-wd-860 {
  max-width: 860px;
}

.mn-wd-860 {
  min-width: 860px;
}

.wd-860-f {
  width: 860px !important;
}

.wd-860p-f {
  width: 860% !important;
}

.mx-wd-860p-f {
  max-width: 860% !important;
}

.mn-wd-860p-f {
  min-width: 860% !important;
}

.mx-wd-860-f {
  max-width: 860px !important;
}

.mn-wd-860-f {
  min-width: 860px !important;
}

.wd-865 {
  width: 865px;
}

.wd-865p {
  width: 865%;
}

.mx-wd-865p {
  max-width: 865%;
}

.mn-wd-865p {
  min-width: 865%;
}

.mx-wd-865 {
  max-width: 865px;
}

.mn-wd-865 {
  min-width: 865px;
}

.wd-865-f {
  width: 865px !important;
}

.wd-865p-f {
  width: 865% !important;
}

.mx-wd-865p-f {
  max-width: 865% !important;
}

.mn-wd-865p-f {
  min-width: 865% !important;
}

.mx-wd-865-f {
  max-width: 865px !important;
}

.mn-wd-865-f {
  min-width: 865px !important;
}

.wd-870 {
  width: 870px;
}

.wd-870p {
  width: 870%;
}

.mx-wd-870p {
  max-width: 870%;
}

.mn-wd-870p {
  min-width: 870%;
}

.mx-wd-870 {
  max-width: 870px;
}

.mn-wd-870 {
  min-width: 870px;
}

.wd-870-f {
  width: 870px !important;
}

.wd-870p-f {
  width: 870% !important;
}

.mx-wd-870p-f {
  max-width: 870% !important;
}

.mn-wd-870p-f {
  min-width: 870% !important;
}

.mx-wd-870-f {
  max-width: 870px !important;
}

.mn-wd-870-f {
  min-width: 870px !important;
}

.wd-875 {
  width: 875px;
}

.wd-875p {
  width: 875%;
}

.mx-wd-875p {
  max-width: 875%;
}

.mn-wd-875p {
  min-width: 875%;
}

.mx-wd-875 {
  max-width: 875px;
}

.mn-wd-875 {
  min-width: 875px;
}

.wd-875-f {
  width: 875px !important;
}

.wd-875p-f {
  width: 875% !important;
}

.mx-wd-875p-f {
  max-width: 875% !important;
}

.mn-wd-875p-f {
  min-width: 875% !important;
}

.mx-wd-875-f {
  max-width: 875px !important;
}

.mn-wd-875-f {
  min-width: 875px !important;
}

.wd-880 {
  width: 880px;
}

.wd-880p {
  width: 880%;
}

.mx-wd-880p {
  max-width: 880%;
}

.mn-wd-880p {
  min-width: 880%;
}

.mx-wd-880 {
  max-width: 880px;
}

.mn-wd-880 {
  min-width: 880px;
}

.wd-880-f {
  width: 880px !important;
}

.wd-880p-f {
  width: 880% !important;
}

.mx-wd-880p-f {
  max-width: 880% !important;
}

.mn-wd-880p-f {
  min-width: 880% !important;
}

.mx-wd-880-f {
  max-width: 880px !important;
}

.mn-wd-880-f {
  min-width: 880px !important;
}

.wd-885 {
  width: 885px;
}

.wd-885p {
  width: 885%;
}

.mx-wd-885p {
  max-width: 885%;
}

.mn-wd-885p {
  min-width: 885%;
}

.mx-wd-885 {
  max-width: 885px;
}

.mn-wd-885 {
  min-width: 885px;
}

.wd-885-f {
  width: 885px !important;
}

.wd-885p-f {
  width: 885% !important;
}

.mx-wd-885p-f {
  max-width: 885% !important;
}

.mn-wd-885p-f {
  min-width: 885% !important;
}

.mx-wd-885-f {
  max-width: 885px !important;
}

.mn-wd-885-f {
  min-width: 885px !important;
}

.wd-890 {
  width: 890px;
}

.wd-890p {
  width: 890%;
}

.mx-wd-890p {
  max-width: 890%;
}

.mn-wd-890p {
  min-width: 890%;
}

.mx-wd-890 {
  max-width: 890px;
}

.mn-wd-890 {
  min-width: 890px;
}

.wd-890-f {
  width: 890px !important;
}

.wd-890p-f {
  width: 890% !important;
}

.mx-wd-890p-f {
  max-width: 890% !important;
}

.mn-wd-890p-f {
  min-width: 890% !important;
}

.mx-wd-890-f {
  max-width: 890px !important;
}

.mn-wd-890-f {
  min-width: 890px !important;
}

.wd-895 {
  width: 895px;
}

.wd-895p {
  width: 895%;
}

.mx-wd-895p {
  max-width: 895%;
}

.mn-wd-895p {
  min-width: 895%;
}

.mx-wd-895 {
  max-width: 895px;
}

.mn-wd-895 {
  min-width: 895px;
}

.wd-895-f {
  width: 895px !important;
}

.wd-895p-f {
  width: 895% !important;
}

.mx-wd-895p-f {
  max-width: 895% !important;
}

.mn-wd-895p-f {
  min-width: 895% !important;
}

.mx-wd-895-f {
  max-width: 895px !important;
}

.mn-wd-895-f {
  min-width: 895px !important;
}

.wd-900 {
  width: 900px;
}

.wd-900p {
  width: 900%;
}

.mx-wd-900p {
  max-width: 900%;
}

.mn-wd-900p {
  min-width: 900%;
}

.mx-wd-900 {
  max-width: 900px;
}

.mn-wd-900 {
  min-width: 900px;
}

.wd-900-f {
  width: 900px !important;
}

.wd-900p-f {
  width: 900% !important;
}

.mx-wd-900p-f {
  max-width: 900% !important;
}

.mn-wd-900p-f {
  min-width: 900% !important;
}

.mx-wd-900-f {
  max-width: 900px !important;
}

.mn-wd-900-f {
  min-width: 900px !important;
}

.wd-905 {
  width: 905px;
}

.wd-905p {
  width: 905%;
}

.mx-wd-905p {
  max-width: 905%;
}

.mn-wd-905p {
  min-width: 905%;
}

.mx-wd-905 {
  max-width: 905px;
}

.mn-wd-905 {
  min-width: 905px;
}

.wd-905-f {
  width: 905px !important;
}

.wd-905p-f {
  width: 905% !important;
}

.mx-wd-905p-f {
  max-width: 905% !important;
}

.mn-wd-905p-f {
  min-width: 905% !important;
}

.mx-wd-905-f {
  max-width: 905px !important;
}

.mn-wd-905-f {
  min-width: 905px !important;
}

.wd-910 {
  width: 910px;
}

.wd-910p {
  width: 910%;
}

.mx-wd-910p {
  max-width: 910%;
}

.mn-wd-910p {
  min-width: 910%;
}

.mx-wd-910 {
  max-width: 910px;
}

.mn-wd-910 {
  min-width: 910px;
}

.wd-910-f {
  width: 910px !important;
}

.wd-910p-f {
  width: 910% !important;
}

.mx-wd-910p-f {
  max-width: 910% !important;
}

.mn-wd-910p-f {
  min-width: 910% !important;
}

.mx-wd-910-f {
  max-width: 910px !important;
}

.mn-wd-910-f {
  min-width: 910px !important;
}

.wd-915 {
  width: 915px;
}

.wd-915p {
  width: 915%;
}

.mx-wd-915p {
  max-width: 915%;
}

.mn-wd-915p {
  min-width: 915%;
}

.mx-wd-915 {
  max-width: 915px;
}

.mn-wd-915 {
  min-width: 915px;
}

.wd-915-f {
  width: 915px !important;
}

.wd-915p-f {
  width: 915% !important;
}

.mx-wd-915p-f {
  max-width: 915% !important;
}

.mn-wd-915p-f {
  min-width: 915% !important;
}

.mx-wd-915-f {
  max-width: 915px !important;
}

.mn-wd-915-f {
  min-width: 915px !important;
}

.wd-920 {
  width: 920px;
}

.wd-920p {
  width: 920%;
}

.mx-wd-920p {
  max-width: 920%;
}

.mn-wd-920p {
  min-width: 920%;
}

.mx-wd-920 {
  max-width: 920px;
}

.mn-wd-920 {
  min-width: 920px;
}

.wd-920-f {
  width: 920px !important;
}

.wd-920p-f {
  width: 920% !important;
}

.mx-wd-920p-f {
  max-width: 920% !important;
}

.mn-wd-920p-f {
  min-width: 920% !important;
}

.mx-wd-920-f {
  max-width: 920px !important;
}

.mn-wd-920-f {
  min-width: 920px !important;
}

.wd-925 {
  width: 925px;
}

.wd-925p {
  width: 925%;
}

.mx-wd-925p {
  max-width: 925%;
}

.mn-wd-925p {
  min-width: 925%;
}

.mx-wd-925 {
  max-width: 925px;
}

.mn-wd-925 {
  min-width: 925px;
}

.wd-925-f {
  width: 925px !important;
}

.wd-925p-f {
  width: 925% !important;
}

.mx-wd-925p-f {
  max-width: 925% !important;
}

.mn-wd-925p-f {
  min-width: 925% !important;
}

.mx-wd-925-f {
  max-width: 925px !important;
}

.mn-wd-925-f {
  min-width: 925px !important;
}

.wd-930 {
  width: 930px;
}

.wd-930p {
  width: 930%;
}

.mx-wd-930p {
  max-width: 930%;
}

.mn-wd-930p {
  min-width: 930%;
}

.mx-wd-930 {
  max-width: 930px;
}

.mn-wd-930 {
  min-width: 930px;
}

.wd-930-f {
  width: 930px !important;
}

.wd-930p-f {
  width: 930% !important;
}

.mx-wd-930p-f {
  max-width: 930% !important;
}

.mn-wd-930p-f {
  min-width: 930% !important;
}

.mx-wd-930-f {
  max-width: 930px !important;
}

.mn-wd-930-f {
  min-width: 930px !important;
}

.wd-935 {
  width: 935px;
}

.wd-935p {
  width: 935%;
}

.mx-wd-935p {
  max-width: 935%;
}

.mn-wd-935p {
  min-width: 935%;
}

.mx-wd-935 {
  max-width: 935px;
}

.mn-wd-935 {
  min-width: 935px;
}

.wd-935-f {
  width: 935px !important;
}

.wd-935p-f {
  width: 935% !important;
}

.mx-wd-935p-f {
  max-width: 935% !important;
}

.mn-wd-935p-f {
  min-width: 935% !important;
}

.mx-wd-935-f {
  max-width: 935px !important;
}

.mn-wd-935-f {
  min-width: 935px !important;
}

.wd-940 {
  width: 940px;
}

.wd-940p {
  width: 940%;
}

.mx-wd-940p {
  max-width: 940%;
}

.mn-wd-940p {
  min-width: 940%;
}

.mx-wd-940 {
  max-width: 940px;
}

.mn-wd-940 {
  min-width: 940px;
}

.wd-940-f {
  width: 940px !important;
}

.wd-940p-f {
  width: 940% !important;
}

.mx-wd-940p-f {
  max-width: 940% !important;
}

.mn-wd-940p-f {
  min-width: 940% !important;
}

.mx-wd-940-f {
  max-width: 940px !important;
}

.mn-wd-940-f {
  min-width: 940px !important;
}

.wd-945 {
  width: 945px;
}

.wd-945p {
  width: 945%;
}

.mx-wd-945p {
  max-width: 945%;
}

.mn-wd-945p {
  min-width: 945%;
}

.mx-wd-945 {
  max-width: 945px;
}

.mn-wd-945 {
  min-width: 945px;
}

.wd-945-f {
  width: 945px !important;
}

.wd-945p-f {
  width: 945% !important;
}

.mx-wd-945p-f {
  max-width: 945% !important;
}

.mn-wd-945p-f {
  min-width: 945% !important;
}

.mx-wd-945-f {
  max-width: 945px !important;
}

.mn-wd-945-f {
  min-width: 945px !important;
}

.wd-950 {
  width: 950px;
}

.wd-950p {
  width: 950%;
}

.mx-wd-950p {
  max-width: 950%;
}

.mn-wd-950p {
  min-width: 950%;
}

.mx-wd-950 {
  max-width: 950px;
}

.mn-wd-950 {
  min-width: 950px;
}

.wd-950-f {
  width: 950px !important;
}

.wd-950p-f {
  width: 950% !important;
}

.mx-wd-950p-f {
  max-width: 950% !important;
}

.mn-wd-950p-f {
  min-width: 950% !important;
}

.mx-wd-950-f {
  max-width: 950px !important;
}

.mn-wd-950-f {
  min-width: 950px !important;
}

.wd-955 {
  width: 955px;
}

.wd-955p {
  width: 955%;
}

.mx-wd-955p {
  max-width: 955%;
}

.mn-wd-955p {
  min-width: 955%;
}

.mx-wd-955 {
  max-width: 955px;
}

.mn-wd-955 {
  min-width: 955px;
}

.wd-955-f {
  width: 955px !important;
}

.wd-955p-f {
  width: 955% !important;
}

.mx-wd-955p-f {
  max-width: 955% !important;
}

.mn-wd-955p-f {
  min-width: 955% !important;
}

.mx-wd-955-f {
  max-width: 955px !important;
}

.mn-wd-955-f {
  min-width: 955px !important;
}

.wd-960 {
  width: 960px;
}

.wd-960p {
  width: 960%;
}

.mx-wd-960p {
  max-width: 960%;
}

.mn-wd-960p {
  min-width: 960%;
}

.mx-wd-960 {
  max-width: 960px;
}

.mn-wd-960 {
  min-width: 960px;
}

.wd-960-f {
  width: 960px !important;
}

.wd-960p-f {
  width: 960% !important;
}

.mx-wd-960p-f {
  max-width: 960% !important;
}

.mn-wd-960p-f {
  min-width: 960% !important;
}

.mx-wd-960-f {
  max-width: 960px !important;
}

.mn-wd-960-f {
  min-width: 960px !important;
}

.wd-965 {
  width: 965px;
}

.wd-965p {
  width: 965%;
}

.mx-wd-965p {
  max-width: 965%;
}

.mn-wd-965p {
  min-width: 965%;
}

.mx-wd-965 {
  max-width: 965px;
}

.mn-wd-965 {
  min-width: 965px;
}

.wd-965-f {
  width: 965px !important;
}

.wd-965p-f {
  width: 965% !important;
}

.mx-wd-965p-f {
  max-width: 965% !important;
}

.mn-wd-965p-f {
  min-width: 965% !important;
}

.mx-wd-965-f {
  max-width: 965px !important;
}

.mn-wd-965-f {
  min-width: 965px !important;
}

.wd-970 {
  width: 970px;
}

.wd-970p {
  width: 970%;
}

.mx-wd-970p {
  max-width: 970%;
}

.mn-wd-970p {
  min-width: 970%;
}

.mx-wd-970 {
  max-width: 970px;
}

.mn-wd-970 {
  min-width: 970px;
}

.wd-970-f {
  width: 970px !important;
}

.wd-970p-f {
  width: 970% !important;
}

.mx-wd-970p-f {
  max-width: 970% !important;
}

.mn-wd-970p-f {
  min-width: 970% !important;
}

.mx-wd-970-f {
  max-width: 970px !important;
}

.mn-wd-970-f {
  min-width: 970px !important;
}

.wd-975 {
  width: 975px;
}

.wd-975p {
  width: 975%;
}

.mx-wd-975p {
  max-width: 975%;
}

.mn-wd-975p {
  min-width: 975%;
}

.mx-wd-975 {
  max-width: 975px;
}

.mn-wd-975 {
  min-width: 975px;
}

.wd-975-f {
  width: 975px !important;
}

.wd-975p-f {
  width: 975% !important;
}

.mx-wd-975p-f {
  max-width: 975% !important;
}

.mn-wd-975p-f {
  min-width: 975% !important;
}

.mx-wd-975-f {
  max-width: 975px !important;
}

.mn-wd-975-f {
  min-width: 975px !important;
}

.wd-980 {
  width: 980px;
}

.wd-980p {
  width: 980%;
}

.mx-wd-980p {
  max-width: 980%;
}

.mn-wd-980p {
  min-width: 980%;
}

.mx-wd-980 {
  max-width: 980px;
}

.mn-wd-980 {
  min-width: 980px;
}

.wd-980-f {
  width: 980px !important;
}

.wd-980p-f {
  width: 980% !important;
}

.mx-wd-980p-f {
  max-width: 980% !important;
}

.mn-wd-980p-f {
  min-width: 980% !important;
}

.mx-wd-980-f {
  max-width: 980px !important;
}

.mn-wd-980-f {
  min-width: 980px !important;
}

.wd-985 {
  width: 985px;
}

.wd-985p {
  width: 985%;
}

.mx-wd-985p {
  max-width: 985%;
}

.mn-wd-985p {
  min-width: 985%;
}

.mx-wd-985 {
  max-width: 985px;
}

.mn-wd-985 {
  min-width: 985px;
}

.wd-985-f {
  width: 985px !important;
}

.wd-985p-f {
  width: 985% !important;
}

.mx-wd-985p-f {
  max-width: 985% !important;
}

.mn-wd-985p-f {
  min-width: 985% !important;
}

.mx-wd-985-f {
  max-width: 985px !important;
}

.mn-wd-985-f {
  min-width: 985px !important;
}

.wd-990 {
  width: 990px;
}

.wd-990p {
  width: 990%;
}

.mx-wd-990p {
  max-width: 990%;
}

.mn-wd-990p {
  min-width: 990%;
}

.mx-wd-990 {
  max-width: 990px;
}

.mn-wd-990 {
  min-width: 990px;
}

.wd-990-f {
  width: 990px !important;
}

.wd-990p-f {
  width: 990% !important;
}

.mx-wd-990p-f {
  max-width: 990% !important;
}

.mn-wd-990p-f {
  min-width: 990% !important;
}

.mx-wd-990-f {
  max-width: 990px !important;
}

.mn-wd-990-f {
  min-width: 990px !important;
}

.wd-995 {
  width: 995px;
}

.wd-995p {
  width: 995%;
}

.mx-wd-995p {
  max-width: 995%;
}

.mn-wd-995p {
  min-width: 995%;
}

.mx-wd-995 {
  max-width: 995px;
}

.mn-wd-995 {
  min-width: 995px;
}

.wd-995-f {
  width: 995px !important;
}

.wd-995p-f {
  width: 995% !important;
}

.mx-wd-995p-f {
  max-width: 995% !important;
}

.mn-wd-995p-f {
  min-width: 995% !important;
}

.mx-wd-995-f {
  max-width: 995px !important;
}

.mn-wd-995-f {
  min-width: 995px !important;
}

.wd-1000 {
  width: 1000px;
}

.wd-1000p {
  width: 1000%;
}

.mx-wd-1000p {
  max-width: 1000%;
}

.mn-wd-1000p {
  min-width: 1000%;
}

.mx-wd-1000 {
  max-width: 1000px;
}

.mn-wd-1000 {
  min-width: 1000px;
}

.wd-1000-f {
  width: 1000px !important;
}

.wd-1000p-f {
  width: 1000% !important;
}

.mx-wd-1000p-f {
  max-width: 1000% !important;
}

.mn-wd-1000p-f {
  min-width: 1000% !important;
}

.mx-wd-1000-f {
  max-width: 1000px !important;
}

.mn-wd-1000-f {
  min-width: 1000px !important;
}

.wd-150 {
  width: 150px;
}

.wd-150-f {
  width: 150px !important;
}

.wd-200 {
  width: 200px;
}

.wd-200-f {
  width: 200px !important;
}

.wd-250 {
  width: 250px;
}

.wd-250-f {
  width: 250px !important;
}

.wd-300 {
  width: 300px;
}

.wd-300-f {
  width: 300px !important;
}

.wd-350 {
  width: 350px;
}

.wd-350-f {
  width: 350px !important;
}

.wd-400 {
  width: 400px;
}

.wd-400-f {
  width: 400px !important;
}

.wd-450 {
  width: 450px;
}

.wd-450-f {
  width: 450px !important;
}

.wd-500 {
  width: 500px;
}

.wd-500-f {
  width: 500px !important;
}

.wd-550 {
  width: 550px;
}

.wd-550-f {
  width: 550px !important;
}

.wd-600 {
  width: 600px;
}

.wd-600-f {
  width: 600px !important;
}

.wd-650 {
  width: 650px;
}

.wd-650-f {
  width: 650px !important;
}

.wd-700 {
  width: 700px;
}

.wd-700-f {
  width: 700px !important;
}

.wd-750 {
  width: 750px;
}

.wd-750-f {
  width: 750px !important;
}

.wd-800 {
  width: 800px;
}

.wd-800-f {
  width: 800px !important;
}

.wd-850 {
  width: 850px;
}

.wd-850-f {
  width: 850px !important;
}

.wd-900 {
  width: 900px;
}

.wd-900-f {
  width: 900px !important;
}

.wd-950 {
  width: 950px;
}

.wd-950-f {
  width: 950px !important;
}

.wd-1000 {
  width: 1000px;
}

.wd-1000-f {
  width: 1000px !important;
}

@media (min-width: 480px) {
  .wd-xs-5 {
    width: 5px;
  }

  .wd-xs-5p {
    width: 5%;
  }

  .mx-wd-xs-5p {
    max-width: 5%;
  }

  .mn-wd-xs-5p {
    min-width: 5%;
  }

  .mx-wd-xs-5 {
    max-width: 5px;
  }

  .mn-wd-xs-5 {
    min-width: 5px;
  }

  .wd-xs-5-f {
    width: 5px !important;
  }

  .wd-xs-5p-f {
    width: 5% !important;
  }

  .mx-wd-xs-5p-f {
    max-width: 5% !important;
  }

  .mn-wd-xs-5p-f {
    min-width: 5% !important;
  }

  .mx-wd-xs-5-f {
    max-width: 5px !important;
  }

  .mn-wd-xs-5-f {
    min-width: 5px !important;
  }

  .wd-xs-10 {
    width: 10px;
  }

  .wd-xs-10p {
    width: 10%;
  }

  .mx-wd-xs-10p {
    max-width: 10%;
  }

  .mn-wd-xs-10p {
    min-width: 10%;
  }

  .mx-wd-xs-10 {
    max-width: 10px;
  }

  .mn-wd-xs-10 {
    min-width: 10px;
  }

  .wd-xs-10-f {
    width: 10px !important;
  }

  .wd-xs-10p-f {
    width: 10% !important;
  }

  .mx-wd-xs-10p-f {
    max-width: 10% !important;
  }

  .mn-wd-xs-10p-f {
    min-width: 10% !important;
  }

  .mx-wd-xs-10-f {
    max-width: 10px !important;
  }

  .mn-wd-xs-10-f {
    min-width: 10px !important;
  }

  .wd-xs-15 {
    width: 15px;
  }

  .wd-xs-15p {
    width: 15%;
  }

  .mx-wd-xs-15p {
    max-width: 15%;
  }

  .mn-wd-xs-15p {
    min-width: 15%;
  }

  .mx-wd-xs-15 {
    max-width: 15px;
  }

  .mn-wd-xs-15 {
    min-width: 15px;
  }

  .wd-xs-15-f {
    width: 15px !important;
  }

  .wd-xs-15p-f {
    width: 15% !important;
  }

  .mx-wd-xs-15p-f {
    max-width: 15% !important;
  }

  .mn-wd-xs-15p-f {
    min-width: 15% !important;
  }

  .mx-wd-xs-15-f {
    max-width: 15px !important;
  }

  .mn-wd-xs-15-f {
    min-width: 15px !important;
  }

  .wd-xs-20 {
    width: 20px;
  }

  .wd-xs-20p {
    width: 20%;
  }

  .mx-wd-xs-20p {
    max-width: 20%;
  }

  .mn-wd-xs-20p {
    min-width: 20%;
  }

  .mx-wd-xs-20 {
    max-width: 20px;
  }

  .mn-wd-xs-20 {
    min-width: 20px;
  }

  .wd-xs-20-f {
    width: 20px !important;
  }

  .wd-xs-20p-f {
    width: 20% !important;
  }

  .mx-wd-xs-20p-f {
    max-width: 20% !important;
  }

  .mn-wd-xs-20p-f {
    min-width: 20% !important;
  }

  .mx-wd-xs-20-f {
    max-width: 20px !important;
  }

  .mn-wd-xs-20-f {
    min-width: 20px !important;
  }

  .wd-xs-25 {
    width: 25px;
  }

  .wd-xs-25p {
    width: 25%;
  }

  .mx-wd-xs-25p {
    max-width: 25%;
  }

  .mn-wd-xs-25p {
    min-width: 25%;
  }

  .mx-wd-xs-25 {
    max-width: 25px;
  }

  .mn-wd-xs-25 {
    min-width: 25px;
  }

  .wd-xs-25-f {
    width: 25px !important;
  }

  .wd-xs-25p-f {
    width: 25% !important;
  }

  .mx-wd-xs-25p-f {
    max-width: 25% !important;
  }

  .mn-wd-xs-25p-f {
    min-width: 25% !important;
  }

  .mx-wd-xs-25-f {
    max-width: 25px !important;
  }

  .mn-wd-xs-25-f {
    min-width: 25px !important;
  }

  .wd-xs-30 {
    width: 30px;
  }

  .wd-xs-30p {
    width: 30%;
  }

  .mx-wd-xs-30p {
    max-width: 30%;
  }

  .mn-wd-xs-30p {
    min-width: 30%;
  }

  .mx-wd-xs-30 {
    max-width: 30px;
  }

  .mn-wd-xs-30 {
    min-width: 30px;
  }

  .wd-xs-30-f {
    width: 30px !important;
  }

  .wd-xs-30p-f {
    width: 30% !important;
  }

  .mx-wd-xs-30p-f {
    max-width: 30% !important;
  }

  .mn-wd-xs-30p-f {
    min-width: 30% !important;
  }

  .mx-wd-xs-30-f {
    max-width: 30px !important;
  }

  .mn-wd-xs-30-f {
    min-width: 30px !important;
  }

  .wd-xs-35 {
    width: 35px;
  }

  .wd-xs-35p {
    width: 35%;
  }

  .mx-wd-xs-35p {
    max-width: 35%;
  }

  .mn-wd-xs-35p {
    min-width: 35%;
  }

  .mx-wd-xs-35 {
    max-width: 35px;
  }

  .mn-wd-xs-35 {
    min-width: 35px;
  }

  .wd-xs-35-f {
    width: 35px !important;
  }

  .wd-xs-35p-f {
    width: 35% !important;
  }

  .mx-wd-xs-35p-f {
    max-width: 35% !important;
  }

  .mn-wd-xs-35p-f {
    min-width: 35% !important;
  }

  .mx-wd-xs-35-f {
    max-width: 35px !important;
  }

  .mn-wd-xs-35-f {
    min-width: 35px !important;
  }

  .wd-xs-40 {
    width: 40px;
  }

  .wd-xs-40p {
    width: 40%;
  }

  .mx-wd-xs-40p {
    max-width: 40%;
  }

  .mn-wd-xs-40p {
    min-width: 40%;
  }

  .mx-wd-xs-40 {
    max-width: 40px;
  }

  .mn-wd-xs-40 {
    min-width: 40px;
  }

  .wd-xs-40-f {
    width: 40px !important;
  }

  .wd-xs-40p-f {
    width: 40% !important;
  }

  .mx-wd-xs-40p-f {
    max-width: 40% !important;
  }

  .mn-wd-xs-40p-f {
    min-width: 40% !important;
  }

  .mx-wd-xs-40-f {
    max-width: 40px !important;
  }

  .mn-wd-xs-40-f {
    min-width: 40px !important;
  }

  .wd-xs-45 {
    width: 45px;
  }

  .wd-xs-45p {
    width: 45%;
  }

  .mx-wd-xs-45p {
    max-width: 45%;
  }

  .mn-wd-xs-45p {
    min-width: 45%;
  }

  .mx-wd-xs-45 {
    max-width: 45px;
  }

  .mn-wd-xs-45 {
    min-width: 45px;
  }

  .wd-xs-45-f {
    width: 45px !important;
  }

  .wd-xs-45p-f {
    width: 45% !important;
  }

  .mx-wd-xs-45p-f {
    max-width: 45% !important;
  }

  .mn-wd-xs-45p-f {
    min-width: 45% !important;
  }

  .mx-wd-xs-45-f {
    max-width: 45px !important;
  }

  .mn-wd-xs-45-f {
    min-width: 45px !important;
  }

  .wd-xs-50 {
    width: 50px;
  }

  .wd-xs-50p {
    width: 50%;
  }

  .mx-wd-xs-50p {
    max-width: 50%;
  }

  .mn-wd-xs-50p {
    min-width: 50%;
  }

  .mx-wd-xs-50 {
    max-width: 50px;
  }

  .mn-wd-xs-50 {
    min-width: 50px;
  }

  .wd-xs-50-f {
    width: 50px !important;
  }

  .wd-xs-50p-f {
    width: 50% !important;
  }

  .mx-wd-xs-50p-f {
    max-width: 50% !important;
  }

  .mn-wd-xs-50p-f {
    min-width: 50% !important;
  }

  .mx-wd-xs-50-f {
    max-width: 50px !important;
  }

  .mn-wd-xs-50-f {
    min-width: 50px !important;
  }

  .wd-xs-55 {
    width: 55px;
  }

  .wd-xs-55p {
    width: 55%;
  }

  .mx-wd-xs-55p {
    max-width: 55%;
  }

  .mn-wd-xs-55p {
    min-width: 55%;
  }

  .mx-wd-xs-55 {
    max-width: 55px;
  }

  .mn-wd-xs-55 {
    min-width: 55px;
  }

  .wd-xs-55-f {
    width: 55px !important;
  }

  .wd-xs-55p-f {
    width: 55% !important;
  }

  .mx-wd-xs-55p-f {
    max-width: 55% !important;
  }

  .mn-wd-xs-55p-f {
    min-width: 55% !important;
  }

  .mx-wd-xs-55-f {
    max-width: 55px !important;
  }

  .mn-wd-xs-55-f {
    min-width: 55px !important;
  }

  .wd-xs-60 {
    width: 60px;
  }

  .wd-xs-60p {
    width: 60%;
  }

  .mx-wd-xs-60p {
    max-width: 60%;
  }

  .mn-wd-xs-60p {
    min-width: 60%;
  }

  .mx-wd-xs-60 {
    max-width: 60px;
  }

  .mn-wd-xs-60 {
    min-width: 60px;
  }

  .wd-xs-60-f {
    width: 60px !important;
  }

  .wd-xs-60p-f {
    width: 60% !important;
  }

  .mx-wd-xs-60p-f {
    max-width: 60% !important;
  }

  .mn-wd-xs-60p-f {
    min-width: 60% !important;
  }

  .mx-wd-xs-60-f {
    max-width: 60px !important;
  }

  .mn-wd-xs-60-f {
    min-width: 60px !important;
  }

  .wd-xs-65 {
    width: 65px;
  }

  .wd-xs-65p {
    width: 65%;
  }

  .mx-wd-xs-65p {
    max-width: 65%;
  }

  .mn-wd-xs-65p {
    min-width: 65%;
  }

  .mx-wd-xs-65 {
    max-width: 65px;
  }

  .mn-wd-xs-65 {
    min-width: 65px;
  }

  .wd-xs-65-f {
    width: 65px !important;
  }

  .wd-xs-65p-f {
    width: 65% !important;
  }

  .mx-wd-xs-65p-f {
    max-width: 65% !important;
  }

  .mn-wd-xs-65p-f {
    min-width: 65% !important;
  }

  .mx-wd-xs-65-f {
    max-width: 65px !important;
  }

  .mn-wd-xs-65-f {
    min-width: 65px !important;
  }

  .wd-xs-70 {
    width: 70px;
  }

  .wd-xs-70p {
    width: 70%;
  }

  .mx-wd-xs-70p {
    max-width: 70%;
  }

  .mn-wd-xs-70p {
    min-width: 70%;
  }

  .mx-wd-xs-70 {
    max-width: 70px;
  }

  .mn-wd-xs-70 {
    min-width: 70px;
  }

  .wd-xs-70-f {
    width: 70px !important;
  }

  .wd-xs-70p-f {
    width: 70% !important;
  }

  .mx-wd-xs-70p-f {
    max-width: 70% !important;
  }

  .mn-wd-xs-70p-f {
    min-width: 70% !important;
  }

  .mx-wd-xs-70-f {
    max-width: 70px !important;
  }

  .mn-wd-xs-70-f {
    min-width: 70px !important;
  }

  .wd-xs-75 {
    width: 75px;
  }

  .wd-xs-75p {
    width: 75%;
  }

  .mx-wd-xs-75p {
    max-width: 75%;
  }

  .mn-wd-xs-75p {
    min-width: 75%;
  }

  .mx-wd-xs-75 {
    max-width: 75px;
  }

  .mn-wd-xs-75 {
    min-width: 75px;
  }

  .wd-xs-75-f {
    width: 75px !important;
  }

  .wd-xs-75p-f {
    width: 75% !important;
  }

  .mx-wd-xs-75p-f {
    max-width: 75% !important;
  }

  .mn-wd-xs-75p-f {
    min-width: 75% !important;
  }

  .mx-wd-xs-75-f {
    max-width: 75px !important;
  }

  .mn-wd-xs-75-f {
    min-width: 75px !important;
  }

  .wd-xs-80 {
    width: 80px;
  }

  .wd-xs-80p {
    width: 80%;
  }

  .mx-wd-xs-80p {
    max-width: 80%;
  }

  .mn-wd-xs-80p {
    min-width: 80%;
  }

  .mx-wd-xs-80 {
    max-width: 80px;
  }

  .mn-wd-xs-80 {
    min-width: 80px;
  }

  .wd-xs-80-f {
    width: 80px !important;
  }

  .wd-xs-80p-f {
    width: 80% !important;
  }

  .mx-wd-xs-80p-f {
    max-width: 80% !important;
  }

  .mn-wd-xs-80p-f {
    min-width: 80% !important;
  }

  .mx-wd-xs-80-f {
    max-width: 80px !important;
  }

  .mn-wd-xs-80-f {
    min-width: 80px !important;
  }

  .wd-xs-85 {
    width: 85px;
  }

  .wd-xs-85p {
    width: 85%;
  }

  .mx-wd-xs-85p {
    max-width: 85%;
  }

  .mn-wd-xs-85p {
    min-width: 85%;
  }

  .mx-wd-xs-85 {
    max-width: 85px;
  }

  .mn-wd-xs-85 {
    min-width: 85px;
  }

  .wd-xs-85-f {
    width: 85px !important;
  }

  .wd-xs-85p-f {
    width: 85% !important;
  }

  .mx-wd-xs-85p-f {
    max-width: 85% !important;
  }

  .mn-wd-xs-85p-f {
    min-width: 85% !important;
  }

  .mx-wd-xs-85-f {
    max-width: 85px !important;
  }

  .mn-wd-xs-85-f {
    min-width: 85px !important;
  }

  .wd-xs-90 {
    width: 90px;
  }

  .wd-xs-90p {
    width: 90%;
  }

  .mx-wd-xs-90p {
    max-width: 90%;
  }

  .mn-wd-xs-90p {
    min-width: 90%;
  }

  .mx-wd-xs-90 {
    max-width: 90px;
  }

  .mn-wd-xs-90 {
    min-width: 90px;
  }

  .wd-xs-90-f {
    width: 90px !important;
  }

  .wd-xs-90p-f {
    width: 90% !important;
  }

  .mx-wd-xs-90p-f {
    max-width: 90% !important;
  }

  .mn-wd-xs-90p-f {
    min-width: 90% !important;
  }

  .mx-wd-xs-90-f {
    max-width: 90px !important;
  }

  .mn-wd-xs-90-f {
    min-width: 90px !important;
  }

  .wd-xs-95 {
    width: 95px;
  }

  .wd-xs-95p {
    width: 95%;
  }

  .mx-wd-xs-95p {
    max-width: 95%;
  }

  .mn-wd-xs-95p {
    min-width: 95%;
  }

  .mx-wd-xs-95 {
    max-width: 95px;
  }

  .mn-wd-xs-95 {
    min-width: 95px;
  }

  .wd-xs-95-f {
    width: 95px !important;
  }

  .wd-xs-95p-f {
    width: 95% !important;
  }

  .mx-wd-xs-95p-f {
    max-width: 95% !important;
  }

  .mn-wd-xs-95p-f {
    min-width: 95% !important;
  }

  .mx-wd-xs-95-f {
    max-width: 95px !important;
  }

  .mn-wd-xs-95-f {
    min-width: 95px !important;
  }

  .wd-xs-100 {
    width: 100px;
  }

  .wd-xs-100p {
    width: 100%;
  }

  .mx-wd-xs-100p {
    max-width: 100%;
  }

  .mn-wd-xs-100p {
    min-width: 100%;
  }

  .mx-wd-xs-100 {
    max-width: 100px;
  }

  .mn-wd-xs-100 {
    min-width: 100px;
  }

  .wd-xs-100-f {
    width: 100px !important;
  }

  .wd-xs-100p-f {
    width: 100% !important;
  }

  .mx-wd-xs-100p-f {
    max-width: 100% !important;
  }

  .mn-wd-xs-100p-f {
    min-width: 100% !important;
  }

  .mx-wd-xs-100-f {
    max-width: 100px !important;
  }

  .mn-wd-xs-100-f {
    min-width: 100px !important;
  }

  .wd-xs-150 {
    width: 150px;
  }

  .wd-xs-150p {
    width: 150%;
  }

  .mx-wd-xs-150p {
    max-width: 150%;
  }

  .mn-wd-xs-150p {
    min-width: 150%;
  }

  .mx-wd-xs-150 {
    max-width: 150px;
  }

  .mn-wd-xs-150 {
    min-width: 150px;
  }

  .wd-xs-150-f {
    width: 150px !important;
  }

  .wd-xs-150p-f {
    width: 150% !important;
  }

  .mx-wd-xs-150p-f {
    max-width: 150% !important;
  }

  .mn-wd-xs-150p-f {
    min-width: 150% !important;
  }

  .mx-wd-xs-150-f {
    max-width: 150px !important;
  }

  .mn-wd-xs-150-f {
    min-width: 150px !important;
  }

  .wd-xs-200 {
    width: 200px;
  }

  .wd-xs-200p {
    width: 200%;
  }

  .mx-wd-xs-200p {
    max-width: 200%;
  }

  .mn-wd-xs-200p {
    min-width: 200%;
  }

  .mx-wd-xs-200 {
    max-width: 200px;
  }

  .mn-wd-xs-200 {
    min-width: 200px;
  }

  .wd-xs-200-f {
    width: 200px !important;
  }

  .wd-xs-200p-f {
    width: 200% !important;
  }

  .mx-wd-xs-200p-f {
    max-width: 200% !important;
  }

  .mn-wd-xs-200p-f {
    min-width: 200% !important;
  }

  .mx-wd-xs-200-f {
    max-width: 200px !important;
  }

  .mn-wd-xs-200-f {
    min-width: 200px !important;
  }

  .wd-xs-250 {
    width: 250px;
  }

  .wd-xs-250p {
    width: 250%;
  }

  .mx-wd-xs-250p {
    max-width: 250%;
  }

  .mn-wd-xs-250p {
    min-width: 250%;
  }

  .mx-wd-xs-250 {
    max-width: 250px;
  }

  .mn-wd-xs-250 {
    min-width: 250px;
  }

  .wd-xs-250-f {
    width: 250px !important;
  }

  .wd-xs-250p-f {
    width: 250% !important;
  }

  .mx-wd-xs-250p-f {
    max-width: 250% !important;
  }

  .mn-wd-xs-250p-f {
    min-width: 250% !important;
  }

  .mx-wd-xs-250-f {
    max-width: 250px !important;
  }

  .mn-wd-xs-250-f {
    min-width: 250px !important;
  }

  .wd-xs-300 {
    width: 300px;
  }

  .wd-xs-300p {
    width: 300%;
  }

  .mx-wd-xs-300p {
    max-width: 300%;
  }

  .mn-wd-xs-300p {
    min-width: 300%;
  }

  .mx-wd-xs-300 {
    max-width: 300px;
  }

  .mn-wd-xs-300 {
    min-width: 300px;
  }

  .wd-xs-300-f {
    width: 300px !important;
  }

  .wd-xs-300p-f {
    width: 300% !important;
  }

  .mx-wd-xs-300p-f {
    max-width: 300% !important;
  }

  .mn-wd-xs-300p-f {
    min-width: 300% !important;
  }

  .mx-wd-xs-300-f {
    max-width: 300px !important;
  }

  .mn-wd-xs-300-f {
    min-width: 300px !important;
  }

  .wd-xs-350 {
    width: 350px;
  }

  .wd-xs-350p {
    width: 350%;
  }

  .mx-wd-xs-350p {
    max-width: 350%;
  }

  .mn-wd-xs-350p {
    min-width: 350%;
  }

  .mx-wd-xs-350 {
    max-width: 350px;
  }

  .mn-wd-xs-350 {
    min-width: 350px;
  }

  .wd-xs-350-f {
    width: 350px !important;
  }

  .wd-xs-350p-f {
    width: 350% !important;
  }

  .mx-wd-xs-350p-f {
    max-width: 350% !important;
  }

  .mn-wd-xs-350p-f {
    min-width: 350% !important;
  }

  .mx-wd-xs-350-f {
    max-width: 350px !important;
  }

  .mn-wd-xs-350-f {
    min-width: 350px !important;
  }

  .wd-xs-400 {
    width: 400px;
  }

  .wd-xs-400p {
    width: 400%;
  }

  .mx-wd-xs-400p {
    max-width: 400%;
  }

  .mn-wd-xs-400p {
    min-width: 400%;
  }

  .mx-wd-xs-400 {
    max-width: 400px;
  }

  .mn-wd-xs-400 {
    min-width: 400px;
  }

  .wd-xs-400-f {
    width: 400px !important;
  }

  .wd-xs-400p-f {
    width: 400% !important;
  }

  .mx-wd-xs-400p-f {
    max-width: 400% !important;
  }

  .mn-wd-xs-400p-f {
    min-width: 400% !important;
  }

  .mx-wd-xs-400-f {
    max-width: 400px !important;
  }

  .mn-wd-xs-400-f {
    min-width: 400px !important;
  }

  .wd-xs-450 {
    width: 450px;
  }

  .wd-xs-450p {
    width: 450%;
  }

  .mx-wd-xs-450p {
    max-width: 450%;
  }

  .mn-wd-xs-450p {
    min-width: 450%;
  }

  .mx-wd-xs-450 {
    max-width: 450px;
  }

  .mn-wd-xs-450 {
    min-width: 450px;
  }

  .wd-xs-450-f {
    width: 450px !important;
  }

  .wd-xs-450p-f {
    width: 450% !important;
  }

  .mx-wd-xs-450p-f {
    max-width: 450% !important;
  }

  .mn-wd-xs-450p-f {
    min-width: 450% !important;
  }

  .mx-wd-xs-450-f {
    max-width: 450px !important;
  }

  .mn-wd-xs-450-f {
    min-width: 450px !important;
  }

  .wd-xs-500 {
    width: 500px;
  }

  .wd-xs-500p {
    width: 500%;
  }

  .mx-wd-xs-500p {
    max-width: 500%;
  }

  .mn-wd-xs-500p {
    min-width: 500%;
  }

  .mx-wd-xs-500 {
    max-width: 500px;
  }

  .mn-wd-xs-500 {
    min-width: 500px;
  }

  .wd-xs-500-f {
    width: 500px !important;
  }

  .wd-xs-500p-f {
    width: 500% !important;
  }

  .mx-wd-xs-500p-f {
    max-width: 500% !important;
  }

  .mn-wd-xs-500p-f {
    min-width: 500% !important;
  }

  .mx-wd-xs-500-f {
    max-width: 500px !important;
  }

  .mn-wd-xs-500-f {
    min-width: 500px !important;
  }

  .wd-xs-550 {
    width: 550px;
  }

  .wd-xs-550p {
    width: 550%;
  }

  .mx-wd-xs-550p {
    max-width: 550%;
  }

  .mn-wd-xs-550p {
    min-width: 550%;
  }

  .mx-wd-xs-550 {
    max-width: 550px;
  }

  .mn-wd-xs-550 {
    min-width: 550px;
  }

  .wd-xs-550-f {
    width: 550px !important;
  }

  .wd-xs-550p-f {
    width: 550% !important;
  }

  .mx-wd-xs-550p-f {
    max-width: 550% !important;
  }

  .mn-wd-xs-550p-f {
    min-width: 550% !important;
  }

  .mx-wd-xs-550-f {
    max-width: 550px !important;
  }

  .mn-wd-xs-550-f {
    min-width: 550px !important;
  }

  .wd-xs-600 {
    width: 600px;
  }

  .wd-xs-600p {
    width: 600%;
  }

  .mx-wd-xs-600p {
    max-width: 600%;
  }

  .mn-wd-xs-600p {
    min-width: 600%;
  }

  .mx-wd-xs-600 {
    max-width: 600px;
  }

  .mn-wd-xs-600 {
    min-width: 600px;
  }

  .wd-xs-600-f {
    width: 600px !important;
  }

  .wd-xs-600p-f {
    width: 600% !important;
  }

  .mx-wd-xs-600p-f {
    max-width: 600% !important;
  }

  .mn-wd-xs-600p-f {
    min-width: 600% !important;
  }

  .mx-wd-xs-600-f {
    max-width: 600px !important;
  }

  .mn-wd-xs-600-f {
    min-width: 600px !important;
  }

  .wd-xs-650 {
    width: 650px;
  }

  .wd-xs-650p {
    width: 650%;
  }

  .mx-wd-xs-650p {
    max-width: 650%;
  }

  .mn-wd-xs-650p {
    min-width: 650%;
  }

  .mx-wd-xs-650 {
    max-width: 650px;
  }

  .mn-wd-xs-650 {
    min-width: 650px;
  }

  .wd-xs-650-f {
    width: 650px !important;
  }

  .wd-xs-650p-f {
    width: 650% !important;
  }

  .mx-wd-xs-650p-f {
    max-width: 650% !important;
  }

  .mn-wd-xs-650p-f {
    min-width: 650% !important;
  }

  .mx-wd-xs-650-f {
    max-width: 650px !important;
  }

  .mn-wd-xs-650-f {
    min-width: 650px !important;
  }

  .wd-xs-700 {
    width: 700px;
  }

  .wd-xs-700p {
    width: 700%;
  }

  .mx-wd-xs-700p {
    max-width: 700%;
  }

  .mn-wd-xs-700p {
    min-width: 700%;
  }

  .mx-wd-xs-700 {
    max-width: 700px;
  }

  .mn-wd-xs-700 {
    min-width: 700px;
  }

  .wd-xs-700-f {
    width: 700px !important;
  }

  .wd-xs-700p-f {
    width: 700% !important;
  }

  .mx-wd-xs-700p-f {
    max-width: 700% !important;
  }

  .mn-wd-xs-700p-f {
    min-width: 700% !important;
  }

  .mx-wd-xs-700-f {
    max-width: 700px !important;
  }

  .mn-wd-xs-700-f {
    min-width: 700px !important;
  }

  .wd-xs-750 {
    width: 750px;
  }

  .wd-xs-750p {
    width: 750%;
  }

  .mx-wd-xs-750p {
    max-width: 750%;
  }

  .mn-wd-xs-750p {
    min-width: 750%;
  }

  .mx-wd-xs-750 {
    max-width: 750px;
  }

  .mn-wd-xs-750 {
    min-width: 750px;
  }

  .wd-xs-750-f {
    width: 750px !important;
  }

  .wd-xs-750p-f {
    width: 750% !important;
  }

  .mx-wd-xs-750p-f {
    max-width: 750% !important;
  }

  .mn-wd-xs-750p-f {
    min-width: 750% !important;
  }

  .mx-wd-xs-750-f {
    max-width: 750px !important;
  }

  .mn-wd-xs-750-f {
    min-width: 750px !important;
  }

  .wd-xs-800 {
    width: 800px;
  }

  .wd-xs-800p {
    width: 800%;
  }

  .mx-wd-xs-800p {
    max-width: 800%;
  }

  .mn-wd-xs-800p {
    min-width: 800%;
  }

  .mx-wd-xs-800 {
    max-width: 800px;
  }

  .mn-wd-xs-800 {
    min-width: 800px;
  }

  .wd-xs-800-f {
    width: 800px !important;
  }

  .wd-xs-800p-f {
    width: 800% !important;
  }

  .mx-wd-xs-800p-f {
    max-width: 800% !important;
  }

  .mn-wd-xs-800p-f {
    min-width: 800% !important;
  }

  .mx-wd-xs-800-f {
    max-width: 800px !important;
  }

  .mn-wd-xs-800-f {
    min-width: 800px !important;
  }

  .wd-xs-850 {
    width: 850px;
  }

  .wd-xs-850p {
    width: 850%;
  }

  .mx-wd-xs-850p {
    max-width: 850%;
  }

  .mn-wd-xs-850p {
    min-width: 850%;
  }

  .mx-wd-xs-850 {
    max-width: 850px;
  }

  .mn-wd-xs-850 {
    min-width: 850px;
  }

  .wd-xs-850-f {
    width: 850px !important;
  }

  .wd-xs-850p-f {
    width: 850% !important;
  }

  .mx-wd-xs-850p-f {
    max-width: 850% !important;
  }

  .mn-wd-xs-850p-f {
    min-width: 850% !important;
  }

  .mx-wd-xs-850-f {
    max-width: 850px !important;
  }

  .mn-wd-xs-850-f {
    min-width: 850px !important;
  }

  .wd-xs-900 {
    width: 900px;
  }

  .wd-xs-900p {
    width: 900%;
  }

  .mx-wd-xs-900p {
    max-width: 900%;
  }

  .mn-wd-xs-900p {
    min-width: 900%;
  }

  .mx-wd-xs-900 {
    max-width: 900px;
  }

  .mn-wd-xs-900 {
    min-width: 900px;
  }

  .wd-xs-900-f {
    width: 900px !important;
  }

  .wd-xs-900p-f {
    width: 900% !important;
  }

  .mx-wd-xs-900p-f {
    max-width: 900% !important;
  }

  .mn-wd-xs-900p-f {
    min-width: 900% !important;
  }

  .mx-wd-xs-900-f {
    max-width: 900px !important;
  }

  .mn-wd-xs-900-f {
    min-width: 900px !important;
  }

  .wd-xs-950 {
    width: 950px;
  }

  .wd-xs-950p {
    width: 950%;
  }

  .mx-wd-xs-950p {
    max-width: 950%;
  }

  .mn-wd-xs-950p {
    min-width: 950%;
  }

  .mx-wd-xs-950 {
    max-width: 950px;
  }

  .mn-wd-xs-950 {
    min-width: 950px;
  }

  .wd-xs-950-f {
    width: 950px !important;
  }

  .wd-xs-950p-f {
    width: 950% !important;
  }

  .mx-wd-xs-950p-f {
    max-width: 950% !important;
  }

  .mn-wd-xs-950p-f {
    min-width: 950% !important;
  }

  .mx-wd-xs-950-f {
    max-width: 950px !important;
  }

  .mn-wd-xs-950-f {
    min-width: 950px !important;
  }

  .wd-xs-1000 {
    width: 1000px;
  }

  .wd-xs-1000p {
    width: 1000%;
  }

  .mx-wd-xs-1000p {
    max-width: 1000%;
  }

  .mn-wd-xs-1000p {
    min-width: 1000%;
  }

  .mx-wd-xs-1000 {
    max-width: 1000px;
  }

  .mn-wd-xs-1000 {
    min-width: 1000px;
  }

  .wd-xs-1000-f {
    width: 1000px !important;
  }

  .wd-xs-1000p-f {
    width: 1000% !important;
  }

  .mx-wd-xs-1000p-f {
    max-width: 1000% !important;
  }

  .mn-wd-xs-1000p-f {
    min-width: 1000% !important;
  }

  .mx-wd-xs-1000-f {
    max-width: 1000px !important;
  }

  .mn-wd-xs-1000-f {
    min-width: 1000px !important;
  }

  .wd-xs-auto {
    width: auto;
  }

  .wd-xs-auto-f {
    width: auto !important;
  }
}
@media (min-width: 576px) {
  .wd-sm-5 {
    width: 5px;
  }

  .wd-sm-5p {
    width: 5%;
  }

  .mx-wd-sm-5p {
    max-width: 5%;
  }

  .mn-wd-sm-5p {
    min-width: 5%;
  }

  .mx-wd-sm-5 {
    max-width: 5px;
  }

  .mn-wd-sm-5 {
    min-width: 5px;
  }

  .wd-sm-5-f {
    width: 5px !important;
  }

  .wd-sm-5p-f {
    width: 5% !important;
  }

  .mx-wd-sm-5p-f {
    max-width: 5% !important;
  }

  .mn-wd-sm-5p-f {
    min-width: 5% !important;
  }

  .mx-wd-sm-5-f {
    max-width: 5px !important;
  }

  .mn-wd-sm-5-f {
    min-width: 5px !important;
  }

  .wd-sm-10 {
    width: 10px;
  }

  .wd-sm-10p {
    width: 10%;
  }

  .mx-wd-sm-10p {
    max-width: 10%;
  }

  .mn-wd-sm-10p {
    min-width: 10%;
  }

  .mx-wd-sm-10 {
    max-width: 10px;
  }

  .mn-wd-sm-10 {
    min-width: 10px;
  }

  .wd-sm-10-f {
    width: 10px !important;
  }

  .wd-sm-10p-f {
    width: 10% !important;
  }

  .mx-wd-sm-10p-f {
    max-width: 10% !important;
  }

  .mn-wd-sm-10p-f {
    min-width: 10% !important;
  }

  .mx-wd-sm-10-f {
    max-width: 10px !important;
  }

  .mn-wd-sm-10-f {
    min-width: 10px !important;
  }

  .wd-sm-15 {
    width: 15px;
  }

  .wd-sm-15p {
    width: 15%;
  }

  .mx-wd-sm-15p {
    max-width: 15%;
  }

  .mn-wd-sm-15p {
    min-width: 15%;
  }

  .mx-wd-sm-15 {
    max-width: 15px;
  }

  .mn-wd-sm-15 {
    min-width: 15px;
  }

  .wd-sm-15-f {
    width: 15px !important;
  }

  .wd-sm-15p-f {
    width: 15% !important;
  }

  .mx-wd-sm-15p-f {
    max-width: 15% !important;
  }

  .mn-wd-sm-15p-f {
    min-width: 15% !important;
  }

  .mx-wd-sm-15-f {
    max-width: 15px !important;
  }

  .mn-wd-sm-15-f {
    min-width: 15px !important;
  }

  .wd-sm-20 {
    width: 20px;
  }

  .wd-sm-20p {
    width: 20%;
  }

  .mx-wd-sm-20p {
    max-width: 20%;
  }

  .mn-wd-sm-20p {
    min-width: 20%;
  }

  .mx-wd-sm-20 {
    max-width: 20px;
  }

  .mn-wd-sm-20 {
    min-width: 20px;
  }

  .wd-sm-20-f {
    width: 20px !important;
  }

  .wd-sm-20p-f {
    width: 20% !important;
  }

  .mx-wd-sm-20p-f {
    max-width: 20% !important;
  }

  .mn-wd-sm-20p-f {
    min-width: 20% !important;
  }

  .mx-wd-sm-20-f {
    max-width: 20px !important;
  }

  .mn-wd-sm-20-f {
    min-width: 20px !important;
  }

  .wd-sm-25 {
    width: 25px;
  }

  .wd-sm-25p {
    width: 25%;
  }

  .mx-wd-sm-25p {
    max-width: 25%;
  }

  .mn-wd-sm-25p {
    min-width: 25%;
  }

  .mx-wd-sm-25 {
    max-width: 25px;
  }

  .mn-wd-sm-25 {
    min-width: 25px;
  }

  .wd-sm-25-f {
    width: 25px !important;
  }

  .wd-sm-25p-f {
    width: 25% !important;
  }

  .mx-wd-sm-25p-f {
    max-width: 25% !important;
  }

  .mn-wd-sm-25p-f {
    min-width: 25% !important;
  }

  .mx-wd-sm-25-f {
    max-width: 25px !important;
  }

  .mn-wd-sm-25-f {
    min-width: 25px !important;
  }

  .wd-sm-30 {
    width: 30px;
  }

  .wd-sm-30p {
    width: 30%;
  }

  .mx-wd-sm-30p {
    max-width: 30%;
  }

  .mn-wd-sm-30p {
    min-width: 30%;
  }

  .mx-wd-sm-30 {
    max-width: 30px;
  }

  .mn-wd-sm-30 {
    min-width: 30px;
  }

  .wd-sm-30-f {
    width: 30px !important;
  }

  .wd-sm-30p-f {
    width: 30% !important;
  }

  .mx-wd-sm-30p-f {
    max-width: 30% !important;
  }

  .mn-wd-sm-30p-f {
    min-width: 30% !important;
  }

  .mx-wd-sm-30-f {
    max-width: 30px !important;
  }

  .mn-wd-sm-30-f {
    min-width: 30px !important;
  }

  .wd-sm-35 {
    width: 35px;
  }

  .wd-sm-35p {
    width: 35%;
  }

  .mx-wd-sm-35p {
    max-width: 35%;
  }

  .mn-wd-sm-35p {
    min-width: 35%;
  }

  .mx-wd-sm-35 {
    max-width: 35px;
  }

  .mn-wd-sm-35 {
    min-width: 35px;
  }

  .wd-sm-35-f {
    width: 35px !important;
  }

  .wd-sm-35p-f {
    width: 35% !important;
  }

  .mx-wd-sm-35p-f {
    max-width: 35% !important;
  }

  .mn-wd-sm-35p-f {
    min-width: 35% !important;
  }

  .mx-wd-sm-35-f {
    max-width: 35px !important;
  }

  .mn-wd-sm-35-f {
    min-width: 35px !important;
  }

  .wd-sm-40 {
    width: 40px;
  }

  .wd-sm-40p {
    width: 40%;
  }

  .mx-wd-sm-40p {
    max-width: 40%;
  }

  .mn-wd-sm-40p {
    min-width: 40%;
  }

  .mx-wd-sm-40 {
    max-width: 40px;
  }

  .mn-wd-sm-40 {
    min-width: 40px;
  }

  .wd-sm-40-f {
    width: 40px !important;
  }

  .wd-sm-40p-f {
    width: 40% !important;
  }

  .mx-wd-sm-40p-f {
    max-width: 40% !important;
  }

  .mn-wd-sm-40p-f {
    min-width: 40% !important;
  }

  .mx-wd-sm-40-f {
    max-width: 40px !important;
  }

  .mn-wd-sm-40-f {
    min-width: 40px !important;
  }

  .wd-sm-45 {
    width: 45px;
  }

  .wd-sm-45p {
    width: 45%;
  }

  .mx-wd-sm-45p {
    max-width: 45%;
  }

  .mn-wd-sm-45p {
    min-width: 45%;
  }

  .mx-wd-sm-45 {
    max-width: 45px;
  }

  .mn-wd-sm-45 {
    min-width: 45px;
  }

  .wd-sm-45-f {
    width: 45px !important;
  }

  .wd-sm-45p-f {
    width: 45% !important;
  }

  .mx-wd-sm-45p-f {
    max-width: 45% !important;
  }

  .mn-wd-sm-45p-f {
    min-width: 45% !important;
  }

  .mx-wd-sm-45-f {
    max-width: 45px !important;
  }

  .mn-wd-sm-45-f {
    min-width: 45px !important;
  }

  .wd-sm-50 {
    width: 50px;
  }

  .wd-sm-50p {
    width: 50%;
  }

  .mx-wd-sm-50p {
    max-width: 50%;
  }

  .mn-wd-sm-50p {
    min-width: 50%;
  }

  .mx-wd-sm-50 {
    max-width: 50px;
  }

  .mn-wd-sm-50 {
    min-width: 50px;
  }

  .wd-sm-50-f {
    width: 50px !important;
  }

  .wd-sm-50p-f {
    width: 50% !important;
  }

  .mx-wd-sm-50p-f {
    max-width: 50% !important;
  }

  .mn-wd-sm-50p-f {
    min-width: 50% !important;
  }

  .mx-wd-sm-50-f {
    max-width: 50px !important;
  }

  .mn-wd-sm-50-f {
    min-width: 50px !important;
  }

  .wd-sm-55 {
    width: 55px;
  }

  .wd-sm-55p {
    width: 55%;
  }

  .mx-wd-sm-55p {
    max-width: 55%;
  }

  .mn-wd-sm-55p {
    min-width: 55%;
  }

  .mx-wd-sm-55 {
    max-width: 55px;
  }

  .mn-wd-sm-55 {
    min-width: 55px;
  }

  .wd-sm-55-f {
    width: 55px !important;
  }

  .wd-sm-55p-f {
    width: 55% !important;
  }

  .mx-wd-sm-55p-f {
    max-width: 55% !important;
  }

  .mn-wd-sm-55p-f {
    min-width: 55% !important;
  }

  .mx-wd-sm-55-f {
    max-width: 55px !important;
  }

  .mn-wd-sm-55-f {
    min-width: 55px !important;
  }

  .wd-sm-60 {
    width: 60px;
  }

  .wd-sm-60p {
    width: 60%;
  }

  .mx-wd-sm-60p {
    max-width: 60%;
  }

  .mn-wd-sm-60p {
    min-width: 60%;
  }

  .mx-wd-sm-60 {
    max-width: 60px;
  }

  .mn-wd-sm-60 {
    min-width: 60px;
  }

  .wd-sm-60-f {
    width: 60px !important;
  }

  .wd-sm-60p-f {
    width: 60% !important;
  }

  .mx-wd-sm-60p-f {
    max-width: 60% !important;
  }

  .mn-wd-sm-60p-f {
    min-width: 60% !important;
  }

  .mx-wd-sm-60-f {
    max-width: 60px !important;
  }

  .mn-wd-sm-60-f {
    min-width: 60px !important;
  }

  .wd-sm-65 {
    width: 65px;
  }

  .wd-sm-65p {
    width: 65%;
  }

  .mx-wd-sm-65p {
    max-width: 65%;
  }

  .mn-wd-sm-65p {
    min-width: 65%;
  }

  .mx-wd-sm-65 {
    max-width: 65px;
  }

  .mn-wd-sm-65 {
    min-width: 65px;
  }

  .wd-sm-65-f {
    width: 65px !important;
  }

  .wd-sm-65p-f {
    width: 65% !important;
  }

  .mx-wd-sm-65p-f {
    max-width: 65% !important;
  }

  .mn-wd-sm-65p-f {
    min-width: 65% !important;
  }

  .mx-wd-sm-65-f {
    max-width: 65px !important;
  }

  .mn-wd-sm-65-f {
    min-width: 65px !important;
  }

  .wd-sm-70 {
    width: 70px;
  }

  .wd-sm-70p {
    width: 70%;
  }

  .mx-wd-sm-70p {
    max-width: 70%;
  }

  .mn-wd-sm-70p {
    min-width: 70%;
  }

  .mx-wd-sm-70 {
    max-width: 70px;
  }

  .mn-wd-sm-70 {
    min-width: 70px;
  }

  .wd-sm-70-f {
    width: 70px !important;
  }

  .wd-sm-70p-f {
    width: 70% !important;
  }

  .mx-wd-sm-70p-f {
    max-width: 70% !important;
  }

  .mn-wd-sm-70p-f {
    min-width: 70% !important;
  }

  .mx-wd-sm-70-f {
    max-width: 70px !important;
  }

  .mn-wd-sm-70-f {
    min-width: 70px !important;
  }

  .wd-sm-75 {
    width: 75px;
  }

  .wd-sm-75p {
    width: 75%;
  }

  .mx-wd-sm-75p {
    max-width: 75%;
  }

  .mn-wd-sm-75p {
    min-width: 75%;
  }

  .mx-wd-sm-75 {
    max-width: 75px;
  }

  .mn-wd-sm-75 {
    min-width: 75px;
  }

  .wd-sm-75-f {
    width: 75px !important;
  }

  .wd-sm-75p-f {
    width: 75% !important;
  }

  .mx-wd-sm-75p-f {
    max-width: 75% !important;
  }

  .mn-wd-sm-75p-f {
    min-width: 75% !important;
  }

  .mx-wd-sm-75-f {
    max-width: 75px !important;
  }

  .mn-wd-sm-75-f {
    min-width: 75px !important;
  }

  .wd-sm-80 {
    width: 80px;
  }

  .wd-sm-80p {
    width: 80%;
  }

  .mx-wd-sm-80p {
    max-width: 80%;
  }

  .mn-wd-sm-80p {
    min-width: 80%;
  }

  .mx-wd-sm-80 {
    max-width: 80px;
  }

  .mn-wd-sm-80 {
    min-width: 80px;
  }

  .wd-sm-80-f {
    width: 80px !important;
  }

  .wd-sm-80p-f {
    width: 80% !important;
  }

  .mx-wd-sm-80p-f {
    max-width: 80% !important;
  }

  .mn-wd-sm-80p-f {
    min-width: 80% !important;
  }

  .mx-wd-sm-80-f {
    max-width: 80px !important;
  }

  .mn-wd-sm-80-f {
    min-width: 80px !important;
  }

  .wd-sm-85 {
    width: 85px;
  }

  .wd-sm-85p {
    width: 85%;
  }

  .mx-wd-sm-85p {
    max-width: 85%;
  }

  .mn-wd-sm-85p {
    min-width: 85%;
  }

  .mx-wd-sm-85 {
    max-width: 85px;
  }

  .mn-wd-sm-85 {
    min-width: 85px;
  }

  .wd-sm-85-f {
    width: 85px !important;
  }

  .wd-sm-85p-f {
    width: 85% !important;
  }

  .mx-wd-sm-85p-f {
    max-width: 85% !important;
  }

  .mn-wd-sm-85p-f {
    min-width: 85% !important;
  }

  .mx-wd-sm-85-f {
    max-width: 85px !important;
  }

  .mn-wd-sm-85-f {
    min-width: 85px !important;
  }

  .wd-sm-90 {
    width: 90px;
  }

  .wd-sm-90p {
    width: 90%;
  }

  .mx-wd-sm-90p {
    max-width: 90%;
  }

  .mn-wd-sm-90p {
    min-width: 90%;
  }

  .mx-wd-sm-90 {
    max-width: 90px;
  }

  .mn-wd-sm-90 {
    min-width: 90px;
  }

  .wd-sm-90-f {
    width: 90px !important;
  }

  .wd-sm-90p-f {
    width: 90% !important;
  }

  .mx-wd-sm-90p-f {
    max-width: 90% !important;
  }

  .mn-wd-sm-90p-f {
    min-width: 90% !important;
  }

  .mx-wd-sm-90-f {
    max-width: 90px !important;
  }

  .mn-wd-sm-90-f {
    min-width: 90px !important;
  }

  .wd-sm-95 {
    width: 95px;
  }

  .wd-sm-95p {
    width: 95%;
  }

  .mx-wd-sm-95p {
    max-width: 95%;
  }

  .mn-wd-sm-95p {
    min-width: 95%;
  }

  .mx-wd-sm-95 {
    max-width: 95px;
  }

  .mn-wd-sm-95 {
    min-width: 95px;
  }

  .wd-sm-95-f {
    width: 95px !important;
  }

  .wd-sm-95p-f {
    width: 95% !important;
  }

  .mx-wd-sm-95p-f {
    max-width: 95% !important;
  }

  .mn-wd-sm-95p-f {
    min-width: 95% !important;
  }

  .mx-wd-sm-95-f {
    max-width: 95px !important;
  }

  .mn-wd-sm-95-f {
    min-width: 95px !important;
  }

  .wd-sm-100 {
    width: 100px;
  }

  .wd-sm-100p {
    width: 100%;
  }

  .mx-wd-sm-100p {
    max-width: 100%;
  }

  .mn-wd-sm-100p {
    min-width: 100%;
  }

  .mx-wd-sm-100 {
    max-width: 100px;
  }

  .mn-wd-sm-100 {
    min-width: 100px;
  }

  .wd-sm-100-f {
    width: 100px !important;
  }

  .wd-sm-100p-f {
    width: 100% !important;
  }

  .mx-wd-sm-100p-f {
    max-width: 100% !important;
  }

  .mn-wd-sm-100p-f {
    min-width: 100% !important;
  }

  .mx-wd-sm-100-f {
    max-width: 100px !important;
  }

  .mn-wd-sm-100-f {
    min-width: 100px !important;
  }

  .wd-sm-150 {
    width: 150px;
  }

  .wd-sm-150p {
    width: 150%;
  }

  .mx-wd-sm-150p {
    max-width: 150%;
  }

  .mn-wd-sm-150p {
    min-width: 150%;
  }

  .mx-wd-sm-150 {
    max-width: 150px;
  }

  .mn-wd-sm-150 {
    min-width: 150px;
  }

  .wd-sm-150-f {
    width: 150px !important;
  }

  .wd-sm-150p-f {
    width: 150% !important;
  }

  .mx-wd-sm-150p-f {
    max-width: 150% !important;
  }

  .mn-wd-sm-150p-f {
    min-width: 150% !important;
  }

  .mx-wd-sm-150-f {
    max-width: 150px !important;
  }

  .mn-wd-sm-150-f {
    min-width: 150px !important;
  }

  .wd-sm-200 {
    width: 200px;
  }

  .wd-sm-200p {
    width: 200%;
  }

  .mx-wd-sm-200p {
    max-width: 200%;
  }

  .mn-wd-sm-200p {
    min-width: 200%;
  }

  .mx-wd-sm-200 {
    max-width: 200px;
  }

  .mn-wd-sm-200 {
    min-width: 200px;
  }

  .wd-sm-200-f {
    width: 200px !important;
  }

  .wd-sm-200p-f {
    width: 200% !important;
  }

  .mx-wd-sm-200p-f {
    max-width: 200% !important;
  }

  .mn-wd-sm-200p-f {
    min-width: 200% !important;
  }

  .mx-wd-sm-200-f {
    max-width: 200px !important;
  }

  .mn-wd-sm-200-f {
    min-width: 200px !important;
  }

  .wd-sm-250 {
    width: 250px;
  }

  .wd-sm-250p {
    width: 250%;
  }

  .mx-wd-sm-250p {
    max-width: 250%;
  }

  .mn-wd-sm-250p {
    min-width: 250%;
  }

  .mx-wd-sm-250 {
    max-width: 250px;
  }

  .mn-wd-sm-250 {
    min-width: 250px;
  }

  .wd-sm-250-f {
    width: 250px !important;
  }

  .wd-sm-250p-f {
    width: 250% !important;
  }

  .mx-wd-sm-250p-f {
    max-width: 250% !important;
  }

  .mn-wd-sm-250p-f {
    min-width: 250% !important;
  }

  .mx-wd-sm-250-f {
    max-width: 250px !important;
  }

  .mn-wd-sm-250-f {
    min-width: 250px !important;
  }

  .wd-sm-300 {
    width: 300px;
  }

  .wd-sm-300p {
    width: 300%;
  }

  .mx-wd-sm-300p {
    max-width: 300%;
  }

  .mn-wd-sm-300p {
    min-width: 300%;
  }

  .mx-wd-sm-300 {
    max-width: 300px;
  }

  .mn-wd-sm-300 {
    min-width: 300px;
  }

  .wd-sm-300-f {
    width: 300px !important;
  }

  .wd-sm-300p-f {
    width: 300% !important;
  }

  .mx-wd-sm-300p-f {
    max-width: 300% !important;
  }

  .mn-wd-sm-300p-f {
    min-width: 300% !important;
  }

  .mx-wd-sm-300-f {
    max-width: 300px !important;
  }

  .mn-wd-sm-300-f {
    min-width: 300px !important;
  }

  .wd-sm-350 {
    width: 350px;
  }

  .wd-sm-350p {
    width: 350%;
  }

  .mx-wd-sm-350p {
    max-width: 350%;
  }

  .mn-wd-sm-350p {
    min-width: 350%;
  }

  .mx-wd-sm-350 {
    max-width: 350px;
  }

  .mn-wd-sm-350 {
    min-width: 350px;
  }

  .wd-sm-350-f {
    width: 350px !important;
  }

  .wd-sm-350p-f {
    width: 350% !important;
  }

  .mx-wd-sm-350p-f {
    max-width: 350% !important;
  }

  .mn-wd-sm-350p-f {
    min-width: 350% !important;
  }

  .mx-wd-sm-350-f {
    max-width: 350px !important;
  }

  .mn-wd-sm-350-f {
    min-width: 350px !important;
  }

  .wd-sm-400 {
    width: 400px;
  }

  .wd-sm-400p {
    width: 400%;
  }

  .mx-wd-sm-400p {
    max-width: 400%;
  }

  .mn-wd-sm-400p {
    min-width: 400%;
  }

  .mx-wd-sm-400 {
    max-width: 400px;
  }

  .mn-wd-sm-400 {
    min-width: 400px;
  }

  .wd-sm-400-f {
    width: 400px !important;
  }

  .wd-sm-400p-f {
    width: 400% !important;
  }

  .mx-wd-sm-400p-f {
    max-width: 400% !important;
  }

  .mn-wd-sm-400p-f {
    min-width: 400% !important;
  }

  .mx-wd-sm-400-f {
    max-width: 400px !important;
  }

  .mn-wd-sm-400-f {
    min-width: 400px !important;
  }

  .wd-sm-450 {
    width: 450px;
  }

  .wd-sm-450p {
    width: 450%;
  }

  .mx-wd-sm-450p {
    max-width: 450%;
  }

  .mn-wd-sm-450p {
    min-width: 450%;
  }

  .mx-wd-sm-450 {
    max-width: 450px;
  }

  .mn-wd-sm-450 {
    min-width: 450px;
  }

  .wd-sm-450-f {
    width: 450px !important;
  }

  .wd-sm-450p-f {
    width: 450% !important;
  }

  .mx-wd-sm-450p-f {
    max-width: 450% !important;
  }

  .mn-wd-sm-450p-f {
    min-width: 450% !important;
  }

  .mx-wd-sm-450-f {
    max-width: 450px !important;
  }

  .mn-wd-sm-450-f {
    min-width: 450px !important;
  }

  .wd-sm-500 {
    width: 500px;
  }

  .wd-sm-500p {
    width: 500%;
  }

  .mx-wd-sm-500p {
    max-width: 500%;
  }

  .mn-wd-sm-500p {
    min-width: 500%;
  }

  .mx-wd-sm-500 {
    max-width: 500px;
  }

  .mn-wd-sm-500 {
    min-width: 500px;
  }

  .wd-sm-500-f {
    width: 500px !important;
  }

  .wd-sm-500p-f {
    width: 500% !important;
  }

  .mx-wd-sm-500p-f {
    max-width: 500% !important;
  }

  .mn-wd-sm-500p-f {
    min-width: 500% !important;
  }

  .mx-wd-sm-500-f {
    max-width: 500px !important;
  }

  .mn-wd-sm-500-f {
    min-width: 500px !important;
  }

  .wd-sm-550 {
    width: 550px;
  }

  .wd-sm-550p {
    width: 550%;
  }

  .mx-wd-sm-550p {
    max-width: 550%;
  }

  .mn-wd-sm-550p {
    min-width: 550%;
  }

  .mx-wd-sm-550 {
    max-width: 550px;
  }

  .mn-wd-sm-550 {
    min-width: 550px;
  }

  .wd-sm-550-f {
    width: 550px !important;
  }

  .wd-sm-550p-f {
    width: 550% !important;
  }

  .mx-wd-sm-550p-f {
    max-width: 550% !important;
  }

  .mn-wd-sm-550p-f {
    min-width: 550% !important;
  }

  .mx-wd-sm-550-f {
    max-width: 550px !important;
  }

  .mn-wd-sm-550-f {
    min-width: 550px !important;
  }

  .wd-sm-600 {
    width: 600px;
  }

  .wd-sm-600p {
    width: 600%;
  }

  .mx-wd-sm-600p {
    max-width: 600%;
  }

  .mn-wd-sm-600p {
    min-width: 600%;
  }

  .mx-wd-sm-600 {
    max-width: 600px;
  }

  .mn-wd-sm-600 {
    min-width: 600px;
  }

  .wd-sm-600-f {
    width: 600px !important;
  }

  .wd-sm-600p-f {
    width: 600% !important;
  }

  .mx-wd-sm-600p-f {
    max-width: 600% !important;
  }

  .mn-wd-sm-600p-f {
    min-width: 600% !important;
  }

  .mx-wd-sm-600-f {
    max-width: 600px !important;
  }

  .mn-wd-sm-600-f {
    min-width: 600px !important;
  }

  .wd-sm-650 {
    width: 650px;
  }

  .wd-sm-650p {
    width: 650%;
  }

  .mx-wd-sm-650p {
    max-width: 650%;
  }

  .mn-wd-sm-650p {
    min-width: 650%;
  }

  .mx-wd-sm-650 {
    max-width: 650px;
  }

  .mn-wd-sm-650 {
    min-width: 650px;
  }

  .wd-sm-650-f {
    width: 650px !important;
  }

  .wd-sm-650p-f {
    width: 650% !important;
  }

  .mx-wd-sm-650p-f {
    max-width: 650% !important;
  }

  .mn-wd-sm-650p-f {
    min-width: 650% !important;
  }

  .mx-wd-sm-650-f {
    max-width: 650px !important;
  }

  .mn-wd-sm-650-f {
    min-width: 650px !important;
  }

  .wd-sm-700 {
    width: 700px;
  }

  .wd-sm-700p {
    width: 700%;
  }

  .mx-wd-sm-700p {
    max-width: 700%;
  }

  .mn-wd-sm-700p {
    min-width: 700%;
  }

  .mx-wd-sm-700 {
    max-width: 700px;
  }

  .mn-wd-sm-700 {
    min-width: 700px;
  }

  .wd-sm-700-f {
    width: 700px !important;
  }

  .wd-sm-700p-f {
    width: 700% !important;
  }

  .mx-wd-sm-700p-f {
    max-width: 700% !important;
  }

  .mn-wd-sm-700p-f {
    min-width: 700% !important;
  }

  .mx-wd-sm-700-f {
    max-width: 700px !important;
  }

  .mn-wd-sm-700-f {
    min-width: 700px !important;
  }

  .wd-sm-750 {
    width: 750px;
  }

  .wd-sm-750p {
    width: 750%;
  }

  .mx-wd-sm-750p {
    max-width: 750%;
  }

  .mn-wd-sm-750p {
    min-width: 750%;
  }

  .mx-wd-sm-750 {
    max-width: 750px;
  }

  .mn-wd-sm-750 {
    min-width: 750px;
  }

  .wd-sm-750-f {
    width: 750px !important;
  }

  .wd-sm-750p-f {
    width: 750% !important;
  }

  .mx-wd-sm-750p-f {
    max-width: 750% !important;
  }

  .mn-wd-sm-750p-f {
    min-width: 750% !important;
  }

  .mx-wd-sm-750-f {
    max-width: 750px !important;
  }

  .mn-wd-sm-750-f {
    min-width: 750px !important;
  }

  .wd-sm-800 {
    width: 800px;
  }

  .wd-sm-800p {
    width: 800%;
  }

  .mx-wd-sm-800p {
    max-width: 800%;
  }

  .mn-wd-sm-800p {
    min-width: 800%;
  }

  .mx-wd-sm-800 {
    max-width: 800px;
  }

  .mn-wd-sm-800 {
    min-width: 800px;
  }

  .wd-sm-800-f {
    width: 800px !important;
  }

  .wd-sm-800p-f {
    width: 800% !important;
  }

  .mx-wd-sm-800p-f {
    max-width: 800% !important;
  }

  .mn-wd-sm-800p-f {
    min-width: 800% !important;
  }

  .mx-wd-sm-800-f {
    max-width: 800px !important;
  }

  .mn-wd-sm-800-f {
    min-width: 800px !important;
  }

  .wd-sm-850 {
    width: 850px;
  }

  .wd-sm-850p {
    width: 850%;
  }

  .mx-wd-sm-850p {
    max-width: 850%;
  }

  .mn-wd-sm-850p {
    min-width: 850%;
  }

  .mx-wd-sm-850 {
    max-width: 850px;
  }

  .mn-wd-sm-850 {
    min-width: 850px;
  }

  .wd-sm-850-f {
    width: 850px !important;
  }

  .wd-sm-850p-f {
    width: 850% !important;
  }

  .mx-wd-sm-850p-f {
    max-width: 850% !important;
  }

  .mn-wd-sm-850p-f {
    min-width: 850% !important;
  }

  .mx-wd-sm-850-f {
    max-width: 850px !important;
  }

  .mn-wd-sm-850-f {
    min-width: 850px !important;
  }

  .wd-sm-900 {
    width: 900px;
  }

  .wd-sm-900p {
    width: 900%;
  }

  .mx-wd-sm-900p {
    max-width: 900%;
  }

  .mn-wd-sm-900p {
    min-width: 900%;
  }

  .mx-wd-sm-900 {
    max-width: 900px;
  }

  .mn-wd-sm-900 {
    min-width: 900px;
  }

  .wd-sm-900-f {
    width: 900px !important;
  }

  .wd-sm-900p-f {
    width: 900% !important;
  }

  .mx-wd-sm-900p-f {
    max-width: 900% !important;
  }

  .mn-wd-sm-900p-f {
    min-width: 900% !important;
  }

  .mx-wd-sm-900-f {
    max-width: 900px !important;
  }

  .mn-wd-sm-900-f {
    min-width: 900px !important;
  }

  .wd-sm-950 {
    width: 950px;
  }

  .wd-sm-950p {
    width: 950%;
  }

  .mx-wd-sm-950p {
    max-width: 950%;
  }

  .mn-wd-sm-950p {
    min-width: 950%;
  }

  .mx-wd-sm-950 {
    max-width: 950px;
  }

  .mn-wd-sm-950 {
    min-width: 950px;
  }

  .wd-sm-950-f {
    width: 950px !important;
  }

  .wd-sm-950p-f {
    width: 950% !important;
  }

  .mx-wd-sm-950p-f {
    max-width: 950% !important;
  }

  .mn-wd-sm-950p-f {
    min-width: 950% !important;
  }

  .mx-wd-sm-950-f {
    max-width: 950px !important;
  }

  .mn-wd-sm-950-f {
    min-width: 950px !important;
  }

  .wd-sm-1000 {
    width: 1000px;
  }

  .wd-sm-1000p {
    width: 1000%;
  }

  .mx-wd-sm-1000p {
    max-width: 1000%;
  }

  .mn-wd-sm-1000p {
    min-width: 1000%;
  }

  .mx-wd-sm-1000 {
    max-width: 1000px;
  }

  .mn-wd-sm-1000 {
    min-width: 1000px;
  }

  .wd-sm-1000-f {
    width: 1000px !important;
  }

  .wd-sm-1000p-f {
    width: 1000% !important;
  }

  .mx-wd-sm-1000p-f {
    max-width: 1000% !important;
  }

  .mn-wd-sm-1000p-f {
    min-width: 1000% !important;
  }

  .mx-wd-sm-1000-f {
    max-width: 1000px !important;
  }

  .mn-wd-sm-1000-f {
    min-width: 1000px !important;
  }

  .wd-sm-auto {
    width: auto;
  }

  .wd-sm-auto-f {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .wd-md-5 {
    width: 5px;
  }

  .wd-md-5p {
    width: 5%;
  }

  .mx-wd-md-5p {
    max-width: 5%;
  }

  .mn-wd-md-5p {
    min-width: 5%;
  }

  .mx-wd-md-5 {
    max-width: 5px;
  }

  .mn-wd-md-5 {
    min-width: 5px;
  }

  .wd-md-5-f {
    width: 5px !important;
  }

  .wd-md-5p-f {
    width: 5% !important;
  }

  .mx-wd-md-5p-f {
    max-width: 5% !important;
  }

  .mn-wd-md-5p-f {
    min-width: 5% !important;
  }

  .mx-wd-md-5-f {
    max-width: 5px !important;
  }

  .mn-wd-md-5-f {
    min-width: 5px !important;
  }

  .wd-md-10 {
    width: 10px;
  }

  .wd-md-10p {
    width: 10%;
  }

  .mx-wd-md-10p {
    max-width: 10%;
  }

  .mn-wd-md-10p {
    min-width: 10%;
  }

  .mx-wd-md-10 {
    max-width: 10px;
  }

  .mn-wd-md-10 {
    min-width: 10px;
  }

  .wd-md-10-f {
    width: 10px !important;
  }

  .wd-md-10p-f {
    width: 10% !important;
  }

  .mx-wd-md-10p-f {
    max-width: 10% !important;
  }

  .mn-wd-md-10p-f {
    min-width: 10% !important;
  }

  .mx-wd-md-10-f {
    max-width: 10px !important;
  }

  .mn-wd-md-10-f {
    min-width: 10px !important;
  }

  .wd-md-15 {
    width: 15px;
  }

  .wd-md-15p {
    width: 15%;
  }

  .mx-wd-md-15p {
    max-width: 15%;
  }

  .mn-wd-md-15p {
    min-width: 15%;
  }

  .mx-wd-md-15 {
    max-width: 15px;
  }

  .mn-wd-md-15 {
    min-width: 15px;
  }

  .wd-md-15-f {
    width: 15px !important;
  }

  .wd-md-15p-f {
    width: 15% !important;
  }

  .mx-wd-md-15p-f {
    max-width: 15% !important;
  }

  .mn-wd-md-15p-f {
    min-width: 15% !important;
  }

  .mx-wd-md-15-f {
    max-width: 15px !important;
  }

  .mn-wd-md-15-f {
    min-width: 15px !important;
  }

  .wd-md-20 {
    width: 20px;
  }

  .wd-md-20p {
    width: 20%;
  }

  .mx-wd-md-20p {
    max-width: 20%;
  }

  .mn-wd-md-20p {
    min-width: 20%;
  }

  .mx-wd-md-20 {
    max-width: 20px;
  }

  .mn-wd-md-20 {
    min-width: 20px;
  }

  .wd-md-20-f {
    width: 20px !important;
  }

  .wd-md-20p-f {
    width: 20% !important;
  }

  .mx-wd-md-20p-f {
    max-width: 20% !important;
  }

  .mn-wd-md-20p-f {
    min-width: 20% !important;
  }

  .mx-wd-md-20-f {
    max-width: 20px !important;
  }

  .mn-wd-md-20-f {
    min-width: 20px !important;
  }

  .wd-md-25 {
    width: 25px;
  }

  .wd-md-25p {
    width: 25%;
  }

  .mx-wd-md-25p {
    max-width: 25%;
  }

  .mn-wd-md-25p {
    min-width: 25%;
  }

  .mx-wd-md-25 {
    max-width: 25px;
  }

  .mn-wd-md-25 {
    min-width: 25px;
  }

  .wd-md-25-f {
    width: 25px !important;
  }

  .wd-md-25p-f {
    width: 25% !important;
  }

  .mx-wd-md-25p-f {
    max-width: 25% !important;
  }

  .mn-wd-md-25p-f {
    min-width: 25% !important;
  }

  .mx-wd-md-25-f {
    max-width: 25px !important;
  }

  .mn-wd-md-25-f {
    min-width: 25px !important;
  }

  .wd-md-30 {
    width: 30px;
  }

  .wd-md-30p {
    width: 30%;
  }

  .mx-wd-md-30p {
    max-width: 30%;
  }

  .mn-wd-md-30p {
    min-width: 30%;
  }

  .mx-wd-md-30 {
    max-width: 30px;
  }

  .mn-wd-md-30 {
    min-width: 30px;
  }

  .wd-md-30-f {
    width: 30px !important;
  }

  .wd-md-30p-f {
    width: 30% !important;
  }

  .mx-wd-md-30p-f {
    max-width: 30% !important;
  }

  .mn-wd-md-30p-f {
    min-width: 30% !important;
  }

  .mx-wd-md-30-f {
    max-width: 30px !important;
  }

  .mn-wd-md-30-f {
    min-width: 30px !important;
  }

  .wd-md-35 {
    width: 35px;
  }

  .wd-md-35p {
    width: 35%;
  }

  .mx-wd-md-35p {
    max-width: 35%;
  }

  .mn-wd-md-35p {
    min-width: 35%;
  }

  .mx-wd-md-35 {
    max-width: 35px;
  }

  .mn-wd-md-35 {
    min-width: 35px;
  }

  .wd-md-35-f {
    width: 35px !important;
  }

  .wd-md-35p-f {
    width: 35% !important;
  }

  .mx-wd-md-35p-f {
    max-width: 35% !important;
  }

  .mn-wd-md-35p-f {
    min-width: 35% !important;
  }

  .mx-wd-md-35-f {
    max-width: 35px !important;
  }

  .mn-wd-md-35-f {
    min-width: 35px !important;
  }

  .wd-md-40 {
    width: 40px;
  }

  .wd-md-40p {
    width: 40%;
  }

  .mx-wd-md-40p {
    max-width: 40%;
  }

  .mn-wd-md-40p {
    min-width: 40%;
  }

  .mx-wd-md-40 {
    max-width: 40px;
  }

  .mn-wd-md-40 {
    min-width: 40px;
  }

  .wd-md-40-f {
    width: 40px !important;
  }

  .wd-md-40p-f {
    width: 40% !important;
  }

  .mx-wd-md-40p-f {
    max-width: 40% !important;
  }

  .mn-wd-md-40p-f {
    min-width: 40% !important;
  }

  .mx-wd-md-40-f {
    max-width: 40px !important;
  }

  .mn-wd-md-40-f {
    min-width: 40px !important;
  }

  .wd-md-45 {
    width: 45px;
  }

  .wd-md-45p {
    width: 45%;
  }

  .mx-wd-md-45p {
    max-width: 45%;
  }

  .mn-wd-md-45p {
    min-width: 45%;
  }

  .mx-wd-md-45 {
    max-width: 45px;
  }

  .mn-wd-md-45 {
    min-width: 45px;
  }

  .wd-md-45-f {
    width: 45px !important;
  }

  .wd-md-45p-f {
    width: 45% !important;
  }

  .mx-wd-md-45p-f {
    max-width: 45% !important;
  }

  .mn-wd-md-45p-f {
    min-width: 45% !important;
  }

  .mx-wd-md-45-f {
    max-width: 45px !important;
  }

  .mn-wd-md-45-f {
    min-width: 45px !important;
  }

  .wd-md-50 {
    width: 50px;
  }

  .wd-md-50p {
    width: 50%;
  }

  .mx-wd-md-50p {
    max-width: 50%;
  }

  .mn-wd-md-50p {
    min-width: 50%;
  }

  .mx-wd-md-50 {
    max-width: 50px;
  }

  .mn-wd-md-50 {
    min-width: 50px;
  }

  .wd-md-50-f {
    width: 50px !important;
  }

  .wd-md-50p-f {
    width: 50% !important;
  }

  .mx-wd-md-50p-f {
    max-width: 50% !important;
  }

  .mn-wd-md-50p-f {
    min-width: 50% !important;
  }

  .mx-wd-md-50-f {
    max-width: 50px !important;
  }

  .mn-wd-md-50-f {
    min-width: 50px !important;
  }

  .wd-md-55 {
    width: 55px;
  }

  .wd-md-55p {
    width: 55%;
  }

  .mx-wd-md-55p {
    max-width: 55%;
  }

  .mn-wd-md-55p {
    min-width: 55%;
  }

  .mx-wd-md-55 {
    max-width: 55px;
  }

  .mn-wd-md-55 {
    min-width: 55px;
  }

  .wd-md-55-f {
    width: 55px !important;
  }

  .wd-md-55p-f {
    width: 55% !important;
  }

  .mx-wd-md-55p-f {
    max-width: 55% !important;
  }

  .mn-wd-md-55p-f {
    min-width: 55% !important;
  }

  .mx-wd-md-55-f {
    max-width: 55px !important;
  }

  .mn-wd-md-55-f {
    min-width: 55px !important;
  }

  .wd-md-60 {
    width: 60px;
  }

  .wd-md-60p {
    width: 60%;
  }

  .mx-wd-md-60p {
    max-width: 60%;
  }

  .mn-wd-md-60p {
    min-width: 60%;
  }

  .mx-wd-md-60 {
    max-width: 60px;
  }

  .mn-wd-md-60 {
    min-width: 60px;
  }

  .wd-md-60-f {
    width: 60px !important;
  }

  .wd-md-60p-f {
    width: 60% !important;
  }

  .mx-wd-md-60p-f {
    max-width: 60% !important;
  }

  .mn-wd-md-60p-f {
    min-width: 60% !important;
  }

  .mx-wd-md-60-f {
    max-width: 60px !important;
  }

  .mn-wd-md-60-f {
    min-width: 60px !important;
  }

  .wd-md-65 {
    width: 65px;
  }

  .wd-md-65p {
    width: 65%;
  }

  .mx-wd-md-65p {
    max-width: 65%;
  }

  .mn-wd-md-65p {
    min-width: 65%;
  }

  .mx-wd-md-65 {
    max-width: 65px;
  }

  .mn-wd-md-65 {
    min-width: 65px;
  }

  .wd-md-65-f {
    width: 65px !important;
  }

  .wd-md-65p-f {
    width: 65% !important;
  }

  .mx-wd-md-65p-f {
    max-width: 65% !important;
  }

  .mn-wd-md-65p-f {
    min-width: 65% !important;
  }

  .mx-wd-md-65-f {
    max-width: 65px !important;
  }

  .mn-wd-md-65-f {
    min-width: 65px !important;
  }

  .wd-md-70 {
    width: 70px;
  }

  .wd-md-70p {
    width: 70%;
  }

  .mx-wd-md-70p {
    max-width: 70%;
  }

  .mn-wd-md-70p {
    min-width: 70%;
  }

  .mx-wd-md-70 {
    max-width: 70px;
  }

  .mn-wd-md-70 {
    min-width: 70px;
  }

  .wd-md-70-f {
    width: 70px !important;
  }

  .wd-md-70p-f {
    width: 70% !important;
  }

  .mx-wd-md-70p-f {
    max-width: 70% !important;
  }

  .mn-wd-md-70p-f {
    min-width: 70% !important;
  }

  .mx-wd-md-70-f {
    max-width: 70px !important;
  }

  .mn-wd-md-70-f {
    min-width: 70px !important;
  }

  .wd-md-75 {
    width: 75px;
  }

  .wd-md-75p {
    width: 75%;
  }

  .mx-wd-md-75p {
    max-width: 75%;
  }

  .mn-wd-md-75p {
    min-width: 75%;
  }

  .mx-wd-md-75 {
    max-width: 75px;
  }

  .mn-wd-md-75 {
    min-width: 75px;
  }

  .wd-md-75-f {
    width: 75px !important;
  }

  .wd-md-75p-f {
    width: 75% !important;
  }

  .mx-wd-md-75p-f {
    max-width: 75% !important;
  }

  .mn-wd-md-75p-f {
    min-width: 75% !important;
  }

  .mx-wd-md-75-f {
    max-width: 75px !important;
  }

  .mn-wd-md-75-f {
    min-width: 75px !important;
  }

  .wd-md-80 {
    width: 80px;
  }

  .wd-md-80p {
    width: 80%;
  }

  .mx-wd-md-80p {
    max-width: 80%;
  }

  .mn-wd-md-80p {
    min-width: 80%;
  }

  .mx-wd-md-80 {
    max-width: 80px;
  }

  .mn-wd-md-80 {
    min-width: 80px;
  }

  .wd-md-80-f {
    width: 80px !important;
  }

  .wd-md-80p-f {
    width: 80% !important;
  }

  .mx-wd-md-80p-f {
    max-width: 80% !important;
  }

  .mn-wd-md-80p-f {
    min-width: 80% !important;
  }

  .mx-wd-md-80-f {
    max-width: 80px !important;
  }

  .mn-wd-md-80-f {
    min-width: 80px !important;
  }

  .wd-md-85 {
    width: 85px;
  }

  .wd-md-85p {
    width: 85%;
  }

  .mx-wd-md-85p {
    max-width: 85%;
  }

  .mn-wd-md-85p {
    min-width: 85%;
  }

  .mx-wd-md-85 {
    max-width: 85px;
  }

  .mn-wd-md-85 {
    min-width: 85px;
  }

  .wd-md-85-f {
    width: 85px !important;
  }

  .wd-md-85p-f {
    width: 85% !important;
  }

  .mx-wd-md-85p-f {
    max-width: 85% !important;
  }

  .mn-wd-md-85p-f {
    min-width: 85% !important;
  }

  .mx-wd-md-85-f {
    max-width: 85px !important;
  }

  .mn-wd-md-85-f {
    min-width: 85px !important;
  }

  .wd-md-90 {
    width: 90px;
  }

  .wd-md-90p {
    width: 90%;
  }

  .mx-wd-md-90p {
    max-width: 90%;
  }

  .mn-wd-md-90p {
    min-width: 90%;
  }

  .mx-wd-md-90 {
    max-width: 90px;
  }

  .mn-wd-md-90 {
    min-width: 90px;
  }

  .wd-md-90-f {
    width: 90px !important;
  }

  .wd-md-90p-f {
    width: 90% !important;
  }

  .mx-wd-md-90p-f {
    max-width: 90% !important;
  }

  .mn-wd-md-90p-f {
    min-width: 90% !important;
  }

  .mx-wd-md-90-f {
    max-width: 90px !important;
  }

  .mn-wd-md-90-f {
    min-width: 90px !important;
  }

  .wd-md-95 {
    width: 95px;
  }

  .wd-md-95p {
    width: 95%;
  }

  .mx-wd-md-95p {
    max-width: 95%;
  }

  .mn-wd-md-95p {
    min-width: 95%;
  }

  .mx-wd-md-95 {
    max-width: 95px;
  }

  .mn-wd-md-95 {
    min-width: 95px;
  }

  .wd-md-95-f {
    width: 95px !important;
  }

  .wd-md-95p-f {
    width: 95% !important;
  }

  .mx-wd-md-95p-f {
    max-width: 95% !important;
  }

  .mn-wd-md-95p-f {
    min-width: 95% !important;
  }

  .mx-wd-md-95-f {
    max-width: 95px !important;
  }

  .mn-wd-md-95-f {
    min-width: 95px !important;
  }

  .wd-md-100 {
    width: 100px;
  }

  .wd-md-100p {
    width: 100%;
  }

  .mx-wd-md-100p {
    max-width: 100%;
  }

  .mn-wd-md-100p {
    min-width: 100%;
  }

  .mx-wd-md-100 {
    max-width: 100px;
  }

  .mn-wd-md-100 {
    min-width: 100px;
  }

  .wd-md-100-f {
    width: 100px !important;
  }

  .wd-md-100p-f {
    width: 100% !important;
  }

  .mx-wd-md-100p-f {
    max-width: 100% !important;
  }

  .mn-wd-md-100p-f {
    min-width: 100% !important;
  }

  .mx-wd-md-100-f {
    max-width: 100px !important;
  }

  .mn-wd-md-100-f {
    min-width: 100px !important;
  }

  .wd-md-150 {
    width: 150px;
  }

  .wd-md-150p {
    width: 150%;
  }

  .mx-wd-md-150p {
    max-width: 150%;
  }

  .mn-wd-md-150p {
    min-width: 150%;
  }

  .mx-wd-md-150 {
    max-width: 150px;
  }

  .mn-wd-md-150 {
    min-width: 150px;
  }

  .wd-md-150-f {
    width: 150px !important;
  }

  .wd-md-150p-f {
    width: 150% !important;
  }

  .mx-wd-md-150p-f {
    max-width: 150% !important;
  }

  .mn-wd-md-150p-f {
    min-width: 150% !important;
  }

  .mx-wd-md-150-f {
    max-width: 150px !important;
  }

  .mn-wd-md-150-f {
    min-width: 150px !important;
  }

  .wd-md-200 {
    width: 200px;
  }

  .wd-md-200p {
    width: 200%;
  }

  .mx-wd-md-200p {
    max-width: 200%;
  }

  .mn-wd-md-200p {
    min-width: 200%;
  }

  .mx-wd-md-200 {
    max-width: 200px;
  }

  .mn-wd-md-200 {
    min-width: 200px;
  }

  .wd-md-200-f {
    width: 200px !important;
  }

  .wd-md-200p-f {
    width: 200% !important;
  }

  .mx-wd-md-200p-f {
    max-width: 200% !important;
  }

  .mn-wd-md-200p-f {
    min-width: 200% !important;
  }

  .mx-wd-md-200-f {
    max-width: 200px !important;
  }

  .mn-wd-md-200-f {
    min-width: 200px !important;
  }

  .wd-md-250 {
    width: 250px;
  }

  .wd-md-250p {
    width: 250%;
  }

  .mx-wd-md-250p {
    max-width: 250%;
  }

  .mn-wd-md-250p {
    min-width: 250%;
  }

  .mx-wd-md-250 {
    max-width: 250px;
  }

  .mn-wd-md-250 {
    min-width: 250px;
  }

  .wd-md-250-f {
    width: 250px !important;
  }

  .wd-md-250p-f {
    width: 250% !important;
  }

  .mx-wd-md-250p-f {
    max-width: 250% !important;
  }

  .mn-wd-md-250p-f {
    min-width: 250% !important;
  }

  .mx-wd-md-250-f {
    max-width: 250px !important;
  }

  .mn-wd-md-250-f {
    min-width: 250px !important;
  }

  .wd-md-300 {
    width: 300px;
  }

  .wd-md-300p {
    width: 300%;
  }

  .mx-wd-md-300p {
    max-width: 300%;
  }

  .mn-wd-md-300p {
    min-width: 300%;
  }

  .mx-wd-md-300 {
    max-width: 300px;
  }

  .mn-wd-md-300 {
    min-width: 300px;
  }

  .wd-md-300-f {
    width: 300px !important;
  }

  .wd-md-300p-f {
    width: 300% !important;
  }

  .mx-wd-md-300p-f {
    max-width: 300% !important;
  }

  .mn-wd-md-300p-f {
    min-width: 300% !important;
  }

  .mx-wd-md-300-f {
    max-width: 300px !important;
  }

  .mn-wd-md-300-f {
    min-width: 300px !important;
  }

  .wd-md-350 {
    width: 350px;
  }

  .wd-md-350p {
    width: 350%;
  }

  .mx-wd-md-350p {
    max-width: 350%;
  }

  .mn-wd-md-350p {
    min-width: 350%;
  }

  .mx-wd-md-350 {
    max-width: 350px;
  }

  .mn-wd-md-350 {
    min-width: 350px;
  }

  .wd-md-350-f {
    width: 350px !important;
  }

  .wd-md-350p-f {
    width: 350% !important;
  }

  .mx-wd-md-350p-f {
    max-width: 350% !important;
  }

  .mn-wd-md-350p-f {
    min-width: 350% !important;
  }

  .mx-wd-md-350-f {
    max-width: 350px !important;
  }

  .mn-wd-md-350-f {
    min-width: 350px !important;
  }

  .wd-md-400 {
    width: 400px;
  }

  .wd-md-400p {
    width: 400%;
  }

  .mx-wd-md-400p {
    max-width: 400%;
  }

  .mn-wd-md-400p {
    min-width: 400%;
  }

  .mx-wd-md-400 {
    max-width: 400px;
  }

  .mn-wd-md-400 {
    min-width: 400px;
  }

  .wd-md-400-f {
    width: 400px !important;
  }

  .wd-md-400p-f {
    width: 400% !important;
  }

  .mx-wd-md-400p-f {
    max-width: 400% !important;
  }

  .mn-wd-md-400p-f {
    min-width: 400% !important;
  }

  .mx-wd-md-400-f {
    max-width: 400px !important;
  }

  .mn-wd-md-400-f {
    min-width: 400px !important;
  }

  .wd-md-450 {
    width: 450px;
  }

  .wd-md-450p {
    width: 450%;
  }

  .mx-wd-md-450p {
    max-width: 450%;
  }

  .mn-wd-md-450p {
    min-width: 450%;
  }

  .mx-wd-md-450 {
    max-width: 450px;
  }

  .mn-wd-md-450 {
    min-width: 450px;
  }

  .wd-md-450-f {
    width: 450px !important;
  }

  .wd-md-450p-f {
    width: 450% !important;
  }

  .mx-wd-md-450p-f {
    max-width: 450% !important;
  }

  .mn-wd-md-450p-f {
    min-width: 450% !important;
  }

  .mx-wd-md-450-f {
    max-width: 450px !important;
  }

  .mn-wd-md-450-f {
    min-width: 450px !important;
  }

  .wd-md-500 {
    width: 500px;
  }

  .wd-md-500p {
    width: 500%;
  }

  .mx-wd-md-500p {
    max-width: 500%;
  }

  .mn-wd-md-500p {
    min-width: 500%;
  }

  .mx-wd-md-500 {
    max-width: 500px;
  }

  .mn-wd-md-500 {
    min-width: 500px;
  }

  .wd-md-500-f {
    width: 500px !important;
  }

  .wd-md-500p-f {
    width: 500% !important;
  }

  .mx-wd-md-500p-f {
    max-width: 500% !important;
  }

  .mn-wd-md-500p-f {
    min-width: 500% !important;
  }

  .mx-wd-md-500-f {
    max-width: 500px !important;
  }

  .mn-wd-md-500-f {
    min-width: 500px !important;
  }

  .wd-md-550 {
    width: 550px;
  }

  .wd-md-550p {
    width: 550%;
  }

  .mx-wd-md-550p {
    max-width: 550%;
  }

  .mn-wd-md-550p {
    min-width: 550%;
  }

  .mx-wd-md-550 {
    max-width: 550px;
  }

  .mn-wd-md-550 {
    min-width: 550px;
  }

  .wd-md-550-f {
    width: 550px !important;
  }

  .wd-md-550p-f {
    width: 550% !important;
  }

  .mx-wd-md-550p-f {
    max-width: 550% !important;
  }

  .mn-wd-md-550p-f {
    min-width: 550% !important;
  }

  .mx-wd-md-550-f {
    max-width: 550px !important;
  }

  .mn-wd-md-550-f {
    min-width: 550px !important;
  }

  .wd-md-600 {
    width: 600px;
  }

  .wd-md-600p {
    width: 600%;
  }

  .mx-wd-md-600p {
    max-width: 600%;
  }

  .mn-wd-md-600p {
    min-width: 600%;
  }

  .mx-wd-md-600 {
    max-width: 600px;
  }

  .mn-wd-md-600 {
    min-width: 600px;
  }

  .wd-md-600-f {
    width: 600px !important;
  }

  .wd-md-600p-f {
    width: 600% !important;
  }

  .mx-wd-md-600p-f {
    max-width: 600% !important;
  }

  .mn-wd-md-600p-f {
    min-width: 600% !important;
  }

  .mx-wd-md-600-f {
    max-width: 600px !important;
  }

  .mn-wd-md-600-f {
    min-width: 600px !important;
  }

  .wd-md-650 {
    width: 650px;
  }

  .wd-md-650p {
    width: 650%;
  }

  .mx-wd-md-650p {
    max-width: 650%;
  }

  .mn-wd-md-650p {
    min-width: 650%;
  }

  .mx-wd-md-650 {
    max-width: 650px;
  }

  .mn-wd-md-650 {
    min-width: 650px;
  }

  .wd-md-650-f {
    width: 650px !important;
  }

  .wd-md-650p-f {
    width: 650% !important;
  }

  .mx-wd-md-650p-f {
    max-width: 650% !important;
  }

  .mn-wd-md-650p-f {
    min-width: 650% !important;
  }

  .mx-wd-md-650-f {
    max-width: 650px !important;
  }

  .mn-wd-md-650-f {
    min-width: 650px !important;
  }

  .wd-md-700 {
    width: 700px;
  }

  .wd-md-700p {
    width: 700%;
  }

  .mx-wd-md-700p {
    max-width: 700%;
  }

  .mn-wd-md-700p {
    min-width: 700%;
  }

  .mx-wd-md-700 {
    max-width: 700px;
  }

  .mn-wd-md-700 {
    min-width: 700px;
  }

  .wd-md-700-f {
    width: 700px !important;
  }

  .wd-md-700p-f {
    width: 700% !important;
  }

  .mx-wd-md-700p-f {
    max-width: 700% !important;
  }

  .mn-wd-md-700p-f {
    min-width: 700% !important;
  }

  .mx-wd-md-700-f {
    max-width: 700px !important;
  }

  .mn-wd-md-700-f {
    min-width: 700px !important;
  }

  .wd-md-750 {
    width: 750px;
  }

  .wd-md-750p {
    width: 750%;
  }

  .mx-wd-md-750p {
    max-width: 750%;
  }

  .mn-wd-md-750p {
    min-width: 750%;
  }

  .mx-wd-md-750 {
    max-width: 750px;
  }

  .mn-wd-md-750 {
    min-width: 750px;
  }

  .wd-md-750-f {
    width: 750px !important;
  }

  .wd-md-750p-f {
    width: 750% !important;
  }

  .mx-wd-md-750p-f {
    max-width: 750% !important;
  }

  .mn-wd-md-750p-f {
    min-width: 750% !important;
  }

  .mx-wd-md-750-f {
    max-width: 750px !important;
  }

  .mn-wd-md-750-f {
    min-width: 750px !important;
  }

  .wd-md-800 {
    width: 800px;
  }

  .wd-md-800p {
    width: 800%;
  }

  .mx-wd-md-800p {
    max-width: 800%;
  }

  .mn-wd-md-800p {
    min-width: 800%;
  }

  .mx-wd-md-800 {
    max-width: 800px;
  }

  .mn-wd-md-800 {
    min-width: 800px;
  }

  .wd-md-800-f {
    width: 800px !important;
  }

  .wd-md-800p-f {
    width: 800% !important;
  }

  .mx-wd-md-800p-f {
    max-width: 800% !important;
  }

  .mn-wd-md-800p-f {
    min-width: 800% !important;
  }

  .mx-wd-md-800-f {
    max-width: 800px !important;
  }

  .mn-wd-md-800-f {
    min-width: 800px !important;
  }

  .wd-md-850 {
    width: 850px;
  }

  .wd-md-850p {
    width: 850%;
  }

  .mx-wd-md-850p {
    max-width: 850%;
  }

  .mn-wd-md-850p {
    min-width: 850%;
  }

  .mx-wd-md-850 {
    max-width: 850px;
  }

  .mn-wd-md-850 {
    min-width: 850px;
  }

  .wd-md-850-f {
    width: 850px !important;
  }

  .wd-md-850p-f {
    width: 850% !important;
  }

  .mx-wd-md-850p-f {
    max-width: 850% !important;
  }

  .mn-wd-md-850p-f {
    min-width: 850% !important;
  }

  .mx-wd-md-850-f {
    max-width: 850px !important;
  }

  .mn-wd-md-850-f {
    min-width: 850px !important;
  }

  .wd-md-900 {
    width: 900px;
  }

  .wd-md-900p {
    width: 900%;
  }

  .mx-wd-md-900p {
    max-width: 900%;
  }

  .mn-wd-md-900p {
    min-width: 900%;
  }

  .mx-wd-md-900 {
    max-width: 900px;
  }

  .mn-wd-md-900 {
    min-width: 900px;
  }

  .wd-md-900-f {
    width: 900px !important;
  }

  .wd-md-900p-f {
    width: 900% !important;
  }

  .mx-wd-md-900p-f {
    max-width: 900% !important;
  }

  .mn-wd-md-900p-f {
    min-width: 900% !important;
  }

  .mx-wd-md-900-f {
    max-width: 900px !important;
  }

  .mn-wd-md-900-f {
    min-width: 900px !important;
  }

  .wd-md-950 {
    width: 950px;
  }

  .wd-md-950p {
    width: 950%;
  }

  .mx-wd-md-950p {
    max-width: 950%;
  }

  .mn-wd-md-950p {
    min-width: 950%;
  }

  .mx-wd-md-950 {
    max-width: 950px;
  }

  .mn-wd-md-950 {
    min-width: 950px;
  }

  .wd-md-950-f {
    width: 950px !important;
  }

  .wd-md-950p-f {
    width: 950% !important;
  }

  .mx-wd-md-950p-f {
    max-width: 950% !important;
  }

  .mn-wd-md-950p-f {
    min-width: 950% !important;
  }

  .mx-wd-md-950-f {
    max-width: 950px !important;
  }

  .mn-wd-md-950-f {
    min-width: 950px !important;
  }

  .wd-md-1000 {
    width: 1000px;
  }

  .wd-md-1000p {
    width: 1000%;
  }

  .mx-wd-md-1000p {
    max-width: 1000%;
  }

  .mn-wd-md-1000p {
    min-width: 1000%;
  }

  .mx-wd-md-1000 {
    max-width: 1000px;
  }

  .mn-wd-md-1000 {
    min-width: 1000px;
  }

  .wd-md-1000-f {
    width: 1000px !important;
  }

  .wd-md-1000p-f {
    width: 1000% !important;
  }

  .mx-wd-md-1000p-f {
    max-width: 1000% !important;
  }

  .mn-wd-md-1000p-f {
    min-width: 1000% !important;
  }

  .mx-wd-md-1000-f {
    max-width: 1000px !important;
  }

  .mn-wd-md-1000-f {
    min-width: 1000px !important;
  }

  .wd-md-auto {
    width: auto;
  }

  .wd-md-auto-f {
    width: auto !important;
  }

  .wd-md-120 {
    width: 120px;
  }
}
@media (min-width: 992px) {
  .wd-lg-5 {
    width: 5px;
  }

  .wd-lg-5p {
    width: 5%;
  }

  .mx-wd-lg-5p {
    max-width: 5%;
  }

  .mn-wd-lg-5p {
    min-width: 5%;
  }

  .mx-wd-lg-5 {
    max-width: 5px;
  }

  .mn-wd-lg-5 {
    min-width: 5px;
  }

  .wd-lg-5-f {
    width: 5px !important;
  }

  .wd-lg-5p-f {
    width: 5% !important;
  }

  .mx-wd-lg-5p-f {
    max-width: 5% !important;
  }

  .mn-wd-lg-5p-f {
    min-width: 5% !important;
  }

  .mx-wd-lg-5-f {
    max-width: 5px !important;
  }

  .mn-wd-lg-5-f {
    min-width: 5px !important;
  }

  .wd-lg-10 {
    width: 10px;
  }

  .wd-lg-10p {
    width: 10%;
  }

  .mx-wd-lg-10p {
    max-width: 10%;
  }

  .mn-wd-lg-10p {
    min-width: 10%;
  }

  .mx-wd-lg-10 {
    max-width: 10px;
  }

  .mn-wd-lg-10 {
    min-width: 10px;
  }

  .wd-lg-10-f {
    width: 10px !important;
  }

  .wd-lg-10p-f {
    width: 10% !important;
  }

  .mx-wd-lg-10p-f {
    max-width: 10% !important;
  }

  .mn-wd-lg-10p-f {
    min-width: 10% !important;
  }

  .mx-wd-lg-10-f {
    max-width: 10px !important;
  }

  .mn-wd-lg-10-f {
    min-width: 10px !important;
  }

  .wd-lg-15 {
    width: 15px;
  }

  .wd-lg-15p {
    width: 15%;
  }

  .mx-wd-lg-15p {
    max-width: 15%;
  }

  .mn-wd-lg-15p {
    min-width: 15%;
  }

  .mx-wd-lg-15 {
    max-width: 15px;
  }

  .mn-wd-lg-15 {
    min-width: 15px;
  }

  .wd-lg-15-f {
    width: 15px !important;
  }

  .wd-lg-15p-f {
    width: 15% !important;
  }

  .mx-wd-lg-15p-f {
    max-width: 15% !important;
  }

  .mn-wd-lg-15p-f {
    min-width: 15% !important;
  }

  .mx-wd-lg-15-f {
    max-width: 15px !important;
  }

  .mn-wd-lg-15-f {
    min-width: 15px !important;
  }

  .wd-lg-20 {
    width: 20px;
  }

  .wd-lg-20p {
    width: 20%;
  }

  .mx-wd-lg-20p {
    max-width: 20%;
  }

  .mn-wd-lg-20p {
    min-width: 20%;
  }

  .mx-wd-lg-20 {
    max-width: 20px;
  }

  .mn-wd-lg-20 {
    min-width: 20px;
  }

  .wd-lg-20-f {
    width: 20px !important;
  }

  .wd-lg-20p-f {
    width: 20% !important;
  }

  .mx-wd-lg-20p-f {
    max-width: 20% !important;
  }

  .mn-wd-lg-20p-f {
    min-width: 20% !important;
  }

  .mx-wd-lg-20-f {
    max-width: 20px !important;
  }

  .mn-wd-lg-20-f {
    min-width: 20px !important;
  }

  .wd-lg-25 {
    width: 25px;
  }

  .wd-lg-25p {
    width: 25%;
  }

  .mx-wd-lg-25p {
    max-width: 25%;
  }

  .mn-wd-lg-25p {
    min-width: 25%;
  }

  .mx-wd-lg-25 {
    max-width: 25px;
  }

  .mn-wd-lg-25 {
    min-width: 25px;
  }

  .wd-lg-25-f {
    width: 25px !important;
  }

  .wd-lg-25p-f {
    width: 25% !important;
  }

  .mx-wd-lg-25p-f {
    max-width: 25% !important;
  }

  .mn-wd-lg-25p-f {
    min-width: 25% !important;
  }

  .mx-wd-lg-25-f {
    max-width: 25px !important;
  }

  .mn-wd-lg-25-f {
    min-width: 25px !important;
  }

  .wd-lg-30 {
    width: 30px;
  }

  .wd-lg-30p {
    width: 30%;
  }

  .mx-wd-lg-30p {
    max-width: 30%;
  }

  .mn-wd-lg-30p {
    min-width: 30%;
  }

  .mx-wd-lg-30 {
    max-width: 30px;
  }

  .mn-wd-lg-30 {
    min-width: 30px;
  }

  .wd-lg-30-f {
    width: 30px !important;
  }

  .wd-lg-30p-f {
    width: 30% !important;
  }

  .mx-wd-lg-30p-f {
    max-width: 30% !important;
  }

  .mn-wd-lg-30p-f {
    min-width: 30% !important;
  }

  .mx-wd-lg-30-f {
    max-width: 30px !important;
  }

  .mn-wd-lg-30-f {
    min-width: 30px !important;
  }

  .wd-lg-35 {
    width: 35px;
  }

  .wd-lg-35p {
    width: 35%;
  }

  .mx-wd-lg-35p {
    max-width: 35%;
  }

  .mn-wd-lg-35p {
    min-width: 35%;
  }

  .mx-wd-lg-35 {
    max-width: 35px;
  }

  .mn-wd-lg-35 {
    min-width: 35px;
  }

  .wd-lg-35-f {
    width: 35px !important;
  }

  .wd-lg-35p-f {
    width: 35% !important;
  }

  .mx-wd-lg-35p-f {
    max-width: 35% !important;
  }

  .mn-wd-lg-35p-f {
    min-width: 35% !important;
  }

  .mx-wd-lg-35-f {
    max-width: 35px !important;
  }

  .mn-wd-lg-35-f {
    min-width: 35px !important;
  }

  .wd-lg-40 {
    width: 40px;
  }

  .wd-lg-40p {
    width: 40%;
  }

  .mx-wd-lg-40p {
    max-width: 40%;
  }

  .mn-wd-lg-40p {
    min-width: 40%;
  }

  .mx-wd-lg-40 {
    max-width: 40px;
  }

  .mn-wd-lg-40 {
    min-width: 40px;
  }

  .wd-lg-40-f {
    width: 40px !important;
  }

  .wd-lg-40p-f {
    width: 40% !important;
  }

  .mx-wd-lg-40p-f {
    max-width: 40% !important;
  }

  .mn-wd-lg-40p-f {
    min-width: 40% !important;
  }

  .mx-wd-lg-40-f {
    max-width: 40px !important;
  }

  .mn-wd-lg-40-f {
    min-width: 40px !important;
  }

  .wd-lg-45 {
    width: 45px;
  }

  .wd-lg-45p {
    width: 45%;
  }

  .mx-wd-lg-45p {
    max-width: 45%;
  }

  .mn-wd-lg-45p {
    min-width: 45%;
  }

  .mx-wd-lg-45 {
    max-width: 45px;
  }

  .mn-wd-lg-45 {
    min-width: 45px;
  }

  .wd-lg-45-f {
    width: 45px !important;
  }

  .wd-lg-45p-f {
    width: 45% !important;
  }

  .mx-wd-lg-45p-f {
    max-width: 45% !important;
  }

  .mn-wd-lg-45p-f {
    min-width: 45% !important;
  }

  .mx-wd-lg-45-f {
    max-width: 45px !important;
  }

  .mn-wd-lg-45-f {
    min-width: 45px !important;
  }

  .wd-lg-50 {
    width: 50px;
  }

  .wd-lg-50p {
    width: 50%;
  }

  .mx-wd-lg-50p {
    max-width: 50%;
  }

  .mn-wd-lg-50p {
    min-width: 50%;
  }

  .mx-wd-lg-50 {
    max-width: 50px;
  }

  .mn-wd-lg-50 {
    min-width: 50px;
  }

  .wd-lg-50-f {
    width: 50px !important;
  }

  .wd-lg-50p-f {
    width: 50% !important;
  }

  .mx-wd-lg-50p-f {
    max-width: 50% !important;
  }

  .mn-wd-lg-50p-f {
    min-width: 50% !important;
  }

  .mx-wd-lg-50-f {
    max-width: 50px !important;
  }

  .mn-wd-lg-50-f {
    min-width: 50px !important;
  }

  .wd-lg-55 {
    width: 55px;
  }

  .wd-lg-55p {
    width: 55%;
  }

  .mx-wd-lg-55p {
    max-width: 55%;
  }

  .mn-wd-lg-55p {
    min-width: 55%;
  }

  .mx-wd-lg-55 {
    max-width: 55px;
  }

  .mn-wd-lg-55 {
    min-width: 55px;
  }

  .wd-lg-55-f {
    width: 55px !important;
  }

  .wd-lg-55p-f {
    width: 55% !important;
  }

  .mx-wd-lg-55p-f {
    max-width: 55% !important;
  }

  .mn-wd-lg-55p-f {
    min-width: 55% !important;
  }

  .mx-wd-lg-55-f {
    max-width: 55px !important;
  }

  .mn-wd-lg-55-f {
    min-width: 55px !important;
  }

  .wd-lg-60 {
    width: 60px;
  }

  .wd-lg-60p {
    width: 60%;
  }

  .mx-wd-lg-60p {
    max-width: 60%;
  }

  .mn-wd-lg-60p {
    min-width: 60%;
  }

  .mx-wd-lg-60 {
    max-width: 60px;
  }

  .mn-wd-lg-60 {
    min-width: 60px;
  }

  .wd-lg-60-f {
    width: 60px !important;
  }

  .wd-lg-60p-f {
    width: 60% !important;
  }

  .mx-wd-lg-60p-f {
    max-width: 60% !important;
  }

  .mn-wd-lg-60p-f {
    min-width: 60% !important;
  }

  .mx-wd-lg-60-f {
    max-width: 60px !important;
  }

  .mn-wd-lg-60-f {
    min-width: 60px !important;
  }

  .wd-lg-65 {
    width: 65px;
  }

  .wd-lg-65p {
    width: 65%;
  }

  .mx-wd-lg-65p {
    max-width: 65%;
  }

  .mn-wd-lg-65p {
    min-width: 65%;
  }

  .mx-wd-lg-65 {
    max-width: 65px;
  }

  .mn-wd-lg-65 {
    min-width: 65px;
  }

  .wd-lg-65-f {
    width: 65px !important;
  }

  .wd-lg-65p-f {
    width: 65% !important;
  }

  .mx-wd-lg-65p-f {
    max-width: 65% !important;
  }

  .mn-wd-lg-65p-f {
    min-width: 65% !important;
  }

  .mx-wd-lg-65-f {
    max-width: 65px !important;
  }

  .mn-wd-lg-65-f {
    min-width: 65px !important;
  }

  .wd-lg-70 {
    width: 70px;
  }

  .wd-lg-70p {
    width: 70%;
  }

  .mx-wd-lg-70p {
    max-width: 70%;
  }

  .mn-wd-lg-70p {
    min-width: 70%;
  }

  .mx-wd-lg-70 {
    max-width: 70px;
  }

  .mn-wd-lg-70 {
    min-width: 70px;
  }

  .wd-lg-70-f {
    width: 70px !important;
  }

  .wd-lg-70p-f {
    width: 70% !important;
  }

  .mx-wd-lg-70p-f {
    max-width: 70% !important;
  }

  .mn-wd-lg-70p-f {
    min-width: 70% !important;
  }

  .mx-wd-lg-70-f {
    max-width: 70px !important;
  }

  .mn-wd-lg-70-f {
    min-width: 70px !important;
  }

  .wd-lg-75 {
    width: 75px;
  }

  .wd-lg-75p {
    width: 75%;
  }

  .mx-wd-lg-75p {
    max-width: 75%;
  }

  .mn-wd-lg-75p {
    min-width: 75%;
  }

  .mx-wd-lg-75 {
    max-width: 75px;
  }

  .mn-wd-lg-75 {
    min-width: 75px;
  }

  .wd-lg-75-f {
    width: 75px !important;
  }

  .wd-lg-75p-f {
    width: 75% !important;
  }

  .mx-wd-lg-75p-f {
    max-width: 75% !important;
  }

  .mn-wd-lg-75p-f {
    min-width: 75% !important;
  }

  .mx-wd-lg-75-f {
    max-width: 75px !important;
  }

  .mn-wd-lg-75-f {
    min-width: 75px !important;
  }

  .wd-lg-80 {
    width: 80px;
  }

  .wd-lg-80p {
    width: 80%;
  }

  .mx-wd-lg-80p {
    max-width: 80%;
  }

  .mn-wd-lg-80p {
    min-width: 80%;
  }

  .mx-wd-lg-80 {
    max-width: 80px;
  }

  .mn-wd-lg-80 {
    min-width: 80px;
  }

  .wd-lg-80-f {
    width: 80px !important;
  }

  .wd-lg-80p-f {
    width: 80% !important;
  }

  .mx-wd-lg-80p-f {
    max-width: 80% !important;
  }

  .mn-wd-lg-80p-f {
    min-width: 80% !important;
  }

  .mx-wd-lg-80-f {
    max-width: 80px !important;
  }

  .mn-wd-lg-80-f {
    min-width: 80px !important;
  }

  .wd-lg-85 {
    width: 85px;
  }

  .wd-lg-85p {
    width: 85%;
  }

  .mx-wd-lg-85p {
    max-width: 85%;
  }

  .mn-wd-lg-85p {
    min-width: 85%;
  }

  .mx-wd-lg-85 {
    max-width: 85px;
  }

  .mn-wd-lg-85 {
    min-width: 85px;
  }

  .wd-lg-85-f {
    width: 85px !important;
  }

  .wd-lg-85p-f {
    width: 85% !important;
  }

  .mx-wd-lg-85p-f {
    max-width: 85% !important;
  }

  .mn-wd-lg-85p-f {
    min-width: 85% !important;
  }

  .mx-wd-lg-85-f {
    max-width: 85px !important;
  }

  .mn-wd-lg-85-f {
    min-width: 85px !important;
  }

  .wd-lg-90 {
    width: 90px;
  }

  .wd-lg-90p {
    width: 90%;
  }

  .mx-wd-lg-90p {
    max-width: 90%;
  }

  .mn-wd-lg-90p {
    min-width: 90%;
  }

  .mx-wd-lg-90 {
    max-width: 90px;
  }

  .mn-wd-lg-90 {
    min-width: 90px;
  }

  .wd-lg-90-f {
    width: 90px !important;
  }

  .wd-lg-90p-f {
    width: 90% !important;
  }

  .mx-wd-lg-90p-f {
    max-width: 90% !important;
  }

  .mn-wd-lg-90p-f {
    min-width: 90% !important;
  }

  .mx-wd-lg-90-f {
    max-width: 90px !important;
  }

  .mn-wd-lg-90-f {
    min-width: 90px !important;
  }

  .wd-lg-95 {
    width: 95px;
  }

  .wd-lg-95p {
    width: 95%;
  }

  .mx-wd-lg-95p {
    max-width: 95%;
  }

  .mn-wd-lg-95p {
    min-width: 95%;
  }

  .mx-wd-lg-95 {
    max-width: 95px;
  }

  .mn-wd-lg-95 {
    min-width: 95px;
  }

  .wd-lg-95-f {
    width: 95px !important;
  }

  .wd-lg-95p-f {
    width: 95% !important;
  }

  .mx-wd-lg-95p-f {
    max-width: 95% !important;
  }

  .mn-wd-lg-95p-f {
    min-width: 95% !important;
  }

  .mx-wd-lg-95-f {
    max-width: 95px !important;
  }

  .mn-wd-lg-95-f {
    min-width: 95px !important;
  }

  .wd-lg-100 {
    width: 100px;
  }

  .wd-lg-100p {
    width: 100%;
  }

  .mx-wd-lg-100p {
    max-width: 100%;
  }

  .mn-wd-lg-100p {
    min-width: 100%;
  }

  .mx-wd-lg-100 {
    max-width: 100px;
  }

  .mn-wd-lg-100 {
    min-width: 100px;
  }

  .wd-lg-100-f {
    width: 100px !important;
  }

  .wd-lg-100p-f {
    width: 100% !important;
  }

  .mx-wd-lg-100p-f {
    max-width: 100% !important;
  }

  .mn-wd-lg-100p-f {
    min-width: 100% !important;
  }

  .mx-wd-lg-100-f {
    max-width: 100px !important;
  }

  .mn-wd-lg-100-f {
    min-width: 100px !important;
  }

  .wd-lg-150 {
    width: 150px;
  }

  .wd-lg-150p {
    width: 150%;
  }

  .mx-wd-lg-150p {
    max-width: 150%;
  }

  .mn-wd-lg-150p {
    min-width: 150%;
  }

  .mx-wd-lg-150 {
    max-width: 150px;
  }

  .mn-wd-lg-150 {
    min-width: 150px;
  }

  .wd-lg-150-f {
    width: 150px !important;
  }

  .wd-lg-150p-f {
    width: 150% !important;
  }

  .mx-wd-lg-150p-f {
    max-width: 150% !important;
  }

  .mn-wd-lg-150p-f {
    min-width: 150% !important;
  }

  .mx-wd-lg-150-f {
    max-width: 150px !important;
  }

  .mn-wd-lg-150-f {
    min-width: 150px !important;
  }

  .wd-lg-200 {
    width: 200px;
  }

  .wd-lg-200p {
    width: 200%;
  }

  .mx-wd-lg-200p {
    max-width: 200%;
  }

  .mn-wd-lg-200p {
    min-width: 200%;
  }

  .mx-wd-lg-200 {
    max-width: 200px;
  }

  .mn-wd-lg-200 {
    min-width: 200px;
  }

  .wd-lg-200-f {
    width: 200px !important;
  }

  .wd-lg-200p-f {
    width: 200% !important;
  }

  .mx-wd-lg-200p-f {
    max-width: 200% !important;
  }

  .mn-wd-lg-200p-f {
    min-width: 200% !important;
  }

  .mx-wd-lg-200-f {
    max-width: 200px !important;
  }

  .mn-wd-lg-200-f {
    min-width: 200px !important;
  }

  .wd-lg-250 {
    width: 250px;
  }

  .wd-lg-250p {
    width: 250%;
  }

  .mx-wd-lg-250p {
    max-width: 250%;
  }

  .mn-wd-lg-250p {
    min-width: 250%;
  }

  .mx-wd-lg-250 {
    max-width: 250px;
  }

  .mn-wd-lg-250 {
    min-width: 250px;
  }

  .wd-lg-250-f {
    width: 250px !important;
  }

  .wd-lg-250p-f {
    width: 250% !important;
  }

  .mx-wd-lg-250p-f {
    max-width: 250% !important;
  }

  .mn-wd-lg-250p-f {
    min-width: 250% !important;
  }

  .mx-wd-lg-250-f {
    max-width: 250px !important;
  }

  .mn-wd-lg-250-f {
    min-width: 250px !important;
  }

  .wd-lg-300 {
    width: 300px;
  }

  .wd-lg-300p {
    width: 300%;
  }

  .mx-wd-lg-300p {
    max-width: 300%;
  }

  .mn-wd-lg-300p {
    min-width: 300%;
  }

  .mx-wd-lg-300 {
    max-width: 300px;
  }

  .mn-wd-lg-300 {
    min-width: 300px;
  }

  .wd-lg-300-f {
    width: 300px !important;
  }

  .wd-lg-300p-f {
    width: 300% !important;
  }

  .mx-wd-lg-300p-f {
    max-width: 300% !important;
  }

  .mn-wd-lg-300p-f {
    min-width: 300% !important;
  }

  .mx-wd-lg-300-f {
    max-width: 300px !important;
  }

  .mn-wd-lg-300-f {
    min-width: 300px !important;
  }

  .wd-lg-350 {
    width: 350px;
  }

  .wd-lg-350p {
    width: 350%;
  }

  .mx-wd-lg-350p {
    max-width: 350%;
  }

  .mn-wd-lg-350p {
    min-width: 350%;
  }

  .mx-wd-lg-350 {
    max-width: 350px;
  }

  .mn-wd-lg-350 {
    min-width: 350px;
  }

  .wd-lg-350-f {
    width: 350px !important;
  }

  .wd-lg-350p-f {
    width: 350% !important;
  }

  .mx-wd-lg-350p-f {
    max-width: 350% !important;
  }

  .mn-wd-lg-350p-f {
    min-width: 350% !important;
  }

  .mx-wd-lg-350-f {
    max-width: 350px !important;
  }

  .mn-wd-lg-350-f {
    min-width: 350px !important;
  }

  .wd-lg-400 {
    width: 400px;
  }

  .wd-lg-400p {
    width: 400%;
  }

  .mx-wd-lg-400p {
    max-width: 400%;
  }

  .mn-wd-lg-400p {
    min-width: 400%;
  }

  .mx-wd-lg-400 {
    max-width: 400px;
  }

  .mn-wd-lg-400 {
    min-width: 400px;
  }

  .wd-lg-400-f {
    width: 400px !important;
  }

  .wd-lg-400p-f {
    width: 400% !important;
  }

  .mx-wd-lg-400p-f {
    max-width: 400% !important;
  }

  .mn-wd-lg-400p-f {
    min-width: 400% !important;
  }

  .mx-wd-lg-400-f {
    max-width: 400px !important;
  }

  .mn-wd-lg-400-f {
    min-width: 400px !important;
  }

  .wd-lg-450 {
    width: 450px;
  }

  .wd-lg-450p {
    width: 450%;
  }

  .mx-wd-lg-450p {
    max-width: 450%;
  }

  .mn-wd-lg-450p {
    min-width: 450%;
  }

  .mx-wd-lg-450 {
    max-width: 450px;
  }

  .mn-wd-lg-450 {
    min-width: 450px;
  }

  .wd-lg-450-f {
    width: 450px !important;
  }

  .wd-lg-450p-f {
    width: 450% !important;
  }

  .mx-wd-lg-450p-f {
    max-width: 450% !important;
  }

  .mn-wd-lg-450p-f {
    min-width: 450% !important;
  }

  .mx-wd-lg-450-f {
    max-width: 450px !important;
  }

  .mn-wd-lg-450-f {
    min-width: 450px !important;
  }

  .wd-lg-500 {
    width: 500px;
  }

  .wd-lg-500p {
    width: 500%;
  }

  .mx-wd-lg-500p {
    max-width: 500%;
  }

  .mn-wd-lg-500p {
    min-width: 500%;
  }

  .mx-wd-lg-500 {
    max-width: 500px;
  }

  .mn-wd-lg-500 {
    min-width: 500px;
  }

  .wd-lg-500-f {
    width: 500px !important;
  }

  .wd-lg-500p-f {
    width: 500% !important;
  }

  .mx-wd-lg-500p-f {
    max-width: 500% !important;
  }

  .mn-wd-lg-500p-f {
    min-width: 500% !important;
  }

  .mx-wd-lg-500-f {
    max-width: 500px !important;
  }

  .mn-wd-lg-500-f {
    min-width: 500px !important;
  }

  .wd-lg-550 {
    width: 550px;
  }

  .wd-lg-550p {
    width: 550%;
  }

  .mx-wd-lg-550p {
    max-width: 550%;
  }

  .mn-wd-lg-550p {
    min-width: 550%;
  }

  .mx-wd-lg-550 {
    max-width: 550px;
  }

  .mn-wd-lg-550 {
    min-width: 550px;
  }

  .wd-lg-550-f {
    width: 550px !important;
  }

  .wd-lg-550p-f {
    width: 550% !important;
  }

  .mx-wd-lg-550p-f {
    max-width: 550% !important;
  }

  .mn-wd-lg-550p-f {
    min-width: 550% !important;
  }

  .mx-wd-lg-550-f {
    max-width: 550px !important;
  }

  .mn-wd-lg-550-f {
    min-width: 550px !important;
  }

  .wd-lg-600 {
    width: 600px;
  }

  .wd-lg-600p {
    width: 600%;
  }

  .mx-wd-lg-600p {
    max-width: 600%;
  }

  .mn-wd-lg-600p {
    min-width: 600%;
  }

  .mx-wd-lg-600 {
    max-width: 600px;
  }

  .mn-wd-lg-600 {
    min-width: 600px;
  }

  .wd-lg-600-f {
    width: 600px !important;
  }

  .wd-lg-600p-f {
    width: 600% !important;
  }

  .mx-wd-lg-600p-f {
    max-width: 600% !important;
  }

  .mn-wd-lg-600p-f {
    min-width: 600% !important;
  }

  .mx-wd-lg-600-f {
    max-width: 600px !important;
  }

  .mn-wd-lg-600-f {
    min-width: 600px !important;
  }

  .wd-lg-650 {
    width: 650px;
  }

  .wd-lg-650p {
    width: 650%;
  }

  .mx-wd-lg-650p {
    max-width: 650%;
  }

  .mn-wd-lg-650p {
    min-width: 650%;
  }

  .mx-wd-lg-650 {
    max-width: 650px;
  }

  .mn-wd-lg-650 {
    min-width: 650px;
  }

  .wd-lg-650-f {
    width: 650px !important;
  }

  .wd-lg-650p-f {
    width: 650% !important;
  }

  .mx-wd-lg-650p-f {
    max-width: 650% !important;
  }

  .mn-wd-lg-650p-f {
    min-width: 650% !important;
  }

  .mx-wd-lg-650-f {
    max-width: 650px !important;
  }

  .mn-wd-lg-650-f {
    min-width: 650px !important;
  }

  .wd-lg-700 {
    width: 700px;
  }

  .wd-lg-700p {
    width: 700%;
  }

  .mx-wd-lg-700p {
    max-width: 700%;
  }

  .mn-wd-lg-700p {
    min-width: 700%;
  }

  .mx-wd-lg-700 {
    max-width: 700px;
  }

  .mn-wd-lg-700 {
    min-width: 700px;
  }

  .wd-lg-700-f {
    width: 700px !important;
  }

  .wd-lg-700p-f {
    width: 700% !important;
  }

  .mx-wd-lg-700p-f {
    max-width: 700% !important;
  }

  .mn-wd-lg-700p-f {
    min-width: 700% !important;
  }

  .mx-wd-lg-700-f {
    max-width: 700px !important;
  }

  .mn-wd-lg-700-f {
    min-width: 700px !important;
  }

  .wd-lg-750 {
    width: 750px;
  }

  .wd-lg-750p {
    width: 750%;
  }

  .mx-wd-lg-750p {
    max-width: 750%;
  }

  .mn-wd-lg-750p {
    min-width: 750%;
  }

  .mx-wd-lg-750 {
    max-width: 750px;
  }

  .mn-wd-lg-750 {
    min-width: 750px;
  }

  .wd-lg-750-f {
    width: 750px !important;
  }

  .wd-lg-750p-f {
    width: 750% !important;
  }

  .mx-wd-lg-750p-f {
    max-width: 750% !important;
  }

  .mn-wd-lg-750p-f {
    min-width: 750% !important;
  }

  .mx-wd-lg-750-f {
    max-width: 750px !important;
  }

  .mn-wd-lg-750-f {
    min-width: 750px !important;
  }

  .wd-lg-800 {
    width: 800px;
  }

  .wd-lg-800p {
    width: 800%;
  }

  .mx-wd-lg-800p {
    max-width: 800%;
  }

  .mn-wd-lg-800p {
    min-width: 800%;
  }

  .mx-wd-lg-800 {
    max-width: 800px;
  }

  .mn-wd-lg-800 {
    min-width: 800px;
  }

  .wd-lg-800-f {
    width: 800px !important;
  }

  .wd-lg-800p-f {
    width: 800% !important;
  }

  .mx-wd-lg-800p-f {
    max-width: 800% !important;
  }

  .mn-wd-lg-800p-f {
    min-width: 800% !important;
  }

  .mx-wd-lg-800-f {
    max-width: 800px !important;
  }

  .mn-wd-lg-800-f {
    min-width: 800px !important;
  }

  .wd-lg-850 {
    width: 850px;
  }

  .wd-lg-850p {
    width: 850%;
  }

  .mx-wd-lg-850p {
    max-width: 850%;
  }

  .mn-wd-lg-850p {
    min-width: 850%;
  }

  .mx-wd-lg-850 {
    max-width: 850px;
  }

  .mn-wd-lg-850 {
    min-width: 850px;
  }

  .wd-lg-850-f {
    width: 850px !important;
  }

  .wd-lg-850p-f {
    width: 850% !important;
  }

  .mx-wd-lg-850p-f {
    max-width: 850% !important;
  }

  .mn-wd-lg-850p-f {
    min-width: 850% !important;
  }

  .mx-wd-lg-850-f {
    max-width: 850px !important;
  }

  .mn-wd-lg-850-f {
    min-width: 850px !important;
  }

  .wd-lg-900 {
    width: 900px;
  }

  .wd-lg-900p {
    width: 900%;
  }

  .mx-wd-lg-900p {
    max-width: 900%;
  }

  .mn-wd-lg-900p {
    min-width: 900%;
  }

  .mx-wd-lg-900 {
    max-width: 900px;
  }

  .mn-wd-lg-900 {
    min-width: 900px;
  }

  .wd-lg-900-f {
    width: 900px !important;
  }

  .wd-lg-900p-f {
    width: 900% !important;
  }

  .mx-wd-lg-900p-f {
    max-width: 900% !important;
  }

  .mn-wd-lg-900p-f {
    min-width: 900% !important;
  }

  .mx-wd-lg-900-f {
    max-width: 900px !important;
  }

  .mn-wd-lg-900-f {
    min-width: 900px !important;
  }

  .wd-lg-950 {
    width: 950px;
  }

  .wd-lg-950p {
    width: 950%;
  }

  .mx-wd-lg-950p {
    max-width: 950%;
  }

  .mn-wd-lg-950p {
    min-width: 950%;
  }

  .mx-wd-lg-950 {
    max-width: 950px;
  }

  .mn-wd-lg-950 {
    min-width: 950px;
  }

  .wd-lg-950-f {
    width: 950px !important;
  }

  .wd-lg-950p-f {
    width: 950% !important;
  }

  .mx-wd-lg-950p-f {
    max-width: 950% !important;
  }

  .mn-wd-lg-950p-f {
    min-width: 950% !important;
  }

  .mx-wd-lg-950-f {
    max-width: 950px !important;
  }

  .mn-wd-lg-950-f {
    min-width: 950px !important;
  }

  .wd-lg-1000 {
    width: 1000px;
  }

  .wd-lg-1000p {
    width: 1000%;
  }

  .mx-wd-lg-1000p {
    max-width: 1000%;
  }

  .mn-wd-lg-1000p {
    min-width: 1000%;
  }

  .mx-wd-lg-1000 {
    max-width: 1000px;
  }

  .mn-wd-lg-1000 {
    min-width: 1000px;
  }

  .wd-lg-1000-f {
    width: 1000px !important;
  }

  .wd-lg-1000p-f {
    width: 1000% !important;
  }

  .mx-wd-lg-1000p-f {
    max-width: 1000% !important;
  }

  .mn-wd-lg-1000p-f {
    min-width: 1000% !important;
  }

  .mx-wd-lg-1000-f {
    max-width: 1000px !important;
  }

  .mn-wd-lg-1000-f {
    min-width: 1000px !important;
  }

  .wd-lg-auto {
    width: auto;
  }

  .wd-lg-auto-f {
    width: auto !important;
  }
}
@media (min-width: 1200px) {
  .wd-xl-5 {
    width: 5px;
  }

  .wd-xl-5p {
    width: 5%;
  }

  .mx-wd-xl-5p {
    max-width: 5%;
  }

  .mn-wd-xl-5p {
    min-width: 5%;
  }

  .mx-wd-xl-5 {
    max-width: 5px;
  }

  .mn-wd-xl-5 {
    min-width: 5px;
  }

  .wd-xl-5-f {
    width: 5px !important;
  }

  .wd-xl-5p-f {
    width: 5% !important;
  }

  .mx-wd-xl-5p-f {
    max-width: 5% !important;
  }

  .mn-wd-xl-5p-f {
    min-width: 5% !important;
  }

  .mx-wd-xl-5-f {
    max-width: 5px !important;
  }

  .mn-wd-xl-5-f {
    min-width: 5px !important;
  }

  .wd-xl-10 {
    width: 10px;
  }

  .wd-xl-10p {
    width: 10%;
  }

  .mx-wd-xl-10p {
    max-width: 10%;
  }

  .mn-wd-xl-10p {
    min-width: 10%;
  }

  .mx-wd-xl-10 {
    max-width: 10px;
  }

  .mn-wd-xl-10 {
    min-width: 10px;
  }

  .wd-xl-10-f {
    width: 10px !important;
  }

  .wd-xl-10p-f {
    width: 10% !important;
  }

  .mx-wd-xl-10p-f {
    max-width: 10% !important;
  }

  .mn-wd-xl-10p-f {
    min-width: 10% !important;
  }

  .mx-wd-xl-10-f {
    max-width: 10px !important;
  }

  .mn-wd-xl-10-f {
    min-width: 10px !important;
  }

  .wd-xl-15 {
    width: 15px;
  }

  .wd-xl-15p {
    width: 15%;
  }

  .mx-wd-xl-15p {
    max-width: 15%;
  }

  .mn-wd-xl-15p {
    min-width: 15%;
  }

  .mx-wd-xl-15 {
    max-width: 15px;
  }

  .mn-wd-xl-15 {
    min-width: 15px;
  }

  .wd-xl-15-f {
    width: 15px !important;
  }

  .wd-xl-15p-f {
    width: 15% !important;
  }

  .mx-wd-xl-15p-f {
    max-width: 15% !important;
  }

  .mn-wd-xl-15p-f {
    min-width: 15% !important;
  }

  .mx-wd-xl-15-f {
    max-width: 15px !important;
  }

  .mn-wd-xl-15-f {
    min-width: 15px !important;
  }

  .wd-xl-20 {
    width: 20px;
  }

  .wd-xl-20p {
    width: 20%;
  }

  .mx-wd-xl-20p {
    max-width: 20%;
  }

  .mn-wd-xl-20p {
    min-width: 20%;
  }

  .mx-wd-xl-20 {
    max-width: 20px;
  }

  .mn-wd-xl-20 {
    min-width: 20px;
  }

  .wd-xl-20-f {
    width: 20px !important;
  }

  .wd-xl-20p-f {
    width: 20% !important;
  }

  .mx-wd-xl-20p-f {
    max-width: 20% !important;
  }

  .mn-wd-xl-20p-f {
    min-width: 20% !important;
  }

  .mx-wd-xl-20-f {
    max-width: 20px !important;
  }

  .mn-wd-xl-20-f {
    min-width: 20px !important;
  }

  .wd-xl-25 {
    width: 25px;
  }

  .wd-xl-25p {
    width: 25%;
  }

  .mx-wd-xl-25p {
    max-width: 25%;
  }

  .mn-wd-xl-25p {
    min-width: 25%;
  }

  .mx-wd-xl-25 {
    max-width: 25px;
  }

  .mn-wd-xl-25 {
    min-width: 25px;
  }

  .wd-xl-25-f {
    width: 25px !important;
  }

  .wd-xl-25p-f {
    width: 25% !important;
  }

  .mx-wd-xl-25p-f {
    max-width: 25% !important;
  }

  .mn-wd-xl-25p-f {
    min-width: 25% !important;
  }

  .mx-wd-xl-25-f {
    max-width: 25px !important;
  }

  .mn-wd-xl-25-f {
    min-width: 25px !important;
  }

  .wd-xl-30 {
    width: 30px;
  }

  .wd-xl-30p {
    width: 30%;
  }

  .mx-wd-xl-30p {
    max-width: 30%;
  }

  .mn-wd-xl-30p {
    min-width: 30%;
  }

  .mx-wd-xl-30 {
    max-width: 30px;
  }

  .mn-wd-xl-30 {
    min-width: 30px;
  }

  .wd-xl-30-f {
    width: 30px !important;
  }

  .wd-xl-30p-f {
    width: 30% !important;
  }

  .mx-wd-xl-30p-f {
    max-width: 30% !important;
  }

  .mn-wd-xl-30p-f {
    min-width: 30% !important;
  }

  .mx-wd-xl-30-f {
    max-width: 30px !important;
  }

  .mn-wd-xl-30-f {
    min-width: 30px !important;
  }

  .wd-xl-35 {
    width: 35px;
  }

  .wd-xl-35p {
    width: 35%;
  }

  .mx-wd-xl-35p {
    max-width: 35%;
  }

  .mn-wd-xl-35p {
    min-width: 35%;
  }

  .mx-wd-xl-35 {
    max-width: 35px;
  }

  .mn-wd-xl-35 {
    min-width: 35px;
  }

  .wd-xl-35-f {
    width: 35px !important;
  }

  .wd-xl-35p-f {
    width: 35% !important;
  }

  .mx-wd-xl-35p-f {
    max-width: 35% !important;
  }

  .mn-wd-xl-35p-f {
    min-width: 35% !important;
  }

  .mx-wd-xl-35-f {
    max-width: 35px !important;
  }

  .mn-wd-xl-35-f {
    min-width: 35px !important;
  }

  .wd-xl-40 {
    width: 40px;
  }

  .wd-xl-40p {
    width: 40%;
  }

  .mx-wd-xl-40p {
    max-width: 40%;
  }

  .mn-wd-xl-40p {
    min-width: 40%;
  }

  .mx-wd-xl-40 {
    max-width: 40px;
  }

  .mn-wd-xl-40 {
    min-width: 40px;
  }

  .wd-xl-40-f {
    width: 40px !important;
  }

  .wd-xl-40p-f {
    width: 40% !important;
  }

  .mx-wd-xl-40p-f {
    max-width: 40% !important;
  }

  .mn-wd-xl-40p-f {
    min-width: 40% !important;
  }

  .mx-wd-xl-40-f {
    max-width: 40px !important;
  }

  .mn-wd-xl-40-f {
    min-width: 40px !important;
  }

  .wd-xl-45 {
    width: 45px;
  }

  .wd-xl-45p {
    width: 45%;
  }

  .mx-wd-xl-45p {
    max-width: 45%;
  }

  .mn-wd-xl-45p {
    min-width: 45%;
  }

  .mx-wd-xl-45 {
    max-width: 45px;
  }

  .mn-wd-xl-45 {
    min-width: 45px;
  }

  .wd-xl-45-f {
    width: 45px !important;
  }

  .wd-xl-45p-f {
    width: 45% !important;
  }

  .mx-wd-xl-45p-f {
    max-width: 45% !important;
  }

  .mn-wd-xl-45p-f {
    min-width: 45% !important;
  }

  .mx-wd-xl-45-f {
    max-width: 45px !important;
  }

  .mn-wd-xl-45-f {
    min-width: 45px !important;
  }

  .wd-xl-50 {
    width: 50px;
  }

  .wd-xl-50p {
    width: 50%;
  }

  .mx-wd-xl-50p {
    max-width: 50%;
  }

  .mn-wd-xl-50p {
    min-width: 50%;
  }

  .mx-wd-xl-50 {
    max-width: 50px;
  }

  .mn-wd-xl-50 {
    min-width: 50px;
  }

  .wd-xl-50-f {
    width: 50px !important;
  }

  .wd-xl-50p-f {
    width: 50% !important;
  }

  .mx-wd-xl-50p-f {
    max-width: 50% !important;
  }

  .mn-wd-xl-50p-f {
    min-width: 50% !important;
  }

  .mx-wd-xl-50-f {
    max-width: 50px !important;
  }

  .mn-wd-xl-50-f {
    min-width: 50px !important;
  }

  .wd-xl-55 {
    width: 55px;
  }

  .wd-xl-55p {
    width: 55%;
  }

  .mx-wd-xl-55p {
    max-width: 55%;
  }

  .mn-wd-xl-55p {
    min-width: 55%;
  }

  .mx-wd-xl-55 {
    max-width: 55px;
  }

  .mn-wd-xl-55 {
    min-width: 55px;
  }

  .wd-xl-55-f {
    width: 55px !important;
  }

  .wd-xl-55p-f {
    width: 55% !important;
  }

  .mx-wd-xl-55p-f {
    max-width: 55% !important;
  }

  .mn-wd-xl-55p-f {
    min-width: 55% !important;
  }

  .mx-wd-xl-55-f {
    max-width: 55px !important;
  }

  .mn-wd-xl-55-f {
    min-width: 55px !important;
  }

  .wd-xl-60 {
    width: 60px;
  }

  .wd-xl-60p {
    width: 60%;
  }

  .mx-wd-xl-60p {
    max-width: 60%;
  }

  .mn-wd-xl-60p {
    min-width: 60%;
  }

  .mx-wd-xl-60 {
    max-width: 60px;
  }

  .mn-wd-xl-60 {
    min-width: 60px;
  }

  .wd-xl-60-f {
    width: 60px !important;
  }

  .wd-xl-60p-f {
    width: 60% !important;
  }

  .mx-wd-xl-60p-f {
    max-width: 60% !important;
  }

  .mn-wd-xl-60p-f {
    min-width: 60% !important;
  }

  .mx-wd-xl-60-f {
    max-width: 60px !important;
  }

  .mn-wd-xl-60-f {
    min-width: 60px !important;
  }

  .wd-xl-65 {
    width: 65px;
  }

  .wd-xl-65p {
    width: 65%;
  }

  .mx-wd-xl-65p {
    max-width: 65%;
  }

  .mn-wd-xl-65p {
    min-width: 65%;
  }

  .mx-wd-xl-65 {
    max-width: 65px;
  }

  .mn-wd-xl-65 {
    min-width: 65px;
  }

  .wd-xl-65-f {
    width: 65px !important;
  }

  .wd-xl-65p-f {
    width: 65% !important;
  }

  .mx-wd-xl-65p-f {
    max-width: 65% !important;
  }

  .mn-wd-xl-65p-f {
    min-width: 65% !important;
  }

  .mx-wd-xl-65-f {
    max-width: 65px !important;
  }

  .mn-wd-xl-65-f {
    min-width: 65px !important;
  }

  .wd-xl-70 {
    width: 70px;
  }

  .wd-xl-70p {
    width: 70%;
  }

  .mx-wd-xl-70p {
    max-width: 70%;
  }

  .mn-wd-xl-70p {
    min-width: 70%;
  }

  .mx-wd-xl-70 {
    max-width: 70px;
  }

  .mn-wd-xl-70 {
    min-width: 70px;
  }

  .wd-xl-70-f {
    width: 70px !important;
  }

  .wd-xl-70p-f {
    width: 70% !important;
  }

  .mx-wd-xl-70p-f {
    max-width: 70% !important;
  }

  .mn-wd-xl-70p-f {
    min-width: 70% !important;
  }

  .mx-wd-xl-70-f {
    max-width: 70px !important;
  }

  .mn-wd-xl-70-f {
    min-width: 70px !important;
  }

  .wd-xl-75 {
    width: 75px;
  }

  .wd-xl-75p {
    width: 75%;
  }

  .mx-wd-xl-75p {
    max-width: 75%;
  }

  .mn-wd-xl-75p {
    min-width: 75%;
  }

  .mx-wd-xl-75 {
    max-width: 75px;
  }

  .mn-wd-xl-75 {
    min-width: 75px;
  }

  .wd-xl-75-f {
    width: 75px !important;
  }

  .wd-xl-75p-f {
    width: 75% !important;
  }

  .mx-wd-xl-75p-f {
    max-width: 75% !important;
  }

  .mn-wd-xl-75p-f {
    min-width: 75% !important;
  }

  .mx-wd-xl-75-f {
    max-width: 75px !important;
  }

  .mn-wd-xl-75-f {
    min-width: 75px !important;
  }

  .wd-xl-80 {
    width: 80px;
  }

  .wd-xl-80p {
    width: 80%;
  }

  .mx-wd-xl-80p {
    max-width: 80%;
  }

  .mn-wd-xl-80p {
    min-width: 80%;
  }

  .mx-wd-xl-80 {
    max-width: 80px;
  }

  .mn-wd-xl-80 {
    min-width: 80px;
  }

  .wd-xl-80-f {
    width: 80px !important;
  }

  .wd-xl-80p-f {
    width: 80% !important;
  }

  .mx-wd-xl-80p-f {
    max-width: 80% !important;
  }

  .mn-wd-xl-80p-f {
    min-width: 80% !important;
  }

  .mx-wd-xl-80-f {
    max-width: 80px !important;
  }

  .mn-wd-xl-80-f {
    min-width: 80px !important;
  }

  .wd-xl-85 {
    width: 85px;
  }

  .wd-xl-85p {
    width: 85%;
  }

  .mx-wd-xl-85p {
    max-width: 85%;
  }

  .mn-wd-xl-85p {
    min-width: 85%;
  }

  .mx-wd-xl-85 {
    max-width: 85px;
  }

  .mn-wd-xl-85 {
    min-width: 85px;
  }

  .wd-xl-85-f {
    width: 85px !important;
  }

  .wd-xl-85p-f {
    width: 85% !important;
  }

  .mx-wd-xl-85p-f {
    max-width: 85% !important;
  }

  .mn-wd-xl-85p-f {
    min-width: 85% !important;
  }

  .mx-wd-xl-85-f {
    max-width: 85px !important;
  }

  .mn-wd-xl-85-f {
    min-width: 85px !important;
  }

  .wd-xl-90 {
    width: 90px;
  }

  .wd-xl-90p {
    width: 90%;
  }

  .mx-wd-xl-90p {
    max-width: 90%;
  }

  .mn-wd-xl-90p {
    min-width: 90%;
  }

  .mx-wd-xl-90 {
    max-width: 90px;
  }

  .mn-wd-xl-90 {
    min-width: 90px;
  }

  .wd-xl-90-f {
    width: 90px !important;
  }

  .wd-xl-90p-f {
    width: 90% !important;
  }

  .mx-wd-xl-90p-f {
    max-width: 90% !important;
  }

  .mn-wd-xl-90p-f {
    min-width: 90% !important;
  }

  .mx-wd-xl-90-f {
    max-width: 90px !important;
  }

  .mn-wd-xl-90-f {
    min-width: 90px !important;
  }

  .wd-xl-95 {
    width: 95px;
  }

  .wd-xl-95p {
    width: 95%;
  }

  .mx-wd-xl-95p {
    max-width: 95%;
  }

  .mn-wd-xl-95p {
    min-width: 95%;
  }

  .mx-wd-xl-95 {
    max-width: 95px;
  }

  .mn-wd-xl-95 {
    min-width: 95px;
  }

  .wd-xl-95-f {
    width: 95px !important;
  }

  .wd-xl-95p-f {
    width: 95% !important;
  }

  .mx-wd-xl-95p-f {
    max-width: 95% !important;
  }

  .mn-wd-xl-95p-f {
    min-width: 95% !important;
  }

  .mx-wd-xl-95-f {
    max-width: 95px !important;
  }

  .mn-wd-xl-95-f {
    min-width: 95px !important;
  }

  .wd-xl-100 {
    width: 100px;
  }

  .wd-xl-100p {
    width: 100%;
  }

  .mx-wd-xl-100p {
    max-width: 100%;
  }

  .mn-wd-xl-100p {
    min-width: 100%;
  }

  .mx-wd-xl-100 {
    max-width: 100px;
  }

  .mn-wd-xl-100 {
    min-width: 100px;
  }

  .wd-xl-100-f {
    width: 100px !important;
  }

  .wd-xl-100p-f {
    width: 100% !important;
  }

  .mx-wd-xl-100p-f {
    max-width: 100% !important;
  }

  .mn-wd-xl-100p-f {
    min-width: 100% !important;
  }

  .mx-wd-xl-100-f {
    max-width: 100px !important;
  }

  .mn-wd-xl-100-f {
    min-width: 100px !important;
  }

  .wd-xl-150 {
    width: 150px;
  }

  .wd-xl-150p {
    width: 150%;
  }

  .mx-wd-xl-150p {
    max-width: 150%;
  }

  .mn-wd-xl-150p {
    min-width: 150%;
  }

  .mx-wd-xl-150 {
    max-width: 150px;
  }

  .mn-wd-xl-150 {
    min-width: 150px;
  }

  .wd-xl-150-f {
    width: 150px !important;
  }

  .wd-xl-150p-f {
    width: 150% !important;
  }

  .mx-wd-xl-150p-f {
    max-width: 150% !important;
  }

  .mn-wd-xl-150p-f {
    min-width: 150% !important;
  }

  .mx-wd-xl-150-f {
    max-width: 150px !important;
  }

  .mn-wd-xl-150-f {
    min-width: 150px !important;
  }

  .wd-xl-200 {
    width: 200px;
  }

  .wd-xl-200p {
    width: 200%;
  }

  .mx-wd-xl-200p {
    max-width: 200%;
  }

  .mn-wd-xl-200p {
    min-width: 200%;
  }

  .mx-wd-xl-200 {
    max-width: 200px;
  }

  .mn-wd-xl-200 {
    min-width: 200px;
  }

  .wd-xl-200-f {
    width: 200px !important;
  }

  .wd-xl-200p-f {
    width: 200% !important;
  }

  .mx-wd-xl-200p-f {
    max-width: 200% !important;
  }

  .mn-wd-xl-200p-f {
    min-width: 200% !important;
  }

  .mx-wd-xl-200-f {
    max-width: 200px !important;
  }

  .mn-wd-xl-200-f {
    min-width: 200px !important;
  }

  .wd-xl-250 {
    width: 250px;
  }

  .wd-xl-250p {
    width: 250%;
  }

  .mx-wd-xl-250p {
    max-width: 250%;
  }

  .mn-wd-xl-250p {
    min-width: 250%;
  }

  .mx-wd-xl-250 {
    max-width: 250px;
  }

  .mn-wd-xl-250 {
    min-width: 250px;
  }

  .wd-xl-250-f {
    width: 250px !important;
  }

  .wd-xl-250p-f {
    width: 250% !important;
  }

  .mx-wd-xl-250p-f {
    max-width: 250% !important;
  }

  .mn-wd-xl-250p-f {
    min-width: 250% !important;
  }

  .mx-wd-xl-250-f {
    max-width: 250px !important;
  }

  .mn-wd-xl-250-f {
    min-width: 250px !important;
  }

  .wd-xl-300 {
    width: 300px;
  }

  .wd-xl-300p {
    width: 300%;
  }

  .mx-wd-xl-300p {
    max-width: 300%;
  }

  .mn-wd-xl-300p {
    min-width: 300%;
  }

  .mx-wd-xl-300 {
    max-width: 300px;
  }

  .mn-wd-xl-300 {
    min-width: 300px;
  }

  .wd-xl-300-f {
    width: 300px !important;
  }

  .wd-xl-300p-f {
    width: 300% !important;
  }

  .mx-wd-xl-300p-f {
    max-width: 300% !important;
  }

  .mn-wd-xl-300p-f {
    min-width: 300% !important;
  }

  .mx-wd-xl-300-f {
    max-width: 300px !important;
  }

  .mn-wd-xl-300-f {
    min-width: 300px !important;
  }

  .wd-xl-350 {
    width: 350px;
  }

  .wd-xl-350p {
    width: 350%;
  }

  .mx-wd-xl-350p {
    max-width: 350%;
  }

  .mn-wd-xl-350p {
    min-width: 350%;
  }

  .mx-wd-xl-350 {
    max-width: 350px;
  }

  .mn-wd-xl-350 {
    min-width: 350px;
  }

  .wd-xl-350-f {
    width: 350px !important;
  }

  .wd-xl-350p-f {
    width: 350% !important;
  }

  .mx-wd-xl-350p-f {
    max-width: 350% !important;
  }

  .mn-wd-xl-350p-f {
    min-width: 350% !important;
  }

  .mx-wd-xl-350-f {
    max-width: 350px !important;
  }

  .mn-wd-xl-350-f {
    min-width: 350px !important;
  }

  .wd-xl-400 {
    width: 400px;
  }

  .wd-xl-400p {
    width: 400%;
  }

  .mx-wd-xl-400p {
    max-width: 400%;
  }

  .mn-wd-xl-400p {
    min-width: 400%;
  }

  .mx-wd-xl-400 {
    max-width: 400px;
  }

  .mn-wd-xl-400 {
    min-width: 400px;
  }

  .wd-xl-400-f {
    width: 400px !important;
  }

  .wd-xl-400p-f {
    width: 400% !important;
  }

  .mx-wd-xl-400p-f {
    max-width: 400% !important;
  }

  .mn-wd-xl-400p-f {
    min-width: 400% !important;
  }

  .mx-wd-xl-400-f {
    max-width: 400px !important;
  }

  .mn-wd-xl-400-f {
    min-width: 400px !important;
  }

  .wd-xl-450 {
    width: 450px;
  }

  .wd-xl-450p {
    width: 450%;
  }

  .mx-wd-xl-450p {
    max-width: 450%;
  }

  .mn-wd-xl-450p {
    min-width: 450%;
  }

  .mx-wd-xl-450 {
    max-width: 450px;
  }

  .mn-wd-xl-450 {
    min-width: 450px;
  }

  .wd-xl-450-f {
    width: 450px !important;
  }

  .wd-xl-450p-f {
    width: 450% !important;
  }

  .mx-wd-xl-450p-f {
    max-width: 450% !important;
  }

  .mn-wd-xl-450p-f {
    min-width: 450% !important;
  }

  .mx-wd-xl-450-f {
    max-width: 450px !important;
  }

  .mn-wd-xl-450-f {
    min-width: 450px !important;
  }

  .wd-xl-500 {
    width: 500px;
  }

  .wd-xl-500p {
    width: 500%;
  }

  .mx-wd-xl-500p {
    max-width: 500%;
  }

  .mn-wd-xl-500p {
    min-width: 500%;
  }

  .mx-wd-xl-500 {
    max-width: 500px;
  }

  .mn-wd-xl-500 {
    min-width: 500px;
  }

  .wd-xl-500-f {
    width: 500px !important;
  }

  .wd-xl-500p-f {
    width: 500% !important;
  }

  .mx-wd-xl-500p-f {
    max-width: 500% !important;
  }

  .mn-wd-xl-500p-f {
    min-width: 500% !important;
  }

  .mx-wd-xl-500-f {
    max-width: 500px !important;
  }

  .mn-wd-xl-500-f {
    min-width: 500px !important;
  }

  .wd-xl-550 {
    width: 550px;
  }

  .wd-xl-550p {
    width: 550%;
  }

  .mx-wd-xl-550p {
    max-width: 550%;
  }

  .mn-wd-xl-550p {
    min-width: 550%;
  }

  .mx-wd-xl-550 {
    max-width: 550px;
  }

  .mn-wd-xl-550 {
    min-width: 550px;
  }

  .wd-xl-550-f {
    width: 550px !important;
  }

  .wd-xl-550p-f {
    width: 550% !important;
  }

  .mx-wd-xl-550p-f {
    max-width: 550% !important;
  }

  .mn-wd-xl-550p-f {
    min-width: 550% !important;
  }

  .mx-wd-xl-550-f {
    max-width: 550px !important;
  }

  .mn-wd-xl-550-f {
    min-width: 550px !important;
  }

  .wd-xl-600 {
    width: 600px;
  }

  .wd-xl-600p {
    width: 600%;
  }

  .mx-wd-xl-600p {
    max-width: 600%;
  }

  .mn-wd-xl-600p {
    min-width: 600%;
  }

  .mx-wd-xl-600 {
    max-width: 600px;
  }

  .mn-wd-xl-600 {
    min-width: 600px;
  }

  .wd-xl-600-f {
    width: 600px !important;
  }

  .wd-xl-600p-f {
    width: 600% !important;
  }

  .mx-wd-xl-600p-f {
    max-width: 600% !important;
  }

  .mn-wd-xl-600p-f {
    min-width: 600% !important;
  }

  .mx-wd-xl-600-f {
    max-width: 600px !important;
  }

  .mn-wd-xl-600-f {
    min-width: 600px !important;
  }

  .wd-xl-650 {
    width: 650px;
  }

  .wd-xl-650p {
    width: 650%;
  }

  .mx-wd-xl-650p {
    max-width: 650%;
  }

  .mn-wd-xl-650p {
    min-width: 650%;
  }

  .mx-wd-xl-650 {
    max-width: 650px;
  }

  .mn-wd-xl-650 {
    min-width: 650px;
  }

  .wd-xl-650-f {
    width: 650px !important;
  }

  .wd-xl-650p-f {
    width: 650% !important;
  }

  .mx-wd-xl-650p-f {
    max-width: 650% !important;
  }

  .mn-wd-xl-650p-f {
    min-width: 650% !important;
  }

  .mx-wd-xl-650-f {
    max-width: 650px !important;
  }

  .mn-wd-xl-650-f {
    min-width: 650px !important;
  }

  .wd-xl-700 {
    width: 700px;
  }

  .wd-xl-700p {
    width: 700%;
  }

  .mx-wd-xl-700p {
    max-width: 700%;
  }

  .mn-wd-xl-700p {
    min-width: 700%;
  }

  .mx-wd-xl-700 {
    max-width: 700px;
  }

  .mn-wd-xl-700 {
    min-width: 700px;
  }

  .wd-xl-700-f {
    width: 700px !important;
  }

  .wd-xl-700p-f {
    width: 700% !important;
  }

  .mx-wd-xl-700p-f {
    max-width: 700% !important;
  }

  .mn-wd-xl-700p-f {
    min-width: 700% !important;
  }

  .mx-wd-xl-700-f {
    max-width: 700px !important;
  }

  .mn-wd-xl-700-f {
    min-width: 700px !important;
  }

  .wd-xl-750 {
    width: 750px;
  }

  .wd-xl-750p {
    width: 750%;
  }

  .mx-wd-xl-750p {
    max-width: 750%;
  }

  .mn-wd-xl-750p {
    min-width: 750%;
  }

  .mx-wd-xl-750 {
    max-width: 750px;
  }

  .mn-wd-xl-750 {
    min-width: 750px;
  }

  .wd-xl-750-f {
    width: 750px !important;
  }

  .wd-xl-750p-f {
    width: 750% !important;
  }

  .mx-wd-xl-750p-f {
    max-width: 750% !important;
  }

  .mn-wd-xl-750p-f {
    min-width: 750% !important;
  }

  .mx-wd-xl-750-f {
    max-width: 750px !important;
  }

  .mn-wd-xl-750-f {
    min-width: 750px !important;
  }

  .wd-xl-800 {
    width: 800px;
  }

  .wd-xl-800p {
    width: 800%;
  }

  .mx-wd-xl-800p {
    max-width: 800%;
  }

  .mn-wd-xl-800p {
    min-width: 800%;
  }

  .mx-wd-xl-800 {
    max-width: 800px;
  }

  .mn-wd-xl-800 {
    min-width: 800px;
  }

  .wd-xl-800-f {
    width: 800px !important;
  }

  .wd-xl-800p-f {
    width: 800% !important;
  }

  .mx-wd-xl-800p-f {
    max-width: 800% !important;
  }

  .mn-wd-xl-800p-f {
    min-width: 800% !important;
  }

  .mx-wd-xl-800-f {
    max-width: 800px !important;
  }

  .mn-wd-xl-800-f {
    min-width: 800px !important;
  }

  .wd-xl-850 {
    width: 850px;
  }

  .wd-xl-850p {
    width: 850%;
  }

  .mx-wd-xl-850p {
    max-width: 850%;
  }

  .mn-wd-xl-850p {
    min-width: 850%;
  }

  .mx-wd-xl-850 {
    max-width: 850px;
  }

  .mn-wd-xl-850 {
    min-width: 850px;
  }

  .wd-xl-850-f {
    width: 850px !important;
  }

  .wd-xl-850p-f {
    width: 850% !important;
  }

  .mx-wd-xl-850p-f {
    max-width: 850% !important;
  }

  .mn-wd-xl-850p-f {
    min-width: 850% !important;
  }

  .mx-wd-xl-850-f {
    max-width: 850px !important;
  }

  .mn-wd-xl-850-f {
    min-width: 850px !important;
  }

  .wd-xl-900 {
    width: 900px;
  }

  .wd-xl-900p {
    width: 900%;
  }

  .mx-wd-xl-900p {
    max-width: 900%;
  }

  .mn-wd-xl-900p {
    min-width: 900%;
  }

  .mx-wd-xl-900 {
    max-width: 900px;
  }

  .mn-wd-xl-900 {
    min-width: 900px;
  }

  .wd-xl-900-f {
    width: 900px !important;
  }

  .wd-xl-900p-f {
    width: 900% !important;
  }

  .mx-wd-xl-900p-f {
    max-width: 900% !important;
  }

  .mn-wd-xl-900p-f {
    min-width: 900% !important;
  }

  .mx-wd-xl-900-f {
    max-width: 900px !important;
  }

  .mn-wd-xl-900-f {
    min-width: 900px !important;
  }

  .wd-xl-950 {
    width: 950px;
  }

  .wd-xl-950p {
    width: 950%;
  }

  .mx-wd-xl-950p {
    max-width: 950%;
  }

  .mn-wd-xl-950p {
    min-width: 950%;
  }

  .mx-wd-xl-950 {
    max-width: 950px;
  }

  .mn-wd-xl-950 {
    min-width: 950px;
  }

  .wd-xl-950-f {
    width: 950px !important;
  }

  .wd-xl-950p-f {
    width: 950% !important;
  }

  .mx-wd-xl-950p-f {
    max-width: 950% !important;
  }

  .mn-wd-xl-950p-f {
    min-width: 950% !important;
  }

  .mx-wd-xl-950-f {
    max-width: 950px !important;
  }

  .mn-wd-xl-950-f {
    min-width: 950px !important;
  }

  .wd-xl-1000 {
    width: 1000px;
  }

  .wd-xl-1000p {
    width: 1000%;
  }

  .mx-wd-xl-1000p {
    max-width: 1000%;
  }

  .mn-wd-xl-1000p {
    min-width: 1000%;
  }

  .mx-wd-xl-1000 {
    max-width: 1000px;
  }

  .mn-wd-xl-1000 {
    min-width: 1000px;
  }

  .wd-xl-1000-f {
    width: 1000px !important;
  }

  .wd-xl-1000p-f {
    width: 1000% !important;
  }

  .mx-wd-xl-1000p-f {
    max-width: 1000% !important;
  }

  .mn-wd-xl-1000p-f {
    min-width: 1000% !important;
  }

  .mx-wd-xl-1000-f {
    max-width: 1000px !important;
  }

  .mn-wd-xl-1000-f {
    min-width: 1000px !important;
  }

  .wd-xl-auto {
    width: auto;
  }

  .wd-xl-auto {
    width: auto !important;
  }
}
.wd-100v {
  width: 100vw;
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30 ";
}

.fa-1::before {
  content: "\31 ";
}

.fa-2::before {
  content: "\32 ";
}

.fa-3::before {
  content: "\33 ";
}

.fa-4::before {
  content: "\34 ";
}

.fa-5::before {
  content: "\35 ";
}

.fa-6::before {
  content: "\36 ";
}

.fa-7::before {
  content: "\37 ";
}

.fa-8::before {
  content: "\38 ";
}

.fa-9::before {
  content: "\39 ";
}

.fa-00::before {
  content: "\e467";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-a::before {
  content: "A";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-accent-grave::before {
  content: "\`";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-album::before {
  content: "\f89f";
}

.fa-album-circle-plus::before {
  content: "\e48c";
}

.fa-album-circle-user::before {
  content: "\e48d";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-album-collection-circle-plus::before {
  content: "\e48e";
}

.fa-album-collection-circle-user::before {
  content: "\e48f";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-ampersand::before {
  content: "\&";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-anchor-circle-check::before {
  content: "\e4aa";
}

.fa-anchor-circle-exclamation::before {
  content: "\e4ab";
}

.fa-anchor-circle-xmark::before {
  content: "\e4ac";
}

.fa-anchor-lock::before {
  content: "\e4ad";
}

.fa-angel::before {
  content: "\f779";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-apartment::before {
  content: "\e468";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-apostrophe::before {
  content: "\'";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-archway::before {
  content: "\f557";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-arrow-down-to-arc::before {
  content: "\e4ae";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-arrow-down-up-across-line::before {
  content: "\e4af";
}

.fa-arrow-down-up-lock::before {
  content: "\e4b0";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-arrow-left-long-to-line::before {
  content: "\e3d4";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-arrow-right-from-arc::before {
  content: "\e4b1";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-arrow-right-long-to-line::before {
  content: "\e3d5";
}

.fa-arrow-right-to-arc::before {
  content: "\e4b2";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-arrow-right-to-city::before {
  content: "\e4b3";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-arrow-turn-down-right::before {
  content: "\e3d6";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-arrow-up-from-arc::before {
  content: "\e4b4";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-arrow-up-from-ground-water::before {
  content: "\e4b5";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-arrow-up-from-water-pump::before {
  content: "\e4b6";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-arrow-up-right-dots::before {
  content: "\e4b7";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-arrows-down-to-line::before {
  content: "\e4b8";
}

.fa-arrows-down-to-people::before {
  content: "\e4b9";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-arrows-left-right-to-line::before {
  content: "\e4ba";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-arrows-spin::before {
  content: "\e4bb";
}

.fa-arrows-split-up-and-left::before {
  content: "\e4bc";
}

.fa-arrows-to-circle::before {
  content: "\e4bd";
}

.fa-arrows-to-dot::before {
  content: "\e4be";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-arrows-to-eye::before {
  content: "\e4bf";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-arrows-turn-right::before {
  content: "\e4c0";
}

.fa-arrows-turn-to-dots::before {
  content: "\e4c1";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-arrows-up-to-line::before {
  content: "\e4c2";
}

.fa-asterisk::before {
  content: "\*";
}

.fa-at::before {
  content: "\@";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-award::before {
  content: "\f559";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-b::before {
  content: "B";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-badge::before {
  content: "\f335";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-badminton::before {
  content: "\e33a";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-bagel::before {
  content: "\e3d7";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-baguette::before {
  content: "\e3d8";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-baseball::before {
  content: "\f433";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-basketball::before {
  content: "\f434";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-bed::before {
  content: "\f236";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-binary::before {
  content: "\e33b";
}

.fa-binary-circle-check::before {
  content: "\e33c";
}

.fa-binary-lock::before {
  content: "\e33d";
}

.fa-binary-slash::before {
  content: "\e33e";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-bird::before {
  content: "\e469";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-blanket-fire::before {
  content: "\e3da";
}

.fa-blender::before {
  content: "\f517";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-block::before {
  content: "\e46a";
}

.fa-block-brick::before {
  content: "\e3db";
}

.fa-wall-brick::before {
  content: "\e3db";
}

.fa-block-brick-fire::before {
  content: "\e3dc";
}

.fa-firewall::before {
  content: "\e3dc";
}

.fa-block-question::before {
  content: "\e3dd";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-blog::before {
  content: "\f781";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-bold::before {
  content: "\f032";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-zap::before {
  content: "\f0e7";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-book::before {
  content: "\f02d";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-books::before {
  content: "\f5db";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-boot::before {
  content: "\f782";
}

.fa-boot-heeled::before {
  content: "\e33f";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-bore-hole::before {
  content: "\e4c3";
}

.fa-bottle-droplet::before {
  content: "\e4c4";
}

.fa-bottle-water::before {
  content: "\e4c5";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-bowl-food::before {
  content: "\e4c6";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-bowl-scoop::before {
  content: "\e3de";
}

.fa-bowl-shaved-ice::before {
  content: "\e3de";
}

.fa-bowl-scoops::before {
  content: "\e3df";
}

.fa-bowl-soft-serve::before {
  content: "\e46b";
}

.fa-bowl-spoon::before {
  content: "\e3e0";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-box::before {
  content: "\f466";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-boxes-packing::before {
  content: "\e4c7";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-bracket-curly::before {
  content: "\{";
}

.fa-bracket-curly-left::before {
  content: "\{";
}

.fa-bracket-curly-right::before {
  content: "\}";
}

.fa-bracket-round::before {
  content: "\(";
}

.fa-parenthesis::before {
  content: "\(";
}

.fa-bracket-round-right::before {
  content: "\)";
}

.fa-bracket-square::before {
  content: "\[";
}

.fa-bracket::before {
  content: "\[";
}

.fa-bracket-left::before {
  content: "\[";
}

.fa-bracket-square-right::before {
  content: "\]";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-brazilian-real-sign::before {
  content: "\e46c";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-bread-slice-butter::before {
  content: "\e3e1";
}

.fa-bridge::before {
  content: "\e4c8";
}

.fa-bridge-circle-check::before {
  content: "\e4c9";
}

.fa-bridge-circle-exclamation::before {
  content: "\e4ca";
}

.fa-bridge-circle-xmark::before {
  content: "\e4cb";
}

.fa-bridge-lock::before {
  content: "\e4cc";
}

.fa-bridge-suspension::before {
  content: "\e4cd";
}

.fa-bridge-water::before {
  content: "\e4ce";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-broccoli::before {
  content: "\e3e2";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-bucket::before {
  content: "\e4cf";
}

.fa-bug::before {
  content: "\f188";
}

.fa-bug-slash::before {
  content: "\e490";
}

.fa-bugs::before {
  content: "\e4d0";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-building-circle-arrow-right::before {
  content: "\e4d1";
}

.fa-building-circle-check::before {
  content: "\e4d2";
}

.fa-building-circle-exclamation::before {
  content: "\e4d3";
}

.fa-building-circle-xmark::before {
  content: "\e4d4";
}

.fa-building-columns::before {
  content: "\f19c";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-museum::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-building-flag::before {
  content: "\e4d5";
}

.fa-building-lock::before {
  content: "\e4d6";
}

.fa-building-ngo::before {
  content: "\e4d7";
}

.fa-building-shield::before {
  content: "\e4d8";
}

.fa-building-un::before {
  content: "\e4d9";
}

.fa-building-user::before {
  content: "\e4da";
}

.fa-building-wheat::before {
  content: "\e4db";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-burger-lettuce::before {
  content: "\e3e3";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-burst::before {
  content: "\e4dc";
}

.fa-bus::before {
  content: "\f207";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-butter::before {
  content: "\e3e4";
}

.fa-c::before {
  content: "C";
}

.fa-cabin::before {
  content: "\e46d";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-cable-car::before {
  content: "\e0cf";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-cake::before {
  content: "\f1fd";
}

.fa-cake-slice::before {
  content: "\e3e5";
}

.fa-shortcake::before {
  content: "\e3e5";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-calendar-circle-exclamation::before {
  content: "\e46e";
}

.fa-calendar-circle-minus::before {
  content: "\e46f";
}

.fa-calendar-circle-plus::before {
  content: "\e470";
}

.fa-calendar-circle-user::before {
  content: "\e471";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-calendar-lines-pen::before {
  content: "\e472";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-screenshot::before {
  content: "\e0da";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-can-food::before {
  content: "\e3e6";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-candy::before {
  content: "\e3e7";
}

.fa-candy-bar::before {
  content: "\e3e8";
}

.fa-chocolate-bar::before {
  content: "\e3e8";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-car-bolt::before {
  content: "\e341";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-car-burst::before {
  content: "\f5e1";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-car-circle-bolt::before {
  content: "\e342";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-car-mirrors::before {
  content: "\e343";
}

.fa-car-on::before {
  content: "\e4dd";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-car-side-bolt::before {
  content: "\e344";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-car-tunnel::before {
  content: "\e4de";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-card-club::before {
  content: "\e3e9";
}

.fa-card-diamond::before {
  content: "\e3ea";
}

.fa-card-heart::before {
  content: "\e3eb";
}

.fa-card-spade::before {
  content: "\e3ec";
}

.fa-cards::before {
  content: "\e3ed";
}

.fa-cards-blank::before {
  content: "\e4df";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-cart-arrow-up::before {
  content: "\e3ee";
}

.fa-cart-circle-arrow-down::before {
  content: "\e3ef";
}

.fa-cart-circle-arrow-up::before {
  content: "\e3f0";
}

.fa-cart-circle-check::before {
  content: "\e3f1";
}

.fa-cart-circle-exclamation::before {
  content: "\e3f2";
}

.fa-cart-circle-plus::before {
  content: "\e3f3";
}

.fa-cart-circle-xmark::before {
  content: "\e3f4";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-cent-sign::before {
  content: "\e3f5";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-chart-simple::before {
  content: "\e473";
}

.fa-chart-simple-horizontal::before {
  content: "\e474";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-check::before {
  content: "\f00c";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-chess::before {
  content: "\f439";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-chestnut::before {
  content: "\e3f6";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-child-dress::before {
  content: "\e59c";
}

.fa-child-reaching::before {
  content: "\e59d";
}

.fa-child-rifle::before {
  content: "\e4e0";
}

.fa-children::before {
  content: "\e4e1";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-chopsticks::before {
  content: "\e3f7";
}

.fa-church::before {
  content: "\f51d";
}

.fa-circle::before {
  content: "\f111";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-circle-nodes::before {
  content: "\e4e2";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-circle-quarters::before {
  content: "\e3f8";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-city::before {
  content: "\f64f";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-clipboard-question::before {
  content: "\e4e3";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-four::before {
  content: "\f017";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-clock-eight::before {
  content: "\e345";
}

.fa-clock-eight-thirty::before {
  content: "\e346";
}

.fa-clock-eleven::before {
  content: "\e347";
}

.fa-clock-eleven-thirty::before {
  content: "\e348";
}

.fa-clock-five::before {
  content: "\e349";
}

.fa-clock-five-thirty::before {
  content: "\e34a";
}

.fa-clock-four-thirty::before {
  content: "\e34b";
}

.fa-clock-nine::before {
  content: "\e34c";
}

.fa-clock-nine-thirty::before {
  content: "\e34d";
}

.fa-clock-one::before {
  content: "\e34e";
}

.fa-clock-one-thirty::before {
  content: "\e34f";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-clock-seven::before {
  content: "\e350";
}

.fa-clock-seven-thirty::before {
  content: "\e351";
}

.fa-clock-six::before {
  content: "\e352";
}

.fa-clock-six-thirty::before {
  content: "\e353";
}

.fa-clock-ten::before {
  content: "\e354";
}

.fa-clock-ten-thirty::before {
  content: "\e355";
}

.fa-clock-three::before {
  content: "\e356";
}

.fa-clock-three-thirty::before {
  content: "\e357";
}

.fa-clock-twelve::before {
  content: "\e358";
}

.fa-clock-twelve-thirty::before {
  content: "\e359";
}

.fa-clock-two::before {
  content: "\e35a";
}

.fa-clock-two-thirty::before {
  content: "\e35b";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-cloud-check::before {
  content: "\e35c";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-cloud-exclamation::before {
  content: "\e491";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-cloud-minus::before {
  content: "\e35d";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-cloud-plus::before {
  content: "\e35e";
}

.fa-cloud-question::before {
  content: "\e492";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-cloud-showers-water::before {
  content: "\e4e4";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-cloud-xmark::before {
  content: "\e35f";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-clover::before {
  content: "\e139";
}

.fa-club::before {
  content: "\f327";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-code::before {
  content: "\f121";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-code-pull-request-closed::before {
  content: "\e3f9";
}

.fa-code-pull-request-draft::before {
  content: "\e3fa";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-coin-blank::before {
  content: "\e3fb";
}

.fa-coin-front::before {
  content: "\e3fc";
}

.fa-coin-vertical::before {
  content: "\e3fd";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-colon::before {
  content: "\:";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-columns-3::before {
  content: "\e361";
}

.fa-comet::before {
  content: "\e003";
}

.fa-comma::before {
  content: "\,";
}

.fa-command::before {
  content: "\e142";
}

.fa-comment::before {
  content: "\f075";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-comments::before {
  content: "\f086";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-compress::before {
  content: "\f066";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-computer::before {
  content: "\e4e5";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-corner::before {
  content: "\e3fe";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-crab::before {
  content: "\e3ff";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-crop::before {
  content: "\f125";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-cross::before {
  content: "\f654";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-crow::before {
  content: "\f520";
}

.fa-crown::before {
  content: "\f521";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-crystal-ball::before {
  content: "\e362";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-cubes-stacked::before {
  content: "\e4e6";
}

.fa-cucumber::before {
  content: "\e401";
}

.fa-cup-straw::before {
  content: "\e363";
}

.fa-cup-straw-swoosh::before {
  content: "\e364";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-cupcake::before {
  content: "\e402";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-custard::before {
  content: "\e403";
}

.fa-d::before {
  content: "D";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-dash::before {
  content: "\e404";
}

.fa-minus-large::before {
  content: "\e404";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-desktop::before {
  content: "\f390";
}

.fa-desktop-alt::before {
  content: "\f390";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-diagram-cells::before {
  content: "\e475";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-diagram-next::before {
  content: "\e476";
}

.fa-diagram-predecessor::before {
  content: "\e477";
}

.fa-diagram-previous::before {
  content: "\e478";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-diagram-subtask::before {
  content: "\e479";
}

.fa-diagram-successor::before {
  content: "\e47a";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-diamond-exclamation::before {
  content: "\e405";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-dice::before {
  content: "\f522";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-display::before {
  content: "\e163";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-distribute-spacing-horizontal::before {
  content: "\e365";
}

.fa-distribute-spacing-vertical::before {
  content: "\e366";
}

.fa-ditto::before {
  content: "\"";
}

.fa-divide::before {
  content: "\f529";
}

.fa-dna::before {
  content: "\f471";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-dollar-sign::before {
  content: "\$";
}

.fa-dollar::before {
  content: "\$";
}

.fa-usd::before {
  content: "\$";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-donut::before {
  content: "\e406";
}

.fa-doughnut::before {
  content: "\e406";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-down-from-dotted-line::before {
  content: "\e407";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-down-to-bracket::before {
  content: "\e4e7";
}

.fa-down-to-dotted-line::before {
  content: "\e408";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-download::before {
  content: "\f019";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-drum::before {
  content: "\f569";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-e::before {
  content: "E";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-earth-america::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-earth-europe::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-earth-oceania::before {
  content: "\e47b";
}

.fa-globe-oceania::before {
  content: "\e47b";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-eject::before {
  content: "\f052";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-envelope-circle-check::before {
  content: "\e4e8";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-equals::before {
  content: "\=";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-exclamation::before {
  content: "\!";
}

.fa-expand::before {
  content: "\f065";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-explosion::before {
  content: "\e4e9";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-eyes::before {
  content: "\e367";
}

.fa-f::before {
  content: "F";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-face-angry-horns::before {
  content: "\e368";
}

.fa-face-anguished::before {
  content: "\e369";
}

.fa-face-anxious-sweat::before {
  content: "\e36a";
}

.fa-face-astonished::before {
  content: "\e36b";
}

.fa-face-awesome::before {
  content: "\e409";
}

.fa-gave-dandy::before {
  content: "\e409";
}

.fa-face-beam-hand-over-mouth::before {
  content: "\e47c";
}

.fa-face-clouds::before {
  content: "\e47d";
}

.fa-face-confounded::before {
  content: "\e36c";
}

.fa-face-confused::before {
  content: "\e36d";
}

.fa-face-cowboy-hat::before {
  content: "\e36e";
}

.fa-face-diagonal-mouth::before {
  content: "\e47e";
}

.fa-face-disappointed::before {
  content: "\e36f";
}

.fa-face-disguise::before {
  content: "\e370";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-face-dotted::before {
  content: "\e47f";
}

.fa-face-downcast-sweat::before {
  content: "\e371";
}

.fa-face-drooling::before {
  content: "\e372";
}

.fa-face-exhaling::before {
  content: "\e480";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-face-expressionless::before {
  content: "\e373";
}

.fa-face-eyes-xmarks::before {
  content: "\e374";
}

.fa-face-fearful::before {
  content: "\e375";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-face-frown-slight::before {
  content: "\e376";
}

.fa-face-glasses::before {
  content: "\e377";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-face-hand-over-mouth::before {
  content: "\e378";
}

.fa-face-hand-peeking::before {
  content: "\e481";
}

.fa-face-hand-yawn::before {
  content: "\e379";
}

.fa-face-head-bandage::before {
  content: "\e37a";
}

.fa-face-holding-back-tears::before {
  content: "\e482";
}

.fa-face-hushed::before {
  content: "\e37b";
}

.fa-face-icicles::before {
  content: "\e37c";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-face-kiss-closed-eyes::before {
  content: "\e37d";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-face-lying::before {
  content: "\e37e";
}

.fa-face-mask::before {
  content: "\e37f";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-face-melting::before {
  content: "\e483";
}

.fa-face-monocle::before {
  content: "\e380";
}

.fa-face-nauseated::before {
  content: "\e381";
}

.fa-face-nose-steam::before {
  content: "\e382";
}

.fa-face-party::before {
  content: "\e383";
}

.fa-face-pensive::before {
  content: "\e384";
}

.fa-face-persevering::before {
  content: "\e385";
}

.fa-face-pleading::before {
  content: "\e386";
}

.fa-face-pouting::before {
  content: "\e387";
}

.fa-face-raised-eyebrow::before {
  content: "\e388";
}

.fa-face-relieved::before {
  content: "\e389";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-face-sad-sweat::before {
  content: "\e38a";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-face-saluting::before {
  content: "\e484";
}

.fa-face-scream::before {
  content: "\e38b";
}

.fa-face-shush::before {
  content: "\e38c";
}

.fa-face-sleeping::before {
  content: "\e38d";
}

.fa-face-sleepy::before {
  content: "\e38e";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-face-smile-halo::before {
  content: "\e38f";
}

.fa-face-smile-hearts::before {
  content: "\e390";
}

.fa-face-smile-horns::before {
  content: "\e391";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-face-smile-relaxed::before {
  content: "\e392";
}

.fa-face-smile-tear::before {
  content: "\e393";
}

.fa-face-smile-tongue::before {
  content: "\e394";
}

.fa-face-smile-upside-down::before {
  content: "\e395";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-face-smiling-hands::before {
  content: "\e396";
}

.fa-face-smirking::before {
  content: "\e397";
}

.fa-face-spiral-eyes::before {
  content: "\e485";
}

.fa-face-sunglasses::before {
  content: "\e398";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-face-swear::before {
  content: "\e399";
}

.fa-face-thermometer::before {
  content: "\e39a";
}

.fa-face-thinking::before {
  content: "\e39b";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-face-tissue::before {
  content: "\e39c";
}

.fa-face-tongue-money::before {
  content: "\e39d";
}

.fa-face-tongue-sweat::before {
  content: "\e39e";
}

.fa-face-unamused::before {
  content: "\e39f";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-face-vomit::before {
  content: "\e3a0";
}

.fa-face-weary::before {
  content: "\e3a1";
}

.fa-face-woozy::before {
  content: "\e3a2";
}

.fa-face-worried::before {
  content: "\e3a3";
}

.fa-face-zany::before {
  content: "\e3a4";
}

.fa-face-zipper::before {
  content: "\e3a5";
}

.fa-falafel::before {
  content: "\e40a";
}

.fa-family::before {
  content: "\e300";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-fan::before {
  content: "\f863";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-fence::before {
  content: "\e303";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-ferry::before {
  content: "\e4ea";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-file-circle-check::before {
  content: "\e493";
}

.fa-file-circle-exclamation::before {
  content: "\e4eb";
}

.fa-file-circle-info::before {
  content: "\e4ec";
}

.fa-file-circle-minus::before {
  content: "\e4ed";
}

.fa-file-circle-plus::before {
  content: "\e4ee";
}

.fa-file-circle-question::before {
  content: "\e4ef";
}

.fa-file-circle-xmark::before {
  content: "\e494";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-file-lock::before {
  content: "\e3a6";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-file-shield::before {
  content: "\e4f0";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-file-slash::before {
  content: "\e3a7";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-files::before {
  content: "\e178";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-fill::before {
  content: "\f575";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-film::before {
  content: "\f008";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-films::before {
  content: "\e17a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-fire-burner::before {
  content: "\e4f1";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-fish::before {
  content: "\f578";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-fish-fins::before {
  content: "\e4f2";
}

.fa-fishing-rod::before {
  content: "\e3a8";
}

.fa-flag::before {
  content: "\f024";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-flask-vial::before {
  content: "\e4f3";
}

.fa-flatbread::before {
  content: "\e40b";
}

.fa-flatbread-stuffed::before {
  content: "\e40c";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-flying-disc::before {
  content: "\e3a9";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-blank::before {
  content: "\f07b";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-folder-closed::before {
  content: "\e185";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-folders::before {
  content: "\f660";
}

.fa-fondue-pot::before {
  content: "\e40d";
}

.fa-font::before {
  content: "\f031";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-football::before {
  content: "\f44e";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-fort::before {
  content: "\e486";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-frame::before {
  content: "\e495";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-function::before {
  content: "\f661";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-g::before {
  content: "G";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-gallery-thumbnails::before {
  content: "\e3aa";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-gamepad-modern::before {
  content: "\f8bc";
}

.fa-gamepad-alt::before {
  content: "\f8bc";
}

.fa-garage::before {
  content: "\e009";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-garlic::before {
  content: "\e40e";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-gauge::before {
  content: "\f624";
}

.fa-dashboard::before {
  content: "\f624";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-gauge-circle-bolt::before {
  content: "\e496";
}

.fa-gauge-circle-minus::before {
  content: "\e497";
}

.fa-gauge-circle-plus::before {
  content: "\e498";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-gauge-simple::before {
  content: "\f629";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-tachometer-fast::before {
  content: "\f62a";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-gif::before {
  content: "\e190";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-glass::before {
  content: "\f804";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-glass-water::before {
  content: "\e4f4";
}

.fa-glass-water-droplet::before {
  content: "\e4f5";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-goal-net::before {
  content: "\e3ab";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-golf-flag-hole::before {
  content: "\e3ac";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-grate::before {
  content: "\e193";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-greater-than::before {
  content: "\>";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-grid-dividers::before {
  content: "\e3ad";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-grip-dots::before {
  content: "\e410";
}

.fa-grip-dots-vertical::before {
  content: "\e411";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-group-arrows-rotate::before {
  content: "\e4f6";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-h::before {
  content: "H";
}

.fa-h1::before {
  content: "\f313";
}

.fa-h2::before {
  content: "\f314";
}

.fa-h3::before {
  content: "\f315";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-h5::before {
  content: "\e412";
}

.fa-h6::before {
  content: "\e413";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-hammer-crash::before {
  content: "\e414";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-hand::before {
  content: "\f256";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-hand-back-fist::before {
  content: "\f255";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-hand-holding-hand::before {
  content: "\e4f7";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-handcuffs::before {
  content: "\e4f8";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-hands-bound::before {
  content: "\e4f9";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-hands-holding-child::before {
  content: "\e4fa";
}

.fa-hands-holding-circle::before {
  content: "\e4fb";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-hashtag::before {
  content: "\#";
}

.fa-hashtag-lock::before {
  content: "\e415";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-headset::before {
  content: "\f590";
}

.fa-heart::before {
  content: "\f004";
}

.fa-heart-circle-bolt::before {
  content: "\e4fc";
}

.fa-heart-circle-check::before {
  content: "\e4fd";
}

.fa-heart-circle-exclamation::before {
  content: "\e4fe";
}

.fa-heart-circle-minus::before {
  content: "\e4ff";
}

.fa-heart-circle-plus::before {
  content: "\e500";
}

.fa-heart-circle-xmark::before {
  content: "\e501";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-helicopter-symbol::before {
  content: "\e502";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-helmet-un::before {
  content: "\e503";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-hexagon-check::before {
  content: "\e416";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-hexagon-exclamation::before {
  content: "\e417";
}

.fa-hexagon-image::before {
  content: "\e504";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-hexagon-vertical-nft::before {
  content: "\e505";
}

.fa-hexagon-vertical-nft-slanted::before {
  content: "\e506";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-hill-avalanche::before {
  content: "\e507";
}

.fa-hill-rockslide::before {
  content: "\e508";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-hockey-stick-puck::before {
  content: "\e3ae";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-honey-pot::before {
  content: "\e418";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-hose::before {
  content: "\e419";
}

.fa-hose-reel::before {
  content: "\e41a";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-alt::before {
  content: "\f0f8";
}

.fa-hospital-wide::before {
  content: "\f0f8";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-2::before {
  content: "\f254";
}

.fa-hourglass-half::before {
  content: "\f254";
}

.fa-hourglass-clock::before {
  content: "\e41b";
}

.fa-hourglass-empty::before {
  content: "\f252";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-house::before {
  content: "\f015";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-alt::before {
  content: "\f015";
}

.fa-home-lg-alt::before {
  content: "\f015";
}

.fa-house-blank::before {
  content: "\e487";
}

.fa-home-blank::before {
  content: "\e487";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-house-chimney::before {
  content: "\e3af";
}

.fa-home-lg::before {
  content: "\e3af";
}

.fa-house-chimney-blank::before {
  content: "\e3b0";
}

.fa-house-chimney-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-house-chimney-heart::before {
  content: "\e1b2";
}

.fa-house-chimney-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-house-chimney-user::before {
  content: "\e065";
}

.fa-house-chimney-window::before {
  content: "\e00d";
}

.fa-house-circle-check::before {
  content: "\e509";
}

.fa-house-circle-exclamation::before {
  content: "\e50a";
}

.fa-house-circle-xmark::before {
  content: "\e50b";
}

.fa-house-crack::before {
  content: "\e3b1";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-house-fire::before {
  content: "\e50c";
}

.fa-house-flag::before {
  content: "\e50d";
}

.fa-house-flood-water::before {
  content: "\e50e";
}

.fa-house-flood-water-circle-arrow-right::before {
  content: "\e50f";
}

.fa-house-heart::before {
  content: "\f4c9";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-house-lock::before {
  content: "\e510";
}

.fa-house-medical::before {
  content: "\e3b2";
}

.fa-house-medical-circle-check::before {
  content: "\e511";
}

.fa-house-medical-circle-exclamation::before {
  content: "\e512";
}

.fa-house-medical-circle-xmark::before {
  content: "\e513";
}

.fa-house-medical-flag::before {
  content: "\e514";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-return::before {
  content: "\e011";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-house-tsunami::before {
  content: "\e515";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-house-user::before {
  content: "\e1b0";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-house-water::before {
  content: "\f74f";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-house-window::before {
  content: "\e3b3";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-hundred-points::before {
  content: "\e41c";
}

.fa-100::before {
  content: "\e41c";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-hyphen::before {
  content: "\-";
}

.fa-i::before {
  content: "I";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-image::before {
  content: "\f03e";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-images::before {
  content: "\f302";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-industry::before {
  content: "\f275";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-info::before {
  content: "\f129";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-integral::before {
  content: "\f667";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-italic::before {
  content: "\f033";
}

.fa-j::before {
  content: "J";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-jar::before {
  content: "\e516";
}

.fa-jar-wheat::before {
  content: "\e517";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-jet-fighter-up::before {
  content: "\e518";
}

.fa-joint::before {
  content: "\f595";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-jug-detergent::before {
  content: "\e519";
}

.fa-k::before {
  content: "K";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-key::before {
  content: "\f084";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-key-skeleton-left-right::before {
  content: "\e3b4";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-kitchen-set::before {
  content: "\e51a";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-l::before {
  content: "L";
}

.fa-lacrosse-stick::before {
  content: "\e3b5";
}

.fa-lacrosse-stick-ball::before {
  content: "\e3b6";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-land-mine-on::before {
  content: "\e51b";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-landmark-flag::before {
  content: "\e51c";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-laptop-file::before {
  content: "\e51d";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-leafy-green::before {
  content: "\e41d";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-left-long-to-line::before {
  content: "\e41e";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-less-than::before {
  content: "\<";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-light-emergency::before {
  content: "\e41f";
}

.fa-light-emergency-on::before {
  content: "\e420";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-lines-leaning::before {
  content: "\e51e";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-lips::before {
  content: "\f600";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-lobster::before {
  content: "\e421";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location::before {
  content: "\f601";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-slash::before {
  content: "\f603";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-location-pin::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-location-pin-lock::before {
  content: "\e51f";
}

.fa-location-pin-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-lock::before {
  content: "\f023";
}

.fa-lock-a::before {
  content: "\e422";
}

.fa-lock-hashtag::before {
  content: "\e423";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-locust::before {
  content: "\e520";
}

.fa-lollipop::before {
  content: "\e424";
}

.fa-lollypop::before {
  content: "\e424";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-m::before {
  content: "M";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-magnifying-glass-arrow-right::before {
  content: "\e521";
}

.fa-magnifying-glass-chart::before {
  content: "\e522";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-map::before {
  content: "\f279";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-mars::before {
  content: "\f222";
}

.fa-mars-and-venus::before {
  content: "\f224";
}

.fa-mars-and-venus-burst::before {
  content: "\e523";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-mask-snorkel::before {
  content: "\e3b7";
}

.fa-mask-ventilator::before {
  content: "\e524";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-mattress-pillow::before {
  content: "\e525";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-meat::before {
  content: "\f814";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-melon::before {
  content: "\e310";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-memo-circle-info::before {
  content: "\e49a";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-memory::before {
  content: "\f538";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-merge::before {
  content: "\e526";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-message-bot::before {
  content: "\e3b8";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-mobile-retro::before {
  content: "\e527";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-money-bill-transfer::before {
  content: "\e528";
}

.fa-money-bill-trend-up::before {
  content: "\e529";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-money-bill-wheat::before {
  content: "\e52a";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-moon::before {
  content: "\f186";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-moped::before {
  content: "\e3b9";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-mosquito::before {
  content: "\e52b";
}

.fa-mosquito-net::before {
  content: "\e52c";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-mound::before {
  content: "\e52d";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-mountain-city::before {
  content: "\e52e";
}

.fa-mountain-sun::before {
  content: "\e52f";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-mug::before {
  content: "\f874";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-mushroom::before {
  content: "\e425";
}

.fa-music::before {
  content: "\f001";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-n::before {
  content: "N";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-nesting-dolls::before {
  content: "\e3ba";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-notes::before {
  content: "\e202";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-o::before {
  content: "O";
}

.fa-object-exclude::before {
  content: "\e49c";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-object-intersect::before {
  content: "\e49d";
}

.fa-object-subtract::before {
  content: "\e49e";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-object-union::before {
  content: "\e49f";
}

.fa-objects-align-bottom::before {
  content: "\e3bb";
}

.fa-objects-align-center-horizontal::before {
  content: "\e3bc";
}

.fa-objects-align-center-vertical::before {
  content: "\e3bd";
}

.fa-objects-align-left::before {
  content: "\e3be";
}

.fa-objects-align-right::before {
  content: "\e3bf";
}

.fa-objects-align-top::before {
  content: "\e3c0";
}

.fa-objects-column::before {
  content: "\e3c1";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-octagon-check::before {
  content: "\e426";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-oil-well::before {
  content: "\e532";
}

.fa-olive::before {
  content: "\e316";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-om::before {
  content: "\f679";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-onion::before {
  content: "\e427";
}

.fa-option::before {
  content: "\e318";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-otter::before {
  content: "\f700";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-overline::before {
  content: "\f876";
}

.fa-p::before {
  content: "P";
}

.fa-page::before {
  content: "\e428";
}

.fa-page-caret-down::before {
  content: "\e429";
}

.fa-file-caret-down::before {
  content: "\e429";
}

.fa-page-caret-up::before {
  content: "\e42a";
}

.fa-file-caret-up::before {
  content: "\e42a";
}

.fa-pager::before {
  content: "\f815";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-paintbrush::before {
  content: "\f1fc";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-paintbrush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paintbrush-alt::before {
  content: "\f5a9";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-pan-food::before {
  content: "\e42b";
}

.fa-pan-frying::before {
  content: "\e42c";
}

.fa-pancakes::before {
  content: "\e42d";
}

.fa-panel-ews::before {
  content: "\e42e";
}

.fa-panel-fire::before {
  content: "\e42f";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-paperclip-vertical::before {
  content: "\e3c2";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-peanut::before {
  content: "\e430";
}

.fa-peanuts::before {
  content: "\e431";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-pen::before {
  content: "\f304";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-pen-nib-slash::before {
  content: "\e4a1";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-pencil-alt::before {
  content: "\f303";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-people::before {
  content: "\e216";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-people-group::before {
  content: "\e533";
}

.fa-people-line::before {
  content: "\e534";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-people-pulling::before {
  content: "\e535";
}

.fa-people-robbery::before {
  content: "\e536";
}

.fa-people-roof::before {
  content: "\e537";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-pepper::before {
  content: "\e432";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-percent::before {
  content: "\%";
}

.fa-percentage::before {
  content: "\%";
}

.fa-period::before {
  content: "\.";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-person-arrow-down-to-line::before {
  content: "\e538";
}

.fa-person-arrow-up-from-line::before {
  content: "\e539";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-person-breastfeeding::before {
  content: "\e53a";
}

.fa-person-burst::before {
  content: "\e53b";
}

.fa-person-cane::before {
  content: "\e53c";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-person-chalkboard::before {
  content: "\e53d";
}

.fa-person-circle-check::before {
  content: "\e53e";
}

.fa-person-circle-exclamation::before {
  content: "\e53f";
}

.fa-person-circle-minus::before {
  content: "\e540";
}

.fa-person-circle-plus::before {
  content: "\e541";
}

.fa-person-circle-question::before {
  content: "\e542";
}

.fa-person-circle-xmark::before {
  content: "\e543";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-person-dress-burst::before {
  content: "\e544";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-person-drowning::before {
  content: "\e545";
}

.fa-person-falling::before {
  content: "\e546";
}

.fa-person-falling-burst::before {
  content: "\e547";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-person-half-dress::before {
  content: "\e548";
}

.fa-person-harassing::before {
  content: "\e549";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-person-military-pointing::before {
  content: "\e54a";
}

.fa-person-military-rifle::before {
  content: "\e54b";
}

.fa-person-military-to-person::before {
  content: "\e54c";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-person-rays::before {
  content: "\e54d";
}

.fa-person-rifle::before {
  content: "\e54e";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-person-shelter::before {
  content: "\e54f";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-person-through-window::before {
  content: "\e433";
}

.fa-person-to-door::before {
  content: "\e550";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-person-walking-arrow-loop-left::before {
  content: "\e551";
}

.fa-person-walking-arrow-right::before {
  content: "\e552";
}

.fa-person-walking-dashed-line-arrow-right::before {
  content: "\e553";
}

.fa-person-walking-luggage::before {
  content: "\e554";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-phone::before {
  content: "\f095";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-phone-intercom::before {
  content: "\e434";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-pickleball::before {
  content: "\e435";
}

.fa-pie::before {
  content: "\f705";
}

.fa-pig::before {
  content: "\f706";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-pills::before {
  content: "\f484";
}

.fa-pinata::before {
  content: "\e3c3";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-pipe::before {
  content: "\|";
}

.fa-pipe-circle-check::before {
  content: "\e436";
}

.fa-pipe-collar::before {
  content: "\e437";
}

.fa-pipe-section::before {
  content: "\e438";
}

.fa-pipe-smoking::before {
  content: "\e3c4";
}

.fa-pipe-valve::before {
  content: "\e439";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-plane::before {
  content: "\f072";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-plane-circle-check::before {
  content: "\e555";
}

.fa-plane-circle-exclamation::before {
  content: "\e556";
}

.fa-plane-circle-xmark::before {
  content: "\e557";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-plane-lock::before {
  content: "\e558";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-plant-wilt::before {
  content: "\e43b";
}

.fa-plate-utensils::before {
  content: "\e559";
}

.fa-plate-wheat::before {
  content: "\e55a";
}

.fa-play::before {
  content: "\f04b";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-plug-circle-bolt::before {
  content: "\e55b";
}

.fa-plug-circle-check::before {
  content: "\e55c";
}

.fa-plug-circle-exclamation::before {
  content: "\e55d";
}

.fa-plug-circle-minus::before {
  content: "\e55e";
}

.fa-plug-circle-plus::before {
  content: "\e55f";
}

.fa-plug-circle-xmark::before {
  content: "\e560";
}

.fa-plus::before {
  content: "\+";
}

.fa-add::before {
  content: "\+";
}

.fa-plus-large::before {
  content: "\e59e";
}

.fa-plus-minus::before {
  content: "\e43c";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-podium::before {
  content: "\f680";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-pompebled::before {
  content: "\e43d";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-pool-8-ball::before {
  content: "\e3c5";
}

.fa-poop::before {
  content: "\f619";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-popsicle::before {
  content: "\e43e";
}

.fa-pot-food::before {
  content: "\e43f";
}

.fa-potato::before {
  content: "\e440";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-pretzel::before {
  content: "\e441";
}

.fa-print::before {
  content: "\f02f";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-pump::before {
  content: "\e442";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-puzzle::before {
  content: "\e443";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-q::before {
  content: "Q";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-question::before {
  content: "\?";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-r::before {
  content: "R";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-radar::before {
  content: "\e024";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-ranking-star::before {
  content: "\e561";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-rectangle-history::before {
  content: "\e4a2";
}

.fa-rectangle-history-circle-plus::before {
  content: "\e4a3";
}

.fa-rectangle-history-circle-user::before {
  content: "\e4a4";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-reel::before {
  content: "\e238";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-right-long-to-line::before {
  content: "\e444";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-road::before {
  content: "\f018";
}

.fa-road-barrier::before {
  content: "\e562";
}

.fa-road-bridge::before {
  content: "\e563";
}

.fa-road-circle-check::before {
  content: "\e564";
}

.fa-road-circle-exclamation::before {
  content: "\e565";
}

.fa-road-circle-xmark::before {
  content: "\e566";
}

.fa-road-lock::before {
  content: "\e567";
}

.fa-road-spikes::before {
  content: "\e568";
}

.fa-robot::before {
  content: "\f544";
}

.fa-robot-astromech::before {
  content: "\e2d2";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-rug::before {
  content: "\e569";
}

.fa-rugby-ball::before {
  content: "\e3c6";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-s::before {
  content: "S";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-sack-xmark::before {
  content: "\e56a";
}

.fa-sailboat::before {
  content: "\e445";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-salt-shaker::before {
  content: "\e446";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-scanner::before {
  content: "\f8f3";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-school::before {
  content: "\f549";
}

.fa-school-circle-check::before {
  content: "\e56b";
}

.fa-school-circle-exclamation::before {
  content: "\e56c";
}

.fa-school-circle-xmark::before {
  content: "\e56d";
}

.fa-school-flag::before {
  content: "\e56e";
}

.fa-school-lock::before {
  content: "\e56f";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-seal::before {
  content: "\e241";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-section::before {
  content: "\e447";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-semicolon::before {
  content: "\;";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-server::before {
  content: "\f233";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-sheet-plastic::before {
  content: "\e571";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f132";
}

.fa-shield-cat::before {
  content: "\e572";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-shield-dog::before {
  content: "\e573";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-shield-halved::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-shield-heart::before {
  content: "\e574";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-shield-quartered::before {
  content: "\e575";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-shirt::before {
  content: "\f553";
}

.fa-t-shirt::before {
  content: "\f553";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-shirt-long-sleeve::before {
  content: "\e3c7";
}

.fa-shirt-running::before {
  content: "\e3c8";
}

.fa-shirt-tank-top::before {
  content: "\e3c9";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-shop-lock::before {
  content: "\e4a5";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-shrimp::before {
  content: "\e448";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-shutters::before {
  content: "\e449";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-signature-lock::before {
  content: "\e3ca";
}

.fa-signature-slash::before {
  content: "\e3cb";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-ski-boot::before {
  content: "\e3cc";
}

.fa-ski-boot-ski::before {
  content: "\e3cd";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-slash::before {
  content: "\f715";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-slash-forward::before {
  content: "\/";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-slider::before {
  content: "\e252";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-slot-machine::before {
  content: "\e3ce";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-snake::before {
  content: "\f716";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-socks::before {
  content: "\f696";
}

.fa-soft-serve::before {
  content: "\e400";
}

.fa-creemee::before {
  content: "\e400";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-spider::before {
  content: "\f717";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-split::before {
  content: "\e254";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-sportsball::before {
  content: "\e44b";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-sprinkler-ceiling::before {
  content: "\e44c";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-square-a-lock::before {
  content: "\e44d";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-square-kanban::before {
  content: "\e488";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-square-list::before {
  content: "\e489";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-square-nfi::before {
  content: "\e576";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-square-person-confined::before {
  content: "\e577";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-square-quarters::before {
  content: "\e44e";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-square-ring::before {
  content: "\e44f";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-square-virus::before {
  content: "\e578";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-squid::before {
  content: "\e450";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-staff-aesculapius::before {
  content: "\e579";
}

.fa-rod-asclepius::before {
  content: "\e579";
}

.fa-rod-snake::before {
  content: "\e579";
}

.fa-staff-snake::before {
  content: "\e579";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-star::before {
  content: "\f005";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-stars::before {
  content: "\f762";
}

.fa-starship::before {
  content: "\e039";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-steak::before {
  content: "\f824";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-store::before {
  content: "\f54e";
}

.fa-store-lock::before {
  content: "\e4a6";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-sun::before {
  content: "\f185";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-sun-plant-wilt::before {
  content: "\e57a";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-sushi::before {
  content: "\e48a";
}

.fa-nigiri::before {
  content: "\e48a";
}

.fa-sushi-roll::before {
  content: "\e48b";
}

.fa-maki-roll::before {
  content: "\e48b";
}

.fa-makizushi::before {
  content: "\e48b";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-t::before {
  content: "T";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-taco::before {
  content: "\f826";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-tamale::before {
  content: "\e451";
}

.fa-tank-water::before {
  content: "\e452";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-tarp::before {
  content: "\e57b";
}

.fa-tarp-droplet::before {
  content: "\e57c";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-teddy-bear::before {
  content: "\e3cf";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-tent::before {
  content: "\e57d";
}

.fa-tent-arrow-down-to-line::before {
  content: "\e57e";
}

.fa-tent-arrow-left-right::before {
  content: "\e57f";
}

.fa-tent-arrow-turn-left::before {
  content: "\e580";
}

.fa-tent-arrows-down::before {
  content: "\e581";
}

.fa-tents::before {
  content: "\e582";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-text::before {
  content: "\f893";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-tilde::before {
  content: "\~";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-tire::before {
  content: "\f631";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-toilet-portable::before {
  content: "\e583";
}

.fa-toilets-portable::before {
  content: "\e584";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-tower-cell::before {
  content: "\e585";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-tower-observation::before {
  content: "\e586";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-train::before {
  content: "\f238";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-train-track::before {
  content: "\e453";
}

.fa-train-tram::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-train-tunnel::before {
  content: "\e454";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-transgender::before {
  content: "\f225";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tree-city::before {
  content: "\e587";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-trees::before {
  content: "\f724";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-trillium::before {
  content: "\e588";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-trowel::before {
  content: "\e589";
}

.fa-trowel-bricks::before {
  content: "\e58a";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-truck-arrow-right::before {
  content: "\e58b";
}

.fa-truck-bolt::before {
  content: "\e3d0";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-truck-droplet::before {
  content: "\e58c";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-truck-field::before {
  content: "\e58d";
}

.fa-truck-field-un::before {
  content: "\e58e";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-truck-plane::before {
  content: "\e58f";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-turn-down-right::before {
  content: "\e455";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-u::before {
  content: "U";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-uniform-martial-arts::before {
  content: "\e3d1";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-up-from-bracket::before {
  content: "\e590";
}

.fa-up-from-dotted-line::before {
  content: "\e456";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-up-to-dotted-line::before {
  content: "\e457";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-upload::before {
  content: "\f093";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-user::before {
  content: "\f007";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-user-chef::before {
  content: "\e3d2";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-user-doctor-hair::before {
  content: "\e458";
}

.fa-user-doctor-hair-long::before {
  content: "\e459";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-user-hair::before {
  content: "\e45a";
}

.fa-user-hair-buns::before {
  content: "\e3d3";
}

.fa-user-hair-long::before {
  content: "\e45b";
}

.fa-user-hair-mullet::before {
  content: "\e45c";
}

.fa-business-front::before {
  content: "\e45c";
}

.fa-party-back::before {
  content: "\e45c";
}

.fa-trian-balbot::before {
  content: "\e45c";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-user-nurse-hair::before {
  content: "\e45d";
}

.fa-user-nurse-hair-long::before {
  content: "\e45e";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-user-robot-xmarks::before {
  content: "\e4a7";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-user-tie-hair::before {
  content: "\e45f";
}

.fa-user-tie-hair-long::before {
  content: "\e460";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-user-vneck::before {
  content: "\e461";
}

.fa-user-vneck-hair::before {
  content: "\e462";
}

.fa-user-vneck-hair-long::before {
  content: "\e463";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-users-between-lines::before {
  content: "\e591";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-users-line::before {
  content: "\e592";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-users-rays::before {
  content: "\e593";
}

.fa-users-rectangle::before {
  content: "\e594";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-users-viewfinder::before {
  content: "\e595";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-utensils-slash::before {
  content: "\e464";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-v::before {
  content: "V";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-vent-damper::before {
  content: "\e465";
}

.fa-venus::before {
  content: "\f221";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-vest::before {
  content: "\e085";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-vial::before {
  content: "\f492";
}

.fa-vial-circle-check::before {
  content: "\e596";
}

.fa-vial-virus::before {
  content: "\e597";
}

.fa-vials::before {
  content: "\f493";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-virus::before {
  content: "\e074";
}

.fa-virus-covid::before {
  content: "\e4a8";
}

.fa-virus-covid-slash::before {
  content: "\e4a9";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-volleyball::before {
  content: "\f45f";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-w::before {
  content: "W";
}

.fa-waffle::before {
  content: "\e466";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-walker::before {
  content: "\f831";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-water::before {
  content: "\f773";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-waveform-path::before {
  content: "\f8f2";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-wheat-awn-circle-exclamation::before {
  content: "\e598";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-window::before {
  content: "\f40e";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-worm::before {
  content: "\e599";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-x::before {
  content: "X";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-xmark-large::before {
  content: "\e59b";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-xmarks-lines::before {
  content: "\e59a";
}

.fa-y::before {
  content: "Y";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-z::before {
  content: "Z";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../../../../assets/fonts/fontawesome/fa-solid-900.woff2") format("woff2"), url("../../../../assets/fonts/fontawesome/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../../../../assets/fonts/fontawesome/fa-duotone-900.woff2") format("woff2"), url("../../../../assets/fonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}
.fad,
.fa-duotone {
  position: relative;
  font-family: "Font Awesome 6 Duotone";
  font-weight: 900;
  letter-spacing: normal;
}

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
}

.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute;
}

.fad.fa-stack-1x::before,
.fa-duotone.fa-stack-1x::before,
.fad.fa-stack-2x::before,
.fa-duotone.fa-stack-2x::before,
.fad.fa-fw::before,
.fa-duotone.fa-fw::before {
  left: 50%;
  transform: translateX(-50%);
}

/*!
 * Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../../../../assets/fonts/fontawesome/fa-brands-400.woff2") format("woff2"), url("../../../../assets/fonts/fontawesome/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

.fa-42-group:before {
  content: "\e080";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-audible:before {
  content: "\f373";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-aws:before {
  content: "\f375";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bilibili:before {
  content: "\e3d9";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-bots:before {
  content: "\e340";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cmplid:before {
  content: "\e360";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envira:before {
  content: "\f299";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-figma:before {
  content: "\f799";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-fly:before {
  content: "\f417";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-flag:before {
  content: "\f2b4";
}

.fa-font-awesome-logo-full:before {
  content: "\f2b4";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golang:before {
  content: "\e40f";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hashnode:before {
  content: "\e499";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-less:before {
  content: "\f41d";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f23a";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-meta:before {
  content: "\e49b";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-nfc-directional:before {
  content: "\e530";
}

.fa-nfc-symbol:before {
  content: "\e531";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-padlet:before {
  content: "\e4a0";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pix:before {
  content: "\e43a";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-screenpal:before {
  content: "\e570";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sith:before {
  content: "\f512";
}

.fa-sitrox:before {
  content: "\e44a";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f198";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ab";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-square-font-awesome:before {
  content: "\f425";
}

.fa-square-font-awesome-stroke:before {
  content: "\f35c";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f2c6";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-unity:before {
  content: "\e049";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-viber:before {
  content: "\f409";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wirsindhandwerk:before {
  content: "\e2d0";
}

.fa-wsh:before {
  content: "\e2d0";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

html {
  scroll-behavior: auto !important;
}

nav.navbar .nav-link {
  text-transform: uppercase;
}

img {
  max-width: 100%;
  height: auto;
}

#hero {
  position: relative;
  background: url(../img/main/bg.jpg) center center no-repeat;
  background-size: cover;
}
#hero #hero-title {
  position: relative;
}
#hero #hero-title img {
  width: 800px;
  height: auto;
}
#hero #hero-title div {
  position: absolute;
  bottom: 10px;
  width: 650px;
  left: 150px;
  text-transform: uppercase;
  font-size: 14px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  #hero #hero-title img {
    width: 600px;
  }
  #hero #hero-title div {
    bottom: 8px;
    width: 440px;
    left: 100px;
    font-size: 11px;
  }
}
@media (max-width: 575.98px) {
  #hero #hero-title img {
    width: 290px;
  }
  #hero #hero-title div {
    bottom: 4px;
    width: 440px;
    left: 48px;
    font-size: 6px;
  }
}
#hero:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to top, rgba(33, 37, 41, 0.3), rgba(33, 37, 41, 0));
}
#hero .container {
  position: relative;
}
@media (min-width: 768px) {
  #hero .play-button {
    position: absolute;
    top: 50px;
    right: -20px;
    width: 100px;
    height: 100px;
    border-radius: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  #hero .play-button {
    top: -80px;
    right: -20px;
    width: 120px;
    height: 120px;
  }
}

.lang-ru #player {
  display: block;
}
.lang-ru #player-en {
  display: none;
}
.lang-ru .show-en {
  display: none;
}

.lang-en #player {
  display: none;
}
.lang-en #player-en {
  display: block;
}
.lang-en .show-ru {
  display: none;
}

#video {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}
#video #video-bg,
#video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#video #video-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url(../img/main/preview.jpg) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: all 0.2s ease;
}
#video #video-bg #play-video {
  cursor: pointer;
  transition: all 0.3s ease;
  transform: scale(1);
  animation: ClickAnimation3 3s ease-in-out infinite;
}
#video #video-bg #play-video:hover {
  opacity: 0.7;
}

@media (max-width: 575.98px) {
  #calculator table thead,
#calculator table th:nth-of-type(1),
#calculator table th:nth-of-type(2),
#calculator table th:nth-of-type(3),
#calculator table th:nth-of-type(4) {
    display: none !important;
  }
  #calculator table tbody {
    border-top: none !important;
  }
  #calculator table tbody tr {
    border-bottom-width: 1px;
    display: flex;
    flex-direction: column;
  }
  #calculator table tbody tr td {
    border-bottom: 0;
    display: inline-block;
    margin: 0 auto;
    width: 100%;
  }
  #calculator table tfoot td {
    display: block;
    text-align: center;
  }
}

.bee-col {
  min-width: 250px;
}

.flying-bee {
  position: absolute;
  width: 70px;
  height: 70px;
  top: -20px;
  left: -20px;
  z-index: 1;
  transform: translate(0, 0);
  transform-origin: 50% 50%;
}
.flying-bee.more-gap {
  left: -35px;
  top: -25px;
}
.flying-bee.right {
  left: auto;
  right: -20px;
}
.flying-bee.right.more-gap {
  left: auto;
  right: -35px;
}
.flying-bee img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 575.98px) {
  .flying-bee {
    left: -10px;
    width: 90px;
    height: 90px;
    top: -30px;
  }
  .flying-bee.more-gap {
    top: -40px;
    left: -20px;
  }
  .flying-bee.right {
    left: auto;
    right: -40px;
  }
  .flying-bee.right.more-gap {
    left: auto;
    right: -40px;
  }
  .flying-bee.h-bee-1 {
    right: -10px !important;
    top: -45px !important;
  }
  .flying-bee.h-bee-2 {
    top: -80px !important;
    left: 0 !important;
  }
  .flying-bee.h-bee-3 {
    top: 25px !important;
    right: -20px !important;
  }
}

.card {
  position: relative;
}
@media (max-width: 575.98px) {
  .card .flying-bee {
    left: 10px;
    top: 30px;
  }
}
.card .card-title {
  z-index: 2;
}

@keyframes Bee {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, 2px);
  }
  50% {
    transform: translate(-5px, 15px);
  }
  75% {
    transform: translate(-8px, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes Bee2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-5px, 10px);
  }
  50% {
    transform: translate(5px, 15px);
  }
  75% {
    transform: translate(-13px, 2px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.card--with-honey {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

@media (max-width: 575.98px) {
  section {
    overflow-x: hidden;
  }
  section#calculator {
    overflow-x: unset;
  }
}

.click-animation {
  animation: 1.5s linear 0s infinite ClickAnimation2;
}

#how-to-list .card {
  transition: background-color 0.2s ease;
}
#how-to-list .card a {
  text-decoration: none !important;
}
#how-to-list .click-icon {
  opacity: 1;
  display: block;
  position: absolute;
  top: 25px;
  right: 25px;
  color: #fff;
  font-size: 30px;
  animation: 1.5s linear 0s infinite ClickAnimation;
  transition: opacity 0.2s ease;
}
#how-to-list .card:hover {
  background-color: #e3ba02 !important;
}

@keyframes ClickAnimation {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.75);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ClickAnimation2 {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.95);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ClickAnimation3 {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
#support-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background: #212529;
  color: #fdd835;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  box-shadow: 0 0 10px rgba(33, 37, 41, 0.75);
  text-decoration: none;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .navbar-nav {
    font-size: 82%;
  }
}